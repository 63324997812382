import { Category } from './task';

export class LegSource {
  active: boolean;
  description: string;
  dueDateDeltaTime: number;
  dueDateInitialTimeField: string;
  dueDateSetTime: string;
  taskCategory: Category;
  taskCategoryId: number;
  taskTypeId: number;
  title: string;
  dueDateUponCreation: boolean;
}

export class LegSourceType {
  active: boolean;
  legSource: string;
  taskType: LegSource;
  taskTypeId: number;
  taskTypeToLegSourceId: number;
}
