import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Client.Client] Load', props<{ payload: number }>());
export const loadSuccess = createAction(
  '[Client.Client] Load Success',
  props<{ payload: models.Client }>()
);
export const loadFail = createAction('[Client.Client] Load Fail');

export class loadOrdersPayload {
  clientId: number;
  ordersPage: number;
  itemsPerPage: number;
}
export const loadOrders = createAction('[Client.Client] Load Orders', props<{ payload: loadOrdersPayload }>());
export class loadOrdersSuccessPayload {
  orders: Array<models.Order>;
  totalItems: number;
  ordersPage: number;
}
export const loadOrdersSuccess = createAction(
  '[Client.Client] Load Orders Success',
  props<{ payload: loadOrdersSuccessPayload }>()
);
export const loadOrdersFail = createAction('[Client.Client] Load Orders Fail');

export const resumeMembership = createAction(
  '[Client.Client] Resume Membership',
  props<{ payload: models.ClientService }>()
);
export const resumeMembershipSuccess = createAction(
  '[Client.Client] Resume Membership Success',
  props<{ payload: models.ClientService }>()
);
export class ResumeMembershipFailPayload {
  constructor(public clientService: models.ClientService, public message: string) {}
}
export const resumeMembershipFail = createAction(
  '[Client.Client] Resume Membership Fail',
  props<{ payload: ResumeMembershipFailPayload }>()
);

export const suspendMembership = createAction(
  '[Client.Client] Suspend Membership',
  props<{ payload: models.ClientService }>()
);
export const suspendMembershipSuccess = createAction(
  '[Client.Client] Suspend Membership Success',
  props<{ payload: models.ClientService }>()
);
export class SuspendMembershipFailPayload {
  constructor(public clientService: models.ClientService, public message: string) {}
}
export const suspendMembershipFail = createAction(
  '[Client.Client] Suspend Membership Fail',
  props<{ payload: SuspendMembershipFailPayload }>()
);

export class TerminateClientServicePayload {
  clientServiceId: number;
  legacyMembershipPeriodId: number;
}
export const terminateClientService = createAction(
  '[Client.Client] Terminate Client Service',
  props<{ payload: TerminateClientServicePayload }>()
);
export class TerminateClientServiceSuccessPayload {
  entity: models.Client;
  clientService: models.ClientService;
}
export const terminateClientServiceSuccess = createAction(
  '[Client.Client] Terminate Client Service Success',
  props<{ payload: TerminateClientServiceSuccessPayload }>()
);
export class TerminateClientServiceFailPayload {
  clientServiceId: number;
  legacyMembershipPeriodId: number;
  message: string;
}
export const terminateClientServiceFail = createAction(
  '[Client.Client] Terminate Client Service Fail',
  props<{ payload: TerminateClientServiceFailPayload }>()
);

export class ReactivateClientServicePayload {
  clientServiceId: number;
  legacyMembershipPeriodId: number;
}
export const reactivateClientService = createAction(
  '[Client.Client] Reactivate Client Service',
  props<{ payload: ReactivateClientServicePayload }>()
);
export class ReactivateClientServiceSuccessPayload {
  entity: models.Client;
  clientService: models.ClientService;
}
export const reactivateClientServiceSuccess = createAction(
  '[Client.Client] Reactivate Client Service Success',
  props<{ payload: ReactivateClientServiceSuccessPayload }>()
);
export class ReactivateClientServiceFailPayload {
  clientServiceId: number;
  legacyMembershipPeriodId: number;
  message: string;
}
export const reactivateClientServiceFail = createAction(
  '[Client.Client] Reactivate Client Service Fail',
  props<{ payload: ReactivateClientServiceFailPayload }>()
);

export class LoadTerminationDataPayload {
  membershipPeriodId: number;
  clientServiceId: number;
}
export const loadTerminationData = createAction(
  '[Client.Client] Load Termination Data',
  props<{ payload: LoadTerminationDataPayload }>()
);
export class LoadTerminationDataSuccessPayload {
  entity: models.TerminationData;
}
export const loadTerminationDataSuccess = createAction(
  '[Client.Client] Load Termination Data Success',
  props<{ payload: LoadTerminationDataSuccessPayload }>()
);
export const loadTerminationDataFail = createAction('[Client.Client] Load Termination Data Fail');

export class TerminateSfdcMembershipPayload {
  legacyMembershipPeriodIds: Array<number>;
  clientServiceIds: Array<number>;
}
export const terminateSfdcMembership = createAction(
  '[Client.Client] Terminate Sfdc Membership',
  props<{ payload: TerminateSfdcMembershipPayload }>()
);
export class TerminateSfdcMembershipSuccessPayload {
  clientId: number;
}
export const terminateSfdcMembershipSuccess = createAction(
  '[Client.Client] Terminate Sfdc Membership Success',
  props<{ payload: TerminateSfdcMembershipSuccessPayload }>()
);
export const terminateSfdcMembershipFail = createAction(
  '[Client.Client] Terminate Sfdc Membership Fail'
);

export class loadSearchHistoryPayload {
  clientId: number;
  searchPage: number;
  itemsPerPage: number = 10;
}
export const loadSearchHistory = createAction('[Client.Client] Load Search History', props<{ payload: loadSearchHistoryPayload }>());
export class loadSearchHistorySuccessPayload {
  searchHistory: Array<models.Search>;
  totalItems: number;
  searchPage: number;
}
export const loadSearchHistorySuccess = createAction(
  '[Client.Client] Load Search History Success',
  props<{ payload: loadSearchHistorySuccessPayload }>()
);
export const loadSearchHistoryFail = createAction('[Client.Client] Load Search History Fail');