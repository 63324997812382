import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Region Collection] Load';
export const LOAD_SUCCESS = '[RM Region Collection] Load Success';
export const LOAD_FAIL = '[RM Region Collection] Load Fail';
// export const SET_AREA = '[RM Region Collection] Set Region';
// export const SAVE = '[RM Region Collection] Save';
// export const SAVE_SUCCESS = '[RM Region Collection] Save Success';
// export const SAVE_FAIL = '[RM Region Collection] Save Fail';

export class LoadPayload {
  constructor(public lookup: viewModels.Lookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<viewModels.ListItem>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

// export class SetRegionAction implements Action {
//   readonly type = SET_AREA;

//   constructor(public payload: SetRegionPayload) {
//   }
// }

// export class SetRegionPayload {
//   constructor(public item: models.Region) {
//   }
// }

// export class SaveAction implements Action {
//   readonly type = SAVE;
// }

// export class SaveSuccessPayload {
//   constructor(public item: models.Region) {
//   }
// }

// export class SaveSuccessAction implements Action {
//   readonly type = SAVE_SUCCESS;

//   constructor(public payload: SaveSuccessPayload) {
//   }
// }

// export class SaveFailAction implements Action {
//   readonly type = SAVE_FAIL;
// }

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
// | SetRegionAction
// | SaveAction
// | SaveSuccessAction
// | SaveFailAction;
