import { createReducer, on } from '@ngrx/store';

import * as models from '../../domain/models';
import * as actions from '../actions/universal-client-list.actions';

import * as _ from 'lodash';

export const universalClientListFeatureKey = 'universalClientList';

export interface State {
  searchTerm: string;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.UniversalClient>;
}

const initialState: State = {
  searchTerm: '',
  isLoaded: false,
  isLoading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.reset,
    (): State => ({
      ...initialState,
    })
  ),

  on(
    actions.showError,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.importClient,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(actions.importClientSuccess, (state, { payload }): State => {
    const item = payload;
    const cloned = _.cloneDeep(state.items);
    const index = state.items.findIndex(
      (i) => i.SalesForceClient.sfId === item.SalesForceClient.sfId
    );
    cloned[index] = item;
    return {
      ...state,
      items: cloned,
      isLoaded: true,
      isLoading: false,
    };
  }),
  on(
    actions.importClientFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.searchClients,
    (state, { payload }): State => ({
      ...state,
      searchTerm: _.cloneDeep(payload.searchTerm),
      isLoading: true,
    })
  ),
  on(
    actions.searchClientsSuccess,
    (state, { payload }): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
      items: payload.data,
    })
  ),

  on(
    actions.linkToSFDCContactSuccess,
    actions.cancelLinkingToSFDCContactSuccess,
    (state, { payload }): State => {
      const item = payload;
      const index = state.items.findIndex((i) => i.AppClient.clientId === item.AppClient.clientId);
      const cloned = _.cloneDeep(state.items);
      cloned[index] = item;
      return {
        ...state,
        items: cloned,
        isLoaded: true,
        isLoading: false,
      };
    }
  ),

  on(actions.generateClientActivationLinkSuccess, (state, { payload }): State => {
    const { items } = state;
    const { activation_link_expiration_time, activation_link_expired, activation_link } = payload;
    const index = state.items.findIndex(
      ({ AppClient: { clientId } }) => clientId === payload.clientId
    );
    const item = _.cloneDeep(items[index]);
    item.AppClient.setActivationLink(activation_link)
      .setActivationLinkExpired(activation_link_expired)
      .setActivationLinkExpirationTime(new Date(activation_link_expiration_time));
    const replaced = Object.assign([], items, { [index]: item });
    return {
      ...state,
      items: replaced,
    };
  })
);

