import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../../domain/models';

export interface IPricingComparison {
  getPrice(request: GetPriceRequest): Observable<GetPriceResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Sales.IPricingComparison');

export class GetPriceRequest {
  offer: models.MembershipPricingComparisonOffer;
}

export class GetPriceResponse {
  entities: Array<models.MembershipPricingComparisonPrice> = [];
}
