import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface IProductService {
  getProductData(request: GetProductDataRequest): Observable<GetProductDataResponse>;

  getVendorList(request: GetVendorListRequest): Observable<GetVendorListResponse>;
}

export const PRODUCT_SERVICE_TOKEN = new InjectionToken('Order.IProductService');

export class GetProductDataRequest {}

export class GetProductDataResponse {
  entities: Array<models.Product>;
  restrictions: Array<models.FunctionRestriction>;
  consoleLoginCheck: models.ConsoleLoginCheck;
}

export class GetVendorListRequest {}

export class GetVendorListResponse {
  entities: Array<models.Vendor>;
}
