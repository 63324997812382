import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as coreTypes from '../../../../../core/types';
import * as serviceContracts from '../../../domain/service-contracts/order';
import * as models from '../../../domain/models';
import * as mappers from './mappers';

import * as _ from 'lodash';

@Injectable()
export class OrderService implements serviceContracts.IOrderService {
  private get API_PATH(): string {
    return this.configService.get('OrderServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  createOrder(
    request: serviceContracts.CreateOrderRequest
  ): Observable<serviceContracts.CreateOrderResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/create`;

    const payload = {
      order: mappers.OrderMapper.convertOrderToSerializable(request),
    };
    const body = JSON.stringify(payload);

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(requestUrl, body, { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.CreateOrderResponse();

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        response.orderId = Number(svcJsonResp.data.orderId);

        response.invoice = new models.Invoice();
        response.invoice.id = svcJsonResp.data.invoiceId;
        response.invoice.orderId = svcJsonResp.data.orderId;
        response.invoice.uuid = svcJsonResp.data.invoiceUuid;
        response.invoice.legacyFormUrlKey = svcJsonResp.data.legacyFormUrlKey;

        return response;
      })
    );
  }

  cancelOrder(
    request: serviceContracts.CancelOrderRequest
  ): Observable<serviceContracts.CancelOrderResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/cancel`;

    const svcRequest = JSON.stringify(request);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put(requestUrl, svcRequest, { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.CancelOrderResponse();

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        response.orderId = svcJsonResp.data.orderId;
        response.orderStatusId = svcJsonResp.data.orderStatusId;
        response.orderStatusName = svcJsonResp.data.orderStatusName;
        response.message = svcJsonResp.data.message;

        return response;
      })
    );
  }

  reinstateOrder(
    request: serviceContracts.ReinstateOrderRequest
  ): Observable<serviceContracts.ReinstateOrderResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${request.orderId}/reinstate`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.put(requestUrl, '{}', { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.ReinstateOrderResponse();

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        response.orderId = svcJsonResp.data.orderId;
        response.orderStatusId = svcJsonResp.data.orderStatusId;
        response.orderStatusName = svcJsonResp.data.orderStatusName;
        response.message = svcJsonResp.data.message;

        return response;
      })
    );
  }

  getOrder(
    request: serviceContracts.GetOrderRequest
  ): Observable<serviceContracts.GetOrderResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${request.orderId}`;

    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.GetOrderResponse();

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        response.entity = mappers.OrderMapper.parseOrder(svcJsonResp.data);
        return response;
      })
    );
  }

  markPaid(
    request: serviceContracts.MarkPaidRequest
  ): Observable<serviceContracts.MarkPaidResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${request.order.id}/activate`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const payload = {
      paymentTransaction: new mappers.PaymentTransactionMapper().toDto(request.paymentTransaction),
    };

    const svcRequest = JSON.stringify(payload);

    return this.http.put(requestUrl, svcRequest, { headers }).pipe(
      map(() => {
        return new serviceContracts.MarkPaidResponse();
      })
    );
  }

  arPaymentConfirm(
    request: serviceContracts.MarkPaidRequest
  ): Observable<serviceContracts.MarkPaidResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${request.order.id}/payment-confirmed`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const payload = {
      paymentTransaction: new mappers.PaymentTransactionMapper().toDto(request.paymentTransaction),
    };

    const svcRequest = JSON.stringify(payload);

    return this.http.put(requestUrl, svcRequest, { headers }).pipe(
      map(() => {
        return new serviceContracts.MarkPaidResponse();
      })
    );
  }

  orderList(
    request: serviceContracts.OrderListRequest
  ): Observable<serviceContracts.OrderListResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/`;

    let params = new HttpParams();

    if (request.accountId) {
      params = params.append('accountId', request.accountId);
    }

    if (request.orderId) {
      params = params.append('orderId', request.orderId.toString());
    }

    if (request.invoiceId) {
      params = params.append('invoiceId', request.invoiceId.toString());
    }

    if (request.clientId) {
      params = params.append('clientId', request.clientId.toString());
    }

    if (request.requestId) {
      params = params.append('requestId', request.requestId.toString());
    }

    if (request.clientNameId) {
      params = params.append('clientNameId', request.clientNameId);
    }

    if (request.orderStatusIds && request.orderStatusIds.length > 0) {
      params = params.append('orderStatusIds', request.orderStatusIds.join());
    }

    if (request.sapExportStatusIds && request.sapExportStatusIds.length > 0) {
      params = params.append('sapExportStatusIds', request.sapExportStatusIds.join());
    }

    if (request.invoiceTotal) {
      params = params.append('invoiceTotal', request.invoiceTotal.toString());
    }

    params = params.append('filterByCurrentUser', (request.filterByCurrentUser ? 1 : 0).toString());

    if (request.filterByUnconfirmedPayments) {
      params = params.append('paymentConfirmed', 'False');
    }

    if (request.paymentType) {
      params = params.append('paymentType', request.paymentType.toString());
    }

    if (request.startDate) {
      params = params.append('departureDateStart', request.startDate);
    }

    if (request.endDate) {
      params = params.append('departureDateEnd', request.endDate);
    }

    params = params.append('offset', request.offset.toString());
    params = params.append('limit', request.limit.toString());

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.OrderListResponse();

        if (!svcJsonResp || !svcJsonResp.data || !svcJsonResp.data.orders) {
          return response;
        }

        if (svcJsonResp.data.accountingReportAcl) {
          response.accountingReportAcl = svcJsonResp.data.accountingReportAcl;
        }

        response.orders = svcJsonResp.data.orders.map((e) => mappers.OrderListMapper.parseOrder(e));

        response.count = svcJsonResp.data.totalOrders;

        return response;
      })
    );
  }

  downloadReport(): Observable<serviceContracts.DownloadReportResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/accounting-report-v2`;

    return this.http.get(requestUrl, { responseType: 'blob' }).pipe(
      map((res) => {
        const today = new Date();
        const year = today.getFullYear();
        const month = `0${today.getMonth() + 1}`.slice(-2);
        const day = today.getDate();

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';

        const url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = `JetStudio-${year}-${month}-${day}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);

        return new serviceContracts.DownloadReportResponse();
      })
    );
  }

  changeOrderPaymentMethod(
    request: serviceContracts.ChangeOrderPaymentMethodRequest
  ): Observable<serviceContracts.ChangeOrderPaymentMethodResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${request.id}/change-payment-type`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const payload = {
      paymentOptions: new mappers.PaymentOptionsMapper().toDto(request.paymentOptions),
    };

    const svcRequest = JSON.stringify(payload);

    return this.http.put(requestUrl, svcRequest, { headers }).pipe(
      map(() => {
        return new serviceContracts.MarkPaidResponse();
      }),
      catchError((svcResp): Observable<any> => {
        throw new Error(svcResp.message);
      })
    );
  }

  reGenerateDocument({
    invoiceId,
  }: serviceContracts.ReGenerateRequest): Observable<serviceContracts.ReGenerateResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/${invoiceId}/generate-pdf`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const body = { name: null };

    return this.http.post(requestUrl, JSON.stringify(body), { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.ReGenerateResponse();
        response.entities = svcJsonResp.data.documents.map((e) =>
          mappers.GeneratedDocumentMapper.parseGeneratedDocument(e)
        );
        response.documentGenerationInProgress = svcJsonResp.data.documentGenerationInProgress;
        response.showGeneratedDocumentsPanel = svcJsonResp.data.showGeneratedDocumentsPanel;
        return response;
      })
    );
  }

  getDocumentsByOrder({
    orderId,
  }: serviceContracts.GetDocumentsByOrderRequest): Observable<serviceContracts.GetDocumentsByOrderResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/get-documents-by-order/${orderId}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.ReGenerateResponse();
        response.entities = svcJsonResp.data.documents.map((e) =>
          mappers.GeneratedDocumentMapper.parseGeneratedDocument(e)
        );
        response.documentGenerationInProgress = svcJsonResp.data.documentGenerationInProgress;
        response.showGeneratedDocumentsPanel = svcJsonResp.data.showGeneratedDocumentsPanel;
        return response;
      })
    );
  }

  isProformaInvoice(flightRequestId: number): Observable<serviceContracts.ProformaInvoiceResponse> {
    const requestUrl =
      `${this.API_PATH}/v1.0/orders/is-proforma-invoice/` + flightRequestId.toString();

    return this.http.get(requestUrl).pipe(
      map((response: serviceContracts.ProformaInvoiceResponse) => {
        return response;
      })
    );
  }

  isAmendChargesRestricted(
    userId: number
  ): Observable<serviceContracts.AmendChargesPermissionResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/security/user-permission/` + userId;

    return this.http.get(requestUrl).pipe(
      map((response: serviceContracts.AmendChargesPermissionResponse) => {
        return response;
      })
    );
  }

  createSfdcOpportunity({
    orderId,
  }: serviceContracts.CreateSfdcOpportunityRequest): Observable<serviceContracts.CreateSfdcOpportunityResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/orders/${orderId}/create-sfdc-opportunity`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.put(requestUrl, null, { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new serviceContracts.CreateSfdcOpportunityResponse();
        response.sfdcOpportunityId = _.get(svcJsonResp?.data, 'sfdcOpportunityId', null);
        return response;
      })
    );
  }
}
