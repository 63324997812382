export class AircraftAmenity {
  public aircraftAmenityUuid: string;
  public name: string;

  getAircraftAmenityUuid(): string {
    return this.aircraftAmenityUuid;
  }

  setAircraftAmenityUuid(aircraftAmenityUuid: string): AircraftAmenity {
    this.aircraftAmenityUuid = aircraftAmenityUuid;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): AircraftAmenity {
    this.name = name;

    return this;
  }
}
