/* eslint-disable */
import { Component, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'secondary',
  templateUrl: './secondary.component.html',
  styleUrls: ['./secondary.component.scss']
})
export class SecondaryComponent {
  @Output() onClose = new EventEmitter<void>();

  answer = '';
  selection = 0;
  ready = false;
  over = false;

  iFrameContentVal = `https://oleh-kolinko.github.io/lazy-pirates-game/`;

  correctAnswerCheck(a: string): boolean {
    return a.toLowerCase() === 'ujet';
  }

  back(): void {
    this.selection = 0;
    this.answer = '';
    this.ready = false;
    this.over = false;
  }

  quit(): void {
    this.over = false;
    this.selection = 0;
    this.ready = false;
  }

  snRestart(): void {
    this.over = false;
    this.ready = false;
    this.initSn();
  }

  initSn(): void {
    if (this.browserCheck()) {
      this.selection = 1;
      setTimeout(() => {
        this.ready = true;

        setTimeout(() => {
          this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
          this.context = this.canvas.getContext('2d');
          this.state = {
            // Canvas Grid count including borders
            gridX: 40,
            gridY: 20,
            sn: [{
              x: 2,
              y: 2
            }],
            apl: {
              x: 10,
              y: 10
            },
            gamePaused: false,
            gameEnded: false,
            verticalCellSize: 0,
            horizontalCellSize: 0,
            currentDirection: this.movementEnum.RIGHT,
            directionQueue: [],

            // NEXT VERSION
            dificulty: 200, // ms
            aplBodyColor: 'red',
            aplBorderColor: 'white',
            snBodyColor: 'yellow',
            snBodyBorderColor: 'white',
            snHeadColor: 'green',
            snHeadBorderColor: 'white',
            backgroundColor: 'black',
            backgroundBorderColor: 'white'
            // END: NEXT VERSION
          };
          this.initialGridCalculation();
          this.resizeCanvas();
          this.itteration();
        }, 200);
      }, 1200);
    }
  }

  // SN
  canvas: HTMLCanvasElement;
  context = null;
  movementEnum = {
    UP: 0,
    DOWN: 1,
    LEFT: 2,
    RIGHT: 3
  };
  state = {
    // Canvas Grid count including borders
    gridX: 40,
    gridY: 20,
    sn: [{
      x: 2,
      y: 2
    }],
    apl: {
      x: 10,
      y: 10
    },
    gamePaused: false,
    gameEnded: false,
    verticalCellSize: 0,
    horizontalCellSize: 0,
    currentDirection: this.movementEnum.RIGHT,
    directionQueue: [],

    // NEXT VERSION
    dificulty: 100, // ms
    aplBodyColor: 'red',
    aplBorderColor: 'white',
    snBodyColor: 'yellow',
    snBodyBorderColor: 'white',
    snHeadColor: 'green',
    snHeadBorderColor: 'white',
    backgroundColor: 'black',
    backgroundBorderColor: 'white'
    // END: NEXT VERSION
  };

  @HostListener('window:resize', ['$event'])
  resizeCanvas() {
    if (this.selection !== 1 || this.ready === false) {
      return;
    }
    this.canvas.width = window.innerWidth;
    this.canvas.height = (window.innerHeight - 55);
    this.calculateGridCellSize();

    this.drawTheCanvas();
  }

  @HostListener('document:keydown', ['$event'])
  keyboardHandler(event: KeyboardEvent) {
    if (this.selection !== 1 || this.ready === false) {
      return;
    }
    switch (event.key) {
      case 'ArrowUp':
        {
          // Can't go back
          if (this.state.currentDirection !== this.movementEnum.DOWN) {
            this.state.directionQueue.push(this.movementEnum.UP);
          }
          break;
        }
      case 'ArrowDown':
        {
          if (this.state.currentDirection !== this.movementEnum.UP) {
            this.state.directionQueue.push(this.movementEnum.DOWN);
          }
          break;
        }
      case 'ArrowLeft':
        {
          if (this.state.currentDirection !== this.movementEnum.RIGHT) {
            this.state.directionQueue.push(this.movementEnum.LEFT);
          }
          break;
        }
      case 'ArrowRight':
        {
          if (this.state.currentDirection !== this.movementEnum.LEFT) {
            this.state.directionQueue.push(this.movementEnum.RIGHT);
          }
          break;
        }
      case 'Escape':
        {
          // To Pause the Game: 'Cheat Code' Every game neads a cheat code :)
          this.state.gamePaused = !this.state.gamePaused;
          // To restart the itteration if it was paused
          this.itteration();
        }
      default:
        break;
    }
  };
  initialGridCalculation() {
    // X axis
    if (window.innerWidth > 1366) {
      this.state.gridX = 40;
      this.state.dificulty = 100;
    } else if (window.innerWidth < 1366 && window.innerWidth >= 1280) {
      this.state.gridX = 30;
      this.state.dificulty = 120;
    } else if (window.innerWidth < 1280 && window.innerWidth >= 768) {
      this.state.gridX = 20;
      this.state.dificulty = 190;
    } else if (window.innerWidth < 768) {
      this.state.gridX = 12;
      this.state.dificulty = 300;
    }

    // Y axis
    if (window.innerHeight > 900) {
      this.state.gridY = 30;
    }else if (window.innerHeight < 768 && window.innerHeight >= 500) {
      this.state.gridY = 20;
    }else if (window.innerHeight < 500) {
      this.state.gridY = 12;
    }
  };

  calculateGridCellSize() {
    this.state.verticalCellSize = (window.innerHeight - 55) / (this.state.gridY + 2);
    this.state.horizontalCellSize = window.innerWidth / (this.state.gridX + 2);
  };

  drawTheCanvas() {
    // Clear out
    this.context.fillStyle = this.state.backgroundBorderColor;
    this.context.fillRect(
      0,
      0,
      window.innerWidth,
      window.innerHeight
    );

    // Draw new background acount for border
    this.context.fillStyle = this.state.backgroundColor;
    this.context.fillRect(
      this.state.horizontalCellSize,
      this.state.verticalCellSize,
      this.canvas.width - 2 * this.state.horizontalCellSize,
      this.canvas.height - 2 * this.state.verticalCellSize
    );

    // Sn with green head
    this.state.sn.forEach((part, ittr) => {
      this.drawCube(
        part.x,
        part.y,
        ittr === 0 ? this.state.snHeadColor : this.state.snBodyColor,
        ittr === 0 ? this.state.snHeadBorderColor : this.state.snBodyBorderColor
      );
    });

    // Apple
    this.drawCube(
      this.state.apl.x,
      this.state.apl.y,
      this.state.aplBodyColor,
      this.state.aplBorderColor
    );
  };

  drawCube(x, y, bodyColor, borderColor) {
    // Body with 2px border
    this.context.fillStyle = bodyColor;
    this.context.fillRect(
      (x + 1) * this.state.horizontalCellSize + 2,
      (y + 1) * this.state.verticalCellSize + 2,
      this.state.horizontalCellSize - 4,
      this.state.verticalCellSize - 4
    );
    this.context.strokeStyle = borderColor;
    this.context.strokeRect(
      (x + 1) * this.state.horizontalCellSize,
      (y + 1) * this.state.verticalCellSize,
      this.state.horizontalCellSize,
      this.state.verticalCellSize
    );
  };

  moveElements() {
    const head = {
      x: null,
      y: null
    };
    switch (this.state.currentDirection) {
      case this.movementEnum.UP:
        {
          // Hit the Wall
          if (this.state.sn[0].y === 0) {
            this.gameOver();
          }
          head.x = this.state.sn[0].x;
          head.y = this.state.sn[0].y - 1;

          break;
        }
      case this.movementEnum.DOWN:
        {
          if (this.state.sn[0].y === this.state.gridY - 1) {
            this.gameOver();
          }
          head.x = this.state.sn[0].x;
          head.y = this.state.sn[0].y + 1;

          break;
        }
      case this.movementEnum.LEFT:
        {
          if (this.state.sn[0].x === 0) {
            this.gameOver();
          }
          head.x = this.state.sn[0].x - 1;
          head.y = this.state.sn[0].y;

          break;
        }
      case this.movementEnum.RIGHT:
        {
          if (this.state.sn[0].x === this.state.gridX - 1) {
            this.gameOver();
          }
          head.x = this.state.sn[0].x + 1;
          head.y = this.state.sn[0].y;

          break;
        }
      default:
        throw new Error('Enum not found: index.js - this.moveElements()');
    }

    // Snake hit itself
    this.state.sn.forEach(part => {
      if (part.x === head.x && part.y === head.y) {
        this.gameOver();
      }
    });

    // Don't draw if the game has ended
    if (!this.state.gameEnded) {
      if (head.x === this.state.apl.x && head.y === this.state.apl.y) {
        // Ate the apl
        this.getNewApple();
      } else {
        // Didn't grow a tail
        this.state.sn.pop();
      }

      // Advanced forward
      this.state.sn.unshift(head);
    }

    this.drawTheCanvas();
  };

  // Game Clock
  itteration() {
    setTimeout(() => {
      // Apple the next direction in the queue
      if (this.state.directionQueue.length) {
        this.state.currentDirection = this.state.directionQueue.pop();
      }
      this.moveElements();
      // Continue unless game is paused or ended
      if (!this.state.gameEnded && !this.state.gamePaused) {
        this.itteration();
      }
    }, this.state.dificulty);
  };

  getNewApple() {
    this.state.apl.x = this.getRandomLocation(1, this.state.gridX - 2);
    this.state.apl.y = this.getRandomLocation(1, this.state.gridY - 2);

    // If lands on the sn then re-draw
    this.state.sn.forEach(part => {
      if (part.x === this.state.apl.x && part.y === this.state.apl.y) {
        this.getNewApple();
      }
    });
  };

  getRandomLocation(max, min) {
    return Math.floor((Math.random() * (max - min) + min));
  };

  gameOver() {
    this.state.gameEnded = true;
    this.over = true;
  };

  browserCheck() {
    const isChromium = window.hasOwnProperty('chrome');
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = window.hasOwnProperty('opr');
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1;

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight - 55;
      this.context.font = "70px Georgia";
      this.context.fillText("Mobile not supported yet", 20, 500);

      return false;
    }

    if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true;
    } else {
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight - 55;
      this.context.font = "70px Georgia";
      this.context.fillText("Only Chrome is supported at this time", 20, 500);

      return false;
    }
  };
}
