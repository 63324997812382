import { PriceComponent } from './price-component';

export class ChargeComponent {
  private priceComponent: PriceComponent;
  private unitBasePrice: number;
  private unitQuantity?: number;
  private unitPercentage?: number;
  private amount: number;
  private currency = 'USD';

  getCurrency(): string {
    return this.currency;
  }

  setCurrency(currency: string): ChargeComponent {
    this.currency = currency;

    return this;
  }

  getPriceComponent(): PriceComponent {
    return this.priceComponent;
  }

  setPriceComponent(priceComponent: PriceComponent): ChargeComponent {
    this.priceComponent = priceComponent;

    return this;
  }

  getUnitBasePrice(): number {
    return this.unitBasePrice;
  }

  setUnitBasePrice(unitBasePrice: number): ChargeComponent {
    this.unitBasePrice = unitBasePrice;

    return this;
  }

  getUnitQuantity(): number {
    return this.unitQuantity;
  }

  setUnitQuantity(unitQuantity: number): ChargeComponent {
    this.unitQuantity = unitQuantity;

    return this;
  }

  getUnitPercentage(): number {
    return this.unitPercentage;
  }

  setUnitPercentage(unitPercentage: number): ChargeComponent {
    this.unitPercentage = unitPercentage;

    return this;
  }

  getAmount(): number {
    return this.amount;
  }

  setAmount(amount: number): ChargeComponent {
    this.amount = amount;

    return this;
  }
}
