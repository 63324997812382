import * as actions from '../actions/leg-pricing';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export interface State {
  leg: models.Leg;
  legPrice: models.Price;
  legMatrix: models.MatrixData;
  legPriceAuditLog: Array<models.LegPriceAudit>;
  loaded: boolean;
  loading: boolean;
  matrixLoaded: boolean;
  matrixLoading: boolean;
  updating: boolean;
  updated: boolean;
  lastUpdatedByUser: string;
  lastUpdatedDate: Date;
  updateReasons: Array<models.PriceUpdateReason>;
  updateReasonsLoaded: boolean;
  updateReasonsLoading: boolean;
}

const initialState: State = {
  leg: null,
  legPrice: null,
  legMatrix: null,
  legPriceAuditLog: [],
  loaded: false,
  loading: false,
  matrixLoaded: false,
  matrixLoading: false,
  updating: false,
  updated: false,
  lastUpdatedByUser: null,
  lastUpdatedDate: null,
  updateReasons: null,
  updateReasonsLoaded: false,
  updateReasonsLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.DEACTIVATE_SUCCESS: {
      return {
        ...state,
        legPrice: new models.Price(),
        loading: true,
      };
    }

    case actions.LOAD_MATRIX: {
      return {
        ...state,
        legMatrix: null,
        matrixLoading: true,
        matrixLoaded: false,
      };
    }

    case actions.LOAD_MATRIX_SUCCESS: {
      return {
        ...state,
        legMatrix: _.cloneDeep(action.payload.matrix),
        matrixLoading: false,
        matrixLoaded: true,
      };
    }

    case actions.LOAD_MATRIX_FAIL: {
      return {
        ...state,
        matrixLoading: false,
        matrixLoaded: true,
      };
    }

    case actions.LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        leg: action.payload.leg,
        legPrice: action.payload.legPrice,
        loaded: true,
        loading: false,
        lastUpdatedByUser: action.payload.lastUpdatedUser,
        lastUpdatedDate: action.payload.lastUpdatedDate,
      };
    }

    case actions.LOAD_AUDIT_LOG: {
      return {
        ...state,
        legPriceAuditLog: [],
      };
    }

    case actions.LOAD_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        legPriceAuditLog: action.payload.auditLog,
      };
    }

    case actions.LOAD_UPDATE_REASONS: {
      return {
        ...state,
        updateReasonsLoading: true,
      };
    }

    case actions.LOAD_UPDATE_REASONS_SUCCESS: {
      return {
        ...state,
        updateReasons: action.payload.entities,
        updateReasonsLoading: false,
        updateReasonsLoaded: true,
      };
    }

    case actions.LOAD_UPDATE_REASONS_FAIL: {
      return {
        ...state,
        updateReasonsLoading: false,
      };
    }

    case actions.UPDATE: {
      return {
        ...state,
        updating: true,
      };
    }

    case actions.UPDATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        leg: payload.leg,
        legPrice: payload.legPrice,
        updating: false,
        updated: true,
        lastUpdatedByUser: payload.lastUpdatedUser,
        lastUpdatedDate: payload.lastUpdatedDate,
      };
    }

    case actions.UPDATE_FAIL: {
      return {
        ...state,
        updating: false,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
        updateReasons: state.updateReasons,
        updateReasonsLoaded: state.updateReasonsLoaded,
        updateReasonsLoading: state.updateReasonsLoading,
      };
    }

    // Shared Charter
    case actions.OPERATOR_SHARED_CHARTER_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.OPERATOR_SHARED_CHARTER_LOAD_SUCCESS: {
      return {
        ...state,
        leg: action.payload.leg,
        legPrice: action.payload.legPrice,
        loaded: true,
        loading: false,
        lastUpdatedByUser: null,
        lastUpdatedDate: null,
      };
    }

    case actions.OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG: {
      return {
        ...state,
        legPriceAuditLog: [],
      };
    }

    case actions.OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        legPriceAuditLog: action.payload.auditLog,
      };
    }
    case actions.OPERATOR_SHARED_CHARTER_UPDATE: {
      return {
        ...state,
        updating: true,
      };
    }

    case actions.OPERATOR_SHARED_CHARTER_UPDATE_SUCCESS: {
      return {
        ...state,
        leg: action.payload.leg,
        legPrice: action.payload.legPrice,
        updating: false,
        updated: true,
        lastUpdatedByUser: null,
        lastUpdatedDate: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const loaded = (state: State) => state.loaded;
export const loading = (state: State) => state.loading;
export const getMatrixLoaded = (state: State) => state.matrixLoaded;
export const getMatrixLoading = (state: State) => state.matrixLoading;
export const getLegPrice = (state: State) => state.legPrice;
export const getLegMatrix = (state: State) => state.legMatrix;
export const getLegPriceAuditLog = (state: State) => state.legPriceAuditLog;
export const updating = (state: State) => state.updating;
export const updated = (state: State) => state.updated;
export const getLastUpdatedByUser = (state: State) => state.lastUpdatedByUser;
export const getLastUpdatedDate = (state: State) => state.lastUpdatedDate;
export const getUpdateReasons = (state: State) => state.updateReasons;
export const getUpdateReasonsLoading = (state: State) => state.updateReasonsLoading;
export const getUpdateReasonsLoaded = (state: State) => state.updateReasonsLoaded;
