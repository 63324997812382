import * as contracts from '../../../domain/service-contracts/security';
import * as models from '../../../domain/models';
import * as domainServices from '../../../domain/domain-services';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

export class SecurityMockService implements contracts.ISecurityService {
  private readonly API_PATH: string;

  reset(request: contracts.ResetResponse): Observable<contracts.ResetResponse> {
    return of(new contracts.ResetResponse());
  }

  authorize(request: contracts.AuthorizeRequest): Observable<contracts.AuthorizeResponse> {
    const response = new contracts.AuthorizeResponse();
    response.entities = _.cloneDeep(request.entities);
    response.entities.pop();

    return of(response).pipe(delay(3000));
  }
}
