import { OrderItemSerializable } from './order-item-serializable';
import * as models from '../../../../domain/models';

export class ProductOrderItemSerializable extends OrderItemSerializable {
  // product: models.Product;
  productId: number = null;
  productProductTypeParentProductTypeId: number = null;
  productTypeId: number = null;
  productName: string = null;
  productAmount: number = null;
  promotionAmount: number = null;
  promotionItemId: number = null;
  clientName: string = null;

  requestId: number = null;
  departureDate: Date = null;
}
