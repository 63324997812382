import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const RESET = '[Marketplace.LastLookRequestList] Reset';
export const SEARCH = '[Marketplace.LastLookRequestList] Search';
export const SEARCH_SUCCESS = '[Marketplace.LastLookRequestList] Load Success';
export const SEARCH_FAIL = '[Marketplace.LastLookRequestList] Load Fail';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SearchPayload {
  page: number;
  itemsPerPage: number;

  constructor(page: number, itemsPerPage: number) {
    this.page = page;
    this.itemsPerPage = itemsPerPage;
  }
}

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: SearchPayload) {}
}

export class SearchSuccessPayload {
  page: number;
  data: Array<models.LastLookRequest>;
  count: number;
}

export class SearchSuccessAction implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: SearchSuccessPayload) {}
}

export class SearchFailAction implements Action {
  readonly type = SEARCH_FAIL;
}

export type Actions = ResetAction 
  | SearchAction 
  | SearchSuccessAction 
  | SearchFailAction;
