import { Injectable } from '@angular/core';
import { FinalizedLegsResponse, IFinanceService } from '../../../domain/service-contracts/finance';
import { Observable, of } from 'rxjs';
import { FinalizedLeg } from '../../../domain/models/finalized-leg';

@Injectable({
  providedIn: 'root',
})
export class FinanceMockService implements IFinanceService {
  constructor() {}

  getFinalizedLegsByFlightRequest(
    flightRequestId: number
  ): Observable<FinalizedLegsResponse | void> {
    const mockFinalizedLeg1 = new FinalizedLeg();
    mockFinalizedLeg1.jsLegId = 'mockJsLegId1';
    mockFinalizedLeg1.fosLegStatus = 'mockFosLegStatus';
    mockFinalizedLeg1.flightStatusId = 1;
    mockFinalizedLeg1.paxCount = 2;
    mockFinalizedLeg1.tailNumber = 'mockTailNumber';
    mockFinalizedLeg1.blockTimeAct = 3;
    mockFinalizedLeg1.departureDateLocal = 'mockDepartureDateLocal';
    mockFinalizedLeg1.departureAirportIcao = 'mockDepartureAirportIcao';
    mockFinalizedLeg1.arrivalDateLocal = 'mockArrivalDateLocal';
    mockFinalizedLeg1.arrivalAirportIcao = 'mockArrivalAirportIcao';
    mockFinalizedLeg1.flightRequestId = 123123;

    const mockFinalizedLeg2 = new FinalizedLeg();
    mockFinalizedLeg1.jsLegId = 'mockJsLegId2';
    mockFinalizedLeg1.fosLegStatus = 'mockFosLegStatus';
    mockFinalizedLeg1.flightStatusId = 1;
    mockFinalizedLeg1.paxCount = 3;
    mockFinalizedLeg1.tailNumber = 'mockTailNumber';
    mockFinalizedLeg1.blockTimeAct = 4;
    mockFinalizedLeg1.departureDateLocal = 'mockDepartureDateLocal';
    mockFinalizedLeg1.departureAirportIcao = 'mockDepartureAirportIcao';
    mockFinalizedLeg1.arrivalDateLocal = 'mockArrivalDateLocal';
    mockFinalizedLeg1.arrivalAirportIcao = 'mockArrivalAirportIcao';
    mockFinalizedLeg1.flightRequestId = 123123;

    const mockFinalizedLegsResponse = new FinalizedLegsResponse();
    mockFinalizedLegsResponse.hasFinalizedLegs = true;
    mockFinalizedLegsResponse.finalizedLegs = [mockFinalizedLeg1, mockFinalizedLeg2];

    return of(mockFinalizedLegsResponse);
  }
}
