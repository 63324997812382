export class AircraftCategory {
  id: number = null;
  name: string;
  eft: number = null;

  getEft(): number {
    return this.eft;
  }

  setEft(eft: number): AircraftCategory {
    this.eft = eft;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): AircraftCategory {
    this.name = name;

    return this;
  }

  getId(): number {
    return this.id;
  }

  setId(id: number): AircraftCategory {
    this.id = id;

    return this;
  }

  toString(): string {
    return `(${this.id}) ${this.name}`;
  }
}
