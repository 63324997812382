export const GetConsoleLegLink = (legId: number): string => {
  if (!legId) { return; }

  let baseUrl = 'https://dev.console.vista.studio/';
  if (location.host.includes('requests.vista.studio')) {
    baseUrl = 'https://console.vista.studio/';
  }

  return `${baseUrl}#activelegsfull:${legId}`;
};
