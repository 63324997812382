import * as moment from 'moment-timezone';

export class MembershipPricingComparisonOffer {
  routes: Array<MembershipPricingComparisonOfferRoute>;
  settings: { [key: string]: boolean | number };
  features: Array<number> = [];
}

export enum DepTimeChangeEnum {
  FIRM,
  FLEX,
}

export enum MemberStatusEnum {
  NON_MEMBER,
  MEMBER,
}

export class MembershipPricingComparisonOfferRoute {
  origin: string = null;
  destination: string = null;
  aircraftCategory: number = null;
  date: Date = null;
  depTimeChange: DepTimeChangeEnum = null;
  tripCount: number = null;
}

export class PricingCalculatorOffer {
  legs: Array<PricingCalculatorOfferLeg>;
  actypes: Array<string>;
  automationIgnore: boolean;
  userTimeZoneId: string = moment.tz.guess();
  depTimeChange: DepTimeChangeEnum = null;
  suppressTaxFee?: boolean;
  prefillRejected?: boolean;
  lastDayOfIATAFuel?: number;
  membershipType: string;
  dnqIgnore: boolean;
  membershipNreg?: boolean = false;
  wholeSale: boolean;
  pax: number;
  showWebView: boolean = null;
}

export class PricingCalculatorOfferLeg {
  origin: string = null;
  destination: string = null;
  aircraftCategory: number = null;
  depDateTime: string = null;
  sortOrder: number = null;
  opportunityPax: number = null;
  opportunityPrice: number = null;
  price: number = null;
  pax: number = null;
  suppressTaxFee: boolean = false;
  ferry?: boolean;
  peakDays?: boolean;
}
