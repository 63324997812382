import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const reset = createAction('[Client.ClientList] Reset');

export class SearchClientsPayload {
  criteria: viewModels.ClientLookupCriteria;
  page: number;
  itemsPerPage: number;
}
export const searchClients = createAction(
  '[Client.ClientList] Search Clients',
  props<{ payload: SearchClientsPayload }>()
);
export class SearchClientsSuccessPayload {
  page: number;
  data: Array<models.Client>;
  count: number;
}
export const searchClientsSuccess = createAction(
  '[Client.ClientList] Search Clients Success',
  props<{ payload: SearchClientsSuccessPayload }>()
);
export const searchClientsFail = createAction('[Client.ClientList] Search Clients Fail');

export const setClient = createAction(
  '[Client.ClientList] Set Client',
  props<{ payload: models.Client }>()
);
