import { Action, createFeatureSelector, combineReducers } from '@ngrx/store';

import * as formsInvoice from '../reducers/invoice';
import * as formsPlaneOperators from '../reducers/plane-operators';
import * as fromRoot from '../../../../reducers';

export interface State {
  forms: formsInvoice.State;
  planeOperators: formsPlaneOperators.State;
}

export const featureName = 'forms';

export const reducers = combineReducers<State, Action>({
  forms: formsInvoice.reducer,
  planeOperators: formsPlaneOperators.reducer,
});

export const getFormsState = createFeatureSelector<fromRoot.AppState, State>(featureName);
