import { Range } from '../range';

export class SeatPricingRule {
  timeIntervals: Array<Range>;

  constructor(obj: any) {
    this.timeIntervals = [];
    if (obj.hasOwnProperty('timeIntervals')) {
      this.timeIntervals = new Array<Range>();
      obj.timeIntervals.forEach((ti) => {
        const timeInterval = new Range();
        timeInterval.start = ti.length === 2 ? ti[0] : null;
        timeInterval.end = ti.length === 2 ? ti[1] : null;
        this.timeIntervals.push(timeInterval);
      });
    }
  }
}
