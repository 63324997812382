import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[One-Way Quote Collection] Load';
export const LOAD_SUCCESS = '[One-Way Quote Collection] Load Success';
// export const LOAD_FAIL = '[One-Way Quote Collection] Load Fail';
export const RESET = '[One-Way Quote Collection] Reset';

export class LoadPayload {
  criteria: viewModels.OneWayQuoteLookupCriteria;
  page: number;
  itemsPerPage: number;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  page: number;
  data: Array<models.OneWayQuote>;
  count: number;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class ResetAction implements Action {
  readonly type = RESET;
}

// export class LoadFailAction implements Action {
//   readonly type = LOAD_FAIL;
//
//   constructor() {
//   }
// }

export type Actions = LoadAction | LoadSuccessAction | ResetAction;
