import { Injectable, Injector, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as configServiceContracts from '../../../core/services/config/contracts';

@Injectable()
export class BaseHttp {

  constructor(
    @Inject(configServiceContracts.SERVICE_TOKEN) private configService: configServiceContracts.IConfigService,
    private http: HttpClient,
    private injector: Injector) {
  }

  public get router(): Router {
    return this.injector.get(Router);
  }

  delete(url: string, options?: any): any {
    return this.http.delete(url, options);
  }

  get(url: string, options?: any): any {
    return this.http.get(url, options);
  }

  post(url: string, body: any, options?: any): any {
    return this.http.post(url, body, options);
  }

  put(url: string, body: any, options?: any): any {
    return this.http.put(url, this.syncModel(body), options);
  }

  /** Update payload with all properties from raw data from GET request in case UI model is not
   * synced with BE model.
   */
  syncModel(modelData) {
    let syncedModelData = modelData;
    try {
      if (modelData) {
        let requestBody;
        if (typeof modelData === 'string') {
          requestBody = JSON.parse(modelData);
        } else {
          requestBody = modelData;
        }
        if (requestBody.rawInitialData) {
          const initialData = Object.assign({}, requestBody.rawInitialData);
          delete requestBody.rawInitialData;
          syncedModelData = Object.assign(initialData, requestBody);
        }
      }
    } catch (e) {
      return syncedModelData;
    }
    return syncedModelData;
  }
}
