import { OrderItemSerializable } from './order-item-serializable';

export class PromotionalCreditOrderItemSerializable extends OrderItemSerializable {
  promotionalCreditsAmount: number;
  productId: number = null;
  productProductTypeParentProductTypeId: number = null;
  productTypeId: number = null;
  productName: string = null;
  productAmount: number = null;
  modifiesTotal = false;
}
