export class ListItem {
  id: string;
  name: string;
  legalName: string;
  selected = false;
  active = true;

  get legalNameAndName(): string {
    return this.legalName 
      && this.name 
      && this.legalName == this.name 
        ? this.legalName
        : `${this.legalName} (${this.name})`
  }
}
