import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromLayout from '../../shared/reducers/layout.reducer';
import * as fromSystem from '../../shared/reducers/system.reducer';
import * as fromApiStatus from '../../shared/reducers/api-status.reducer';
import * as fromUser from './user.reducer';
import * as fromAirport from '../../shared/reducers/airport.reducer';

export const featureName = 'core';

export interface State {
  [fromLayout.layoutFeatureKey]: fromLayout.State;
  router: fromRouter.RouterReducerState;
  [fromSystem.systemFeatureKey]: fromSystem.State;
  [fromApiStatus.apiStatusFeatureKey]: fromApiStatus.State;
  [fromUser.userFeatureKey]: fromUser.State;
  [fromAirport.airportFeatureKey]: fromAirport.State;
}

export const reducers = combineReducers<State, Action>({
  [fromLayout.layoutFeatureKey]: fromLayout.reducer,
  router: fromRouter.routerReducer,
  [fromSystem.systemFeatureKey]: fromSystem.reducer,
  [fromApiStatus.apiStatusFeatureKey]: fromApiStatus.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromAirport.airportFeatureKey]: fromAirport.reducer,
});

export const getCoreState = createFeatureSelector<State>(featureName);
