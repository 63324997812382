import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/leg-source-type-collection.actions';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const legSourceTypeCollectionFeatureKey = 'legSourceTypeCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.LegSourceType>;
  filter: string;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  filter: 'op_shared_charter',
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      items: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(actions.updateLegSourceTypeSuccess, (state, { payload }): State => {
    const cloned = _.cloneDeep(state.items);
    const i = cloned.findIndex(
      ({ taskTypeToLegSourceId }) => payload.taskTypeToLegSourceId === taskTypeToLegSourceId
    );
    if (i === -1) {
      return {
        ...state,
      };
    }
    cloned[i] = payload;
    return {
      ...state,
      items: cloned,
    };
  }),

  on(actions.createLegSourceTypeSuccess, (state, { payload }): State => {
    const cloned = _.cloneDeep(state.items);
    cloned.unshift(payload);
    return {
      ...state,
      items: cloned,
    };
  }),

  on(
    actions.filterWorkflowItems,
    (state, { payload: { filterCriteria } }): State => ({
      ...state,
      filter: filterCriteria,
    })
  ),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState,
    })
  )
);
