export const TASK_LINK_CONFIG = [
  {
    value: 'Post Flight Report / Charter Post Check',
    link: 'https://www.tfaforms.com/4893197',
  },
  {
    value: 'Post Check',
    link: 'https://www.tfaforms.com/5034642',
  },
];

export enum ValidateLinks {
  postCheck = 'Post Check',
}
