import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Charter.QuoteAttributesCollection] Load');
export const loadSuccess = createAction(
  '[Charter.QuoteAttributesCollection] Load Success',
  props<{ payload: Array<models.QuoteAttribute> }>()
);
export const loadFail = createAction('[Charter.QuoteAttributesCollection] Load Fail');
