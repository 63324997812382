import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Marketplace.LastLookRequest] Load';
export const LOAD_SUCCESS = '[Marketplace.LastLookRequest] Load Success';
export const SEND_OFF_FLEET = '[Marketplace.LastLookRequest] Send Off Fleet';
export const SEND_OFF_FLEET_SUCCESS = '[Marketplace.LastLookRequest] Send Off Fleet Success';
export const TAKE_ON_FLEET = '[Marketplace.LastLookRequest] Take On Fleet';
export const TAKE_ON_FLEET_SUCCESS = '[Marketplace.LastLookRequest] Take On Fleet Success';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload: string) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: models.LastLookRequest) {}
}

export class SendOffFleetActionPayload {
  purchaseOfferId: string;
  priceType: models.PriceTypeEnum;
}

export class SendOffFleetAction implements Action {
  readonly type = SEND_OFF_FLEET;

  constructor(public payload: SendOffFleetActionPayload) {}
}

export class SendOffFleetSuccessAction implements Action {
  readonly type = SEND_OFF_FLEET_SUCCESS;
}

export class TakeOnFleetActionPayload {
  purchaseOfferId: string;
  aircraftId: number;
  additionalInstructions: string;
}

export class TakeOnFleetAction implements Action {
  readonly type = TAKE_ON_FLEET;

  constructor(public payload: TakeOnFleetActionPayload) {}
}

export class TakeOnFleetSuccessAction implements Action {
  readonly type = TAKE_ON_FLEET_SUCCESS;
}


export type Actions =
  | LoadAction
  | LoadSuccessAction;
