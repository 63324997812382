import { Component, Inject, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { menuItems as siteMap } from './app-menu';
import * as coreModels from '../../models';
import * as sharedModels from '../../../shared/models';
import * as userAction from '../../actions/user.actions';
import * as helpers from '../../helpers';
import * as layoutAction from '../../../shared/actions/layout.actions';
import * as apiStatusAction from '../../../shared/actions/api-status.actions';
import * as coreSelectors from '../../selectors';
import * as sharedSelectors from '../../../shared/selectors';
import { INotificationService, NOTIFICATION_SERVICE_TOKEN } from '../../../shared/types';
import { IGitEnvVariablesService, GIT_ENV_VARIABLES_SERVICE_TOKEN } from '../../../shared/services';
import {
  DefaultLayoutComponent as SharedDefaultLayoutComponent,
  MenuItem,
} from '../../../shared/components/';
import { pagesToggleService } from '../../../../assets/pages/services/toggler.service';

import { Observable, combineLatest } from 'rxjs';
import { filter, first, map, tap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from "@auth0/auth0-angular";

declare let pg: any;

@Component({
  selector: 'default-layout',
  styleUrls: ['./default-layout.component.scss'],
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent extends SharedDefaultLayoutComponent {
  user$: Observable<coreModels.User>;
  userName$: Observable<string>;
  logoutUrl$: Observable<string>;
  activeRoute$: Observable<string>;
  menuItems$: Observable<Array<MenuItem>>;
  isDebug$: Observable<boolean>;
  accessDenied$: Observable<boolean>;
  menuPinThreeWay$: Observable<sharedModels.MenuPin>;
  secondaryToggle = false;
  environmentName$: Observable<string>;
  status$: Observable<sharedModels.ApiStatus>;
  isStatusLoading$: Observable<boolean>;
  gitEnvVariables$: Observable<sharedModels.GitEnvVariables>;

  // ToDo Remove after implementing auth0 authorization in production
  helpers = helpers;
  auth: AuthService;

  get isLoginPage() {
    return document.location.pathname === '/login';
  }

  constructor(
    store$: Store<any>,
    toggler: pagesToggleService,
    router: Router,
    @Inject(NOTIFICATION_SERVICE_TOKEN) notificationService: INotificationService,
    @Inject(GIT_ENV_VARIABLES_SERVICE_TOKEN)
    private gitEnvVariablesService: IGitEnvVariablesService,
    private injector: Injector) {
    super(store$, router, toggler);

    // ToDo Update after implementing auth0 authorization in production
    if (!helpers.isLegacyAuth()) {
      this.auth = this.injector.get(AuthService);
    }

    this.menuPinThreeWay$ = this.store$.pipe(
      select(sharedSelectors.getLayoutMenuPin),
      tap((pin) => {
        if (pin === 0) {
          if (pg.hasClass(document.body, 'menu-pin')) {
            pg.removeClass(document.body, 'menu-pin');
          }
          if (pg.hasClass(document.body, 'menu-pin-sm')) {
            pg.removeClass(document.body, 'menu-pin-sm');
          }
        } else if (pin === 1) {
          if (pg.hasClass(document.body, 'menu-pin') === false) {
            pg.addClass(document.body, 'menu-pin');
          }
          if (pg.hasClass(document.body, 'menu-pin-sm')) {
            pg.removeClass(document.body, 'menu-pin-sm');
          }
        } else {
          if (pg.hasClass(document.body, 'menu-pin') === false) {
            pg.addClass(document.body, 'menu-pin');
          }
          if (pg.hasClass(document.body, 'menu-pin-sm') === false) {
            pg.addClass(document.body, 'menu-pin-sm');
          }
        }
      })
    );

    this.isDebug$ = this.store$.pipe(select(sharedSelectors.getSystemIsDebug));
    this.accessDenied$ = this.store$.pipe(select(coreSelectors.getUserUserIsAccessDenied));

    this.setNotificationService(notificationService);

    // Override
    this.isFullHeightView$ = combineLatest([
      this.store$.pipe(select(sharedSelectors.getLayoutState)),
      this.store$.pipe(select(coreSelectors.getUserUserIsAccessDenied)),
      this.store$.pipe(select(sharedSelectors.getSystemState)),
    ]).pipe(
      map(([layoutState, userAccessDenied, systemState]) => {
        return (
          layoutState.isAppView ||
          layoutState.isLoading ||
          layoutState.isFullHeightView ||
          layoutState.isError ||
          userAccessDenied ||
          systemState.browserSupported === false
        );
      })
    );

    // Override
    this.outletIsVisible$ = combineLatest([
      this.store$.pipe(select(sharedSelectors.getLayoutState)),
      this.store$.pipe(select(coreSelectors.getUserUserIsAccessDenied)),
      this.store$.pipe(select(sharedSelectors.getSystemState)),
    ]).pipe(
      map(([layoutState, userAccessDenied, systemState]) => {
        return (
          layoutState.isLoading === false &&
          layoutState.isError === false &&
          userAccessDenied === false &&
          systemState.browserSupported
        );
      })
    );

    this.user$ = this.store$.pipe(select(coreSelectors.getUserUser));

    this.userName$ = this.user$.pipe(
      map((user: coreModels.User) => {
        if (user === null) {
          return '';
        }

        return `${user.firstName} ${user.lastName}`;
      })
    );

    this.logoutUrl$ = this.user$.pipe(
      map((user: coreModels.User) => {
        if (user === null) {
          return '';
        }

        return document.location.origin + user.logoutUrl;
      })
    );

    this.activeRoute$ = this.store$.pipe(select(coreSelectors.getRouterPath));

    this.menuItems$ = combineLatest([
      this.store$.pipe(select(coreSelectors.getUserUser)),
      this.store$.pipe(select(sharedSelectors.getSystemIsDebug)),
    ]).pipe(
      filter(([user]) => !!user),
      map(([user, isDebugMode]) => {
        if (
          user.organization.id === 1 ||
          user.organization.id === 8 ||
          user.organization.id === 28 ||
          user.organization.id === 35 ||
          user.organization.id === 213
        ) {
          return _.cloneDeep(siteMap).filter((smi) =>
            this.menuItemFilter(smi, isDebugMode, user.viewPermissions)
          );
        }

        return [];
      })
    );
    this.environmentName$ = this.store$.pipe(
      select(sharedSelectors.getLayoutServerEnvironment),
      map((env) => (env === 'prod' ? '' : 'Develop'))
    );

    this.status$ = this.store$.pipe(select(sharedSelectors.getApiStatus));
    this.isStatusLoading$ = this.store$.pipe(select(sharedSelectors.getApiStatusIsLoading));
    this.gitEnvVariables$ = this.gitEnvVariablesService.getGitEnvVariables();

    if (!this.isLoginPage) {
      const menuPin = Number(localStorage.getItem('menuPin')) || 0; 
      this.setLayoutMenuPin(menuPin);
      router.events.pipe(
        filter((event) => event instanceof NavigationEnd && event.url === '/login'),
        take(1)
      )
      .subscribe(() => {
        this.setLayoutMenuPin(0);
      })
    }
  }

  // Override
  async toggleMenuPin() {
    if (pg.isVisibleSm()) {
      this.setLayoutMenuPin(sharedModels.MenuPin.CLOSED);
      return;
    }
    let menuPin = await this.menuPinThreeWay$.pipe(first()).toPromise();
    menuPin = (menuPin + 1) % 3;
    localStorage.setItem('menuPin', menuPin.toString());
    this.setLayoutMenuPin(menuPin);
  }

  setLayoutMenuPin(pin: sharedModels.MenuPin): void {
    this.store$.dispatch(layoutAction.setMenuPin({ payload: pin }));
    this.store$.dispatch(userAction.setMenuPinPreference({ payload: pin }));
  }

  menuItemFilter(mi: MenuItem, isDebug: boolean, viewPermissions: coreModels.Permissions): boolean {
    if (_.get(mi, 'submenu', false)) {
      mi.submenu = mi.submenu.filter((sm) => this.menuItemFilter(sm, isDebug, viewPermissions));
    }

    let dontRemove = false;

    // If Menu Item doesnt have viewPermission Setting
    // Then check if Debug
    // If Menu Item has viewPermission Setting
    // Then check if the viewPermission is true before checking for Debug mode only
    if (!_.get(mi, 'viewPermission', false)) {
      dontRemove = true;

      if (_.get(mi, 'debugOnly', false)) {
        // Is for Debug only
        dontRemove = isDebug === true;
      }
    } else if (_.get(viewPermissions, mi.viewPermission, false)) {
      dontRemove = true;

      if (_.get(mi, 'debugOnly', false)) {
        // Is for Debug only
        dontRemove = isDebug === true;
      } else {
        dontRemove = true;
      }
    }

    return dontRemove;
  }

  appSwitcher(e: coreModels.Application): void {
    switch (e) {
      case coreModels.Application.JetStudio:
        this.secondaryToggle = false;
        break;
      case coreModels.Application.Secondary:
        this.secondaryToggle = true;
        break;
      default:
        break;
    }
  }

  onApiStatusExpand(apiName: string): void {
    this.store$.dispatch(apiStatusAction.loadApiStatus({ payload: apiName }));
  }
}
