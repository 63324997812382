export class Attribute {
  id: number;
  name: string;
  selected?: boolean;

  getId(): number {
    return this.id;
  }

  setId(id: number): Attribute {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Attribute {
    this.name = name;

    return this;
  }
}
