import * as actions from '../actions/last-look-request';
import * as _ from 'lodash';
import * as models from '../../domain/models';

export interface State {
  lastLookRequest: models.LastLookRequest;
  loaded: boolean;
  loading: boolean;
}

const initialState: State = {
  lastLookRequest: null,
  loaded: false,
  loading: false,

};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        lastLookRequest: action.payload,
        loaded: true,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getLastLookRequest = (state: State) => state.lastLookRequest;
