export class Operator {
  id: string = null;
  name: string = null;
  legalName: string = null;


  getOperatorId(): string {
    return this.id;
  }

  setOperatorId(id: string): Operator {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Operator {
    this.name = name;

    return this;
  }

  getLegalName(): string {
    return this.legalName;
  }

  setLegalName(legalName: string): Operator {
    this.legalName = legalName;

    return this;
  }

  getLegalNameAndName(): string {
    return this.legalName 
      && this.name 
      && this.legalName == this.name 
        ? this.legalName
        : `${this.legalName} (${this.name})`
  }

  equals(o1: Operator): boolean {
    if (!o1) {
      return false;
    }

    return this.id === o1.id;
  }
}
