import * as actions from '../actions/ramen-filters';
import * as models from '../../domain/models';

export interface State {
  filterCriteria: models.RmnFilterCriteria;
}

const initialState: State = {
  filterCriteria: new models.RmnFilterCriteria(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.SET_FILTER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actions.RESET_FILTER: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}

export const getFilters = (state: State) => state.filterCriteria;
