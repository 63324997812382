import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const DEACTIVATE = '[YieldMgmt.LegPricing] Deactivate';
export const DEACTIVATE_SUCCESS = '[YieldMgmt.LegPricing] Deactivate Success';
export const LOAD = '[YieldMgmt.LegPricing] Load';
export const LOAD_SUCCESS = '[YieldMgmt.LegPricing] Load Success';
export const LOAD_FAIL = '[YieldMgmt.LegPricing] Load Fail';
export const LOAD_AUDIT_LOG = '[YieldMgmt.LegPricing] Load Audit Log';
export const LOAD_AUDIT_LOG_SUCCESS = '[YieldMgmt.LegPricing] Load Audit Log Success';
export const LOAD_AUDIT_LOG_FAIL = '[YieldMgmt.LegPricing] Load Audit Log Fail';
export const LOAD_MATRIX = '[YieldMgmt.LegPricing] Load Matrix';
export const LOAD_MATRIX_SUCCESS = '[YieldMgmt.LegPricing] Load Matrix Success';
export const LOAD_MATRIX_FAIL = '[YieldMgmt.LegPricing] Load Matrix Fail';
export const UPDATE = '[YieldMgmt.LegPricing] Update';
export const UPDATE_SUCCESS = '[YieldMgmt.LegPricing] Update Success';
export const UPDATE_FAIL = '[YieldMgmt.LegPricing] Update Fail';
export const RESET = '[YieldMgmt.LegPricing] Reset';
export const NO_ACTION = '[YieldMgmt.LegPricing] No Action';

export const OPERATOR_SHARED_CHARTER_LOAD = '[YieldMgmt.LegPricing] Shared Charter Load Price';
export const OPERATOR_SHARED_CHARTER_LOAD_SUCCESS =
  '[YieldMgmt.LegPricing] Shared Charter Load Success';
export const OPERATOR_SHARED_CHARTER_LOAD_FAIL = '[YieldMgmt.LegPricing] Shared Charter Load Fail';
export const OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG =
  '[YieldMgmt.LegPricing] Shared Charter Load Audit Log';
export const OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG_SUCCESS =
  '[YieldMgmt.LegPricing] Shared Charter Load Audit Log Success';
export const OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG_FAIL =
  '[YieldMgmt.LegPricing] Shared Charter Load Audit Log Fail';
export const OPERATOR_SHARED_CHARTER_UPDATE = '[YieldMgmt.LegPricing] Shared Charter Update';
export const OPERATOR_SHARED_CHARTER_UPDATE_SUCCESS =
  '[YieldMgmt.LegPricing] Shared Charter Update Success';
export const OPERATOR_SHARED_CHARTER_UPDATE_FAIL =
  '[YieldMgmt.LegPricing] Shared Charter Update Fail';

// Update reasons:
export const LOAD_UPDATE_REASONS = '[YieldMgmt.LegPricing] Load update reasons';
export const LOAD_UPDATE_REASONS_SUCCESS = '[YieldMgmt.LegPricing] Load Success update reasons';
export const LOAD_UPDATE_REASONS_FAIL = '[YieldMgmt.LegPricing] Load Fail update reasons';

export class NoAction implements Action {
  readonly type = NO_ACTION;
}

export class LoadUpdateReasonsAction implements Action {
  readonly type = LOAD_UPDATE_REASONS;
}

export class LoadUpdateReasonsSuccessPayload {
  constructor(public entities: Array<models.PriceUpdateReason>) {}
}

export class LoadUpdateReasonsSuccessAction implements Action {
  readonly type = LOAD_UPDATE_REASONS_SUCCESS;

  constructor(public payload: LoadUpdateReasonsSuccessPayload) {}
}

export class LoadUpdateReasonsFailAction implements Action {
  readonly type = LOAD_UPDATE_REASONS_FAIL;
}

export class DeactivateAction implements Action {
  readonly type = DEACTIVATE;

  constructor(public payload: models.Leg) {}
}

export class DeactivateSuccessAction implements Action {
  readonly type = DEACTIVATE_SUCCESS;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: models.Leg) {}
}

export class LoadSuccessPayload {
  constructor(
    public leg: models.Leg,
    public legPrice: models.Price,
    public lastUpdatedUser: string,
    public lastUpdatedDate: Date
  ) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class LoadAuditLogAction implements Action {
  readonly type = LOAD_AUDIT_LOG;

  constructor(public payload: models.Leg) {}
}

export class LoadAuditLogSuccessPayload {
  constructor(public leg: models.Leg, public auditLog: Array<models.LegPriceAudit>) {}
}

export class LoadAuditLogSuccessAction {
  readonly type = LOAD_AUDIT_LOG_SUCCESS;

  constructor(public payload: LoadAuditLogSuccessPayload) {}
}

export class LoadAuditLogFailAction {
  readonly type = LOAD_AUDIT_LOG_FAIL;
}

export class LoadMatrixPayload {
  constructor(public id: number) {}
}

export class LoadMatrixAction implements Action {
  readonly type = LOAD_MATRIX;

  constructor(public payload: LoadMatrixPayload) {}
}

export class LoadMatrixSuccessPayload {
  constructor(public matrix: models.MatrixData) {}
}

export class LoadMatrixSuccessAction {
  readonly type = LOAD_MATRIX_SUCCESS;

  constructor(public payload: LoadMatrixSuccessPayload) {}
}

export class LoadMatrixFailAction {
  readonly type = LOAD_MATRIX_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export class UpdatePayload {
  constructor(
    public availableSeatNewPrice: number,
    public availableSeatOldPrice: number,
    public leg: models.Leg,
    public legPrice: models.Price,
    public emptySeats: number
  ) {}
}

export class UpdateAction implements Action {
  readonly type = UPDATE;

  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccessPayload {
  constructor(
    public leg: models.Leg,
    public legPrice: models.Price,
    public lastUpdatedUser: string,
    public lastUpdatedDate: Date
  ) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: UpdateSuccessPayload) {}
}

export class UpdateFailAction implements Action {
  readonly type = UPDATE_FAIL;
}

//
// Shared Charter Leg Pricing
//

export class OperatorSharedCharterLoadAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD;

  constructor(public payload: models.Leg) {}
}

export class OperatorSharedCharterLoadSuccessPayload {
  constructor(public leg: models.Leg, public legPrice: models.Price) {}
}

export class OperatorSharedCharterLoadSuccessAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD_SUCCESS;

  constructor(public payload: OperatorSharedCharterLoadSuccessPayload) {}
}

export class OperatorSharedCharterLoadFailAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD_FAIL;
}

export class OperatorSharedCharterLoadAuditLogAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG;

  constructor(public payload: models.Leg) {}
}

export class OperatorSharedCharterLoadAuditLogSuccessPayload {
  constructor(public leg: models.Leg, public auditLog: Array<models.LegPriceAudit>) {}
}

export class OperatorSharedCharterLoadAuditLogSuccessAction {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG_SUCCESS;

  constructor(public payload: OperatorSharedCharterLoadAuditLogSuccessPayload) {}
}

export class OperatorSharedCharterLoadAuditLogFailAction {
  readonly type = OPERATOR_SHARED_CHARTER_LOAD_AUDIT_LOG_FAIL;
}

export class OperatorSharedCharterUpdatePayload {
  constructor(public leg: models.Leg, public legPrice: models.Price) {}
}

export class OperatorSharedCharterUpdateAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_UPDATE;

  constructor(public payload: OperatorSharedCharterUpdatePayload) {}
}

export class OperatorSharedCharterUpdateSuccessPayload {
  constructor(public leg: models.Leg, public legPrice: models.Price) {}
}

export class OperatorSharedCharterUpdateSuccessAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_UPDATE_SUCCESS;

  constructor(public payload: OperatorSharedCharterUpdateSuccessPayload) {}
}

export class OperatorSharedCharterUpdateFailAction implements Action {
  readonly type = OPERATOR_SHARED_CHARTER_UPDATE_FAIL;
}

export type Actions =
  | NoAction
  | DeactivateAction
  | DeactivateSuccessAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadAuditLogAction
  | LoadAuditLogSuccessAction
  | LoadAuditLogFailAction
  | LoadUpdateReasonsAction
  | LoadUpdateReasonsSuccessAction
  | LoadUpdateReasonsFailAction
  | LoadMatrixAction
  | LoadMatrixSuccessAction
  | LoadMatrixFailAction
  | ResetAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateFailAction
  | OperatorSharedCharterLoadAction
  | OperatorSharedCharterLoadSuccessAction
  | OperatorSharedCharterLoadFailAction
  | OperatorSharedCharterLoadAuditLogAction
  | OperatorSharedCharterLoadAuditLogSuccessAction
  | OperatorSharedCharterLoadAuditLogFailAction
  | OperatorSharedCharterUpdateAction
  | OperatorSharedCharterUpdateSuccessAction
  | OperatorSharedCharterUpdateFailAction;
