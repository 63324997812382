import { User } from './user';
import { LegHistoryInfo } from './leg-history-info';
import { Aircraft } from './aircraft';
import { OfferStatusEnum } from '.';
import { CurrencyEnum } from '../../../../shared/models';

export class BidHistoryItem {
  offerPrice: number = null;
  legs: Array<LegHistoryInfo> = null;
  acceptedDate: Date = null;
  acceptedByUser: User = null;
  flightPrice: number = null;
  aircraft: Aircraft = null;
  quoteReferenceId?: string;
  status?: OfferStatusEnum;
  currency: CurrencyEnum = null;
  offerPriceInCurrency: number = null;
  currencyUpdateTime?: Date = null;
  currencyRate?: number = null;

  getOfferPrice(): number {
    return this.offerPrice;
  }

  setOfferPrice(maxPrice: number): BidHistoryItem {
    this.offerPrice = maxPrice;
    return this;
  }

  getLegs(): Array<LegHistoryInfo> {
    return this.legs;
  }

  setLegs(legs: Array<LegHistoryInfo>): BidHistoryItem {
    this.legs = legs;
    return this;
  }

  getAcceptedDate(): Date {
    return this.acceptedDate;
  }

  setAcceptedDate(acceptedDate: Date): BidHistoryItem {
    this.acceptedDate = acceptedDate;
    return this;
  }

  getAcceptedByUser(): User {
    return this.acceptedByUser;
  }

  setAcceptedByUser(acceptedByUser: User): BidHistoryItem {
    this.acceptedByUser = acceptedByUser;
    return this;
  }

  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): BidHistoryItem {
    this.flightPrice = flightPrice;
    return this;
  }

  getAircraft(): Aircraft {
    return this.aircraft;
  }

  setAircraft(aircraft: Aircraft): BidHistoryItem {
    this.aircraft = aircraft;
    return this;
  }

  getQuoteReferenceId(): string {
    return this.quoteReferenceId;
  }

  setQuoteReferenceId(quoteReferenceId: string): BidHistoryItem {
    this.quoteReferenceId = quoteReferenceId;
    return this;
  }

  getStatus(): OfferStatusEnum {
    return this.status;
  }

  setStatus(status: OfferStatusEnum): BidHistoryItem {
    this.status = status;

    return this;
  }

  getOfferPriceInCurrency(): number {
    return this.offerPriceInCurrency;
  }

  setOfferPriceInCurrency(price: number): BidHistoryItem {
    this.offerPriceInCurrency = price;
    return this;
  }

  getCurrency(): CurrencyEnum {
    return this.currency;
  }

  setCurrency(currency: CurrencyEnum): BidHistoryItem {
    this.currency = currency;
    return this;
  }

  setCurrencyUpdateTime(updateDate: Date): BidHistoryItem {
    this.currencyUpdateTime = updateDate;
    return this;
  }

  getCurrencyUpdateTime(): Date {
    return this.currencyUpdateTime;
  }

  getCurrencyRate(): number {
    return this.currencyRate;
  }

  setCurrencyRate(rate: number): BidHistoryItem {
    this.currencyRate = rate;
    return this;
  }
}
