import { createSelector } from 'reselect';

import * as fromCore from '../../core/reducers';

import { apiStatusFeatureKey } from '../reducers/api-status.reducer';

export const getApiStatusState = createSelector(
  fromCore.getCoreState,
  (state) => state[apiStatusFeatureKey]
);
export const getApiStatus = createSelector(getApiStatusState, (state) => state.apiStatus);
export const getApiStatusIsLoading = createSelector(
  getApiStatusState,
  (state) => state.apiStatusLoading
);
export const getApiStatusIsLoaded = createSelector(
  getApiStatusState,
  (state) => state.apiStatusLoaded
);
