import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';
import * as queries from '../../domain/queries';

export const reset = createAction('[Charter.OpportunityList] Reset');

export class SearchPayload {
  criteria: queries.OpportunityCriteria;
  page: number;
  itemsPerPage: number;
  salesAgentId: string;

  constructor(criteria: queries.OpportunityCriteria, page: number, itemsPerPage: number) {
    this.criteria = new queries.OpportunityCriteria();

    if (criteria) {
      this.criteria = criteria;
    }

    this.page = page;
    this.itemsPerPage = itemsPerPage;
  }
}
export const search = createAction(
  '[Charter.OpportunityList] Search',
  props<{ payload: SearchPayload }>()
);
export class SearchSuccessPayload {
  page: number;
  data: Array<models.Opportunity>;
  count: number;
}
export const searchSuccess = createAction(
  '[Charter.OpportunityList] Search Success',
  props<{ payload: SearchSuccessPayload }>()
);
export const searchFail = createAction('[Charter.OpportunityList] Search Fail');
