import * as moment from 'moment';

export class SchedulerOptions {
  type = 'monthly';
  startDate: moment.Moment = moment().add(3, 'days');
  eventWidth = '255px';
  eventHeight = '160px';
  resourcesName: Array<string> = ['aircraftCategoryName', 'membershipTierName'];
  resourceIdKeyName: Array<string> = ['aircraftCategoryId', 'membershipTypeId'];
  columnKey = 'departureStartTime';
  sortEventsByKey = 'minTotalPrice';
}
