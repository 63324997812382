/* eslint-disable max-lines */
/* eslint-disable max-len */
import * as models from '../../../domain/models';

export const response = {
  message: 'OK',
  code: 200,
  data: {
    priceComponents: [
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 1,
        securityAccess: 1,
        percentage: null,
        amount: '1000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:15:43.265827+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 3,
        securityAccess: 1,
        percentage: null,
        amount: '1500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:17:48.125363+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 4,
        securityAccess: 1,
        percentage: null,
        amount: '2000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:17:59.825967+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 5,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:18:16.673456+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 6,
        securityAccess: 1,
        percentage: null,
        amount: '500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:19:36.796152+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 7,
        securityAccess: 1,
        percentage: null,
        amount: '1000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:19:50.029763+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 8,
        securityAccess: 1,
        percentage: null,
        amount: '1500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:19:57.838626+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 9,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:20:06.647987+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 10,
        securityAccess: 1,
        percentage: null,
        amount: '500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:21:03.398078+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 11,
        securityAccess: 1,
        percentage: null,
        amount: '1000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:21:12.350468+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 12,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:21:27.424469+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 16,
        securityAccess: 1,
        percentage: null,
        amount: '500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:23:45.943139+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 7,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 18,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:24:26.129688+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 7,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 19,
        securityAccess: 1,
        percentage: null,
        amount: '500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:24:39.308820+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 8,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 20,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2017-08-16T17:24:46.221494+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 8,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 21,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-18T19:43:41.617520+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 22,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-18T19:44:43.376069+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 23,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-18T19:44:56.481481+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 25,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:17:23.242677+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 30,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 26,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:17:46.045429+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 31,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 27,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:17:51.430945+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 32,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 28,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:00.421615+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 33,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 30,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:15.051366+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 35,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 31,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:20.188605+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 36,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 32,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:25.543061+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 37,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 33,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:31.463460+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 38,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 34,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:41.005021+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 39,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 35,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:49.429446+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 40,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 36,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:54.049675+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 41,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 37,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:18:59.619593+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 42,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 38,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-19T15:19:06.708110+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 43,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 40,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:36:06.730564+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 41,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:42:11.217473+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 30,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 42,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:43:25.176557+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 31,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 43,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:43:38.887925+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 32,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 44,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:00.720680+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 33,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 46,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:12.646862+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 35,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 47,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:15.800161+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 36,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 48,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:17.767516+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 37,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 49,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:19.702960+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 38,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 50,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:22.287113+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 39,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 51,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:31.211635+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 40,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 52,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:34.281468+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 41,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 53,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:37.300072+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 42,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 54,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-19T20:44:39.134875+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 43,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 55,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-20T04:33:54.194610+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 56,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-20T04:34:07.608698+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 58,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-26T18:39:04.527821+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 44,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 59,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-26T18:40:27.415850+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 44,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 60,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2017-10-26T19:09:32.147452+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 45,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 61,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2017-10-26T19:10:12.210254+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 45,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 63,
        securityAccess: 1,
        percentage: null,
        amount: '1500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-02-16T19:36:41.223040+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 64,
        securityAccess: 1,
        percentage: null,
        amount: '5000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-02-16T20:02:30.065791+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 68,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-03-14T17:42:14.146701+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 3, 13, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 53,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 69,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (3-6 months)',
        createTime: '2018-03-14T21:17:08.981690+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 58,
      },
      {
        priceComponentTypeId: 5,
        description: null,
        priceComponentTypeName: 'Early Renewal Discount',
        priceComponentId: 70,
        securityAccess: 1,
        percentage: '10',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Early Renewal Discount (6+ months)',
        createTime: '2018-03-14T21:17:42.882646+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 58,
      },
      {
        priceComponentTypeId: 1,
        description: 'Membership Initiation Fee Discount',
        priceComponentTypeName: 'Discount',
        priceComponentId: 84,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Membership Initiation Fee Discount',
        createTime: '2018-04-02T04:53:29.351240+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 62,
      },
      {
        priceComponentTypeId: 1,
        description: 'Customer Service',
        priceComponentTypeName: 'Discount',
        priceComponentId: 86,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Customer Service',
        createTime: '2018-04-04T18:58:25.241362+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 59,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 104,
        securityAccess: 1,
        percentage: null,
        amount: '4000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-04-25T16:35:57.376161+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 71,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 105,
        securityAccess: 1,
        percentage: null,
        amount: '12000',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-04-25T16:36:38.135427+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 72,
      },
      {
        priceComponentTypeId: 2,
        description: null,
        priceComponentTypeName: 'Surcharge',
        priceComponentId: 108,
        securityAccess: 1,
        percentage: null,
        amount: '2050',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Surcharge',
        createTime: '2018-05-01T15:33:04.847191+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 64,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 111,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-05-03T17:10:16.883913+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 55,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 114,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'JetSmarter Partnership',
        createTime: '2018-05-08T17:18:14.406738+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 115,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Investor Discount',
        createTime: '2018-05-10T16:05:25.604915+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 116,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-05-10T16:17:37.754432+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 13,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 117,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-05-10T16:19:50.830868+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 77,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 118,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Prepaid Fee Discount',
        createTime: '2018-05-10T20:53:20.338935+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 119,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-05-11T14:33:55.253767+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 74,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 120,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-05-11T14:34:25.560148+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 75,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 122,
        securityAccess: 1,
        percentage: null,
        amount: '450',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Group Extra Seats Discount (>10)',
        createTime: '2018-05-16T21:34:08.186584+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 66,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 123,
        securityAccess: 1,
        percentage: null,
        amount: '960',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Group Extra Seats Discount (>20)',
        createTime: '2018-05-16T21:34:37.897740+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 66,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 124,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Full Discount',
        createTime: '2018-05-17T19:49:27.239697+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 79,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 143,
        securityAccess: 1,
        percentage: null,
        amount: '5300',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-07-18T20:52:33.598828+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 95,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 169,
        securityAccess: 1,
        percentage: null,
        amount: '1500',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'FC applied',
        createTime: '2018-08-17T20:47:23.808398+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 102,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 175,
        securityAccess: 1,
        percentage: '30',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Discount',
        createTime: '2018-08-27T16:06:49.561783+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 64,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 180,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'FC applied',
        createTime: '2018-08-29T15:44:01.427983+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 105,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 186,
        securityAccess: 1,
        percentage: null,
        amount: '2888.63',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Prorate',
        createTime: '2018-09-04T20:35:26.482948+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 98,
      },
      {
        priceComponentTypeId: 2,
        description: null,
        priceComponentTypeName: 'Surcharge',
        priceComponentId: 189,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Administrative Fee',
        createTime: '2018-10-01T13:47:06.136755+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 106,
      },
      {
        priceComponentTypeId: 2,
        description: null,
        priceComponentTypeName: 'Surcharge',
        priceComponentId: 190,
        securityAccess: 1,
        percentage: '5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Administrative Fee',
        createTime: '2018-10-01T13:47:26.959527+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 107,
      },
      {
        priceComponentTypeId: 2,
        description: null,
        priceComponentTypeName: 'Surcharge',
        priceComponentId: 227,
        securityAccess: 1,
        percentage: '3.5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Administrative Fee',
        createTime: '2018-10-01T13:47:26.959527+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 172,
      },
      {
        priceComponentTypeId: 2,
        description: null,
        priceComponentTypeName: 'Surcharge',
        priceComponentId: 228,
        securityAccess: 1,
        percentage: '3.5',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Administrative Fee',
        createTime: '2018-10-01T13:47:06.136755+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 173,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 229,
        securityAccess: 1,
        percentage: '100',
        amount: null,
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Promotional Discount',
        createTime: '2018-10-03T17:37:35.667012+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 153,
      },
      {
        priceComponentTypeId: 1,
        description: null,
        priceComponentTypeName: 'Discount',
        priceComponentId: 230,
        securityAccess: 1,
        percentage: null,
        amount: '5000.01',
        productTypeId: null,
        partyId: null,
        active: true,
        productFeatureId: null,
        priceComponentName: 'Promotional Discount Flex',
        createTime: '2018-10-03T17:37:35.667012+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 153,
      },
    ],
    productPriceComponentBrackets: [],
    consoleLoginCheck: {
      url: 'http://jetsm.com:3011/c_getdata',
      payload: {
        filter: {
          clients: {
            id: -1,
          },
          boot: true,
        },
      },
    },
    productDependencies: [
      {
        childProductId: 12,
        membershipProductDependencyId: 1,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 3,
        createTime: '2018-02-16T19:54:01.838770+00:00',
      },
      {
        childProductId: 47,
        membershipProductDependencyId: 2,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 3,
        createTime: '2018-02-16T19:54:32.528841+00:00',
      },
      {
        childProductId: 48,
        membershipProductDependencyId: 3,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-02-16T19:56:01.338913+00:00',
      },
      {
        childProductId: 49,
        membershipProductDependencyId: 4,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-02-16T19:56:17.502884+00:00',
      },
      {
        childProductId: 50,
        membershipProductDependencyId: 5,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 3,
        createTime: '2018-02-16T20:07:26.941549+00:00',
      },
      {
        childProductId: 51,
        membershipProductDependencyId: 6,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 1,
        createTime: '2018-02-16T20:08:38.344090+00:00',
      },
      {
        childProductId: 51,
        membershipProductDependencyId: 7,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 2,
        createTime: '2018-02-16T20:10:12.572563+00:00',
      },
      {
        childProductId: 52,
        membershipProductDependencyId: 10,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 2,
        createTime: '2018-02-16T20:53:57.583237+00:00',
      },
      {
        childProductId: 57,
        membershipProductDependencyId: 13,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-03-14T17:36:07.368328+00:00',
      },
      {
        childProductId: 57,
        membershipProductDependencyId: 14,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-03-14T17:36:30.318307+00:00',
      },
      {
        childProductId: 49,
        membershipProductDependencyId: 15,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-03-14T17:40:58.732513+00:00',
      },
      {
        childProductId: 62,
        membershipProductDependencyId: 19,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 59,
        createTime: '2018-04-02T04:56:11.332218+00:00',
      },
      {
        childProductId: 52,
        membershipProductDependencyId: 23,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 2,
        createTime: '2018-04-02T21:45:51.242632+00:00',
      },
      {
        childProductId: 48,
        membershipProductDependencyId: 24,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-02T21:53:11.065264+00:00',
      },
      {
        childProductId: 62,
        membershipProductDependencyId: 25,
        membershipSalesActionId: 3,
        required: true,
        active: true,
        parentProductId: 59,
        createTime: '2018-04-04T22:17:40.021987+00:00',
      },
      {
        childProductId: 65,
        membershipProductDependencyId: 26,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 64,
        createTime: '2018-04-10T18:09:25.082360+00:00',
      },
      {
        childProductId: 66,
        membershipProductDependencyId: 27,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 64,
        createTime: '2018-04-10T20:17:38.889630+00:00',
      },
      {
        childProductId: 68,
        membershipProductDependencyId: 28,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 67,
        createTime: '2018-04-12T04:17:41.364843+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 29,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 2,
        createTime: '2018-04-13T20:06:14.123744+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 30,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 2,
        createTime: '2018-04-13T20:06:36.380170+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 31,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:40:52.833119+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 32,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:41:07.778758+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 33,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:41:17.447369+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 34,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:41:31.409109+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 35,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:41:47.154746+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 36,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:42:00.494857+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 37,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:42:20.057900+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 38,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:42:34.736922+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 39,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T16:42:44.842361+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 40,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:42:58.601491+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 41,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:43:10.682777+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 42,
        membershipSalesActionId: 3,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T16:43:23.107907+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 43,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T17:10:49.203709+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 44,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T17:11:12.768442+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 45,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 1,
        createTime: '2018-04-25T17:11:21.466068+00:00',
      },
      {
        childProductId: 53,
        membershipProductDependencyId: 46,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T17:11:46.366316+00:00',
      },
      {
        childProductId: 71,
        membershipProductDependencyId: 47,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T17:11:56.126289+00:00',
      },
      {
        childProductId: 72,
        membershipProductDependencyId: 48,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 56,
        createTime: '2018-04-25T17:12:05.134096+00:00',
      },
      {
        childProductId: 85,
        membershipProductDependencyId: 55,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 64,
        createTime: '2018-06-10T17:59:33.335976+00:00',
      },
      {
        childProductId: 65,
        membershipProductDependencyId: 56,
        membershipSalesActionId: 1,
        required: true,
        active: true,
        parentProductId: 95,
        createTime: '2018-06-28T17:01:34.074009+00:00',
      },
      {
        childProductId: 112,
        membershipProductDependencyId: 58,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 102,
        createTime: '2018-09-17T19:31:41.435306+00:00',
      },
      {
        childProductId: 113,
        membershipProductDependencyId: 59,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 103,
        createTime: '2018-09-17T19:32:33.111181+00:00',
      },
      {
        childProductId: 113,
        membershipProductDependencyId: 60,
        membershipSalesActionId: 2,
        required: false,
        active: true,
        parentProductId: 95,
        createTime: '2018-09-17T19:32:58.588350+00:00',
      },
      {
        childProductId: 85,
        membershipProductDependencyId: 61,
        membershipSalesActionId: 1,
        required: false,
        active: true,
        parentProductId: 59,
        createTime: '2018-09-19T21:16:19.078603+00:00',
      },
    ],
    productFeatures: [
      {
        productFeatureId: 1,
        value: '60000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2017-09-19T14:34:59.573267+00:00',
        productId: 9,
      },
      {
        productFeatureId: 2,
        value: '20000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2017-09-19T14:35:24.263885+00:00',
        productId: 10,
      },
      {
        productFeatureId: 3,
        value: '1500',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2017-09-19T14:35:38.033354+00:00',
        productId: 12,
      },
      {
        productFeatureId: 4,
        value: '36',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-09-19T14:40:29.650204+00:00',
        productId: 1,
      },
      {
        productFeatureId: 5,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-09-19T14:41:55.068062+00:00',
        productId: 2,
      },
      {
        productFeatureId: 6,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-09-19T14:42:28.680134+00:00',
        productId: 3,
      },
      {
        productFeatureId: 8,
        value: '3',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-09-19T14:42:56.066291+00:00',
        productId: 5,
      },
      {
        productFeatureId: 9,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-09-28T20:48:56.861882+00:00',
        productId: 14,
      },
      {
        productFeatureId: 11,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:45:28.317569+00:00',
        productId: 16,
      },
      {
        productFeatureId: 12,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:47:00.972438+00:00',
        productId: 17,
      },
      {
        productFeatureId: 13,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:47:56.487958+00:00',
        productId: 18,
      },
      {
        productFeatureId: 14,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:50:07.711746+00:00',
        productId: 19,
      },
      {
        productFeatureId: 15,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:50:31.528707+00:00',
        productId: 20,
      },
      {
        productFeatureId: 16,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:51:54.365761+00:00',
        productId: 21,
      },
      {
        productFeatureId: 17,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:54:18.376569+00:00',
        productId: 22,
      },
      {
        productFeatureId: 18,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:55:17.060553+00:00',
        productId: 23,
      },
      {
        productFeatureId: 19,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:57:05.124744+00:00',
        productId: 24,
      },
      {
        productFeatureId: 20,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T16:58:14.763943+00:00',
        productId: 25,
      },
      {
        productFeatureId: 21,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T17:00:11.118167+00:00',
        productId: 26,
      },
      {
        productFeatureId: 22,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-06T17:00:17.303940+00:00',
        productId: 27,
      },
      {
        productFeatureId: 23,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:10:56.070833+00:00',
        productId: 30,
      },
      {
        productFeatureId: 24,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:13.584158+00:00',
        productId: 31,
      },
      {
        productFeatureId: 25,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:18.922052+00:00',
        productId: 32,
      },
      {
        productFeatureId: 26,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:23.642689+00:00',
        productId: 33,
      },
      {
        productFeatureId: 28,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:33.265960+00:00',
        productId: 35,
      },
      {
        productFeatureId: 29,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:37.369186+00:00',
        productId: 36,
      },
      {
        productFeatureId: 30,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:11:41.989426+00:00',
        productId: 37,
      },
      {
        productFeatureId: 31,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:04.607733+00:00',
        productId: 38,
      },
      {
        productFeatureId: 32,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:09.994755+00:00',
        productId: 39,
      },
      {
        productFeatureId: 33,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:16.215327+00:00',
        productId: 40,
      },
      {
        productFeatureId: 34,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:20.468435+00:00',
        productId: 41,
      },
      {
        productFeatureId: 35,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:25.173962+00:00',
        productId: 42,
      },
      {
        productFeatureId: 36,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-19T15:12:33.730981+00:00',
        productId: 43,
      },
      {
        productFeatureId: 37,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-26T18:32:11.120378+00:00',
        productId: 44,
      },
      {
        productFeatureId: 38,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2017-10-26T19:12:03.063237+00:00',
        productId: 45,
      },
      {
        productFeatureId: 40,
        value: '1000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-02-16T19:50:31.976662+00:00',
        productId: 47,
      },
      {
        productFeatureId: 41,
        value: '10000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-02-16T19:50:47.076352+00:00',
        productId: 48,
      },
      {
        productFeatureId: 42,
        value: '15000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-02-16T19:51:04.219533+00:00',
        productId: 49,
      },
      {
        productFeatureId: 43,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-02-16T20:59:08.305701+00:00',
        productId: 53,
      },
      {
        productFeatureId: 45,
        value: '3',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-02-16T22:00:28.814704+00:00',
        productId: 55,
      },
      {
        productFeatureId: 46,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 29,
      },
      {
        productFeatureId: 47,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 30,
      },
      {
        productFeatureId: 48,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 31,
      },
      {
        productFeatureId: 49,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 32,
      },
      {
        productFeatureId: 50,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 33,
      },
      {
        productFeatureId: 51,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 35,
      },
      {
        productFeatureId: 52,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 36,
      },
      {
        productFeatureId: 53,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 37,
      },
      {
        productFeatureId: 54,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 38,
      },
      {
        productFeatureId: 55,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 39,
      },
      {
        productFeatureId: 56,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 40,
      },
      {
        productFeatureId: 57,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 41,
      },
      {
        productFeatureId: 58,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 42,
      },
      {
        productFeatureId: 59,
        value: '1',
        productFeatureTypeId: 3,
        productFeatureName: 'Legacy',
        createTime: '2018-02-20T03:40:22.097035+00:00',
        productId: 43,
      },
      {
        productFeatureId: 60,
        value: '5000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-03-04T18:29:58.561510+00:00',
        productId: 52,
      },
      {
        productFeatureId: 61,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-03-14T20:44:36.559303+00:00',
        productId: 58,
      },
      {
        productFeatureId: 62,
        value: '20000',
        productFeatureTypeId: 1,
        productFeatureName: 'Price',
        createTime: '2018-03-14T20:49:46.491306+00:00',
        productId: 57,
      },
      {
        productFeatureId: 65,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-02T04:51:16.165244+00:00',
        productId: 59,
      },
      {
        productFeatureId: 68,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-10T18:03:13.459546+00:00',
        productId: 63,
      },
      {
        productFeatureId: 69,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-10T18:04:43.336256+00:00',
        productId: 64,
      },
      {
        productFeatureId: 70,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-04-10T18:05:08.958034+00:00',
        productId: 64,
      },
      {
        productFeatureId: 71,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-04-10T18:05:28.975725+00:00',
        productId: 64,
      },
      {
        productFeatureId: 72,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-04-10T18:05:41.542078+00:00',
        productId: 64,
      },
      {
        productFeatureId: 73,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-04-10T18:05:56.724933+00:00',
        productId: 64,
      },
      {
        productFeatureId: 74,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-10T20:13:56.437622+00:00',
        productId: 66,
      },
      {
        productFeatureId: 77,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-04-10T20:16:07.689910+00:00',
        productId: 66,
      },
      {
        productFeatureId: 78,
        value: '1000',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-04-10T20:16:28.524927+00:00',
        productId: 66,
      },
      {
        productFeatureId: 79,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-04-10T20:16:56.290415+00:00',
        productId: 66,
      },
      {
        productFeatureId: 80,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-12T04:14:22.810273+00:00',
        productId: 67,
      },
      {
        productFeatureId: 81,
        value: '20',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-04-12T04:14:22.810273+00:00',
        productId: 67,
      },
      {
        productFeatureId: 83,
        value: '69',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-04-12T04:14:22.810273+00:00',
        productId: 67,
      },
      {
        productFeatureId: 84,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-04-12T14:38:26.387542+00:00',
        productId: 69,
      },
      {
        productFeatureId: 85,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-04-20T20:27:47.329032+00:00',
        productId: 64,
      },
      {
        productFeatureId: 86,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-04-20T20:28:49.349250+00:00',
        productId: 67,
      },
      {
        productFeatureId: 87,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-04-23T01:28:14.294952+00:00',
        productId: 66,
      },
      {
        productFeatureId: 89,
        value: '24',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-04-25T16:38:27.098971+00:00',
        productId: 71,
      },
      {
        productFeatureId: 90,
        value: '36',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-04-25T16:38:37.628606+00:00',
        productId: 72,
      },
      {
        productFeatureId: 93,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-05-07T20:12:53.422960+00:00',
        productId: 64,
      },
      {
        productFeatureId: 94,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-05-07T20:15:28.050193+00:00',
        productId: 67,
      },
      {
        productFeatureId: 102,
        value: '76',
        productFeatureTypeId: 8,
        productFeatureName: 'ExtraSeatProductId',
        createTime: '2018-05-16T21:28:53.633745+00:00',
        productId: 67,
      },
      {
        productFeatureId: 103,
        value: '0.33',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-05-17T19:48:35.513535+00:00',
        productId: 79,
      },
      {
        productFeatureId: 104,
        value: '2',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-05-23T17:27:16.137534+00:00',
        productId: 77,
      },
      {
        productFeatureId: 105,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-05-23T21:04:17.787185+00:00',
        productId: 76,
      },
      {
        productFeatureId: 106,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-05-23T21:04:31.897172+00:00',
        productId: 76,
      },
      {
        productFeatureId: 107,
        value: '1000',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-05-23T21:04:43.167564+00:00',
        productId: 76,
      },
      {
        productFeatureId: 108,
        value: '69',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-05-23T21:04:59.657842+00:00',
        productId: 76,
      },
      {
        productFeatureId: 109,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-05-23T21:05:18.446214+00:00',
        productId: 76,
      },
      {
        productFeatureId: 110,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-06-04T14:32:53.478373+00:00',
        productId: 80,
      },
      {
        productFeatureId: 111,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-04T14:33:08.384240+00:00',
        productId: 80,
      },
      {
        productFeatureId: 112,
        value: '0',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-04T14:33:21.245338+00:00',
        productId: 80,
      },
      {
        productFeatureId: 113,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-04T14:33:56.178310+00:00',
        productId: 80,
      },
      {
        productFeatureId: 114,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-04T14:34:14.159935+00:00',
        productId: 80,
      },
      {
        productFeatureId: 115,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'ExtraSeatProductId',
        createTime: '2018-06-04T14:34:59.861217+00:00',
        productId: 80,
      },
      {
        productFeatureId: 116,
        value: '0',
        productFeatureTypeId: 9,
        productFeatureName: 'IsMultipleAllowed',
        createTime: '2018-06-04T14:35:16.606027+00:00',
        productId: 80,
      },
      {
        productFeatureId: 117,
        value: '64',
        productFeatureTypeId: 10,
        productFeatureName: 'AddOnToProductId',
        createTime: '2018-06-04T14:35:49.420919+00:00',
        productId: 80,
      },
      {
        productFeatureId: 118,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-04T14:36:03.833967+00:00',
        productId: 80,
      },
      {
        productFeatureId: 119,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-06-04T14:48:03.994561+00:00',
        productId: 81,
      },
      {
        productFeatureId: 120,
        value: '1',
        productFeatureTypeId: 9,
        productFeatureName: 'IsMultipleAllowed',
        createTime: '2018-06-04T14:48:15.169356+00:00',
        productId: 81,
      },
      {
        productFeatureId: 121,
        value: '64',
        productFeatureTypeId: 10,
        productFeatureName: 'AddOnToProductId',
        createTime: '2018-06-04T14:49:01.328452+00:00',
        productId: 81,
      },
      {
        productFeatureId: 122,
        value: '0',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-04T14:49:30.782639+00:00',
        productId: 81,
      },
      {
        productFeatureId: 123,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-04T14:49:58.060344+00:00',
        productId: 81,
      },
      {
        productFeatureId: 124,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Duration in months',
        createTime: '2018-06-04T14:52:45.304767+00:00',
        productId: 82,
      },
      {
        productFeatureId: 125,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-04T14:53:02.760029+00:00',
        productId: 82,
      },
      {
        productFeatureId: 126,
        value: '0',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-04T14:53:20.415735+00:00',
        productId: 82,
      },
      {
        productFeatureId: 127,
        value: '69',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-04T14:54:03.620627+00:00',
        productId: 82,
      },
      {
        productFeatureId: 128,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-04T14:54:23.988406+00:00',
        productId: 82,
      },
      {
        productFeatureId: 129,
        value: '76',
        productFeatureTypeId: 8,
        productFeatureName: 'ExtraSeatProductId',
        createTime: '2018-06-04T14:55:04.428019+00:00',
        productId: 82,
      },
      {
        productFeatureId: 130,
        value: '0',
        productFeatureTypeId: 9,
        productFeatureName: 'IsMultipleAllowed',
        createTime: '2018-06-04T14:55:26.538282+00:00',
        productId: 82,
      },
      {
        productFeatureId: 131,
        value: '67',
        productFeatureTypeId: 10,
        productFeatureName: 'AddOnToProductId',
        createTime: '2018-06-04T14:56:40.672682+00:00',
        productId: 82,
      },
      {
        productFeatureId: 132,
        value: '1000',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-04T14:57:03.466118+00:00',
        productId: 82,
      },
      {
        productFeatureId: 141,
        value: '3',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-10T17:38:44.805096+00:00',
        productId: 84,
      },
      {
        productFeatureId: 142,
        value: '487.50',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-10T17:39:53.957770+00:00',
        productId: 84,
      },
      {
        productFeatureId: 143,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-10T17:40:03.931677+00:00',
        productId: 84,
      },
      {
        productFeatureId: 144,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-10T17:40:15.138153+00:00',
        productId: 84,
      },
      {
        productFeatureId: 145,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-10T17:40:27.353195+00:00',
        productId: 84,
      },
      {
        productFeatureId: 147,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-10T17:40:48.673282+00:00',
        productId: 84,
      },
      {
        productFeatureId: 148,
        value: '86',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-06-10T17:40:59.337929+00:00',
        productId: 84,
      },
      {
        productFeatureId: 149,
        value: '2000',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-06-10T17:58:48.275167+00:00',
        productId: 85,
      },
      {
        productFeatureId: 150,
        value: '3',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-10T18:44:23.754005+00:00',
        productId: 86,
      },
      {
        productFeatureId: 151,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-10T18:44:34.969877+00:00',
        productId: 86,
      },
      {
        productFeatureId: 152,
        value: '1000',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-10T18:44:44.745434+00:00',
        productId: 86,
      },
      {
        productFeatureId: 153,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-10T18:44:55.136948+00:00',
        productId: 86,
      },
      {
        productFeatureId: 154,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-10T18:45:04.834058+00:00',
        productId: 86,
      },
      {
        productFeatureId: 167,
        value: '0',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-26T19:42:34.178356+00:00',
        productId: 89,
      },
      {
        productFeatureId: 168,
        value: '0',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-26T19:42:51.883071+00:00',
        productId: 89,
      },
      {
        productFeatureId: 169,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-26T19:43:16.371353+00:00',
        productId: 89,
      },
      {
        productFeatureId: 170,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-26T19:43:29.312934+00:00',
        productId: 89,
      },
      {
        productFeatureId: 171,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-26T19:43:42.001451+00:00',
        productId: 89,
      },
      {
        productFeatureId: 172,
        value: '0',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-26T19:46:23.684815+00:00',
        productId: 90,
      },
      {
        productFeatureId: 173,
        value: '0',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-26T19:49:03.646201+00:00',
        productId: 91,
      },
      {
        productFeatureId: 174,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-26T19:49:17.014100+00:00',
        productId: 91,
      },
      {
        productFeatureId: 175,
        value: '100',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-26T19:49:31.313342+00:00',
        productId: 91,
      },
      {
        productFeatureId: 176,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-26T19:49:44.020731+00:00',
        productId: 91,
      },
      {
        productFeatureId: 177,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-26T19:49:58.516120+00:00',
        productId: 91,
      },
      {
        productFeatureId: 178,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-26T20:21:37.396468+00:00',
        productId: 89,
      },
      {
        productFeatureId: 179,
        value: '91',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-06-26T20:21:46.705112+00:00',
        productId: 89,
      },
      {
        productFeatureId: 182,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-27T01:39:58.675389+00:00',
        productId: 92,
      },
      {
        productFeatureId: 183,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-27T01:43:49.343654+00:00',
        productId: 92,
      },
      {
        productFeatureId: 184,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-27T01:44:09.262953+00:00',
        productId: 92,
      },
      {
        productFeatureId: 185,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-27T01:44:29.717869+00:00',
        productId: 92,
      },
      {
        productFeatureId: 186,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-27T01:44:46.353478+00:00',
        productId: 92,
      },
      {
        productFeatureId: 187,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-27T01:45:01.117758+00:00',
        productId: 92,
      },
      {
        productFeatureId: 188,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-06-27T01:45:13.004789+00:00',
        productId: 92,
      },
      {
        productFeatureId: 189,
        value: '92',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-06-27T01:46:01.051591+00:00',
        productId: 64,
      },
      {
        productFeatureId: 190,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-27T01:47:30.809016+00:00',
        productId: 93,
      },
      {
        productFeatureId: 191,
        value: '93',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-06-27T01:47:56.319572+00:00',
        productId: 59,
      },
      {
        productFeatureId: 192,
        value: '15',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-06-28T16:15:47.111208+00:00',
        productId: 95,
      },
      {
        productFeatureId: 193,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-06-28T16:15:59.434112+00:00',
        productId: 95,
      },
      {
        productFeatureId: 194,
        value: '6',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-06-28T16:16:22.245918+00:00',
        productId: 95,
      },
      {
        productFeatureId: 195,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-06-28T16:37:00.350361+00:00',
        productId: 95,
      },
      {
        productFeatureId: 196,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-06-28T16:37:15.717493+00:00',
        productId: 95,
      },
      {
        productFeatureId: 197,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-06-28T16:37:31.379157+00:00',
        productId: 95,
      },
      {
        productFeatureId: 198,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-06-28T16:37:44.376920+00:00',
        productId: 95,
      },
      {
        productFeatureId: 199,
        value: '92',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-06-28T16:38:00.593943+00:00',
        productId: 95,
      },
      {
        productFeatureId: 201,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-07-17T18:37:32.647155+00:00',
        productId: 98,
      },
      {
        productFeatureId: 202,
        value: '950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-07-17T18:37:50.336627+00:00',
        productId: 98,
      },
      {
        productFeatureId: 203,
        value: '6',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-07-17T18:38:21.576985+00:00',
        productId: 98,
      },
      {
        productFeatureId: 204,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-07-17T18:38:53.073729+00:00',
        productId: 98,
      },
      {
        productFeatureId: 205,
        value: '100',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-07-17T18:39:08.658164+00:00',
        productId: 98,
      },
      {
        productFeatureId: 206,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-07-17T18:39:22.877722+00:00',
        productId: 98,
      },
      {
        productFeatureId: 207,
        value: '99',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-07-17T18:40:06.969320+00:00',
        productId: 98,
      },
      {
        productFeatureId: 209,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-07-17T18:42:13.371810+00:00',
        productId: 99,
      },
      {
        productFeatureId: 210,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-07-17T18:42:26.786710+00:00',
        productId: 99,
      },
      {
        productFeatureId: 211,
        value: '100',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-07-17T18:42:39.316724+00:00',
        productId: 99,
      },
      {
        productFeatureId: 212,
        value: '100',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-07-17T18:42:51.617810+00:00',
        productId: 99,
      },
      {
        productFeatureId: 213,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-07-17T18:43:03.075942+00:00',
        productId: 99,
      },
      {
        productFeatureId: 221,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-07-23T20:58:18.609072+00:00',
        productId: 100,
      },
      {
        productFeatureId: 222,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-08-08T02:12:20.313594+00:00',
        productId: 101,
      },
      {
        productFeatureId: 223,
        value: '93',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-08-08T02:12:37.274260+00:00',
        productId: 101,
      },
      {
        productFeatureId: 224,
        value: '15',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-08-10T18:34:33.165164+00:00',
        productId: 102,
      },
      {
        productFeatureId: 225,
        value: '93',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-08-10T18:34:59.972689+00:00',
        productId: 102,
      },
      {
        productFeatureId: 226,
        value: '15',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 227,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 228,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 229,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 230,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 231,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 232,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 233,
        value: '92',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        productId: 103,
      },
      {
        productFeatureId: 234,
        value: '15',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 235,
        value: '950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 236,
        value: '6',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 237,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 238,
        value: '100',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 239,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 240,
        value: '99',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        productId: 104,
      },
      {
        productFeatureId: 242,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:31:21.323851+00:00',
        productId: 2,
      },
      {
        productFeatureId: 243,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:31:32.680344+00:00',
        productId: 3,
      },
      {
        productFeatureId: 244,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:31:51.637034+00:00',
        productId: 50,
      },
      {
        productFeatureId: 245,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:31:58.386593+00:00',
        productId: 51,
      },
      {
        productFeatureId: 247,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:32:35.049189+00:00',
        productId: 59,
      },
      {
        productFeatureId: 248,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-08-19T21:32:44.744250+00:00',
        productId: 62,
      },
      {
        productFeatureId: 249,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 250,
        value: '1950',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 251,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 252,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 253,
        value: '63',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 254,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 255,
        value: '66',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 256,
        value: '92',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        productId: 105,
      },
      {
        productFeatureId: 257,
        value: '1',
        productFeatureTypeId: 15,
        productFeatureName: 'IsConcierge',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 106,
      },
      {
        productFeatureId: 258,
        value: '1',
        productFeatureTypeId: 16,
        productFeatureName: 'ConciergeVendorTypeId',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 106,
      },
      {
        productFeatureId: 259,
        value: '1',
        productFeatureTypeId: 15,
        productFeatureName: 'IsConcierge',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 107,
      },
      {
        productFeatureId: 260,
        value: '2',
        productFeatureTypeId: 16,
        productFeatureName: 'ConciergeVendorTypeId',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 107,
      },
      {
        productFeatureId: 261,
        value: '1',
        productFeatureTypeId: 12,
        productFeatureName: 'IsCompensation',
        createTime: '2018-08-31T20:57:26.020529+00:00',
        productId: 108,
      },
      {
        productFeatureId: 263,
        value: '1',
        productFeatureTypeId: 17,
        productFeatureName: 'IsFlex',
        createTime: '2018-09-04T19:58:29.541788+00:00',
        productId: 89,
      },
      {
        productFeatureId: 264,
        value: '1',
        productFeatureTypeId: 17,
        productFeatureName: 'IsFlex',
        createTime: '2018-09-04T20:01:25.289729+00:00',
        productId: 90,
      },
      {
        productFeatureId: 265,
        value: '0',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 109,
      },
      {
        productFeatureId: 266,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 109,
      },
      {
        productFeatureId: 267,
        value: '100',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 109,
      },
      {
        productFeatureId: 268,
        value: '100',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 109,
      },
      {
        productFeatureId: 269,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 109,
      },
      {
        productFeatureId: 270,
        value: '0',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 271,
        value: '0',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 272,
        value: '6',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 273,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 274,
        value: '100',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 275,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 276,
        value: '109',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 277,
        value: '1',
        productFeatureTypeId: 17,
        productFeatureName: 'IsFlex',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        productId: 110,
      },
      {
        productFeatureId: 278,
        value: '1485',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-09-17T19:30:16.045106+00:00',
        productId: 112,
      },
      {
        productFeatureId: 279,
        value: '2985',
        productFeatureTypeId: 1,
        productFeatureName: 'Flight Credits',
        createTime: '2018-09-17T19:30:25.956115+00:00',
        productId: 113,
      },
      {
        productFeatureId: 280,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-09-25T13:41:23.851710+00:00',
        productId: 64,
      },
      {
        productFeatureId: 281,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-09-25T13:41:38.546086+00:00',
        productId: 65,
      },
      {
        productFeatureId: 282,
        value: '1',
        productFeatureTypeId: 14,
        productFeatureName: 'IsMappedToInAppProduct',
        createTime: '2018-09-25T13:42:59.287505+00:00',
        productId: 101,
      },
      {
        productFeatureId: 283,
        value: '1',
        productFeatureTypeId: 18,
        productFeatureName: 'IsCharter',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 114,
      },
      {
        productFeatureId: 316,
        value: '1',
        productFeatureTypeId: 18,
        productFeatureName: 'IsCharter',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 147,
      },
      {
        productFeatureId: 317,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 147,
      },
      {
        productFeatureId: 318,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 148,
      },
      {
        productFeatureId: 319,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 149,
      },
      {
        productFeatureId: 320,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 149,
      },
      {
        productFeatureId: 321,
        value: '1',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 149,
      },
      {
        productFeatureId: 322,
        value: '148',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 149,
      },
      {
        productFeatureId: 323,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 149,
      },
      {
        productFeatureId: 324,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 325,
        value: '2000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 326,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 327,
        value: '6',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 328,
        value: '148',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 329,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 330,
        value: '149',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 150,
      },
      {
        productFeatureId: 331,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 332,
        value: '2000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 333,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 334,
        value: '6',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 335,
        value: '148',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 336,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 337,
        value: '149',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 338,
        value: '150',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        productId: 151,
      },
      {
        productFeatureId: 339,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-20T22:15:16.587829+00:00',
        productId: 150,
      },
      {
        productFeatureId: 340,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-20T22:15:16.638917+00:00',
        productId: 151,
      },
      {
        productFeatureId: 341,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 342,
        value: '2000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 343,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 344,
        value: '6',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 345,
        value: '148',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 346,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 347,
        value: '149',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 152,
      },
      {
        productFeatureId: 348,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 349,
        value: '2000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 350,
        value: '4',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 351,
        value: '6',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 352,
        value: '148',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 353,
        value: '2',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 354,
        value: '149',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Seat Product Id',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 355,
        value: '152',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 153,
      },
      {
        productFeatureId: 356,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 148,
      },
      {
        productFeatureId: 357,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 149,
      },
      {
        productFeatureId: 358,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 150,
      },
      {
        productFeatureId: 359,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 151,
      },
      {
        productFeatureId: 360,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 152,
      },
      {
        productFeatureId: 361,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 153,
      },
      {
        productFeatureId: 362,
        value: '1',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        productId: 114,
      },
      {
        productFeatureId: 390,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 158,
      },
      {
        productFeatureId: 391,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 158,
      },
      {
        productFeatureId: 392,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 393,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 394,
        value: '1',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 395,
        value: '158',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 396,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 397,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 159,
      },
      {
        productFeatureId: 398,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 399,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 400,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 401,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 402,
        value: '158',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 403,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 404,
        value: '159',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 405,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 406,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 160,
      },
      {
        productFeatureId: 407,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 408,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 409,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 410,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 411,
        value: '158',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 412,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 413,
        value: '159',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 414,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 415,
        value: '160',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 416,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 161,
      },
      {
        productFeatureId: 417,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 418,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 419,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 420,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 421,
        value: '158',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 422,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 423,
        value: '159',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 424,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 162,
      },
      {
        productFeatureId: 425,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 426,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 427,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 428,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 429,
        value: '158',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 430,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 431,
        value: '159',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 432,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 433,
        value: '162',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        productId: 163,
      },
      {
        productFeatureId: 434,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 164,
      },
      {
        productFeatureId: 435,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 164,
      },
      {
        productFeatureId: 436,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 437,
        value: '1',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 438,
        value: '1',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 439,
        value: '164',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 440,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 441,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 165,
      },
      {
        productFeatureId: 442,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 443,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 444,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 445,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 446,
        value: '164',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 447,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 448,
        value: '165',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 449,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 450,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 166,
      },
      {
        productFeatureId: 451,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 452,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 453,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 454,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 455,
        value: '164',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 456,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 457,
        value: '165',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 458,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 459,
        value: '166',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 460,
        value: '1',
        productFeatureTypeId: 20,
        productFeatureName: 'AddsFOA',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 167,
      },
      {
        productFeatureId: 461,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 462,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 463,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 464,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 465,
        value: '164',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 466,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 467,
        value: '165',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 468,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 168,
      },
      {
        productFeatureId: 469,
        value: '12',
        productFeatureTypeId: 2,
        productFeatureName: 'Length in months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 470,
        value: '100000',
        productFeatureTypeId: 1,
        productFeatureName: 'Extra slot price',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 471,
        value: '10',
        productFeatureTypeId: 4,
        productFeatureName: 'Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 472,
        value: '10',
        productFeatureTypeId: 5,
        productFeatureName: 'Max Slots Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 473,
        value: '164',
        productFeatureTypeId: 6,
        productFeatureName: 'Slot Membership Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 474,
        value: '0',
        productFeatureTypeId: 7,
        productFeatureName: 'Replacements Count',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 475,
        value: '165',
        productFeatureTypeId: 8,
        productFeatureName: 'Extra Slot Product Id',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 476,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 477,
        value: '168',
        productFeatureTypeId: 13,
        productFeatureName: 'CustomTermProductId',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        productId: 169,
      },
      {
        productFeatureId: 478,
        value: '1',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        productId: 150,
      },
      {
        productFeatureId: 479,
        value: '1',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.560194+00:00',
        productId: 151,
      },
      {
        productFeatureId: 480,
        value: '2',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.612904+00:00',
        productId: 152,
      },
      {
        productFeatureId: 481,
        value: '2',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.662922+00:00',
        productId: 153,
      },
      {
        productFeatureId: 482,
        value: '3',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.714396+00:00',
        productId: 160,
      },
      {
        productFeatureId: 483,
        value: '3',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 161,
      },
      {
        productFeatureId: 484,
        value: '4',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 162,
      },
      {
        productFeatureId: 485,
        value: '4',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 163,
      },
      {
        productFeatureId: 486,
        value: '5',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 166,
      },
      {
        productFeatureId: 487,
        value: '5',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 167,
      },
      {
        productFeatureId: 488,
        value: '6',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 168,
      },
      {
        productFeatureId: 489,
        value: '6',
        productFeatureTypeId: 21,
        productFeatureName: 'XOAgreementType',
        createTime: '2019-06-27T22:01:14.767546+00:00',
        productId: 169,
      },
      {
        productFeatureId: 490,
        value: '1',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 106,
      },
      {
        productFeatureId: 491,
        value: '1',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        productId: 107,
      },
      {
        productFeatureId: 492,
        value: '1',
        productFeatureTypeId: 15,
        productFeatureName: 'IsConcierge',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 172,
      },
      {
        productFeatureId: 493,
        value: '1',
        productFeatureTypeId: 16,
        productFeatureName: 'ConciergeVendorTypeId',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 172,
      },
      {
        productFeatureId: 494,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 172,
      },
      {
        productFeatureId: 495,
        value: '1',
        productFeatureTypeId: 15,
        productFeatureName: 'IsConcierge',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 173,
      },
      {
        productFeatureId: 496,
        value: '2',
        productFeatureTypeId: 16,
        productFeatureName: 'ConciergeVendorTypeId',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 173,
      },
      {
        productFeatureId: 497,
        value: '2',
        productFeatureTypeId: 19,
        productFeatureName: 'ClientSource',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        productId: 173,
      },
    ],
    products: [
      {
        description: null,
        previousProductId: null,
        basePrice: '97500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 36 Months',
        createTime: '2017-08-16T16:41:21.449272+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 1,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '50000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 12 Months',
        createTime: '2017-08-16T16:41:59.580050+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 2,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '15000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $15,000',
        createTime: '2017-08-16T16:59:14.056627+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 3,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Simple',
        productTypeId: 4,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Simple 3 Months (FREE)',
        createTime: '2017-08-16T16:59:35.794764+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 5,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Affinity',
        productTypeId: 7,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Affinity',
        createTime: '2017-08-16T17:00:35.945321+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 7,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'LegacyTrial (TrialMembership)',
        productTypeId: 8,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Legacy',
        createTime: '2017-08-16T17:00:48.674815+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 8,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '60,000 Flight Credits',
        createTime: '2017-08-16T17:01:12.445383+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 9,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '20,000 Flight Credits',
        createTime: '2017-08-16T17:01:52.377571+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 10,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '1,500 Flight Credits',
        createTime: '2017-09-14T21:58:57.116806+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 12,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '4166.67',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 1 Month',
        createTime: '2017-09-27T18:27:28.093261+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 13,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'ACCESS (Limited)',
        productTypeId: 9,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Access 12 Months',
        createTime: '2017-09-28T20:47:57.072390+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 14,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'LegacyTrial (TrialMembershipR)',
        productTypeId: 10,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'LegacyTrial (TrialMembershipR)',
        createTime: '2017-10-06T16:44:49.962779+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 16,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'GILT (GILT_Membership)',
        productTypeId: 11,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'GILT (GILT_Membership)',
        createTime: '2017-10-06T16:46:35.795073+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 17,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'ARTBASEL (ARTBASEL_Membership)',
        productTypeId: 12,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'ARTBASEL (ARTBASEL_Membership)',
        createTime: '2017-10-06T16:47:24.839255+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 18,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'UBS (UBS)',
        productTypeId: 13,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'UBS (UBS)',
        createTime: '2017-10-06T16:48:16.594929+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 19,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '3990',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'ACCESS-OLD (Social)',
        productTypeId: 14,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'ACCESS-OLD (Social)',
        createTime: '2017-10-06T16:49:52.903629+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 20,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'INVITATION ONLY (Gift)',
        productTypeId: 15,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'INVITATION ONLY (Gift)',
        createTime: '2017-10-06T16:51:29.838301+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 21,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '15000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'SMART (MembershipManual)',
        productTypeId: 16,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'SMART (MembershipManual)',
        createTime: '2017-10-06T16:53:56.495611+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 22,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '4999',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'SMART (Manual)',
        productTypeId: 17,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'SMART (Manual)',
        createTime: '2017-10-06T16:54:49.245812+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 23,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '23340',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'CORPORATE (Corporate)',
        productTypeId: 18,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'CORPORATE (Corporate)',
        createTime: '2017-10-06T16:56:34.913707+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 24,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'AffinityC (AFFINITY_C)',
        productTypeId: 19,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'AffinityC (AFFINITY_C)',
        createTime: '2017-10-06T16:57:53.918575+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 25,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'AFFINITY2 (AFFINITY2)',
        productTypeId: 20,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'AFFINITY2 (AFFINITY2)',
        createTime: '2017-10-06T16:58:46.774292+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 26,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'SMART (Legacy)',
        productTypeId: 21,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'SMART (Legacy)',
        createTime: '2017-10-06T16:59:42.808597+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 9, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 27,
      },
      {
        description: null,
        previousProductId: 4,
        basePrice: '6000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Simple',
        productTypeId: 4,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Simple 12 Months',
        createTime: '2017-10-16T16:41:50.781153+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 10, 16, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 28,
      },
      {
        description: 'To renew Smart memberships from Console',
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: 'To renew Smart memberships from Console',
        nextProductId: null,
        productTypeName: 'Smart Renewals From Console',
        productTypeId: 24,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart Renewals From Console',
        createTime: '2017-10-19T14:34:36.108992+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 10, 16, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 29,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '5999',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $5,999',
        createTime: '2017-10-19T14:43:08.160812+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 30,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '6999',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $6,999',
        createTime: '2017-10-19T14:44:51.484421+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 31,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '7999',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $7,999',
        createTime: '2017-10-19T14:45:11.094532+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 32,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '8499',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $8,499',
        createTime: '2017-10-19T14:45:32.072620+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 33,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $9,000',
        createTime: '2017-10-19T14:55:11.682268+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 35,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9675',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $9,675',
        createTime: '2017-10-19T14:57:01.846523+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 36,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '10000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $10,000',
        createTime: '2017-10-19T14:57:27.425548+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 37,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '11000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $11,000',
        createTime: '2017-10-19T14:57:45.411381+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 38,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '11500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $11,500',
        createTime: '2017-10-19T14:58:00.517242+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 39,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '12000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $12,000',
        createTime: '2017-10-19T14:58:16.398712+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 40,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '13000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $13,000',
        createTime: '2017-10-19T14:58:34.057219+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 41,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '13500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $13,500',
        createTime: '2017-10-19T14:58:46.082349+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 42,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '14000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $14,000',
        createTime: '2017-10-19T14:59:15.261797+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 43,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '40000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 12 Months',
        createTime: '2017-10-26T18:29:08.341708+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 44,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '45000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 12 Months',
        createTime: '2017-10-26T19:07:56.916637+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 45,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '1,000 Flight Credits',
        createTime: '2018-02-16T19:48:47.817750+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 47,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '10,000 Flight Credits',
        createTime: '2018-02-16T19:49:28.787543+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 48,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '15,000 Flight Credits',
        createTime: '2018-02-16T19:49:49.750889+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 49,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '2500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart Initiation Fee',
        productTypeId: 25,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Initiation Fee (Smart)',
        createTime: '2018-02-16T20:05:00.585566+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 50,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '2500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated Initiation Fee',
        productTypeId: 26,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Initiation Fee (Sophisticated)',
        createTime: '2018-02-16T20:06:04.575489+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 51,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '5,000 Flight Credits',
        createTime: '2018-02-16T20:52:48.595054+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 52,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '20000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'JetPass',
        productTypeId: 23,
        isRefundable: true,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: 'JetPass 12 Months',
        createTime: '2018-02-16T20:55:36.856844+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 53,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '3750',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart 3 Months',
        createTime: '2018-02-16T21:56:46.969252+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 55,
      },
      {
        description: 'To renew Sophisticated memberships from Console',
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: 'To renew Sophisticated memberships from Console',
        nextProductId: null,
        productTypeName: 'Sophisticated Renewals From Console',
        productTypeId: 27,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated Renewals From Console',
        createTime: '2018-02-28T22:00:57.317161+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 56,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Tangible Goods',
        productTypeId: 29,
        isRefundable: true,
        productTypeParentProductTypeId: 28,
        active: true,
        parentProductId: null,
        productName: 'Hublot Watch',
        createTime: '2018-03-14T17:30:03.796700+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 57,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '7500',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $7,500',
        createTime: '2018-03-14T20:43:24.203963+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 58,
      },
      {
        description: 'Membership 12 Months $4,950',
        previousProductId: null,
        basePrice: '4950',
        securityAccess: 1,
        productTypeDescription: 'NewJetSmarter - membership offered since 2018-04-02',
        nextProductId: null,
        productTypeName: 'NewJetSmarter',
        productTypeId: 31,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Individual Membership',
        createTime: '2018-04-02T04:51:16.165244+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 59,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '3000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Membership Initiation Fee',
        productTypeId: 32,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Initiation Fee (Membership)',
        createTime: '2018-04-02T04:53:29.351240+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 1, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 62,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Slot Membership',
        productTypeId: 35,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Family Slot',
        createTime: '2018-04-10T18:02:25.654136+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 63,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group 12 Months',
        createTime: '2018-04-10T18:04:22.594543+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 64,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '3000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Initiation Fee',
        productTypeId: 37,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Family Group Initiation Fee',
        createTime: '2018-04-10T18:06:45.301999+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 65,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Extra Slots',
        productTypeId: 41,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Extra Seats',
        createTime: '2018-04-10T20:11:56.582947+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 66,
      },
      {
        description: 'Enterprise Group 12 Months $19,950',
        previousProductId: null,
        basePrice: '19950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Enterprise Group Membership',
        productTypeId: 39,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Group 12 Months',
        createTime: '2018-04-12T04:14:22.810273+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 67,
      },
      {
        description: 'Enterprise Group Initiation Fee',
        previousProductId: null,
        basePrice: '3000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Enterprise Group Initiation Fee',
        productTypeId: 40,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Group Initiation Fee',
        createTime: '2018-04-12T04:15:32.881811+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 68,
      },
      {
        description: 'Enterprise Slot 12 Months',
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Enterprise Slot Membership',
        productTypeId: 36,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Slot 12 Months',
        createTime: '2018-04-12T14:38:26.387542+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 69,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '40000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'JetPass',
        productTypeId: 23,
        isRefundable: true,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: 'JetPass 24 Months',
        createTime: '2018-04-25T16:32:20.907765+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 71,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '60000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'JetPass',
        productTypeId: 23,
        isRefundable: true,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: 'JetPass 36 Months',
        createTime: '2018-04-25T16:32:55.457050+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 72,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '499',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Upgrade Fee',
        productTypeId: 43,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Family Group Upgrade Fee',
        createTime: '2018-05-04T00:00:18.259321+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 74,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '499',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Enterprise Group Upgrade Fee',
        productTypeId: 44,
        isRefundable: false,
        productTypeParentProductTypeId: 22,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Group Upgrade Fee',
        createTime: '2018-05-04T00:00:39.275128+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 75,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Enterprise Group Extra Slots',
        productTypeId: 42,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Group Extra Seats 12 Months',
        createTime: '2018-05-07T20:14:14.297774+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 76,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '8333.33',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Sophisticated',
        productTypeId: 2,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Sophisticated 2 Months',
        createTime: '2018-05-10T16:19:18.730453+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 77,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '301.37',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Smart',
        productTypeId: 3,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Smart $11,000 (10 days free)',
        createTime: '2018-05-17T19:46:05.087526+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 79,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Group Extra Seats AddOn',
        productTypeId: 45,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Extra Seats',
        createTime: '2018-06-01T19:34:29.080903+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 80,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '495',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Group Replacement Seats AddOn',
        productTypeId: 46,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Replacement Seats',
        createTime: '2018-06-04T14:39:35.075526+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 81,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Group Extra Seats AddOn',
        productTypeId: 45,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Enterprise Extra Seats',
        createTime: '2018-06-04T14:51:17.384411+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 82,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '2487.50',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group 3 Months',
        createTime: '2018-06-10T17:37:43.689028+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 84,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '2,000 Flight Credits',
        createTime: '2018-06-10T17:57:04.528245+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2018, 9, 19, 4, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 85,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '487.50',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Extra Slots',
        productTypeId: 41,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Extra Seats 3 Months',
        createTime: '2018-06-10T18:40:57.305135+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 86,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Flex',
        createTime: '2018-06-26T19:39:22.448596+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 89,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: 'NewJetSmarter - membership offered since 2018-04-02',
        nextProductId: null,
        productTypeName: 'NewJetSmarter',
        productTypeId: 31,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Membership Flex',
        createTime: '2018-06-26T19:39:46.816894+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 90,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Extra Slots',
        productTypeId: 41,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group Extra Seats Flex',
        createTime: '2018-06-26T19:40:12.871898+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 91,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group 12+ Months',
        createTime: '2018-06-27T01:33:12.635523+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 92,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '4950',
        securityAccess: 1,
        productTypeDescription: 'NewJetSmarter - membership offered since 2018-04-02',
        nextProductId: null,
        productTypeName: 'NewJetSmarter',
        productTypeId: 31,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Membership 12+ Months',
        createTime: '2018-06-27T01:37:58.750548+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 93,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group 15 Months 6 Users',
        createTime: '2018-06-28T16:15:13.083511+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 95,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '25000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Signature Group Membership',
        productTypeId: 48,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature Group',
        createTime: '2018-07-17T18:36:35.805924+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 98,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Group Extra Seats AddOn',
        productTypeId: 45,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature Group Extra Seats',
        createTime: '2018-07-17T18:41:04.156768+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 99,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Signature Slot Membership',
        productTypeId: 47,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Signature Slot',
        createTime: '2018-07-23T15:23:21.658904+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 100,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1995',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Starter',
        productTypeId: 50,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Starter',
        createTime: '2018-08-08T02:08:22.907982+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 101,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '4950',
        securityAccess: 1,
        productTypeDescription: 'NewJetSmarter - membership offered since 2018-04-02',
        nextProductId: null,
        productTypeName: 'NewJetSmarter',
        productTypeId: 31,
        isRefundable: true,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Membership 15 Months',
        createTime: '2018-08-10T18:31:04.988780+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 102,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '9950',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Family Group 15 Months',
        createTime: '2018-08-10T18:46:27.616083+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 103,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '25000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Signature Group Membership',
        productTypeId: 48,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature 15 Months',
        createTime: '2018-08-10T18:51:19.363183+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 104,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '1995',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Family Group Membership',
        productTypeId: 38,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Founding Member Promo',
        createTime: '2018-08-29T15:37:22.027704+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 105,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Concierge',
        productTypeId: 62,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'Ground Transportation',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 106,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Concierge',
        productTypeId: 62,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'Catering',
        createTime: '2018-08-31T20:25:34.561910+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 107,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: 'Flight Credits',
        createTime: '2018-08-31T20:56:39.418859+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 108,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Group Extra Seats AddOn',
        productTypeId: 45,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature Group Flex Extra Slots',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 109,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Signature Group Membership',
        productTypeId: 48,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature Group Flex',
        createTime: '2018-09-10T13:06:52.929985+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 110,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '1,485 Flight Credits',
        createTime: '2018-09-17T19:28:37.713084+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 112,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Flight Credits',
        productTypeId: 6,
        isRefundable: false,
        productTypeParentProductTypeId: null,
        active: true,
        parentProductId: null,
        productName: '2,985 Flight Credits',
        createTime: '2018-09-17T19:29:19.221310+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 113,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'Charter Flight',
        productTypeId: 60,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'Charter Flight',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 114,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Charter Flight',
        productTypeId: 61,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'XO Charter Flight',
        createTime: '2018-09-27T20:45:59.867498+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 147,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Slot',
        productTypeId: 51,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Select Slot',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 148,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '2000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Extra Slots',
        productTypeId: 52,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Select Extra Slots',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 149,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '50000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Group',
        productTypeId: 53,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Select Monthly (Program Deposit) 12+ Months',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 150,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '50000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Group',
        productTypeId: 53,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Select Monthly (Program Deposit)',
        createTime: '2019-06-20T21:05:51.885782+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 151,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '5000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Group',
        productTypeId: 53,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Select Annual 12+ Months',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 152,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '5000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Select Group',
        productTypeId: 53,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Select Annual',
        createTime: '2019-06-20T22:27:57.318369+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 153,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Slot',
        productTypeId: 54,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Signature* Slot',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 158,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Extra Slots',
        productTypeId: 55,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature* Extra Slots',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 159,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Group',
        productTypeId: 56,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature* Monthly (Program Deposit) 12+ Months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 160,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Group',
        productTypeId: 56,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature* Monthly (Program Deposit)',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 161,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '15000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Group',
        productTypeId: 56,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature* Annual 12+ Months',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 162,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '15000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Signature Group',
        productTypeId: 56,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Signature* Annual',
        createTime: '2019-06-25T21:24:50.128838+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 163,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Slot',
        productTypeId: 57,
        isRefundable: false,
        productTypeParentProductTypeId: 1,
        active: true,
        parentProductId: null,
        productName: 'Elite Slot',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 164,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Extra Slots',
        productTypeId: 58,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Elite Extra Slots',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 165,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Group',
        productTypeId: 59,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Elite Monthly (Program Deposit) 12+ Months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 166,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '100000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Group',
        productTypeId: 59,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Elite Monthly (Program Deposit)',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 167,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '25000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Group',
        productTypeId: 59,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Elite Annual 12+ Months',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 168,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '25000',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Elite Group',
        productTypeId: 59,
        isRefundable: true,
        productTypeParentProductTypeId: 34,
        active: true,
        parentProductId: null,
        productName: 'Elite Annual',
        createTime: '2019-06-25T22:09:54.021166+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 169,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Concierge',
        productTypeId: 63,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'XO Ground Transportation',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 172,
      },
      {
        description: null,
        previousProductId: null,
        basePrice: '0',
        securityAccess: 1,
        productTypeDescription: null,
        nextProductId: null,
        productTypeName: 'XO Concierge',
        productTypeId: 63,
        isRefundable: false,
        productTypeParentProductTypeId: 30,
        active: true,
        parentProductId: null,
        productName: 'XO Catering',
        createTime: '2019-07-22T22:10:54.145472+00:00',
        lifetimeInterval:
          "DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), '[]')",
        productId: 173,
      },
    ],
    restrictedFunctions: [
      {
        restrictedFunctionId: 1,
        restrictedFunctionName: 'Renewal Paid',
        description:
          'Renewal Paid function should only be avilable to the members of the Renewals team',
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 2,
        restrictedFunctionName: 'Wire Received',
        description: null,
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 3,
        restrictedFunctionName: 'Accounting Report Download',
        description: null,
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 4,
        restrictedFunctionName: 'NewOrderMembership',
        description: null,
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 5,
        restrictedFunctionName: 'NewOrderService',
        description: null,
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 6,
        restrictedFunctionName: 'NewOrderConcierge',
        description: null,
        securityAccess: 1,
      },
      {
        restrictedFunctionId: 7,
        restrictedFunctionName: 'NewOrderCharter',
        description: null,
        securityAccess: 1,
      },
    ],
  },
};
