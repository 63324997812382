import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales.VendorCollection] Load');
export const loadSuccess = createAction(
  '[Sales.VendorCollection] Load Success',
  props<{ payload: Array<models.Vendor> }>()
);
export const loadFail = createAction('[Sales.VendorCollection] Load Fail');
