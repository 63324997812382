import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as contracts from '../../../domain/service-contracts/pricing-comparison';
import * as mappers from '../mapper';
import { response } from './get-price-response';

@Injectable()
export class PricingComparisonMockService implements contracts.IPricingComparison {
  getPrice(request: contracts.GetPriceRequest): Observable<contracts.GetPriceResponse> {
    const { data } = response;
    const r = new contracts.GetPriceResponse();
    r.entities = data.map((item) => mappers.PricingComparisonMapper.parsePrice(item));
    return of(r).pipe(delay(2000));
  }
}
