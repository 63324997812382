import * as models from '../../models';

export class StompMapper {
    private static instance: StompMapper = new StompMapper();

    static Instance(): StompMapper {
        return StompMapper.instance;
    }

    stompMessageDtoToStompMessage(item): models.StompMessage {
        const msg =  new models.StompMessage();
        msg.causationId = item.causationId;
        msg.correlationId = item.correlationId;
        if (item.createdDate) {
            msg.createdDate = new Date(item.createdDate);
        }
        msg.messageType = item.messageType;
        msg.purchaseOfferId = item.purchaseOfferId;
        msg.sourcingRequestId = item.sourcingRequestId;
        msg.actor = this.userDtoToUser(item.actor);
        if (item.lastModifiedBy) {
          msg.lastModifiedBy = this.userDtoToUser(msg.lastModifiedBy);
        }
        return msg;

    }

    userDtoToUser(item: any): models.User {
        const u1 = new models.User();
        if (!item) {
            return u1;
        }
        u1.id = Number(item.id);
        u1.firstName = item.firstName;
        u1.lastName = item.lastName;
        u1.emailAddress = item.emailAddress;

        return u1;
    }
}
