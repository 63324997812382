export class SalesQuoteRequestType {
  id: number;
  name: string;

  getId(): number {
    return this.id;
  }

  setId(id: number): SalesQuoteRequestType {
    this.id = id;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): SalesQuoteRequestType {
    this.name = name;
    return this;
  }
}
