export enum SimplifiedMembershipEnum {
  Undefined = 0,
  Select = 1,
  Signature = 2,
  Elite = 3,
  XO_MEMBERSHIP = 4,
  XO_RESERVE = 5,
  XO_SHARED_MEMBERSHIP = 6,
  RISE = 7,
  XOJET_SELECT_ACCESS = 8,
  XOJET_PREFERRED_ACCESS = 9,
  XOJET_ELITE_ACCESS = 10,
  JET_EDGE_RESERVE = 11,
  XOJET_CORPORATE_ACCESS = 12,
}

export const AllowedSimplifiedMemberships: Array<SimplifiedMembershipEnum> = [
  SimplifiedMembershipEnum.Select,
  SimplifiedMembershipEnum.Signature,
  SimplifiedMembershipEnum.Elite,
  SimplifiedMembershipEnum.XO_MEMBERSHIP,
  SimplifiedMembershipEnum.XO_RESERVE,
  SimplifiedMembershipEnum.XOJET_PREFERRED_ACCESS,
  SimplifiedMembershipEnum.XOJET_SELECT_ACCESS,
  SimplifiedMembershipEnum.XOJET_ELITE_ACCESS,
  SimplifiedMembershipEnum.XOJET_CORPORATE_ACCESS,
  SimplifiedMembershipEnum.RISE,
  SimplifiedMembershipEnum.XO_SHARED_MEMBERSHIP,
];

