import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'offerStatus'})
export class OfferStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'Active':
      case 'Cancelled':
      case 'Accepted':
      case 'Declined':
      case 'Expired':
        return value;
      case 'Bid':
        return 'Bid Pending';
      case 'Bid Approved':
        return 'Bid Sent To Client';
      default:
        return value;
    }
  }
}
