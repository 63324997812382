import { ClientDiscount } from './client-discount';
import { ClientFirmDepartureFee } from './client-firm-departure-fee';

export class MembershipStatus {
  clientId: number;
  clientBookingFee: number = 0;
  clientMembershipStatus: boolean = false;
  membershipDisplayName: string;
  clientDiscounts: Array<ClientDiscount>;
  clientFirmDepartureFee: Array<ClientFirmDepartureFee>;
}
