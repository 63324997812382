/* eslint-disable complexity */
import * as actions from '../actions/airport-collection';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  airports: Array<models.Airport>;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  airports: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const airports = _.cloneDeep(action.payload.entities);

      return {
        airports,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getAirports = (state: State) => state.airports;
