import { Action } from '@ngrx/store';

export const LOAD = '[PodsCollection.Pods] Load';
export const LOAD_SUCCESS = '[PodsCollection.Pods] Load Success';
export const LOAD_FAIL = '[PodsCollection.Pods] Load Fail';
export const SET_INITIAL = '[PodsCollection.Pods] Set Initial';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<string>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetInitialAction {
  readonly type = SET_INITIAL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | SetInitialAction;
