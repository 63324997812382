import { Client } from './client';
import { OrderItemTypeEnum } from './order-item-type';
import { PriceComponent } from './price-component';
import { PromotionItem } from './promotion-item';
import { ProductOrderItem } from './product-order-item';
import { Product } from './product';

export class OrderItem {
  id: number = null;
  orderId: number = null;
  childOrderItems: Array<OrderItem> = [];
  parentOrderItem: OrderItem = null;
  client: Client = null;
  discount: PriceComponent = null;
  surcharge: PriceComponent = null;
  product: Product;

  notes: string = null;

  required = false;
  refundableAmount = 0;

  // basePrice: number = null;
  totalPrice: number = null;

  get isPromotional(): boolean {
    return false;
  }

  get orderItemType(): OrderItemTypeEnum {
    throw new Error('Not Implemented');
  }

  get optionalChildren(): Array<OrderItem> {
    return this.childOrderItems.filter((i) => {
      if (i.required === true || i.orderItemType !== OrderItemTypeEnum.Product) {
        return false;
      }

      const orderItem = i as ProductOrderItem;

      return orderItem.product.productType.id !== 6;
    });
  }

  get flightCredit(): OrderItem {
    const orderItem = this.childOrderItems.find(
      (i) =>
        i.orderItemType === OrderItemTypeEnum.Product &&
        (i as ProductOrderItem).product.productType.id === 6
    );

    return orderItem ? orderItem : null;
  }

  get requiredChildren(): Array<OrderItem> {
    return this.childOrderItems.filter(
      (i: OrderItem) =>
        i.required === true &&
        ((i.orderItemType === OrderItemTypeEnum.Product &&
          (i as ProductOrderItem).product.productType.id !== 6) ||
          i.orderItemType === OrderItemTypeEnum.Membership)
    );
  }

  get adjustmentChildren(): Array<OrderItem> {
    return this.childOrderItems.filter(
      (i: OrderItem) =>
        i.orderItemType === OrderItemTypeEnum.Adjustment ||
        i.orderItemType === OrderItemTypeEnum.LegacyAdjustment ||
        i.orderItemType === OrderItemTypeEnum.FlightCredit
    );
  }
}
