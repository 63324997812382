import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'toHtmlList'})
export class ValueToHtmlListPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: Object): SafeHtml {
    const resHtml = this.toHtmlList(data);
    return this.sanitizer.bypassSecurityTrustHtml(resHtml);
  }

  private toHtmlList(object: any): string {
    if (object === null) {
      return '';
    }

    if (Array.isArray(object)) {
      const liElements = object.map((value) => {
        const res = this.toHtmlList(value);
        return object.length > 1 ? `<li>${res}</li>` : res;
      });
      return object.length > 1 ? `<ul>${liElements.join('')}</ul>` : liElements[0];
    }

    if (typeof object === 'object') {
      const keys = Object.keys(object);
      const liElements = keys.map((key) => {
        let res = this.toHtmlList(object[key]);
        res = res !== null ? res : 'null';
        return keys.length > 1 ? `<li>${key}: ${res}</li>` : `${key}: ${res}`;
      });
      return keys.length > 1 ? `<ul>${liElements.join('')}</ul>` : liElements[0];
    }

    return object;
  }
}
