export class AircraftTail {
  id: number;
  aircraftTailNumber: string;

  getId(): number {
    return this.id;
  }

  getTail(): string {
    return this.aircraftTailNumber;
  }
}
