import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as models from '../models';
import * as coreSelectors from '../selectors';

import { Observable, of } from 'rxjs';
import { map,  } from 'rxjs/operators';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(private store$: Store<any>, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url.startsWith('/legs')) {
      return of(true);
    }

    return this.store$.pipe(
      select(coreSelectors.getUserUser),
      map((user: models.User) => {
        if (user.organization.id !== 1 && user.organization.id !== 8 && user.organization.id !== 28
          && user.organization.id !== 35 && user.organization.id !== 213) {
          this.router.navigate(['/access-denied'], {skipLocationChange: true});

          return null;
        }

        return true;
      }));
  }
}
