export enum FinalizeParams {
  latestInvoiceIsActive = 'Latest invoice should not be Awaiting Payment or Completed. If most recent invoice is Cancelled, previous invoice must be Awaiting Fulfillment.',
  latestInvoicePostedToSAP = 'Latest invoice has successfully posted to SAP',
  legIsNotCancelled = 'Leg ID is not cancelled',
  flightRequestIsNotCancelled = 'Charter search request ID is not cancelled',
  tailNumberIsInConsole = 'Tail number is present in Console on leg request page',
  // xojet = 'On fleet tail number must have XOJET as operator',
  completed = 'Actual Flight time tasks are complete – Departure and Arrival',
  // xojetOnFleet = 'XOJET cannot be the operator if tail number is not on fleet',
  tailNumberOperatorMatchAM = 'Tail/Operator/Model in Console matches Aircraft Management and Tail is not generic',
}
