import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export class UpdateOrderItemPayload {
  constructor(public index: number, public orderItem: models.OrderItem) {}
}
export class AddItemPayload {
  constructor(
    public orderItem: models.OrderItem,
    public orderItemEditor: models.OrderItemEditorEnum
  ) {}
}
export const addItem = createAction(
  '[Sales.OrderNew] Add Item',
  props<{ payload: AddItemPayload }>()
);
export class ApplyFlightCreditPayload {
  amount: number;
  isProductNeeded?: boolean;
}
export const applyFlightCredit = createAction(
  '[Sales.OrderNew] Apply Flight Credit Adjustment',
  props<{ payload: ApplyFlightCreditPayload }>()
);
export class ApplyLoyaltyCreditPayload {
  amount: number;
  isProductNeeded?: boolean;
}
export const applyLoyaltyCredit = createAction(
  '[Sales.OrderNew] Apply Loyalty Credit Adjustment',
  props<{ payload: ApplyLoyaltyCreditPayload }>()
);
export class ApplyLoyaltyCreditAmountGrantedPayload {
  amount: number;
  type: models.LoyaltyType;
}
export const applyLoyaltyCreditAmountGranted = createAction(
  '[Sales.OrderNew] Apply Loyalty Credit Amount Granted',
  props<{ payload: ApplyLoyaltyCreditAmountGrantedPayload }>()
);
export const applyLoyaltyCreditAmountGrantedForFlights = createAction(
  '[Sales.OrderNew] Apply Loyalty Credit Amount Granted For Flights'
);
export const applyLoyaltyCreditAmountGrantedForMembership = createAction(
  '[Sales.OrderNew] Apply Loyalty Credit Amount Granted For Membership'
);
export class ApplyPromotionCreditPayload {
  amount: number;
  isProductNeeded?: boolean;
}
export const applyPromotionCredit = createAction(
  '[Sales.OrderNew] Apply Promotion Credit Adjustment',
  props<{ payload: ApplyPromotionCreditPayload }>()
);
export class ApplySfdcAnnualMembershipFeePayload {
  sfdcAnnualMembershipFee: number;
}
export const applySfdcAnnualMembershipFee = createAction(
  '[Sales.OrderNew] Apply Sfdc Annual Membership Fee',
  props<{ payload: ApplySfdcAnnualMembershipFeePayload }>()
);
export class ApplySfdcBonusCreditTransferPayload {
  sfdcBonusCreditTransfer: number;
}
export const applySfdcBonusCreditTransfer = createAction(
  '[Sales.OrderNew] Apply Sfdc Bonus Credit Transfer',
  props<{ payload: ApplySfdcBonusCreditTransferPayload }>()
);
export class ApplySfdcCashCreditTransferPayload {
  sfdcCashCreditTransfer: number;
}
export const applySfdcCashCreditTransfer = createAction(
  '[Sales.OrderNew] Apply Sfdc Cash Credit Transfer',
  props<{ payload: ApplySfdcCashCreditTransferPayload }>()
);
export class ApplySfdcDepositPayload {
  sfdcDepositAmount: number;
  sfdcDepositAdjustment: number;
  isProductNeeded?: boolean;
}
export const applySfdcDeposit = createAction(
  '[Sales.OrderNew] Apply Sfdc Deposit Adjustment',
  props<{ payload: ApplySfdcDepositPayload }>()
);
export const clearPromotion = createAction('[Sales.OrderNew] Clear Promotion');
export const getAmendChargesRestriction = createAction(
  '[Sales.OrderNew] Is Amend Charges Button restricted by user or not',
  props<{ userId: number }>()
);
export const getProformaInvoice = createAction(
  '[Sales.OrderNew] Is Proforma Invoice by Flight Request id',
  props<{ flightRequestId: number }>()
);
export class InsertItemPayload {
  constructor(
    public orderItem: models.OrderItem,
    public orderItemEditor: models.OrderItemEditorEnum
  ) {}
}
export const insertItem = createAction(
  '[Sales.OrderNew] Insert Item',
  props<{ payload: InsertItemPayload }>()
);

export class LoadReferredByClientPayload {
  constructor(public id: number, public index: number) {}
}
export const loadReferredByClient = createAction(
  '[Sales.OrderNew] Load Referred By',
  props<{ payload: LoadReferredByClientPayload }>()
);
export const loadReferredByClientFail = createAction('[Sales.OrderNew] Load Referred By Fail');
export class LoadReferredByClientSuccessPayload {
  constructor(public client: models.Client, public index: number) {}
}
export const loadReferredByClientSuccess = createAction(
  '[Sales.OrderNew] Load Referred By Success',
  props<{ payload: LoadReferredByClientSuccessPayload }>()
);

export const loadSfdcOpportunity = createAction(
  '[Sales.OrderNew] Load SFDC opportunity',
  props<{ payload: string }>()
);
export class SfdcOpportunityPayload {
  constructor(public sfdcOpportunityData: models.SfdcOpportunityData) {}
}
export const loadSfdcOpportunitySuccess = createAction(
  '[Sales.OrderNew] Load SFDC opportunity Success',
  props<{ payload: SfdcOpportunityPayload }>()
);
export const loadSfdcOpportunityFail = createAction('[Sales.OrderNew] Load SFDC Opportunity Fail');

export const loadSfdcOpportunityCancelOrder = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity cancel order',
  props<{ id: string }>()
);
export const loadSfdcOpportunityCancelOrderFail = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity cancel order Fail'
);
export class SfdcOpportunityCancelOrderSuccessPayload {
  sfdcOpportunityCancelOrderData: models.SfdcOpportunityCancelData;
}
export const loadSfdcOpportunityCancelOrderSuccess = createAction(
  '[Sales.OrderNew] Load SFDC opportunity cancel order Success',
  props<{ payload: SfdcOpportunityCancelOrderSuccessPayload }>()
);

export const loadSfdcOpportunityCreditMemo = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Credit Memo',
  props<{ payload: string }>()
);
export const loadSfdcOpportunityCreditMemoFail = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Credit Memo Fail'
);
export class SfdcOpportunityCreditMemoPayload {
  constructor(public sfdcOpportunityCreditMemoData: models.SfdcOpportunityCreditMemoData) {}
}
export const loadSfdcOpportunityCreditMemoSuccess = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Credit Memo Success',
  props<{ payload: SfdcOpportunityCreditMemoPayload }>()
);

export const loadSfdcOpportunityFlightPass = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Flight Pass',
  props<{ payload: string }>()
);
export class SfdcOpportunityFlightPassPayload {
  constructor(public sfdcOpportunityFlightPassData: models.SfdcOpportunityFlightPassData) {}
}
export const loadSfdcOpportunityFlightPassSuccess = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Flight Pass Success',
  props<{ payload: SfdcOpportunityFlightPassPayload }>()
);
export const loadSfdcOpportunityFlightPassFail = createAction(
  '[Sales.OrderNew] Load SFDC Opportunity Flight Pass Fail'
);

export const lookupPromotion = createAction(
  '[Sales.OrderNew] Lookup Promotion',
  props<{ payload: string }>()
);
export const lookupPromotionSuccess = createAction(
  '[Sales.OrderNew] Lookup Promotion Success',
  props<{ payload: models.Promotion }>()
);
export const lookupPromotionFail = createAction('[Sales.OrderNew] Lookup Promotion Fail');

export const placeOrder = createAction('[Sales.OrderNew] Place Order');
export const placeOrderSuccess = createAction(
  '[Sales.OrderNew] Place Order Success',
  props<{ payload: number }>()
);
export const placeOrderFail = createAction(
  '[Sales.OrderNew] Place Order Fail',
  props<{ payload: any }>()
);

export class RemoveGroupMembershipBeneficiariesPayload {
  constructor(public client: models.Client) {}
}
export const removeGroupMembershipBeneficiaries = createAction(
  '[Sales.OrderNew] Remove Group Membership Beneficiaries',
  props<{ payload: RemoveGroupMembershipBeneficiariesPayload }>()
);
export const removeItem = createAction(
  '[Sales.OrderNew] Remove Item',
  props<{ payload: number }>()
);
export const reset = createAction('[Sales.OrderNew] Reset');
export const selectOrderItem = createAction(
  '[Sales.OrderNew] Select Order Item',
  props<{ payload: number }>()
);
export const setAutoAccept = createAction(
  '[Sales.OrderNew] Set Auto Accept',
  props<{ payload: boolean }>()
);
export class SetBillingAsOrderItemClientPayload {
  constructor(public client: models.Client) {}
}
export const setBillingAsOrderItemClient = createAction(
  '[Sales.OrderNew] Set Billing As Order Item Client',
  props<{ payload: SetBillingAsOrderItemClientPayload }>()
);
export const setCharterOrderItemProduct = createAction(
  '[Sales.OrderNew] Set Charter Order Item Product'
);
export const setClient = createAction(
  '[Sales.OrderNew] Set Client',
  props<{ payload: models.Client }>()
);
export const setContactMethods = createAction(
  '[Sales.OrderNew] Set ContactMethods',
  // TODO: ????? SET Contact Methods and invoice - error
  props<{ payload: models.Invoice }>()
);
export class SetGroupMembershipBeneficiariesPayload {
  constructor(public client: models.Client, public index: number) {}
}
export const setGroupMembershipBeneficiaries = createAction(
  '[Sales.OrderNew] Set Group Membership Beneficiaries',
  props<{ payload: SetGroupMembershipBeneficiariesPayload }>()
);
export class SetGroupMembershipSlotOrderCountPayload {
  constructor(public parentIndex: number, public quantity: number) {}
}
export const setGroupMembershipSlotOrderItemCount = createAction(
  '[Sales.OrderNew] Set Group Membership Slot Order Item Count',
  props<{ payload: SetGroupMembershipSlotOrderCountPayload }>()
);
export const setInitial = createAction('[Sales.OrderNew] Set initial');
export const setInvoice = createAction(
  '[Sales.OrderNew] Set Invoice',
  props<{ payload: models.Invoice }>()
);
export const setIsProformaInvoice = createAction(
  '[Sales.OrderNew] Set isProformaInvoice',
  props<{ isProformaInvoice: boolean }>()
);
export const setIsProformaInvoiceCheckRunning = createAction(
  '[Sales.OrderNew] Set isProformaInvoice check running',
  props<{ isRunningProformaCheck: boolean }>()
);
export const setMembershipClientService = createAction(
  '[Sales.OrderNew] Set Membership Client Service Action',
  props<{ payload: models.ClientService }>()
);
export class SetMembershipOrderItemProductPayload {
  constructor(public index: number, public product: models.Product) {}
}
export const setMembershipOrderItemProduct = createAction(
  '[Sales.OrderNew] Set Membership Order Item Product',
  props<{ payload: SetMembershipOrderItemProductPayload }>()
);
export class SetMembershipOrderItemSalesActionPayload {
  constructor(public index: number, public salesAction: models.SalesActionEnum) {}
}
export const setMembershipOrderItemSales = createAction(
  '[Sales.OrderNew] Set Membership Order Item Sales',
  props<{ payload: SetMembershipOrderItemSalesActionPayload }>()
);
export const setOrderBilling = createAction(
  '[Sales.OrderNew] Set Order Billing',
  props<{ payload: models.OrderBilling }>()
);
export const setOrderInvalid = createAction('[Sales.OrderNew] Set order invalid');
export class SetOrderItemClientPayload {
  constructor(public index: number, public client: models.Client) {}
}
export const setOrderItemClient = createAction(
  '[Sales.OrderNew] Set Order Item Client',
  props<{ payload: SetOrderItemClientPayload }>()
);
export class SetOrderItemsPayload {
  constructor(
    public orderItems: Array<models.OrderItem>,
    public orderItemEditor: models.OrderItemEditorEnum,
    public lastOrder: models.ConciergeLastOrder = null
  ) {}
}
export const setOrderItems = createAction(
  '[Sales.OrderNew] Set Order Items',
  props<{ payload: SetOrderItemsPayload }>()
);
export const setOrderItemsCreditReasons = createAction(
  '[Sales.OrderNew] Set Order Items Credit Reasons',
  props<{ payload: Array<models.CreditReason> }>()
);
export const setOrderLegacyAdjustment = createAction(
  '[Sales.OrderNew] Set Order Legacy Adjustment',
  props<{ payload: models.LegacyAdjustmentOrderItem }>()
);
export const setOrderProrateExtensionMonth = createAction(
  '[Sales.OrderNew] Set Order Prorate Extension Month',
  props<{ payload: number }>()
);
export const setOrderProrateProduct = createAction(
  '[Sales.OrderNew] Set Order Prorate Product',
  props<{ payload: models.Product }>()
);
export const setOrderProrateType = createAction(
  '[Sales.OrderNew] Set Order Prorate Type',
  props<{ payload: models.OrderItemProrateTypeEnum }>()
);
export class SetOrderReferredByPayload {
  constructor(public client: models.Client, public index: number) {}
}
export const setOrderReferredBy = createAction(
  '[Sales.OrderNew] Set Order Referred By',
  props<{ payload: SetOrderReferredByPayload }>()
);
export const setPaymentOptions = createAction(
  '[Sales.OrderNew] Set Payment Options',
  props<{ payload: models.OrderPaymentOptions }>()
);
export class SetProductOrderItemAlignPayload {
  constructor(public index: number, public align: boolean) {}
}
export const setProductOrderItemAlign = createAction(
  '[Sales.OrderNew] Set Product Order Item Align',
  props<{ payload: SetProductOrderItemAlignPayload }>()
);
export class SetProductOrderItemProductPayload {
  constructor(public index: number, public product: models.Product) {}
}
export const setProductOrderItemProduct = createAction(
  '[Sales.OrderNew] Set Product Order Item Product',
  props<{ payload: SetProductOrderItemProductPayload }>()
);

export const setPromotion = createAction(
  '[Sales.OrderNew] Set Promotion',
  props<{ payload: models.Promotion }>()
);
export class SetPromotionSuccessPayload {
  constructor(
    public promotion: models.Promotion,
    public orderItems: Array<models.OrderItem>,
    public orderItemEditors: Array<models.OrderItemEditorEnum>
  ) {}
}
export const setPromotionSuccess = createAction(
  '[Sales.OrderNew] Set Promotion Success',
  props<{ payload: SetPromotionSuccessPayload }>()
);

export const setSfdcCreditMemoDialogSubmit = createAction(
  '[Sales.OrderNew] Set Sfdc Credit Memo Dialog Submit',
  props<{ payload: boolean }>()
);
export const setSfdcDialogSubmit = createAction(
  '[Sales.OrderNew] Set sfdc dialog Submit',
  props<{ payload: boolean }>()
);
export const setSfdcFlightPassDialogSubmit = createAction(
  '[Sales.OrderNew] Set Sfdc Flight Pass Dialog Submit',
  props<{ payload: boolean }>()
);
export const setShowAmendChargesButton = createAction(
  '[Sales.OrderNew] Set Show Amend Charges Button',
  props<{ showAmendChargesButton: boolean }>()
);
export const updateOrderItem = createAction(
  '[Sales.OrderNew] Update Order Item',
  props<{ payload: UpdateOrderItemPayload }>()
);
