/* eslint-disable complexity */
import * as actions from '../actions/charter-pricing-collection';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  rules: Array<models.CharterPricingRule>;
  lookup: viewModels.CharterPricingLookup;
  totalCount: number;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  rules: [],
  lookup: new viewModels.CharterPricingLookup(),
  totalCount: 0,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        lookup: _.cloneDeep(action.payload.lookup),
      };
    }

    case actions.LOAD_SUCCESS: {
      const rules = _.cloneDeep(action.payload.items);
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...state,
        rules,
        lookup,
        isLoaded: true,
        isLoading: false,
        totalCount: _.cloneDeep(action.payload.total),
      };
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        isLoaded: true,
        lookup,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getRules = (state: State) => state.rules;
export const getLookup = (state: State) => state.lookup;
export const getTotalCount = (state: State) => state.totalCount;
