import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD_CANCELLATION_TERMS = '[Sourcing Cancellation Terms] Load';
export const LOAD_ACCEPTED_CANCELLATION_TERMS = '[Sourcing Accepted Cancellation Terms] Load';
export const LOAD_CANCELLATION_TERMS_SUCCESS = '[Sourcing Cancellation Terms] Load Success';
export const LOAD_CANCELLATION_TERMS_FAIL = '[Sourcing Cancellation Terms] Load Fail';

export class LoadTermsPayload {
  constructor(public operatorId: string) {
  }
}

export class LoadAcceptedTermsPayload {
  constructor(public purchaseOfferId: string) {
  }
}

export class LoadTermsAction implements Action {
  readonly type = LOAD_CANCELLATION_TERMS;

  constructor(public payload: LoadTermsPayload) {
  }
}

export class LoadAcceptedTermsAction implements Action {
  readonly type = LOAD_ACCEPTED_CANCELLATION_TERMS;

  constructor(public payload: LoadAcceptedTermsPayload) {
  }
}

export class LoadTermsSuccessAction implements Action {
  readonly type = LOAD_CANCELLATION_TERMS_SUCCESS;

  constructor(public payload: models.CancellationTerms) {
  }
}

export class LoadTermsFailAction implements Action {
  readonly type = LOAD_CANCELLATION_TERMS_FAIL;
}

export type Actions = LoadTermsAction | LoadAcceptedTermsAction | LoadTermsSuccessAction | LoadTermsFailAction;
