import * as models from '../../domain/models';
import * as actions from '../actions/sourcing-request';
import * as _ from 'lodash';

export interface State {
  sourcingRequest: models.SourcingRequest;
  loaded: boolean;
  loading: boolean;
  priceBreakdown: models.FlightPriceBreakdown;
  moderatedOffers: models.ModeratedOffers;
  sendModeratedOffersLoading: boolean;
  isPriceBreakdownLoading: boolean;
  taxBreakdown: Array<models.TaxItem>;
  moderatedLegs: Array<models.LegRequest>;
  isTaxBreakdownLoading: boolean;
  isValidationLoading: boolean
  validationOfferResults: models.OfferValidationResults;
  purchaseOfferCharterAgreement: Blob;
  elacPriceInfo: models.ElacPriceInfo;
  elacPriceInfoLoading: boolean;
  elacPriceInfoLoaded: boolean;
  marketplaceQuotes: Array<models.MarketplaceQuote>;
  marketplaceQuotesLoading: boolean;
  marketplaceQuotesLoaded: boolean;
  offerListLoading: boolean;
}

const initialState: State = {
  sourcingRequest: null,
  loaded: false,
  loading: false,
  priceBreakdown: new models.FlightPriceBreakdown(),
  moderatedOffers: new models.ModeratedOffers(),
  sendModeratedOffersLoading: false,
  isPriceBreakdownLoading: false,
  taxBreakdown: [],
  moderatedLegs: [],
  isTaxBreakdownLoading: false,
  isValidationLoading: false,
  validationOfferResults: null,
  purchaseOfferCharterAgreement: null,
  elacPriceInfo: null,
  elacPriceInfoLoading: false,
  elacPriceInfoLoaded: true,
  marketplaceQuotes: [],
  marketplaceQuotesLoading: false,
  marketplaceQuotesLoaded: true,
  offerListLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        sourcingRequest: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case actions.GET_PRICE_BREAKDOWN: {
      return {
        ...state,
        isPriceBreakdownLoading: true,
      };
    }

    case actions.GET_PRICE_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        priceBreakdown: action.payload,
        isPriceBreakdownLoading: false,
      };
    }

    case actions.GET_PRICE_BREAKDOWN_FAIL: {
      return {
        ...state,
        isPriceBreakdownLoading: false,
      };
    }
    case actions.GET_TAX_BREAKDOWN:
    case actions.GET_EFTS: {
      return {
        ...state,
        isTaxBreakdownLoading: true
      };
    }

    case actions.GET_TAX_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        taxBreakdown: action.payload,
        isTaxBreakdownLoading: false,
      };
    }

    case actions.GET_EFTS_SUCCESS: {
      return {
        ...state,
        moderatedLegs: action.payload.legs,
        isTaxBreakdownLoading: false,
      }
    }

    case actions.GET_TAX_BREAKDOWN_FAIL: {
      return {
        ...state,
        isTaxBreakdownLoading: false,
      };
    }

    case actions.SET_MODERATED_OFFERS: {
      return {
        ...state,
        moderatedOffers: _.cloneDeep(action.payload)
      };
    }

    case actions.FULFILL_AND_SEND_OFFERS:
    case actions.SEND_PURCHASE_OFFERS: {
      return {
        ...state,
        sendModeratedOffersLoading: true
      };
    }

    case actions.SEND_PURCHASE_OFFERS_SUCCESS:
    case actions.SEND_PURCHASE_OFFERS_FAIL: {
      return {
        ...state,
        sendModeratedOffersLoading: false
      };
    }

    case actions.BOOK_PROFORMA: {
      return {
        ...state,
        isValidationLoading: true
      }
    }

    case actions.BOOK_PROFORMA_SUCCESS:
    case actions.BOOK_PROFORMA_FAIL: {
      return {
        ...state,
        isValidationLoading: false
      }
    }

    case actions.VALIDATE_PURCHASE_OFFER_SUCCESS: {
      return {
        ...state,
        validationOfferResults: action.payload
      }
    }

    case actions.RESET_VALIDATION_RESULTS: {
      return {
        ...state,
        validationOfferResults: null
      }
    }

    case actions.GET_CHARTER_AGREEMENT_SUCCESS: {
      return {
        ...state,
        purchaseOfferCharterAgreement: action.payload.blob
      }
    }

    case actions.GET_ELAC_QUOTES: {
      return {
        ...state,
        elacPriceInfoLoading: true,
        elacPriceInfoLoaded: false,
      }
    }

    case actions.GET_ELAC_QUOTES_SUCCESS: {
      return {
        ...state,
        elacPriceInfo: action.payload,
        elacPriceInfoLoading: false,
        elacPriceInfoLoaded: true,
      }
    }

    case actions.GET_ELAC_QUOTES_FAIL: {
      return {
        ...state,
        elacPriceInfoLoading: false,
        elacPriceInfoLoaded: false,
      }
    }

    case actions.GET_MARKETPLACE_QUOTES: {
      return {
        ...state,
        marketplaceQuotesLoading: true,
        marketplaceQuotesLoaded: false,
      }
    }

    case actions.GET_MARKETPLACE_QUOTES_SUCCESS: {
      return {
        ...state,
        marketplaceQuotes: action.payload,
        marketplaceQuotesLoading: false,
        marketplaceQuotesLoaded: true,
      }
    }

    case actions.GET_MARKETPLACE_QUOTES_FAIL: {
      return {
        ...state,
        marketplaceQuotesLoading: false,
        marketplaceQuotesLoaded: false,
      }
    }

    case actions.SAVE_OFFER_COMMENT: {
      return {
        ...state,
        offerListLoading: true
      }
    }

    case actions.SAVE_OFFER_COMMENT_FAIL: {
      return {
        ...state,
        offerListLoading: false
      }
    }

    case actions.SAVE_OFFER_COMMENT_SUCCESS: {
      const sourcingRequest = _.cloneDeep(state.sourcingRequest);
      const payload = action.payload;
      const foundOffer = sourcingRequest.getOperatorPurchaseOffers().find(offer => offer.getOperatorPurchaseOfferId() === payload.purchaseOfferId);
      if (foundOffer) {
        foundOffer.setOfferComment(payload.comment);
      }
      return {
        ...state,
        sourcingRequest,
        offerListLoading: false
      }
    }

    case actions.EXTEND_OFFER: {
      return {
        ...state,
        offerListLoading: true,
      }
    }

    case actions.EXTEND_OFFER_FAIL: {
      return {
        ...state,
        offerListLoading: false,
      }
    }

    case actions.CREATE_MULTI_NGA_PURCHASE_OFFER:
    case actions.CREATE_NGA_PURCHASE_OFFER:
    case actions.CREATE_GA_PURCHASE_OFFER:
    case actions.CREATE_NGA_OFFER_AND_ACCEPT:
    case actions.ACCEPT_GA_PURCHASE_OFFER: {
      return {
        ...state,
        offerListLoading: true
      }
    }

    case actions.CREATE_PURCHASE_OFFER_SUCCESS:
    case actions.CREATE_PURCHASE_OFFER_FAIL:
    case actions.ACCEPT_GA_PURCHASE_OFFER_SUCCESS:
    case actions.ACCEPT_GA_PURCHASE_OFFER_FAIL: {
      return {
        ...state,
        offerListLoading: false
      }
    }

    case actions.SET_MODERATED_LEGS: {
      return {
        ...state,
        moderatedLegs: action.payload
      }
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSourcingRequest = (state: State) => state.sourcingRequest;
export const getPriceBreakdown = (state: State) => state.priceBreakdown;
export const getIsPriceBreakdownLoading = (state: State) => state.isPriceBreakdownLoading;
export const getModeratedOffers = (state: State) => state.moderatedOffers;
export const getSendModeratedOffersLoading = (state: State) => state.sendModeratedOffersLoading;
export const getTaxBreakdown = (state: State) => state.taxBreakdown;
export const getIsTaxBreakdownLoading = (state: State) => state.isTaxBreakdownLoading;
export const getIsValidationLoading = (state: State) => state.isValidationLoading;
export const getValidationOfferResults = (state: State) => state.validationOfferResults;
export const getPurchaseOfferCharterAgreement = (state: State) => state.purchaseOfferCharterAgreement;
export const getElacPriceInfo = (state: State) => state.elacPriceInfo;
export const getElacPriceInfoLoading = (state: State) => state.elacPriceInfoLoading;
export const getElacPriceInfoLoaded = (state: State) => state.elacPriceInfoLoaded;
export const getElacPriceInfoFailed = (state: State) => !state.elacPriceInfoLoading && !state.elacPriceInfoLoaded;
export const getMarketplaceQuotes = (state: State) => state.marketplaceQuotes;
export const getMarketplaceQuotesLoading = (state: State) => state.marketplaceQuotesLoading;
export const getMarketplaceQuotesLoaded = (state: State) => state.marketplaceQuotesLoaded;
export const getOfferListLoading = (state: State) => state.offerListLoading;
export const getModeratedLegs = (state: State) => state.moderatedLegs;