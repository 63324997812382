import * as models from '../../../../domain/models';
import * as dto from '../contracts';

export class PaymentOptionsMapper {
  toDto(po: models.OrderPaymentOptions): dto.OrderPaymentOptionsDto {
    const pod = new dto.OrderPaymentOptionsDto();
    pod.splitPayment = po.splitPayment;
    if (pod.splitPayment) {
      pod.splitPayments = po.splitPayments.map((sp) => {
        const spp = new dto.SplitPaymentDto();
        spp.clientId = sp.client ? sp.client.id : null;
        spp.splitAmount = sp.splitAmount;
        spp.businessName = sp.businessName ? sp.businessName : null;

        return spp;
      });
    }

    pod.paymentByWireTransferOnly = po.paymentByWireTransferOnly;

    return pod;
  }
}
