import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class SetRestrictionsPayload {
  constructor(public entities: Array<models.FunctionRestriction>) {}
}
export const setRestrictions = createAction(
  '[Sales.Security] Set Restrictions',
  props<{ payload: SetRestrictionsPayload }>()
);

export class ResetPayload {
  constructor(public entity: models.SecurityAccess) {}
}
export const reset = createAction('[Sales.Security] Reset', props<{ payload: ResetPayload }>());
export const resetSuccess = createAction('[Sales.Security] Reset Success');
export const resetFail = createAction('[Sales.Security] Reset Fail');

export class AuthorizeOrderPayload {
  constructor(public securityAccess: models.SecurityAccess) {}
}
export const authorizeOrder = createAction(
  '[Sales.Security] Authorize Order',
  props<{ payload: AuthorizeOrderPayload }>()
);
export class AuthorizeOrderSuccessPayload {
  constructor(public entities: Array<models.AuthorizationRequest>) {}
}
export const authorizeOrderSuccess = createAction(
  '[Sales.Security] Authorize Order Success',
  props<{ payload: AuthorizeOrderSuccessPayload }>()
);
export const authorizeOrderFail = createAction('[Sales.Security] Authorize Order Fail');

export class AuthorizeFunctionalityPayload {
  constructor(
    public securityAccess: models.SecurityAccess,
    public entity: models.AuthorizationRequest
  ) {}
}
export const authorizeFunctionality = createAction(
  '[Sales.Security] Authorize Functionality',
  props<{ payload: AuthorizeFunctionalityPayload }>()
);
export class AuthorizeFunctionalitySuccessPayload {
  constructor(public entities: Array<models.AuthorizationRequest>) {}
}
export const authorizeFunctionalitySuccess = createAction(
  '[Sales.Security] Authorize Functionality Success',
  props<{ payload: AuthorizeFunctionalitySuccessPayload }>()
);
export const authorizeFunctionalityFail = createAction(
  '[Sales.Security] Authorize Functionality Fail'
);
