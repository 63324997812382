import * as moment from 'moment';
import { AircraftCategory } from '../aircraft-category';
import { Vendor } from '../vendor';

export class RmnFilterCriteria {
  startDate: string = moment().utc().format('YYYY-MM-DD');
  endDate: string = moment().utc().add(30, 'days').format('YYYY-MM-DD');
  aircraftCategory: AircraftCategory = new AircraftCategory().setId(5).setName('Mid Size Jet');
  vendor: Vendor = new Vendor()
    .setId('0f0fec1d-0cd3-459e-a2ff-24f38a8e216c')
    .setName('VistaJet Ltd');
}
