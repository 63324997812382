import { PaymentTransaction } from './payment-transaction';
import { PaymentTransactionTypeEnum } from './payment-transaction-type-enum';

export class PaymentTransactionCheck extends PaymentTransaction {
  referenceNumber: string = null;

  get paymentTransactionType(): PaymentTransactionTypeEnum {
    return PaymentTransactionTypeEnum.Check;
  }
}
