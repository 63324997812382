import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/layout.actions';
import * as models from '../models';

export const layoutFeatureKey = 'layout';

export interface State {
  isAppView: boolean;
  isError: boolean;
  errorText: string;
  isFullHeightView: boolean;
  isFullWidthView: boolean;
  isLoading: boolean;
  isNamedOutletLoading: boolean;
  layoutType: models.LayoutType;
  serverEnvironment: string;
  menuPin: models.MenuPin;
}

const initialState: State = {
  isAppView: false,
  isError: false,
  errorText: null,
  isFullHeightView: false,
  isFullWidthView: false,
  isLoading: false,
  isNamedOutletLoading: false,
  layoutType: models.LayoutType.Default,
  serverEnvironment: 'prod',
  menuPin: 0,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.closeSidenav,
    (state): State => ({
      ...state,
      isAppView: false,
    })
  ),

  on(
    actions.openSidenav,
    (state): State => ({
      ...state,
    })
  ),

  on(
    actions.activateAppView,
    (state): State => ({
      ...state,
      isAppView: true,
    })
  ),

  on(
    actions.activateFullHeightView,
    (state): State => ({
      ...state,
      isFullHeightView: true,
    })
  ),

  on(
    actions.deactivateAppView,
    (state): State => ({
      ...state,
      isAppView: false,
    })
  ),

  on(
    actions.deactivateFullHeightView,
    (state): State => ({
      ...state,
      isFullHeightView: false,
    })
  ),

  on(
    actions.showError,
    (state, { payload }): State => ({
      ...state,
      isError: true,
      errorText: payload,
    })
  ),

  on(
    actions.showSpinner,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),

  on(
    actions.hideSpinner,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.showNamedOutletSpinner,
    (state): State => ({
      ...state,
      isNamedOutletLoading: true,
    })
  ),

  on(
    actions.hideNamedOutletSpinner,
    (state): State => ({
      ...state,
      isNamedOutletLoading: false,
    })
  ),

  on(
    actions.setIsFullWidth,
    (state, { payload }): State => ({
      ...state,
      isFullWidthView: payload,
    })
  ),

  on(
    actions.setLayout,
    (state, { payload }): State => ({
      ...state,
      layoutType: payload,
    })
  ),

  on(
    actions.setMenuPin,
    (state, { payload }): State => ({
      ...state,
      menuPin: payload,
    })
  ),

  on(
    actions.setServerEnvironment,
    (state, { payload }): State => ({
      ...state,
      serverEnvironment: payload,
    })
  )
);
