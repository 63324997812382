import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import { VendorOrganizationUser } from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Users Collection] Load';
export const LOAD_SUCCESS = '[RM Users Collection] Load Success';
export const LOAD_FAIL = '[RM Users Collection] Load Fail';
export const SAVE_USER = '[RM Users Collection] Save';
export const SAVE_USER_SUCCESS = '[RM Users Collection] Save Success';
export const SAVE_USER_FAIL = '[RM Users Collection] Save Fail';
export const UPDATE_USER = '[RM Users Collection] Update';
export const UPDATE_USER_SUCCESS = '[RM Users Collection] Update Success';
export const UPDATE_USER_FAIL = '[RM Users Collection] Update Fail';
export const RESET = '[RM Users Collection] Reset';


export class LoadPayload {
  constructor(public lookup: viewModels.UsersLookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<models.VendorOrganizationUser>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SavePayload {
  constructor(public item: VendorOrganizationUser) {}
}

export class SaveAction implements Action {
  readonly type = SAVE_USER;

  constructor(public payload: SavePayload) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_USER_SUCCESS;
}

export class SaveFailAction implements Action {
  readonly type = SAVE_USER_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}


export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | ResetAction;
