import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'grid-dropdown-filter-cell',
  template: `
    <ng-container *ngIf="!dataPrimitive; else dropdownPrimitive">
        <kendo-dropdownlist
            [data]="data"
            [defaultItem]="defaultItem"
            [valueField]="valueField"
            [textField]="textField"
            [valuePrimitive]="true"
            (valueChange)="onChange($event)"
        >
        </kendo-dropdownlist>
    </ng-container>

    <ng-template #dropdownPrimitive>
        <kendo-dropdownlist
            [data]="data"
            [defaultItem]="defaultItem"
            (valueChange)="onChange($event)"
        >
        </kendo-dropdownlist>
    <ng-template>
    
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridDropdownFilterCellComponent extends BaseFilterCellComponent {

    @Input() public filter: CompositeFilterDescriptor = null;
    @Input() public data: string[];
    @Input() public valueField: string;
    @Input() public textField: string;
    @Input() public field: string;
    @Input() public defaultText: string = ' ';
    @Input() public dataPrimitive: boolean = false;

    get defaultItem(): any {
        return this.dataPrimitive 
            ? this.defaultText
            : {[this.valueField]: null, [this.textField]: this.defaultText}
    }
    
    constructor(filterService: FilterService) {
        super(filterService);
    }
    
    public onChange(value: any): void {
        const defaultValue = this.defaultItem[this.valueField] !== undefined 
            ? this.defaultItem[this.valueField]
            : this.defaultItem;
            
        this.applyFilter(
            value === defaultValue
                ? this.removeFilter(this.field)
                : this.updateFilter({
                    field: this.field,
                    operator: "eq",
                    value: value,
                })
        );
    }
}
