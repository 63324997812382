import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

import { IConfigService } from './contracts';

@Injectable()
export class ConfigMockService implements IConfigService {
  private config: Object = {};

  get(key: string): string {
    return this.config[key];
  }

  load(): void {
    this.config['AuthorizationToken'] = environment.bearerToken;
    this.config['AircraftServiceEndpoint'] = environment.aircraftServiceEndpoint;
    this.config['ClientServiceEndpoint'] = environment.clientServiceEndpoint;
    this.config['ClientOperatorServiceEndpoint'] = environment.clientOperatorServiceEndpoint;
    this.config['ConsoleAppEndpoint'] = environment.consoleAppEndpoint;
    this.config['CharterPricingServiceEndpoint'] = environment.charterPricingServiceEndpoint;
    this.config['CharterPricingServiceEndpointV1'] = environment.charterPricingServiceEndpointV1;
    this.config['ErrorLoggerServiceEndpoint'] = window.location.origin;
    this.config['FlightAnalyticsServiceEndpoint'] = environment.flightAnalyticsServiceEndpoint;
    this.config['InvoiceAppEndpoint'] = environment.invoiceAppEndpoint;
    this.config['InvoiceServiceEndpoint'] = environment.invoiceServiceEndpoint;
    this.config['JetPulseServiceEndpoint'] = environment.jetPulseServiceEndpoint;
    this.config['JetSalesServiceEndpoint'] = environment.jetSalesServiceEndpoint;
    this.config['LegServiceEndpoint'] = environment.legServiceEndpoint;
    this.config['MarketplaceServiceEndpoint'] = environment.marketplaceServiceEndpoint;
    this.config['MembershipServiceEndpoint'] = environment.membershipServiceEndpoint;
    this.config['OperatorServiceEndpoint'] = environment.operatorServiceEndpoint;
    this.config['OrderServiceEndpoint'] = environment.orderServiceEndpoint;
    this.config['OfferServiceEndpoint'] = environment.offerServiceEndpoint;
    this.config['ProductServiceEndpoint'] = environment.productServiceEndpoint;
    this.config['RouteGroupServiceEndpoint'] = environment.routeGroupServiceEndpoint;
    this.config['RouteManagementServiceEndpoint'] = environment.routeManagementServiceEndpoint;
    this.config['SeatPricingServiceEndpoint'] = environment.seatPricingServiceEndpoint;
    this.config['CyoPricingServiceEndpoint'] = environment.cyoPricingServiceEndpoint;
    this.config['CharterSalesServiceEndpoint'] = environment.charterSalesServiceEndpoint;
    this.config['OperatorApiServiceEndpoint'] = environment.operatorApiServiceEndpoint;
    this.config['SourcingApiServiceEndpoint'] = environment.sourcingApiServiceEndpoint;
    this.config['OperatorAnalyticsApiServiceEndpoint'] = environment.operatorAnalyticsApiServiceEndpoint;
    this.config['BookingApiServiceEndpoint'] = environment.bookingApiServiceEndpoint;
    this.config['CharterPricingAnalyticsEndpoint'] = environment.charterPricingAnalyticsEndpoint;
    this.config['CharterPricingAnalyticsEndpointV1'] = environment.charterPricingAnalyticsEndpointV1;
    this.config['TaskApiServiceEndpoint'] = environment.taskApiServiceEndpoint;
    this.config['CustomShuttleServiceEndpoint'] = environment.customShuttleServiceEndpoint;
    this.config['SeatChartsUrl'] = environment.seatChartsUrl;
    this.config['FlightPreviewOptionsEndpoint'] = environment.flightPreviewOptionsEndpoint;
    this.config['OperatorPlatformApiServiceEndpoint'] = environment.operatorPlatformApiServiceEndpoint;
    this.config['ClientAppEndpoint'] = environment.clientAppEndpoint;
    this.config['ClientSearchEndpoint'] = environment.clientSearchEndpoint;
    this.config['MasterApiEndpoint'] = environment.masterApiEndpoint;
    this.config['EmailServiceEndpoint'] = environment.emailServiceEndpoint;
    this.config['IdentityProvider'] = environment.identityProvider;
    this.config['RmDashboardExternalUrl'] = environment.rmDashboardExternalUrl;
    this.config['ErpServiceEndpoint'] = environment.erpServiceEndpoint;
  }
}
