import { AuthService } from '@auth0/auth0-angular';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as coreAuth0ErrorActions from '../actions/auth0-error-notification.actions';

@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private store$: Store<any>) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res) => res),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.store$.dispatch(
            coreAuth0ErrorActions.sendErrorNotification({ failedEndpoint: error.url })
          );
          // localStorage.setItem('isLegacy', 'true');
          // this.auth.logout({ returnTo: document.location.origin });
        }

        return throwError(error);
      })
    );
  }
}
