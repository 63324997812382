export class IntegrationStatus {
  integrated: boolean = null;
  fms: string;
  lastUpdate: Date;
  operatorId: number = null;

  getIntegrated(): boolean {
    return this.integrated;
  }

  setIntegrated(integrated: boolean): IntegrationStatus {
    this.integrated = integrated;

    return this;
  }

  getFms(): string {
    return this.fms;
  }

  setFms(fms: string): IntegrationStatus {
    this.fms = fms;

    return this;
  }

  getLastUpdate(): Date {
    return this.lastUpdate;
  }

  setLastUpdate(lastUpdate: Date): IntegrationStatus {
    this.lastUpdate = lastUpdate;

    return this;
  }

  getOperatorId(): number {
    return this.operatorId;
  }

  setOperatorId(operatorId: number): IntegrationStatus {
    this.operatorId = operatorId;

    return this;
  }

}