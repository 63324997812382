export const response = {
  content: [
    {
      id: null,
      name: 'Super Mid Size Jets',
      actype: 'SMID',
    },
    {
      id: null,
      name: 'Light Jets',
      actype: 'LITE',
    },
    {
      id: null,
      name: 'Challenger 300',
      actype: 'CL30',
    },
  ],
};
