import { SeatPricingRuleDto } from './seat-pricing-rule-dto';

export class RouteGroupRuleDto {
  routeGroupNames: Array<string> = new Array<string>();
  aircraftCategories: Array<string> = new Array<string>();
  shuttleTypeNames: Array<string> = new Array<string>();
  dow: Array<string> = null;
  totalSeats: number = null;
  seat: SeatPricingRuleDto = null;
}
