export enum UserTypeEnumType {
  System = 1,
  Partner = 2,
  Pilot = 3,
  ShuttleExperienceManager = 4,
  SalesRep = 5,
  Accountant = 6,
  Executive = 7,
  Investor = 8,
  K9 = 9,
  AviationSpecialist = 10,
  BusinessDevelopment = 11,
  Operator = 12,
  Marketing = 13,
  Engineering = 14,
  Sourcing = 15,
  Analyst = 16,
  ClientService = 17,
}
