import { ClientService } from './client-service';
import { ClientServiceTypeEnum } from './client-service-type';
import { GroupMembershipOrderItem } from './group-membership-order-item';

export class GroupMembershipClientService extends ClientService {
  slotsCount = 1;
  membershipOrderItem: GroupMembershipOrderItem = null;

  get clientServiceType(): ClientServiceTypeEnum {
    return ClientServiceTypeEnum.GroupMembership;
  }
}
