import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class LoadPayload {
  tasks: Array<number>;
}
export const load = createAction(
  '[TaskMgmt.TaskAdditionalInfoCollection] Load',
  props<{ payload: LoadPayload }>()
);
export const loadSuccess = createAction(
  '[TaskMgmt.TaskAdditionalInfoCollection] Load Success',
  props<{ payload: Array<models.TaskAdditionalInfo> }>()
);
export const loadFail = createAction('[TaskMgmt.TaskAdditionalInfoCollection] Load Fail');

export const reset = createAction('[TaskMgmt.TaskAdditionalInfoCollection] Reset');
