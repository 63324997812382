export const LEG_STATES = [
  {
    legStatusId: 1,
    displayName: 'Not Started',
  },
  {
    legStatusId: 2,
    displayName: 'In progress',
  },
  {
    legStatusId: 3,
    displayName: 'Finalized',
  },
  {
    legStatusId: 4,
    displayName: 'Canceled',
  },
];
