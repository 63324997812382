import * as models from '../../domain/models';
import * as viewModels from './';
import { InvoiceUiPaymentStatusEnum } from "../../domain/models";

export class OrderVM {
  id: number = null;
  orderStatusId: number = null;
  partyId: number = null;
  userId: number = null;
  client: models.Client = null;
  totalPrice: number = null;
  createTime: Date = null;
  items: Array<models.OrderItem> = [];
  invoices: Array<models.Invoice> = [];
  orderSourceId: number = null;
  invoiceUIPaymentStatusId: InvoiceUiPaymentStatusEnum;
  canCancelOrder?: boolean = null;

  orderStatusName: string = null;
  orderStatusCssClass: string = null;
  partyName: string = null;
  partyLegalName: string = null;
  userName: string = null;
  cellItems: Array<viewModels.OrderCellItemVM> = [];
  orderData: OrderVM = null;
  cancelButtonCaption = 'Cancel';
  cancelButtonDisabled = false;
  chargeableCancellation?: boolean = null;
  charterSalesCancellation?: {
    cancellationFee?: number;
    cancellationNotes?: string;
  } = {};
  sapExportStatus: Array<models.SapExportStatus> = null;
  paymentConfirmed: boolean = false;

  legs: Array<OrderVMLeg> = [];
}

export class OrderVMLeg {
  departureDate: Date;
  destination: string;
  legId: number;
  origin: string;
}
