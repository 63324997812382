export enum OfferStatusEnum {
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  EXPIRED = 'Expired',
  BID = 'Bid',
  BID_APPROVED = 'Bid Approved',
  DEFERRED = 'Deferred',
  TAKEN_ON_FLEET = 'Taken On Fleet',
}
