export class User {
  active: boolean;
  firstName: string;
  lastName: string;
  id: number;
  emailAddress: string;

  toString(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }
}
