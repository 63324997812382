import { RequestorTypeEnum } from './requestor-type-enum';

export class LegBookingHistory {
  requestId: number;
  requestTime: Date;
  calloutDays: number;
  departureDate: Date;
  requestorType: RequestorTypeEnum;
  legId: number;
  source: string;
  routeGroup: string;
  clientId: number;
  membership: string;
  seatsCount: number;
  flightCredits: number;
  tokensUsed: number;
  cashAmount: number;
  totalAmount: number;
  rebateCredits: number;
  clientName: string;
}
