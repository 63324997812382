import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";

import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from "@angular/common";
import { IconDefinition, faPowerOff } from "@fortawesome/free-solid-svg-icons";

import { HttpClient } from '@angular/common/http';
import * as coreTypes from '../../../core/types';

@Component({
  selector: 'auth0-header',
  templateUrl: './auth0-header.component.html',
})
export class Auth0HeaderComponent {
  @Input() applicationName: string;
  @Input() appSwitcherIsVisible: boolean;
  @Input() userName: string;
  @Input() logoutUrl: string;
  @Input() title: string;
  @Input() environmentName: string;
  @Output() signOut = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<void>();
  @Output() appSwitcher = new EventEmitter<number>();

  @ViewChild('anchor') public authAnchor: ElementRef;

  public showPopup = false;
  public faPowerOff: IconDefinition = faPowerOff;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService,
  ) {
  }

  getApplicationHeader(): any {
    return this.environmentName ? `<span class="text-danger"> ${this.environmentName}</span>` : '';
  }

  public onToggle(): void {
    this.showPopup = !this.showPopup;
  }

  public logout(): void {
    this.auth.logout({
      returnTo: this.document.location.origin
    });
  }

  private get API_PATH(): string {
    const endpoint = new URL(this.configService.get('CharterSalesServiceEndpoint'));
    return endpoint.origin;
  }

  public testRequest() {
    this.http.get(`${this.API_PATH}/itms/test-vj-ui`).subscribe(() => console.log('test request successful'));
  }
}

const formatString1 = (value: string, cssClass: string): any => {
  if (!cssClass) {
    throw new Error('Css class is not defined');
  }

  if (!value) {
    return value;
  }

  const words = value.match(/[A-Z][a-z]+/g);

  const regular = words.slice(0, words.length - 1).join(' ');
  const lastWord = words[words.length - 1];

  return `${regular}<span class="${cssClass}">${lastWord}</span>`;
};

const formatString2 = (value: string, cssClass: string): any => {
  if (!cssClass) {
    throw new Error('Css class is not defined');
  }

  if (!value) {
    return value;
  }

  const words = value.split(' ');

  const regular = words.slice(0, words.length - 1).join(' ');
  const lastWord = words[words.length - 1];

  return `${regular} <span class="${cssClass}">${lastWord}</span>`;
};
