import { OrderBilling } from './order-billing';
import { User } from './user';
import { SalesActionEnum } from './sales-action-enum';

export class Order {
  id: number = null;
  orderStatusId: number = null;
  // partyId: number = null;
  user: User = null;
  orderBilling: OrderBilling = null;
  totalPrice: number = null;
  productName: string = null;
  createTime: Date = null;
  orderItemsSalesActions: Array<SalesActionEnum> = [];
}
