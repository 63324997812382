import * as viewModels from '../../view/view-models';
import * as models from '../../domain/models';
import * as actions from '../actions/airport-detail';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  item: models.Airport;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  item: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        item: _.cloneDeep(action.payload.item),
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
        isLoaded: true,
      };
    }

    case actions.RESET: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItem = (state: State) => state.item;
