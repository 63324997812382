import { MembershipPlanEnum } from '../models';

export class MembershipPricingComparisonPrice {
  id: number = null;
  membershipDetails: MembershipPricingComparisonPriceMembershipDetails;
  recommended = false;
  routes: Array<MembershipPricingComparisonPriceRoute>;
  totalCost: MembershipPricingComparisonPriceTotalCost;
  offFleetCredit: number = null;
  onFleetCredit: number = null;
  lastDayOfIATAFuel: number;
  features: Array<number> = [];
  discountPercentage = null;
}

export class MembershipPricingComparisonPriceMembershipDetails {
  membershipPlan: MembershipPlanEnum;
  showLoyaltyCredits: boolean;
  membershipFee: number;
  membershipFeePeriod: string;
  minimumDeposit: number;
  flightBookingPayments: number;
  flightBookingPaymentsType: string;
  benefitAccess: string;
  referralProgram: boolean;
}

export class MembershipPricingComparisonPriceRoute {
  aircraftCategory: number = null;
  cost: MembershipPricingComparisonPriceCost;
  destination: string = null;
  id: string = null;
  blockTime: number = null;
  origin: string = null;
  pax: number = null;
}

export class MembershipPricingComparisonPriceCost {
  low: number = null;
  high: number = null;
  lowHourlyAvg: number = null;
  highHourlyAvg: number = null;
  discountValueLow: number = null;
  discountValueHigh: number = null;
  lowWithDiscount: number = null;
  fetTax: number = null;
  fetTaxPercent: number = null;
  fuelCost: number = null;
  nonMemberFee: number = null;
  priceWithoutServiceFee: boolean = null;
  segmentFee: number = null;
  serviceFee: number = null;
}

export class MembershipPricingComparisonPriceTotalCost {
  low: number = null;
  high: number = null;
  lowHourlyAvg: number = null;
  highHourlyAvg: number = null;
  discountValueLow: number = null;
  discountValueHigh: number = null;
  lowWithDiscount: number = null;
  fetTax: number = null;
  fetTaxPercent: number = null;
  fuelCost: number = null;
  nonMemberFee: number = null;
  priceWithoutServiceFee: boolean = null;
  segmentFee: number = null;
  serviceFee: number = null;
}
