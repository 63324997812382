import { User } from '../heat-map/user';

export class CustomShuttleOfferHistory {
  active: boolean;
  createTime: Date;
  customShuttlesCreationPricesHistoryLogId: number = null;
  customShuttlesCreationPricesId: number = null;
  locked: boolean;
  minSeats: number = null;
  minTotalPrice: string = null;
  pricePerExtraSeat: string = null;
  pricePerSeat: string = null;
  priceSurcharge: string = null;
  user: User;
}
