import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';

import * as actions from '../actions/system.actions';

import { Observable, of } from 'rxjs';

@Injectable()
export class DebugGuard implements CanActivate {
  constructor(private store$: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if ('debug' in route.queryParams) {
      this.store$.dispatch(actions.setDebug({ payload: true }));
    }

    return of(true);
  }
}
