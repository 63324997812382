import { User } from './user';
import { OrderStatus } from './order-status';

export class Order {
  createdDate: Date;
  createdUser: User;
  invoiceId: string;
  invoiceUrl: string;
  isPaid: boolean;
  orderId: number;
  status: OrderStatus;

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): Order {
    this.createdDate = createdDate;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): Order {
    this.createdUser = createdUser;
    return this;
  }

  getInvoiceId(): string {
    return this.invoiceId;
  }

  setInvoiceId(invoiceId: string): Order {
    this.invoiceId = invoiceId;
    return this;
  }

  getInvoiceUrl(): string {
    return this.invoiceUrl;
  }

  setInvoiceUrl(invoiceUrl: string): Order {
    this.invoiceUrl = invoiceUrl;
    return this;
  }

  getIsPaid(): boolean {
    return this.isPaid;
  }

  setIsPaid(isPaid: boolean): Order {
    this.isPaid = isPaid;
    return this;
  }

  getOrderId(): number {
    return this.orderId;
  }

  setOrderId(orderId: number): Order {
    this.orderId = orderId;
    return this;
  }

  getStatus(): OrderStatus {
    return this.status;
  }

  setStatus(status: OrderStatus): Order {
    this.status = status;
    return this;
  }
}
