import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class SfdcDepositAdjustmentOrderItem extends OrderItem {
  sfdcDepositAmount = 0;
  sfdcDepositAdjustment = 0;
  replacesTotal = true;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.SFDCDepositDue;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.sfdcDepositAmount;
  }
}
