import { AircraftCategory } from '../aircraft-category';
import { AircraftModel } from './aircraft-model';
import { LegKpi } from './leg-kpi';
import { Route } from './route';
import { Operator } from './operator';
import { LegSource } from './leg-source';

export class Leg {
  legId: number;
  kpi: LegKpi;
  operator: Operator;
  route: Route;
  aircraftCategory: AircraftCategory;
  aircraftModel: AircraftModel;
  tail: string;
  legSource: LegSource;
  departureDateOverridden: boolean;
  originalDepartureDateLocal: Date;
  originalDepartureDateUtc: Date;
  overridenDepartureDateLocal: Date;
  overridenDepartureDateUtc: Date;
  arrivalDateTimeLocal: Date;
  arrivalDateTimeUtc: Date;
  confirmed: boolean;
  crowdfundedConfirmedTime: Date;
  releaseDate: Date;

  getLegId(): number {
    return this.legId;
  }

  setLegId(legId: number): Leg {
    this.legId = legId;

    return this;
  }

  getKpi(): LegKpi {
    return this.kpi;
  }

  setKpi(kpi: LegKpi): Leg {
    this.kpi = kpi;

    return this;
  }

  getAircraftCategory(): AircraftCategory {
    return this.aircraftCategory;
  }

  setAircraftCategory(aircraftCategory: AircraftCategory): Leg {
    this.aircraftCategory = aircraftCategory;

    return this;
  }

  getAircraftModel(): AircraftModel {
    return this.aircraftModel;
  }

  setAircraftModel(aircraftModel: AircraftModel): Leg {
    this.aircraftModel = aircraftModel;

    return this;
  }

  getConfirmed(): boolean {
    return this.confirmed;
  }

  setConfirmed(confirmed: boolean): Leg {
    this.confirmed = confirmed;

    return this;
  }

  getLegSource(): LegSource {
    return this.legSource;
  }

  setLegSource(legSource: LegSource): Leg {
    this.legSource = legSource;

    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): Leg {
    this.operator = operator;

    return this;
  }

  getOriginalDepartureDateLocal(): Date {
    return this.originalDepartureDateLocal;
  }

  setOriginalDepartureDateLocal(date: Date): Leg {
    this.originalDepartureDateLocal = date;

    return this;
  }

  getOriginalDepartureDateUtc(): Date {
    return this.originalDepartureDateUtc;
  }

  setOriginalDepartureDateUtc(date: Date): Leg {
    this.originalDepartureDateUtc = date;

    return this;
  }

  getOverridenDepartureDateLocal(): Date {
    return this.overridenDepartureDateLocal;
  }

  setOverriddenDepartureDateLocal(date: Date): Leg {
    this.overridenDepartureDateLocal = date;
    this.departureDateOverridden = date !== null;

    return this;
  }

  getOverriddenDepartureDateUtc(date: Date): Date {
    return this.overridenDepartureDateUtc;
  }

  setOverriddenDepartureDateUtc(date: Date): Leg {
    this.overridenDepartureDateUtc = date;
    this.departureDateOverridden = date !== null;

    return this;
  }

  getDepartureDateLocal(): Date {
    if (this.departureDateOverridden) {
      return this.overridenDepartureDateLocal;
    } else {
      return this.originalDepartureDateLocal;
    }
  }

  getDepartureDateUtc(): Date {
    if (this.departureDateOverridden) {
      return this.overridenDepartureDateUtc;
    } else {
      return this.originalDepartureDateUtc;
    }
  }

  getReleaseDate(): Date {
    return this.releaseDate;
  }

  setReleaseDate(releaseDate: Date): Leg {
    this.releaseDate = releaseDate;

    return this;
  }

  getCrowdfundedConfirmedTime(): Date {
    return this.crowdfundedConfirmedTime;
  }

  setCrowdfundedConfirmedTime(crowdfundedConfirmedTime: Date): Leg {
    this.crowdfundedConfirmedTime = crowdfundedConfirmedTime;

    return this;
  }
}
