export class AircraftRating {
  id: string = null;
  name: string = null;

  getId(): string {
    return this.id;
  }

  setId(id: string): AircraftRating {
    this.id = id;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): AircraftRating {
    this.name = name;
    return this;
  }

  equals(o1: AircraftRating): boolean {
    if (o1 === null || this.id === null || this.id === undefined) {
      return false;
    }

    return this.id === o1.id;
  }
}
