<div class="quickview-wrapper  builder hidden-sm hidden-xs"
     [class.open]="viewOpen">
  <div class="p-l-30 p-r-30 m-t-10">
    <a class="builder-close quickview-toggle pg pg-close"
       (click)="closeView()"
       href="javascript:void(0)"></a>
    <a class="builder-toggle"
       (click)="toggleView()"><i class="pg pg-theme"></i></a>
    <pg-tabset tabAnimation="slide-left"
               Type="simple"
               ShowPagination="true"
               extraTabContentClass="p-l-30">
      <pg-tab>
        <ng-template #TabHeading>
          System
        </ng-template>
        <div class="p-l-10 p-r-50">
          <div class="row">
            <div class="col col-sm-12">
              <p class="no-margin">
                Environment
              </p>
              <p class="small hint-text m-b-20">
                {{ envConfigurations.serverEnvironment | uppercase }}
              </p>

            </div>
          </div>
          <ng-container *ngIf="gitEnvVariables">
            <div class="row">
              <div class="col col-sm-12">
                <p class="no-margin">
                  Version
                </p>
                <p class="small hint-text m-b-20">
                  {{ gitEnvVariables.versionHash }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12">
                <p class="no-margin">
                  Epic
                </p>
                <p class="small hint-text m-b-20">
                  {{ gitEnvVariables.envName }}
                </p>
  
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12">
                <p class="no-margin">
                  Branch
                </p>
                <p class="small hint-text m-b-20">
                  {{gitEnvVariables.branch}}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12">
                <p class="no-margin">
                  Pipeline Url
                </p>
                <p class="small hint-text m-b-20">
                  <a [attr.href]="gitEnvVariables.pipelineUrl" target="_blank">{{gitEnvVariables.pipelineUrl}}</a>
                </p>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col col-sm-12">
              <ng-container *ngFor="let name of apiNameList">
                <kendo-expansionpanel #panel
                                      [title]="name" 
                                      (action)="onApiStatusClick(panel)">
                  <ng-container *ngIf="status && !isStatusLoading; else loader">
                    <ul>
                      <li *ngFor="let item of status.status | keyvalue">
                        <span class="text-break"><strong>{{item.key}}</strong>: {{item.value}}</span>
                      </li>
                      <li *ngIf="status.xoLibraries">
                        <span class="text-break"><strong>xo-libraries</strong>:</span>
                      </li>
                    </ul>
                    <div class="p-l-30 p-b-30" *ngIf="status.xoLibraries as xoLibraries">
                      <kendo-panelbar>
                        <kendo-panelbar-item  *ngFor="let xoLibrary of xoLibraries | keyvalue"
                                              [title]="xoLibrary.key">
                          <ng-template kendoPanelBarContent>
                            <ul>
                              <li *ngFor="let item of xoLibrary.value | keyvalue">
                                <span class="text-break"><strong>{{item.key}}</strong>: {{item.value}}</span>
                              </li>
                            </ul>
                          </ng-template>
                        </kendo-panelbar-item>
                      </kendo-panelbar>
                    </div>
                  </ng-container>
                  <ng-template #loader>
                    <spinner class="full-height"></spinner>
                  </ng-template>
                </kendo-expansionpanel>
              </ng-container>

              <kendo-expansionpanel  title="JetStudio UI Config" [expanded]="false">
                <ul>
                  <li *ngFor="let item of envConfigurations | keyvalue">
                    <span class="text-break"><strong>{{item.key}}</strong>: {{item.value}}</span>
                  </li>
                </ul>
              </kendo-expansionpanel>
            </div>
          </div>
        </div>
      </pg-tab>
    </pg-tabset>

  </div>
</div>
