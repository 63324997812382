import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD_HISTORY = '[YieldMgmt.LegBooking] Load History';
export const LOAD_HISTORY_SUCCESS = '[YieldMgmt.LegBooking] Load History Success';
export const LOAD_HISTORY_FAIL = '[YieldMgmt.LegBooking] Load History Fail';
export const RESET = '[YieldMgmt.LegBooking] Reset';

export class LoadHistoryAction implements Action {
  readonly type = LOAD_HISTORY;

  constructor(public payload: LoadHistoryPayload) {}
}

export class LoadHistoryPayload {
  constructor(public legId: number) {}
}

export class LoadHistorySuccessPayload {
  constructor(public bookings: Array<models.LegBookingHistory>) {}
}

export class LoadHistorySuccessAction implements Action {
  readonly type = LOAD_HISTORY_SUCCESS;

  constructor(public payload: LoadHistorySuccessPayload) {}
}

export class LoadHistoryFailAction implements Action {
  readonly type = LOAD_HISTORY_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadHistoryAction
  | LoadHistorySuccessAction
  | LoadHistoryFailAction
  | ResetAction;
