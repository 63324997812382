import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import * as helpers from '../helpers';

@Injectable({ providedIn: 'root' })
export class AuthorizationUserGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {

    // ToDo Remove after implementing auth0 authorization in production
    if (helpers.isLegacyAuth()) {
      return of(true);
    }

    return this.authService.isAuthenticated$.pipe(
      map((loggedIn) => {
        if (!loggedIn) {
          this.authService.loginWithRedirect({ appState: { target: document.location.pathname + document.location.search } })
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
