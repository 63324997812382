import { AirportGroup } from './airport-group';
import { Address } from './address';

export class Airport {
  address: Address;
  airportGroup: AirportGroup;
  code: string;
  name: string;
  timezoneName: string;
}
