export class SourcingRequestLegInput {
  departureTime: Date;
  departureTimeTBD: boolean;
  departureAirportCode: string;
  arrivalAirportCode: string;
  pax: number;
  departureFboId: number;
  arrivalFboId: number;
  toIsTechStop: boolean;
}
