import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class SfdcCashCreditTransferOrderItem extends OrderItem {
  sfdcCashCreditTransfer: number = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.CashCreditTransfer;
  }
}
