import * as models from '../../../domain/models';

import * as _ from 'lodash';

export class OrderMapperService {
  static parseOrderInternal(item: any): models.Order {
    const order = new models.Order();
    order.id = Number(item.id);
    order.orderStatusId = Number(item.orderStatusId);
    order.user = _.get(item, 'user', null) ? this.parseOrderUser(item.user) : new models.User();
    order.orderBilling = this.parseOrderBilling(item.orderBilling);
    order.totalPrice = Number(item.totalPrice);
    return order;
  }

  static parseOrderUser(item: any): models.User {
    const u = new models.User();
    u.id = Number(item.id);
    u.firstName = _.get(item, 'firstName', null);
    u.lastName = _.get(item, 'lastName', null);
    return u;
  }

  static parseOrderBilling(item: any): models.OrderBilling {
    const o = new models.OrderBilling();
    o.client = this.parseOrderBillingClient(item.client);
    return o;
  }

  static parseOrderBillingClient(item: any): models.Client {
    const c = new models.Client();
    c.id = Number(item.id);
    c.dba = _.get(item, 'dba', null);
    c.name = _.get(item, 'name', null);
    c.legalName = _.get(item, 'legalName', null);
    return c;
  }
}
