import { LegListFilterItem } from './leg-list-filter-item';
import { LegListDirection } from './leg-list-direction';
import { LegListDateSelection } from './leg-list-date-selection';
import { LegListSource } from './leg-list-source';
import { LegSource } from '../../../domain/models/heat-map/leg-source';
import { LegListLoadFactor } from './leg-list-load-factor';
import { LegListArea } from './leg-list-area';
import { LegListPriceOverride } from './leg-list-price-override';

export class LegListFilters {
  routeGroups = new LegListFilterItem(new Array<LegListDirection>(), true);
  departureDateSelection = new LegListFilterItem(new LegListDateSelection());
  releaseDateSelection = new LegListFilterItem(new LegListDateSelection(true));
  loadFactor = new LegListFilterItem(new LegListLoadFactor(), false);
  areaSelection = new LegListFilterItem(new LegListArea(), false);
  tail: string = null;
  operator: string = null;
  source: LegListFilterItem = null;
  legIds: Array<string> = [];
  priceOverride: LegListFilterItem = new LegListFilterItem(new LegListPriceOverride(), false);

  constructor() {
    // get keys and real values from enum:
    const sourceKeys = Object.keys(LegSource).filter(
      (key) => typeof LegSource[key as any] === 'number'
    );
    const sourceValues = sourceKeys
      .map((key) => LegSource[key as any])
      .map((source) => Number(source));

    // dynamically generate all possible options with default values
    const sourceFilterOptions: Array<LegListSource> = sourceValues.map((source) => {
      let isEnabled = true; // defatult setting: all sources are enabled, otherwise we override isEnabled

      if (source === LegSource.PrivateCharter) {
        isEnabled = false; // default setting: PrivateCharter is disabled
      }

      if (source === LegSource.SharedCharter) {
        isEnabled = false; // default setting: SharedCharter is disabled
      }

      if (source === LegSource.OperatorPortalSharedCharter) {
        isEnabled = false; // default setting: OperatorPortalSharedCharter is disabled
      }

      if (source === LegSource.OperatorPortalWholeCharter) {
        isEnabled = false; // default setting: OperatorPortalWholeCharter is disabled
      }

      return new LegListSource(source, isEnabled);
    });

    this.source = new LegListFilterItem(sourceFilterOptions, true);
    this.departureDateSelection.applied = true;

    this.source.applied = true; // default setting: source filter is toggled on
    this.loadFactor.applied = true; // default setting: load factor filter is toggled on
  }
}
