import * as models from '../../../domain/models';
import * as sharedTypes from '../../../../../shared/types';

import * as _ from 'lodash';
import * as moment from 'moment';

export class ClientMapperService {
  static parseConciergeFlightAncillaryData(item: any): models.ConciergeFlightAncillaryData {
    const d = new models.ConciergeFlightAncillaryData();
    d.creditReasons = _.get(item, 'creditReasons', []).map((e) => this.parseCreditReason(e));
    d.flightRequests = _.get(item, 'flightRequests', []).map((e) => this.parseFlightRequest(e));
    d.legRequests = _.get(item, 'legRequests', []).map((e) => this.parseLegRequest(e));
    return d;
  }

  static parseCreditReason(item: any): models.CreditReason {
    const c = new models.CreditReason();
    c.creditReasonName = item.creditReasonName;
    c.creditReasonId = item.creditReasonId;
    c.tiedToFlight = item.tiedToFlight;
    return c;
  }

  static parseFlightRequest(item: any): models.ConciergeFlightRequest {
    const r = new models.ConciergeFlightRequest();
    r.conciergeAction = _.get(item, 'conciergeAction', null);
    r.departureDate = _.get(item, 'departureDate', null)
      ? moment(_.get(item, 'departureDate', null)).toDate()
      : null;

    // will be removed once
    if (_.get(item, 'lastOrder', null) && !_.get(item, 'lastOrder.orderItems', null)) {
      item.lastOrder = null;
    }

    r.lastOrder = this.parseOrder(_.get(item, 'lastOrder', new models.ConciergeLastOrder()));
    r.lastOrder.items = [];

    for (const rootOrderItemEl of _.get(item, 'lastOrder.orderItems', [])) {
      const orderItem = this.parseConciergeOrderItemInternal(rootOrderItemEl);
      r.lastOrder.items.push(orderItem);
    }

    r.requestId = _.get(item, 'requestId', null);
    r.invoices = _.get(item, 'invoices', []).map((e) => this.parseConciergeFlightRequestInvoice(e));
    r.legRequestIds = _.get(item, 'legRequestIds', null);
    return r;
  }

  static parseConciergeOrderItemInternal(item: any): models.ConciergeOrderItem {
    const orderItem = new models.ConciergeOrderItem();

    orderItem.id = +_.get(item, 'id', null);
    orderItem.quantity = +_.get(item, 'quantity', 1);
    orderItem.totalPrice = Math.round((Number(item.totalPrice) + 0.00001) * 100) / 100;

    const product2 = new models.Product();
    product2.id = Number(item.product.productId);
    product2.name = item.product.productName;
    product2.amount = Math.round((Number(item.basePrice) + 0.00001) * 100) / 100;
    orderItem.product = product2;

    orderItem.requestId = _.get(item, 'requestId', null);
    orderItem.clientComments = _.get(item, 'clientComments', null);
    orderItem.quoteAmount = +_.get(item, 'quoteAmount', null);
    orderItem.flightRequestId = _.get(item, 'flightRequestId', null);
    orderItem.comments = _.get(item, 'comments', null);
    orderItem.markupPercent = +_.get(item, 'markupPercent', null);
    orderItem.addMarkupPercent = !!orderItem.markupPercent;
    orderItem.orderStatusId = _.get(item, 'orderStatusId', null);
    orderItem.totalPrice = +_.get(item, 'totalPrice', null);
    orderItem.serviceDiscountAmount = +_.get(item, 'serviceDiscountAmount', null);
    orderItem.serviceDate = _.get(item, 'serviceDate', null);
    orderItem.vendorId = _.get(item, 'vendorId', null);
    orderItem.vendorRefNumber = _.get(item, 'vendorRefNumber', null);
    orderItem.orderItemId = +_.get(item, 'orderItemId', null);
    orderItem.orderId = +_.get(item, 'orderId', null);
    orderItem.cateringCreditOrderItem = _.get(item, 'cateringCredit', null);
    return orderItem;
  }

  static parseConciergeFlightRequestInvoice(item: any): models.ConciergeFlightRequestInvoice {
    const i = new models.ConciergeFlightRequestInvoice();
    i.formTplId = _.get(item, 'formTplId', null);
    i.formUrl = _.get(item, 'formUrl', null);
    i.clientSource = _.get(item, 'clientSource', null);
    i.createTime = _.get(item, 'createTime', null)
      ? moment(_.get(item, 'createTime', null)).toDate()
      : null;
    i.paymentStatus = _.get(item, 'invoicePaymentStatus', null);
    i.totalPrice = _.get(item, 'totalPrice', null);
    return i;
  }

  static parseLegRequest(item: any): models.ConciergeLegRequest {
    const r = new models.ConciergeLegRequest();
    r.cateringCredit = this.parseCateringCredit(_.get(item, 'cateringCredit', null));
    r.departureDate = _.get(item, 'departureDate', null)
      ? moment(_.get(item, 'departureDate', null)).toDate()
      : null;
    r.flightRequest = this.parseFlightRequestInLeg(_.get(item, 'flightRequest', null));
    r.flightRequestId = _.get(item, 'flightRequestId', null);
    r.flightRequestUrl = _.get(item, 'flightRequestUrl', null);
    r.legId = _.get(item, 'legId', null);
    r.legUrl = _.get(item, 'legUrl', null);
    r.requestId = _.get(item, 'requestId', null);
    r.requestUrl = _.get(item, 'requestUrl', null);
    return r;
  }

  static parseFlightRequestInLeg(item: any): models.FlightRequestInLeg {
    const r = new models.FlightRequestInLeg();
    r.invoices = _.get(item, 'invoices', []).map((e) => this.parseConciergeFlightRequestInvoice(e));
    return r;
  }

  static parseCateringCredit(item: any): models.CateringCredit {
    if (!item) {
      return null;
    }
    const c = new models.CateringCredit();
    c.amount = _.get(item, 'amount', null);
    c.cateringCreditId = _.get(item, 'cateringCreditId', null);
    c.clientId = _.get(item, 'clientId', null);
    c.flightRequestId = _.get(item, 'flightRequestId', null);
    c.legId = _.get(item, 'legId', null);
    c.legRequestId = _.get(item, 'legRequestId', null);
    return c;
  }

  static parseOrder(item: any): models.ConciergeLastOrder {
    const o = new models.ConciergeLastOrder();
    o.orderUrl = _.get(item, 'orderUrl', null);
    o.totalPrice = Number(_.get(item, 'totalPrice', null));
    return o;
  }

  static parseUser(item: any): models.User {
    const u = new models.User();
    u.email = _.get(item, 'email', null);
    u.firstName = _.get(item, 'firstName', null);
    u.id = _.get(item, 'id', null);
    u.lastName = _.get(item, 'lastName', null);
    u.phone = _.get(item, 'phone', null);
    return u;
  }

  static parseOrderBilling(item: any): models.OrderBilling {
    const b = new models.OrderBilling();
    b.client = this.parseClient(_.get(item, 'client', null));
    b.businessName = _.get(item, 'businessName', null);
    return b;
  }

  static parseClient(item: any): models.Client {
    const c = new models.Client();
    c.accountClients = _.get(item, 'accountClients', []);
    c.accountRoleId = _.get(item, 'accountRoleId', null);
    c.address = _.get(item, 'address', null);
    c.clientServices = _.get(item, 'clientServices', []);
    c.clientServiceOrderItem = _.get(item, 'clientServiceOrderItem', []);
    c.dba = _.get(item, 'dba', null);
    c.dob = _.get(item, 'dob', null) ? moment(_.get(item, 'dob', null)).toDate() : null;
    c.email = _.get(item, 'email', null);
    c.flightCreditAmount = _.get(item, 'flightCreditAmount', null);
    c.id = Number(_.get(item, 'id', null));
    c.isSelectableAsBeneficiary = _.get(item, 'isSelectableAsBeneficiary', null);
    c.isSelectedAsBeneficiary = _.get(item, 'isSelectedAsBeneficiary', null);
    c.legalName = _.get(item, 'legalName', null);
    c.name = _.get(item, 'name', null);
    c.orders = _.get(item, 'orders', []);
    c.phone = _.get(item, 'phone', null);
    c.referredByClient = _.get(item, 'referredByClient', null);
    c.state = _.get(item, 'state', null);
    c.status = _.get(item, 'status', null);
    return c;
  }

  static parseCancellation(item: any): models.Cancellation {
    const c = new models.Cancellation();
    c.cancellationTime = _.get(item, 'cancellationTime', null)
      ? moment(_.get(item, 'cancellationTime', null)).toDate()
      : null;
    c.orderStatusId = _.get(item, 'orderStatusId', null);
    c.user = this.parseCancellationUser(_.get(item, 'user', null));
    return c;
  }

  static parseCancellationUser(item: any): models.CancellationUser {
    const u = new models.CancellationUser();
    u.email = _.get(item, 'email', null);
    u.firstName = _.get(item, 'firstName', null);
    u.id = _.get(item, 'id', null);
    u.lastName = _.get(item, 'lastName', null);
    return u;
  }

  static parseInvoice(item: any): models.Invoice {
    const u = new models.Invoice();
    u.amount = _.get(item, 'amount', null);
    u.client = this.parseClient(_.get(item, 'client', null));
    u.clientId = _.get(item, 'clientId', null);
    u.contactMethods = _.get(item, 'contactMethods', []).map((e) => this.parseContactMethod(e));
    u.createTime = _.get(item, 'createTime', null)
      ? moment(_.get(item, 'createTime', null)).toDate()
      : null;
    u.documents = _.get(item, 'documents', null).map((e) => this.parseDocument(e));
    u.externalProfileLink = _.get(item, 'externalProfileLink', null);
    u.id = _.get(item, 'id', null);
    u.invoiceReplacements = _.get(item, 'invoiceReplacements', null).map((e) =>
      this.parseInvoiceReplacement(e)
    );
    u.invoiceStatusId = _.get(item, 'invoiceStatusId', null);
    u.invoiceTypeId = _.get(item, 'invoiceTypeId', null);
    u.invoiceTypeName = _.get(item, 'invoiceTypeName', null);
    u.legacyFormTplId = _.get(item, 'legacyFormTplId', null);
    u.legacyFormUrlKey = _.get(item, 'legacyFormUrlKey', null) || _.get(item, 'formUrlKey', null);
    u.legacyFormEditUrl =
      _.get(item, 'legacyFormEditUrl', null) || _.get(item, 'formEditUrl', null);
    u.legacyFormUrl = _.get(item, 'legacyFormUrl', null) || _.get(item, 'formUrl', null);
    u.orderId = _.get(item, 'orderId', null);
    u.statusId = _.get(item, 'statusId', null);
    u.uuid = _.get(item, 'uuid', null);
    return u;
  }

  static parseContactMethod(item: any): models.ContactMethod {
    const m = new models.ContactMethod();
    m.contentTemplateId = _.get(item, 'contentTemplateId', null);
    m.checked = _.get(item, 'checked', null);
    m.description = _.get(item, 'description', null);
    m.id = _.get(item, 'id', null);
    m.message = _.get(item, 'message', null);
    m.name = _.get(item, 'name', null);
    return m;
  }

  static parseInvoiceReplacement(item: any): models.InvoiceReplacement {
    const r = new models.InvoiceReplacement();
    r.formTplId = _.get(item, 'formTplId', null);
    r.name = _.get(item, 'name', null);
    return r;
  }

  static parseDocument(item: any): models.SalesInvoiceDocumentClass {
    const d = new models.SalesInvoiceDocumentClass();
    d.active = _.get(item, 'active', null);
    d.createTime = _.get(item, 'createTime', null)
      ? moment(_.get(item, 'createTime', null)).toDate()
      : null;
    d.documentId = _.get(item, 'documentId', null);
    d.documentTypeId = _.get(item, 'documentTypeId', null);
    d.documentTypeName = _.get(item, 'documentTypeName', null);
    d.generateTime = _.get(item, 'generateTime', null)
      ? moment(_.get(item, 'generateTime', null)).toDate()
      : null;
    d.grayedOut = _.get(item, 'grayedOut', null);
    d.invoiceId = _.get(item, 'invoiceId', null);
    d.name = _.get(item, 'name', null);
    d.regeneratable = _.get(item, 'regeneratable', null);
    d.updateTime = _.get(item, 'updateTime', null)
      ? moment(_.get(item, 'updateTime', null)).toDate()
      : null;
    d.uploadTime = _.get(item, 'uploadTime', null)
      ? moment(_.get(item, 'uploadTime', null)).toDate()
      : null;
    d.url = _.get(item, 'url', null);
    d.userId = _.get(item, 'userId', null);
    d.userName = _.get(item, 'userName', null);
    return d;
  }

  static parsePromotion(item: any): models.Promotion {
    const p = new models.Promotion();
    p.amount = _.get(item, 'amount', null);
    p.code = _.get(item, 'code', null);
    p.createTime = _.get(item, 'createTime', null);
    p.description = _.get(item, 'description', null);
    p.id = _.get(item, 'id', null);
    p.items = _.get(item, 'items', []).map((e) => this.parsePromotionItem(e));
    p.percentage = _.get(item, 'percentage', null);
    return p;
  }

  static parsePromotionItem(item: any): models.PromotionItem {
    const i = new models.PromotionItem();
    i.createTime = _.get(item, 'createTime', null)
      ? moment(_.get(item, 'createTime', null)).toDate()
      : null;
    i.discount = this.parsePriceComponent(_.get(item, 'discount', null));
    i.id = _.get(item, 'id', null);
    i.product = this.parseProduct(_.get(item, 'product', null));
    i.promotion = _.get(item, 'promotion', null);
    i.promotionAmount = _.get(item, 'promotionAmount', null);
    i.quantity = _.get(item, 'quantity', 1);
    return i;
  }

  static parsePriceComponent(item: any): models.PriceComponent {
    const c = new models.PriceComponent();
    c.amount = _.get(item, 'amount', null);
    c.description = _.get(item, 'description', null);
    c.id = _.get(item, 'id', null);
    c.name = _.get(item, 'name', null);
    c.percentage = _.get(item, 'percentage', null);
    c.securityAccess = _.get(item, 'securityAccess', null);
    c.typeId = _.get(item, 'typeId', null);
    c.typeName = _.get(item, 'typeName', null);
    return c;
  }

  static parseProduct(item: any): models.Product {
    const p = new models.Product();
    p.amount = _.get(item, 'amount', null);
    p.description = _.get(item, 'description', null);
    p.id = _.get(item, 'id', null);
    p.isRefundable = _.get(item, 'isRefundable', null);
    p.name = _.get(item, 'name', null);
    p.parentProductId = _.get(item, 'parentProductId', null);
    p.priceComponents = _.get(item, 'priceComponents', []).map((e) => this.parsePriceComponent(e));
    p.priceComponentsBrackets = _.get(item, 'priceComponentsBrackets', []).map((e) =>
      this.parsePriceComponentsBracket(e)
    );
    p.productDependencies = _.get(item, 'productDependencies', []).map((e) =>
      this.parseMembershipProductDependency(e)
    );
    p.productFeatures = _.get(item, 'productFeatures', []).map((e) => this.parseProductFeature(e));
    p.productType = _.get(item, 'productType', null);
    p.securityAccess = _.get(item, 'securityAccess', null);
    p.vendorList = _.get(item, 'vendorList', []).map((e) => this.parseVendor(e));
    return p;
  }

  static parsePriceComponentsBracket(item: any): models.PriceComponentsBracket {
    const c = new models.PriceComponentsBracket();
    c.base = _.get(item, 'base', null);
    c.priceComponentId = _.get(item, 'priceComponentId', null);
    c.sequence = _.get(item, 'sequence', null);
    return c;
  }

  static parseProductFeature(item: any): models.ProductFeature {
    const f = new models.ProductFeature();
    f.id = _.get(item, 'id', null);
    f.name = _.get(item, 'name', null);
    f.productId = _.get(item, 'productId', null);
    f.typeId = _.get(item, 'typeId', null);
    f.value = _.get(item, 'value', null);
    return f;
  }

  static parseMembershipProductDependency(item: any): models.MembershipProductDependency {
    const d = new models.MembershipProductDependency();
    d.childProduct = _.get(item, 'childProduct', null);
    d.id = _.get(item, 'id', null);
    d.required = _.get(item, 'required', null);
    d.salesAction = _.get(item, 'salesAction', null);
    return d;
  }

  static parseVendor(item: any): models.Vendor {
    const v = new models.Vendor();
    v.description = _.get(item, 'description', null);
    v.id = _.get(item, 'id', null);
    v.name = _.get(item, 'name', null);
    v.products = _.get(item, 'products', []).map((e) => this.parseVendorProduct(e));
    return v;
  }

  static parseVendorProduct(item: any): models.VendorProduct {
    const p = new models.VendorProduct();
    p.markupPercent = _.get(item, 'markupPercent', null);
    p.productId = _.get(item, 'productId', null);
    return p;
  }

  static parseOrderPaymentOptions(item: any): models.OrderPaymentOptions {
    const o = new models.OrderPaymentOptions();
    o.paymentByWireTransferOnly = _.get(item, 'paymentByWireTransferOnly', null);
    o.splitPayment = _.get(item, 'splitPayment', null);
    o.splitPayments = _.get(item, 'splitPayments', []).map((e) => this.parseSplitPayment(e));
    return o;
  }

  static parseSplitPayment(item: any): models.SplitPayment {
    const p = new models.SplitPayment();
    p.businessName = _.get(item, 'businessName', null);
    p.client = this.parseClient(_.get(item, 'client', null));
    p.splitAmount = _.get(item, 'splitAmount', null);
    return p;
  }

  static parseSfdcOpportunityFlightPassDataInternal = (
    item: any
  ): models.SfdcOpportunityFlightPassData => {
    const fpd = new models.SfdcOpportunityFlightPassData();
    fpd.sfdcOpportunityId = _.get(item, 'sfdcOpportunityId', null);
    fpd.clientId = _.get(item, 'clientId', null);
    fpd.salesActionId = _.get(item, 'salesActionId', null);
    fpd.productId = _.get(item, 'productId', null);
    fpd.faceValue = _.get(item, 'faceValue', null);
    fpd.qtyOfPasses = _.get(item, 'qtyOfPasses', null);
    fpd.route = _.get(item, 'route', null);
    fpd.total = _.get(item, 'total', null);
    fpd.contractCommencementDate = _.get(item, 'contractCommencementDate', null);
    fpd.warningMessage = _.get(item, 'warningMessage', null);
    fpd.errorMessage = _.get(item, 'errorMessage', null);
    fpd.warningPopupAlert = _.get(item, 'warningPopupAlert', null);
    fpd.errorPopupAlert = _.get(item, 'errorPopupAlert', null);
    return fpd;
  };

  static parseSfdcOpportunityCreditMemoDataInternal(
    item: any
  ): models.SfdcOpportunityCreditMemoData {
    const d = new models.SfdcOpportunityCreditMemoData();
    d.sfdcOpportunityId = _.get(item, 'sfdcOpportunityId', null);
    d.sfdcCompensationId = _.get(item, 'sfdcCompensationId', null);
    d.clientId = _.get(item, 'clientId', null);
    d.internalComment = _.get(item, 'internalComment', null);
    d.creditAmount = _.get(item, 'creditAmount', null);
    d.creditMethod = _.get(item, 'creditMethod', null);
    d.creditReasons = _.get(item, 'creditReasons', null);
    d.warningMessage = _.get(item, 'warningMessage', null);
    d.errorMessage = _.get(item, 'errorMessage', null);
    d.warningPopupAlert = _.get(item, 'warningPopupAlert', null);
    d.errorPopupAlert = _.get(item, 'errorPopupAlert', null);
    d.legs = _.get(item, 'legs', []).map((e) => this.parseCreditMemoLeg(e));
    return d;
  }

  static parseCreditMemoLeg(item: any): models.CreditMemoLeg {
    const d = new models.CreditMemoLeg();
    d.legId = _.get(item, 'legId', null);
    d.legRequestId = _.get(item, 'legRequestId', null);
    d.creditAmount = _.get(item, 'creditAmount', null);
    return d;
  }

  static parseClientInternal(item: any): models.Client {
    const client = new models.Client();
    client.id = _.get(item, 'id', null);
    client.name = _.get(item, 'name', null);
    client.legalName = _.get(item, 'legalName', null);
    client.dob = _.get(item, 'dob', null);
    client.dba = _.get(item, 'dba', null);
    client.email = _.get(item, 'email', null);
    client.phone = _.get(item, 'phone', null);
    client.flightCreditAmount = _.get(item, 'flightCreditsAmount', null);

    client.referredByClient = new models.Client();
    client.referredByClient.id = _.get(item, 'referredByClient.id', null);
    client.referredByClient.name = _.get(item, 'referredByClient.name', null);

    client.isSelectableAsBeneficiary = Boolean(_.get(item, 'isSelectableAsBeneficiary', false));
    client.isSelectedAsBeneficiary = Boolean(_.get(item, 'isSelectedAsBeneficiary', false));
    client.accountRoleId = _.get(item, 'accountRoleId', null);
    client.accountClients = _.get(item, 'accountClients', false)
      ? item.accountClients.map((ac) => this.parseClientInternal(ac))
      : [];

    client.clientServices = _.get(item, 'clientServices', []).map((cs) => {
      const [clientService, orderItem] = this.parseClientServiceInternal(cs);

      if (orderItem !== null) {
        client.clientServiceOrderItem[clientService.clientServiceId] = orderItem;
      }

      clientService.client = client;

      return clientService;
    });

    return client;
  }

  static parseClientServiceInternal(cs: any): [models.ClientService, models.OrderItem] {
    const product = this.parseClientServiceProduct(cs.product);

    if (cs.isLegacy === false) {
      switch (cs.clientServiceTypeId) {
        case models.ClientServiceTypeEnum.GroupMembership:
          const cs1 = this.parseGroupMembershipClientService(cs);
          const oi1 = new models.GroupMembershipOrderItem();
          oi1.id = Number(cs.orderItem.orderItemId);
          oi1.orderId = Number(cs.orderId);
          oi1.salesAction = Number(cs.membershipSalesActionId);
          oi1.createTime = new Date(cs.membershipCreateTime);
          oi1.product = product;
          // TODO: wrong, needs to be removed
          oi1.membershipClientService = cs1;
          oi1.totalPrice = sharedTypes.Util.nvlToNumber(cs.orderItem.totalPrice);
          if (cs.orderItem.promotionAmount) {
            oi1.promotionAmount = cs.orderItem.promotionAmount;
          }

          if (cs.discount) {
            oi1.discount = this.parseOrderItemDiscount(cs.discount);
          }

          return [cs1, oi1];

        case models.ClientServiceTypeEnum.Membership:
        case models.ClientServiceTypeEnum.JetPass:
          const cs2 = this.parseMembershipClientService(cs);
          const oi2 = new models.MembershipOrderItem();
          oi2.id = Number(cs.orderItem.orderItemId);
          oi2.orderId = Number(cs.orderId);
          oi2.salesAction = Number(cs.membershipSalesActionId);
          oi2.createTime = new Date(cs.membershipCreateTime);
          oi2.product = product;
          // TODO: wrong, needs to be removed
          oi2.membershipClientService = cs2;
          oi2.totalPrice = sharedTypes.Util.nvlToNumber(cs.orderItem.totalPrice);
          if (cs.orderItem.promotionAmount) {
            oi2.promotionAmount = cs.orderItem.promotionAmount;
          }

          if (cs.discount) {
            oi2.discount = this.parseOrderItemDiscount(cs.discount);
          }

          return [cs2, oi2];
        default:
          throw new Error(`Can't find the Client Service Type`);
      }
    } else {
      const oi3: models.ProductOrderItem = null;
      const cs3 = new models.LegacyMembershipClientService();
      cs3.membershipPeriodId = Number(cs.id);
      cs3.clientServiceStatus = Number(cs.clientServiceStatusId);
      cs3.startDate = new Date(cs.startDate);
      cs3.endDate = new Date(cs.endDate);
      cs3.prorateSuppressed = _.get(cs, 'prorateSuppressed', false);
      cs3.product = product;

      return [cs3, oi3];
    }
  }

  static parseClientServiceProduct(item: any): models.Product {
    const productType = new models.ProductType();
    productType.id = Number(item.productTypeId);
    productType.name = _.get(item, 'productTypeName', null);
    productType.parentProductTypeId = _.get(item, 'productTypeParentProductTypeId', null);

    const product = new models.Product();
    product.id = Number(item.productId);
    product.name = _.get(item, 'productName', null);
    product.amount = sharedTypes.Util.nvlToNumber(item.basePrice);
    product.isRefundable = Boolean(item.isRefundable);
    product.productType = productType;
    return product;
  }

  static parseOrderItemDiscount(item: any): models.PriceComponent {
    const discount = new models.PriceComponent();
    discount.id = _.get(item, 'priceComponentId', null);
    discount.typeId = _.get(item, 'priceComponentTypeId', null);
    discount.amount = sharedTypes.Util.nvlToNumber(item.amount);
    discount.percentage = sharedTypes.Util.nvlToNumber(item.percentage);
    discount.name = _.get(item, 'priceComponentName', null);
    discount.typeName = _.get(item, 'priceComponentTypeName', null);
    return discount;
  }

  static parseGroupMembershipClientService(item: any): models.GroupMembershipClientService {
    const cs = new models.GroupMembershipClientService();
    cs.clientServiceId = Number(item.clientServiceId);
    cs.clientServiceStatus = Number(item.clientServiceStatusId);
    cs.startDate = new Date(item.startDate);
    cs.endDate = new Date(item.endDate);
    cs.createTime = new Date(item.clientServiceCreateTime);
    cs.slotsCount = item.slotsCount ? Number(item.slotsCount) : null;
    cs.prorateSuppressed = _.get(item, 'prorateSuppressed', false);
    cs.product = this.parseClientServiceProduct(item.product);
    return cs;
  }

  static parseMembershipClientService(item: any): models.MembershipClientService {
    const cs = new models.MembershipClientService();
    cs.clientServiceId = Number(item.clientServiceId);
    cs.clientServiceStatus = Number(item.clientServiceStatusId);
    cs.startDate = new Date(item.startDate);
    cs.endDate = new Date(item.endDate);
    cs.createTime = new Date(item.clientServiceCreateTime);
    cs.prorateSuppressed = _.get(item, 'prorateSuppressed', false);
    cs.product = this.parseClientServiceProduct(item.product);
    return cs;
  }
}
