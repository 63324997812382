import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class CustomTermOrderItem extends OrderItem {
  termDays: number = null;
  termMonths: number = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.CustomTerm;
  }
}
