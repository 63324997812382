export class LegKpi {
  marketableSeats: number = null;
  bookedSeats: number = null;
  emptySeats: number = null;
  occupiedSeats: number = null;
  creatorCash: number = null;
  creatorFc: number = null;
  finderCash: number = null;
  finderFc: number = null;
  totalCash: number = null;
  totalFc: number = null;
  totalRevenue: number = null;
  estimatedCost: number = null;
  quotedCost: number = null;
  actualCost: number = null;
  impressions: number = null;
  estimatedCancellationFees: number = null;

  // pricing
  priceOverrideDelta: number = null;
  priceOverrideReasonId: number = null;

  // Percentages
  conversionRate: number = null;
  loadFactor: number = null;
  // grossProfit: number = null;
  // cashGrossProfit: number = null;
}
