import { MatrixInterval } from './matrix-interval';
import { SeatPricingMgmtMapperService } from '../../../services/seat-pricing-mgmt/mappers/seat-pricing-mgmt-mapper.service';
import * as _ from 'lodash';

export class MatrixData {
  id: string;
  ruleId: string;
  disableOverride: boolean = false;
  baseSeatIntervals: Array<MatrixInterval>;
  feeDetails: FeeDetails;

  constructor(intervalsData: any, id: string) {
    if (id === null) {
      throw new Error(`Couldn't find id for Matrix`);
    }

    if (!(intervalsData.pricesOnInterval instanceof Array)) {
      throw new Error('Time Interval parameter for Base Seat matrix is not accurate');
    }

    this.id = id;
    this.disableOverride = intervalsData.disableOverride;
    this.ruleId = intervalsData.ruleId;
    this.baseSeatIntervals = intervalsData.pricesOnInterval.map((i) => new MatrixInterval(i));
    this.feeDetails = SeatPricingMgmtMapperService.parseFeeDetails(
      _.get(intervalsData, 'feeDetails', null)
    );
  }
}

export class FeeDetails {
  arrivalFee: number;
  departureFee: number;
  timeFee: number;
  totalFee: number;
}
