import { Operator } from './operator';
import { AircraftModel } from './aircraft-model';
import { User } from './user';
import { Quote } from './quote';

import { v4 as uuidv4 } from 'uuid';
import { AircraftAmenity } from './aircraft-amenity';
import { LegQuote } from './leg-quote';
import { AircraftImage } from './aircraft-image';
import { AircraftCategory } from './aircraft-category';
import { Order } from './order';
import { QuoteAttribute } from './quote-attribute';

export class SalesQuote extends Quote {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  salesQuoteId: string = uuidv4();
  salesQuoteSequenceId: number;
  opportunityId: string;
  deleted: boolean;
  salesQuoteRequestId: string;
  quoteAttributes: QuoteAttribute[];
  purchaseQuoteId: string;
  operator: Operator;
  aircraftModel: AircraftModel;
  aircraftAmenities: Array<AircraftAmenity> = new Array<AircraftAmenity>();
  aircraftTailNumber: string;
  aircraftYearOfManufacture: number;
  aircraftYearOfRefurbishment: number;
  aircraftCategory: AircraftCategory;
  aircraftImages: Array<AircraftImage> = new Array<AircraftImage>();
  legs: Array<LegQuote> = new Array<LegQuote>();
  amount: number;
  requiresOwnerApproval: boolean;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  createdDate: Date;
  createdUser: User;
  order: Order = null;

  getRequiresOwnerApproval(): boolean {
    return this.requiresOwnerApproval;
  }

  setRequiresOwnerApproval(requiresOwnerApproval: boolean): SalesQuote {
    this.requiresOwnerApproval = requiresOwnerApproval;
    return this;
  }

  getOrder(): Order {
    return this.order;
  }

  setOrder(order: Order): SalesQuote {
    this.order = order;
    return this;
  }

  getAircraftCategory(): AircraftCategory {
    return this.aircraftCategory;
  }

  setAircraftCategory(aircraftCategory: AircraftCategory): SalesQuote {
    this.aircraftCategory = aircraftCategory;
    return this;
  }

  getDeleted(): boolean {
    return this.deleted;
  }

  setDeleted(deleted): SalesQuote {
    this.deleted = deleted;
    return this;
  }

  getSalesQuoteId(): string {
    return this.salesQuoteId;
  }

  setSalesQuoteId(salesQuoteId: string): SalesQuote {
    this.salesQuoteId = salesQuoteId;

    return this;
  }

  getSalesQuoteSequenceId(): number {
    return this.salesQuoteSequenceId;
  }

  setSalesQuoteSequenceId(salesQuoteSequenceId: number): SalesQuote {
    this.salesQuoteSequenceId = salesQuoteSequenceId;

    return this;
  }

  getOpportunityId(): string {
    return this.opportunityId;
  }

  setOpportunityId(opportunityId: string): SalesQuote {
    this.opportunityId = opportunityId;

    return this;
  }

  getSalesQuoteRequestId(): string {
    return this.salesQuoteRequestId;
  }

  setSalesQuoteRequestId(salesQuoteRequestId: string): SalesQuote {
    this.salesQuoteRequestId = salesQuoteRequestId;

    return this;
  }

  getPurchaseQuoteId(): string {
    return this.purchaseQuoteId;
  }

  setPurchaseQuoteId(purchaseQuoteId: string): SalesQuote {
    this.purchaseQuoteId = purchaseQuoteId;

    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): SalesQuote {
    this.operator = operator;

    return this;
  }

  getAircraftModel(): AircraftModel {
    return this.aircraftModel;
  }

  setAircraftModel(aircraftModel: AircraftModel): SalesQuote {
    this.aircraftModel = aircraftModel;

    return this;
  }

  getAircraftTailNumber(): string {
    return this.aircraftTailNumber;
  }

  setAircraftTailNumber(aircraftTailNumber: string): SalesQuote {
    this.aircraftTailNumber = aircraftTailNumber;

    return this;
  }

  getAircraftYearOfManufacture(): number {
    return this.aircraftYearOfManufacture;
  }

  setAircraftYearOfManufacture(aircraftYearOfManufacture: number): SalesQuote {
    this.aircraftYearOfManufacture = aircraftYearOfManufacture;

    return this;
  }

  getAircraftYearOfRefurbishment(): number {
    return this.aircraftYearOfRefurbishment;
  }

  setAircraftYearOfRefurbishment(aircraftYearOfRefurbishment: number): SalesQuote {
    this.aircraftYearOfRefurbishment = aircraftYearOfRefurbishment;

    return this;
  }

  getAircraftAmenities(): Array<AircraftAmenity> {
    return this.aircraftAmenities;
  }

  setAircraftAmenities(aircraftAmenities: Array<AircraftAmenity>): SalesQuote {
    this.aircraftAmenities = aircraftAmenities;

    return this;
  }

  getAircraftImages(): Array<AircraftImage> {
    return this.aircraftImages;
  }

  setAircraftImages(aircraftImages: Array<AircraftImage>): SalesQuote {
    this.aircraftImages = aircraftImages;

    return this;
  }

  getLegs(): Array<LegQuote> {
    return this.legs;
  }

  setLegs(legs: Array<LegQuote>): SalesQuote {
    this.legs = legs;

    return this;
  }

  getAmount(): number {
    return this.amount;
  }

  setAmount(amount: number): SalesQuote {
    this.amount = amount;

    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): SalesQuote {
    this.lastModifiedDate = lastModifiedDate;

    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): SalesQuote {
    this.lastModifiedUser = lastModifiedUser;

    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): SalesQuote {
    this.createdDate = createdDate;

    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): SalesQuote {
    this.createdUser = createdUser;

    return this;
  }

  getQuoteAttributes(): QuoteAttribute[] {
    return this.quoteAttributes;
  }

  setQuoteAttributes(quoteAttributes: QuoteAttribute[]): SalesQuote {
    this.quoteAttributes = quoteAttributes;
    return this;
  }
}
