export enum PriceComponentTypeEnum {
  Discount = 1,
  Surcharge = 2,
  Tax = 3,
  PercentageOfSale = 4,
  EarlyRenewalDiscount = 5,
  IncentivizerDiscount = 6,
  PromotionalDiscount = 7,
  InAppPurchaseDiscount = 8,
  InAppPurchaseSurcharge = 9,
}
