export class Catering {
  id: number;
  leader: boolean;
  legalName: string;
  requestId: number;
  seatNumber: string;
  comments: string;
  catering: CateringItems;
  cateringOrder: Array<string>;

  getCaption(): string {
    const obj = this.cateringOrder.reduce((ac, el) => {
      if (el === '-') {
        return ac;
      }
      const uppercase = el.toUpperCase();
      ac[uppercase] = (ac[uppercase] || 0) + 1;
      return ac;
    }, {});
    return Object.keys(obj)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .reduce((i, e) => {
        i += ` ${e} (${obj[e]})`;
        return i;
      }, '');
  }
}

export type CateringType = 'set-a' | 'set-b' | 'set-c' | 'set-d' | 'set-e' | 'set-f';

export class CateringItems {
  items: {
    entries<T extends { [key: string]: keyof CateringType }, K extends keyof T>(o: T): number;
  };
}
