import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/aircraft-amenity-collection.actions';
import * as models from '../../domain/models';

export const aircraftAmenityCollectionFeatureKey = 'aircraftAmenityCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.AircraftAmenity>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(actions.load, (state): State => ({ ...state, loading: true })),

  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      loaded: true,
      loading: false,
      items: payload,
    })
  )
);
