import * as models from '../../domain/models';
import { CurrencyEnum } from '../../../../shared/models';

export class SourcingRequestCreateForm {
  aircraftCategory: models.AircraftCategory;
  maxPrice: number;
  legs: Array<models.LegRequest> = [];
  dba = 'JS';
  additionalInstructions: string;
  petOnBoard = false;
  petNote: string;
  currency: CurrencyEnum = CurrencyEnum.USD;

  isValid(): boolean {
    return (
      !!this.aircraftCategory &&
      !!this.maxPrice &&
      !!this.legs.length &&
      !(this.legs.length === 1 && this.legs[0].getTechStop()) &&
      !(this.legs[this.legs.length - 1].getTechStop()) &&
      this.dba !== null
    );
  }

  addLegRequest(legRequest: models.LegRequest): void {
    this.legs.push(legRequest);
  }

  removeLegRequestAt(index: number): void {
    this.legs.splice(index, 1);
  }

  updateLegRequest(index: number, legRequest: models.LegRequest): void {
    this.legs[index] = legRequest;
  }

  getLegs(): Array<models.LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<models.LegRequest>): SourcingRequestCreateForm {
    this.legs = legs;

    return this;
  }

  setDba(dba: string): SourcingRequestCreateForm {
    this.dba = dba;

    return this;
  }

  getDba(): string {
    return this.dba;
  }

  setAdditionalInstructions(additionalInstructions: string): SourcingRequestCreateForm {
    this.additionalInstructions = additionalInstructions;

    return this;
  }

  getAdditionalInstructions(): string {
    return this.additionalInstructions;
  }

  setPetOnBoard(petOnBoard: boolean): SourcingRequestCreateForm {
    this.petOnBoard = petOnBoard;

    return this;
  }

  getPetOnBoard(): boolean {
    return this.petOnBoard;
  }

  setPetNote(petNote: string): SourcingRequestCreateForm {
    this.petNote = petNote;

    return this;
  }

  getPetNote(): string {
    return this.petNote;
  }

  setCurrency(currency: CurrencyEnum): SourcingRequestCreateForm {
    this.currency = currency;

    return this;
  }

  getCurrency(): string {
    return this.currency;
  }
}
