import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import * as _ from 'lodash';

import * as actions from '../actions/leg.actions';
import * as models from '../../domain/models';
import * as helpers from '../../domain/helpers';
import { PAX_FLIGHT_STATUSES } from '../../domain/constants';
import { CHECKS } from '../../domain/constants/checks';

export const legFeatureKey = 'leg';

export interface State {
  loaded: boolean;
  loading: boolean;
  item: models.Leg;
  taskCollection: Array<models.Task>;
  taskCollectionLoaded: boolean;
  taskCollectionLoading: boolean;
  taskNoteLoading: boolean;
  prevState: models.StateEnum;
  relatedLegsLoading: boolean;
  relatedLegsLoaded: boolean;
  relatedLegs: models.RelatedLegs;
  aircraftInspectionsLoaded: boolean;
  aircraftInspectionsLoading: boolean;
  aircraftInspectionItems: Array<models.AircraftInspection>;
  cateringLoading: boolean;
  cateringLoaded: boolean;
  cateringItems: Array<models.Catering>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  item: null,
  taskCollection: [],
  taskCollectionLoaded: false,
  taskCollectionLoading: false,
  taskNoteLoading: false,
  prevState: null,
  relatedLegsLoading: false,
  relatedLegsLoaded: false,
  relatedLegs: null,
  aircraftInspectionsLoaded: false,
  aircraftInspectionsLoading: false,
  aircraftInspectionItems: [],
  cateringLoading: false,
  cateringLoaded: false,
  cateringItems: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      item: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(
    actions.resetLeg,
    (state): State => ({
      ...state,
      loaded: false,
      item: null,
      cateringItems: [],
      cateringLoaded: false,
    })
  ),

  on(
    actions.setTasksInitial,
    (state): State => ({
      ...state,
      taskCollection: [],
      taskCollectionLoaded: false,
      taskCollectionLoading: false,
    })
  ),

  on(
    actions.loadTasks,
    (state): State => ({
      ...state,
      taskCollectionLoading: true,
    })
  ),
  on(
    actions.loadTasksSuccess,
    (state, { payload: { items } }): State => ({
      ...state,
      taskCollectionLoaded: true,
      taskCollectionLoading: false,
      taskCollection: items,
    })
  ),
  on(
    actions.loadTasksFail,
    (state): State => ({
      ...state,
      taskCollectionLoaded: true,
      taskCollectionLoading: false,
    })
  ),
  on(actions.sortTasks, (state, { payload: { sortBy, sortOrder } }): State => {
    const taskCollection = helpers.sortTasks(sortBy, sortOrder, state.taskCollection);
    return {
      ...state,
      taskCollection,
    };
  }),

  on(actions.setStateForMultipleTask, (state, { payload: { taskIds } }): State => {
    const taskCollection: Array<models.Task> = _.cloneDeep(state.taskCollection);
    let prevState: models.StateEnum;
    taskIds.forEach((id) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === id);
      if (index !== -1) {
        prevState = taskCollection[index].state.taskStateId;
        taskCollection[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      taskCollection,
    };
  }),
  on(actions.setStateForMultipleTaskSuccess, (state, { payload: { tasks } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    tasks.forEach((task) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === task.taskId);
      if (index !== -1) {
        clone[index] = task;
      }
    });
    return {
      ...state,
      taskCollection: clone,
    };
  }),
  on(actions.setStateForMultipleTaskFail, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    taskIds.forEach((taskId) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clone[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      taskCollection: clone,
    };
  }),

  on(actions.revertState, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    let prevState: models.StateEnum;
    taskIds.forEach((taskId) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        prevState = clone[index].state.taskStateId;
        clone[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      taskCollection: clone,
    };
  }),
  on(actions.revertStateSuccess, (state, { payload: { tasks } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    tasks.forEach((task) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === task.taskId);
      if (index !== -1) {
        clone[index] = task;
      }
    });
    return {
      ...state,
      taskCollection: clone,
    };
  }),
  on(actions.revertStateFail, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    taskIds.forEach((taskId) => {
      const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clone[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      taskCollection: clone,
    };
  }),
  on(
    actions.updateRelatedLegs,
    (state): State => ({
      ...state,
      taskCollectionLoading: true,
      taskCollectionLoaded: false,
    })
  ),
  on(actions.updateRelatedLegsSuccess, (state, { payload: { pod } }): State => {
    const taskCollection = state.taskCollection.map((task) => {
      return { ...task, leg: { ...task.leg, pod } };
    });
    return {
      ...state,
      taskCollectionLoading: false,
      taskCollectionLoaded: true,
      taskCollection,
    };
  }),
  on(
    actions.updateRelatedLegsFail,
    (state): State => ({
      ...state,
      taskCollectionLoading: false,
      taskCollectionLoaded: true,
    })
  ),
  on(
    actions.getRelatedLegs,
    (state): State => ({
      ...state,
      relatedLegsLoading: true,
      relatedLegsLoaded: false,
    })
  ),
  on(
    actions.getRelatedLegsSuccess,
    (state, { payload: { relatedLegs } }): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
      relatedLegs,
    })
  ),
  on(
    actions.getRelatedLegsFail,
    (state): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
    })
  ),

  on(actions.createNewTaskSuccess, (state, { payload: { task } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    clone.push(task);
    return {
      ...state,
      taskCollection: clone,
    };
  }),

  on(
    actions.addChecksSuccess,
    (state, { payload }): State => ({
      ...state,
      item: {
        ...state.item,
        aircraftInspectionId: payload,
      },
    })
  ),

  on(
    actions.loadInspections,
    (state): State => ({
      ...state,
      aircraftInspectionsLoading: true,
      aircraftInspectionsLoaded: false,
    })
  ),
  on(
    actions.loadInspectionsSuccess,
    (state, { payload: { items } }): State => ({
      ...state,
      aircraftInspectionItems: items,
      aircraftInspectionsLoading: false,
      aircraftInspectionsLoaded: true,
    })
  ),
  on(
    actions.loadCatering,
    (state): State => ({
      ...state,
      cateringLoading: true,
    })
  ),
  on(
    actions.loadCateringSuccess,
    (state, { payload }): State => ({
      ...state,
      cateringLoading: false,
      cateringLoaded: true,
      cateringItems: payload,
    })
  ),
  on(actions.setCheckSuccess, (state, { payload }): State => {
    const times = _.find(CHECKS, ({ data }) => _.some(data, ({ code }) => code === payload));
    const t = _.find(times.data, ({ code }) => code === payload);
    if (!t) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      item: {
        ...state.item,
        [t.timeKey]: moment().toDate(),
      },
    };
  }),
  on(actions.updatePaxState, (state, { payload: { paxId } }): State => {
    const passengers: Array<models.Passenger> = _.cloneDeep(state.item.passengers);
    const index = passengers.findIndex((p) => p.id === paxId);
    passengers[index].flightStatusId = 0;
    return {
      ...state,
      item: {
        ...state.item,
        passengers,
      },
    };
  }),
  on(
    actions.updatePaxStateSuccess,
    (state, { payload: { paxId, boardingStatusChangedTime, state: paxState } }): State => {
      const passengers: Array<models.Passenger> = _.cloneDeep(state.item.passengers);
      const index = passengers.findIndex((p) => p.id === paxId);
      switch (paxState) {
        case 'boarded':
          passengers[index].flightStatusId = 2;
          passengers[index].paxFlightStatus = PAX_FLIGHT_STATUSES.find(({ id }) => id === 2);
          break;
        case 'boardedingreset':
          passengers[index].flightStatusId = 1;
          passengers[index].paxFlightStatus = PAX_FLIGHT_STATUSES.find(({ id }) => id === 1);
          break;
        case 'boardingdenied':
          passengers[index].flightStatusId = 3;
          passengers[index].paxFlightStatus = PAX_FLIGHT_STATUSES.find(({ id }) => id === 3);
          break;
        case 'boardingnoshow':
          passengers[index].flightStatusId = 4;
          passengers[index].paxFlightStatus = PAX_FLIGHT_STATUSES.find(({ id }) => id === 4);
          break;
        default: {
          passengers[index].flightStatusId = 1;
          passengers[index].paxFlightStatus = PAX_FLIGHT_STATUSES.find(({ id }) => id === 1);
        }
      }
      passengers[index].boardingStatusChangedTime = boardingStatusChangedTime;
      return {
        ...state,
        item: {
          ...state.item,
          passengers,
        },
      };
    }
  ),
  on(
    actions.createNewNote,
    (state): State => ({
      ...state,
      taskNoteLoading: true,
    })
  ),
  on(actions.createNewNoteSuccess, (state, { payload: { note, taskId } }): State => {
    const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    if (index !== -1) {
      clone[index].notes.unshift(note);
      return {
        ...state,
        taskCollection: clone,
        taskNoteLoading: false,
      };
    }
    return {
      ...state,
      taskNoteLoading: false,
    };
  }),
  on(
    actions.createNewNoteFail,
    (state): State => ({
      ...state,
      taskNoteLoading: false,
    })
  ),

  on(
    actions.updateNote,
    (state): State => ({
      ...state,
      taskNoteLoading: true,
    })
  ),
  on(actions.updateNoteSuccess, (state, { payload: { note, taskId } }): State => {
    const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    if (index !== -1) {
      const noteIndex = clone[index].notes.findIndex((e) => note.id === e.id);
      clone[index].notes[noteIndex] = note;
      return {
        ...state,
        taskCollection: clone,
        taskNoteLoading: false,
      };
    }
    return {
      ...state,
      taskNoteLoading: false,
    };
  }),
  on(
    actions.updateNoteFail,
    (state): State => ({
      ...state,
      taskNoteLoading: false,
    })
  ),

  on(
    actions.deleteNote,
    (state): State => ({
      ...state,
      taskNoteLoading: true,
    })
  ),
  on(actions.deleteNoteSuccess, (state, { payload: { id, taskId } }): State => {
    const index = state.taskCollection.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.taskCollection);
    if (index !== -1) {
      const noteIndex = clone[index].notes.findIndex((e) => id === e.id);
      clone[index].notes.splice(noteIndex, 1);
      return {
        ...state,
        taskCollection: clone,
        taskNoteLoading: false,
      };
    }
    return {
      ...state,
      taskNoteLoading: false,
    };
  }),
  on(
    actions.deleteNoteFail,
    (state): State => ({
      ...state,
      taskNoteLoading: false,
    })
  )
);
