import { CustomShuttleExtraInvoiceItem } from './custom-shuttle-extra-invoice-item';

export class CustomShuttlePriceDetails {
  extraInvoiceItems: Array<CustomShuttleExtraInvoiceItem> = [];
  emptySeats: number = null;
  cysMarketableSeats: number = null;
  peakTravelSurcharge: number = null;
  departureDateLog: Date = null;
  pricePerSeat: number = null;
  oppositeEmptySeats: number = null;
  peakTravelMinTotalPrice: number = null;
  adjustedDow: string = null;
  creatorSeatsMultiplier: number = null;
  totalEmptySeats: number = null;
  penaltyCharge: number = null;
  season: string = null;
  minTotalPrice: string = null;
  adjMinSeats: number = null;
  demand: number = null;
  priceSurcharge: number = null;
  oppositeDemand: number = null;
  minPenaltySurchargePerEmptySeat: number = null;
  lastUpdatedTime: Date = null;
  ruleMinSeats: number = null;
  calloutDays: number = null;
  adjOppositeMinSeats: number = null;
  dow: string = null;
  totalSeats: number = null;
}
