import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/airport.actions';
import * as models from '../models';

export const airportFeatureKey = 'airport';

export interface State {
  airportOptions: Array<models.Airport>;
  airportOptionsLoading: boolean;
}

const initialState: State = {
  airportOptions: [],
  airportOptionsLoading: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.getAirportList,
    (state): State => ({
      ...state,
      airportOptionsLoading: true,
    })
  ),
  on(
    actions.getAirportListSuccess,
    (state, { payload }): State => ({
      ...state,
      airportOptions: payload,
      airportOptionsLoading: false,
    })
  ),
  on(
    actions.getAirportListFail,
    (state): State => ({
      ...state,
      airportOptionsLoading: false,
    })
  )
);
