import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class OrderAdjustmentOrderItem extends OrderItem {
  adjustedOrderItem: OrderItem;
  adjustedAmount = 0;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.Adjustment;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.adjustedAmount;
  }
}
