import { Injectable } from '@angular/core';

import * as contracts from '../../../domain/service-contracts/client';
import * as models from '../../../domain/models';
import * as mappers from '../mappers';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as _ from 'lodash';

import { response as XoClientResponse } from './get-client-xo-response';
import { response as JsSophisClientResponse } from './get-client-js-sophi-response';
import { response as JsMultiResponse } from './get-client-js-multi-response';
import { response as FlightPassResponse } from './get-flight-pass-response';
import { response as CreditMemoResponse } from './get-credit-memo-response';
import { response as GetFlightRequestsResponse } from './get-flight-requests-response';
import { response as GetCharterRequestsResponse } from './get-charter-requests-response';
import { response as conciergeFlightRequestsResponse } from './get-concierge-flight-requests-response';
import { responseSfdcOpportunityCancelWithOrder } from './get-sfdc-opportunity-cancel-response';

@Injectable()
export class ClientMockService implements contracts.IClientService {
  private clients: Array<models.Client>;
  private readonly clientsNoOrders: Array<models.Client>;

  constructor() {
    const clientArr = [XoClientResponse, JsSophisClientResponse, JsMultiResponse];
    this.clients = clientArr.map((c) => clientBuilder(c));
    this.clientsNoOrders = clientArr.map((c) => clientBuilderWithoutOrder(c));
  }

  loadSimplifiedMembership(
    request: number
  ): Observable<contracts.LoadSimplifiedMembershipResponse> {
    return of({ simplifiedMembership: 1 });
  }

  loadSfdcOpportunity(
    request: contracts.LoadSfdcOpportunityRequest
  ): Observable<contracts.LoadSfdcOpportunityResponse> {
    return of(new contracts.LoadSfdcOpportunityResponse());
  }

  loadSfdcOpportunityCancel(
    request: contracts.LoadSfdcOpportunityRequest
  ): Observable<contracts.LoadSfdcOpportunityCancelResponse> {
    const response = new contracts.LoadSfdcOpportunityCancelResponse();
    response.entity =
      mappers.SfdcOpportunityDataMapperService.parseSfdcOpportunityCancelDataInternal(
        responseSfdcOpportunityCancelWithOrder
      );
    // response.entity =
    //   SfdcOpportunityCancelDataMapperService.parseSfdcOpportunityCancelDataInternal(
    //     responseSfdcOpportunityCancelWithoutOrder
    //   );
    return of(response).pipe(delay(1000));
  }

  loadSfdcOpportunityFlightPass(
    request: contracts.loadSfdcOpportunityFlightPassRequest
  ): Observable<contracts.loadSfdcOpportunityFlightPassResponse> {
    const response = new contracts.loadSfdcOpportunityFlightPassResponse();
    response.entity =
      mappers.ClientMapperService.parseSfdcOpportunityFlightPassDataInternal(FlightPassResponse);
    return of(response).pipe(delay(500));
  }

  loadSfdcOpportunityCreditMemo(
    request: contracts.loadSfdcOpportunityCreditMemoRequest
  ): Observable<contracts.loadSfdcOpportunityCreditMemoResponse> {
    const response = new contracts.loadSfdcOpportunityCreditMemoResponse();
    response.entity =
      mappers.ClientMapperService.parseSfdcOpportunityCreditMemoDataInternal(CreditMemoResponse);
    return of(response).pipe(delay(500));
  }

  getClient(request: contracts.GetClientRequest): Observable<contracts.GetClientResponse> {
    const getClientResponse = new contracts.GetClientResponse();
    const resp = this.clients.find((c) => c.id === request.clientId);
    getClientResponse.entity = resp === undefined ? null : resp;

    return of(getClientResponse).pipe(delay(5000));
  }

  getClientWithoutOrder(
    request: contracts.GetClientWithoutOrderRequest
  ): Observable<contracts.GetClientWithoutOrderResponse> {
    const getClientResponse = new contracts.GetClientWithoutOrderResponse();
    const resp = this.clientsNoOrders.find((c) => c.id === request.clientId);
    getClientResponse.entity = resp === undefined ? null : resp;

    return of(getClientResponse).pipe(delay(500));
  }

  removeCharterInvoice(
    request: contracts.RemoveCharterInvoiceRequest
  ): Observable<contracts.RemoveCharterInvoiceResponse> {
    const removeCharterInvoiceResponse = ['200'];
    return of(removeCharterInvoiceResponse);
  }

  searchClients(
    request: contracts.SearchClientsRequest
  ): Observable<contracts.SearchClientsResponse> {
    const searchClientsResponse = new contracts.SearchClientsResponse();

    if (request.clientId !== null) {
      searchClientsResponse.entities = [this.clientsNoOrders[request.clientId - 1]];
      searchClientsResponse.count = 1;
    } else {
      searchClientsResponse.entities = this.clientsNoOrders;
      searchClientsResponse.count = this.clientsNoOrders.length;
    }

    return of(searchClientsResponse);
  }

  searchClientsByName(request: contracts.SearchClientsByNameRequest) {
    const searchClientsResponse = new contracts.SearchClientsResponse();

    if (request.clientName !== null) {
      searchClientsResponse.entities = _.find(this.clientsNoOrders, {
        clientName: request.clientName,
      });
      searchClientsResponse.count = 1;
    } else {
      searchClientsResponse.entities = this.clientsNoOrders;
      searchClientsResponse.count = this.clientsNoOrders.length;
    }

    return of(searchClientsResponse);
  }

  searchAccountsByName(
    request: contracts.SearchAccountsByNameRequest
  ): Observable<contracts.SearchAccountsByNameResponse> {
    const response = new contracts.SearchAccountsByNameResponse();
    return of(response);
  }

  getFlightRequests(
    request: contracts.GetFlightRequestsRequest
  ): Observable<contracts.GetFlightRequestsResponse> {
    const getClientRequestsResponse = new contracts.GetFlightRequestsResponse();

    getClientRequestsResponse.entities = GetFlightRequestsResponse.data.map((rq) => {
      const ro = new models.ClientRequest();
      ro.requestId = rq.requestId;
      ro.departureDate = rq.departureDate !== null ? new Date(rq.departureDate) : null;
      ro.requestUrl = rq.requestUrl ? rq.requestUrl : null;
      ro.flightRequest = rq.flightRequest
        ? mappers.CharterRequestMapperService.mapCharterRequestInternal(rq.flightRequest)
        : null;

      return ro;
    });

    return of(getClientRequestsResponse).pipe(delay(500));
  }

  getConciergeAncillaryData(
    request: contracts.GetConciergeAncillaryDataRequest
  ): Observable<contracts.GetConciergeAncillaryDataResponse> {
    const response = new contracts.GetConciergeAncillaryDataResponse();
    response.entity = mappers.ClientMapperService.parseConciergeFlightAncillaryData(
      conciergeFlightRequestsResponse
    );
    return of(response).pipe(delay(500));
  }

  getCharterRequests(
    request: contracts.GetCharterRequestsRequest
  ): Observable<contracts.GetCharterRequestsResponse> {
    const getClientRequestsResponse = new contracts.GetCharterRequestsResponse();

    getClientRequestsResponse.entities = GetCharterRequestsResponse.data.map((rq) => {
      return mappers.CharterRequestMapperService.mapCharterRequestInternal(rq);
    });

    return of(getClientRequestsResponse).pipe(delay(500));
  }

  createCharterInvoice(
    request: contracts.CreateCharterInvoiceRequest
  ): Observable<contracts.CreateCharterInvoiceResponse> {
    return of(new contracts.CreateCharterInvoiceResponse()).pipe(delay(200));
  }
}

const clientBuilder = (svcResp: any): models.Client => {
  const clientResp = svcResp.client.data;
  const ordersResp = svcResp.orders.data;
  const client = mappers.ClientMapperService.parseClientInternal(clientResp);
  client.orders = ordersResp.items.map((e) => mappers.OrderMapperService.parseOrderInternal(e));
  return client;
};

const clientBuilderWithoutOrder = (svcResp: any): models.Client =>
  mappers.ClientMapperService.parseClientInternal(svcResp.client.data);
