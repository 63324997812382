import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/amend-charges.actions';
import { LegCost } from '../../domain/models/leg-cost';
import { CostType } from '../../domain/models/cost-type';

export interface State {
  isDialogOpen: boolean;
  isRemoveConfirmationDialogIsOpen: boolean;
  isEmptyFields: boolean;
  requestId?: number;
  isLegCostsTableLoading: boolean;
  isCancelConfirmationDialogOpen: boolean;
  originalLegCosts: LegCost[];
  modifiedLegCosts: LegCost[];
  totalAmount: number;
  isCostTypesListLoading: boolean;
  costTypesList: CostType[];
  legCostIdToBeRemoved?: number;
  isSaveButtonLoading: boolean;
  finalizedLegIds: String[];
  hasFinalizedLegs?: boolean;
  isFinalizedLegsCheckRunning: boolean;
  isFinalizedLegIdsLoading: boolean;
}

export const amendChargesFeatureKey = 'amendCharges';

const initialState: State = {
  isDialogOpen: false,
  isRemoveConfirmationDialogIsOpen: false,
  isEmptyFields: false,
  isLegCostsTableLoading: false,
  isCancelConfirmationDialogOpen: false,
  originalLegCosts: [],
  modifiedLegCosts: [],
  totalAmount: 0.0,
  isCostTypesListLoading: false,
  isSaveButtonLoading: false,
  costTypesList: [],
  finalizedLegIds: [],
  isFinalizedLegsCheckRunning: false,
  isFinalizedLegIdsLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.toggleDialog,
    (state): State => ({
      ...state,
      isDialogOpen: !state.isDialogOpen,
    })
  ),
  on(actions.enableSaveButton, (state, { emptyFields }): State => {
    return {
      ...state,
      isEmptyFields: emptyFields == true,
    };
  }),
  on(
    actions.toggleConfirmRemoveDialog,
    (state): State => ({
      ...state,
      isRemoveConfirmationDialogIsOpen: !state.isRemoveConfirmationDialogIsOpen,
    })
  ),
  on(
    actions.toggleLoadingLegCostsTable,
    (state): State => ({
      ...state,
      isLegCostsTableLoading: !state.isLegCostsTableLoading,
    })
  ),
  on(
    actions.setLegCostsTableData,
    (state, { legCosts }): State => ({
      ...state,
      originalLegCosts: legCosts,
      modifiedLegCosts: legCosts,
    })
  ),
  on(
    actions.toggleCostTypesListLoading,
    (state): State => ({
      ...state,
      isCostTypesListLoading: !state.isCostTypesListLoading,
    })
  ),
  on(
    actions.setCostTypesList,
    (state, { costTypes }): State => ({
      ...state,
      costTypesList: costTypes,
    })
  ),
  on(actions.updateTotalLegCostsAmount, (state): State => {
    const legCosts = state.modifiedLegCosts.filter((legCost) => legCost.flightRequestId !== -1);

    if (legCosts.length === 0) {
      return state;
    }

    return {
      ...state,
      totalAmount: legCosts
        .map((legCost) => legCost.amount)
        .reduce((previousValue, currentValue) => previousValue + currentValue),
    };
  }),
  on(actions.removeLegCost, (state) => {
    if (state.legCostIdToBeRemoved === undefined) {
      return { ...state };
    }
    if(state.legCostIdToBeRemoved < 0) {
      return {
        ...state,
        modifiedLegCosts: state.modifiedLegCosts.filter(function(legCost) {
          return legCost.id != state.legCostIdToBeRemoved;
        })
      }
    }
    return {
      ...state,
      modifiedLegCosts: state.modifiedLegCosts.map((legCost) =>
        legCost.id === state.legCostIdToBeRemoved ? { ...legCost, flightRequestId: -1 } : legCost
      ),
    };
  }),
  on(
    actions.insertLegCost,
    (state, { legCost }): State => ({
      ...state,
      modifiedLegCosts: [...state.modifiedLegCosts, legCost],
    })
  ),
  on(
    actions.updateLegCost,
    (state, { legCost: modifiedLegCost }): State => ({
      ...state,
      modifiedLegCosts: state.modifiedLegCosts.map((legCost) => {
        if (legCost.id === modifiedLegCost.id) {
          return modifiedLegCost;
        }
        return legCost;
      }),
    })
  ),
  on(
    actions.resetModifiedLegCosts,
    (state): State => ({
      ...state,
      modifiedLegCosts: state.originalLegCosts,
    })
  ),
  on(
    actions.setLegCostIdToBeRemoved,
    (state, { legCostId }): State => ({
      ...state,
      legCostIdToBeRemoved: legCostId,
    })
  ),
  on(
    actions.toggleSaveButtonLoading,
    (state): State => ({
      ...state,
      isSaveButtonLoading: !state.isSaveButtonLoading,
    })
  ),
  on(
    actions.setRequestId,
    (state, { requestId }): State => ({
      ...state,
      requestId: requestId,
    })
  ),
  on(
    actions.setFinalizedLegIds,
    (state, { finalizedLegIds }): State => ({
      ...state,
      finalizedLegIds: finalizedLegIds,
    })
  ),
  on(
    actions.toggleLoadingFinalizedLegIds,
    (state): State => ({
      ...state,
      isFinalizedLegIdsLoading: !state.isFinalizedLegIdsLoading,
    })
  ),
  on(
    actions.setHasFinalizedLegs,
    (state, { hasFinalizedLegs }): State => ({
      ...state,
      hasFinalizedLegs,
    })
  ),
  on(
    actions.setIsFinalizedLegsCheckRunning,
    (state, { isFinalizedLegsCheckRunning }): State => ({
      ...state,
      isFinalizedLegsCheckRunning: isFinalizedLegsCheckRunning,
    })
  )
);
