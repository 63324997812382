export class ClientLookupCriteria {
  clientId: number = null;
  legalName: string = null;
  dateOfBirth: Date = null;
  emailAddress: string = null;
  phoneNumber: string = null;

  isEmpty(): boolean {
    return (
      this.clientId === null &&
      this.legalName === null &&
      this.dateOfBirth === null &&
      this.emailAddress === null &&
      this.phoneNumber === null
    );
  }
}
