import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class LoadPayload {
  uuid: string;
}
export const load = createAction('[TaskMgmt.Leg] Load', props<{ payload: LoadPayload }>());
export const loadSuccess = createAction(
  '[TaskMgmt.Leg] Load Success',
  props<{ payload: models.Leg }>()
);
export const loadFail = createAction('[TaskMgmt.Leg] Load Fail');

export const resetLeg = createAction('[TaskMgmt.Leg] Reset Leg');

export const setTasksInitial = createAction('[TaskMgmt.Leg] Set Tasks Initial');

export class LoadTasksPayload {
  search: number;
}
export const loadTasks = createAction(
  '[TaskMgmt.Leg] Load Tasks',
  props<{ payload: LoadTasksPayload }>()
);
export class LoadTasksSuccessPayload {
  items: Array<models.Task>;
}
export const loadTasksSuccess = createAction(
  '[TaskMgmt.Leg] Load Tasks Success',
  props<{ payload: LoadTasksSuccessPayload }>()
);
export const loadTasksFail = createAction('[TaskMgmt.Leg] Load Tasks Fail');

export class SortTasksPayload {
  sortBy: models.SortByEnum;
  sortOrder: models.SortOrderEnum;
}
export const sortTasks = createAction(
  '[TaskMgmt.Leg] Sort Tasks',
  props<{ payload: SortTasksPayload }>()
);

export class UpdateLegsPayload {
  legs: Array<models.LegRequest>;
}
export const updateLegs = createAction(
  '[TaskMgmt.Leg] Update Legs',
  props<{ payload: UpdateLegsPayload }>()
);

export class CompleteLegsPayload {
  updateLegsPayload: UpdateLegsPayload;
  setStateForMultipleTaskPayload: SetStateForMultipleTaskPayload;
}
export const completeLegs = createAction(
  '[TaskMgmt.Leg] Complete Legs',
  props<{ payload: CompleteLegsPayload }>()
);
export const completeLegsFail = createAction('[TaskMgmt.Leg] Complete Legs Fail');

export class UpdateLegsSuccessPayload {
  entities: Array<models.FlightLeg>;
}
export const updateLegsSuccess = createAction(
  '[TaskMgmt.Leg] Update Legs Success',
  props<{ payload: UpdateLegsSuccessPayload }>()
);
export const updateLegsFail = createAction('[TaskMgmt.Leg] Update Legs Fail');

export class SetStateForMultipleTaskPayload {
  taskStateId: number;
  taskIds: Array<number>;
}
export const setStateForMultipleTask = createAction(
  '[TaskMgmt.Leg] Set State For Multiple Task',
  props<{ payload: SetStateForMultipleTaskPayload }>()
);
export class SetStateForMultipleTaskSuccessPayload {
  tasks: Array<models.Task>;
}
export const setStateForMultipleTaskSuccess = createAction(
  '[TaskMgmt.Leg] Set State For Multiple Task Success',
  props<{ payload: SetStateForMultipleTaskSuccessPayload }>()
);
export class SetStateForMultipleTaskFailedPayload {
  taskIds: Array<number>;
  taskStateId: models.StateEnum;
}
export const setStateForMultipleTaskFail = createAction(
  '[TaskMgmt.Leg] Set State For Multiple Task Fail',
  props<{ payload: SetStateForMultipleTaskFailedPayload }>()
);

export class RevertStatePayload {
  taskIds: Array<number>;
}
export const revertState = createAction(
  '[TaskMgmt.Leg] Revert State',
  props<{ payload: RevertStatePayload }>()
);
export class RevertStateSuccessPayload {
  tasks: Array<models.Task>;
}
export const revertStateSuccess = createAction(
  '[TaskMgmt.Leg] Revert State Success',
  props<{ payload: RevertStateSuccessPayload }>()
);
export class RevertStateFailedPayload {
  taskIds: Array<number>;
}
export const revertStateFail = createAction(
  '[TaskMgmt.Leg] Revert State Fail',
  props<{ payload: RevertStateFailedPayload }>()
);

export class CreateNewTaskPayload {
  legId: number;
  dueDate: Date;
  description: string;
  taskCategoryId: number;
  title: string;
}
export const createNewTask = createAction(
  '[TaskMgmt.Leg] Create New Task',
  props<{ payload: CreateNewTaskPayload }>()
);
export class CreateNewTaskSuccessPayload {
  task: models.Task;
}
export const createNewTaskSuccess = createAction(
  '[TaskMgmt.Leg] Create New Task Success',
  props<{ payload: CreateNewTaskSuccessPayload }>()
);
export const createNewTaskFail = createAction(
  '[TaskMgmt.Leg] Create New Task Fail',
  props<{ payload: any }>()
);

export class FinalizeLegPayload {
  legId: number;
}
export const finalizeLeg = createAction(
  '[TaskMgmt.Leg] Finalize Leg',
  props<{ payload: FinalizeLegPayload }>()
);
export const finalizeLegSuccess = createAction('[TaskMgmt.Leg] Finalize Leg Success');
export const finalizeLegFail = createAction('[TaskMgmt.Leg] Finalize Leg Fail');

export class UpdateRelatedLegsPayload {
  flightRequestId: number;
  legIds: Array<number>;
  pod: string;
}
export const updateRelatedLegs = createAction(
  '[TaskMgmt.Leg] Update Related Legs',
  props<{ payload: UpdateRelatedLegsPayload }>()
);
export class UpdateRelatedLegsSuccessPayload {
  pod: string;
}
export const updateRelatedLegsSuccess = createAction(
  '[TaskMgmt.Leg] Update Related Legs Success',
  props<{ payload: UpdateRelatedLegsSuccessPayload }>()
);
export const updateRelatedLegsFail = createAction('[TaskMgmt.Leg] Update Related Legs Fail');

export class GetRelatedLegsPayload {
  legId: number;
}
export const getRelatedLegs = createAction(
  '[TaskMgmt.Leg] Get Related Legs',
  props<{ payload: GetRelatedLegsPayload }>()
);
export class GetRelatedLegsSuccessPayload {
  relatedLegs: models.RelatedLegs;
}
export const getRelatedLegsSuccess = createAction(
  '[TaskMgmt.Leg] Get Related Legs Success',
  props<{ payload: GetRelatedLegsSuccessPayload }>()
);
export const getRelatedLegsFail = createAction('[TaskMgmt.Leg] Get Related Legs Fail');

export class SetCheckPayload {
  legId: number;
  check: string;
}
export const setCheck = createAction(
  '[TaskMgmt.Leg] Set Check',
  props<{ payload: SetCheckPayload }>()
);
export const setCheckSuccess = createAction(
  '[TaskMgmt.Leg] Set Check Success',
  props<{ payload: string }>()
);
export const setCheckFail = createAction('[TaskMgmt.Leg] Set Check Fail');

export class AddChecksPayload {
  inspectionItems: Array<{ pass: boolean; images: Array<any>; itemTypeId: number }>;
  legId: number;
  regNr: string;
}
export const addChecks = createAction(
  '[TaskMgmt.Leg] Add Checks',
  props<{ payload: AddChecksPayload }>()
);
export const addChecksSuccess = createAction(
  '[TaskMgmt.Leg] Add Checks Success',
  props<{ payload: number }>()
);
export const addChecksFail = createAction('[TaskMgmt.Leg] Add Checks Fail');

export const loadInspections = createAction(
  '[TaskMgmt.Leg] Load Inspections',
  props<{ payload: number }>()
);
export class LoadInspectionsSuccessPayload {
  items: Array<models.AircraftInspection>;
}
export const loadInspectionsSuccess = createAction(
  '[TaskMgmt.Leg] Load Inspections Success',
  props<{ payload: LoadInspectionsSuccessPayload }>()
);
export const loadInspectionsFail = createAction('[TaskMgmt.Leg] Load Inspections Fail');

export class LoadCateringPayload {
  legId: number;
}
export const loadCatering = createAction(
  '[TaskMgmt.Leg] Load Catering',
  props<{ payload: LoadCateringPayload }>()
);
export const loadCateringSuccess = createAction(
  '[TaskMgmt.Leg] Load Catering Success',
  props<{ payload: Array<models.Catering> }>()
);
export const loadCateringFail = createAction('[TaskMgmt.Leg] Load Catering Fail');

export class UpdatePaxStatePayload {
  legId: number;
  paxId: number;
  state: string;
  denyReasonId?: number;
  denyReasonText?: string;
  boardingStatusChangedTime?: Date;
}
export const updatePaxState = createAction(
  '[TaskMgmt.Leg] Update Pax State',
  props<{ payload: UpdatePaxStatePayload }>()
);
export const updatePaxStateSuccess = createAction(
  '[TaskMgmt.Leg] Update Pax State Success',
  props<{ payload: UpdatePaxStatePayload }>()
);
export const updatePaxStateFail = createAction('[TaskMgmt.Leg] Update Pax State Fail');

export class CreateNewNotePayload {
  taskId: number;
  text: string;
}
export const createNewNote = createAction(
  '[TaskMgmt.Leg] Create New Note',
  props<{ payload: CreateNewNotePayload }>()
);
export class CreateNewNoteSuccessPayload {
  note: models.Note;
  taskId: number;
}
export const createNewNoteSuccess = createAction(
  '[TaskMgmt.Leg] Create New Note Success',
  props<{ payload: CreateNewNoteSuccessPayload }>()
);
export const createNewNoteFail = createAction(
  '[TaskMgmt.Leg] Create New Note Fail',
  props<{ payload?: any }>()
);

export class UpdateNotePayload {
  id: string;
  text: string;
  taskId: number;
}
export const updateNote = createAction(
  '[TaskMgmt.Leg] Update Note',
  props<{ payload: UpdateNotePayload }>()
);
export class UpdateNoteSuccessPayload {
  note: models.Note;
  taskId: number;
}
export const updateNoteSuccess = createAction(
  '[TaskMgmt.Leg] Update Note Success',
  props<{ payload: UpdateNoteSuccessPayload }>()
);
export const updateNoteFail = createAction(
  '[TaskMgmt.Leg] Update Note Fail',
  props<{ payload?: any }>()
);

export class DeleteNotePayload {
  id: string;
  taskId: number;
}
export const deleteNote = createAction(
  '[TaskMgmt.Leg] Delete Note',
  props<{ payload: DeleteNotePayload }>()
);
export class DeleteNoteSuccessPayload {
  id: string;
  taskId: number;
}
export const deleteNoteSuccess = createAction(
  '[TaskMgmt.Leg] Delete Note Success',
  props<{ payload: DeleteNoteSuccessPayload }>()
);
export const deleteNoteFail = createAction(
  '[TaskMgmt.Leg] Delete Note Fail',
  props<{ payload?: any }>()
);
