import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD_CAMBER_OPTIONS = '[Sourcing.AircraftAvailability] Load Camber Options';
export const LOAD_CAMBER_OPTIONS_SUCCESS = '[Sourcing.AircraftAvailability] Load Camber Options Success';
export const LOAD_CAMBER_OPTIONS_FAIL = '[Sourcing.AircraftAvailability] Load Camber Options Fail';
export const LOAD_MARKETPLACE_OPTIONS = '[Sourcing.AircraftAvailability] Load Marketplace Options';
export const LOAD_MARKETPLACE_OPTIONS_SUCCESS = '[Sourcing.AircraftAvailability] Load Marketplace Options Success';
export const LOAD_MARKETPLACE_OPTIONS_FAIL = '[Sourcing.AircraftAvailability] Load Marketplace Options Fail';

export class LoadOptionsActionPayload {
  constructor(public sourcingRequestId: string) {}
}

export class LoadCamberOptionsAction implements Action {
  readonly type = LOAD_CAMBER_OPTIONS;

  constructor(public payload: LoadOptionsActionPayload) {}
}

export class LoadCamberOptionsSuccessAction implements Action {
  readonly type = LOAD_CAMBER_OPTIONS_SUCCESS;

  constructor(public payload: Array<models.CamberOption>) {}
}

export class LoadCamberOptionsFailAction implements Action {
  readonly type = LOAD_CAMBER_OPTIONS_FAIL;
}

export class LoadMarketplaceOptionsAction implements Action {
  readonly type = LOAD_MARKETPLACE_OPTIONS;

  constructor(public payload: LoadOptionsActionPayload) {}
}

export class LoadMarketplaceOptionsSuccessAction implements Action {
  readonly type = LOAD_MARKETPLACE_OPTIONS_SUCCESS;

  constructor(public payload: Array<models.MarketplaceOption>) {}
}

export class LoadMarketplaceOptionsFailAction implements Action {
  readonly type = LOAD_MARKETPLACE_OPTIONS_FAIL;
}

export type Actions = LoadCamberOptionsAction 
  | LoadCamberOptionsSuccessAction 
  | LoadCamberOptionsFailAction
  | LoadMarketplaceOptionsAction
  | LoadMarketplaceOptionsSuccessAction
  | LoadMarketplaceOptionsFailAction;
