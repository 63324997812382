import { Injectable } from '@angular/core';
import * as contracts from '../../../service-contracts/airport';
import { Observable, of } from 'rxjs';

@Injectable()
export class AirportMockService implements contracts.IAirportService {
  getAirportByCode(
    request: contracts.GetAirportByCodeRequest
  ): Observable<contracts.GetAirportByCodeResponse> {
    const response = new contracts.GetAirportByCodeResponse();
    return of(response);
  }
}
