import { EmailActionTypeEnum } from "./email-action-type-enum";
import { EmailProcessSourceEnum } from "./email-process-source-enum";

export class EmailProcessingMetaData {
  emailResponseTime: Date;
  actionType: EmailActionTypeEnum;
  pdfUrl: string;
  source: EmailProcessSourceEnum;
  pdfTemplate: string;
  emailUrl: string;
  priceDeviationDetected: boolean;
  message: string;

  getEmailResponseTime(): Date {
    return this.emailResponseTime;
  }

  setEmailResponseTime(emailResponseTime: Date): EmailProcessingMetaData {
    this.emailResponseTime = emailResponseTime;

    return this;
  }

  getActionType(): EmailActionTypeEnum {
    return this.actionType;
  }

  setActionType(actionType: EmailActionTypeEnum): EmailProcessingMetaData {
    this.actionType = actionType;

    return this;
  }
  getPdfUrl(): string {
    return this.pdfUrl;
  }

  setPdfUrl(pdfUrl: string): EmailProcessingMetaData {
    this.pdfUrl = pdfUrl;

    return this;
  }

  getSource(): EmailProcessSourceEnum {
   return  this.source;
  }
  
  setSource(source: EmailProcessSourceEnum): EmailProcessingMetaData {
    this.source = source;
    
    return this;
  }

  getPdfTemplate(): string {
    return this.pdfTemplate;
  }

  setPdfTemplate(pdfTemplate: string): EmailProcessingMetaData {
    this.pdfTemplate = pdfTemplate;

    return this;
  }

  getEmailUrl(): string {
    return this.emailUrl; 
  }

  setEmailUrl(emailUrl: string): EmailProcessingMetaData {
    this.emailUrl = emailUrl;

    return this;
  }

  getPriceDeviationDetected(): boolean {
    return this.priceDeviationDetected;
  }

  setPriceDeviationDetected(priceDeviationDetected: boolean): EmailProcessingMetaData {
    this.priceDeviationDetected = priceDeviationDetected;
    
    return this;
  }

  getMessage(): string {
    return this.message;
  }

  setMessage(message: string): EmailProcessingMetaData {
    this.message = message;
    
    return this;
  }
}