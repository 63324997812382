export const response = {
  data: [
    {
      id: 1,
      name: 'Guaranteed Aircraft Recovery',
      notes: 'notes Guaranteed Aircraft Recovery',
    },
    {
      id: 2,
      name: 'Guaranteed Availability Peak Days(24 Hour Notice)',
      notes: 'notes Guaranteed Availability Peak Days(24 Hour Notice)',
    },
    {
      id: 3,
      name: 'Catering credit ($250 / Leg)',
      notes: 'notes mock text mock text mock text',
    },
    {
      id: 4,
      name: 'Get free seats on empty legs',
      notes: 'notes Get free seats on empty legs',
    },
    {
      id: 5,
      name: 'Fixed Pricing',
      notes: 'notes Fixed Pricing',
    },
    {
      id: 6,
      name: 'Fixed Pricing 2',
      notes: 'notes Fixed Pricing 2',
    },
    {
      id: 7,
      name: 'Fixed Pricing 3',
      notes: 'notes Fixed Pricing 3',
    },
    {
      id: 8,
      name: 'Fixed Pricing 4',
      notes: 'notes Fixed Pricing 3',
    },
  ],
};
