import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { CurrencyRateContentComponent } from './currency-rate-content';

@Directive({
  selector: 'currencyRateTooltip',
})
export class CurrencyRateDirective implements OnInit {
  @Input('currencyRateTooltip') currencyRate: any;
  @Input() contentClass: string;
  @Input() amount: number;

  protected currencyRateComponent = CurrencyRateContentComponent;
  protected currencyRateInstance;

  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected resolver: ComponentFactoryResolver
  ) {
  }

  ngOnInit(): void {
    const factory = this.resolver.resolveComponentFactory(this.currencyRateComponent);
    this.currencyRateInstance = this.viewContainerRef.createComponent(factory).instance as CurrencyRateContentComponent;
    this.updateProperties();
  }

  ngOnChanges(): void {
    if (this.currencyRateInstance) {
      this.updateProperties();
    }
  }

  updateProperties() {
    this.currencyRateInstance.rateInfo = this.currencyRate;
    this.currencyRateInstance.amount = this.amount;
    this.currencyRateInstance.contentClass = this.contentClass;
  }
}
