import { User } from './user';
import { PRICING_TYPES } from '../../constants';

export class LegPriceAudit {
  // Shared Charter
  private internal: boolean = null;

  // All
  private seatPrice: number = null;
  private createdBy: User;
  private createdDate: Date = null;
  private departureTimeLocal: Date = null;
  private legId: number = null;
  private updateReasonId: number = null;
  private active: boolean = null;

  // All - For Extended Log list
  private departureAirportCode: string = null;
  private arrivalAirportCode: string = null;
  private departureDateLocal: Date = null;

  overridePricingType: PRICING_TYPES = null;

  availableSeatNewPrice: number = null;
  availableSeatOldPrice: number = null;
  expirationEndTime: Date = null;
  expirationStartTime: Date = null;
  seatsOverridden: string = null;
  bulkId: string = null;

  getLegId(): number {
    return this.legId;
  }

  setLegId(id: number): LegPriceAudit {
    this.legId = id;

    return this;
  }

  getSeatPrice(): number {
    return this.seatPrice;
  }

  setSeatPrice(price: number): LegPriceAudit {
    this.seatPrice = price;

    return this;
  }

  getInternal(): boolean {
    return this.internal;
  }

  setInternal(internal: boolean): LegPriceAudit {
    this.internal = internal;

    return this;
  }

  getActive(): boolean {
    return this.active;
  }

  setActive(active: boolean): LegPriceAudit {
    this.active = active;

    return this;
  }

  getCreatedBy(): User {
    return this.createdBy;
  }

  setCreatedBy(user: User): LegPriceAudit {
    this.createdBy = user;

    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(date: Date): LegPriceAudit {
    this.createdDate = date;

    return this;
  }

  getDepartureTimeLocal(): Date {
    return this.departureTimeLocal;
  }

  setDepartureTimeLocal(date: Date): LegPriceAudit {
    this.departureTimeLocal = date;

    return this;
  }

  getUpdateReasonId(): number {
    return this.updateReasonId;
  }

  setUpdateReasonId(updateReasonId: number): LegPriceAudit {
    this.updateReasonId = updateReasonId;

    return this;
  }

  getDepartureAirportCode(): string {
    return this.departureAirportCode;
  }

  setDepartureAirportCode(code: string): void {
    this.departureAirportCode = code;
  }

  getArrivalAirportCode(): string {
    return this.arrivalAirportCode;
  }

  setArrivalAirportCode(code: string): void {
    this.arrivalAirportCode = code;
  }

  getDepartureDateLocal(): Date {
    return this.departureDateLocal;
  }

  setDepartureDateLocal(date: Date): void {
    this.departureDateLocal = date;
  }
}
