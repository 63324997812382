import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Rating] Load';
export const LOAD_SUCCESS = '[RM Rating] Load Success';
export const LOAD_FAIL = '[RM Rating] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: models.Rating) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
