import { Permissions } from "../../../../core/models/permissions";
import { Operator } from "./operator";
import { UserGroup } from "./user-group";
import { UserAccount } from './user-account';

export class VendorOrganizationUser {
  userTypeId: number = null;
  organizationId: number = null;
  firstName: string = null;
  lastName: string = null;
  phoneNumber: string = null;
  emailAddress: string = null;
  active = true;
  activated = false;
  superUser = false;
  userId: number = null;
  lastLoginDate: Date = null;
  accounts: Array<UserAccount> = [];
  operatorId: string;
  createTime: Date;
  activatedTime: Date;
  operator: Operator;
  permissions: Permissions = new Permissions();
  groups: Array<UserGroup> = [];

  constructor(data: VendorOrganizationUser = null) {
    if (data) {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.emailAddress = data.emailAddress;
      this.phoneNumber = data.phoneNumber;
      this.superUser = !!data.superUser;
      this.active = !!data.active;
      this.activated = !!data.activated;
      this.userId = data.userId;
      this.lastLoginDate = data.lastLoginDate;
      this.operatorId = data.operatorId;
      this.createTime = data.createTime;
      this.activatedTime = data.activatedTime;
      this.operator = data.operator;
      this.permissions = data.permissions;
    }
  }
}
