import { Action } from '@ngrx/store';

import * as models from '../../domain/models';

export const LOAD = '[RM Categories Collection] Load';
export const LOAD_SUCCESS = '[RM Categories Collection] Load Success';
export const LOAD_FAIL = '[RM Categories Collection] Load Fail';
export const RESET = '[RM Categories Collection] Reset';

export class LoadCategoriesAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessCategoriesPayload {
  entities: Array<models.AircraftCategory>;
}

export class LoadSuccessCategoriesAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessCategoriesPayload) {}
}

export class LoadFailCategoriesAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadCategoriesAction
  | LoadSuccessCategoriesAction
  | LoadFailCategoriesAction
  | ResetAction;
