import { Lookup } from "./lookup";

export class ClientLookup extends Lookup {
    clientId: number = null;
    legalName: string = null;
    dateOfBirth: Date = null;
    emailAddress: string = null;
    phoneNumber: string = null;
    limit: number = 5;
  }
  