import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../../domain/models';

export interface IAdditionalFeaturesService {
  getAdditionalFeatures(
    request: GetAdditionalFeaturesRequest
  ): Observable<GetAdditionalFeaturesResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Sales.IAdditionalFeaturesService');

export class GetAdditionalFeaturesRequest {}

export class GetAdditionalFeaturesResponse {
  entities: Array<models.AdditionalFeature> = [];
}
