export class PriceComponent {
  private name: string;
  private unitBasePrice: number;
  private unitPercentage: number;
  private priceComponentId: string;

  getName(): string {
    return this.name;
  }

  setName(name: string): PriceComponent {
    this.name = name;

    return this;
  }

  getUnitBasePrice(): number {
    return this.unitBasePrice;
  }

  getPriceComponentId(): string {
    return this.priceComponentId;
  }

  setPriceComponentId(priceComponentId: string): PriceComponent {
    this.priceComponentId = priceComponentId;
    return this;
  }

  setUnitBasePrice(unitBasePrice: number): PriceComponent {
    this.unitBasePrice = unitBasePrice;

    return this;
  }

  getUnitPercentage(): number {
    return this.unitPercentage;
  }

  setUnitPercentage(unitPercentage: number): PriceComponent {
    this.unitPercentage = unitPercentage;

    return this;
  }
}
