import { AircraftRating } from './aircraft-rating';

export class Operator {
  id: string = null;
  name: string = null;
  legalName: string = null;
  argusRating: AircraftRating = new AircraftRating();
  wyvernRating: AircraftRating = new AircraftRating();
  isBaoRating: AircraftRating = new AircraftRating();
  levelFlightRating: AircraftRating = new AircraftRating();
  dedicatedFleet: boolean;

  get legalNameAndName(): string {
    return this.legalName
      && this.name
      && this.legalName == this.name
        ? this.legalName
        : `${this.legalName} (${this.name})`;
  }

  equals(o1: Operator): boolean {
    if (!o1) {
      return false;
    }

    return this.id === o1.id;
  }
}
