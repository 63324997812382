import * as actions from '../actions/new-flight-request';

export interface State {
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {


    case actions.SUBMIT: {
      return {
        ...state,
        loading: true
      };
    }

    case actions.SUBMIT_FAIL:
    case actions.SUBMIT_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;

