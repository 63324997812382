export class BulkEditOffer {
  launch: number;
  minSeats: number;
  pricePerSeat: number;
  pricePerExtraSeat: number;
  priceSurcharge: number;
  shuttleTypeName: string;
  departureStartTime: Date | string;
  departureEndTime: Date | string;
  offerTypeName: string;
  aircraftCategoryName: string;
  aircraftCategoryId: number;
  crowdSourceEnabled: boolean;
  departureAirportCodes: Array<string>;
  arrivalAirportCodes: Array<string>;
  lifeTimeMinimum: number = null;
  lifeTimeMaximum: number = null;
}
