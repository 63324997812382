import { ProductOrderItem } from './product-order-item';
import { OrderItemTypeEnum } from './order-item-type';
import { PriceComponent } from './price-component';
import { CateringCredit } from './catering-credit';
import { CreditReason } from "./concierge-flight-ancillary-data";

export class ConciergeOrderItem extends ProductOrderItem {
  vendorId: number = null;
  vendorRefNumber: string = null;
  quoteAmount: number = null;
  addMarkupPercent: boolean = true;
  markupPercent: number = null;
  vendorMarkupPercent: number = null;
  clientComments: string = null;
  serviceDate: Date = null;
  comments: string = null;
  surcharge: PriceComponent = null;
  requestUrl: string = null;
  serviceDiscountAmount: number = null;
  cateringCredit: CateringCredit;
  cateringCreditOrderItem?: number;
  orderItemId?: number = null;
  flightRequestUrl: string = null;
  flightRequestId: number = null;
  legUrl: string;
  legId: number;
  orderStatusId: number = null;
  sfdcOpportunityId?: string;
  sfdcCompensationId?: string
  isCreditMemo? = false;
  creditMethod: number = null;
  creditReason: Array<CreditReason> = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.Concierge;
  }
}
