import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Marketplace.SpecialRequirement] Load';
export const LOAD_SUCCESS = '[Marketplace.SpecialRequirement] Load Success';
export const LOAD_FAIL = '[Marketplace.SpecialRequirement] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.SpecialRequirement>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
