import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'confirmation-dialog',
  styleUrls: ['confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
  @Input() confirmText = 'Confirm';
  @Input() cancelText = 'Cancel';
  @Input() text: string;
  @Input() title = 'Confirmation';

  @Output() confirm = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }
}
