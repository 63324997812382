import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import * as models from '../../domain/models';
import * as moment from 'moment';

export class PriceCalculatorMapper {
  static parsePrice(item: any): models.PriceCalculator {
    const p = new models.PriceCalculator();
    const segments = get(item, 'segments', null);
    const taxes = get(item, 'taxes', null);
    const tax = get(item, 'fetTax', null);
    const log = get(item, 'log', null);
    p.pax = get(item, 'pax', null);
    p.actype = get(item, 'actype', null);
    p.automation = get(item, 'automation', null);
    p.price = get(item, 'price', null);
    p.subtotal = get(item, 'subtotal', null);
    p.segmentFee = get(item, 'segmentFee', null);
    p.nonMemberFee = get(item, 'nonMemberFee', null);
    p.serviceFee = get(item, 'serviceFee', null);
    p.pricingSupportType = get(item, 'pricingSupportType', null);
    p.reject = get(item, 'reject', null);
    p.fuelCost = get(item, 'fuelCost', null);
    p.lastDayOfIATAFuel = get(item, 'lastDayOfIATAFuel', null);
    p.roundTripDiscount = get(item, 'roundTripDiscount', null);
    p.bestPrice = get(item, 'bestPrice', null);
    p.dnq = get(item, 'dnq', null);

    if (segments) {
      p.segments = segments.map((i) => this.parseSegment(i));
    }

    if (taxes) {
      p.taxes = taxes.map((i) => this.parseTax(i));
    }

    if (tax !== null) {
      p.fetTax = this.parseTaxFee(item);
    }

    if (log) {
      p.log = this.parseLog(log);
    }
    return p;
  }

  static parseTax(item): models.PriceTax {
    const t = new models.PriceTax();
    t.amount = get(item, 'amount', null);
    t.name = get(item, 'name', null);
    return t;
  }

  static parseTaxFee(item): models.TaxFee {
    const t = new models.TaxFee();
    t.amount = get(item, 'fetTax', null);
    t.unitPercentage = get(item, 'fetTaxPercent', null);
    return t;
  }

  static parseLog(item): models.CalculationLog {
    const t = new models.CalculationLog();
    const user = new models.User();
    const userData = get(item, 'user', null);
    const createdTime = get(item, 'createdTime', null);

    if (userData) {
      user.id = userData.id;
      user.firstName = userData.firstName;
      user.lastName = userData.lastName;
    }

    t.logId = get(item, 'logId', null);
    t.user = user;
    t.createdTime = createdTime ? moment(createdTime).toDate() : null;
    t.input = get(item, 'input', null);
    return t;
  }

  static parseSegment(item): models.PriceCalculatorSegment {
    const p = new models.PriceCalculatorSegment();
    const time = get(item, 'time', null);
    const taxFees = get(item, 'taxFees', []);
    const formattedTime = time ? moment(time.substring(0, 19)).format('MM/DD/YYYY LT') : null;
    p.adjustment = get(item, 'adjustment', null);
    p.id = formattedTime;
    p.firmFlexSurcharge = get(item, 'firmFlexSurcharge', null);
    p.depart = get(item, 'depart', null);
    p.arrive = get(item, 'arrive', null);
    p.time = formattedTime;
    p.actype = get(item, 'actype', null);
    p.pax = get(item, 'pax', null);
    p.eft = get(item, 'eft', null);
    p.opportunityPax = get(item, 'opportunityPax', null);
    p.opportunityPrice = get(item, 'opportunityPrice', null);
    p.price = get(item, 'price', null);
    p.ferryInfo = this.parseFerryInfo(get(item, 'ferryInfo', null));
    if (p.ferryInfo !== null) {
      p.ferryInfo.originRepoPrice = get(item, 'priceInfo.breakdowns.origin_repo_price', null);
      p.ferryInfo.destinationRepoPrice = get(
        item,
        'priceInfo.breakdowns.destination_repo_price',
        null
      );
    }
    p.priceInfo = this.parsePriceInfo(get(item, 'priceInfo', null), p.ferryInfo);
    p.suppressTaxFee = get(item, 'suppressTaxFee', false);

    if (taxFees && taxFees.length) {
      p.taxFees = taxFees.map((t) => this.parseTaxFee(t));
    }

    return p;
  }

  static parsePriceInfo(item: any, ferryInfo: models.FerryInfo): models.PriceCalculatorInfo {
    const p = new models.PriceCalculatorInfo();
    const surcharges = get(item, 'surcharges', []);

    p.rzosurcharge = get(item, 'rzosurcharge', null);
    p.regularHourlyRate = get(item, 'regularHourlyRate', null);
    p.base = get(item, 'base', null);
    p.fuelSurcharge = get(item, 'fuelSurcharge', null);
    p.noodleToggle = get(item, 'noodleToggle', null);
    p.departAP = get(item, 'departAP', null);
    p.arriveAP = get(item, 'arriveAP', null);
    p.departState = get(item, 'departState', null);
    p.arriveState = get(item, 'arriveState', null);
    p.challenger300 = get(item, 'challenger300', null);
    p.calendarMsrp = get(item, 'calendarMsrp', null);
    p.msrp = get(item, 'msrp', null);
    p.msrpValue = get(item, 'msrpValue', null);
    p.peakDayCharge = get(item, 'peakDayCharge', null);
    p.highDemandDayCharge = get(item, 'highDemandDayCharge', null);
    p.departureRZOSurcharge = get(item, 'departureRZOSurcharge', null);
    p.arrivalRZOSurcharge = get(item, 'arrivalRZOSurcharge', null);
    p.flightTime = get(item, 'flightTime', null);
    p.internationalSurcharge = get(item, 'internationalSurcharge', null);
    p.eventArrive = get(item, 'eventArrive', null);
    p.eventDepart = get(item, 'eventDepart', null);
    p.eventSurcharge = get(item, 'eventSurcharge', null);
    p.eventFee = get(item, 'eventFee', null);
    p.otherLocationFee = get(item, 'otherLocationFee', null);
    const breakdowns = get(item, 'breakdowns', null);

    if (
      ferryInfo?.originRegularIcao &&
      ferryInfo?.originRepoIcao &&
      ferryInfo?.originRepoPrice !== null
    ) {
      delete breakdowns['origin_repo_price'];
    }
    if (
      ferryInfo?.destinationRegularIcao &&
      ferryInfo?.destinationRepoIcao &&
      ferryInfo?.destinationRepoPrice !== null
    ) {
      delete breakdowns['destination_repo_price'];
    }
    p.breakdowns = breakdowns;

    p.surcharges = this.parseSurcharges(surcharges);
    return p;
  }

  static parseFerryInfo(items: any): models.FerryInfo {
    if (items === null) {
      return null;
    }

    const f = new models.FerryInfo();
    f.originEft = get(items, 'origin_eft', null);
    f.originHourlyRate = get(items, 'origin_hourly_rate', null);
    f.destinationHourlyRate = get(items, 'destination_hourly_rate', null);
    f.destinationBasePrice = get(items, 'destination_base_price', null);
    f.destinationDiscount = get(items, 'destination_discount', null);
    f.originBasePrice = get(items, 'origin_base_price', null);
    f.originRegularIcao = get(items, 'origin_regular_icao', null);
    f.originRepoIcao = get(items, 'origin_repo_icao', null);
    f.destinationEft = get(items, 'destination_eft', null);
    f.destinationRegularIcao = get(items, 'destination_regular_icao', null);
    f.destinationRepoIcao = get(items, 'destination_repo_icao', null);
    f.originDiscount = get(items, 'origin_discount', null);
    return f;
  }

  static parseSurcharges(items: Array<any> = []): Array<models.PricingInfoSurcharge> {
    return items.map((i) => {
      const p = new models.PricingInfoSurcharge();
      p.code = i.code;
      p.name = i.name;
      p.value = i.value;
      return p;
    });
  }

  static parsePricingMeta(item: any): models.PricingMeta {
    const p = new models.PricingMeta();
    p.memberships = map(get(item, 'memberships'), (field) => this.parseMembership(field));
    p.calculatorCategories = map(get(item, 'calculatorCategories'), (field) =>
      this.parseAircraftCategory(field)
    );
    p.comparisonCategories = map(get(item, 'comparisonCategories'), (field) =>
      this.parseAircraftCategory(field)
    );
    p.peakDays = reduce(
      keys(get(item, 'peakDays')),
      (accumulator, key) => {
        accumulator[key.toLowerCase()] = map(get(item, `peakDays.${key}`), (field) =>
          moment(field).toDate()
        );
        return accumulator;
      },
      {}
    );

    p.highDemandDays = reduce(
      keys(get(item, 'highDemandDays')),
      (accumulator, key) => {
        accumulator[key.toLowerCase()] = map(get(item, `highDemandDays.${key}`), (field) =>
          moment(field).toDate()
        );
        return accumulator;
      },
      {}
    );
    p.premiumFeatures = map(get(item, 'premiumFeatures'), (field) =>
      this.parseAdditionalFeature(field)
    );
    return p;
  }

  static parseAircraftCategory(item: any): models.AircraftCategory {
    const p = new models.AircraftCategory();
    p.id = item.id;
    p.actype = item.actype;
    p.name = item.name;
    p.nregName = item.nregName;
    p.elacName = item.elacName;
    return p;
  }

  static parseAdditionalFeature(item: any): models.AdditionalFeature {
    const f = new models.AdditionalFeature();
    f.id = get(item, 'id', null);
    f.name = get(item, 'name', null);
    f.notes = get(item, 'notes', null);
    return f;
  }

  static parseMembership(item: any): models.MembershipType {
    const f = new models.MembershipType();
    f.id = get(item, 'id', null);
    f.nreg = get(item, 'nreg', false);
    f.displayName = get(item, 'displayName', null);
    f.categories = get(item, 'categories', null);
    return f;
  }
}
