export const response = {
  client: {
    message: 'OK',
    code: 200,
    data: {
      phoneCallConsent: true,
      balance: '0',
      regSource: 'JS',
      name: 'JS Soph',
      dba: 'JS',
      referredByClient: { id: 3, name: 'JS Multi' },
      isSelectableAsBeneficiary: true,
      isSelectedAsBeneficiary: false,
      accountRoleId: 1,
      accountClients: [
        {
          id: 996,
          name: 'Account Client 1',
          accountRoleId: 0,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 997,
          name: 'Account Client 2',
          accountRoleId: 1,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 998,
          name: 'Account Client 3',
          accountRoleId: 2,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 999,
          name: 'Account Client 4',
          accountRoleId: 3,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1000,
          name: 'Account Client 5',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1001,
          name: 'Account Client 6',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1002,
          name: 'Account Client 7',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1003,
          name: 'Account Client 8',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1004,
          name: 'Account Client 9',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1005,
          name: 'Account Client 10',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1006,
          name: 'Account Client 11',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1007,
          name: 'Account Client 12',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1008,
          name: 'Account Client 13',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1009,
          name: 'Account Client 14',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 1010,
          name: 'Account Client 15',
          accountRoleId: 3,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
      ],
      verificationStatus: 'Not submitted',
      account: null,
      clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
      email: 'JS_Sophe.com',
      phone: '049',
      clientServices: [
        {
          prorateSuppressed: true,
          startDate: '2019-07-26T04:00:00+00:00',
          product: {
            description: 'To renew Sophisticated memberships from Console',
            previousProductId: null,
            basePrice: '0',
            productTypeDescription: 'To renew Sophisticated memberships from Console',
            nextProductId: null,
            productTypeName: 'Sophisticated Renewals From Console',
            productTypeId: 27,
            isRefundable: true,
            productTypeParentProductTypeId: 1,
            active: true,
            parentProductId: null,
            productName: 'Sophisticated Renewals From Console',
            createTime: '2018-02-28T22:00:57.317161+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(
              2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(
              9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), \'[]\')`,
            productId: 56,
          },
          totalPrice: '100000',
          endDate: '2022-07-26T04:00:00+00:00',
          orderItemId: null,
          typeId: 1,
          clientServiceStatusId: 2,
          id: 1063820,
          state: 12,
          isReactivatable: false,
          isLegacy: true,
          createTime: '2019-07-26T21:42:00.837925+00:00',
          isTerminatable: true,
          name: 'SOPHISTICATED',
        },
      ],
      brokerid: 0,
      dob: '1991-01-01',
      legalName: 'JS Soph',
      membershipRequests: [
        {
          orderId: 7827,
          orderItemId: 12993,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 114,
          managedClientServiceId: null,
          clientId: 2,
          productName: 'Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-07-16T21:28:08.411600+00:00',
          orderItemTotalPrice: '15488.4',
          requestedProductId: null,
          createTime: '2019-07-01T21:28:01.986090+00:00',
          productId: 114,
        },
        {
          orderId: 7830,
          orderItemId: 12996,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 114,
          managedClientServiceId: null,
          clientId: 2,
          productName: 'Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-07-16T21:51:44.709170+00:00',
          orderItemTotalPrice: '16488.4',
          requestedProductId: null,
          createTime: '2019-07-01T21:51:38.030834+00:00',
          productId: 114,
        },
        {
          orderId: 7831,
          orderItemId: 12997,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 147,
          managedClientServiceId: null,
          clientId: 2,
          productName: 'XO Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-07-16T21:56:17.795724+00:00',
          orderItemTotalPrice: '15488.4',
          requestedProductId: null,
          createTime: '2019-07-01T21:56:11.035951+00:00',
          productId: 147,
        },
        {
          orderId: 7833,
          orderItemId: 12999,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 114,
          managedClientServiceId: null,
          clientId: 2,
          productName: 'Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-07-17T18:28:51.162228+00:00',
          orderItemTotalPrice: '15488.4',
          requestedProductId: null,
          createTime: '2019-07-02T18:28:44.530821+00:00',
          productId: 114,
        },
        {
          orderId: 7834,
          orderItemId: 13000,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 147,
          managedClientServiceId: null,
          clientId: 2,
          productName: 'XO Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-07-17T20:43:15.504523+00:00',
          orderItemTotalPrice: '16488.4',
          requestedProductId: null,
          createTime: '2019-07-02T20:43:07.135568+00:00',
          productId: 147,
        },
      ],
      isFree: false,
      gdpr: null,
      id: 2,
      emailSupportConsent: true,
      flightCreditsAmount: '0',
    },
  },
  orders: {
    message: 'OK',
    code: 200,
    data: {
      totalItems: 8,
      items: [
        {
          canChangePaymentType: true,
          totalPrice: '16488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 360,
              invoiceTypeName: 'Main Invoice',
              uuid: 'cc63ba7f-0e7b-4c2f-8a4b-76b8dfd37830',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '2534f2c6-051e-4b7a-8dbc-66a62a688326',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 2,
              orderId: 7834,
              invoiceId: 10651,
              createTime: '2019-07-02T20:43:13.828990+00:00',
              parentInvoiceId: 10648,
              orderItemId: 13000,
              legacyFormUrl:
                'http://console.vista.studio/forms/xcharterjs_add/?2534f2c6-051e-4b7a-8dbc-66a62a688326',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '16488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7834,
              parentOrderItem: null,
              totalPrice: '16488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13000,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'XO Charter Flight',
                expirationTime: '2019-07-17T20:43:15.504523+00:00',
                expired: true,
                createTime: '2019-07-02T20:43:07.135568+00:00',
              },
              id: 13000,
              product: {
                productTypeId: 61,
                productId: 147,
                productName: 'XO Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712548',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712548,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '16488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7834,
          orderStatusId: 6,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-17T20:43:15.504523+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-02T20:43:06.515869+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: '0db15f36-d36e-4137-b56a-2d8828a05f41',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '6b032ab1-30c3-4b12-a8b1-8cb490d386b7',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7833,
              invoiceId: 10650,
              createTime: '2019-07-02T18:28:51.157024+00:00',
              parentInvoiceId: null,
              orderItemId: 12999,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs/?6b032ab1-30c3-4b12-a8b1-8cb490d386b7',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7833,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 12999,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-07-17T18:28:51.162228+00:00',
                expired: true,
                createTime: '2019-07-02T18:28:44.530821+00:00',
              },
              id: 12999,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712596',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712596,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7833,
          orderStatusId: 5,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-17T18:28:51.162228+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-02T18:28:43.846179+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '16488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 360,
              invoiceTypeName: 'Main Invoice',
              uuid: '57fc6c35-bc86-4910-ba75-b708d014da60',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'cdc4d361-cc58-4232-9550-1a73da28d229',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7832,
              invoiceId: 10649,
              createTime: '2019-07-01T22:09:56.918624+00:00',
              parentInvoiceId: 10648,
              orderItemId: 12998,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs_add/?cdc4d361-cc58-4232-9550-1a73da28d229',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '16488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7832,
              parentOrderItem: null,
              totalPrice: '16488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 12998,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-07-16T22:09:57.247081+00:00',
                expired: true,
                createTime: '2019-07-01T22:09:50.270964+00:00',
              },
              id: 12998,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712548',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712548,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '16488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7832,
          orderStatusId: 3,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-16T22:09:57.247081+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-01T22:09:49.693645+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: '52496471-29c8-426d-ad29-7668887486f3',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '5cac8670-0ff1-431b-ae93-66a6ff83004a',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7831,
              invoiceId: 10648,
              createTime: '2019-07-01T21:56:17.685346+00:00',
              parentInvoiceId: null,
              orderItemId: 12997,
              legacyFormUrl:
                'http://console.vista.studio/forms/xcharterjs/?5cac8670-0ff1-431b-ae93-66a6ff83004a',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7831,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 12997,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'XO Charter Flight',
                expirationTime: '2019-07-16T21:56:17.795724+00:00',
                expired: true,
                createTime: '2019-07-01T21:56:11.035951+00:00',
              },
              id: 12997,
              product: {
                productTypeId: 61,
                productId: 147,
                productName: 'XO Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712548',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712548,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7831,
          orderStatusId: 5,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-16T21:56:17.795724+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-01T21:56:10.431823+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: true,
          totalPrice: '16488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 360,
              invoiceTypeName: 'Main Invoice',
              uuid: '220bd4fd-8185-4782-a9d7-4869a7acfe08',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'd090e53e-4a0d-4f2a-85bc-5f270fb97926',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 2,
              orderId: 7830,
              invoiceId: 10647,
              createTime: '2019-07-01T21:51:44.831278+00:00',
              parentInvoiceId: 10644,
              orderItemId: 12996,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs_add/?d090e53e-4a0d-4f2a-85bc-5f270fb97926',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '16488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7830,
              parentOrderItem: null,
              totalPrice: '16488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 12996,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-07-16T21:51:44.709170+00:00',
                expired: true,
                createTime: '2019-07-01T21:51:38.030834+00:00',
              },
              id: 12996,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712541',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712541,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '16488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7830,
          orderStatusId: 6,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-16T21:51:44.709170+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-01T21:51:37.419611+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '100000',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 348,
              invoiceTypeName: 'Main Invoice',
              uuid: 'ac724ea5-3a20-4c93-a018-ed15eff5a1d4',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'acf8bf0d-0af4-477a-887f-21ccb9d79f16',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 2,
              orderId: 7829,
              invoiceId: 10646,
              createTime: '2019-07-01T21:32:18.424861+00:00',
              parentInvoiceId: null,
              orderItemId: 12995,
              legacyFormUrl:
                'http://console.vista.studio/forms/xmembership_nocc_terms/?acf8bf0d-0af4-477a-887f-21ccb9d79f16',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '100000',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7829,
              parentOrderItem: null,
              totalPrice: '100000',
              promotionAmount: null,
              orderItemTypeId: 1,
              notes: null,
              membershipRequest: {
                orderItemId: 12995,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Signature* Monthly (Program Deposit)',
                expirationTime: '2019-07-16T21:32:08.659524+00:00',
                expired: true,
                createTime: '2019-07-01T21:32:02.299558+00:00',
              },
              id: 12995,
              product: {
                productTypeId: 56,
                productId: 161,
                productName: 'Signature* Monthly (Program Deposit)',
              },
              childOrderItems: [],
              charterInfo: null,
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '100000',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7829,
          orderStatusId: 6,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: null,
          promotionId: null,
          expired: false,
          createTime: '2019-07-01T21:32:01.679649+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '100000',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 348,
              invoiceTypeName: 'Main Invoice',
              uuid: 'c04f5a6e-59cb-4f2a-84aa-1323aab31997',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'be38e6db-f71f-4585-a39a-2b43cb12322a',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 2,
              orderId: 7828,
              invoiceId: 10645,
              createTime: '2019-07-01T21:32:13.486912+00:00',
              parentInvoiceId: null,
              orderItemId: 12994,
              legacyFormUrl:
                'http://console.vista.studio/forms/xmembership_nocc_terms/?be38e6db-f71f-4585-a39a-2b43cb12322a',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '100000',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7828,
              parentOrderItem: null,
              totalPrice: '100000',
              promotionAmount: null,
              orderItemTypeId: 1,
              notes: null,
              membershipRequest: {
                orderItemId: 12994,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Signature* Monthly (Program Deposit)',
                expirationTime: '2019-07-16T21:32:08.657126+00:00',
                expired: true,
                createTime: '2019-07-01T21:32:02.293458+00:00',
              },
              id: 12994,
              product: {
                productTypeId: 56,
                productId: 161,
                productName: 'Signature* Monthly (Program Deposit)',
              },
              childOrderItems: [],
              charterInfo: null,
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '100000',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7828,
          orderStatusId: 6,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: null,
          promotionId: null,
          expired: false,
          createTime: '2019-07-01T21:32:01.664411+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: '3c50bdcc-6cbd-43de-838c-4346c9cf3148',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '0a23bbe0-fc80-4915-881f-ad4eb4443b71',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7827,
              invoiceId: 10644,
              createTime: '2019-07-01T21:28:08.781936+00:00',
              parentInvoiceId: null,
              orderItemId: 12993,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs/?0a23bbe0-fc80-4915-881f-ad4eb4443b71',
              clientId: 2,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7827,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 12993,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-07-16T21:28:08.411600+00:00',
                expired: true,
                createTime: '2019-07-01T21:28:01.986090+00:00',
              },
              id: 12993,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12712541',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12712541,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: '2019-07-26T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Soph',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '100000',
                membershipDisplayName: 'SOPHISTICATED',
                membershipTypeId: 15,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'JS_Sophe.com',
                brokerid: 0,
                membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Soph',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 2,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7827,
          orderStatusId: 5,
          partyId: 2,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: '2019-07-26T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Soph',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '100000',
              membershipDisplayName: 'SOPHISTICATED',
              membershipTypeId: 15,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'JS_Sophe.com',
              brokerid: 0,
              membershipTimeExpiring: '2022-07-26T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Soph',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 2,
            },
          },
          expirationTime: '2019-07-16T21:28:08.411600+00:00',
          promotionId: null,
          expired: true,
          createTime: '2019-07-01T21:28:01.322862+00:00',
          orderSourceId: 1,
        },
      ],
    },
  },
};
