import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import moment from 'moment';

import * as contracts from '../../../domain/service-contracts/price-calculator';
import * as mappers from '../mapper';
import * as coreHelpers from '../../../../../core/helpers';
import * as coreTypes from '../../../../../core/types';
import * as models from '../../../domain/models';

@Injectable()
export class PriceCalculatorService implements contracts.IPriceCalculator {
  private get API_PATH(): string {
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      return this.configService.get('CharterPricingServiceEndpoint');
    } else {
      return this.configService.get('CharterPricingServiceEndpointV1');
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getPrice({
    offer,
  }: contracts.GetPriceCalculatorRequest): Observable<contracts.GetPriceCalculatorResponse> {
    let requestUrl: string;
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      requestUrl = `${this.API_PATH}/pricing-calculator/calculate`;
    } else {
      requestUrl = `${this.API_PATH}/pricing-calculator/lcalculate`;
    }

    let headers = new HttpHeaders();
    const updatedOffer = {
      ...offer,
      legs: offer.legs.map(
        ({
          origin,
          destination,
          aircraftCategory,
          depDateTime,
          sortOrder,
          opportunityPax,
          opportunityPrice,
          price,
          pax,
          suppressTaxFee,
          ferry,
          peakDays,
        }) => {
          const leg: models.PricingCalculatorOfferLeg = {
            origin,
            destination,
            aircraftCategory,
            depDateTime,
            sortOrder,
            opportunityPax,
            opportunityPrice,
            price,
            pax,
            suppressTaxFee,
          };
          if (offer.membershipType !== models.MembershipTypesEnum.ELAC) {
            leg.ferry = ferry;
          } else {
            leg.peakDays = peakDays;
          }
          return leg;
        }
      ),
    };
    updatedOffer.automationIgnore = true;
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(requestUrl, JSON.stringify(updatedOffer), { headers }).pipe(
      map((svcJsonResp: any) => {
        const r = new contracts.GetPriceCalculatorResponse();
        r.log = mappers.PriceCalculatorMapper.parseLog(svcJsonResp.log);
        r.items = svcJsonResp.data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));

        return r;
      })
    );
  }

  updatePrice({
    offer,
  }: contracts.UpdatePriceCalculatorRequest): Observable<contracts.GetPriceCalculatorResponse> {
    let requestUrl: string;
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      requestUrl = `${this.API_PATH}/pricing-calculator/calculate/opportunity`;
    } else {
      requestUrl = `${this.API_PATH}/pricing-calculator/calculate/lopportunity`;
    }

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(requestUrl, JSON.stringify(offer), { headers }).pipe(
      map((svcJsonResp: any) => {
        const r = new contracts.GetPriceCalculatorResponse();
        r.log = mappers.PriceCalculatorMapper.parseLog(svcJsonResp.log);
        r.items = svcJsonResp.data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));

        return r;
      })
    );
  }

  getCalculation(
    request: contracts.GetCalculationRequest
  ): Observable<contracts.GetPriceCalculatorResponse> {
    const requestUrl = `${this.API_PATH}/pricing-calculator/quote/${request.logId}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const r = new contracts.GetPriceCalculatorResponse();
        r.log = mappers.PriceCalculatorMapper.parseLog(svcJsonResp.log);
        r.items = svcJsonResp.data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));

        return r;
      })
    );
  }

  getPeakDays(): Observable<contracts.GetPeakDaysResponse> {
    const requestUrl = `${this.API_PATH}/pricing-calculator/peakdays`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const r = new contracts.GetPeakDaysResponse();
        r.items = new models.PeakDays();
        for (const peakDayArr in svcJsonResp.peakDays) {
          r.items[peakDayArr.toLowerCase()] = svcJsonResp.peakDays[peakDayArr].map((item) =>
            moment(item).toDate()
          );
        }

        return r;
      })
    );
  }

  getPricingMeta(): Observable<contracts.GetPricingMetaResponse> {
    const requestUrl = `${this.API_PATH}/meta`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => ({
        item: mappers.PriceCalculatorMapper.parsePricingMeta(svcJsonResp),
      }))
    );
  }
}
