import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[YM Seat-Pricing-Collection] Load';
export const LOAD_SUCCESS = '[YM Seat-Pricing-Collection] Load Success';
export const LOAD_FAIL = '[YM Seat-Pricing-Collection] Load Fail';
export const LOAD_RULE = '[YM Seat-Pricing-Collection] Load Rule';
export const LOAD_RULE_SUCCESS = '[YM Seat-Pricing-Collection] Load Rule Success';
export const LOAD_RULE_FAIL = '[YM Seat-Pricing-Collection] Load Rule Fail';
export const LOAD_META_DATA = '[YM Seat-Pricing-Collection] Load Meta Data';
export const LOAD_META_DATA_SUCCESS = '[YM Seat-Pricing-Collection] Load Meta Data Success';
export const LOAD_META_DATA_FAIL = '[YM Seat-Pricing-Collection] Load Meta Data Fail';
export const LOAD_MATRIX = '[YM Seat-Pricing-Collection] Load Matrix';
export const LOAD_MATRIX_SUCCESS = '[YM Seat-Pricing-Collection] Load Matrix Success';
export const LOAD_MATRIX_FAIL = '[YM Seat-Pricing-Collection] Load Matrix Fail';
export const SET_MATRIX = '[YM Seat-Pricing-Collection] Set Matrix';
export const SET = '[YM Seat-Pricing-Collection] Set';
export const ADD = '[YM Seat-Pricing-Collection] Add';
export const REMOVE = '[YM Seat-Pricing-Collection] Remove';
export const REMOVE_SUCCESS = '[YM Seat-Pricing-Collection] Remove Success';
export const REMOVE_FAIL = '[YM Seat-Pricing-Collection] Remove Fail';
export const SET_LOOKUP_CRITERIA = '[YM Seat-Pricing-Collection] Set Lookup Criteria';
export const SAVE = '[YM Seat-Pricing-Collection] Save';
export const SAVE_SUCCESS = '[YM Seat-Pricing-Collection] Save Success';
export const SAVE_FAIL = '[YM Seat-Pricing-Collection] Save Fail';
export const CREATE = '[YM Seat-Pricing-Collection] Create';
export const CREATE_SUCCESS = '[YM Seat-Pricing-Collection] Create Success';
export const CREATE_FAIL = '[YM Seat-Pricing-Collection] Create Fail';

export class LoadRuleCollectionAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessRuleCollectionPayload {
  entities: Array<models.RuleData>;
}

export class LoadSuccessRuleCollectionAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessRuleCollectionPayload) {}
}

export class LoadFailRuleAction implements Action {
  readonly type = LOAD_FAIL;
}

export class LoadRuleCollectionMetaDataAction implements Action {
  readonly type = LOAD_META_DATA;
}

export class LoadSuccessRuleCollectionMetaDataPayload {
  entity: models.MetaData;
}

export class LoadSuccessRuleCollectionMetaDataAction implements Action {
  readonly type = LOAD_META_DATA_SUCCESS;

  constructor(public payload: LoadSuccessRuleCollectionMetaDataPayload) {}
}

export class LoadFailRuleMetaDataAction implements Action {
  readonly type = LOAD_META_DATA_FAIL;
}

export class LoadRulePayload {
  id: string;
}

export class LoadRuleAction implements Action {
  readonly type = LOAD_RULE;

  constructor(public payload: LoadRulePayload) {
  }
}

export class LoadRuleSuccessPayload {
  rule: models.RuleData;
}

export class LoadRuleSuccessAction implements Action {
  readonly type = LOAD_RULE_SUCCESS;

  constructor(public payload: LoadRuleSuccessPayload) {
  }
}

export class LoadRuleFailAction implements Action {
  readonly type = LOAD_RULE_FAIL;
}

// Matrix

export class LoadRuleCollectionMatrixPayload {
  rule: models.RuleData;
}

export class LoadRuleCollectionMatrixAction implements Action {
  readonly type = LOAD_MATRIX;

  constructor(public payload: LoadRuleCollectionMatrixPayload) {}
}

export class LoadRuleCollectionMatrixSuccessPayload {
  entity: models.MatrixData;
}

export class LoadRuleCollectionMatrixSuccessAction implements Action {
  readonly type = LOAD_MATRIX_SUCCESS;

  constructor(public payload: LoadRuleCollectionMatrixSuccessPayload) {}
}

export class LoadRuleMatrixFailAction implements Action {
  readonly type = LOAD_MATRIX_FAIL;
}

export class SetRuleCollectionMatrixPayload {
  matrix: models.MatrixData;
}

export class SetRuleCollectionMatrixAction implements Action {
  readonly type = SET_MATRIX;

  constructor(public payload: SetRuleCollectionMatrixPayload) {
  }
}

// End: Matrix

export class SetRulePayload {
  entity: viewModels.SeatPricingRuleWrapper;
}

export class SetRuleAction implements Action {
  readonly type = SET;

  constructor(public payload: SetRulePayload) {}
}

export class SaveRulePayload {
  rule: models.RuleData;
}

export class SaveRuleAction implements Action {
  readonly type = SAVE;

  constructor(public payload: SaveRulePayload) {
  }
}

export class SaveSuccessRulePayload {
  entity: models.RuleData;
}

export class SaveSuccessRuleAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessRulePayload) {}
}

export class SaveFailRuleAction implements Action {
  readonly type = SAVE_FAIL;
}

export class CreateRulePayload {
  rule: models.RuleData;
}

export class CreateRuleAction implements Action {
  readonly type = CREATE;

  constructor(public payload: CreateRulePayload) {
  }
}

export class CreateSuccessRulePayload {
  entity: models.RuleData;
}

export class CreateSuccessRuleAction implements Action {
  readonly type = CREATE_SUCCESS;

  constructor(public payload: CreateSuccessRulePayload) {
  }
}

export class CreateFailRuleAction implements Action {
  readonly type = CREATE_FAIL;
}

export class SetLookupCriteriaPayload {
  entity: viewModels.RouteLookup;
}

export class SetLookupCriteriaAction implements Action {
  readonly type = SET_LOOKUP_CRITERIA;

  constructor(public payload: SetLookupCriteriaPayload) {}
}

export class AddRuleAction implements Action {
  readonly type = ADD;
}

export class RemoveRulePayload {
  id: string;
}

export class RemoveRuleAction implements Action {
  readonly type = REMOVE;

  constructor(public payload: RemoveRulePayload) {}
}

export class RemoveRuleSuccessPayload {
  id: string;
}

export class RemoveRuleSuccessAction implements Action {
  readonly type = REMOVE_SUCCESS;

  constructor(public payload: RemoveRuleSuccessPayload) {
  }
}

export class RemoveRuleFailAction implements Action {
  readonly type = REMOVE_FAIL;
}

export type Actions = LoadRuleCollectionAction
  | LoadSuccessRuleCollectionAction
  | LoadFailRuleAction
  | LoadRuleAction
  | LoadRuleSuccessAction
  | LoadRuleFailAction
  | LoadRuleCollectionMetaDataAction
  | LoadSuccessRuleCollectionMetaDataAction
  | LoadFailRuleMetaDataAction
  | LoadRuleCollectionMatrixAction
  | LoadRuleCollectionMatrixSuccessAction
  | LoadRuleMatrixFailAction
  | SetRuleCollectionMatrixAction
  | SetRuleAction
  | SaveRuleAction
  | SaveSuccessRuleAction
  | SaveFailRuleAction
  | CreateRuleAction
  | CreateSuccessRuleAction
  | CreateFailRuleAction
  | SetLookupCriteriaAction
  | AddRuleAction
  | RemoveRuleAction
  | RemoveRuleSuccessAction
  | RemoveRuleFailAction;
