import { LegCostType } from './leg-cost-type';
import { Leg } from '../../domain/models/heat-map/leg';
import { LegSource } from '../../domain/models/heat-map/leg-source';

export class LegKpiWidget {
  legId: number;
  leg: Leg;
  tail: string;
  operatorName: string;
  recentlyAdded: boolean;
  departureAirportCode: string;
  departureAirportCity: string;
  departureAirportGroupName: string;
  arrivalAirportCode: string;
  arrivalAirportCity: string;
  arrivalAirportGroupName: string;
  departureDateTime: Date;
  departureDateTimeUtc: Date;
  releaseDate: Date;
  creatorCash: number;
  creatorFc: number;
  finderCash: number;
  finderFc: number;
  totalCash: number;
  totalFc: number;
  totalRevenue: number;
  impressions: number;
  pnl: number;
  legSource: LegSource;
  confirmed: boolean;
  crowdfundedConfirmedTime: Date;

  // estimatedCost: number;
  cost: number;
  costType: LegCostType;
  totalProfit: number;
  loadFactor: number;
  grossProfit: number;
  cashGrossProfit: number;
  emptySeats: number;
  marketableSeats: number;
  occupiedSeats: number;
  kpiFactor: number;
  estimatedCancellationFees: number;
  priceOverrideDelta: number;
  priceOverrideReasonId: number;

  legConsoleUri: string;
  legPricingUri: string;
  legRecoveryUrl: string;
}
