import { FinalizedLegsResponse } from '../../../../domain/service-contracts/finance';
import { FinalizedLegMapper } from './finalized-leg-mapper';

export class FinalizedLegsResponseMapper {
  static fromJson(json: any): FinalizedLegsResponse | null {
    if (!json) {
      return null;
    }

    const finalizedLegsResponse = new FinalizedLegsResponse();
    finalizedLegsResponse.hasFinalizedLegs = json.hasFinalizedLegs;
    finalizedLegsResponse.finalizedLegs = (json.finalizedLegs as Array<any>).map(
      (finalizedLegJson) => FinalizedLegMapper.fromJson(finalizedLegJson)
    );

    return finalizedLegsResponse;
  }
}
