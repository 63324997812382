import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Aircraft Collection] Load';
export const LOAD_SUCCESS = '[RM Aircraft Collection] Load Success';
export const LOAD_FAIL = '[RM Aircraft Collection] Load Fail';
export const SET_SELECTED_AIRCRAFT = '[RM Aircraft Collection] Set Selected Aircraft';
export const ADD_AIRCRAFT = '[RM Aircraft Collection] Add Aircraft';
export const ADD_AIRCRAFT_SUCCESS = '[RM Aircraft Collection] Add Aircraft Success';
export const ADD_AIRCRAFT_FAIL = '[RM Aircraft Collection] Add Aircraft Fail';
export const BULK_SAVE_AIRCRAFTS = '[RM Aircraft Collection] Bulk Edit Aircraft';
export const BULK_SAVE_AIRCRAFTS_SUCCESS = '[RM Aircraft Collection] Bulk Edit Aircraft Success';
export const BULK_SAVE_AIRCRAFTS_FAIL = '[RM Aircraft Collection] Bulk Edit Aircraft Fail';
export const GET_IMPORT_STATUS = '[RM Aircraft Collection] Get Aircraft Import Status';
export const GET_IMPORT_STATUS_SUCCESS = '[RM Aircraft Collection] Get Aircraft Import Status Success';
export const GET_IMPORT_STATUS_FAIL = '[RM Aircraft Collection] Get Aircraft Import Status Fail';
export const RESET_IMPORT_STATUS_STATE = '[RM Aircraft Collection] Reset Aircraft Import Status State';
export const RESET = '[RM Aircraft Collection] Reset';

export class LoadPayload {
  constructor(public lookup: viewModels.AircraftLookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<models.Aircraft>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetSelectedAircraftAction implements Action {
  readonly type = SET_SELECTED_AIRCRAFT;

  constructor(public payload: Array<models.Aircraft>) {}
}

export class AddAircraftAction implements Action {
  readonly type = ADD_AIRCRAFT;

  constructor(public payload: AddAircraftPayload) {}
}

export class AddAircraftPayload {
  constructor(public item: models.Aircraft) {}
}

export class AddAircraftSuccessAction implements Action {
  readonly type = ADD_AIRCRAFT_SUCCESS;

  constructor(public payload: AddAircraftSuccessPayload) {}
}

export class AddAircraftSuccessPayload {
  constructor(public item: models.Aircraft) {}
}

export class AddAircraftFailAction implements Action {
  readonly type = ADD_AIRCRAFT_FAIL;
}

export class BulkSaveAircraftAction implements Action {
  readonly type = BULK_SAVE_AIRCRAFTS;

  constructor(public payload: BulkSaveAircraftPayload) {}
}

export class BulkSaveAircraftPayload {
  bulkEditData: models.BulkEditData;
}

export class BulkSaveAircraftSuccessAction implements Action {
  readonly type = BULK_SAVE_AIRCRAFTS_SUCCESS;

  constructor(public payload: number) {
  }
}

export class BulkSaveAircraftFailAction implements Action {
  readonly type = BULK_SAVE_AIRCRAFTS_FAIL;
}

export class GetImportStatusAction implements Action {
  readonly type = GET_IMPORT_STATUS;
}

export class GetImportStatusSuccessAction implements Action {
  readonly type = GET_IMPORT_STATUS_SUCCESS;

  constructor(public payload: boolean) {
  }
}

export class ResetImportStatusStateAction implements Action {
  readonly type = RESET_IMPORT_STATUS_STATE;
}

export class GetImportStatusFailAction implements Action {
  readonly type = GET_IMPORT_STATUS_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetSelectedAircraftAction
  | AddAircraftAction
  | AddAircraftSuccessAction
  | AddAircraftFailAction
  | BulkSaveAircraftAction
  | BulkSaveAircraftSuccessAction
  | BulkSaveAircraftFailAction
  | GetImportStatusAction
  | GetImportStatusSuccessAction
  | GetImportStatusFailAction
  | ResetImportStatusStateAction
  | ResetAction;
