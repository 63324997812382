import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as coreTypes from '../../../../core/types';
import * as contracts from '../../domain/service-contracts/sales-document';
import * as mappers from './mapper';
import * as _ from 'lodash';

@Injectable()
export class SalesDocumentService implements contracts.ISalesDocumentService {
  private get API_PATH(): string {
    return this.configService.get('InvoiceServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  uploadDocument(
    payload: contracts.SalesDocumentUploadRequestPayload
  ): Observable<contracts.SalesDocumentUploadResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/${payload.invoiceId}/upload-document`;

    const formData = new FormData();

    formData.append('documentName', `${payload.documentName}`);
    formData.append('documentType', `${payload.documentType}`);
    formData.append(`documentData`, payload.documentData, `${payload.documentName}`);

    return this.http.post(requestUrl, formData).pipe(
      map((response: any) => {
        return Object.assign(new contracts.SalesDocumentUploadResponse(), response.data);
      })
    );
  }

  uploadAndBook(
    payload: contracts.SalesDocumentUploadAndBookRequestPayload
  ): Observable<contracts.SalesDocumentUploadAndBookResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/api/od/rm/order/pay`;

    const formData = new FormData();

    formData.append('orderId', `${payload.orderId}`);
    formData.append('documentName', `${payload.documentName}`);
    formData.append('documentType', `${payload.documentType}`);
    formData.append(`documentData`, payload.documentData, `${payload.documentName}`);

    return this.http.put(requestUrl, formData).pipe(
      map((svcJsonResp: any) => {
        const responseData = svcJsonResp.data ? svcJsonResp.data : svcJsonResp;
        const response = new contracts.SalesDocumentUploadAndBookResponse();
        response.orderId = _.get(responseData, 'orderId', null);
        response.orderStatusId = _.get(responseData, 'orderStatusId', null);
        response.orderStatusName = _.get(responseData, 'orderStatusName', null);
        response.documents = _.get(responseData, 'documents', []).map((e) =>
          mappers.Mapper.parseDocument(e)
        );
        return response;
      })
    );
  }
}
