import * as actions from '../actions/membership-status';
import * as models from '../../domain/models';

export interface State {
  loaded: boolean;
  loading: boolean;
  membershipStatus: models.MembershipStatus;
}

const initialState: State = {
  loaded: false,
  loading: false,
  membershipStatus: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return { ...state, loading: true };
    }

    case actions.LOAD_SUCCESS: {
      return {
        loaded: true,
        loading: false,
        membershipStatus: action.payload,
      };
    }
    case actions.LOAD_FAIL: {
      return {
        loaded: true,
        loading: false,
        membershipStatus: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getMembershipStatus = (state: State) => state.membershipStatus;
