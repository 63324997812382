import { CurrencyEnum } from './currency-enum';

export class Constants {
  static techStopLabel = 'Fuel Stop';
}

export const currencyList =
  [
    {
      currency: CurrencyEnum.USD,
      country: 'us',
    },
    {
      currency: CurrencyEnum.EUR,
      country: 'eu',
    },
    {
      currency: CurrencyEnum.GBP,
      country: 'gb',
    },
  ];
