import { InjectionToken } from '@angular/core';
import { LegCost } from '../models/leg-cost';
import { Observable } from 'rxjs';
import { LegCostsDto } from '../../services/leg-costs/leg-costs/dto/leg-costs-dto';
import { FinalizedLegIdDto } from '../../services/leg-costs/leg-costs/dto/finalized-leg-id-dto';

export interface ILegCostsService {
  getLegCostsByFlightRequestId(flightRequestId: number): Observable<LegCostsListResponse>;

  updateLegCost(legCost: LegCost): Observable<LegCostsUpdateResponse>;

  getLegIdListByFlightRequestId(
    flightRequestId: number
  ): Observable<LegCostsFinalizedLegIdsResponse>;
}

export const LEG_COSTS_SERVICE_TOKEN = new InjectionToken('LegCosts.ILegCostsService');

export class LegCostsListResponse {
  code: number;
  message: string;
  legCosts: Array<LegCost>;
}

export class LegCostsUpdateResponse {
  code: number;
  message: string;
  data: string;
}

export class GetLegCostsByFlightRequestIdResponse {
  code: number;
  message: string;
  data: Array<LegCostsDto>;
}

export class LegCostsFinalizedLegIdsResponse {
  code: number;
  message: string;
  finalizedLegIds: Array<string>;
}

export class GetFinalizedLegIdsFromFlightRequestIdResponse {
  code: number;
  message: string;
  data: Array<FinalizedLegIdDto>;
}
