import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/aircraft-categories-collection.actions';
import * as models from '../../domain/models';

export const aircraftCategoriesCollectionFeatureKey = 'aircraftCategoriesCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items?: Array<models.AircraftCategory>;
  calcItems?: Array<models.AircraftCategory>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  calcItems: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    actions.loadForCalc,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      items: payload,
    })
  ),

  on(
    actions.loadForCalcSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      calcItems: payload,
    })
  ),

  on(actions.setInitial, (state): State => initialState)
);
