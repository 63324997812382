import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/opportunity-list.actions';
import * as models from '../../domain/models';
import * as queries from '../../domain/queries';
import * as _ from 'lodash';

export const opportunityListFeatureKey = 'opportunityList';

export interface State {
  query: queries.OpportunityCriteria;
  currentPage: number;
  loaded: boolean;
  loading: boolean;
  items: Array<models.Opportunity>;
  itemsPerPage: number;
  itemsTotalCount: number;
}

const initialState: State = {
  query: new queries.OpportunityCriteria(),
  currentPage: 1,
  loaded: false,
  loading: false,
  items: [],
  itemsPerPage: 15,
  itemsTotalCount: 0,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.search,
    (state, { payload }): State => ({
      ...state,
      query: _.cloneDeep(payload.criteria),
      loading: true,
    })
  ),

  on(
    actions.searchSuccess,
    (state, { payload }): State => ({
      ...state,
      currentPage: payload.page,
      loaded: true,
      loading: false,
      items: payload.data,
      itemsTotalCount: payload.count,
    })
  ),

  on(
    actions.reset,
    (): State => ({
      ...initialState,
    })
  )
);
