import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/user-management-data-collection.actions';
import * as models from '../../domain/models';

export const userManagementDataCollectionFeatureKey = 'userManagementDataCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  userManagementData: models.UserManagementData;
}

const initialState: State = {
  loaded: false,
  loading: false,
  userManagementData: null,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      userManagementData: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  )
);
