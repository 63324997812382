import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Area Collection] Load';
export const LOAD_SUCCESS = '[RM Area Collection] Load Success';
export const LOAD_FAIL = '[RM Area Collection] Load Fail';
export const ADD_AREA = '[RM Area Collection] Add Area';
export const ADD_AREA_SUCCESS = '[RM Area Collection] Add Area Success';
export const ADD_AREA_FAIL = '[RM Area Collection] Add Area Fail';
// export const SET_AREA = '[RM Area Collection] Set Area';
// export const SAVE = '[RM Area Collection] Save';
// export const SAVE_SUCCESS = '[RM Area Collection] Save Success';
// export const SAVE_FAIL = '[RM Area Collection] Save Fail';

export class LoadPayload {
  constructor(public lookup: viewModels.Lookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<viewModels.ListItem>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddAreaAction implements Action {
  readonly type = ADD_AREA;

  constructor(public payload: AddAreaPayload) {}
}

export class AddAreaPayload {
  constructor(public name: string) {}
}

export class AddAreaSuccessAction implements Action {
  readonly type = ADD_AREA_SUCCESS;

  constructor(public payload: AddAreaSuccessPayload) {}
}

export class AddAreaSuccessPayload {
  constructor(public item: models.Area) {}
}

export class AddAreaFailAction implements Action {
  readonly type = ADD_AREA_FAIL;
}

// export class SetAreaAction implements Action {
//   readonly type = SET_AREA;

//   constructor(public payload: SetAreaPayload) {
//   }
// }

// export class SetAreaPayload {
//   constructor(public item: models.Area) {
//   }
// }

// export class SaveAction implements Action {
//   readonly type = SAVE;
// }

// export class SaveSuccessPayload {
//   constructor(public item: models.Area) {
//   }
// }

// export class SaveSuccessAction implements Action {
//   readonly type = SAVE_SUCCESS;

//   constructor(public payload: SaveSuccessPayload) {
//   }
// }

// export class SaveFailAction implements Action {
//   readonly type = SAVE_FAIL;
// }

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddAreaAction
  | AddAreaSuccessAction
  | AddAreaFailAction;
// | SetAreaAction
// | SaveAction
// | SaveSuccessAction
// | SaveFailAction;
