import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction('[TaskMgmt.DenyReasonCollection] Load');
export const loadSuccess = createAction(
  '[TaskMgmt.DenyReasonCollection] Load Success',
  props<{ payload: Array<models.DenyReason> }>()
);
export const loadFail = createAction('[TaskMgmt.DenyReasonCollection] Load Fail');
export const setInitial = createAction('[TaskMgmt.DenyReasonCollection] Set Initial');
