import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Yield Vendor Collection] Load';

export class LoadPayload {
  constructor(public items: Array<models.Vendor>) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export type Actions = LoadAction;
