import { ClientService } from './client-service';
import { ClientServiceTypeEnum } from './client-service-type';

export class LegacyMembershipClientService extends ClientService {
  membershipPeriodId: number = null;

  get clientServiceType(): ClientServiceTypeEnum {
    return ClientServiceTypeEnum.LegacyMembership;
  }
}
