import { SecurityAccessEnum } from './security-access-enum';
import { PriceComponentTypeEnum } from './price-component-type-enum';
export class PriceComponent {
  id: number = null;
  typeId: PriceComponentTypeEnum = null;
  name: string = null;
  description: string = null;
  amount: number | null = null;
  percentage: number | null = null;
  typeName: string = null;
  securityAccess: SecurityAccessEnum = SecurityAccessEnum.Denied;
}
