export const TASK_CATEGORIES = [
{
  taskCategoryId: 4,
  displayName: 'Prelim',
  sortOrder: 4
},
{
  taskCategoryId: 1,
  displayName: 'Flight briefing',
  sortOrder: 1
},
{
  taskCategoryId: 2,
  displayName: 'Pre check',
  sortOrder: 2
},
{
  taskCategoryId: 3,
  displayName: 'DOTM',
  sortOrder: 3
},
{
  taskCategoryId: 5,
  displayName: 'IROPS',
  sortOrder: 5
}
];
