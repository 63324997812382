import { createAction, props } from '@ngrx/store';

import * as models from '../.././domain/models';
import * as coreModels from '../.././../../core/models';

export class SetPricingCalculatorOpportunityPayload {
  pricingCalculatorOpportunity: coreModels.PricingCalculatorOpportunity;
}
export const setPricingCalculatorOpportunity = createAction(
  '[Charter.OpportunityNewPc] Set Pricing Calculator Opportunity',
  props<{ payload: SetPricingCalculatorOpportunityPayload }>()
);

export class GetSameAndSimilarPayload {
  aircraftModelId: number;
  legs: Array<models.PricingCalculatorLeg>;
}
export const getSameAndSimilar = createAction(
  '[Charter.OpportunityNewPc] Get Same And Similar',
  props<{ payload: GetSameAndSimilarPayload }>()
);
export class GetSameAndSimilarSuccessPayload {
  items: Array<models.SameAndSimilarData>;
}
export const getSameAndSimilarSuccess = createAction(
  '[Charter.OpportunityNewPc] Get Same And Similar Success',
  props<{ payload: GetSameAndSimilarSuccessPayload }>()
);
export const getSameAndSimilarFail = createAction(
  '[Charter.OpportunityNewPc] Get Same And Similar Fail'
);

export class CreateOpportunityRMJSPayload extends models.OpportunityRmjsDto {}
export const createOpportunityRMJS = createAction(
  '[Charter.OpportunityNewPc] Create Opportunity RMJS',
  props<{ payload: CreateOpportunityRMJSPayload }>()
);
export const createOpportunityRMJSSuccess = createAction(
  '[Charter.OpportunityNewPc] Create Opportunity RMJS Success'
);
export const createOpportunityRMJSFail = createAction(
  '[Charter.OpportunityNewPc] Create Opportunity RMJS Fail'
);

export const setInitialState = createAction('[Charter.OpportunityNewPc] Set Initial State');
