import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Sourcing.MembershipStatus] Load';
export const LOAD_SUCCESS = '[Sourcing.MembershipStatus] Load Success';
export const LOAD_FAIL = '[Sourcing.MembershipStatus] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: number) {
  }
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: models.MembershipStatus) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
