import { createAction, props } from '@ngrx/store';

import * as models from '../models';

export const empty = createAction('[Core.User] Empty');

export const load = createAction('[Core.User] Load');
export const loadSuccess = createAction(
  '[Core.User] Load Success',
  props<{ payload: models.User }>()
);
export const loadFail = createAction('[Core.User] Load Fail');

export const signOut = createAction('[Core.User] Sign Out');

export const accessDenied = createAction(
  '[Core.User] Access Denied',
  props<{ payload: boolean }>()
);

export const setMenuPinPreference = createAction(
  '[Core.User] Set Menu Pin Preference',
  props<{ payload: number }>()
);
