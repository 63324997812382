import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const FLIGHT_PREVIEW_LOAD = '[Marketplace.FlightPreview] Load';
export const FLIGHT_PREVIEW_LOAD_SUCCESS = '[Marketplace.FlightPreview] Load Success';
export const FLIGHT_PREVIEW_LOAD_FAIL = '[Marketplace.FlightPreview] Load Fail';
export const FLIGHT_PREVIEW_RESET = '[Marketplace.FlightPreview] Reset';

export class FlightPreviewLoadPayload {
  constructor(
    public aircraftCategories: Array<models.AircraftCategory>,
    public legs: Array<models.LegRequest>,
    public elacProForma: boolean) {
  }
}

export class FlightPreviewLoadAction implements Action {
  readonly type = FLIGHT_PREVIEW_LOAD;

  constructor(public payload: FlightPreviewLoadPayload) {
  }
}

export class FlightPreviewLoadSuccessAction implements Action {
  readonly type = FLIGHT_PREVIEW_LOAD_SUCCESS;

  constructor(public payload: Array<any>) {
  }
}

export class FlightPreviewLoadFailAction implements Action {
  readonly type = FLIGHT_PREVIEW_LOAD_FAIL;
}

export class FlightPreviewResetAction implements Action {
  readonly type = FLIGHT_PREVIEW_RESET;
}

export type Actions = FlightPreviewLoadAction
  | FlightPreviewLoadSuccessAction
  | FlightPreviewLoadFailAction
  | FlightPreviewResetAction;
