import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction(
  '[UserMgmt.GroupsCollection] Load',
);
export const loadSuccess = createAction(
  '[UserMgmt.GroupsCollection] Load Success',
  props<{ payload: Array<models.Group> }>()
);
export const loadFail = createAction('[UserMgmt.GroupsCollection] Load Fail');
