import { createAction, props } from '@ngrx/store';

import * as models from '../models';

export const openSidenav = createAction('[Shared.Layout] Open Sidenav');

export const closeSidenav = createAction('[Shared.Layout] Close Sidenav');

export const activateAppView = createAction('[Shared.Layout] Activate AppView');
export const deactivateAppView = createAction('[Shared.Layout] Deactivate AppView');

export const activateFullHeightView = createAction('[Shared.Layout] Activate Full Height View');
export const deactivateFullHeightView = createAction('[Shared.Layout] Deactivate Full Height View');

export const showError = createAction('[Shared.Layout] Show Error', props<{ payload: string }>());
export const showErrorPopup = createAction(
  '[Shared.Layout] Show Error Popup',
  props<{ payload?: string }>()
);

export const showSpinner = createAction('[Shared.Layout] Show Spinner');
export const hideSpinner = createAction('[Shared.Layout] Hide Spinner');

export const showNamedOutletSpinner = createAction('[Shared.Layout] Show Named Outlet Spinner');
export const hideNamedOutletSpinner = createAction('[Shared.Layout] Hide Named Outlet Spinner');

export const setLayout = createAction(
  '[Shared.Layout] Set Layout',
  props<{ payload: models.LayoutType }>()
);

export const setMenuPin = createAction(
  '[Shared.Layout] Set Menu Pin',
  props<{ payload: models.MenuPin }>()
);

export const setServerEnvironment = createAction(
  '[Shared.Layout] Set Server Environment',
  props<{ payload: string }>()
);

export const setIsFullWidth = createAction(
  '[Shared.Layout] Set Is Full Width',
  props<{ payload: boolean }>()
);
