import { CustomShuttlePriceDetails } from './custom-shuttle-price-details';

export class CustomShuttleOffers {
  active: boolean;
  action: string;
  aircraftCategoryId: number = null;
  aircraftCategoryName: string = null;
  arrivalAirportCodes: Array<string> = [];
  createTime: Date = null;
  createdByUserId: number = null;
  crowdSourceEnabled: boolean;
  crowdSourceLifetime: Array<number> = [];
  datetimeInterval: Array<Date> = [];
  departureStartTime: Date | string;
  departureEndTime: Date | string;
  departureAirportCodes: Array<string> = [];
  crowdSourceExpirationTime: string = null;
  id: number = null;
  locked: boolean;
  membershipTierName: string = null;
  membershipTypeId: number = null;
  minSeats: number = null;
  minTotalPrice: string | number = null;
  offerTypeId: number = null;
  offerTypeName: OfferTypeName;
  priceDetails: CustomShuttlePriceDetails;
  priceDetailsJson: string = null;
  pricePerExtraSeat: string | number = null;
  pricePerSeat: string | number = null;
  priceSurcharge: string | number = null;
  routeGroupId: number = null;
  routeGroupName: string = null;
  shuttleTypeId: number = null;
  shuttleTypeName: string = null;
}

export type OfferTypeName = 'Regular' | 'Deal';
