import * as moment from 'moment';

export class LegListDateSelection {
  range = true;
  startDate = moment(new Date()).toDate();
  endDate = moment(new Date()).toDate();
  daysOfTheWeek = new Array<boolean>(7).fill(true);

  constructor(isForReleaseDate = false) {
    if (isForReleaseDate) {
      this.startDate.setDate(new Date().getDate() - 180);
      // this.startDate.setHours(0, 0, 0, 0);
    } else {
      this.endDate.setDate(new Date().getDate() + 90);
      // Set start date hours to beginning of the day
      // this.startDate.setHours(0, 0, 0, 0);
    }
  }
}
