import { PriceTypeEnum } from './price-type-enum';
import { FixedPriceAlternateOption } from './fixed-price-alternate-option';
import { Surcharge } from './surcharge';

export class ElacPriceInfo {
  price?: number;
  pricingSupportType: PriceTypeEnum;
  bestPrice: boolean;
  base: number = 0;
  surcharges: Array<Surcharge>;
  fixedPriceAlternateOption: FixedPriceAlternateOption = null;

  getPrice(): number {
    return this.price;
  }

  setPrice(price: number): ElacPriceInfo {
    this.price = price;

    return this;
  }

  getPricingSupportType(): PriceTypeEnum {
    return this.pricingSupportType;
  }

  setPricingSupportType(pricingSupportType: PriceTypeEnum): ElacPriceInfo {
    this.pricingSupportType = pricingSupportType;

    return this;
  }

  getBestPrice(): boolean {
    return this.bestPrice;
  }

  setBestPrice(bestPrice: boolean): ElacPriceInfo {
    this.bestPrice = bestPrice;

    return this;
  }

  getBase(): number {
    return this.base;
  }

  setBase(base: number): ElacPriceInfo {
    this.base = base;

    return this;
  }

  getSurcharges(): Array<Surcharge> {
    return this.surcharges;
  }

  setSurcharges(surcharges: Array<Surcharge>): ElacPriceInfo {
    this.surcharges = surcharges;
    
    return this;
  }

  getFixedPriceAlternateOption(): FixedPriceAlternateOption {
    return this.fixedPriceAlternateOption;
  }

  setFixedPriceAlternateOption(fixedPriceAlternateOption: FixedPriceAlternateOption): ElacPriceInfo {
    this.fixedPriceAlternateOption = fixedPriceAlternateOption;

    return this;
  }
}