import {
  ILegCostsService,
  LegCostsFinalizedLegIdsResponse,
  LegCostsListResponse,
  LegCostsUpdateResponse,
} from '../../../domain/service-contracts/leg-costs';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LegCost } from '../../../domain/models/leg-cost';

@Injectable()
export class LegCostsMockService implements ILegCostsService {
  private mockLegCosts: LegCost[] = [
    {
      id: 1,
      flightRequestId: 1234568,
      legId: '123457',
      costType: {
        id: 64,
        type: 'FCHRG',
        description: 'Flight Charge',
      },
      amount: 5000.0,
    },
    {
      id: 2,
      flightRequestId: 1234568,
      legId: '123457',
      costType: {
        id: 59,
        type: 'CCFE',
        description: 'Convenience Fee',
      },
      amount: 2650.0,
    },
    {
      id: 3,
      flightRequestId: 1234568,
      legId: '123457',
      costType: {
        id: 32,
        type: 'RTD',
        description: 'ELAC Round Trip Discount',
      },
      amount: 500.0,
    },
    {
      id: 4,
      flightRequestId: 1234568,
      legId: '123459',
      costType: {
        id: 12,
        type: 'CANF',
        description: 'Cancellation Fee',
      },
      amount: 500.0,
    },
    {
      id: 5,
      flightRequestId: 1234568,
      legId: '123457',
      costType: {
        id: 52,
        type: 'PLUS',
        description: 'Price Adjustment',
      },
      amount: 500.0,
    },
    {
      id: 6,
      flightRequestId: 1234568,
      legId: '123456',
      costType: {
        id: 57,
        type: 'BASE',
        description: 'MSRP Basement',
      },
      amount: 500.0,
    },
    {
      id: 7,
      flightRequestId: 1234568,
      legId: '123456',
      costType: {
        id: 33,
        type: 'STD',
        description: 'MSRP Standard',
      },
      amount: 500.0,
    },
    {
      id: 8,
      flightRequestId: 1234568,
      legId: '123459',
      costType: {
        id: 56,
        type: 'SSVC',
        description: 'SA Discount for Service Issues',
      },
      amount: 500.0,
    },
    {
      id: 9,
      flightRequestId: 1234568,
      legId: '123459',
      costType: {
        id: 8,
        type: 'GCL3',
        description: 'Guaranteed CL30',
      },
      amount: 500.0,
    },
    {
      id: 10,
      flightRequestId: 1234568,
      legId: '123458',
      costType: {
        id: 34,
        type: 'PDFP',
        description: 'Peak Day Premium 1/2',
      },
      amount: 500.0,
    },
  ];

  private mockLegIdList: string[] = ['123457', '123459', '123456', '123458'];

  getLegCostsByFlightRequestId(flightRequestId: number): Observable<LegCostsListResponse> {
    const response = new LegCostsListResponse();
    response.legCosts = this.mockLegCosts;
    response.message = 'success';
    response.code = 200;

    return of(response);
  }

  getLegIdListByFlightRequestId(
    flightRequestId: number
  ): Observable<LegCostsFinalizedLegIdsResponse> {
    const response = new LegCostsFinalizedLegIdsResponse();
    response.finalizedLegIds = this.mockLegIdList;
    response.message = 'success';
    response.code = 200;

    return of(response);
  }

  updateLegCost(legCost: LegCost): Observable<LegCostsUpdateResponse> {
    return null;
  }
}
