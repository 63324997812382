import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class FlightCreditAdjustmentOrderItem extends OrderItem {
  flightCreditAmount = 0;
  modifiesTotal = false;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.FlightCredit;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.flightCreditAmount;
  }
}
