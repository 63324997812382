import * as actions from '../actions/membership-status';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export interface State {
  loaded: boolean;
  loading: boolean;
  membershipStatus: models.MembershipStatus;
  membershipStatusBackup: models.MembershipStatus;
}

const initialState: State = {
  loaded: false,
  loading: false,
  membershipStatus: null,
  membershipStatusBackup: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return { ...state, loading: true };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        membershipStatus: action.payload,
      };
    }
    case actions.LOAD_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
        membershipStatus: null,
      };
    }
    case actions.BACKUP: {
      return {
        ...state,
        membershipStatusBackup: _.cloneDeep(state.membershipStatus),
        membershipStatus: new models.MembershipStatus()
      }
    }
    case actions.RESTORE: {
      return {
        ...state,
        membershipStatus: _.cloneDeep(state.membershipStatusBackup),
        membershipStatusBackup: null
      }
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getMembershipStatus = (state: State) => state.membershipStatus;
