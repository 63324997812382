<div class="container-xs-height full-height">
  <div class="row-xs-height">
    <div class="col col-xs-height col-middle">
      <div class="error-container text-center">
        <h2 class="semi-bold">Access denied</h2>
        <p>Your access to this resource is denied. Please contact administrator.</p>
      </div>
    </div>
  </div>
</div>
