import { ReportPaths } from './report-paths';

export enum OperatorReportPathsEnum {
	REPORT_CONFIG = 'report-config',
	RATING_AOC_CONFIG = 'aoc-config',
	RATING_CONFIG = 'rating-config',
	AUDIT_CONFIG = 'audit-config',
}

export enum OperatorStartReportPathsEnum {
	REPORT_AOC_COMPILER = 'report-aoc',
	REPORT_RATING = 'report-rating',
	AUDIT_RATING = 'report-audit'
}

export const operatorReportsPathsByName = {
	[OperatorReportPathsEnum.REPORT_CONFIG] : 'config',
	[OperatorReportPathsEnum.RATING_AOC_CONFIG] : 'aoc/config',
	[OperatorReportPathsEnum.RATING_CONFIG] : 'rating/config',
	[OperatorReportPathsEnum.AUDIT_CONFIG] : 'audit/config',
	[OperatorStartReportPathsEnum.REPORT_AOC_COMPILER] : 'report/aoc',
	[OperatorStartReportPathsEnum.REPORT_RATING] : 'report/rating',
	[OperatorStartReportPathsEnum.AUDIT_RATING] : 'report/audit',
}

export const operatorReportPaths: ReportPaths = {
	configLabelsByPath: new Map([
		[OperatorReportPathsEnum.RATING_AOC_CONFIG, 'Aoc report configuration'],
		[OperatorReportPathsEnum.AUDIT_CONFIG, 'Audit report configuration'],
		[OperatorReportPathsEnum.RATING_CONFIG, 'Rating report configuration'],
		[OperatorReportPathsEnum.REPORT_CONFIG, 'All report configuration'],
	]),
	startLabelsByPath: new Map([
		[OperatorStartReportPathsEnum.REPORT_AOC_COMPILER, 'Start Aoc report process'],
		[OperatorStartReportPathsEnum.AUDIT_RATING, 'Start Audit report process'],
		[OperatorStartReportPathsEnum.REPORT_RATING, 'Start Rating report process'],
	])
}