/* eslint-disable complexity */
import * as actions from '../actions/cyo-pricing';
import * as models from '../../domain/models';
import * as viewModel from '../../view/view-models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  lookupCriteria: viewModel.RouteLookup;
  version: number;
  rules: Array<viewModel.CyoPricingRuleWrapper>;
  isMarketLoading: boolean;
  isMarketLoaded: boolean;
  market: models.Market;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  lookupCriteria: new viewModel.RouteLookup(),
  version: null,
  rules: new Array<viewModel.CyoPricingRuleWrapper>(),
  isMarketLoading: false,
  isMarketLoaded: false,
  market: new models.Market(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.ADD: {
      const rules = _.cloneDeep(state.rules);
      const n = new viewModel.CyoPricingRuleWrapper();
      // Assign temp Id
      rules.unshift({ id: 0 });
      n.id =
        'new-ui-id-' +
        rules
          .map((m) => m.id)
          .reduce((a, b) => {
            if (b.includes('new-ui-id-')) {
              const ittr = Number(b.charAt(b.length - 1));

              return a <= ittr ? ittr + 1 : a;
            }

            return a;
          });
      rules[0] = n;

      return {
        ...state,
        lookupCriteria: new viewModel.RouteLookup(),
        rules,
      };
    }

    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const rules = _.cloneDeep(action.payload.entities);
      const version = _.cloneDeep(action.payload.version);

      return {
        ...state,
        rules,
        isLoaded: true,
        version,
        isLoading: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: true,
      };
    }

    case actions.LOAD_MARKET: {
      return {
        ...state,
        isMarketLoaded: false,
        isMarketLoading: true,
      };
    }

    case actions.LOAD_MARKET_SUCCESS: {
      const market = _.cloneDeep(action.payload.entity);

      return {
        ...state,
        market,
        isMarketLoaded: true,
        isMarketLoading: false,
      };
    }

    case actions.LOAD_MARKET_FAIL: {
      return {
        ...state,
        isMarketLoaded: true,
        isMarketLoading: false,
      };
    }

    case actions.SET: {
      const rule = _.cloneDeep(action.payload.entity);

      const rules = _.cloneDeep(
        state.rules.map((r: viewModel.CyoPricingRuleWrapper) => (r.id === rule.id ? rule : r))
      );

      return {
        ...state,
        rules,
      };
    }

    case actions.REMOVE: {
      const rule = _.cloneDeep(action.payload.entity);

      const rules = _.cloneDeep(
        state.rules.filter((r: viewModel.CyoPricingRuleWrapper) => r.id !== rule.id)
      );

      return {
        ...state,
        rules,
      };
    }

    case actions.SAVE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }
    case actions.SAVE_SUCCESS: {
      const rules = _.cloneDeep(action.payload.entities);
      const version = _.cloneDeep(action.payload.version);

      return {
        ...state,
        rules,
        version,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SAVE_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SET_LOOKUP_CRITERIA: {
      const lookupCriteria = _.cloneDeep(action.payload.entity);

      return {
        ...state,
        lookupCriteria,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getVersion = (state: State) => state.version;
export const getRules = (state: State) => state.rules;
export const getLookupCriteria = (state: State) => state.lookupCriteria;
export const getIsMarketLoaded = (state: State) => state.isMarketLoaded;
export const getIsMarketLoading = (state: State) => state.isMarketLoading;
export const getMarket = (state: State) => state.market;
