export class Dba {
  name: string;
  value: string;

  getName(): string {
    return this.value;
  }

  setName(name: string): Dba {
    this.name = name;
    return this;
  }

  getValue(): string {
    return this.value;
  }

  setValue(value: string): Dba {
    this.value = value;
    return this;
  }
}
