export const TASK_STATES = [
  {
    taskStateId: 1,
    displayName: 'To do',
    code: 'TO_DO',
  },
  {
    taskStateId: 2,
    displayName: 'In progress',
    code: 'IN_PROGRESS',
  },
  {
    taskStateId: 3,
    displayName: 'On hold',
    code: 'ON_HOLD',
  },
  {
    taskStateId: 4,
    displayName: 'Discarded',
    code: 'DISCARDED',
  },
  {
    taskStateId: 5,
    displayName: 'Completed',
    code: 'COMPLETED',
  },
  {
    taskStateId: 6,
    displayName: 'Review',
    code: 'REVIEW',
  },
];
