import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

import * as contracts from '../../../domain/service-contracts/additional-features';
import { response } from './get-additional-features-response';

@Injectable()
export class AdditionalFeaturesMock implements contracts.IAdditionalFeaturesService {
  getAdditionalFeatures(
    request: contracts.GetAdditionalFeaturesRequest
  ): Observable<contracts.GetAdditionalFeaturesResponse> {
    const { data } = response;
    const r = new contracts.GetAdditionalFeaturesResponse();
    r.entities = data;
    return of(r);
  }
}
