export enum PaymentTransactionTypeEnum {
  CreditCard = 1,
  Amex = 2,
  ACH = 3,
  ACHWithCCHold = 4,
  WireTransfer = 5,
  WireTransferWithCCHold = 6,
  Unknown = 7,
  Check = 8,
  CheckWithCCHold = 9,
}
