import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as actions from '../actions/auth0-error-notification.actions';
import * as serviceContracts from '../service-contracts/auth0-error-notification';
import * as sharedTypes from '../../shared/types';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class Auth0ErrorNotificationEffects {
  sendErrorNotification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.sendErrorNotification),
      switchMap(({ failedEndpoint }) => {
        const request = new serviceContracts.SendErrorNotificationRequest();
        request.failedEndpoint = failedEndpoint;
        return this.auth0ErrorService.sendErrorNotification(request).pipe(
          withLatestFrom(this.auth.getUser()),
          map(([, user]) => {
            this.notificationService.show(
              `User ${user.email} was not authorized.`,
              sharedTypes.NotificationStyle.Bar,
              sharedTypes.NotificationType.Danger,
              sharedTypes.NotificationPosition.Top,
              0
            );
            return actions.sendErrorNotificationSuccess();
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    @Inject(serviceContracts.SERVICE_TOKEN)
    private auth0ErrorService: serviceContracts.IAuth0ErrorNotificationService,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN)
    private notificationService: sharedTypes.INotificationService,
    private auth: AuthService
  ) {}
}
