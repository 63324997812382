import * as models from '../../../../domain/models';
import { OrderItemSerializable } from './order-item-serializable';
import { OrderPaymentOptionsDto } from './order-payment-options-dto';
import { OrderBillingDto } from './order-billing-dto';

export class OrderSerializable {
  id: number = null;
  orderStatusId: number = null;
  partyId: number = null;
  userId: number = null;
  promotionId: number = null;
  // orderBilling: models.OrderBilling = null;
  // orderBillingClientName: string = null;
  // orderBillingClientEmail: string = null;
  // orderBillingClientPhone: string = null;
  orderBilling: OrderBillingDto = new OrderBillingDto();
  conciergeInvoiceType?: models.ConciergeInvoiceTypeEnum;
  totalPrice: number = null;
  lastOrder?: models.Order = null;
  autoAccept?: boolean = null;
  totalAmount: number = null;
  createTime: Date = null;
  items: Array<OrderItemSerializable> = [];
  invoices: Array<models.Invoice> = [];
  notes: string = null;
  orderSourceId: number = null;
  balance?: number = null;
  // wireTransferOnly = false;
  // paymentOptions: OrderPaymentOptionsDto = new OrderPaymentOptionsDto();
  paymentOptions: OrderPaymentOptionsDto;
}
