export enum ProductFeatureType {
  Money = 1,
  Length = 2,
  Legacy = 3,
  Count = 4,
  MaxCount = 5,
  ProductID = 6,
  ReplacementsCount = 7,
  ExtraSeatProductId = 8,
  IsMultippleAllowed = 9,
  AddOnToProductId = 10,
  IsProratable = 11,
  IsCompensation = 12,
  CustomTermProductId = 13,
  IsMappedToInAppProduct = 14,
  IsConcierge = 15,
  ConciergeVendorTypeId = 16,
  IsFlex = 17,
  IsCharter = 18,
  ClientSource = 19,
  AddsFOA = 20,
  XOAgreementType = 21,
  IsVariableMoney = 24,
}
