import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/order-new.actions';
import * as domainServices from '../../domain/domain-services';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const orderNewFeatureKey = 'orderNew';

export interface State {
  isPlacing: boolean;
  isPlaced: boolean;
  isValid: boolean;
  orderItemEditors: Array<models.OrderItemEditorEnum>;
  paymentOptionsContext: PaymentOptionsContext;
  promotionIsValidating: boolean;
  order: models.Order;
  selectedOrderItemIndex?: number;
  sfdcOpportunityIdLoaded: boolean;
  sfdcOpportunityIdLoading: boolean;
  sfdcOpportunityData: models.SfdcOpportunityData;
  sfdcOpportunityCancelOrderLoaded: boolean;
  sfdcOpportunityCancelOrderLoading: boolean;
  sfdcOpportunityCancelOrderData: models.SfdcOpportunityCancelData;
  isSfdcDialogSubmit: boolean;
  sfdcOpportunityFlightPassLoaded: boolean;
  sfdcOpportunityFlightPassLoading: boolean;
  sfdcOpportunityFlightPassData: models.SfdcOpportunityFlightPassData;
  isSfdcFlightPassDialogSubmit: boolean;
  sfdcOpportunityCreditMemoLoaded: boolean;
  sfdcOpportunityCreditMemoLoading: boolean;
  sfdcOpportunityCreditMemoData: models.SfdcOpportunityCreditMemoData;
  isSfdcCreditMemoDialogSubmit: boolean;
  isProformaInvoice: boolean;
  isProformaInvoiceCheckRunning: boolean;
  showAmendChargesButton: boolean;
}

export class PaymentOptionsContext {
  splitPaymentOutstandingAmount = 0;
  isValid = true;
}

const initialState: State = {
  isPlacing: false,
  isPlaced: false,
  isValid: false,
  orderItemEditors: [],
  order: new models.Order(),
  paymentOptionsContext: new PaymentOptionsContext(),
  promotionIsValidating: false,
  selectedOrderItemIndex: null,
  sfdcOpportunityIdLoaded: false,
  sfdcOpportunityIdLoading: false,
  sfdcOpportunityData: new models.SfdcOpportunityData(),
  sfdcOpportunityCancelOrderLoaded: false,
  sfdcOpportunityCancelOrderLoading: false,
  sfdcOpportunityCancelOrderData: new models.SfdcOpportunityCancelData(),
  isSfdcDialogSubmit: false,
  sfdcOpportunityFlightPassLoaded: false,
  sfdcOpportunityFlightPassLoading: false,
  sfdcOpportunityFlightPassData: new models.SfdcOpportunityFlightPassData(),
  isSfdcFlightPassDialogSubmit: false,
  sfdcOpportunityCreditMemoLoaded: false,
  sfdcOpportunityCreditMemoLoading: false,
  sfdcOpportunityCreditMemoData: new models.SfdcOpportunityCreditMemoData(),
  isSfdcCreditMemoDialogSubmit: false,
  isProformaInvoice: false,
  isProformaInvoiceCheckRunning: false,
  showAmendChargesButton: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.loadSfdcOpportunity,
    (state): State => ({
      ...state,
      sfdcOpportunityIdLoading: true,
      sfdcOpportunityIdLoaded: false,
    })
  ),
  on(
    actions.loadSfdcOpportunitySuccess,
    (state, { payload: { sfdcOpportunityData } }): State => ({
      ...state,
      sfdcOpportunityData,
      sfdcOpportunityIdLoading: false,
      sfdcOpportunityIdLoaded: true,
    })
  ),

  on(
    actions.loadSfdcOpportunityCancelOrder,
    (state): State => ({
      ...state,
      sfdcOpportunityCancelOrderLoading: true,
      sfdcOpportunityIdLoaded: false,
    })
  ),
  on(
    actions.loadSfdcOpportunityCancelOrderSuccess,
    (state, { payload: { sfdcOpportunityCancelOrderData } }): State => ({
      ...state,
      sfdcOpportunityCancelOrderData,
      sfdcOpportunityCancelOrderLoading: false,
      sfdcOpportunityCancelOrderLoaded: true,
    })
  ),
  on(
    actions.loadSfdcOpportunityCancelOrderFail,
    (state): State => ({
      ...state,
      sfdcOpportunityCancelOrderLoading: false,
      sfdcOpportunityCancelOrderLoaded: true,
    })
  ),

  on(
    actions.loadSfdcOpportunityFlightPass,
    (state): State => ({
      ...state,
      sfdcOpportunityFlightPassLoading: true,
      sfdcOpportunityFlightPassLoaded: false,
    })
  ),
  on(
    actions.loadSfdcOpportunityFlightPassSuccess,
    (state, { payload: { sfdcOpportunityFlightPassData } }): State => ({
      ...state,
      sfdcOpportunityFlightPassData,
      sfdcOpportunityFlightPassLoading: false,
      sfdcOpportunityFlightPassLoaded: true,
    })
  ),
  on(
    actions.loadSfdcOpportunityFlightPassFail,
    (state): State => ({
      ...state,
      sfdcOpportunityFlightPassLoading: false,
      sfdcOpportunityFlightPassLoaded: false,
    })
  ),

  on(
    actions.setSfdcFlightPassDialogSubmit,
    (state, { payload }): State => ({
      ...state,
      isSfdcFlightPassDialogSubmit: payload,
    })
  ),

  on(
    actions.loadSfdcOpportunityCreditMemo,
    (state): State => ({
      ...state,
      sfdcOpportunityCreditMemoLoading: true,
      sfdcOpportunityCreditMemoLoaded: false,
    })
  ),
  on(
    actions.loadSfdcOpportunityCreditMemoSuccess,
    (state, { payload: { sfdcOpportunityCreditMemoData } }): State => ({
      ...state,
      sfdcOpportunityCreditMemoData,
      sfdcOpportunityCreditMemoLoading: false,
      sfdcOpportunityCreditMemoLoaded: true,
    })
  ),
  on(
    actions.loadSfdcOpportunityCreditMemoFail,
    (state): State => ({
      ...state,
      sfdcOpportunityCreditMemoLoading: false,
      sfdcOpportunityCreditMemoLoaded: false,
    })
  ),

  on(
    actions.setSfdcCreditMemoDialogSubmit,
    (state, { payload }): State => ({
      ...state,
      isSfdcCreditMemoDialogSubmit: payload,
    })
  ),

  on(
    actions.setOrderInvalid,
    (state): State => ({
      ...state,
      isValid: false,
    })
  ),

  on(actions.loadReferredByClient, (state): State => {
    const order = _.cloneDeep(state.order) as models.Order;
    order.isReferredClientLoaded = false;
    order.isReferredClientLoading = true;

    return {
      ...state,
      order,
    };
  }),
  on(actions.loadReferredByClientSuccess, (state, { payload }): State => {
    const order = _.cloneDeep(state.order) as models.Order;
    order.isReferredClientLoaded = true;
    order.isReferredClientLoading = false;

    const client = _.cloneDeep(payload.client);

    if (order.items[payload.index] instanceof models.MembershipOrderItem) {
      (order.items[payload.index] as models.MembershipOrderItem).referredByClient = client;
    }

    return {
      ...state,
      order,
    };
  }),
  on(actions.loadReferredByClientFail, (state): State => {
    const order = _.cloneDeep(state.order) as models.Order;
    order.isReferredClientLoaded = true;
    order.isReferredClientLoading = false;

    return {
      ...state,
      order,
    };
  }),

  on(actions.setOrderItems, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);

    const orderItemEditors = new Array(payload.orderItems.length).fill(
      _.cloneDeep(payload.orderItemEditor)
    );
    order.items = _.cloneDeep(payload.orderItems);
    order.lastOrder =
      payload.lastOrder && payload.lastOrder.items.length ? payload.lastOrder : null;

    const newState: State = {
      ...state,
      order,
      orderItemEditors,
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: order.items.length > 0 ? 0 : null,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.addItem, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);

    const orderItemEditors = _.cloneDeep(state.orderItemEditors);
    order.items.push(_.cloneDeep(payload.orderItem));
    orderItemEditors.push(_.cloneDeep(payload.orderItemEditor));

    const newState: State = {
      ...state,
      order,
      orderItemEditors,
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: order.items.length > 0 ? order.items.length - 1 : null,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.clearPromotion, (state): State => {
    const order = _.cloneDeep(state.order);

    const newState: State = {
      ...state,
      order,
      orderItemEditors: [],
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: null,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.setOrderReferredBy, (state, { payload }): State => {
    const client = _.cloneDeep(payload.client);
    const order = _.cloneDeep(state.order);

    if (order.items[payload.index] instanceof models.MembershipOrderItem) {
      (order.items[payload.index] as models.MembershipOrderItem).referredByClient = client;
    }

    return {
      ...state,
      order,
    };
  }),

  on(actions.insertItem, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.items.push(_.cloneDeep(payload.orderItem));

    const orderItemEditors = _.cloneDeep(state.orderItemEditors);
    orderItemEditors.push(payload.orderItemEditor);

    const newState: State = {
      ...state,
      order,
      orderItemEditors,
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: order.items.length > 0 ? order.items.length - 1 : null,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(
    actions.lookupPromotion,
    (state): State => ({
      ...state,
      promotionIsValidating: true,
    })
  ),
  on(
    actions.lookupPromotionSuccess,
    actions.lookupPromotionFail,
    (state): State => ({
      ...state,
      promotionIsValidating: false,
    })
  ),

  on(
    actions.placeOrder,
    (state): State => ({
      ...state,
      isPlacing: true,
    })
  ),
  on(
    actions.placeOrderSuccess,
    (): State => ({
      ...initialState,
    })
  ),
  on(
    actions.placeOrderFail,
    (state): State => ({
      ...state,
      isPlacing: false,
    })
  ),

  on(
    actions.setIsProformaInvoice,
    (state, { isProformaInvoice }): State => ({
      ...state,
      isProformaInvoice,
    })
  ),

  on(
    actions.setIsProformaInvoiceCheckRunning,
    (state, { isRunningProformaCheck }): State => ({
      ...state,
      isProformaInvoiceCheckRunning: isRunningProformaCheck,
    })
  ),

  on(
    actions.setShowAmendChargesButton,
    (state, { showAmendChargesButton }): State => ({
      ...state,
      showAmendChargesButton: showAmendChargesButton,
    })
  ),

  on(actions.setAutoAccept, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.autoAccept = payload;
    return {
      ...state,
      order,
    };
  }),

  on(actions.removeItem, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);

    const orderItemEditors = _.cloneDeep(state.orderItemEditors);
    order.items.splice(payload, 1);
    orderItemEditors.splice(payload, 1);

    const newState: State = {
      ...state,
      order,
      orderItemEditors,
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: order.items.length > 0 ? 0 : null,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(
    actions.reset,
    (state): State => ({
      ...initialState,
      sfdcOpportunityIdLoading: state.sfdcOpportunityIdLoading,
      sfdcOpportunityIdLoaded: state.sfdcOpportunityIdLoaded,
      sfdcOpportunityFlightPassLoading: state.sfdcOpportunityFlightPassLoading,
      sfdcOpportunityFlightPassLoaded: state.sfdcOpportunityFlightPassLoaded,
      sfdcOpportunityCreditMemoLoading: state.sfdcOpportunityCreditMemoLoading,
      sfdcOpportunityCreditMemoLoaded: state.sfdcOpportunityCreditMemoLoaded,
    })
  ),

  on(actions.setOrderBilling, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.orderBilling = payload;

    const newState = {
      ...state,
      order,
    };

    resetOrderPaymentOptions(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.setBillingAsOrderItemClient, (state, { payload }): State => {
    const order = _.cloneDeep(state.order) as models.Order;
    order.items = order.items.map((i) => {
      if (
        i.orderItemType === models.OrderItemTypeEnum.Concierge ||
        i.orderItemType === models.OrderItemTypeEnum.Charter
      ) {
        if (
          (i.client === null || i.client.id !== payload.client.id) &&
          i.orderItemType !== models.OrderItemTypeEnum.Concierge
        ) {
          (i as models.ProductOrderItem).requestId = null;
        }
        i.client = payload.client;
        // (i as models.ProductOrderItem).requestId = null;
      }

      return i;
    });

    const newState = {
      ...state,
      order,
    };

    updateOrderValidity(newState);

    return newState;
  }),

  on(
    actions.selectOrderItem,
    (state, { payload }): State => ({
      ...state,
      selectedOrderItemIndex: payload,
    })
  ),

  on(actions.setClient, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.orderBilling.client = _.cloneDeep(payload);

    const newState: State = {
      ...state,
      order,
    };

    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.setPaymentOptions, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    const paymentOptionsContext = _.cloneDeep(state.paymentOptionsContext);

    const po = _.cloneDeep(payload) as models.OrderPaymentOptions;

    if (po.paymentByWireTransferOnly) {
      po.splitPayment = false;
    }

    // updateCharterInvoiceType(order, po.splitPayment);

    if (po.splitPayment) {
      if (order.paymentOptions.splitPayment === false && po.splitPayments.length === 0) {
        const sp = new models.SplitPayment();
        sp.client = state.order.orderBilling.client;
        sp.businessName = state.order.orderBilling.businessName;
        po.splitPayments.push(...[sp, _.cloneDeep(sp)]);
      }

      order.paymentOptions = po;

      const totalSplitPayment =
        Math.round(
          (order.paymentOptions.splitPayments.reduce(
            (total: number, item: models.SplitPayment) => total + item.splitAmount,
            0
          ) +
            0.00001) *
            100
        ) / 100;

      paymentOptionsContext.splitPaymentOutstandingAmount =
        Math.round((state.order.totalPrice - totalSplitPayment) * 100) / 100;
      paymentOptionsContext.isValid =
        totalSplitPayment === Math.round((Number(state.order.totalPrice) + 0.00001) * 100) / 100;
    } else {
      po.splitPayments = [];
      order.paymentOptions = po;
      paymentOptionsContext.splitPaymentOutstandingAmount = 0;
      paymentOptionsContext.isValid = true;
    }

    return {
      ...state,
      order,
      paymentOptionsContext,
    };
  }),

  on(actions.setPromotionSuccess, (state, { payload }): State => {
    const actionPayload = _.cloneDeep(payload) as actions.SetPromotionSuccessPayload;

    const order = _.cloneDeep(state.order);
    order.promotion = actionPayload.promotion;
    order.items = actionPayload.orderItems;

    const newState: State = {
      ...state,
      order,
      orderItemEditors: actionPayload.orderItemEditors,
      paymentOptionsContext: initialState.paymentOptionsContext,
      selectedOrderItemIndex: 0,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(actions.updateOrderItem, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.items[payload.index] = _.cloneDeep(payload.orderItem);

    const newState: State = {
      ...state,
      order,
      paymentOptionsContext: initialState.paymentOptionsContext,
    };

    resetOrderPaymentOptions(newState);
    updateTotalAmount(newState);
    updateOrderValidity(newState);

    return newState;
  }),

  on(
    actions.setSfdcDialogSubmit,
    (state, { payload }): State => ({
      ...state,
      isSfdcDialogSubmit: payload,
    })
  ),

  on(actions.setOrderItemsCreditReasons, (state, { payload }): State => {
    const order = _.cloneDeep(state.order);
    order.items.forEach((i) => (i.creditReason = payload));
    const newState: State = {
      ...state,
      order,
    };
    updateOrderValidity(newState);
    return newState;
  }),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState,
    })
  )
);

// const updateCharterInvoiceType = (order: models.Order, splitPayment: boolean) => {
//   order.items = order.items.map((oi, i) => {
//     if (oi.orderItemType === models.OrderItemTypeEnum.Charter) {
//       if ((oi as models.CharterOrderItem).charterInvoiceType !== models.CharterInvoiceTypeEnum.Additional) {
//         (oi as models.CharterOrderItem).charterInvoiceType = splitPayment
//           ? models.CharterInvoiceTypeEnum.Split : models.CharterInvoiceTypeEnum.New;
//       }
//     }

//     return oi;
//   });
// };

const resetOrderPaymentOptions = (state: State) => {
  state.order.paymentOptions = new models.OrderPaymentOptions();

  // const splitPaymentOptions = new models.OrderPaymentOptions();
  // splitPaymentOptions.splitPayment = true;
  // splitPaymentOptions.paymentByWireTransferOnly = false;
  // const sp = new models.SplitPayment();
  // sp.client = _.cloneDeep(state.order.orderBilling.client);
  // sp.businessName = _.cloneDeep(state.order.orderBilling.businessName);
  // splitPaymentOptions.splitPayments.push(sp, _.cloneDeep(sp));

  // state.order.paymentOptions = state.order.items.find(oi => oi instanceof models.CharterOrderItem && oi.product !== null)
  //   ? splitPaymentOptions
  //   : new models.OrderPaymentOptions();
};

const updateTotalAmount = (state: State) => {
  const orderService = new domainServices.OrderService();

  let totalAmount = 0;

  for (const orderItem of state.order.items) {
    for (const childOrderItem of orderItem.childOrderItems) {
      childOrderItem.totalPrice = orderService.getOrderItemTotalPrice(childOrderItem);
    }

    orderItem.totalPrice = orderService.getOrderItemTotalPrice(orderItem);

    if (
      orderItem.product &&
      (orderItem as models.GroupMembershipOrderItem).salesAction &&
      (orderItem as models.GroupMembershipOrderItem).salesAction ===
        models.SalesActionEnum.Convert &&
      !(orderItem as models.GroupMembershipOrderItem).isNotAffectPrice
    ) {
      recalculatePrices(orderItem as models.GroupMembershipOrderItem);
    }
    totalAmount += orderItem.totalPrice;
  }

  const sfdcOrderItem = state.order.items.find(
    (oi) =>
      oi &&
      oi.childOrderItems.find(
        (coi) => coi.orderItemType === models.OrderItemTypeEnum.SFDCDepositDue
      )
  );

  const sfdcAnnualMembershipFeeOrderItem = state.order.items.find(
    (oi) =>
      oi &&
      oi.childOrderItems.find(
        (coi) => coi.orderItemType === models.OrderItemTypeEnum.AnnualMembershipFee
      )
  );

  state.order.totalPrice = totalAmount;

  if (sfdcOrderItem) {
    const childOrderItem: models.SfdcDepositAdjustmentOrderItem =
      sfdcOrderItem.childOrderItems.find(
        (coi) => coi.orderItemType === models.OrderItemTypeEnum.SFDCDepositDue
      ) as models.SfdcDepositAdjustmentOrderItem;

    const childAnnualMembershipFeeOrderItem =
      sfdcAnnualMembershipFeeOrderItem?.childOrderItems?.find(
        (coi) => coi.orderItemType === models.OrderItemTypeEnum.AnnualMembershipFee
      ) as models.SfdcAnnualMembershipFeeOrderItem;

    if (childAnnualMembershipFeeOrderItem?.sfdcAnnualMembershipFee) {
      state.order.totalPrice =
        childOrderItem.sfdcDepositAmount +
        childAnnualMembershipFeeOrderItem.sfdcAnnualMembershipFee;
    } else {
      state.order.totalPrice = childOrderItem.sfdcDepositAmount;
    }
  }

  if (
    state.order.items.find((oi) => oi instanceof models.CharterOrderItem && oi.product !== null)
  ) {
    const context = new PaymentOptionsContext();
    context.isValid = state.paymentOptionsContext.isValid;
    context.splitPaymentOutstandingAmount = totalAmount;
    state.paymentOptionsContext = context;
  }
};

const isBillingValid = (state: State): boolean => {
  return state.order.orderBilling.client !== null;
};

const updateOrderValidity = (state: State) => {
  let isOrderValid = true;

  isOrderValid = isOrderValid && isBillingValid(state);

  if (state.order.items.length === 0) {
    isOrderValid = false;
  } else {
    for (const orderItem of state.order.items) {
      if (!isOrderItemValid(orderItem)) {
        isOrderValid = false;
      }
    }
  }

  state.isValid = isOrderValid;
  updateOrderItemApproval(state);
};

const updateOrderItemApproval = (state: State) => {
  const orderService = new domainServices.OrderService();
  let needsApproval = false;

  if (state.order.items.length !== 0) {
    for (const orderItem of state.order.items) {
      for (const childOrderItem of orderItem.childOrderItems) {
        if (orderService.getOrderItemNeedsApproval(childOrderItem)) {
          needsApproval = true;
        }
      }
      if (orderService.getOrderItemNeedsApproval(orderItem)) {
        needsApproval = true;
      }
    }
  }

  state.order.needsApproval = needsApproval;
};

const recalculatePrices = (orderItem: models.GroupMembershipOrderItem): void => {
  const { membershipClientService } = orderItem;
  if (!membershipClientService || !membershipClientService.product) {
    return;
  }
  const [childOrderItem] = orderItem.childOrderItems;
  if (!childOrderItem) {
    return;
  }
  const childProductFeatures = membershipClientService.product.productFeatures;
  const childClientSource = childProductFeatures.find(
    ({ typeId }) => typeId === models.ProductFeatureType.ClientSource
  );
  const childIsJS = !childClientSource || childClientSource.value === '1';
  const childIsRise =
    membershipClientService.product.productType.id === models.ProductTypeEnum.XORiseGroup;

  const childAddsFOA = childProductFeatures.find(
    ({ typeId }) => typeId === models.ProductFeatureType.AddsFOA
  );
  const childType =
    !childAddsFOA || (childAddsFOA && (childAddsFOA.value === '0' || !childAddsFOA.value))
      ? 'annual'
      : childAddsFOA.value === '1'
      ? 'deposit'
      : 'default';
  const parentProductFeatures = orderItem.product.productFeatures;
  const parentAddsFOA = parentProductFeatures.find(
    ({ typeId }) => typeId === models.ProductFeatureType.AddsFOA
  );
  const parentType =
    !parentAddsFOA || (parentAddsFOA && (parentAddsFOA.value === '0' || !parentAddsFOA.value))
      ? 'annual'
      : parentAddsFOA.value === '1'
      ? 'deposit'
      : 'default';

  if (childIsRise || childIsJS || (childType === 'deposit' && parentType === 'annual')) {
    childOrderItem.totalPrice = 0;
    childOrderItem['adjustedAmount'] = childOrderItem.totalPrice;
    orderItem.totalPrice = orderItem.product.amount;
  } else if (
    (childType === 'deposit' && parentType === 'deposit') ||
    (childType === 'annual' && parentType === 'deposit')
  ) {
    childOrderItem.totalPrice = -(orderItem.totalPrice + childOrderItem['adjustedAmount']);
    childOrderItem['adjustedAmount'] = -childOrderItem.totalPrice;
    orderItem.totalPrice = 0;
  }
};

const isOrderItemValid = (orderItem: models.OrderItem) => {
  if (orderItem === null) {
    return true;
  }
  if (orderItem.orderItemType === models.OrderItemTypeEnum.Product) {
    const productOrderItem = orderItem as models.ProductOrderItem;

    return productOrderItem.client != null && productOrderItem.product != null;
  } else if (orderItem.orderItemType === models.OrderItemTypeEnum.FlightPass) {
    const flightPassOrderItem = orderItem as models.FlightPassOrderItem;

    return (
      flightPassOrderItem.client != null &&
      flightPassOrderItem.product != null &&
      flightPassOrderItem.faceValue
    );
  } else if (orderItem.orderItemType === models.OrderItemTypeEnum.Membership) {
    const membershipOrderItem = orderItem as models.MembershipOrderItem;

    return (
      membershipOrderItem.client != null &&
      membershipOrderItem.product != null &&
      membershipOrderItem.quantity !== 0
    );
  } else if (orderItem.orderItemType === models.OrderItemTypeEnum.GroupMembership) {
    const groupMembershipOrderItem = orderItem as models.GroupMembershipOrderItem;

    return (
      groupMembershipOrderItem.client != null &&
      groupMembershipOrderItem.product != null &&
      groupMembershipOrderItem.quantity !== 0 &&
      !groupMembershipOrderItem.childOrderItems
        .filter((i) => i instanceof models.MembershipOrderItem)
        .find((ci: models.MembershipOrderItem) => ci.quantity === 0)
    );
  } else if (orderItem.orderItemType === models.OrderItemTypeEnum.Concierge) {
    const conciergeOi = orderItem as models.ConciergeOrderItem;

    if (conciergeOi.isCreditMemo) {
      return (
        conciergeOi.product !== null &&
        conciergeOi.vendorId !== null &&
        conciergeOi.creditReason &&
        conciergeOi.creditReason.length &&
        conciergeOi.creditMethod
      );
    }

    return (
      conciergeOi.product !== null &&
      conciergeOi.vendorId !== null &&
      conciergeOi.vendorRefNumber &&
      conciergeOi.requestId !== null &&
      (conciergeOi.requestId !== 0 || conciergeOi.serviceDate !== null) &&
      conciergeOi.quoteAmount
    );
  } else if (orderItem.orderItemType === models.OrderItemTypeEnum.Charter) {
    const charterOi = orderItem as models.CharterOrderItem;

    return (
      charterOi.product !== null &&
      charterOi.requestId !== null &&
      charterOi.formUrlKey !== null &&
      charterOi.isValid &&
      charterOi.charterInvoiceType !== null &&
      (charterOi.charterAction === models.CharterActionEnum.NewNoOrder ||
        charterOi.charterAction === models.CharterActionEnum.AdditionalNoOrder ||
        charterOi.charterAction === models.CharterActionEnum.AdditionalProforma)
    );
  }

  throw new Error(
    `Order Item Type: ${orderItem.orderItemType} is not supported inside reducer(order-new).isOrderItemValid`
  );
};
