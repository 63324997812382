import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/error.actions';

export const errorFeatureKey = 'error';

export interface State {
  error: string;
}

const initialState: State = {
  error: null,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.showError,
    (state, { payload }): State => ({
      ...state,
      error: payload,
    })
  ),
  on(
    actions.resetError,
    (): State => ({
      ...initialState,
    })
  )
);
