import { CharterInvoiceAuthorizationStateEnum } from './charter-invoice-authorization-state-enum';

export interface SalesInvoiceDocument {
  documentId: number;
  documentTypeId: CharterInvoiceAuthorizationStateEnum;
  documentTypeName: string;
  name: string;
  invoiceId: number;
  url: string;
  uploadTime: Date;
  generateTime: Date;
  updateTime: Date;
  createTime: Date;
  grayedOut: boolean;
  active: boolean;
  regeneratable: boolean;
  userId: number;
  userName: string;
}

export class SalesInvoiceDocumentClass {
  documentId: number = null;
  documentTypeId: CharterInvoiceAuthorizationStateEnum = null;
  documentTypeName: string = null;
  name: string = null;
  invoiceId: number = null;
  url: string = null;
  uploadTime: Date = null;
  generateTime: Date = null;
  updateTime: Date = null;
  createTime: Date = null;
  grayedOut: boolean = null;
  active: boolean = null;
  regeneratable: boolean = null;
  userId: number = null;
  userName: string = null;
}
