import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales PriceComponentToSalesActionCollection] Load');
export const loadSuccess = createAction(
  '[Sales PriceComponentToSalesActionCollection] Load Success',
  props<{ payload: Array<models.PriceComponentToSalesAction> }>()
);
export const loadFail = createAction('[Sales PriceComponentToSalesActionCollection] Load Fail');

