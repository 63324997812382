import { Invoice } from './invoice';
import { OrderItem } from './order-item';
import { OrderBilling } from './order-billing';
import { OrderPaymentOptions } from './order-payment-options';
import { OrderStatusEnum } from './order-status';
import { SapExportStatus } from './sap-export-status';
import { Promotion } from './promotion';
import { User } from './user';
import { Cancellation } from './cancellation';
import { InvoiceUiPaymentStatusEnum } from './invoice-ui-payment-status-enum';

export class Order {
  id: number = null;
  orderStatusId: number = null;
  orderStatus: OrderStatusEnum = null;
  documentGenerationInProgress: boolean;
  showGeneratedDocumentsPanel: boolean;
  sapExportStatus: SapExportStatus[] = null;
  partyId: number = null;
  promotion: Promotion = null;
  paymentConfirmed: boolean;
  replacedWithAdditional: boolean = false;
  user: User = null;
  orderBilling: OrderBilling = new OrderBilling();
  totalPrice = 0;
  balance? = null;
  canEdit?: boolean = null;
  orderUrl? = null;
  lastOrder?: Order = null;
  autoAccept?: boolean = null;
  chargeableCancellation?: boolean = null;
  charterSalesCancellation?: {
    cancellationFee?: number;
    cancellationNotes?: string;
  } = {};
  createTime: Date = null;
  items: Array<OrderItem> = [];
  invoices: Array<Invoice> = [];
  orderSourceId: number = null;
  needsApproval = false;
  paymentOptions: OrderPaymentOptions = new OrderPaymentOptions();
  canChangePaymentType = false;
  isReferredClientLoading = false;
  isReferredClientLoaded = false;
  charterSalesOpportunityId: string = null;
  cancellations: Array<Cancellation> = [];
  canCancelOrder: boolean = null;
  invoiceUIPaymentStatusId: InvoiceUiPaymentStatusEnum;
  showCreateSFDCOpportunityButton: boolean = null;
}
