import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const load = createAction('[Charter.Opportunity] Load', props<{ payload: string }>());
export const loadSuccess = createAction(
  '[Charter.Opportunity] Load Success',
  props<{ payload: models.Opportunity }>()
);
export const loadFail = createAction('[Charter.Opportunity] Load Fail');

export const loadClientMembership = createAction(
  '[Charter.Opportunity] Load Client Membership',
  props<{ payload: number }>()
);
export class LoadClientMembershipSuccessPayload {
  timeStarted: Date;
  timeExpiring: Date;
  productTypeId: number;
}
export const loadClientMembershipSuccess = createAction(
  '[Charter.Opportunity] Load Client Membership Success',
  props<{ payload: LoadClientMembershipSuccessPayload }>()
);
export const loadClientMembershipFail = createAction(
  '[Charter.Opportunity] Load Client Membership Fail',
  props<{ payload: any }>()
);

export class UpdateOpportunitySalesAgentPayload {
  constructor(public opportunity: models.Opportunity, public salesAgent: models.User) {}
}
export const updateOpportunitySalesAgent = createAction(
  '[Charter.Opportunity] Update Opportunity Sales Agent',
  props<{ payload: UpdateOpportunitySalesAgentPayload }>()
);
export const updateOpportunitySalesAgentSuccess = createAction(
  '[Charter.Opportunity] Update Opportunity Sales Agent Success',
  props<{ payload: models.Opportunity }>()
);

export class UpdateSourcingSpecialistPayload {
  constructor(public opportunity: models.Opportunity, public sourcingSpecialist: models.User) {}
}
export const updateSourcingSpecialist = createAction(
  '[Charter.Opportunity] Update Opportunity Sourcing Specialist',
  props<{ payload: UpdateSourcingSpecialistPayload }>()
);
export const updateSourcingSpecialistSuccess = createAction(
  '[Charter.Opportunity] Update Opportunity Sourcing Specialist Success',
  props<{ payload: models.Opportunity }>()
);

export class PromptDuplicateCheckOpportunityPayload {
  duplicateCheck: boolean;
  duplicateContext: models.DuplicateContext;
}
export const promptDuplicateCheck = createAction(
  '[Charter.Opportunity] Prompt Duplicate Check',
  props<{ payload: PromptDuplicateCheckOpportunityPayload }>()
);

export const submitFail = createAction('[Charter.Opportunity] Submit Fail');

export const showError = createAction(
  '[Charter.Opportunity] Show Error',
  props<{ payload: any }>()
);

export class SetOpportunityStatusPayload {
  constructor(
    public opportunity: models.Opportunity,
    public status: models.OpportunityStatus,
    public createSourcing: boolean
  ) {}
}
export const setOpportunityStatus = createAction(
  '[Charter.Opportunity] Set Opportunity Status',
  props<{ payload: SetOpportunityStatusPayload }>()
);
export class SetOpportunityStatusSuccessPayload {
  constructor(public opportunity: models.Opportunity, public status: models.OpportunityStatus) {}
}
export const setOpportunityStatusSuccess = createAction(
  '[Charter.Opportunity] Set Opportunity Status Success',
  props<{ payload: SetOpportunityStatusSuccessPayload }>()
);

export class SendSmartSalesQuoteEmailPayload {
  constructor(public smartSalesQuoteId: string, public emailAddress: string) {}
}
export const sendSmartSalesQuoteEmail = createAction(
  '[Charter.Opportunity] Send Smart Sales Quote Email',
  props<{ payload: SendSmartSalesQuoteEmailPayload }>()
);
export const sendSmartSalesQuoteEmailSuccess = createAction(
  '[Charter.Opportunity] Send Smart Sales Quote Email Success',
  props<{ payload: any }>()
);

export class UpdateSmartSalesQuotePayload {
  constructor(
    public smartSalesQuoteId: string,
    public opportunity: models.Opportunity,
    public salesQuotes: Array<models.SalesQuote>,
    public greetingText: string,
    public attributes: Array<number>
  ) {}
}
export const updateSmartSalesQuote = createAction(
  '[Charter.Opportunity] Update Smart Sales Quote',
  props<{ payload: UpdateSmartSalesQuotePayload }>()
);
export const updateSmartSalesQuoteSuccess = createAction(
  '[Charter.Opportunity] Update Smart Sales Quote Success',
  props<{ payload: any }>()
);
export const updateSmartSalesQuoteFail = createAction(
  '[Charter.Opportunity] Update Smart Sales Quote Fail'
);

export class AddSmartSalesQuotePayload {
  constructor(
    public opportunity: models.Opportunity,
    public salesQuotes: Array<models.SalesQuote>,
    public greetingText: string,
    public attributes: Array<number>
  ) {}
}
export const addSmartSalesQuote = createAction(
  '[Charter.Opportunity] Add Smart Sales Quote',
  props<{ payload: AddSmartSalesQuotePayload }>()
);
export class AddSmartSalesQuoteSuccessPayload {
  constructor(
    public opportunity: models.Opportunity,
    public smartSalesQuote: models.SmartSalesQuote
  ) {}
}
export const addSmartSalesQuoteSuccess = createAction(
  '[Charter.Opportunity] Add Smart Sales Quote Success',
  props<{ payload: AddSmartSalesQuoteSuccessPayload }>()
);

export class DeleteSalesQuotePayload {
  constructor(public salesQuoteId: string) {}
}
export const deleteSalesQuote = createAction(
  '[Charter.Opportunity] Delete Sales Quote',
  props<{ payload: DeleteSalesQuotePayload }>()
);
export const deleteSalesQuoteSuccess = createAction(
  '[Charter.Opportunity] Delete Sales Quote Success',
  props<{ payload: models.SalesQuote }>()
);
export const deleteSalesQuoteFail = createAction('[Charter.Opportunity] Delete Sales Quote Fail');

export class ConvertSalesQuoteToOrderPayload {
  constructor(public salesQuoteId: string) {}
}
export const convertSalesQuoteToOrder = createAction(
  '[Charter.Opportunity] Convert Sales Quote To Order',
  props<{ payload: ConvertSalesQuoteToOrderPayload }>()
);
export const convertSalesQuoteToOrderSuccess = createAction(
  '[Charter.Opportunity] Convert Sales Quote To Order Success',
  props<{ payload: models.SalesQuote }>()
);

export class AddSalesQuotePayload {
  constructor(
    public opportunity: models.Opportunity,
    public purchaseQuote: models.PurchaseQuote,
    public operator: models.Operator,
    public aircraftCategory: models.AircraftCategory,
    public aircraftModel: models.AircraftModel,
    public aircraftTailNumber: string,
    public aircraftYearOfManufacture: number,
    public aircraftYearOrRefurbishment: number,
    public aircraftAmenities: Array<models.AircraftAmenity>,
    public legs: Array<models.LegQuote>,
    public amount: number,
    public isProformaInvoice: boolean,
    public absoluteMarginSelected: boolean,
    public absoluteMargin: number,
    public percentageMargin: number,
    public nonMemberFee?: number,
    public serviceFee?: number
  ) {}
}
export const addSalesQuote = createAction(
  '[Charter.Opportunity] Add Sales Quote',
  props<{ payload: AddSalesQuotePayload }>()
);
export class AddSalesQuoteSuccessPayload {
  constructor(public opportunity: models.Opportunity, public quote: models.SalesQuote) {}
}
export const addSalesQuoteSuccess = createAction(
  '[Charter.Opportunity] Add Sales Quote Success',
  props<{ payload: AddSalesQuoteSuccessPayload }>()
);
export const addSalesQuoteFail = createAction('[Charter.Opportunity] Add Sales Quote Fail');

export class DeletePurchaseQuotePayload {
  constructor(public purchaseQuoteId: string) {}
}
export const deletePurchaseQuote = createAction(
  '[Charter.Opportunity] Delete Purchase Quote',
  props<{ payload: DeletePurchaseQuotePayload }>()
);
export const deletePurchaseQuoteSuccess = createAction(
  '[Charter.Opportunity] Delete Purchase Quote Success',
  props<{ payload: models.PurchaseQuote }>()
);
export const deletePurchaseQuoteFail = createAction(
  '[Charter.Opportunity] Delete Purchase Quote Fail'
);

export class UpdatePurchaseQuoteItineraryPayload {
  constructor(
    public purchaseQuoteId: string,
    public legs: Array<models.LegQuote>,
    public salesQuoteRequestId: string
  ) {}
}
export const updatePurchaseQuoteItinerary = createAction(
  '[Charter.Opportunity] Update Purchase Quote Itinerary',
  props<{ payload: UpdatePurchaseQuoteItineraryPayload }>()
);
export const updatePurchaseQuoteItinerarySuccess = createAction(
  '[Charter.Opportunity] Update Purchase Quote Itinerary Success',
  props<{ payload: models.PurchaseQuote }>()
);

export class UpdatePurchaseQuoteAircraftPayload {
  constructor(
    public purchaseQuoteId: string,
    public operator: models.Operator,
    public aircraftTailNumber: string,
    public aircraftModel: models.AircraftModel,
    public aircraftCategory: models.AircraftCategory,
    public aircraftAmenities: Array<models.AircraftAmenity>,
    public aircraftYearOfManufacture: number,
    public aircraftYearOrRefurbishment: number,
    public requiresOwnerApproval: boolean,
    public aircraftId: number,
    public uploadedFiles: Array<models.PdfFile>,
    public quoteAttributes: Array<models.QuoteAttribute>
  ) {}
}
export const updatePurchaseQuoteAircraft = createAction(
  '[Charter.Opportunity] Update Purchase Quote Aircraft',
  props<{ payload: UpdatePurchaseQuoteAircraftPayload }>()
);
export const updatePurchaseQuoteAircraftSuccess = createAction(
  '[Charter.Opportunity] Update Purchase Quote Aircraft Success',
  props<{ payload: models.PurchaseQuote }>()
);
export const updatePurchaseQuoteAircraftFail = createAction(
  '[Charter.Opportunity] Update Purchase Quote Aircraft Fail'
);

export class UpdateVendorQuoteIdPayload {
  constructor(
    public purchaseQuoteId: string,
    public vendorQuoteId: string,
    public vendorQuoteDate: Date
  ) {}
}
export const updateVendorQuoteId = createAction(
  '[Charter.Opportunity] Update Vendor Quote Id',
  props<{ payload: UpdateVendorQuoteIdPayload }>()
);
export const updateVendorQuoteIdSuccess = createAction(
  '[Charter.Opportunity] Update Vendor Quote Id Success',
  props<{ payload: models.PurchaseQuote }>()
);

export class UpdatePurchaseQuotePayload {
  constructor(
    public payload: viewModels.PurchaseQuoteFormViewModel,
    public sendSubcharterConfirmedFlag: boolean
  ) {}
}
export const updatePurchaseQuote = createAction(
  '[Charter.Opportunity] Update Purchase Quote',
  props<{ payload: UpdatePurchaseQuotePayload }>()
);
export const updatePurchaseQuoteSuccess = createAction(
  '[Charter.Opportunity] Update Purchase Quote Success',
  props<{ payload: models.PurchaseQuote }>()
);
export const updatePurchaseQuoteFail = createAction(
  '[Charter.Opportunity] Update Purchase Quote Fail'
);

export class ConfirmUncorfimSubCharterPayload {
  constructor(public payload: models.PurchaseQuote) {}
}
export const confirmUncorfimSubCharter = createAction(
  '[Charter.Opportunity] Confirm Uncorfim Sub Charter',
  props<{ payload: ConfirmUncorfimSubCharterPayload }>()
);
export const confirmUncorfimSubCharterSuccess = createAction(
  '[Charter.Opportunity] Confirm Uncorfim Sub Charter Success',
  props<{ payload: models.PurchaseQuote }>()
);
export const confirmUncorfimSubCharterFail = createAction(
  '[Charter.Opportunity] Confirm Uncorfim Sub Charter Fail'
);

export class UpdatePurchaseQuoteAmountPayload {
  constructor(public purchaseQuoteId: string, public amount: number, public currency: string) {}
}
export const updatePurchaseQuoteAmount = createAction(
  '[Charter.Opportunity] Update Purchase Quote Amount',
  props<{ payload: UpdatePurchaseQuoteAmountPayload }>()
);
export const updatePurchaseQuoteAmountSuccess = createAction(
  '[Charter.Opportunity] Update Purchase Quote Amount Success',
  props<{ payload: models.PurchaseQuote }>()
);

export class CreatePurchaseQuoteNotePayload {
  constructor(public purchaseQuoteId: string, public text: string) {}
}
export const createPurchaseQuoteNote = createAction(
  '[Charter.Opportunity] Create Purchase Quote Note',
  props<{ payload: CreatePurchaseQuoteNotePayload }>()
);
export const createPurchaseQuoteNoteSuccess = createAction(
  '[Charter.Opportunity] Create Purchase Quote Note Success',
  props<{ payload: models.PurchaseQuote }>()
);

export class UpdateOpportunityNotePayload {
  constructor(public opportunityId: string, public notes: string) {}
}
export const updateOpportunityNote = createAction(
  '[Charter.Opportunity] Update Opportunity Note',
  props<{ payload: UpdateOpportunityNotePayload }>()
);
export const updateOpportunityNoteSuccess = createAction(
  '[Charter.Opportunity] Update Opportunity Note Success',
  props<{ payload: models.Opportunity }>()
);

export class AddPurchaseQuotePayload {
  constructor(public opportunity: models.Opportunity, public quote: models.PurchaseQuote) {}
}
export const addPurchaseQuote = createAction(
  '[Charter.Opportunity] Add Purchase Quote',
  props<{ payload: AddPurchaseQuotePayload }>()
);
export class AddPurchaseQuoteSuccessPayload {
  constructor(public opportunity: models.Opportunity, public quote: models.PurchaseQuote) {}
}
export const addPurchaseQuoteSuccess = createAction(
  '[Charter.Opportunity] Add Purchase Quote Success',
  props<{ payload: AddPurchaseQuoteSuccessPayload }>()
);

export class DeleteSalesQuoteRequestPayload {
  constructor(public salesQuoteRequestId, public opportunityId) {}
}
export const deleteSalesQuoteRequest = createAction(
  '[Charter.Opportunity] Delete Sales Quote Request',
  props<{ payload: DeleteSalesQuoteRequestPayload }>()
);
export const deleteSalesQuoteRequestSuccess = createAction(
  '[Charter.Opportunity] Delete Sales Quote Request Success',
  props<{ payload: models.SalesQuoteRequest }>()
);
export const deleteSalesQuoteRequestFail = createAction(
  '[Charter.Opportunity] Delete Sales Quote Request Fail'
);

export class AddSalesQuoteRequestPayload {
  constructor(
    public salesQuoteRequest: models.SalesQuoteRequest,
    public opportunity: models.Opportunity
  ) {}
}
export const addSalesQuoteRequest = createAction(
  '[Charter.Opportunity] Add Sales Quote Request',
  props<{ payload: AddSalesQuoteRequestPayload }>()
);
export const addSalesQuoteRequestSuccess = createAction(
  '[Charter.Opportunity] Add Sales Quote Request Success',
  props<{ payload: models.SalesQuoteRequest }>()
);

export class SubmitRequisitionPurchaseQuotePayload {
  constructor(
    public purchaseQuoteId: string,
    public vendorQuoteId: string,
    public vendorQuoteDate: Date,
    public files: Array<models.FileData>
  ) {}
}
export const submitRequisitionPurchaseQuote = createAction(
  '[Charter.Opportunity] Submit Requisition Purchase Quote',
  props<{ payload: SubmitRequisitionPurchaseQuotePayload }>()
);
export const submitRequisitionPurchaseQuoteSuccess = createAction(
  '[Charter.Opportunity] Submit Requisition Purchase Quote Success',
  props<{ payload: models.PurchaseQuote }>()
);
export const submitRequisitionPurchaseQuoteFail = createAction(
  '[Charter.Opportunity] Submit Requisition Purchase Quote Fail',
  props<{ payload: string }>()
);
