import { User } from './user';
import * as moment from 'moment';

export class PriceCalculator {
  pax: number = null;
  actype: string;
  price: number;
  serviceFee: number;
  nonMemberFee: number;
  segmentFee: number;
  subtotal: number;
  fetTax: TaxFee;
  log: CalculationLog = null;
  segments: Array<PriceCalculatorSegment>;
}

export class PriceCalculatorSegment {
  id: string;
  depart: string;
  arrive: string;
  time: string;
  actype: string;
  pax: number = null;
  eft: number = null;
  price: number = null;
  priceInfo: PriceCalculatorInfo;
  taxFees: Array<TaxFee>;
  firmFlexSurcharge: number;
}

export class PriceCalculatorInfo {
  base: number;
  noodleToggle: number;
  departAP: number;
  arriveAP: number;
  departState: number;
  arriveState: number;
  challenger300?: number;
  calendarMsrp: string;
  msrp: string;
  msrpValue: number;
}

export class TaxFee {
  amount: number;
  unitPercentage: number;
}

export class CalculationLog {
  logId: string;
  user: User;
  createdTime: Date = null;
  input: any;

  toString(): string {
    const hasTimezone = this.input && this.input.userTimeZoneId;
    const timeZone = hasTimezone ? ` (${this.input.userTimeZoneId})` : '';
    const dateTime = hasTimezone ? moment(this.createdTime + 'Z').tz(this.input.userTimeZoneId) : moment(this.createdTime);
    const createdTime = this.createdTime ? ` at ${dateTime.format('YYYY-MM-DD HH:mm')}${timeZone}` : '';
    const user = this.user ? `created by ${this.user.firstName} ${this.user.lastName}` : '';
    return `${user} ${createdTime}`;
  }
}

export class PricingCalculatorLeg {
  originAirportCode: string = null;
  destinationAirportCode: string = null;
  departureDate: string = null;
  ferry: boolean = false;
}

export class SameAndSimilarData {
  dba: string;
  opportunityId: string;
  clientName: string;
  rate: number;
  paid: boolean;
  route: Array<PricingCalculatorLeg>;
}
