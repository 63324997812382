<ul class="">
  <li class="pagination-first page-item"
      *ngIf="boundaryLinks"
      [class.disabled]="noPrevious()||disabled">
    <a class="page-link" href (click)="selectPage(1, $event)" [innerHTML]="getText('first')"></a>
  </li>
  <li class="pagination-prev page-item"
      *ngIf="directionLinks"
      [class.disabled]="noPrevious()||disabled">
    <a class="page-link" href (click)="selectPage(page - 1, $event)">
      <i class="fal fa-angle-left"></i>
    </a>
  </li>
  <li *ngFor="let pg of pages"
      [class.active]="pg.active"
      [class.disabled]="disabled&&!pg.active"
      class="pagination-page page-item">
    <a class="page-link" href (click)="selectPage(pg.number, $event)" [innerHTML]="pg.text"></a>
  </li>
  <li class="pagination-next page-item"
      *ngIf="directionLinks"
      [class.disabled]="noNext()||disabled">
    <a class="page-link" href (click)="selectPage(page + 1, $event)">
      <i class="fal fa-angle-right"></i>
    </a>
  </li>
  <li class="pagination-last page-item"
      *ngIf="boundaryLinks"
      [class.disabled]="noNext()||disabled">
    <a class="page-link" href (click)="selectPage(totalPages, $event)" [innerHTML]="getText('last')"></a>
  </li>
</ul>
