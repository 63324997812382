import * as models from '../models';
import * as _ from 'lodash';

export const GetLegNewStatus = (
  legs: Array<models.TasksLeg>,
  legId: number,
  tasks: Array<models.Task>
): string => {
  const clone: Array<models.TasksLeg> = _.cloneDeep(legs);
  const legIndex = clone.findIndex((leg) => leg.legId === legId);
  const clonedTasks = clone[legIndex].tasks;
  tasks.forEach((task) => {
    const taskIndex = clonedTasks.findIndex((e) => e.taskId === task.taskId);
    if (taskIndex !== -1) {
      clonedTasks[taskIndex] = task;
    }
  });
  const currentLegStatus = clone[legIndex].statusDisplayName;
  const currentTasksStateId = clonedTasks.map((task) => task.state.taskStateId);
  if (currentTasksStateId.every((stateId) => stateId === models.StateEnum.TO_DO)) {
    return currentLegStatus !== 'Not Started' ? 'Not Started' : null;
  }

  if (currentTasksStateId.every((stateId) => stateId === models.StateEnum.DISCARDED)) {
    return currentLegStatus !== 'Canceled' ? 'Canceled' : null;
  }

  if (
    currentTasksStateId.some((stateId) => {
      return (
        stateId === models.StateEnum.IN_PROGRESS ||
        stateId === models.StateEnum.ON_HOLD ||
        stateId === models.StateEnum.REVIEW ||
        stateId === models.StateEnum.COMPLETED ||
        stateId === models.StateEnum.TO_DO
      );
    })
  ) {
    return currentLegStatus !== 'In progress' ? 'In progress' : null;
  }
  return null;
};
