import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as configServiceContracts from '../../../core/services/config/contracts';
import * as userServiceContracts from '../../../core/services/user/contracts';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(configServiceContracts.SERVICE_TOKEN) private configService: configServiceContracts.IConfigService,
    @Inject(userServiceContracts.USER_SERVICE_TOKEN) private userService: userServiceContracts.IUserService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modified = httpRequest;
    if (!httpRequest.headers.get('No-Authorization')) {
      modified = httpRequest.clone({ setHeaders: { 'Authorization': `Bearer ${this.configService.get('AuthorizationToken')}` } });
    }
    return next.handle(modified).pipe(map(event => event), catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        location.href = `${this.configService.get('IdentityProvider')}/login/?&tcs=${location.origin}/login&return_url=${encodeURIComponent(location.href)}`;
        // ToDo Update after implementing auth0 authorization in production
        this.userService.signOut({ isLegacyAuthInterceptorError: true });
        return EMPTY;
      }

      if(err.status === 403) {
        let errorMessage = 'Insufficient permissions to perform the requested action';
        if (err.error['errorId']) {
          errorMessage += ` Error Id: ${err.error['errorId']}`;
        } 
        return throwError(new HttpErrorResponse({ 
          error: new Error(errorMessage), 
          status: err.status 
        }));
      }

      return throwError(err);
    }));
  }
}
