import { ClientService } from './client-service';
import { ClientServiceTypeEnum } from './client-service-type';
import { MembershipOrderItem } from './membership-order-item';

export class MembershipClientService extends ClientService {
  membershipOrderItem: MembershipOrderItem = null;

  get clientServiceType(): ClientServiceTypeEnum {
    return ClientServiceTypeEnum.Membership;
  }
}
