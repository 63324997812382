import * as actions from '../actions/aircraft-availability';
import * as models from '../../domain/models';

export interface State {
  camberOptions: Array<models.CamberOption>;
  marketplaceOptions: Array<models.MarketplaceOption>;
  camberOptionsLoading: boolean;
  camberOptionsLoaded: boolean;
  marketplaceOptionsLoading: boolean;
  marketplaceOptionsLoaded: boolean;

}

const initialState: State = {
  camberOptions: [],
  marketplaceOptions: [],
  camberOptionsLoading: false,
  camberOptionsLoaded: false,
  marketplaceOptionsLoading: false,
  marketplaceOptionsLoaded: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_CAMBER_OPTIONS: {
      return { ...state, camberOptionsLoading: true };
    }

    case actions.LOAD_CAMBER_OPTIONS_SUCCESS: {
      return {
        ...state,
        camberOptionsLoaded: true,
        camberOptionsLoading: false,
        camberOptions: action.payload,
      };
    }

    case actions.LOAD_MARKETPLACE_OPTIONS: {
      return { ...state, marketplaceOptionsLoading: true };
    }

    case actions.LOAD_MARKETPLACE_OPTIONS_SUCCESS: {
      return {
        ...state,
        marketplaceOptionsLoaded: true,
        marketplaceOptionsLoading: false,
        marketplaceOptions: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCamberOptionsLoaded = (state: State) => state.camberOptionsLoaded;
export const getCamberOptionsLoading = (state: State) => state.camberOptionsLoading;
export const getCamberOptions = (state: State) => state.camberOptions;
export const getMarketplaceOptionsLoaded = (state: State) => state.marketplaceOptionsLoaded;
export const getMarketplaceOptionsLoading = (state: State) => state.marketplaceOptionsLoading;
export const getMarketplaceOptions = (state: State) => state.marketplaceOptions;
