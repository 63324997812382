<div class="container-xs-height full-height">
  <div class="row-xs-height">
    <div class="col col-xs-height col-middle">
      <div class="error-container text-center">
        <h1 class="error-number">404</h1>
        <h2 class="semi-bold">Page is not found</h2>
        <p>An error occurred and your request couldn't be completed. Please try again.</p>
      </div>
    </div>
  </div>
</div>
