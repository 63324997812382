import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales.TransitionCollection] Load');
export const loadSuccess = createAction(
  '[Sales.TransitionCollection] Load Success',
  props<{ payload: Array<models.Transition> }>()
);
export const loadFail = createAction('[Sales.TransitionCollection] Load Fail');
