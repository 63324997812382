import { createAction } from '@ngrx/store';
import * as utils from '../../../../core/utils';
import * as models from '../../models';

enum Actions {
  LOAD = '[Features.Plane Operators] Load',
  LOAD_SUCCESS = '[Features.Plane Operators] Load Success',
  LOAD_FAIL = '[Features.Plane Operators] Load Fail'
}

export class LoadSuccessPayload {
  planeOperators: Array<models.PlaneOperator>;
}

export const load = createAction(Actions.LOAD);
export const loadSuccess = createAction(Actions.LOAD_SUCCESS, utils.payload<LoadSuccessPayload>());
export const loadFailed = createAction(Actions.LOAD_FAIL);
