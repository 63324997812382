export class FlightPriceBreakdown {
  private operatorPrice: number;
  private flightPrice: number;
  private commission: number;
  private specialRate: boolean;

  constructor(operatorPrice?: number, flightPrice?: number, commission?: number, specialRate?: boolean) {
    this.operatorPrice = operatorPrice;
    this.flightPrice = flightPrice;
    this.commission = commission;
    this.specialRate = specialRate;
  }

  getOperatorPrice(): number {
    return this.operatorPrice;
  }

  setOperatorPrice(operatorPrice: number): FlightPriceBreakdown {
    this.operatorPrice = operatorPrice;
    return this;
  }

  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): FlightPriceBreakdown {
    this.flightPrice = flightPrice;
    return this;
  }

  getCommission(): number {
    return this.commission;
  }

  setCommission(commission: number): FlightPriceBreakdown {
    this.commission = commission;
    return this;
  }

  getSpecialRate(): boolean {
    return this.specialRate;
  }

  setSpecialRate(specialRate: boolean): FlightPriceBreakdown {
    this.specialRate = specialRate;
    return this;
  }
}
