import * as models from '../../../../domain/models';
import * as sharedTypes from '../../../../../../shared/types';
import * as viewModels from '../../../../view/view-models';

import * as _ from 'lodash';

export class OrderListMapper {
  static parseOrder(item: any): viewModels.OrderVM {
    const order = new viewModels.OrderVM();
    order.id = _.get(item, 'orderId', null);
    order.legs = _.get(item, 'legs', null);
    order.orderStatusId = _.get(item, 'orderStatusId', null);
    order.orderStatusName = _.get(item, 'orderStatusName', null);
    order.orderSourceId = Number(item.orderSourceId);
    order.partyId = _.get(item, 'partyId', null);
    order.partyName = _.get(item, 'partyName', null);
    order.invoiceUIPaymentStatusId = _.get(item, 'invoiceUiPaymentStatus', null);
    order.partyLegalName = _.get(item, 'partyLegalName', null);
    order.paymentConfirmed = _.get(item, 'paymentConfirmed', null);
    order.userId = _.get(item, 'userId', null);
    order.canCancelOrder = _.get(item, 'canCancelOrder', null);
    order.userName = _.get(item, 'userName', null);
    order.totalPrice = sharedTypes.Util.nvlToNumber(item.totalPrice);
    order.createTime = new Date(item.createTime);
    order.sapExportStatus = item.sapExportStatus
      ? item.sapExportStatus.map((status) =>
          models.SapExportStatus.getStatus(
            status.statusId,
            status.statusDescription,
            status.submissionTypeId
          )
        )
      : null;
    order.cellItems = _.get(item, 'items', []).map((e) =>
      this.parseCellItems(e, _.cloneDeep(order))
    );
    order.orderData = _.cloneDeep(order);
    order.invoices = _.get(item, 'invoices', []).map((i) => this.parseInvoice(i));
    return order;
  }

  static parseCellItems(item: any, order: viewModels.OrderVM): viewModels.OrderCellItemVM {
    const cellItem = new viewModels.OrderCellItemVM();
    cellItem.orderItemId = _.get(item, 'cellId', null);
    cellItem.order = order;
    cellItem.name = _.get(item, 'cellName', null);
    cellItem.person = _.get(item, 'cellPerson', null);
    cellItem.personLegal = _.get(item, 'cellPersonLegal', null);
    cellItem.personId = _.get(item, 'cellPersonId', null);
    cellItem.cellIsProduct = _.get(item, 'cellIsProduct', null);
    cellItem.cellIsTotal = _.get(item, 'cellIsTotal', null);
    cellItem.parentOrderItemId = _.get(item, 'parentOrderItemId', null);
    cellItem.productTypeId = _.get(item, 'productTypeId', null);
    cellItem.productCellData = {
      name: _.get(cellItem, 'name', null),
      cellIsProduct: _.get(cellItem, 'cellIsProduct', null),
      cellIsTotal: _.get(cellItem, 'cellIsTotal', null),
      parentOrderItemId: _.get(cellItem, 'parentOrderItemId', null),
    };
    cellItem.personCellData = {
      name: _.get(cellItem, 'person', null),
      legalName: _.get(cellItem, 'personLegal', null),
      id: _.get(cellItem, 'personId', null),
    };
    if (item.membership) {
      cellItem.membership = this.parseMembership(item.membership);
    }
    if (item.membershipRequest) {
      cellItem.membershipRequest = this.parseMembershipRequest(item.membershipRequest);
    }
    cellItem.amount = sharedTypes.Util.nvlToNumber(item.cellAmount);
    cellItem.amountCellData = _.cloneDeep(cellItem);
    return cellItem;
  }

  static parseMembership(item: any): models.MembershipClientService {
    const membership = new models.MembershipClientService();
    membership.product = new models.Product();
    membership.product.name = _.get(item, 'typeName', null);
    membership.clientServiceId = _.get(item, 'id', null);
    membership.startDate = new Date(_.get(item, 'startDate', null));
    membership.endDate = new Date(_.get(item, 'endDate', null));
    return membership;
  }

  static parseMembershipRequest(item: any): models.MembershipOrderItem {
    const i = new models.MembershipOrderItem();
    i.product = new models.Product();
    i.product.name = _.get(item, 'productName', null);
    i.expirationTime = new Date(item.expirationTime);
    i.createTime = new Date(item.createTime);
    i.expired = _.get(item, 'expired', null);
    i.salesAction = Number(item.salesActionId);
    return i;
  }

  static parseInvoice(item: any): viewModels.InvoiceVM {
    const invoice = new viewModels.InvoiceVM();
    invoice.id = _.get(item, 'invoiceId', null);
    invoice.orderId = _.get(item, 'orderId', null);
    invoice.statusId = _.get(item, 'invoiceStatusId', null);
    invoice.amount = sharedTypes.Util.nvlToNumber(item.amount);
    invoice.uuid = _.get(item, 'uuid', null);
    invoice.legacyFormUrlKey = _.get(item, 'legacyFormUrlKey', null);
    invoice.legacyFormUrl = _.get(item, 'legacyFormUrl', null);
    invoice.legacyFormTplId = _.get(item, 'legacyFormTplId', null);
    invoice.externalProfileLink = _.get(item, 'externalProfileLink', null);
    invoice.createTime = new Date(item.createTime);
    invoice.orderSourceId = Number(item.orderSourceId);
    invoice.invoiceTypeName = _.get(item, 'invoiceTypeName', null);
    if (_.get(item, 'invoiceReplacements', [])?.length) {
      invoice.invoiceReplacements = item.invoiceReplacements.map((ir) => {
        const ir1 = new models.InvoiceReplacement();
        ir1.formTplId = _.get(ir, 'formTplId', null);
        ir1.name = _.get(ir, 'name', null);
        return ir1;
      });
    }
    if (_.get(item, 'client', null)) {
      invoice.client = this.parseInvoiceClient(item.client);
    }
    invoice.self = _.cloneDeep(invoice);
    return invoice;
  }

  static parseInvoiceClient(item: any): models.Client {
    const client = new models.Client();
    client.id = _.get(item, 'id', null);
    client.name = _.get(item, 'name', null);
    client.legalName = _.get(item, 'legalName', null);
    client.dob = _.get(item, 'dob', null);
    client.email = _.get(item, 'email', null);
    client.phone = _.get(item, 'phone', null);
    return client;
  }
}
