import * as models from '../models';
import { ProductFeatureType } from '../models';
import * as _ from 'lodash';

export class OrderService {
  getOrderTotalPrice(order: models.Order): number {
    let amount = 0;

    for (const orderItem of order.items) {
      amount += this.getOrderItemTotalPrice(orderItem);
    }

    return amount;
  }

  getOrderItemTotalPrice(orderItem: models.OrderItem): number {
    switch (orderItem.orderItemType) {
      case models.OrderItemTypeEnum.GroupMembership:
      case models.OrderItemTypeEnum.GroupMembershipSlot:
      case models.OrderItemTypeEnum.Membership:
      case models.OrderItemTypeEnum.Product:
        return this.getProductOrderItemTotalPriceInternal(orderItem as models.ProductOrderItem);
      case models.OrderItemTypeEnum.Adjustment:
      case models.OrderItemTypeEnum.LegacyAdjustment:
      case models.OrderItemTypeEnum.CustomTerm:
      case models.OrderItemTypeEnum.FlightCredit:
      case models.OrderItemTypeEnum.SFDCDepositDue:
      case models.OrderItemTypeEnum.AnnualMembershipFee:
      case models.OrderItemTypeEnum.CashCreditTransfer:
      case models.OrderItemTypeEnum.BonusCreditTransfer:
      case models.OrderItemTypeEnum.PromotionalCredit:
      case models.OrderItemTypeEnum.LoyaltyCredit:
        return this.getOrderAdjustmentOrderItemTotalPriceInternal(orderItem);
      case models.OrderItemTypeEnum.Concierge:
        return this.getOrderConciergeOrderItemTotalPriceInternal(orderItem);
      case models.OrderItemTypeEnum.Charter:
        return orderItem.totalPrice;
      case models.OrderItemTypeEnum.FlightPass:
        return orderItem.totalPrice;
      default:
        throw new Error('Not Implemented');
    }
  }

  getOrderItemRefundableTotalPrice(orderItem: models.OrderItem): number {
    switch (orderItem.orderItemType) {
      case models.OrderItemTypeEnum.GroupMembership:
      case models.OrderItemTypeEnum.GroupMembershipSlot:
      case models.OrderItemTypeEnum.Membership:
      case models.OrderItemTypeEnum.Product:
        return this.getProductOrderItemRefundableTotalAmountInternal(
          orderItem as models.ProductOrderItem
        );
      case models.OrderItemTypeEnum.Adjustment:
      case models.OrderItemTypeEnum.Concierge:
      case models.OrderItemTypeEnum.LegacyAdjustment:
      case models.OrderItemTypeEnum.CustomTerm:
      case models.OrderItemTypeEnum.FlightCredit:
      case models.OrderItemTypeEnum.Charter:
      case models.OrderItemTypeEnum.SFDCDepositDue:
        return 0;
      default:
        throw new Error('Not Implemented');
    }
  }

  getOrderItemNeedsApproval(orderItem: models.OrderItem): boolean {
    switch (orderItem.orderItemType) {
      case models.OrderItemTypeEnum.GroupMembership:
      case models.OrderItemTypeEnum.GroupMembershipSlot:
      case models.OrderItemTypeEnum.Membership:
      case models.OrderItemTypeEnum.Product:
        return (
          ((orderItem as models.ProductOrderItem).product &&
            (orderItem as models.ProductOrderItem).product.securityAccess !==
              models.SecurityAccessEnum.Granted) ||
          (orderItem.discount &&
            orderItem.discount.securityAccess !== models.SecurityAccessEnum.Granted)
        );
      case models.OrderItemTypeEnum.Adjustment:
      case models.OrderItemTypeEnum.Concierge:
      case models.OrderItemTypeEnum.FlightPass:
      case models.OrderItemTypeEnum.Charter:
      case models.OrderItemTypeEnum.LegacyAdjustment:
      case models.OrderItemTypeEnum.CustomTerm:
      case models.OrderItemTypeEnum.FlightCredit:
      case models.OrderItemTypeEnum.PromotionalCredit:
      case models.OrderItemTypeEnum.LoyaltyCredit:
      case models.OrderItemTypeEnum.SFDCDepositDue:
      case models.OrderItemTypeEnum.AnnualMembershipFee:
      case models.OrderItemTypeEnum.CashCreditTransfer:
      case models.OrderItemTypeEnum.BonusCreditTransfer:
        return false;
      default:
        throw new Error('Not Implemented');
    }
  }

  getProductOrderItemTotalPriceInternal(orderItem: models.ProductOrderItem): number {
    let amount = 0;

    if (orderItem.product === null) {
      return 0;
    }

    if (orderItem.promotionAmount !== null) {
      amount += orderItem.promotionAmount;
    } else {
      const isVariableMoney = orderItem.product.productFeatures.find(
        (f) => f.typeId === models.ProductFeatureType.IsVariableMoney
      );
      if (!isVariableMoney) {
        amount += orderItem.product.amount * orderItem.quantity;
      }
    }

    if (orderItem.discount !== null) {
      if (orderItem.discount.amount !== null) {
        amount -= orderItem.discount.amount * orderItem.quantity;
      } else if (orderItem.discount.percentage !== null && orderItem.product) {
        amount -=
          (orderItem.product.amount * orderItem.quantity * orderItem.discount.percentage) / 100;
      }
    }

    if (orderItem.surcharge !== null) {
      if (orderItem.surcharge.amount !== null) {
        amount += orderItem.surcharge.amount * orderItem.quantity;
      } else if (orderItem.surcharge.percentage !== null && orderItem.product) {
        amount +=
          (orderItem.product.amount * orderItem.quantity * orderItem.surcharge.percentage) / 100;
      }
    }

    for (const childOrderItem of orderItem.childOrderItems) {
      if (
        childOrderItem.orderItemType === models.OrderItemTypeEnum.LoyaltyCredit ||
        childOrderItem.orderItemType === models.OrderItemTypeEnum.FlightCredit ||
        childOrderItem.orderItemType === models.OrderItemTypeEnum.PromotionalCredit ||
        childOrderItem.orderItemType === models.OrderItemTypeEnum.CashCreditTransfer ||
        childOrderItem.orderItemType === models.OrderItemTypeEnum.BonusCreditTransfer
      ) {
        if (childOrderItem['modifiesTotal']) {
          amount += childOrderItem.totalPrice;
        }
      } else {
        amount += childOrderItem.totalPrice;
      }
    }

    return Number(amount.toFixed(2));
  }

  getOrderAdjustmentOrderItemTotalPriceInternal(orderItem: models.OrderItem): number {
    let amount = 0;

    // if (this.promotionItem !== null) {
    //   if (this.promotionItem.discountAmount !== null) {
    //     amount -= this.promotionItem.discountAmount;
    //   } else if (this.promotionItem.discountPercentage !== null) {
    //     amount -= this.product.amount * this.promotionItem.discountPercentage / 100;
    //   }
    // }
    //
    // if (this.discount !== null) {
    //   if (this.discount.amount !== null) {
    //     amount -= this.discount.amount;
    //   } else if (this.discount.percentage !== null && this.product) {
    //     amount -= this.product.amount * this.discount.percentage / 100;
    //   }
    // }
    //
    // for (const childOrderItem of this.childOrderItems) {
    //   amount += childOrderItem.totalPrice;
    // }

    if (orderItem.orderItemType === models.OrderItemTypeEnum.Adjustment) {
      const oi1 = orderItem as models.OrderAdjustmentOrderItem;
      amount -= oi1.adjustedAmount ? oi1.adjustedAmount : 0;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.LegacyAdjustment) {
      const oi2 = orderItem as models.OrderAdjustmentOrderItem;
      amount -= oi2.adjustedAmount ? oi2.adjustedAmount : 0;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.CustomTerm) {
      const oi3 = orderItem as models.CustomTermOrderItem;
      amount = oi3.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.FlightCredit) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.PromotionalCredit) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.LoyaltyCredit) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.SFDCDepositDue) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.AnnualMembershipFee) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.CashCreditTransfer) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    } else if (orderItem.orderItemType === models.OrderItemTypeEnum.BonusCreditTransfer) {
      const oi4 = orderItem as models.CustomTermOrderItem;
      amount = oi4.totalPrice;
    }

    return Number(amount.toFixed(2));
  }
  getOrderConciergeOrderItemTotalPriceInternal(orderItem: models.OrderItem): number {
    let amount = 0;

    if (
      orderItem.orderItemType === models.OrderItemTypeEnum.Concierge &&
      (orderItem as models.ConciergeOrderItem).quoteAmount !== null &&
      (orderItem as models.ConciergeOrderItem).markupPercent !== null
    ) {
      const oi1 = orderItem as models.ConciergeOrderItem;
      // Makes sure it is always down to 2 decimals
      amount =
        Math.round(((oi1.quoteAmount * (oi1.markupPercent + 100)) / 100 + 0.00001) * 100) / 100;

      // if (oi1.isCreditMemo && Number(oi1.creditMethod) === 1) {
        // amount = -amount;
      // }

      // if ((orderItem as models.ConciergeOrderItem).surcharge !== null) {
      //   amount = Math.round((
      //     (amount * (oi1.surcharge.percentage + 100) / 100)
      //     + 0.00001) * 100) / 100;
      // }

      if ((orderItem as models.ConciergeOrderItem).serviceDiscountAmount) {
        amount -= (orderItem as models.ConciergeOrderItem).serviceDiscountAmount;
      }

      if ((orderItem as models.ConciergeOrderItem).cateringCredit && orderItem.product) {
        const isConcierge = orderItem.product.productFeatures.find(
          ({ typeId }) => typeId === ProductFeatureType.ConciergeVendorTypeId
        );
        if (isConcierge && isConcierge.value === '2') {
          amount -= (orderItem as models.ConciergeOrderItem).cateringCredit.amount;
        }
      }

      if ((orderItem as models.ConciergeOrderItem).discount) {
        amount -= (orderItem as models.ConciergeOrderItem).discount.amount;
      }
    }

    if (amount < 0 && !(orderItem as models.ConciergeOrderItem).isCreditMemo) {
      amount = 0;
    }

    return Number(amount.toFixed(2));
  }

  getProductOrderItemRefundableTotalAmountInternal(orderItem: models.ProductOrderItem): number {
    // if (orderItem.originalTotalPriceLost) {
    //   return orderItem.refundableAmount ? orderItem.refundableAmount : 0;
    // }

    let amount = 0;

    if (orderItem.product === null || orderItem.product.isRefundable === false) {
      return 0;
    }

    amount +=
      orderItem.promotionAmount === null ? orderItem.product.amount : orderItem.promotionAmount;

    // if (orderItem.promotionItem !== null) {
    //   if (orderItem.promotionItem.discountAmount !== null) {
    //     amount -= orderItem.promotionItem.discountAmount;
    //   } else if (orderItem.promotionItem.discountPercentage !== null) {
    //     amount -= orderItem.product.amount * orderItem.promotionItem.discountPercentage / 100;
    //   }
    // }

    if (orderItem.discount !== null) {
      if (orderItem.discount.amount !== null) {
        amount -= orderItem.discount.amount;
      } else if (orderItem.discount.percentage !== null && orderItem.product) {
        amount -= (orderItem.product.amount * orderItem.discount.percentage) / 100;
      }
    }

    if (orderItem.surcharge !== null) {
      if (orderItem.surcharge.amount !== null) {
        amount += orderItem.surcharge.amount;
      } else if (orderItem.surcharge.percentage !== null && orderItem.product) {
        amount += (orderItem.product.amount * orderItem.surcharge.percentage) / 100;
      }
    }

    // if (orderItem.totalPrice < amount) {
    //   return Number(orderItem.totalPrice.toFixed(2));
    // }

    return Number(amount.toFixed(2));
  }

  parseOrderItemInternal(orderItemEl: any): models.OrderItem {
    const orderItemType = Number(orderItemEl.orderItemTypeId);

    let orderItem = null;

    switch (orderItemType) {
      case models.OrderItemTypeEnum.Membership:
        orderItem = this.parseMembershipOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.Product:
        orderItem = this.parseProductOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.LegacyAdjustment:
        orderItem = this.parseLegacyAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.Adjustment:
        orderItem = this.parseAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.FlightCredit:
        orderItem = this.parseFlightCreditAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.PromotionalCredit:
        orderItem = this.parsePromotionCreditAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.LoyaltyCredit:
        orderItem = this.parseLoyaltyCreditAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.GroupMembership:
        orderItem = this.parseGroupMembershipOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.CustomTerm:
        orderItem = this.parseCustomOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.Concierge:
        orderItem = this.parseConciergeOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.Charter:
        orderItem = this.parseCharterOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.FlightPass:
        orderItem = this.parseFlightPassOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.SFDCDepositDue:
        orderItem = this.parseSfdcDepositAdjustmentOrderItemInternal(orderItemEl);
        break;

      case models.OrderItemTypeEnum.AnnualMembershipFee:
        orderItem = this.parseSfdcAnnualMembershipFeeOrderItemInternal(orderItemEl);
        break;

        case models.OrderItemTypeEnum.CashCreditTransfer:
        orderItem = this.parseSfdcCashCreditTransferOrderItemInternal(orderItemEl);
        break;

        case models.OrderItemTypeEnum.BonusCreditTransfer:
        orderItem = this.parseSfdcBonusCreditTransferOrderItemInternal(orderItemEl);
        break;

      default:
        throw new Error(
          `Order Item Type: ${orderItemType} is not supported in getOrder.parseOrderItemInternal`
        );
    }

    return orderItem;
  }

  parseMembershipOrderItemInternal(orderItemEl: any): models.MembershipOrderItem {
    const orderItem = new models.MembershipOrderItem();
    orderItem.id = Number(orderItemEl.id);

    orderItem.salesAction =
      orderItemEl.membershipRequest && orderItemEl.membershipRequest.salesAction
        ? Number(orderItemEl.membershipRequest.salesAction.id)
        : models.SalesActionEnum.New;
    const product1 = new models.Product();
    product1.id = Number(orderItemEl.product.productId);
    product1.name = orderItemEl.product.productName;
    product1.amount = orderItemEl.basePrice;
    orderItem.product = product1;

    const c1 = new models.Client();
    c1.id = Number(orderItemEl.client.id);
    c1.name = orderItemEl.client.name;
    c1.legalName = orderItemEl.client.legalName;
    c1.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c1;

    if (orderItemEl.promotionItemId) {
      const promotionItem = new models.PromotionItem();
      promotionItem.id = Number(orderItemEl.promotionItemId);

      orderItem.promotionItem = promotionItem;
    }

    if (orderItemEl.discount) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.discount.name;
      priceComponent.typeId = Number(orderItemEl.discount.typeId);
      priceComponent.typeName = orderItemEl.discount.typeName;

      if (orderItemEl.discount.percentage) {
        priceComponent.percentage = Number(orderItemEl.discount.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.discount.amount);
      }

      orderItem.discount = priceComponent;
    }

    if (orderItemEl.surcharge) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.surcharge.name;
      priceComponent.typeId = Number(orderItemEl.surcharge.typeId);
      priceComponent.typeName = orderItemEl.surcharge.typeName;

      if (orderItemEl.surcharge.percentage) {
        priceComponent.percentage = Number(orderItemEl.surcharge.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.surcharge.amount);
      }

      orderItem.surcharge = priceComponent;
    }

    if (orderItemEl.promotionAmount) {
      orderItem.promotionAmount = Number(orderItemEl.promotionAmount);
    }

    // if (orderItemEl.endDate) {
    //   orderItem.endDate = orderItemEl.endDate;
    // }

    return orderItem;
  }

  parseProductOrderItemInternal(orderItemEl: any): models.ProductOrderItem {
    const orderItem = new models.ProductOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);
    orderItem.quantity = Number(orderItemEl.quantity);

    const product2 = new models.Product();
    product2.id = Number(orderItemEl.product.productId);
    product2.name = orderItemEl.product.productName;
    product2.amount = orderItemEl.basePrice;
    orderItem.product = product2;

    const c2 = new models.Client();
    c2.id = Number(orderItemEl.client.id);
    c2.name = orderItemEl.client.name;
    c2.legalName = orderItemEl.client.legalName;
    c2.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c2;

    if (orderItemEl.discount) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.discount.name;
      priceComponent.typeId = Number(orderItemEl.discount.typeId);
      priceComponent.typeName = orderItemEl.discount.typeName;

      if (orderItemEl.discount.percentage) {
        priceComponent.percentage = Number(orderItemEl.discount.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.discount.amount);
      }

      orderItem.discount = priceComponent;
    }

    if (orderItemEl.surcharge) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.surcharge.name;
      priceComponent.typeId = Number(orderItemEl.surcharge.typeId);
      priceComponent.typeName = orderItemEl.surcharge.typeName;

      if (orderItemEl.surcharge.percentage) {
        priceComponent.percentage = Number(orderItemEl.surcharge.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.surcharge.amount);
      }

      orderItem.surcharge = priceComponent;
    }

    // Needs to be Removed
    orderItem.salesAction =
      orderItemEl.membershipRequest && orderItemEl.membershipRequest.salesAction
        ? Number(orderItemEl.membershipRequest.salesAction.id)
        : null;

    if (orderItemEl.promotionAmount) {
      orderItem.promotionAmount = Number(orderItemEl.promotionAmount);
    }

    // if (orderItemEl.endDate) {
    //   orderItem.endDate = orderItemEl.endDate;
    // }

    return orderItem;
  }

  parseFlightPassOrderItemInternal(orderItemEl: any): models.FlightPassOrderItem {
    const orderItem = new models.FlightPassOrderItem();

    orderItem.id = Number(orderItemEl.orderItemId);
    const product2 = new models.Product();
    product2.id = Number(orderItemEl.product.productId);
    product2.name = orderItemEl.product.productName;
    product2.amount = orderItemEl.basePrice;
    orderItem.product = product2;

    const c2 = new models.Client();
    c2.id = Number(orderItemEl.client.id);
    c2.name = orderItemEl.client.name;
    c2.legalName = orderItemEl.client.legalName;
    c2.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c2;
    // TODO remove that line as soon as Ruibin fix it on BE side
    const flightPassInfo =
      orderItemEl.flightPassInfo && orderItemEl.flightPassInfo.length
        ? orderItemEl.flightPassInfo[0]
        : orderItemEl.flightPassInfo || {};
    orderItem.route = flightPassInfo.route;
    orderItem.qtyOfPasses = flightPassInfo.qtyOfPasses;
    orderItem.faceValue = flightPassInfo.faceValue;
    orderItem.totalPrice = flightPassInfo.totalPrice;
    orderItem.salesAction =
      orderItemEl.membershipRequest && orderItemEl.membershipRequest.salesAction
        ? Number(orderItemEl.membershipRequest.salesAction.id)
        : null;

    return orderItem;
  }

  parseLegacyAdjustmentOrderItemInternal(orderItemEl: any): models.LegacyAdjustmentOrderItem {
    const orderItem = new models.LegacyAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    /* TODO: this is workaround - consider all adjustedOrderItems as Product Order Items
       service needs to return complete order item*/
    // const adjustedOrderItem1 = new models.MembershipOrderItem();
    const clientService1 = new models.LegacyMembershipClientService();

    const product3 = new models.Product();
    product3.name = orderItemEl.clientService.product.name;
    clientService1.product = product3;

    clientService1.startDate = new Date(orderItemEl.clientService.startDate);
    clientService1.endDate = new Date(orderItemEl.clientService.endDate);
    // adjustedOrderItem1.membershipClientService = clientService1;

    orderItem.clientService = clientService1;
    // orderItem.adjustedOrderItem = adjustedOrderItem1;
    orderItem.adjustedAmount = Number(orderItemEl.adjustedAmount);

    return orderItem;
  }

  parseAdjustmentOrderItemInternal(orderItemEl: any): models.OrderAdjustmentOrderItem {
    const orderItem = new models.OrderAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    /* TODO: this is workaround - consider all adjustedOrderItems as Product Order Items
       service needs to return complete order item*/
    const adjustedOrderItem2 = new models.MembershipOrderItem();
    const clientService2 = new models.MembershipClientService();

    const product4 = new models.Product();
    product4.name = orderItemEl.clientService.product.name;
    clientService2.product = product4;

    clientService2.startDate = new Date(orderItemEl.clientService.startDate);
    clientService2.endDate = new Date(orderItemEl.clientService.endDate);
    adjustedOrderItem2.membershipClientService = clientService2;
    adjustedOrderItem2.product = product4;
    adjustedOrderItem2.salesAction = orderItemEl.adjustedOrderItem.salesAction.id;

    orderItem.adjustedOrderItem = adjustedOrderItem2;
    orderItem.adjustedAmount = Number(orderItemEl.adjustedAmount);

    return orderItem;
  }

  parseFlightCreditAdjustmentOrderItemInternal(
    orderItemEl: any
  ): models.FlightCreditAdjustmentOrderItem {
    const orderItem = new models.FlightCreditAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    orderItem.flightCreditAmount = Number(orderItemEl.flightCreditsAmount);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);

    return orderItem;
  }

  parseLoyaltyCreditAdjustmentOrderItemInternal(
    orderItemEl: any
  ): models.LoyaltyCreditAdjustmentOrderItem {
    const orderItem = new models.LoyaltyCreditAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    orderItem.loyaltyCreditAmount = Number(orderItemEl.loyaltyCreditsAmount);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);

    return orderItem;
  }

  parseSfdcDepositAdjustmentOrderItemInternal(
    orderItemEl: any
  ): models.SfdcDepositAdjustmentOrderItem {
    const orderItem = new models.SfdcDepositAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    orderItem.sfdcDepositAmount = Number(orderItemEl.sfdcDepositAmount);
    orderItem.sfdcDepositAdjustment = Number(orderItemEl.sfdcDepositAdjustment);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);
    orderItem.replacesTotal = orderItemEl.replacesTotal;

    return orderItem;
  }

  parseSfdcAnnualMembershipFeeOrderItemInternal(
    orderItemEl: any
  ): models.SfdcAnnualMembershipFeeOrderItem {
    const orderItem = new models.SfdcAnnualMembershipFeeOrderItem();
    orderItem.id = _.get(orderItemEl, 'orderItemId', null)
      ? Number(_.get(orderItemEl, 'orderItemId', null))
      : null;
    orderItem.sfdcAnnualMembershipFee = _.get(orderItemEl, 'sfdcAnnualMembershipFee', null)
      ? Number(_.get(orderItemEl, 'sfdcAnnualMembershipFee', null))
      : null;
    orderItem.totalPrice = _.get(orderItemEl, 'totalPrice', null)
      ? Number(_.get(orderItemEl, 'totalPrice', null))
      : null;

    return orderItem;
  }

  parseSfdcCashCreditTransferOrderItemInternal(
    orderItemEl: any
  ): models.SfdcCashCreditTransferOrderItem {
    const orderItem = new models.SfdcCashCreditTransferOrderItem();
    orderItem.id = _.get(orderItemEl, 'orderItemId', null)
      ? Number(_.get(orderItemEl, 'orderItemId', null))
      : null;
    orderItem.sfdcCashCreditTransfer = _.get(orderItemEl, 'sfdcCashCreditTransfer', null)
      ? Number(_.get(orderItemEl, 'sfdcCashCreditTransfer', null))
      : null;
    orderItem.totalPrice = _.get(orderItemEl, 'totalPrice', null)
      ? Number(_.get(orderItemEl, 'totalPrice', null))
      : null;

    return orderItem;
  }

  parseSfdcBonusCreditTransferOrderItemInternal(
    orderItemEl: any
  ): models.SfdcBonusCreditTransferOrderItem {
    const orderItem = new models.SfdcBonusCreditTransferOrderItem();
    orderItem.id = _.get(orderItemEl, 'orderItemId', null)
      ? Number(_.get(orderItemEl, 'orderItemId', null))
      : null;
    orderItem.sfdcBonusCreditTransfer = _.get(orderItemEl, 'sfdcBonusCreditTransfer', null)
      ? Number(_.get(orderItemEl, 'sfdcBonusCreditTransfer', null))
      : null;
    orderItem.totalPrice = _.get(orderItemEl, 'totalPrice', null)
      ? Number(_.get(orderItemEl, 'totalPrice', null))
      : null;

    return orderItem;
  }

  parsePromotionCreditAdjustmentOrderItemInternal(
    orderItemEl: any
  ): models.PromotionCreditAdjustmentOrderItem {
    const orderItem = new models.PromotionCreditAdjustmentOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    orderItem.promotionalCreditAmount = Number(orderItemEl.promotionalCreditsAmount);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);

    return orderItem;
  }

  parseGroupMembershipOrderItemInternal(orderItemEl: any): models.GroupMembershipOrderItem {
    const orderItem = new models.GroupMembershipOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);

    orderItem.salesAction =
      orderItemEl.membershipRequest && orderItemEl.membershipRequest.salesAction
        ? Number(orderItemEl.membershipRequest.salesAction.id)
        : models.SalesActionEnum.New;

    const product = new models.Product();
    product.id = Number(orderItemEl.product.productId);
    product.name = orderItemEl.product.productName;
    product.amount = orderItemEl.basePrice;
    orderItem.product = product;

    const c3 = new models.Client();
    c3.id = Number(orderItemEl.client.id);
    c3.name = orderItemEl.client.name;
    c3.legalName = orderItemEl.client.legalName;
    c3.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c3;

    if (orderItemEl.discount) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.discount.name;
      priceComponent.typeId = Number(orderItemEl.discount.typeId);
      priceComponent.typeName = orderItemEl.discount.typeName;

      if (orderItemEl.discount.percentage) {
        priceComponent.percentage = Number(orderItemEl.discount.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.discount.amount);
      }

      orderItem.discount = priceComponent;
    }

    if (orderItemEl.surcharge) {
      const priceComponent = new models.PriceComponent();
      priceComponent.name = orderItemEl.surcharge.name;
      priceComponent.typeId = Number(orderItemEl.surcharge.typeId);
      priceComponent.typeName = orderItemEl.surcharge.typeName;

      if (orderItemEl.surcharge.percentage) {
        priceComponent.percentage = Number(orderItemEl.surcharge.percentage);
      } else {
        priceComponent.amount = Number(orderItemEl.surcharge.amount);
      }

      orderItem.surcharge = priceComponent;
    }

    if (orderItemEl.promotionAmount) {
      orderItem.promotionAmount = Number(orderItemEl.promotionAmount);
    }

    // if (orderItemEl.endDate) {
    //   orderItem.endDate = orderItemEl.endDate;
    // }

    return orderItem;
  }

  parseCustomOrderItemInternal(orderItemEl: any): models.CustomTermOrderItem {
    const orderItem = new models.CustomTermOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);
    orderItem.termMonths = Number(orderItemEl.termMonths);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);

    return orderItem;
  }

  parseCharterOrderItemInternal(orderItemEl: any): models.CharterOrderItem {
    const orderItem = new models.CharterOrderItem();
    orderItem.id = Number(orderItemEl.orderItemId);
    orderItem.totalPrice = Number(orderItemEl.totalPrice);

    const product2 = new models.Product();
    product2.id = Number(orderItemEl.product.productId);
    product2.name = orderItemEl.product.productName;
    product2.amount = Math.round((Number(orderItemEl.basePrice) + 0.00001) * 100) / 100;
    orderItem.product = product2;

    const c2 = new models.Client();
    c2.id = Number(orderItemEl.client.id);
    c2.name = orderItemEl.client.name;
    c2.legalName = orderItemEl.client.legalName;
    c2.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c2;

    if (orderItemEl.charterInfo) {
      orderItem.requestId = Number(orderItemEl.charterInfo.requestId);
      orderItem.purchaseOrderNumber = orderItemEl.charterInfo.purchaseOrderNumber;
      orderItem.formUrlKey = orderItemEl.charterInfo.formUrlKey
        ? orderItemEl.charterInfo.formUrlKey
        : null;
      orderItem.departureDate =
        orderItemEl.charterInfo.departureDate !== null
          ? new Date(orderItemEl.charterInfo.departureDate)
          : null;
      orderItem.requestUrl = orderItemEl.charterInfo.requestUrl;
    }

    return orderItem;
  }

  parseConciergeOrderItemInternal(orderItemEl: any): models.ConciergeOrderItem {
    const orderItem = new models.ConciergeOrderItem();

    orderItem.id = Number(orderItemEl.id);
    orderItem.quantity = Number(orderItemEl.quantity);
    orderItem.totalPrice = Math.round((Number(orderItemEl.totalPrice) + 0.00001) * 100) / 100;

    const product2 = new models.Product();
    product2.id = Number(orderItemEl.product.productId);
    product2.name = orderItemEl.product.productName;
    product2.amount = Math.round((Number(orderItemEl.basePrice) + 0.00001) * 100) / 100;
    orderItem.product = product2;

    const c2 = new models.Client();

    if (orderItemEl.client) c2.id = Number(orderItemEl.client.id);
    c2.name = orderItemEl.client.name;
    c2.legalName = orderItemEl.client.legalName;
    c2.dob = new Date(orderItemEl.client.dob);
    orderItem.client = c2;

    if (orderItemEl.conciergeInfo) {
      if (orderItemEl.conciergeInfo.creditReasons) {
        orderItem.isCreditMemo = true;
        orderItem.creditReason = orderItemEl.conciergeInfo.creditReasons.map(
          ({ creditReasonName, creditReasonId }) => {
            const reason = new models.CreditReason();
            reason.creditReasonName = creditReasonName;
            reason.creditReasonId = creditReasonId;
            return reason;
          }
        );
      }
      orderItem.creditMethod = orderItemEl.conciergeInfo.creditMethodId;
      orderItem.requestId = Number(orderItemEl.conciergeInfo.requestId);
      orderItem.flightRequestId = Number(orderItemEl.conciergeInfo.flightRequestId);
      orderItem.departureDate =
        orderItemEl.conciergeInfo.departureDate !== null
          ? new Date(orderItemEl.conciergeInfo.departureDate)
          : null;
      orderItem.vendorId = Number(orderItemEl.conciergeInfo.vendorId);
      orderItem.serviceDate =
        orderItemEl.conciergeInfo.serviceDate !== null
          ? new Date(orderItemEl.conciergeInfo.serviceDate)
          : null;
      orderItem.vendorRefNumber = orderItemEl.conciergeInfo.vendorRefNumber;
      orderItem.quoteAmount = Number(orderItemEl.conciergeInfo.quoteAmount);
      orderItem.markupPercent = Number(orderItemEl.conciergeInfo.markupPercent);
      orderItem.clientComments = orderItemEl.conciergeInfo.clientComments;
      orderItem.comments = orderItemEl.conciergeInfo.comments;
      orderItem.requestUrl = orderItemEl.conciergeInfo.requestUrl;
      orderItem.serviceDiscountAmount = orderItemEl.conciergeInfo.serviceDiscountAmount;
    }

    if (orderItemEl.serviceDiscountAmount) {
      orderItem.serviceDiscountAmount = orderItemEl.serviceDiscountAmount;
    }

    if (orderItemEl.cateringCredit) {
      const ro = new models.CateringCredit();
      ro.amount = _.get(orderItemEl.cateringCredit, 'amount', null);
      ro.cateringCreditId = _.get(orderItemEl.cateringCredit, 'cateringCreditId', null);
      ro.clientId = _.get(orderItemEl.cateringCredit, 'clientId', null);
      ro.flightRequestId = _.get(orderItemEl.cateringCredit, 'flightRequestId', null);
      ro.legId = _.get(orderItemEl.cateringCredit, 'legId', null);
      ro.legRequestId = _.get(orderItemEl.cateringCredit, 'legRequestId', null);
      orderItem.legId = _.get(orderItemEl.cateringCredit, 'legId', null);
      orderItem.legUrl = _.get(orderItemEl.cateringCredit, 'legUrl', null);
      orderItem.flightRequestId = _.get(orderItemEl.cateringCredit, 'flightRequestId', null);
      orderItem.flightRequestUrl = _.get(orderItemEl.cateringCredit, 'flightRequestUrl', null);
      orderItem.cateringCredit = ro;
    }

    if (orderItemEl.discount) {
      const discount = new models.PriceComponent();
      discount.amount = orderItemEl.discount.amount;
      discount.typeName = orderItemEl.discount.typeName;
      discount.typeId = orderItemEl.discount.type;
      discount.description = orderItemEl.discount.description;
      discount.name = orderItemEl.discount.name;
      discount.id = orderItemEl.discount.id;
      discount.percentage = orderItemEl.discount.percentage;
      orderItem.discount = discount;
    }

    if (orderItemEl.surcharge) {
      const s1 = new models.PriceComponent();
      s1.name = orderItemEl.surcharge.name;
      s1.typeId = Number(orderItemEl.surcharge.typeId);
      s1.typeName = orderItemEl.surcharge.typeName;

      if (orderItemEl.surcharge.percentage) {
        s1.percentage = Number(orderItemEl.surcharge.percentage);
      } else {
        s1.amount = Number(orderItemEl.surcharge.amount);
      }

      orderItem.surcharge = s1;
    }

    return orderItem;
  }
}
