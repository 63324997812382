import { Action } from '@ngrx/store';
import { Email } from '../../domain/models';

export const LOAD_SENT_EMAILS = '[Sourcing.SourcingRequest] Load Sent Emails';
export const LOAD_SENT_EMAILS_FAILURE = '[Sourcing.SourcingRequest] Load Sent Emails Failure';
export const LOAD_SENT_EMAILS_SUCCESS = '[Sourcing.SourcingRequest] Load Sent Emails Success';
export const LOAD_EMAIL_BODY = '[Sourcing.SourcingRequest] Load Emails Body';
export const LOAD_EMAIL_BODY_FAILURE = '[Sourcing.SourcingRequest] Load Emails Body Failure';
export const LOAD_EMAIL_BODY_SUCCESS = '[Sourcing.SourcingRequest] Load Emails Body Success';

export class LoadSentEmailsAction implements Action {
  readonly type = LOAD_SENT_EMAILS;

  constructor(public payload: string) {
  }
}

export class LoadSentEmailsFailureAction implements Action {
  readonly type = LOAD_SENT_EMAILS_FAILURE;
}

export class LoadSentEmailsSuccessPayload {
  emails: Array<Email>;
}

export class LoadSentEmailsSuccessAction implements Action {
  readonly type = LOAD_SENT_EMAILS_SUCCESS;

  constructor(public payload: LoadSentEmailsSuccessPayload) {
  }
}

export class LoadEmailBodyAction implements Action {
  readonly type = LOAD_EMAIL_BODY;

  constructor(public payload: string) {
  }
}

export class LoadEmailBodyFailureAction implements Action {
  readonly type = LOAD_EMAIL_BODY_FAILURE;
}

export class LoadEmailBodySuccessPayload {
  body: string;
}

export class LoadEmailBodySuccessAction implements Action {
  readonly type = LOAD_EMAIL_BODY_SUCCESS;

  constructor(public payload: LoadEmailBodySuccessPayload) {
  }
}

export type Actions =
  LoadSentEmailsAction
  | LoadSentEmailsFailureAction
  | LoadSentEmailsSuccessAction
  | LoadEmailBodyAction
  | LoadEmailBodySuccessAction
  | LoadEmailBodyFailureAction;
