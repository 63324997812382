export enum ProductTypeEnum {
  Membership = 1,
  Sophisticated = 2,
  Smart = 3,
  Simple = 4,
  PayAsYouGo = 5,
  FlightCredits = 6,
  Affinity = 7,
  LegacyTrial__TrialMembership = 8,
  ACCESS_Limited = 9,
  LegacyTrial__TrialMembershipR = 10,
  GILT__GILT_Membership = 11,
  ARTBASEL__ARTBASEL_Membership = 12,
  UBS__UBS = 13,
  ACCESS_OLD__Social = 14,
  INVITATION_ONLY__Gift = 15,
  SMART__MembershipManual = 16,
  SMART__Manual = 17,
  CORPORATE__Corporate = 18,
  AffinityC__AFFINITY_C = 19,
  AFFINITY2__AFFINITY2 = 20,
  SMART__Legacy = 21,
  Initiation_Fee = 22,
  JetPass = 23,
  SmartRenewalsFromConsole = 24,
  SmartInitiationFee = 25,
  SophisticatedInitiationFee = 26,
  SophisticatedRenewalsFromConsole = 27,
  Goods = 28,
  TangibleGoods = 29,
  IntangibleGoods = 30,
  ALaCarte = 31,
  MembershipInitiationFee = 32,
  ConsoleMembership = 33,
  GroupMembership = 34,
  FamilySlotMembership = 35,
  EnterpriseSlotMembership = 36,
  FamilyGroupInitiationFee = 37,
  FamilyGroupMembership = 38,
  EnterpriseGroupMembership = 39,
  EnterpriseGroupInitiationFee = 40,
  FamilyGroupExtraSlots = 41,
  EnterpriseGroupExtraSlots = 42,
  FamilyGroupUpgradeFee = 43,
  EnterpriseGroupUpgradeFee = 44,
  GroupExtraSeatsAddOn = 45,
  GroupReplacementSeatsAddOn = 46,
  SignatureSlotMembership = 47,
  SignatureGroupMembership = 48,
  SignatureGroupExtraSlot = 49,
  Starter = 50,
  XOSelectSlot = 51,
  XOSelectExtraSlots = 52,
  XOSelectGroup = 53,
  XOSignatureSlot = 54,
  XOSignatureExtraSlots = 55,
  XOSignatureGroup = 56,
  XOEliteSlot = 57,
  XOEliteExtraSlot = 58,
  XOEliteGroup = 59,
  CharterFlight = 60,
  XOCharterFlight = 61,
  Concierge = 62,
  XOConcierge = 63,
  XORiseSlot = 64,
  XORiseExtraSlots = 65,
  XORiseGroup = 66,
  PromotionalCredits = 67,
  LoyaltyCredits = 68,
  SFDCRise = 69,
  SFDCSelectAccess = 70,
  SFDCSignatureAccess = 71,
  SFDCEliteAccess = 72,
  SFDCXOLegacySelectAccess = 73,
  SFDCXOLegacyPreferredAccess = 74,
  SFDCXOLegacyEliteAccess = 75,
  SFDCXOLegacyCorporateAccess = 76,
  XOMembershipSlot = 78,
  XOMembershipExtraSlots = 79,
  XOMembershipGroup = 80,
  XOReserveSlot = 81,
  XOReserveExtraSlots = 82,
  XOReserveGroup = 83,
  XOSharedSlot = 86,
  XOSharedExtraSlots = 87,
  XOSharedGroup = 88,
  SFDCShared = 89,
  JetEdgeReserveSlot = 90,
  JetEdgeReserveExtraSlots = 91,
  JetEdgeReserveGroup = 92,
  SFDCJetEdgeReserve = 93,
}
