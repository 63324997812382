import { LinkToSfdcContactEnum } from './link-to-sfdc-contact-enum';

export class SalesForceClient {
  sfId: string = null;
  sfdcEmail: string = null;
  sfdcPhone: string = null;
  sfdcSalesRegion: string = null;
  sfdcMembership: string = null;
  sfdcMemberSince: Date = null;
  sfdcRecentFlight: Date = null;
  sfAccount: string = null;
  sfdcPod: string = null;
  sfdcName: string = null;
  sfdcAviationAdvisor: string = null;
  sfdcClientType: string = null;
  sfdcRole: string = null;
  linkToSfdcContactPhase: LinkToSfdcContactEnum = null;
}
