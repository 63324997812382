import { Region } from "./region";

export class Airport {
  id: string = null;
  code: string = null;
  city: string = null;
  state: string = null;
  country: string = null;
  faaCode: string = null;
  iataCode: string = null;
  icaoCode: string = null;
  serviceArea: Region = new Region();
}
