export enum CharterActionEnum {
  NoInvoice = 1,
  NewNoOrder = 2,
  WithOrderNotPaid = 3,
  AdditionalPaid = 4,
  NewPaid = 5,
  AdditionalNoOrder = 6,
  AdditionalProforma = 7,
  PreventAnyOtherAdditional = 8,
  Finalized = 9,
  NoAdditionalInvoice = 10,
}
