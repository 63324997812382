export class DepartureTimeFee {
  id: number;
  groupId?: string;
  marketFrom: string;
  marketTo: string;
  timeOfDay: 'MORNING' | 'AFTERNOON' | 'EVENING';
  fee: number;

  get route() {
    return `${this.marketFrom} - ${this.marketTo}`;
  }
}
