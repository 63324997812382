import * as models from '../models';
import { OrderService } from './order.service';
import * as _ from 'lodash';

export class ClientServiceProrateService {
  addProrateToOrderItem(
    orderItem: models.MembershipOrderItem | models.GroupMembershipOrderItem
  ): models.MembershipOrderItem | models.GroupMembershipOrderItem {
    if (
      orderItem.salesAction !== null &&
      orderItem.salesAction === models.SalesActionEnum.Convert
    ) {
      // Find adjustments that have been set by the user
      const legacyBackup = orderItem.childOrderItems.filter(
        (i) => i instanceof models.LegacyAdjustmentOrderItem
      );
      // Loop through childItems and remove Adjustments Products
      orderItem.childOrderItems = orderItem.childOrderItems.filter(
        (i) =>
          !(
            i instanceof models.ClientServiceAdjustmentOrderItem ||
            i instanceof models.LegacyAdjustmentOrderItem ||
            i instanceof models.CustomTermOrderItem
          )
      );

      const buildChildren = (clientService: models.ClientService): void => {
        let oi1: models.OrderItem = null;

        if (clientService.clientServiceType === models.ClientServiceTypeEnum.LegacyMembership) {
          if (legacyBackup.length) {
            oi1 = legacyBackup.find(
              (lb: models.LegacyAdjustmentOrderItem) =>
                lb.clientService.membershipPeriodId ===
                (clientService as models.LegacyMembershipClientService).membershipPeriodId
            );
          } else {
            const legacyMembershipClientService = clientService as models.LegacyMembershipClientService;

            const legacyAdjustmentOrderItem = new models.LegacyAdjustmentOrderItem();
            legacyAdjustmentOrderItem.parentOrderItem = orderItem;
            legacyAdjustmentOrderItem.membershipPeriodId =
              legacyMembershipClientService.membershipPeriodId;
            legacyAdjustmentOrderItem.clientService = clientService as models.LegacyMembershipClientService;

            oi1 = legacyAdjustmentOrderItem;
          }
        } else {
          const orderAdjustmentOrderItem = new models.ClientServiceAdjustmentOrderItem();
          orderAdjustmentOrderItem.clientService = clientService;
          orderAdjustmentOrderItem.parentOrderItem = orderItem;
          orderAdjustmentOrderItem.adjustedOrderItem =
            orderItem.client.clientServiceOrderItem[clientService.clientServiceId];

          orderAdjustmentOrderItem.adjustedOrderItem.refundableAmount = new OrderService().getOrderItemRefundableTotalPrice(
            orderAdjustmentOrderItem.adjustedOrderItem
          );

          if (_.get(orderItem, 'membershipClientService.prorateSuppressed', false) === false) {
            orderAdjustmentOrderItem.adjustedAmount = this.prorate(
              clientService,
              orderAdjustmentOrderItem.adjustedOrderItem.totalPrice <
                orderAdjustmentOrderItem.adjustedOrderItem.refundableAmount
                ? orderAdjustmentOrderItem.adjustedOrderItem.totalPrice
                : orderAdjustmentOrderItem.adjustedOrderItem.refundableAmount
            );
          }

          orderAdjustmentOrderItem.totalPrice = -orderAdjustmentOrderItem.adjustedAmount;

          oi1 = orderAdjustmentOrderItem;
        }

        orderItem.childOrderItems.push(oi1);
      };

      if (orderItem.membershipClientService) {
        buildChildren(orderItem.membershipClientService);
      } else {
        for (const clientService of orderItem.client.pendingMemberships) {
          buildChildren(clientService);
        }
      }
    }

    if (orderItem.prorateType !== null) {
      orderItem = this.addUserSelectedProrateToOrderItem(orderItem);
    }

    return orderItem;
  }

  addUserSelectedProrateToOrderItem(
    orderItem: models.MembershipOrderItem | models.GroupMembershipOrderItem
  ): models.MembershipOrderItem | models.GroupMembershipOrderItem {
    let leftOverAmount = 0;
    const existingCustomTerm = orderItem.childOrderItems
      .filter((coi) => coi instanceof models.CustomTermOrderItem)
      .map((coi: models.CustomTermOrderItem) => coi.termMonths)
      .pop();

    // Rmeoves already existing Order Items to properly calculate the leftOverAmount
    // Removes Flight Credits that have IsCompensation Product Feature and CustomOrderItem (Extensions)
    const filteredOI = _.cloneDeep(orderItem);
    filteredOI.childOrderItems = filteredOI.childOrderItems.filter(
      (coi) =>
        !(
          (coi instanceof models.ProductOrderItem &&
            coi.product.productFeatures.find(
              (f) => f.typeId === models.ProductFeatureType.IsCompensation
            )) ||
          coi instanceof models.CustomTermOrderItem
        )
    );

    for (const childOrderItem of filteredOI.childOrderItems) {
      childOrderItem.totalPrice = new OrderService().getOrderItemTotalPrice(childOrderItem);
    }

    orderItem.totalPrice = new OrderService().getOrderItemTotalPrice(filteredOI);
    leftOverAmount += orderItem.totalPrice;

    if (leftOverAmount <= 0 && orderItem.product) {
      leftOverAmount = -leftOverAmount;

      switch (orderItem.prorateType) {
        case models.OrderItemProrateTypeEnum.MoneyBack:
          // Add Child Item that matches Prorate Value
          // const adjustmentOrderItems = oI.childOrderItems.filter(
          //   coi => coi instanceof models.LegacyAdjustmentOrderItem || coi instanceof models.ClientServiceAdjustmentOrderItem
          // );
          // adjustmentOrderItems.forEach((i: models.LegacyAdjustmentOrderItem | models.ClientServiceAdjustmentOrderItem) => {
          //   if (i.totalPrice) {
          //     const offsetOrderItem = new models.OrderItem();
          //     offsetOrderItem.parentOrderItem = oI;
          //     offsetOrderItem.client = oI.client;
          //     offsetOrderItem.totalPrice = -(i.totalPrice);
          //     offsetOrderItem.orderId = oI.orderId;
          //     oI.childOrderItems.push(offsetOrderItem);
          //   }
          // });
          break;
        case models.OrderItemProrateTypeEnum.ExtendProduct:
          orderItem.childOrderItems = filteredOI.childOrderItems;
          const orderTotalPriceWithoutChildren = orderItem.product.amount * orderItem.quantity;
          let totalProductDuration = 1;
          if (orderTotalPriceWithoutChildren) {
            const ratio = leftOverAmount / orderTotalPriceWithoutChildren;
            const durationFeature = orderItem.product.productFeatures.find(
              (f) => f.typeId === models.ProductFeatureType.Length
            );
            if (!durationFeature) {
              throw new Error(
                `Couldn't find duration/length feature for productId: ${orderItem.product.id}`
              );
            }
            const extensionMonth = Math.ceil(ratio * Number(durationFeature.value));
            totalProductDuration = Number(durationFeature.value) + extensionMonth;
          }

          // const endDate = new Date();
          // endDate.setMonth(endDate.getMonth() + totalProductDuration);

          // orderItem.endDate = endDate;

          // Add adjustment to event out the order
          const adjustmentOi = new models.CustomTermOrderItem();

          // if (orderItem.membershipClientService !== null) {
          //   throw new Error('Membership is not selected');
          // }

          adjustmentOi.parentOrderItem = orderItem;
          adjustmentOi.totalPrice = leftOverAmount;
          adjustmentOi.termMonths = existingCustomTerm ? existingCustomTerm : totalProductDuration;

          orderItem.childOrderItems.push(adjustmentOi);

          break;
        case models.OrderItemProrateTypeEnum.AddProduct:
          // Do nothing until an Add On Product is selected
          orderItem.childOrderItems = orderItem.childOrderItems
            .filter((coI) => !(coI instanceof models.CustomTermOrderItem))
            .map((coI) => {
              if (
                coI instanceof models.ProductOrderItem &&
                coI.product.productFeatures.find(
                  (f) => f.typeId === models.ProductFeatureType.IsCompensation
                )
              ) {
                const product = _.cloneDeep(coI.product);
                product.amount = leftOverAmount;
                coI.product = product;
              }

              return coI;
            });
          break;
        default:
          throw new Error(`Prorate Type: ${orderItem.prorateType} Not Supported`);
      }
    }

    if (orderItem.prorateType === null) {
      orderItem.childOrderItems = filteredOI.childOrderItems;
    }

    return orderItem;
  }

  prorate(clientService: models.ClientService, amountPaid: number): number {
    // TODO: Exclude non refundable fees like initiation fee and etc
    if (!amountPaid) {
      return 0;
    }

    if (Date.now() < clientService.startDate.getTime()) {
      return amountPaid;
    }

    if (Date.now() > clientService.endDate.getTime()) {
      return 0;
    }

    const totalDays = Math.floor(
      (clientService.endDate.getTime() - clientService.startDate.getTime()) / 86400000
    );
    const leftDays = Math.floor((clientService.endDate.getTime() - Date.now()) / 86400000);

    const amount = +((amountPaid * leftDays) / totalDays).toFixed(2);

    return amount;
  }
}
