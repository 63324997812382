import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'warning-dialog',
  templateUrl: './warning-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningDialogComponent {
  @Input() text: string;
  @Input() title: string = 'Warning';
  @Input() cancelText: string = 'Close';
  @Output() close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }
}
