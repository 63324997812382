import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class LoadPayload {
  legsCriteria: models.LegsLookupCriteria;
  offset: number;
  limit: number;
  sortOrder: models.SortOrderLegsEnum = models.SortOrderLegsEnum.asc;
  sortBy: models.SortLegsEnum = models.SortLegsEnum.legId;
  isNotUpdateLegCriteria = true;
  sort = false;
}
export const load = createAction(
  '[TaskMgmt.LegCollection] Load',
  props<{ payload: LoadPayload }>()
);
export class LoadSuccessPayload {
  items: Array<models.TasksLeg>;
  totalElements: number;
  number: number;
}
export const loadSuccess = createAction(
  '[TaskMgmt.LegCollection] Load Success',
  props<{ payload: LoadSuccessPayload }>()
);
export const loadFail = createAction('[TaskMgmt.LegCollection] Load Fail');

export class LoadTasksPayload {
  legId: number;
  criteria: models.LegTasksLookupFilters;
}
export const loadTasks = createAction(
  '[TaskMgmt.LegCollection] Load Tasks',
  props<{ payload: LoadTasksPayload }>()
);
export class LoadTasksSuccessPayload {
  legId: number;
  items: Array<models.Task>;
}
export const loadTasksSuccess = createAction(
  '[TaskMgmt.LegCollection] Load Tasks Success',
  props<{ payload: LoadTasksSuccessPayload }>()
);
export class LoadTasksFailPayload {
  legId: number;
}
export const loadTasksFail = createAction(
  '[TaskMgmt.LegCollection] Load Tasks Fail',
  props<{ payload: LoadTasksFailPayload }>()
);

export class CreateNewTaskPayload {
  legId: number;
  dueDate: Date;
  description: string;
  taskCategoryId: number;
  title: string;
}
export const createNewTask = createAction(
  '[TaskMgmt.LegCollection] Create New Task',
  props<{ payload: CreateNewTaskPayload }>()
);
export class CreateNewTaskSuccessPayload {
  legId: number;
  task: models.Task;
}
export const createNewTaskSuccess = createAction(
  '[TaskMgmt.LegCollection] Create New Task Success',
  props<{ payload: CreateNewTaskSuccessPayload }>()
);
export const createNewTaskFail = createAction(
  '[TaskMgmt.LegCollection] Create New Task Fail',
  props<{ payload?: any }>()
);

export class RevertTasksStatePayload {
  legId: number;
  taskIds: Array<number>;
}
export const revertTasksState = createAction(
  '[TaskMgmt.LegCollection] Revert Tasks State',
  props<{ payload: RevertTasksStatePayload }>()
);
export class RevertTasksStateSuccessPayload {
  legId: number;
  tasks: Array<models.Task>;
}
export const revertTasksStateSuccess = createAction(
  '[TaskMgmt.LegCollection] Revert Tasks State Success',
  props<{ payload: RevertTasksStateSuccessPayload }>()
);
export class RevertTasksStateFailedPayload {
  legId: number;
  taskIds: Array<number>;
}
export const revertTasksStateFail = createAction(
  '[TaskMgmt.LegCollection] Revert Tasks State Fail',
  props<{ payload: RevertTasksStateFailedPayload }>()
);

export class CompleteTasksPayload {
  updateLegsPayload: UpdateLegsPayload;
  setStateForMultipleTaskPayload: SetStateForMultipleTaskPayload;
}
export const completeTasks = createAction(
  '[TaskMgmt.LegCollection] Complete Tasks',
  props<{ payload: CompleteTasksPayload }>()
);
export const completeTasksSuccess = createAction('[TaskMgmt.LegCollection] Complete Tasks Success');
export class CompleteTasksFailPayload {
  taskIds: Array<number>;
  taskStateId: models.StateEnum;
}
export const completeTasksFail = createAction(
  '[TaskMgmt.LegCollection] Complete Tasks Fail',
  props<{ payload: CompleteTasksFailPayload }>()
);

export class SetStateForMultipleTaskPayload {
  legId: number;
  taskStateId: number;
  taskIds: Array<number>;
}
export const setStateForMultipleTask = createAction(
  '[TaskMgmt.LegCollection] Set State For Multiple Task',
  props<{ payload: SetStateForMultipleTaskPayload }>()
);
export class SetStateForMultipleTaskSuccessPayload {
  newLegStatus: string;
  legId: number;
  tasks: Array<models.Task>;
}
export const setStateForMultipleTaskSuccess = createAction(
  '[TaskMgmt.LegCollection] Set State For Multiple Task Success',
  props<{ payload: SetStateForMultipleTaskSuccessPayload }>()
);
export class SetStateForMultipleTaskFailedPayload {
  legId: number;
  taskIds: Array<number>;
  taskStateId: models.StateEnum;
}
export const setStateForMultipleTaskFail = createAction(
  '[TaskMgmt.LegCollection] Set State For Multiple Task Fail',
  props<{ payload: SetStateForMultipleTaskFailedPayload }>()
);

export class SetTasksCriteriaInitialPayload {
  tasksCriteria: models.LegsTaskLookupCriteria;
}
export const setTasksCriteriaInitial = createAction(
  '[TaskMgmt.LegCollection] Set Tasks Criteria Initial',
  props<{ payload: SetTasksCriteriaInitialPayload }>()
);

export const setInitial = createAction('[TaskMgmt.LegCollection] Set Initial');

export class FinalizeLegPayload {
  legId: number;
  taskId: number;
}
export const finalizeLeg = createAction(
  '[TaskMgmt.LegCollection] Finalize Leg',
  props<{ payload: FinalizeLegPayload }>()
);
export class FinalizeLegSuccessPayload {
  legId: number;
  taskId: number;
}
export const finalizeLegSuccess = createAction(
  '[TaskMgmt.LegCollection] Finalize Leg Success',
  props<{ payload: FinalizeLegSuccessPayload }>()
);
export const finalizeLegFail = createAction('[TaskMgmt.LegCollection] Finalize Leg Fail');

export class UpdateLegsPayload {
  legs: Array<models.LegRequest>;
}
export const updateLegs = createAction(
  '[TaskMgmt.LegCollection] Update Legs',
  props<{ payload: UpdateLegsPayload }>()
);
export class UpdateLegsSuccessPayload {
  entities: Array<models.FlightLeg>;
}
export const updateLegsSuccess = createAction(
  '[TaskMgmt.LegCollection] Update Legs Success',
  props<{ payload: UpdateLegsSuccessPayload }>()
);
export const updateLegsFail = createAction('[TaskMgmt.LegCollection] Update Legs Fail');

export class CreateNewNotePayload {
  legId: number;
  taskId: number;
  text: string;
}
export const createNewNote = createAction(
  '[TaskMgmt.LegCollection] Create New Note',
  props<{ payload: CreateNewNotePayload }>()
);
export class CreateNewNoteSuccessPayload {
  legId: number;
  note: models.Note;
  taskId: number;
}
export const createNewNoteSuccess = createAction(
  '[TaskMgmt.LegCollection] Create New Note Success',
  props<{ payload: CreateNewNoteSuccessPayload }>()
);
export const createNewNoteFail = createAction(
  '[TaskMgmt.LegCollection] Create New Note Fail',
  props<{ payload?: any }>()
);

export class UpdateNotePayload {
  id: string;
  legId: number;
  text: string;
  taskId: number;
}
export const updateNote = createAction(
  '[TaskMgmt.LegCollection] Update Note',
  props<{ payload: UpdateNotePayload }>()
);
export class UpdateNoteSuccessPayload {
  legId: number;
  note: models.Note;
  taskId: number;
}
export const updateNoteSuccess = createAction(
  '[TaskMgmt.LegCollection] Update Note Success',
  props<{ payload: UpdateNoteSuccessPayload }>()
);
export const updateNoteFail = createAction(
  '[TaskMgmt.LegCollection] Update Note Fail',
  props<{ payload?: any }>()
);

export class DeleteNotePayload {
  id: string;
  legId: number;
  taskId: number;
}
export const deleteNote = createAction(
  '[TaskMgmt.LegCollection] Delete Note',
  props<{ payload: DeleteNotePayload }>()
);
export class DeleteNoteSuccessPayload {
  id: string;
  legId: number;
  taskId: number;
}
export const deleteNoteSuccess = createAction(
  '[TaskMgmt.LegCollection] Delete Note Success',
  props<{ payload: DeleteNoteSuccessPayload }>()
);
export const deleteNoteFail = createAction(
  '[TaskMgmt.LegCollection] Delete Note Fail',
  props<{ payload?: any }>()
);

export class GetRelatedLegsPayload {
  legId: number;
}
export const getRelatedLegs = createAction(
  '[TaskMgmt.LegCollection] Get Related Legs',
  props<{ payload: GetRelatedLegsPayload }>()
);
export class GetRelatedLegsSuccessPayload {
  relatedLegs: models.RelatedLegs;
}
export const getRelatedLegsSuccess = createAction(
  '[TaskMgmt.LegCollection] Get Related Legs Success',
  props<{ payload: GetRelatedLegsSuccessPayload }>()
);
export const getRelatedLegsFail = createAction('[TaskMgmt.LegCollection] Get Related Legs Fail');

export class UpdateRelatedLegsPayload {
  flightRequestId: number;
  legId: number;
  legIds: Array<number>;
  pod: string;
}
export const updateRelatedLegs = createAction(
  '[TaskMgmt.LegCollection] Update Related Legs',
  props<{ payload: UpdateRelatedLegsPayload }>()
);
export class UpdateRelatedLegsSuccessPayload {
  legIds: Array<number>;
  pod: string;
}
export const updateRelatedLegsSuccess = createAction(
  '[TaskMgmt.LegCollection] Update Related Legs Success',
  props<{ payload: UpdateRelatedLegsSuccessPayload }>()
);
export const updateRelatedLegsFail = createAction(
  '[TaskMgmt.LegCollection] Update Related Legs Fail'
);
