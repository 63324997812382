import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/dba-list.actions';
import * as models from '../../domain/models';

export const dbaListFeatureKey = 'dbaList';

export interface State {
  loaded: boolean;
  loading: boolean;
  entities: Array<models.Dba>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  entities: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      entities: payload,
    })
  )
);
