import { createReducer, on } from '@ngrx/store';

import * as _ from 'lodash';

import * as models from '../../domain/models';
import * as actions from '../actions/entities-to-update.actions';

export const entitiesToUpdateFeatureKey = 'entitiesToUpdate';

export interface State {
  loaded: boolean;
  loading: boolean;
  userToUpdateGroups: models.User;
  userToUpdateRestrictions: models.User;
  groupToUpdateUsers: models.GroupToUpdate;
  groupToUpdateRestrictions: models.GroupToUpdate;
}

const initialState: State = {
  loaded: false,
  loading: false,
  userToUpdateGroups: null,
  userToUpdateRestrictions: null,
  groupToUpdateUsers: {
    userGroupId: null,
    users: [],
  },
  groupToUpdateRestrictions: {
    userGroupId: null,
    restrictedFunctions: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(
    actions.loadUserGroups,
    (state, { payload }): State => ({
      ...state,
      loading: true,
      userToUpdateGroups: payload,
    })
  ),
  on(actions.loadUserGroupsSuccess, (state, { payload }): State => {
    const userToUpdateGroups: models.User = _.cloneDeep(state.userToUpdateGroups);
    userToUpdateGroups.userGroups = payload;

    return {
      ...state,
      loading: false,
      userToUpdateGroups,
    };
  }),
  on(
    actions.loadUserGroupsFail,
    (state): State => ({
      ...state,
      userToUpdateGroups: null,
      loading: false,
    })
  ),

  on(
    actions.loadGroupUsers,
    (state, { payload }): State => ({
      ...state,
      loading: true,
      groupToUpdateUsers: { ...state.groupToUpdateUsers, userGroupId: payload },
    })
  ),
  on(
    actions.loadGroupUsersSuccess,
    (state, { payload }): State => ({
      ...state,
      loading: false,
      groupToUpdateUsers: { ...state.groupToUpdateUsers, users: payload },
    })
  ),
  on(
    actions.loadGroupUsersFail,
    (state): State => ({
      ...state,
      groupToUpdateUsers: { ...state.groupToUpdateUsers, users: [] },
      loading: false,
    })
  ),

  on(
    actions.resetGroupUsers,
    (state): State => ({
      ...state,
      groupToUpdateUsers: initialState.groupToUpdateUsers,
    })
  ),
  on(
    actions.resetUserToUpdateGroups,
    (state): State => ({
      ...state,
      userToUpdateGroups: null,
    })
  ),

  on(actions.addUsersToGroup, (state, { payload }): State => {
    const usersIdsArr = state.groupToUpdateUsers.users.map(({ userId }) => userId);
    const users = _.cloneDeep(payload)
      .filter((user) => !usersIdsArr.includes(user.userId))
      .map((user) => {
        user.userGroupId = state.groupToUpdateUsers.userGroupId;
        return user;
      });
    const usersToUpdate = state.groupToUpdateUsers.users.concat(users);

    return {
      ...state,
      groupToUpdateUsers: { ...state.groupToUpdateUsers, users: usersToUpdate },
    };
  }),

  on(actions.removeUserFromGroup, (state, { payload }): State => {
    const usersToUpdate = _.cloneDeep(
      state.groupToUpdateUsers.users.filter((user) => user.userId !== payload)
    );

    return {
      ...state,
      groupToUpdateUsers: { ...state.groupToUpdateUsers, users: usersToUpdate },
    };
  }),

  on(
    actions.loadUserRestricts,
    (state, { payload }): State => ({
      ...state,
      loading: true,
      userToUpdateRestrictions: payload,
    })
  ),
  on(actions.loadUserRestrictsSuccess, (state, { payload }): State => {
    const userToUpdateRestrictions: models.User = _.cloneDeep(state.userToUpdateRestrictions);
    userToUpdateRestrictions.restrictedFunctions = payload;
    return {
      ...state,
      loading: false,
      userToUpdateRestrictions,
    };
  }),
  on(
    actions.loadUserRestrictsFail,
    (state): State => ({
      ...state,
      loading: false,
      userToUpdateRestrictions: null,
    })
  ),

  on(
    actions.loadGroupRestricts,
    (state, { payload }): State => ({
      ...state,
      loading: true,
      groupToUpdateRestrictions: {
        ...state.groupToUpdateRestrictions,
        userGroupId: payload,
      },
    })
  ),
  on(
    actions.loadGroupRestrictsSuccess,
    (state, { payload }): State => ({
      ...state,
      loading: false,
      groupToUpdateRestrictions: {
        ...state.groupToUpdateRestrictions,
        restrictedFunctions: payload,
      },
    })
  ),
  on(
    actions.loadGroupRestrictsFail,
    (state): State => ({
      ...state,
      groupToUpdateRestrictions: { ...state.groupToUpdateRestrictions, restrictedFunctions: [] },
      loading: false,
    })
  ),

  on(
    actions.resetGroupRestricts,
    (state): State => ({
      ...state,
      groupToUpdateRestrictions: initialState.groupToUpdateRestrictions,
    })
  ),

  on(
    actions.resetUserToUpdateRestrictions,
    (state): State => ({
      ...state,
      userToUpdateRestrictions: null,
    })
  ),

  on(
    actions.updateGroupUsers,
    actions.updateRestricts,
    actions.updateUserGroups,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),

  on(
    actions.updateGroupUsersSuccess,
    actions.updateGroupUsersFail,
    actions.updateUserGroupsSuccess,
    actions.updateUserGroupsFail,
    actions.updateRestrictsSuccess,
    actions.updateRestrictsFail,
    (state): State => ({
      ...state,
      loading: false,
    })
  )
);
