import * as models from '../../domain/models';
import * as actions from '../actions/leg-pricing-audit-collection';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.LegPriceAudit>;
  itemsPerPage: number;
  itemsTotalCount: number;
  currentPage: number;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 100,
  itemsTotalCount: 0,
  currentPage: 1,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        items: _.cloneDeep(action.payload.items),
        itemsTotalCount: action.payload.count,
        currentPage: action.payload.page,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
export const getCurrentPage = (state: State) => state.currentPage;
