import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface IPromotionService {
  getPromotion(request: GetPromotionRequest): Observable<GetPromotionResponse>;

  // offerList(request: OfferListRequest): Observable<OfferListResponse>;
}

export const PROMOTION_SERVICE_TOKEN = new InjectionToken('Order.IOfferService');

export class GetPromotionRequest {
  promotionCode: string;
}

export class GetPromotionResponse {
  entity: models.Promotion;
}

// export class OfferListRequest {
// }
//
// export class OfferListResponse {
//   promotions: Array<models.Promotion>;
// }
