import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '../../../shared/actions/layout.actions';

@Component({
  selector: 'not-found-page',
  templateUrl: './not-found-page.component.html'
})
export class NotFoundPageComponent implements OnDestroy {
  constructor(private store: Store<any>) {
    this.store.dispatch(actions.activateFullHeightView());
  }

  ngOnDestroy(): void {
    this.store.dispatch(actions.deactivateFullHeightView());
  }
}
