import * as models from '../../domain/models';
import * as actions from '../actions/flight-request';

export interface State {
  flightRequest: models.FlightRequest;
  loaded: boolean;
  loading: boolean;
  sourcingRequestCreating: boolean;
}

const initialState: State = {
  flightRequest: null,
  loaded: false,
  loading: false,
  sourcingRequestCreating: false
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        flightRequest: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case actions.CREATE_SOURCING_REQUEST: {
      return {
        ...state,
        sourcingRequestCreating: true
      }
    }

    case actions.CREATE_SOURCING_REQUEST_FAIL:
    case actions.CREATE_SOURCING_REQUEST_SUCCESS: {
      return {
        ...state,
        sourcingRequestCreating: false
      }
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getFlightRequest = (state: State) => state.flightRequest;
export const getSourcingRequestCreating = (state: State) => state.sourcingRequestCreating;
