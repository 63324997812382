import * as models from '../models';

interface Criteria {
  name: string;
  value: boolean;
}

const ERRORS: { [key: string]: string } = {
  charterInvoiceTypeNotExists: 'Charter invoice is required',
  childOrderItemsShouldBeZero: 'Child order items should not be 0',
  clientNotExists: 'Client is required',
  creditMethodNotExists: 'Credit method is required',
  creditReasonNotExists: 'Credit reason is required',
  faceValueNotExists: 'Face value is required',
  formUrlKeyNotExists: 'Form UrlKey is required',
  isNotValid: 'Item is not valid',
  orderItemNotExists: 'Order item is required',
  productNotExists: 'Product is required',
  quantityShouldBeZero: 'Quantity should not be 0',
  quoteAmountShouldBeZero: 'Quote amount is required and should not be 0',
  requestNotExists: 'Request is required',
  serviceDateNotExists: 'Service date is required',
  vendorNotExists: 'Vendor is required',
  vendorRefNumberNotExists: 'Vendor ref number is required',
  wrongItemTypeError: 'Item type is required',
};

export const getError = (criteria: boolean, path: string): string | null => {
  return !criteria ? ERRORS[path] : null;
};

export const orderItemValidator = (
  orderItem: models.OrderItem
): { [key: string]: string } | null => {
  const errors: { [key: string]: string } = {};
  const setErrors = (criteriaList: Array<Criteria>): void => {
    criteriaList.forEach(({ value, name }) => {
      if (getError(value, name)) {
        errors[name] = getError(value, name);
      }
    });
  };

  switch (orderItem.orderItemType) {
    case models.OrderItemTypeEnum.Product: {
      const productOrderItem = orderItem as models.ProductOrderItem;
      const criteriaList: Array<Criteria> = [
        {
          name: 'clientNotExists',
          value: productOrderItem.client != null,
        },
        {
          name: 'productNotExists',
          value: productOrderItem.product != null,
        },
      ];
      setErrors(criteriaList);
      break;
    }

    case models.OrderItemTypeEnum.FlightPass: {
      const flightPassOrderItem = orderItem as models.FlightPassOrderItem;
      const criteriaList: Array<Criteria> = [
        {
          name: 'clientNotExists',
          value: flightPassOrderItem.client != null,
        },
        {
          name: 'productNotExists',
          value: flightPassOrderItem.product != null,
        },
        { name: 'faceValueNotExists', value: !!flightPassOrderItem.faceValue },
      ];
      setErrors(criteriaList);
      break;
    }

    case models.OrderItemTypeEnum.Membership: {
      const membershipOrderItem = orderItem as models.MembershipOrderItem;
      const criteriaList: Array<Criteria> = [
        {
          name: 'clientNotExists',
          value: membershipOrderItem.client != null,
        },
        {
          name: 'productNotExists',
          value: membershipOrderItem.product != null,
        },
        {
          name: 'quantityShouldBeZero',
          value: membershipOrderItem.quantity !== 0,
        },
      ];
      setErrors(criteriaList);
      break;
    }

    case models.OrderItemTypeEnum.GroupMembership: {
      const groupMembershipOrderItem = orderItem as models.GroupMembershipOrderItem;
      const criteriaList: Array<Criteria> = [
        {
          name: 'clientNotExists',
          value: groupMembershipOrderItem.client != null,
        },
        {
          name: 'productNotExists',
          value: groupMembershipOrderItem.product != null,
        },
        {
          name: 'quantityShouldBeZero',
          value: groupMembershipOrderItem.quantity !== 0,
        },
        {
          name: 'childOrderItemsShouldBeZero',
          value: !groupMembershipOrderItem.childOrderItems
            .filter((i) => i instanceof models.MembershipOrderItem)
            .find((ci: models.MembershipOrderItem) => ci.quantity === 0),
        },
      ];
      setErrors(criteriaList);
      break;
    }

    case models.OrderItemTypeEnum.Concierge: {
      const conciergeOi = orderItem as models.ConciergeOrderItem;

      if (conciergeOi.isCreditMemo) {
        const criteriaList: Array<Criteria> = [
          {
            name: 'productNotExists',
            value: conciergeOi.product !== null,
          },
          {
            name: 'vendorNotExists',
            value: conciergeOi.vendorId !== null,
          },
          {
            name: 'creditReasonNotExists',
            value: !!(conciergeOi.creditReason && conciergeOi.creditReason.length),
          },
          {
            name: 'creditMethodNotExists',
            value: !!conciergeOi.creditMethod,
          },
        ];
        criteriaList.forEach(({ value, name }) => {
          if (getError(value, name)) {
            errors[name] = getError(value, name);
          }
        });
        break;
      }

      const criteriaList: Array<Criteria> = [
        {
          name: 'productNotExists',
          value: conciergeOi.product !== null,
        },
        {
          name: 'vendorNotExists',
          value: conciergeOi.vendorId !== null,
        },
        {
          name: 'vendorRefNumberNotExists',
          value: !!conciergeOi.vendorRefNumber,
        },
        {
          name: 'requestId',
          value: conciergeOi.requestId !== null,
        },
        {
          name: 'serviceDateNotExists',
          value: conciergeOi.requestId !== 0 || conciergeOi.serviceDate !== null,
        },
        {
          name: 'quoteAmountShouldBeZero',
          value: !!conciergeOi.quoteAmount,
        },
      ];
      setErrors(criteriaList);
      break;
    }

    case models.OrderItemTypeEnum.Charter: {
      const charterOi = orderItem as models.CharterOrderItem;
      const criteriaList: Array<Criteria> = [
        {
          name: 'productNotExists',
          value: charterOi.product !== null,
        },
        {
          name: 'requestNotExists',
          value: charterOi.requestId !== null,
        },
        {
          name: 'formUrlKeyNotExists',
          value: charterOi.formUrlKey !== null,
        },
        {
          name: 'isNotValid',
          value: charterOi.isValid,
        },
        {
          name: 'charterInvoiceTypeNotExists',
          value: charterOi.charterInvoiceType !== null,
        },
        {
          name: 'wrongItemTypeError',
          value:
            charterOi.charterAction === models.CharterActionEnum.NewNoOrder ||
            charterOi.charterAction === models.CharterActionEnum.AdditionalNoOrder ||
            charterOi.charterAction === models.CharterActionEnum.AdditionalProforma,
        },
      ];
      setErrors(criteriaList);
      break;
    }

    default:
      throw new Error(
        `Order Item Type: ${orderItem.orderItemType} is not supported inside reducer(order-new).isOrderItemValid`
      );
  }

  return Object.keys(errors).length ? errors : null;
};
