export class LegRequestInput {
  departureTimeUTC: Date;
  departureAirportCode: string;
  arrivalAirportCode: string;
  departureFboId: number;
  arrivalFboId: number;
  newDepartureTime: Date;
  pax?: number;
  eft?: number;
  toIsTechStop?: boolean;
  newArrivalAirportCode?: string;
  newDepartureAirportCode?: string;
}
