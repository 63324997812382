export class ServiceClass {
  id: number;
  name: string;

  getId(): number {
    return this.id;
  }

  setId(id: number): ServiceClass {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): ServiceClass {
    this.name = name;

    return this;
  }
}
