import { Injectable } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { Params, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../environments/environment';
import { EnvironmentEnum } from '../../environments/environment.interface';

import * as fromCharterSales from '../modules/+charter/application/reducers';
import * as fromExplorer from '../modules/+explorer/application/reducers';
import * as fromClient from '../modules/+client/application/reducers';
import * as fromCore from '../core/reducers';
import * as fromMarketplace from '../modules/+marketplace/application/reducers';
import * as fromSales from '../modules/+sales/application/reducers';
import * as fromSourcingRequest from '../modules/+sourcing/application/reducers';
import * as fromTaskMgmt from '../modules/+task-mgmt/application/reducers';
import { routerReducer, RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import * as fromYieldMgmt from '../modules/+yield-mgmt/application/reducers';
import * as fromResourceMgmt from '../modules/+resource-mgmt/application/reducers';
import * as fromUserMgmt from '../modules/+user-mgmt/application/reducers';
import * as fromForms from '../features/forms/application/reducers';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

export interface State {
  [fromCharterSales.featureName]: fromCharterSales.State;
  explorer: fromExplorer.State;
  [fromClient.featureName]: fromClient.State;
  [fromSales.featureName]: fromSales.State;
  sourcing: fromSourcingRequest.State;
  [fromCore.featureName]: fromCore.State;
  router: RouterReducerState<RouterStateUrl>;
  marketplace: fromMarketplace.State;
  [fromTaskMgmt.featureName]: fromTaskMgmt.State;
  yieldMgmt: fromYieldMgmt.State;
  resourceMgmt: fromResourceMgmt.State;
  [fromUserMgmt.featureName]: fromUserMgmt.State;
}

export interface AppState extends State {
  [fromForms.featureName]: fromForms.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromCharterSales.featureName]: fromCharterSales.reducers,
  explorer: fromExplorer.reducer,
  [fromClient.featureName]: fromClient.reducers,
  [fromSales.featureName]: fromSales.reducers,
  sourcing: fromSourcingRequest.reducer,
  [fromCore.featureName]: fromCore.reducers,
  router: routerReducer,
  marketplace: fromMarketplace.reducer,
  [fromTaskMgmt.featureName]: fromTaskMgmt.reducers,
  yieldMgmt: fromYieldMgmt.reducer,
  resourceMgmt: fromResourceMgmt.reducer,
  [fromUserMgmt.featureName]: fromUserMgmt.reducers,
};

export const metaReducers: Array<MetaReducer<AppState>> =
  environment.environment !== EnvironmentEnum.PRODUCTION ? [storeFreeze] : [];

// export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
//   return function(state, action) {
//     if (state) {
//       console.log('state', state);
//       console.log('action', action);
//     }
//
//     return reducer(state, action);
//   };
// }
//
// export const metaReducers: Array<MetaReducer<State>> = environment.environment !== EnvironmentEnum.PRODUCTION ? [storeFreeze, debug] : [];
