import * as models from '../../../../domain/models';
import * as dto from '../contracts';

export class PaymentTransactionCheckDtoMapper {
  toDto(pt: models.PaymentTransactionCheck): dto.PaymentTransactionCheckDto {
    return new dto.PaymentTransactionCheckDto(
      pt.referenceNumber,
      pt.paymentDate,
      pt.paymentTransactionType
    );
  }
}
