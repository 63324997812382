export class TerminationData {
  memberships: Array<TerminationMembership>;
  sfdcAccountName: string;
}

export class TerminationMembership {
  clientId: number;
  clientName: string;
  clientServiceId: number;
  legacyMembershipPeriodId: number;
  orderId: number;
  programName: string;
  timeExpiring: Date;
  timeStarted: Date;
}
