export class Vendor {
  id: string;
  name: string;

  getName(): string {
    return this.name;
  }

  setName(name: string): Vendor {
    this.name = name;

    return this;
  }

  getId(): string {
    return this.id;
  }

  setId(id: string): Vendor {
    this.id = id;

    return this;
  }
}
