import { OrderItemSerializable } from './order-item-serializable';

export class FlightCreditOrderItemSerializable extends OrderItemSerializable {
  flightCreditsAmount: number;
  modifiesTotal = false;
  productId: number = null;
  productProductTypeParentProductTypeId: number = null;
  productTypeId: number = null;
  productName: string = null;
  productAmount: number = null;
}
