import { AircraftCategory } from './aircraft-category';
import { RmnRegion } from './rmn-region';

export class ServiceArea {
  aircraftCategory: AircraftCategory = null;
  regions: Array<RmnRegion> = [];

  get hasRegions() {
    return this.regions.length > 0;
  }
}
