import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface ISecurityService {
  reset(request: ResetRequest): Observable<ResetResponse>;
  authorize(request: AuthorizeRequest): Observable<AuthorizeResponse>;
}

export const SECURITY_SERVICE_TOKEN = new InjectionToken('Security.ISecurityService');

export class ResetRequest {
  userId: string;
  securityCode: string;

  constructor(entity: models.SecurityAccess) {
    this.userId = entity.userId;
    this.securityCode = btoa(entity.securityCode);
  }
}

export class ResetResponse {}

export class AuthorizeRequest {
  userId: string;
  securityCode: string;
  entities: Array<models.AuthorizationRequest>;

  constructor(entity: models.SecurityAccess, entities?: Array<models.AuthorizationRequest>) {
    this.userId = entity.userId;
    this.securityCode = btoa(entity.securityCode);
    this.entities = entities ? entities : [];
  }
}

export class AuthorizeResponse {
  entities: Array<models.AuthorizationRequest>;
}
