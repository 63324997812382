export class LegHistoryInfo {
  departureDate: Date = null;
  departureFboName: string = null;
  arrivalFboName: string = null;
  eft: number;
  toIsTechStop: boolean = null;
  pax: number = null;

  getDepartureFboName(): string {
    return this.departureFboName;
  }

  setDepartureFboName(fboName: string): LegHistoryInfo {
    this.departureFboName = fboName;
    return this;
  }

  getArrivalFboName(): string {
    return this.arrivalFboName;
  }

  setArrivalFboName(fboName: string): LegHistoryInfo {
    this.arrivalFboName = fboName;
    return this;
  }

  getDepartureDate(): Date {
    return this.departureDate;
  }

  setDepartureDate(departureDate: Date): LegHistoryInfo {
    this.departureDate = departureDate;
    return this;
  }

  getEft(): number {
    return this.eft;
  }

  setEft(eft: number): LegHistoryInfo {
    this.eft = eft;
    return this;
  }

  getTechStop(): boolean {
    return this.toIsTechStop;
  }

  setTechStop(value: boolean): LegHistoryInfo {
    this.toIsTechStop = value;
    return this;
  }

  getPax(): number {
    return this.pax;
  }

  setPax(value: number): LegHistoryInfo {
    this.pax = value;
    return this;
  }

}
