import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class LoyaltyCreditAdjustmentOrderItem extends OrderItem {
  loyaltyCreditAmount = 0;
  modifiesTotal = false;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.LoyaltyCredit;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.loyaltyCreditAmount;
  }
}
