import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as serviceContracts from '../../../domain/service-contracts/order';
import * as models from '../../../domain/models';
import * as domainServices from '../../../domain/domain-services';
import * as mappers from '../order/mappers';
import * as contracts from '../order/contracts';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { response as getCharterOrderDataResponse } from './get-charter-order-data-response';
import { response as getOrderDataWithSurchargeResponse } from './get-order-with-surcharge-data-response';
import { response as getOrderDataResponse } from './get-order-data-response';
import { response as getOrderListResponse } from './get-order-list-response';
import { response as createOrderResponse } from './create-order-response';
import { response as generatedDocumentResponse } from './get-generated-document';

import * as _ from 'lodash';

@Injectable()
export class OrderMockService implements serviceContracts.IOrderService {
  constructor(private datePipe: DatePipe) {}

  convertOrderItemToSerializable(orderItem: models.OrderItem): contracts.OrderItemSerializable {
    let orderItemSerializable: contracts.OrderItemSerializable = null;

    switch (orderItem.orderItemType) {
      case models.OrderItemTypeEnum.Adjustment: {
        orderItemSerializable = new contracts.AdjustmentOrderItemSerializable();
        const oi1 = orderItem as models.OrderAdjustmentOrderItem;

        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedOrderId =
          oi1.adjustedOrderItem.orderId;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedOrderItemId =
          oi1.adjustedOrderItem.id;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedAmount =
          oi1.adjustedAmount;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).isFullRefund =
          oi1.isFullRefund();

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.LegacyAdjustment: {
        orderItemSerializable = new contracts.LegacyAdjustmentOrderItemSerializable();
        const oi2 = orderItem as models.LegacyAdjustmentOrderItem;

        (
          orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable
        ).membershipPeriodId = oi2.membershipPeriodId;
        (orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable).adjustedAmount =
          oi2.adjustedAmount;
        (orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable).isFullRefund =
          oi2.isFullRefund();

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.Product: {
        orderItemSerializable = new contracts.ProductOrderItemSerializable();
        const oi3 = orderItem as models.ProductOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi3.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi3.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi3.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi3.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi3.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).clientName =
          oi3.client.name;
        orderItemSerializable.basePrice = oi3.product.amount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi3.quantity;
        // orderItemSerializable.promotionItem = oi.promotionItem;
        orderItemSerializable.promotionItemId =
          oi3.promotionItem !== null ? oi3.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi3.promotionAmount;

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.Membership: {
        orderItemSerializable = new contracts.MembershipOrderItemSerializable();
        const oi4 = orderItem as models.MembershipOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi4.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi4.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi4.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi4.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi4.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.MembershipOrderItemSerializable).salesAction =
          oi4.salesAction;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).clientName =
          oi4.client.name;
        orderItemSerializable.basePrice = oi4.product.amount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi4.quantity;
        orderItemSerializable.promotionItemId =
          oi4.promotionItem !== null ? oi4.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi4.promotionAmount;
        if (oi4.membershipClientService && oi4.membershipClientService !== null) {
          if (!(oi4.membershipClientService instanceof models.LegacyMembershipClientService)) {
            (
              orderItemSerializable as contracts.MembershipOrderItemSerializable
            ).managedClientServiceId = oi4.membershipClientService.clientServiceId;
          } else {
            (
              orderItemSerializable as contracts.MembershipOrderItemSerializable
            ).managedLsegacyMembershipPeriodId = oi4.membershipClientService.clientServiceId;
          }
        }

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.GroupMembership:
      case models.OrderItemTypeEnum.GroupMembershipSlot:
        orderItemSerializable = new contracts.GroupMembershipOrderItemSerializable();
        const oi5 = orderItem as models.GroupMembershipOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi5.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi5.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi5.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi5.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi5.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).clientName =
          oi5.client.name;
        orderItemSerializable.basePrice = oi5.product.amount;
        orderItemSerializable.promotionItemId =
          oi5.promotionItem !== null ? oi5.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi5.promotionAmount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).slotsCount =
          oi5.slotsCount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi5.quantity;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).salesAction =
          oi5.salesAction;
        if (oi5.membershipClientService && oi5.membershipClientService !== null) {
          if (!(oi5.membershipClientService instanceof models.LegacyMembershipClientService)) {
            (
              orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
            ).managedClientServiceId = oi5.membershipClientService.clientServiceId;
          } else {
            (
              orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
            ).managedLsegacyMembershipPeriodId = oi5.membershipClientService.clientServiceId;
          }
        }
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).beneficiaries =
          oi5.beneficiaries.filter((i) => i !== null).map((i) => i.id);

        orderItemSerializable.orderItemType = models.OrderItemTypeEnum.Product;

        break;

      case models.OrderItemTypeEnum.CustomTerm:
        orderItemSerializable = new contracts.CustomTermOrderItemSerializable();
        const oi6 = orderItem as models.CustomTermOrderItem;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).termMonths =
          oi6.termMonths;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).termDays =
          oi6.termDays;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).totalPrice =
          oi6.totalPrice;

        orderItemSerializable.orderItemType = models.OrderItemTypeEnum.CustomTerm;
        break;

      case models.OrderItemTypeEnum.FlightCredit: {
        orderItemSerializable = new contracts.FlightCreditOrderItemSerializable();
        const oi7 = orderItem as models.FlightCreditAdjustmentOrderItem;
        (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).flightCreditsAmount =
          oi7.flightCreditAmount;
        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.Concierge: {
        orderItemSerializable = new contracts.ConciergeOrderItemSerializable();
        const oi8 = orderItem as models.ConciergeOrderItem;

        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).salesAction =
          models.SalesActionEnum.New;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productId =
          oi8.product.id;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productTypeId =
          oi8.product.productType.id;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productName =
          oi8.product.name;
        (
          orderItemSerializable as contracts.ConciergeOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi8.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).quantity = oi8.quantity;

        // (orderItemSerializable as contracts.ConciergeOrderItemSerializable).clientName = oi8.client.name;

        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).requestId =
          oi8.requestId;
        if (oi8.requestId === 0) {
          (orderItemSerializable as contracts.ConciergeOrderItemSerializable).serviceDate =
            this.datePipe.transform(oi8.serviceDate, 'y-MM-dd');
        }
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).departureDate =
          oi8.departureDate;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).vendorId = oi8.vendorId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).vendorRefNumber =
          oi8.vendorRefNumber;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).quoteAmount =
          oi8.quoteAmount;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).markupPercent =
          oi8.markupPercent;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).clientComments =
          oi8.clientComments;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).comments = oi8.comments;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).surcharge =
          oi8.surcharge;

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      case models.OrderItemTypeEnum.Charter: {
        orderItemSerializable = new contracts.CharterOrderItemSerializable();
        const oi9 = orderItem as models.CharterOrderItem;

        (orderItemSerializable as contracts.CharterOrderItemSerializable).salesAction =
          models.SalesActionEnum.New;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productId =
          oi9.product.id;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productTypeId =
          oi9.product.productType.id;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productName =
          oi9.product.name;
        (
          orderItemSerializable as contracts.CharterOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi9.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).quantity = oi9.quantity;
        orderItemSerializable.basePrice = oi9.product.amount;

        (orderItemSerializable as contracts.CharterOrderItemSerializable).requestId = oi9.requestId;
        // (orderItemSerializable as contracts.CharterOrderItemSerializable).departureDate = oi9.departureDate;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).purchaseOrderNumber =
          oi9.purchaseOrderNumber;
        // (orderItemSerializable as contracts.CharterOrderItemSerializable).formUrl = oi9.formUrl;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).formUrlKey =
          oi9.formUrlKey;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).charterInvoiceType =
          oi9.charterInvoiceType;

        orderItemSerializable.orderItemType = orderItem.orderItemType;

        break;
      }

      default: {
        throw new Error(`Wrong orderItem.orderItemType: ${orderItem.orderItemType}`);
      }
    }

    orderItemSerializable.id = orderItem.id;
    orderItemSerializable.orderId = orderItem.orderId;
    orderItemSerializable.clientId = orderItem.client ? orderItem.client.id : null;
    orderItemSerializable.discount = orderItem.discount;
    // orderItemSerializable.promotion = orderItem.promotion;
    orderItemSerializable.totalPrice = orderItem.totalPrice;
    orderItemSerializable.notes = orderItem.notes;
    // orderItemSerializable.orderItemType = orderItem.orderItemType;

    if (orderItem.parentOrderItem) {
      orderItemSerializable.parentOrderItemId = orderItem.parentOrderItem.id;
    }

    return orderItemSerializable;
  }

  cancelOrder(
    request: serviceContracts.CancelOrderRequest
  ): Observable<serviceContracts.CancelOrderResponse> {
    throw new Error('Not implemented');
  }

  reinstateOrder(
    request: serviceContracts.ReinstateOrderRequest
  ): Observable<serviceContracts.ReinstateOrderResponse> {
    return of(null);
  }

  createOrder(
    request: serviceContracts.CreateOrderRequest
  ): Observable<serviceContracts.CreateOrderResponse> {
    const el = createOrderResponse;
    const { orderId, invoiceId, invoiceUuid, externalInvoiceId } = el;

    const response = new serviceContracts.CreateOrderResponse();
    response.orderId = orderId;
    response.invoice = new models.Invoice();
    response.invoice.id = invoiceId;
    response.invoice.orderId = orderId;
    response.invoice.uuid = invoiceUuid;
    response.invoice.legacyFormUrlKey = externalInvoiceId;

    return of(response).pipe(delay(3000));
  }

  getOrder(
    request: serviceContracts.GetOrderRequest
  ): Observable<serviceContracts.GetOrderResponse> {
    const response = new serviceContracts.GetOrderResponse();

    const el =
      request.orderId === 1
        ? getOrderDataWithSurchargeResponse
        : request.orderId === 7651
        ? getCharterOrderDataResponse
        : getOrderDataResponse;

    response.entity = mappers.OrderMapper.parseOrder(el);

    return of(response);
  }

  markPaid(
    request: serviceContracts.MarkPaidRequest
  ): Observable<serviceContracts.MarkPaidResponse> {
    throw new Error('Not implemented');
  }

  arPaymentConfirm(
    request: serviceContracts.MarkPaidRequest
  ): Observable<serviceContracts.MarkPaidResponse> {
    throw new Error('Not implemented');
  }

  orderList(
    request: serviceContracts.OrderListRequest
  ): Observable<serviceContracts.OrderListResponse> {
    const svcJsonResp = getOrderListResponse;

    const response = new serviceContracts.OrderListResponse();

    if (svcJsonResp.data.accountingReportAcl) {
      response.accountingReportAcl = svcJsonResp.data.accountingReportAcl;
    }

    response.orders = svcJsonResp.data.orders.map((e) => mappers.OrderListMapper.parseOrder(e));

    response.count = svcJsonResp.data.totalOrders;

    return of(response);
  }

  downloadReport(): Observable<serviceContracts.DownloadReportResponse> {
    throw new Error('Not implemented');
  }

  changeOrderPaymentMethod(): Observable<serviceContracts.ChangeOrderPaymentMethodResponse> {
    const response = new serviceContracts.ChangeOrderPaymentMethodResponse();
    return of(response).pipe(delay(1000));
  }

  reGenerateDocument(
    request: serviceContracts.ReGenerateRequest
  ): Observable<serviceContracts.ReGenerateResponse> {
    const resp = new serviceContracts.ReGenerateResponse();
    resp.documentGenerationInProgress = false;
    resp.showGeneratedDocumentsPanel = false;
    resp.entities = generatedDocumentResponse.data.map((e) =>
      mappers.GeneratedDocumentMapper.parseGeneratedDocument(e)
    );
    return of(resp).pipe(delay(1000));
  }

  getDocumentsByOrder(
    request: serviceContracts.GetDocumentsByOrderRequest
  ): Observable<serviceContracts.GetDocumentsByOrderResponse> {
    const resp = new serviceContracts.GetDocumentsByOrderResponse();
    resp.documentGenerationInProgress = true;
    resp.showGeneratedDocumentsPanel = true;
    resp.entities = generatedDocumentResponse.data.map((e) =>
      mappers.GeneratedDocumentMapper.parseGeneratedDocument(e)
    );
    return of(resp).pipe(delay(1000));
  }

  // orderStatusList(request: serviceContracts.OrderStatusListRequest): Observable<serviceContracts.OrderStatusListResponse> {
  //   throw new Error('Not implemented');
  // }

  isProformaInvoice(orderId: number): Observable<serviceContracts.ProformaInvoiceResponse> {
    return of(new serviceContracts.ProformaInvoiceResponse());
  }

  isAmendChargesRestricted(
    userId: number
  ): Observable<serviceContracts.AmendChargesPermissionResponse> {
    return of(new serviceContracts.AmendChargesPermissionResponse());
  }

  createSfdcOpportunity(
    request: serviceContracts.CreateSfdcOpportunityRequest
  ): Observable<serviceContracts.CreateSfdcOpportunityResponse> {
    return of(null);
  }
}
