import { User } from './user';

export class OfferModerationHistoryItem {
  user: User;
  date: Date;
  approverComment: string;
  offerComment: string;
  recommended: boolean;
  offerPrice: number;
  flightPrice: number;
}
