import * as uuid from 'uuid';

import { Client } from './client';
import { Note } from './note';
import { OpportunityStatus } from './opportunity-status';
import { PurchaseQuote } from './purchase-quote';
import { SalesQuote } from './sales-quote';
import { SalesQuoteRequest } from './sales-quote-request';
import { SmartSalesQuote } from './smart-sales-quote';
import { User } from './user';
import { ZendeskIntegration } from './zendesk-integration';

export class Opportunity {
  opportunityId: string = uuid.v4();
  subCharter: boolean;
  client: Client;
  zendeskIntegration: ZendeskIntegration;
  salesQuoteRequests: Array<SalesQuoteRequest> = [];
  purchaseQuotes: Array<PurchaseQuote> = [];
  salesQuotes: Array<SalesQuote> = [];
  smartSalesQuotes: Array<SmartSalesQuote> = [];
  salesAgent: User = new User();
  sourcingSpecialist: User = new User();
  status: OpportunityStatus;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  createdDate: Date;
  createdUser: User;
  notes: string;
  from: string;
  to: string;
  departureDate: Date;

  constructor() {
    this.zendeskIntegration = new ZendeskIntegration();
    this.purchaseQuotes = [];
    this.salesQuotes = [];
  }

  getSubCharter(): boolean {
    return this.subCharter;
  }

  setSubCharter(subCharter: boolean): Opportunity {
    this.subCharter = subCharter;
    return this;
  }

  getNotes(): string {
    return this.notes;
  }

  setNotes(notes: string): Opportunity {
    this.notes = notes;
    return this;
  }

  getSourcingSpecialist(): User {
    return this.sourcingSpecialist;
  }

  setSourcingSpecialist(sourcingSpecialist: User): Opportunity {
    this.sourcingSpecialist = sourcingSpecialist;
    return this;
  }

  getOpportunityId(): string {
    return this.opportunityId;
  }

  setOpportunityId(opportunityId: string): Opportunity {
    this.opportunityId = opportunityId;

    return this;
  }

  getClient(): Client {
    return this.client;
  }

  setClient(client: Client): Opportunity {
    this.client = client;

    return this;
  }

  getZendeskIntegration(): ZendeskIntegration {
    return this.zendeskIntegration;
  }

  setZendeskIntegration(zendeskIntegration: ZendeskIntegration): Opportunity {
    this.zendeskIntegration = zendeskIntegration;

    return this;
  }

  getSalesQuoteRequests(): Array<SalesQuoteRequest> {
    return this.salesQuoteRequests;
  }

  setSalesQuoteRequests(salesQuoteRequests: Array<SalesQuoteRequest>): Opportunity {
    this.salesQuoteRequests = salesQuoteRequests;

    return this;
  }

  getPurchaseQuotes(): Array<PurchaseQuote> {
    return this.purchaseQuotes;
  }

  setPurchaseQuotes(purchaseQuotes: Array<PurchaseQuote>): Opportunity {
    this.purchaseQuotes = purchaseQuotes;

    return this;
  }

  getSalesQuotes(): Array<SalesQuote> {
    return this.salesQuotes;
  }

  setSalesQuotes(salesQuotes: Array<SalesQuote>): Opportunity {
    this.salesQuotes = salesQuotes;

    return this;
  }

  getSmartSalesQuotes(): Array<SmartSalesQuote> {
    return this.smartSalesQuotes;
  }

  setSmartSalesQuotes(smartSalesQuotes: Array<SmartSalesQuote>): Opportunity {
    this.smartSalesQuotes = smartSalesQuotes;

    return this;
  }

  getSalesAgent(): User {
    return this.salesAgent;
  }

  setSalesAgent(salesAgent: User): Opportunity {
    this.salesAgent = salesAgent;

    return this;
  }

  getStatus(): OpportunityStatus {
    return this.status;
  }

  setStatus(status: OpportunityStatus): Opportunity {
    this.status = status;

    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): Opportunity {
    this.lastModifiedDate = lastModifiedDate;

    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): Opportunity {
    this.lastModifiedUser = lastModifiedUser;

    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createddDate: Date): Opportunity {
    this.createdDate = createddDate;

    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): Opportunity {
    this.createdUser = createdUser;

    return this;
  }

  isValidForSubmit(): boolean {
    let valid = true;

    if (this.getSalesQuoteRequests() === null) {
      return false;
    }

    valid =
      valid &&
      this.getSalesQuoteRequests()[0].getClient() !== undefined &&
      this.getSalesQuoteRequests()[0].getClient() !== null;
    valid =
      valid &&
      this.getSalesQuoteRequests()[0].getAircraftCategory() !== undefined &&
      this.getSalesQuoteRequests()[0].getAircraftCategory() !== null;

    if (this.getSalesQuoteRequests()[0].getLegs().length === 0) {
      return false;
    }

    for (const lr1 of this.getSalesQuoteRequests()[0].getLegs()) {
      valid = valid && lr1.isValidForSubmit();
    }

    return valid;
  }
}
