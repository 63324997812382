import { Inject, Injectable } from '@angular/core';
import * as contracts from '../../../domain/service-contracts/product';
import * as sharedTypes from '../../../../../shared/types';

import * as models from '../../../domain/models';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { response as getProductDataResponse } from './get-product-data-response';
import { response as getVendorListResponse } from './get-vendor-list-response';

@Injectable()
export class ProductMockService implements contracts.IProductService {
  // private productList: Array<models.Product>;
  // private promoList: Array<models.Promotion>;
  //

  getProductData(
    request: contracts.GetProductDataRequest
  ): Observable<contracts.GetProductDataResponse> {
    // const response = new contracts.GetProductDataResponse();
    //
    // response.entities = this.productList;
    // response.productData.promotions = this.promoList;

    const products: Array<models.Product> = [];

    const svcJsonResp = getProductDataResponse;

    const response = new contracts.GetProductDataResponse();
    response.entities = [];

    svcJsonResp.data.products
      .filter((p) => p.securityAccess !== models.SecurityAccessEnum.Denied)
      .forEach((el) => {
        const product = new models.Product();
        product.id = Number(el.productId);
        product.isRefundable = Boolean(el.isRefundable);
        product.name = el.productName;
        product.description = el.description;
        product.amount = sharedTypes.Util.nvlToNumber(el.basePrice);
        // product.productTypeId = Number(el.productTypeId);
        product.parentProductId = Number(el.parentProductId);
        product.priceComponents = [];
        product.productFeatures = [];
        product.securityAccess = el.securityAccess;

        product.productType = new models.ProductType();
        product.productType.id = Number(el.productTypeId); // product.productTypeId;
        product.productType.name = el.productTypeName;
        product.productType.description = el.productTypeDescription;
        product.productType.parentProductTypeId = Number(el.productTypeParentProductTypeId);

        const priceComponents = svcJsonResp.data.priceComponents.filter(
          (pc) =>
            pc.productId === product.id && pc.securityAccess !== models.SecurityAccessEnum.Denied
        );
        priceComponents.forEach((elPC) => {
          const priceComponent = new models.PriceComponent();
          priceComponent.id = elPC.priceComponentId;
          priceComponent.typeId = elPC.priceComponentTypeId;
          priceComponent.typeName = elPC.priceComponentTypeName;
          priceComponent.percentage = sharedTypes.Util.nvlToNumber(elPC.percentage);
          priceComponent.amount = sharedTypes.Util.nvlToNumber(elPC.amount);
          priceComponent.description = elPC.description;
          priceComponent.name = elPC.priceComponentName;
          priceComponent.securityAccess = elPC.securityAccess;
          product.priceComponents.push(priceComponent);
        });

        const productPriceComponentBrackets = svcJsonResp.data.productPriceComponentBrackets.find(
          (pcm) => pcm.productId === product.id
        ) as models.ProductPriceComponentsBracket;
        if (productPriceComponentBrackets) {
          productPriceComponentBrackets.brackets.forEach((elPCM) => {
            const brackets = new models.PriceComponentsBracket();
            brackets.base = elPCM.base;
            brackets.priceComponentId = elPCM.priceComponentId;
            brackets.sequence = elPCM.sequence;
            product.priceComponentsBrackets.push(brackets);
          });
        }

        const productFeatures = svcJsonResp.data.productFeatures.filter(
          (pf) => pf.productId === product.id
        );
        productFeatures.forEach((elPC) => {
          const productFeature = new models.ProductFeature();
          productFeature.id = elPC.productFeatureId;
          productFeature.name = elPC.productFeatureName;
          productFeature.typeId = elPC.productFeatureTypeId;
          productFeature.productId = elPC.productId;
          productFeature.value = elPC.value.toString();
          product.productFeatures.push(productFeature);
        });

        products.push(product);
      });

    svcJsonResp.data.productDependencies.forEach((el) => {
      const productDependency = new models.MembershipProductDependency();
      productDependency.id = el.membershipProductDependencyId;
      productDependency.salesAction = Number(el.membershipSalesActionId);

      const childProductId = Number(el.childProductId);
      const childProduct = products.find((i) => i.id === childProductId);

      if (!childProduct) {
        throw new Error(
          `Can't find child product (id: ${childProductId}}) for Membership Product Dependency (id ${productDependency.id})`
        );
      }

      productDependency.childProduct = childProduct;
      productDependency.required = el.required;

      const parentProductId = Number(el.parentProductId);
      const parentProduct = products.find((i) => i.id === parentProductId);

      if (!parentProduct) {
        throw new Error(
          `Can't find parent product (id: ${parentProductId}}) for Membership Product Dependency (id ${productDependency.id})`
        );
      }

      parentProduct.productDependencies.push(productDependency);
    });

    if (svcJsonResp.data.consoleLoginCheck) {
      const consoleLoginPayload = new models.ConsoleLoginCheck();
      consoleLoginPayload.url = svcJsonResp.data.consoleLoginCheck.url;
      consoleLoginPayload.payload = svcJsonResp.data.consoleLoginCheck.payload;
      response.consoleLoginCheck = consoleLoginPayload;
    }

    response.entities = products;

    response.restrictions = svcJsonResp.data.restrictedFunctions.map((rf) => {
      const restrictedObj = new models.FunctionRestriction();
      restrictedObj.restrictedFunctionType = rf.restrictedFunctionId;
      restrictedObj.securityAccess = rf.securityAccess;

      return restrictedObj;
    });

    return of(response);
  }

  getVendorList(
    request: contracts.GetVendorListRequest
  ): Observable<contracts.GetVendorListResponse> {
    let vendors: Array<models.Vendor> = [];

    const svcJsonResp = getVendorListResponse;

    vendors = svcJsonResp.data.map((v) => {
      const vendor = new models.Vendor();
      vendor.id = v.vendorId;
      vendor.name = v.vendorName;
      vendor.products = v.products.map((vp) => {
        const productRef = new models.VendorProduct();
        productRef.productId = vp.productId;
        productRef.markupPercent = Number(vp.markupPercent);

        return productRef;
      });

      return vendor;
    });

    const response = new contracts.GetVendorListResponse();
    response.entities = vendors;

    return of(response).pipe(delay(1000));
  }

  // private init(): void {
  //   this.productList = [];
  //
  //   const p1 = new models.Product();
  //   p1.id = 1;
  //   p1.name = 'Simple Membership 12 Months';
  //   p1.amount = 5000;
  //   this.productList.push(p1);
  //
  //   const p2 = new models.Product();
  //   p2.id = 2;
  //   p2.name = 'Smart Membership 12 Months';
  //   p2.amount = 15000;
  //   this.productList.push(p2);
  //
  //   const p3 = new models.Product();
  //   p3.id = 3;
  //   p3.name = 'Sophisticated Membership 12 Months';
  //   p3.amount = 50000;
  //   this.productList.push(p3);
  //
  //   this.promoList = [];
  //
  //   let p = new models.Promotion();
  //   p.id = 1;
  //   p.code = 'POKER';
  //   p.amount = 500;
  //   this.promoList.push(p);
  //
  //   p = new models.Promotion();
  //   p.id = 2;
  //   p.code = 'FB';
  //   p.amount = 1000;
  //   this.promoList.push(p);
  //
  //   p = new models.Promotion();
  //   p.id = 1;
  //   p.code = 'BASEL';
  //   p.amount = 1500;
  //   this.promoList.push(p);
  // }
}
