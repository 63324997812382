import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[One-Way Quote Status Collection] Load';
export const LOAD_SUCCESS = '[One-Way Quote Status Collection] Load Success';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.OneWayQuoteStatus>) {}
}

export type Actions = LoadAction | LoadSuccessAction;
