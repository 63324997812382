export const response = {
  user: {
    phone: null,
    email: 'inapp.purchase.recognizer@jetsmarter.com',
    id: 252,
    firstName: 'InApp Purchase',
    lastName: 'Recognizer',
  },
  totalPrice: 7950,
  canChangePaymentType: true,
  invoices: [
    {
      orderId: 1,
      invoiceId: 935,
      uuid: 'cdae37b8-e99d-4009-ba0f-0435b734ce13',
      legacyFormUrl: 'http://localhost:5200/forms/receipt/index.html?2176332143762713',
      legacyFormUrlKey: '2176332143762713',
      legacyFormEditUrl:
        'http://console.vista.studio/forms/flightinvoice/edit/?014b5285-4c94-25ff-be4b-2bd0080379de',
      userId: 252,
      invoiceExternalId: null,
      invoiceTypeId: 1,
      clientId: 1,
      amount: 7950,
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-04-02T18:38:59.331435+00:00',
        membershipTimeStarted: '2018-04-02T18:42:47.101845+00:00',
        name: 'Kate Sok',
        futureMembershipTimeStarted: null,
        dob: '1986-12-06',
        membershipTotal: 4950,
        membershipDisplayName: 'ENTERPRISE',
        membershipTypeId: 44,
        futureMembershipTypeId: null,
        phone: 79856283636,
        futureMembershipTimeExpiring: null,
        email: 'ksent@test.ru',
        brokerid: 0,
        membershipTimeExpiring: '2019-04-02T18:42:47.101845+00:00',
        legalName: 'Kate Sok',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1,
      },
      orderItemId: 1881,
      orderSourceId: 2,
      legacyFormTplId: 3,
      invoiceStatusId: 4,
      invoiceTypeName: 'Main Invoice',
      createTime: '2018-09-24T18:39:40.208364+00:00',
      parentInvoiceId: null,
      invoiceReplacements: [
        {
          formTplId: 323,
          name: 'Invoice, no T&C',
        },
        {
          formTplId: 350,
          name: 'Membership Invoice',
        },
      ],
    },
  ],
  items: [
    {
      orderId: 1,
      parentOrderItem: null,
      promotionAmount: null,
      orderItemTypeId: 2,
      notes: null,
      membershipRequest: {
        orderItemId: 1881,
        salesAction: {
          name: 'New',
          id: 1,
        },
        productName: 'Smart $15,000',
        expirationTime: '2018-09-27T18:39:20.723169+00:00',
        expired: false,
        createTime: '2018-09-24T18:39:26.203136+00:00',
      },
      id: 1881,
      product: {
        productId: 3,
        productName: 'Smart $15,000',
      },
      childOrderItems: [
        {
          product: {
            productId: 44,
            productName: 'Initiation Fee (Smart)',
          },
          totalPrice: 3000,
          orderItemId: 1882,
          orderItemTypeId: 1,
          beneficiaryPersonId: 1,
          client: {
            futureMembershipTotal: null,
            clientCreateTime: '2018-04-02T18:38:59.331435+00:00',
            membershipTimeStarted: '2018-04-02T18:42:47.101845+00:00',
            name: 'Kate Sok',
            futureMembershipTimeStarted: null,
            dob: '1986-12-06',
            membershipTotal: 4950,
            membershipDisplayName: 'ENTERPRISE',
            membershipTypeId: 44,
            futureMembershipTypeId: null,
            phone: 79856283636,
            futureMembershipTimeExpiring: null,
            email: 'ksent@test.ru',
            brokerid: 0,
            membershipTimeExpiring: '2019-04-02T18:42:47.101845+00:00',
            legalName: 'Kate Sok',
            personalManager: 1000001,
            futureMembershipDisplayName: null,
            id: 1,
          },
          basePrice: 3000,
          productFeatureId: null,
          quantity: 1,
        },
      ],
      membership: {
        startDate: '2018-04-02T18:39:42.518734+00:00',
        activateTime: '2018-09-24T18:40:12.514543+00:00',
        salesAction: {
          name: 'New',
          id: 1,
        },
        typeName: 'Smart',
        endDate: '2018-04-02T18:40:42.518734+00:00',
        id: 817,
      },
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-04-02T18:38:59.331435+00:00',
        membershipTimeStarted: '2018-04-02T18:42:47.101845+00:00',
        name: 'Kate Sok',
        futureMembershipTimeStarted: null,
        dob: '1986-12-06',
        membershipTotal: 4950,
        membershipDisplayName: 'ENTERPRISE',
        membershipTypeId: 44,
        futureMembershipTypeId: null,
        phone: 79856283636,
        futureMembershipTimeExpiring: null,
        email: 'ksent@test.ru',
        brokerid: 0,
        membershipTimeExpiring: '2019-04-02T18:42:47.101845+00:00',
        legalName: 'Kate Sok',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1,
      },
      basePrice: 4950,
      conciergeInfo: null,
      quantity: 1,
      promotionItemId: null,
      surcharge: {
        typeId: 9,
        name: 'InApp Purchase Surcharge',
        promoCode: null,
        typeName: 'InApp Purchase Surcharge',
        amount: 1000,
        percentage: null,
        id: 70,
      },
    },
  ],
  notes: null,
  id: 1,
  orderStatusId: 5,
  sapExportStatus: [
    {
      statusId: 3,
      statusDescription: 'This order is suppressed',
      submissionTypeId: 1
    }
  ],
  partyId: 1,
  orderBilling: {
    client: {
      futureMembershipTotal: null,
      clientCreateTime: '2018-04-02T18:38:59.331435+00:00',
      membershipTimeStarted: '2018-04-02T18:42:47.101845+00:00',
      name: 'Kate Sok',
      futureMembershipTimeStarted: null,
      dob: '1986-12-06',
      membershipTotal: 4950,
      membershipDisplayName: 'ENTERPRISE',
      membershipTypeId: 44,
      futureMembershipTypeId: null,
      phone: 79856283636,
      futureMembershipTimeExpiring: null,
      email: 'ksent@test.ru',
      brokerid: 0,
      membershipTimeExpiring: '2019-04-02T18:42:47.101845+00:00',
      legalName: 'Kate Sok',
      personalManager: 1000001,
      futureMembershipDisplayName: null,
      id: 1,
    },
  },
  expirationTime: '2018-09-27T18:39:20.723169+00:00',
  promotionId: null,
  expired: false,
  createTime: '2018-09-24T18:39:22.788713+00:00',
  orderSourceId: 2,
  charterSalesOpportunityId: 'c84dfd8d-4884-475d-be0c-448f2626db56',
};
