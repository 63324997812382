import * as models from '../../../models';

import * as _ from 'lodash';

export class UserMapperService {
  static mapUserDtoToInternal(svcJsonResp: any): models.User {
    const user = new models.User();
    user.firstName = svcJsonResp.firstName;
    user.lastName = svcJsonResp.lastName;
    user.id = svcJsonResp.userId;
    user.userTypeId = svcJsonResp.userTypeId;
    user.emailAddress = svcJsonResp.emailAddress;

    const organization = new models.Organization();
    organization.id = Number(svcJsonResp.organizationId);
    user.organization = organization;

    if(svcJsonResp.restrictedFunctions) {
      let restrictions = [];
      restrictions = svcJsonResp.restrictedFunctions.map((rf) => {
        const restrictedObj = new models.RestrictedFunction();
        restrictedObj.description = rf.description;
        restrictedObj.restrictedFunctionId = rf.restrictedFunctionId;
        restrictedObj.restrictedFunctionName = rf.restrictedFunctionName;
        restrictedObj.securityAccess = rf.securityAccess;

        return restrictedObj;
      });
      user.restrictedFunctions = restrictions;
    }
    const viewPermissions = new models.Permissions();

    viewPermissions.clientsModule.overall = _.get(svcJsonResp, 'viewPermissions.clientsModule.overall', false);
    viewPermissions.clientsModule.lookup = _.get(svcJsonResp, 'viewPermissions.clientsModule.lookup', false);
    viewPermissions.clientsModule.universalSearch = _.get(svcJsonResp, 'viewPermissions.clientsModule.universalSearch', false);

    viewPermissions.charterSalesModule.overall = _.get(svcJsonResp, 'viewPermissions.charterSalesModule.overall', false);
    viewPermissions.charterSalesModule.opportunities = _.get(svcJsonResp, 'viewPermissions.charterSalesModule.opportunities', false);
    viewPermissions.charterSalesModule.newOpportunities =
      _.get(svcJsonResp, 'viewPermissions.charterSalesModule.newOpportunities', false);
    viewPermissions.charterSalesModule.myOpportunities =
      _.get(svcJsonResp, 'viewPermissions.charterSalesModule.myOpportunities', false);

    viewPermissions.explorerModule.search = _.get(svcJsonResp, 'viewPermissions.explorerModule.search', false);

    viewPermissions.marketplaceModule.overall = _.get(svcJsonResp, 'viewPermissions.marketplaceModule.overall', false);
    viewPermissions.marketplaceModule.oneWayQuotes = _.get(svcJsonResp, 'viewPermissions.marketplaceModule.oneWayQuotes', false);

    viewPermissions.resourceModule.overall = _.get(svcJsonResp, 'viewPermissions.resourceModule.overall', false);
    viewPermissions.resourceModule.aircraft = _.get(svcJsonResp, 'viewPermissions.resourceModule.aircraft', false);
    viewPermissions.resourceModule.airports = _.get(svcJsonResp, 'viewPermissions.resourceModule.airports', false);
    viewPermissions.resourceModule.applicationConfigs = _.get(svcJsonResp, 'viewPermissions.resourceModule.applicationConfigs', false);
    viewPermissions.resourceModule.areas = _.get(svcJsonResp, 'viewPermissions.resourceModule.areas', false);
    viewPermissions.resourceModule.markets = _.get(svcJsonResp, 'viewPermissions.resourceModule.markets', false);
    viewPermissions.resourceModule.regions = _.get(svcJsonResp, 'viewPermissions.resourceModule.regions', false);
    viewPermissions.resourceModule.vendors = _.get(svcJsonResp, 'viewPermissions.resourceModule.vendors', false);
    viewPermissions.resourceModule.users = _.get(svcJsonResp, 'viewPermissions.resourceModule.users', false);

    viewPermissions.salesModule.overall = _.get(svcJsonResp, 'viewPermissions.salesModule.overall', false);
    viewPermissions.salesModule.new = _.get(svcJsonResp, 'viewPermissions.salesModule.new', false);
    viewPermissions.salesModule.orders = _.get(svcJsonResp, 'viewPermissions.salesModule.orders', false);
    viewPermissions.salesModule.pricingCalculator = _.get(svcJsonResp, 'viewPermissions.salesModule.pricingCalculator', false);
    viewPermissions.salesModule.pricingComparison = _.get(svcJsonResp, 'viewPermissions.salesModule.pricingComparison', false);

    viewPermissions.sourcingModule.overall = _.get(svcJsonResp, 'viewPermissions.sourcingModule.overall', false);
    viewPermissions.sourcingModule.requests = _.get(svcJsonResp, 'viewPermissions.sourcingModule.requests', false);
    viewPermissions.sourcingModule.myRequests = _.get(svcJsonResp, 'viewPermissions.sourcingModule.myRequests', false);
    viewPermissions.sourcingModule.new = _.get(svcJsonResp, 'viewPermissions.sourcingModule.new', false);


    viewPermissions.taskMgmtModule.overall = _.get(svcJsonResp, 'viewPermissions.taskMgmtModule.overall', false);
    viewPermissions.taskMgmtModule.tasks = _.get(svcJsonResp, 'viewPermissions.taskMgmtModule.tasks', false);

    viewPermissions.yieldMgmtModule.overall = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.overall', false);
    viewPermissions.yieldMgmtModule.heatMap = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.heatMap', false);
    viewPermissions.yieldMgmtModule.configurations = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.configurations', false);
    viewPermissions.yieldMgmtModule.seatCharts = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.seatCharts', false);
    viewPermissions.yieldMgmtModule.seatPricing = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.seatPricing', false);
    viewPermissions.yieldMgmtModule.cyoPricing = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.cyoPricing', false);
    viewPermissions.yieldMgmtModule.charterPricing = _.get(svcJsonResp, 'viewPermissions.yieldMgmtModule.charterPricing', false);

    viewPermissions.userMgmtModule.overall = _.get(svcJsonResp, 'viewPermissions.userMgmtModule.overall', false);
    viewPermissions.userMgmtModule.users = _.get(svcJsonResp, 'viewPermissions.userMgmtModule.users', false);

    user.viewPermissions = viewPermissions;

    return user;
  }
}
