import { AccountRoleEnum } from './account-role-enum';
import { ClientService } from './client-service';
import { ClientServiceStatusEnum } from './client-service-status';
import { ClientServiceTypeEnum } from './client-service-type';
import { MembershipClientService } from './membership-client-service';
import { Order } from './order';
import { OrderItem } from './order-item';
import { OrderStatusEnum } from './order-status';
import * as coreModels from '../../../../core/models';

export class Client {
  id: number = null;
  name: string = null;
  legalName: string = null;
  dob: Date = null;
  address: string = null;
  state: string = null;
  phone: string = null;
  email: string = null;
  status: string = null;
  flightCreditAmount: number = null;
  referredByClient: Client = null;
  dba: coreModels.Dba = null;

  isSelectableAsBeneficiary = false;
  isSelectedAsBeneficiary = false;

  accountRoleId: AccountRoleEnum = null;
  accountClients: Array<Client> = [];

  clientServices: Array<ClientService> = [];
  clientServiceOrderItem: { [key: number]: OrderItem } = [];

  orders: Array<Order> = [];

  // get currentMembership(): MembershipClientService {
  //   if (this.memberships.length > 0) {
  //     const currentDate = new Date();
  //
  //     const membershipClientService = this.memberships
  //       .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
  //       .find(i => i.startDate <= currentDate && i.endDate > currentDate);
  //
  //     return membershipClientService ? membershipClientService : null;
  //   }
  //
  //   return null;
  // }

  get currentMembership(): MembershipClientService {
    if (this.memberships.length > 0) {
      const currentDate = new Date();

      const membershipClientService = this.memberships
        .filter(
          (i: ClientService) =>
            i.clientServiceType === ClientServiceTypeEnum.Membership ||
            i.clientServiceType === ClientServiceTypeEnum.GroupMembership ||
            i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
        )
        .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
        .find((i) => i.startDate <= currentDate && i.endDate > currentDate);

      return membershipClientService ? membershipClientService : null;
    }

    return null;
  }

  get allCurrentMemberships(): Array<ClientService> {
    if (this.memberships.length > 0) {
      const currentDate = new Date();

      const membershipClientService = this.memberships
        .filter(
          (i: ClientService) =>
            i.clientServiceType === ClientServiceTypeEnum.Membership ||
            i.clientServiceType === ClientServiceTypeEnum.GroupMembership ||
            i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
        )
        .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
        .filter((i) => i.startDate <= currentDate && i.endDate > currentDate);

      return membershipClientService ? membershipClientService : [];
    }

    return null;
  }

  get allEmergentMemberships(): Array<ClientService> {
    // Current and Future Memberships
    if (this.memberships.length > 0) {
      const currentDate = new Date();

      const membershipClientService = this.memberships
        .filter(
          (i: ClientService) =>
            i.clientServiceType === ClientServiceTypeEnum.Membership ||
            i.clientServiceType === ClientServiceTypeEnum.GroupMembership ||
            i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
        )
        .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
        .filter((i) => i.endDate > currentDate);

      return membershipClientService ? membershipClientService : [];
    }

    return null;
  }

  get pendingOrders(): Array<Order> {
    return this.orders.filter((i: Order) => i.orderStatus === OrderStatusEnum.AwaitingFulfillment);
  }

  get pendingMemberships(): Array<ClientService> {
    return this.clientServices.filter(
      (i: ClientService) =>
        i.clientServiceStatus !== ClientServiceStatusEnum.Completed &&
        (i.clientServiceType === ClientServiceTypeEnum.Membership ||
          i.clientServiceType === ClientServiceTypeEnum.LegacyMembership)
    );
    // .map(i => i as MembershipClientService);
  }

  get memberships(): Array<MembershipClientService> {
    return this.clientServices
      .filter(
        (i) =>
          i.clientServiceType === ClientServiceTypeEnum.Membership ||
          i.clientServiceType === ClientServiceTypeEnum.GroupMembership ||
          i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
      )
      .map((i) => i as MembershipClientService);
  }
}
