import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import { CurrencyEnum } from '../../../../shared/models';

export const LOAD = '[Sourcing.SourcingRequest] Load';
export const LOAD_SUCCESS = '[Sourcing.SourcingRequest] Load Success';

export const CREATE_NGA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Create Nga Purchase Offer';
export const CREATE_MULTI_NGA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Create Multi Nga Purchase Offer';
export const CREATE_NGA_OFFER_AND_ACCEPT = '[Sourcing.SourcingRequest] Create Nga Offer Offer And Accept';
export const CREATE_GA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Create Ga Purchase Offer';
export const CREATE_PURCHASE_OFFER_SUCCESS = '[Sourcing.SourcingRequest] Create Purchase Offer Success';
export const CREATE_PURCHASE_OFFER_FAIL = '[Sourcing.SourcingRequest] Create Purchase Offer Fail';
export const SHOW_PURCHASE_OFFER_BID_DIALOG = '[Sourcing.SourcingRequest] Show Purchase Offer Bid Dialog';
export const CANCEL_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Cancel Purchase Offer';
export const CANCEL_PURCHASE_OFFER_SUCCESS ='[Sourcing.SourcingRequest] Cancel Purchase Offer Success';

export const UPDATE_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Update Purchase Offer';
export const UPDATE_PURCHASE_OFFER_SUCCESS ='[Sourcing.SourcingRequest] Update Purchase Offer Success';

export const ACCEPT_GA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Accept GA Purchase Offer';
export const ACCEPT_GA_PURCHASE_OFFER_SUCCESS = '[Sourcing.SourcingRequest] Accept GA Purchase Offer Success';
export const ACCEPT_GA_PURCHASE_OFFER_FAIL = '[Sourcing.SourcingRequest] Accept GA Purchase Offer Fail';

export const GET_PRICE_BREAKDOWN = '[Sourcing.SourcingRequest] Get Price Breakdown';
export const GET_PRICE_BREAKDOWN_SUCCESS = '[Sourcing.SourcingRequest] Get Price Breakdown Success';
export const GET_PRICE_BREAKDOWN_FAIL = '[Sourcing.SourcingRequest] Get Price Breakdown Fail';

export const GET_ELAC_QUOTES = '[Sourcing.SourcingRequest] Get Elac Quotes';
export const GET_ELAC_QUOTES_SUCCESS = '[Sourcing.SourcingRequest] Get Elac Quotes Success';
export const GET_ELAC_QUOTES_FAIL = '[Sourcing.SourcingRequest] Get Elac Quotes Fail';

export const GET_MARKETPLACE_QUOTES = '[Sourcing.SourcingRequest] Get Marketplace Quotes';
export const GET_MARKETPLACE_QUOTES_SUCCESS = '[Sourcing.SourcingRequest] Get Marketplace Quotes Success';
export const GET_MARKETPLACE_QUOTES_FAIL = '[Sourcing.SourcingRequest] Get Marketplace Quotes Fail';

export const CANCEL_SOURCING_REQUEST = '[Sourcing.SourcingRequest] Cancel Sourcing Request';
export const CANCEL_SOURCING_REQUEST_SUCCESS = '[Sourcing.SourcingRequest] Cancel Sourcing Request Success';

export const SEND_PURCHASE_OFFERS = '[Sourcing.SourcingRequest] Send Purchase Offers';
export const SEND_PURCHASE_OFFERS_SUCCESS = '[Sourcing.SourcingRequest] Send Purchase Offers Success';
export const SEND_PURCHASE_OFFERS_FAIL = '[Sourcing.SourcingRequest] Send Purchase Offers Fail';
export const SET_MODERATED_OFFERS = '[Sourcing.SourcingRequest] Set Moderated Offers';
export const SET_MODERATED_LEGS = '[Sourcing.SourcingRequest] Set Moderated Legs';

export const GET_TAX_BREAKDOWN = '[Sourcing.SourcingRequest] Get Tax Breakdown';
export const GET_TAX_BREAKDOWN_SUCCESS = '[Sourcing.SourcingRequest] Get Tax Breakdown Success';
export const GET_TAX_BREAKDOWN_FAIL = '[Sourcing.SourcingRequest] Get Tax Breakdown Fail';

export const GET_EFTS = '[Sourcing.SourcingRequest] Get Efts';
export const GET_EFTS_SUCCESS = '[Sourcing.SourcingRequest] Get Efts Success';
export const GET_EFTS_FAILED = '[Sourcing.SourcingRequest] Get Efts Failed';

export const BOOK_PROFORMA = '[Sourcing.SourcingRequest] Book Pro Forma';
export const BOOK_PROFORMA_SUCCESS = '[Sourcing.SourcingRequest] Book Pro Forma Success';
export const BOOK_PROFORMA_FAIL = '[Sourcing.SourcingRequest] Book Pro Forma Fail';

export const VALIDATE_NGA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Validate NGA Purchase Offer';
export const VALIDATE_GA_PURCHASE_OFFER = '[Sourcing.SourcingRequest] Validate GA Purchase Offer';
export const VALIDATE_PURCHASE_OFFER_SUCCESS = '[Sourcing.SourcingRequest] Validate Purchase Offer Success';
export const VALIDATE_PURCHASE_OFFER_FAIL = '[Sourcing.SourcingRequest] Validate Purchase Offer Fail';

export const RESET_VALIDATION_RESULTS = '[Sourcing.SourcingRequest] Reset Validation Results';

export const GET_CHARTER_AGREEMENT = '[Sourcing.SourcingRequest] Get Charter Agreement';
export const GET_CHARTER_AGREEMENT_SUCCESS = '[Sourcing.SourcingRequest] Get Charter Agreement Success';
export const GET_CHARTER_AGREEMENT_FAIL = '[Sourcing.SourcingRequest] Get Charter Agreement Fail';

export const SAVE_OFFER_COMMENT = '[Sourcing.SourcingRequest] Save Purchase Offer Comment';
export const SAVE_OFFER_COMMENT_SUCCESS = '[Sourcing.SourcingRequest] Save Purchase Offer Comment Success';
export const SAVE_OFFER_COMMENT_FAIL = '[Sourcing.SourcingRequest] Save Purchase Offer Comment Fail';

export const EXTEND_OFFER = '[Sourcing.SourcingRequest] Extend Offer';
export const EXTEND_OFFER_SUCCESS = '[Sourcing.SourcingRequest] Extend Offer Success';
export const EXTEND_OFFER_FAIL = '[Sourcing.SourcingRequest] Extend Offer Fail';

export const FULFILL_AND_SEND_OFFERS = '[Sourcing.SourcingRequest] Fulfill And Send Offers';
export const FULFILL_AND_SEND_OFFERS_SUCCESS = '[Sourcing.SourcingRequest]  Fulfill And Send Offers Success';
export const FULFILL_AND_SEND_OFFERS_FAIL = '[Sourcing.SourcingRequest]  Fulfill And Send Offer Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload: string) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: models.SourcingRequest) {}
}

export class CreateNgaPurchaseOfferPayload {
  constructor(
    public sourcingRequestId: string,
    public operatorUuids: Array<string>,
    public aircraftCategoryIds: Array<number>,
    public additionalInstructions: string,
  ) {}
}

export class CreateMultiNgaPurchaseOfferPayload {
  constructor(
    public camberOptions: Array<models.CamberOption>,
    public marketplaceOptions: Array<models.MarketplaceOption>,
  ) {}
}

export class CreateNgaOfferAndAcceptPayload {
  constructor(
    public sourcingRequestId: string,
    public operatorUuid: string,
    public offerPrice: number,
    public aircraftId: number,
    public additionalInstructions: string,
    public currency: CurrencyEnum,
    public creationType: models.CreationTypeEnum,
    public legs?: Array<models.LegRequest>,
    public flightPrice?: number,
    public quoteReferenceId?: string,
    public ownerAircraftApprovalAcquired?: boolean,
    public firmFlexDepartureFee?: number,
    public elacPriceInfo?: models.ElacPriceInfo,
  ) {}
}

export class CreateGaPurchaseOfferPayload {
  constructor(
    public sourcingRequestId: string,
    public operatorUuids: Array<string>,
    public offerPrice: number,
    public additionalInstructions: string,
    public currency: CurrencyEnum
  ) {}
}

export class ValidateNgaPurchaseOffer implements Action {
  readonly type = VALIDATE_NGA_PURCHASE_OFFER;
  constructor(public payload: CreateNgaPurchaseOfferPayload) {}
}

export class ValidateGaPurchaseOffer implements Action {
  readonly type = VALIDATE_GA_PURCHASE_OFFER;
  constructor(public payload: CreateGaPurchaseOfferPayload) {}
}

export class ValidatePurchaseOfferSuccess implements Action {
  readonly type = VALIDATE_PURCHASE_OFFER_SUCCESS;

  constructor(public payload: models.OfferValidationResults) {}
}

export class ValidatePurchaseOfferFail implements Action {
  readonly type = VALIDATE_PURCHASE_OFFER_FAIL;
}

export class ResetOfferValidationResults implements Action {
  readonly type = RESET_VALIDATION_RESULTS;
}
export class CreateNgaPurchaseOffer implements Action {
  readonly type = CREATE_NGA_PURCHASE_OFFER;
  constructor(public payload: CreateNgaPurchaseOfferPayload) {}
}

export class CreateMultiNgaPurchaseOffer implements Action {
  readonly type = CREATE_MULTI_NGA_PURCHASE_OFFER;
  constructor(public payload: CreateMultiNgaPurchaseOfferPayload) {}
}

export class CreateNgaOfferAndAccept implements Action {
  readonly type = CREATE_NGA_OFFER_AND_ACCEPT;
  constructor(public payload: CreateNgaOfferAndAcceptPayload) {}
}

export class CreateGaPurchaseOffer implements Action {
  readonly type = CREATE_GA_PURCHASE_OFFER;
  constructor(public payload: CreateGaPurchaseOfferPayload) {}
}

export class CreatePurchaseOfferSuccess implements Action {
  readonly type = CREATE_PURCHASE_OFFER_SUCCESS;
}

export class CreatePurchaseOfferFail implements Action {
  readonly type = CREATE_PURCHASE_OFFER_FAIL;
}


export class ShowPurchaseOfferBidDialog implements Action {
  readonly type = SHOW_PURCHASE_OFFER_BID_DIALOG;
  constructor(public payload: string) {}
}
export class CancelPurchaseOfferPayload {
  constructor(public sourcingRequestId: string, public operatorPurchaseOfferId: string) {}
}

export class CancelPurchaseOffer implements Action {
  readonly type = CANCEL_PURCHASE_OFFER;
  constructor(public payload: CancelPurchaseOfferPayload) {}
}

export class CancelPurchaseOfferSuccess implements Action {
  readonly type = CANCEL_PURCHASE_OFFER_SUCCESS;
}

export class UpdatePurchaseOfferPayload {
  constructor(
    public sourcingRequestId: string,
    public operatorPurchaseOfferId: string,
    public offerPrice: number,
    public aircraftId: number,
    public additionalInstructions: string,
    public currency: CurrencyEnum,
    public creationType: models.CreationTypeEnum,
    public legs?: Array<models.LegRequest>,
    public flightPrice?: number,
    public quoteReferenceId?: string,
    public ownerAircraftApprovalAcquired?: boolean,
    public firmFlexDepartureFee?: number,
  ) {}
}

export class UpdatePurchaseOffer implements Action {
  readonly type = UPDATE_PURCHASE_OFFER;
  constructor(public payload: UpdatePurchaseOfferPayload) {}
}

export class UpdatePurchaseOfferSuccess implements Action {
  readonly type = UPDATE_PURCHASE_OFFER_SUCCESS;
}

export class UpdateGaPurchaseOfferPayload {
  constructor(
    public operatorPurchaseOfferId: string,
    public aircraftId: number,
    public legs?: Array<models.LegRequest>,
    public ownerAircraftApprovalAcquired?: boolean,
    public additionalInstructions?: string,
    ) {}
}

export class UpdateGaPurchaseOffer implements Action {
  readonly type = ACCEPT_GA_PURCHASE_OFFER;
  constructor(public payload: UpdateGaPurchaseOfferPayload) {}
}

export class UpdateGaPurchaseOfferSuccess implements Action {
  readonly type = ACCEPT_GA_PURCHASE_OFFER_SUCCESS;
}

export class UpdateGaPurchaseOfferFail implements Action {
  readonly type = ACCEPT_GA_PURCHASE_OFFER_FAIL;
}

export class FulfillAndSendOffers implements Action {
  readonly type = FULFILL_AND_SEND_OFFERS;
}

export class FulfillAndSendOffersSuccess implements Action {
  readonly type = FULFILL_AND_SEND_OFFERS_SUCCESS;
}

export class FulfillAndSendOffersFail implements Action {
  readonly type = FULFILL_AND_SEND_OFFERS_FAIL;
}

export class SendPurchaseOffersPayload {
  constructor(
    public moderatedOffers: models.ModeratedOffers
  ) {}
}

export class SendPurchaseOffers implements Action {
  readonly type = SEND_PURCHASE_OFFERS;

  constructor(public payload: SendPurchaseOffersPayload) {}
}

export class SendPurchaseOffersSuccess implements Action {
  readonly type = SEND_PURCHASE_OFFERS_SUCCESS;
}

export class SendPurchaseOffersFail implements Action {
  readonly type = SEND_PURCHASE_OFFERS_FAIL;
}

export class SetModeratedOffersPayload {
  constructor(
    public offers: Array<models.ModeratedOfferInput>,
    public approverComment?: string
  ) {}
}

export class SetModeratedOffers implements Action {
  readonly type = SET_MODERATED_OFFERS;
  constructor(public payload: SetModeratedOffersPayload) {}
}

export class GetPriceBreakdownPayload {
  operatorPrice: number;
  operatorUuid: string;
  priceType: string;
  flightPrice?: number;
}

export class GetElacQuotesPayload {
  userTimeZoneId?: string;
  dnqIgnore?: boolean;
  pax: number;
  aircraftCategoryIds: Array<number>;
  legs: Array<models.LegRequest>;
  pricingSupportType: models.PriceTypeEnum;
  aircraftId: number;
}

export class GetElacQuotesAction {
  readonly type = GET_ELAC_QUOTES;

  constructor(public payload: GetElacQuotesPayload) {}
}

export class GetElacQuotesSuccessAction implements Action {
  readonly type = GET_ELAC_QUOTES_SUCCESS;

  constructor(public payload: models.ElacPriceInfo) {}
}

export class GetElacQuotesFailAction implements Action {
  readonly type = GET_ELAC_QUOTES_FAIL;
}

export class GetMarketplaceQuotesPayload {
  aircraftCategories: Array<models.AircraftCategory>;
  legs: Array<models.LegRequest>;
}

export class GetMarketplaceQuotesAction {
  readonly type = GET_MARKETPLACE_QUOTES;

  constructor(public payload: GetMarketplaceQuotesPayload) {}
}

export class GetMarketplaceQuotesSuccessAction implements Action {
  readonly type = GET_MARKETPLACE_QUOTES_SUCCESS;

  constructor(public payload: Array<models.MarketplaceQuote>) {}
}

export class GetMarketplaceQuotesFailAction implements Action {
  readonly type = GET_MARKETPLACE_QUOTES_FAIL;
}
export class GetTaxBreakdownPayload {
  legs: Array<models.LegRequest>;
  seatsCount: number;
  basePrice: number;
  operatorUuid: string;
  priceType: models.PriceTypeEnum;
  aircraftTypeName: string;
}

export class GetTaxBreakdownAction implements Action {
  readonly type = GET_TAX_BREAKDOWN;

  constructor(public payload: GetTaxBreakdownPayload) {}
}

export class GetTaxBreakdownSuccessAction implements Action {
  readonly type = GET_TAX_BREAKDOWN_SUCCESS;

  constructor(public payload: Array<models.TaxItem>) {}
}

export class GetExtPriceBreakdownFailAction implements Action {
  readonly type = GET_TAX_BREAKDOWN_FAIL;
}

export class SetModeratedLegsAction implements Action {
  readonly type = SET_MODERATED_LEGS;

  constructor(public payload: Array<models.LegRequest>) {}
}

export class GetEftsActionPayload {
  aircraftCategoryId: number;
}

export class GetEftsAction implements Action {
  readonly type = GET_EFTS;
  constructor(public payload: GetEftsActionPayload) {}
}

export class GetEftsSuccessPayload {
  legs: Array<models.LegRequest>;
}
export class GetEftsSuccessAction implements Action {
  readonly type = GET_EFTS_SUCCESS;

  constructor(public payload: GetEftsSuccessPayload) {}
}

export class GetEftsFailedAction implements Action {
  readonly type = GET_EFTS_FAILED;
}

export class GetPriceBreakdownAction implements Action {
  readonly type = GET_PRICE_BREAKDOWN;

  constructor(public payload: GetPriceBreakdownPayload) {}
}

export class GetPriceBreakdownSuccessAction implements Action {
  readonly type = GET_PRICE_BREAKDOWN_SUCCESS;

  constructor(public payload: models.FlightPriceBreakdown) {}
}

export class GetPriceBreakdownFailAction implements Action {
  readonly type = GET_PRICE_BREAKDOWN_FAIL;
}

export class CancelSourcingRequestPayload {
  constructor(public sourcingRequestId: string) {}
}

export class CancelSourcingRequest implements Action {
  readonly type = CANCEL_SOURCING_REQUEST;
  constructor(public payload: CancelSourcingRequestPayload) {}
}

export class CancelSourcingRequestSuccess implements Action {
  readonly type = CANCEL_SOURCING_REQUEST_SUCCESS;
}

export class BookProFormaPayload {
    sourcingRequestId: string;
    operatorPurchaseOfferId: string;
    flightPrice: number;
}

export class BookProForma implements Action {
  readonly type = BOOK_PROFORMA;

  constructor(public payload: BookProFormaPayload) {}
}

export class BookProFormaSuccess implements Action {
  readonly type = BOOK_PROFORMA_SUCCESS;
}

export class BookProFormaFail implements Action {
  readonly type = BOOK_PROFORMA_FAIL;
}

export class GetCharterAgreementPayload {
  constructor(public purchaseOfferId: string) {}
}
export class GetCharterAgreement implements Action {
  readonly type = GET_CHARTER_AGREEMENT;
  constructor(public payload: GetCharterAgreementPayload) {}
}

export class GetCharterAgreementSuccessPayload {
  constructor(public blob: Blob) {}
}
export class GetCharterAgreementSuccess implements Action {
  readonly type = GET_CHARTER_AGREEMENT_SUCCESS;
  constructor(public payload: GetCharterAgreementSuccessPayload) {}
}

export class GetCharterAgreementFailed implements Action {
  readonly type = GET_CHARTER_AGREEMENT_FAIL;
}

export class SaveOfferCommentPayload {
  constructor(public purchaseOfferId: string, public comment: string) {
  }
}

export class SaveOfferCommentAction implements Action {
  readonly type = SAVE_OFFER_COMMENT;

  constructor(public payload: SaveOfferCommentPayload) {
  }
}

export class SaveOfferCommentSuccessAction implements Action {
  readonly type = SAVE_OFFER_COMMENT_SUCCESS;

  constructor(public payload: SaveOfferCommentPayload) {
  }
}

export class SaveOfferCommentFailAction implements Action {
  readonly type = SAVE_OFFER_COMMENT_FAIL;
}

export class ExtendOfferPayload {
  constructor(
    public purchaseOfferId: string,
    public newExpirationDate: string
  ) {}
}

export class ExtendOfferAction implements Action {
  readonly type = EXTEND_OFFER;

  constructor(public payload: ExtendOfferPayload) {}
}

export class ExtendOfferSuccessAction implements Action {
  readonly type = EXTEND_OFFER_SUCCESS;
}

export class ExtendOfferFailAction implements Action {
  readonly type = EXTEND_OFFER_FAIL;
}

export type CreatePurchaseOffer = CreateNgaPurchaseOffer | CreateGaPurchaseOffer;
export type ValidateOfferActions = ValidateNgaPurchaseOffer | ValidateGaPurchaseOffer;

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | CreateNgaPurchaseOffer
  | CreateMultiNgaPurchaseOffer
  | CreateNgaOfferAndAccept
  | CreateGaPurchaseOffer
  | CreatePurchaseOfferSuccess
  | CreatePurchaseOfferFail
  | CancelPurchaseOffer
  | CancelPurchaseOfferSuccess
  | UpdatePurchaseOffer
  | UpdatePurchaseOfferSuccess
  | GetPriceBreakdownAction
  | GetPriceBreakdownSuccessAction
  | GetPriceBreakdownFailAction
  | CancelSourcingRequest
  | CancelSourcingRequestSuccess
  | SendPurchaseOffers
  | SendPurchaseOffersSuccess
  | SendPurchaseOffersFail
  | SetModeratedOffers
  | GetTaxBreakdownAction
  | GetTaxBreakdownSuccessAction
  | GetExtPriceBreakdownFailAction
  | BookProForma
  | BookProFormaSuccess
  | BookProFormaFail
  | ValidateNgaPurchaseOffer
  | ValidateGaPurchaseOffer
  | ValidatePurchaseOfferSuccess
  | ValidatePurchaseOfferFail
  | ResetOfferValidationResults
  | GetCharterAgreement
  | GetCharterAgreementSuccess
  | GetCharterAgreementFailed
  | GetElacQuotesAction
  | GetElacQuotesSuccessAction
  | GetElacQuotesFailAction
  | SaveOfferCommentAction
  | SaveOfferCommentSuccessAction
  | SaveOfferCommentFailAction
  | ExtendOfferAction
  | ExtendOfferSuccessAction
  | ExtendOfferFailAction
  | GetMarketplaceQuotesAction
  | GetMarketplaceQuotesSuccessAction
  | GetMarketplaceQuotesFailAction
  | FulfillAndSendOffers
  | FulfillAndSendOffersSuccess
  | FulfillAndSendOffersFail
  | UpdateGaPurchaseOffer
  | UpdateGaPurchaseOfferSuccess
  | UpdateGaPurchaseOfferFail
  | GetEftsAction
  | GetEftsSuccessAction
  | GetEftsFailedAction
  | SetModeratedLegsAction;
