import { MenuItem } from '../../../shared/components';

export const menuItems: Array<MenuItem> = [
  {
    label: 'Clients',
    routerLink: null,
    viewPermission: 'clientsModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-user',
    toggle: 'close',
    submenu: [
      {
        label: 'Client Lookup',
        viewPermission: 'clientsModule.lookup',
        routerLink: 'clients',
        iconType: 'letter',
        iconName: 'cl'
      },
      {
        label: 'Universal Search',
        viewPermission: 'clientsModule.universalSearch',
        routerLink: 'clients/universal-search',
        iconType: 'letter',
        iconName: 'us'
      }
    ]
  },
  {
    label: 'Explorer',
    routerLink: null,
    viewPermission: 'explorerModule.search',
    iconType: 'fal',
    iconName: 'fal fa-search',
    toggle: 'close',
    submenu: [{
      label: 'Charter Search',
      viewPermission: 'explorerModule.search',
      routerLink: 'explorer/charter-search',
      iconType: 'letter',
      iconName: 'cs'
    }]
  },
  {
    label: 'Charters',
    routerLink: null,
    viewPermission: 'charterSalesModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-globe-americas',
    toggle: 'close',
    submenu: [
      {
        label: 'Opportunities',
        viewPermission: 'charterSalesModule.opportunities',
        routerLink: 'charters/opportunities',
        iconType: 'letter',
        iconName: 'co'
      },
      {
        label: 'My Opportunities',
        viewPermission: 'charterSalesModule.myOpportunities',
        routerLink: 'charters/opportunities/my',
        iconType: 'letter',
        iconName: 'my'
      },
      {
        label: 'New Opportunity',
        viewPermission: 'charterSalesModule.newOpportunities',
        routerLink: 'charters/opportunities/new',
        iconType: 'letter',
        iconName: 'o'
      }
    ]
  },
  {
    label: 'Marketplace',
    routerLink: null,
    viewPermission: 'marketplaceModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-shopping-cart',
    toggle: 'close',
    submenu: [
      {
        label: 'Requests',
        viewPermission: 'marketplaceModule.oneWayQuotes',
        routerLink: 'marketplace/requests',
        iconType: 'letter',
        iconName: 'mr'
      },
      {
        label: 'New Request',
        viewPermission: 'marketplaceModule.oneWayQuotes',
        routerLink: 'marketplace/new-request',
        iconType: 'letter',
        iconName: 'nr'
      },
      {
        label: 'One Way Quotes',
        viewPermission: 'marketplaceModule.oneWayQuotes',
        routerLink: 'marketplace/one-way/quotes',
        iconType: 'letter',
        iconName: 'q'
      }
    ]
  },
  {
    label: 'Resources',
    routerLink: null,
    viewPermission: 'resourceModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-cubes',
    toggle: 'close',
    submenu: [
      {
        label: 'Aircraft',
        viewPermission: 'resourceModule.aircraft',
        routerLink: 'resource-mgmt/aircraft',
        iconType: 'letter',
        iconName: 'ac'
      },
      {
        label: 'Airports',
        viewPermission: 'resourceModule.airports',
        routerLink: 'resource-mgmt/airports',
        iconType: 'letter',
        iconName: 'ap'
      },
      {
        label: 'Application Configs',
        viewPermission: 'resourceModule.applicationConfigs',
        routerLink: 'resource-mgmt/configs',
        iconType: 'letter',
        iconName: 'c'
      },
      {
        label: 'Areas',
        viewPermission: 'resourceModule.areas',
        routerLink: 'resource-mgmt/areas',
        iconType: 'letter',
        iconName: 'ar'
      },
      {
        label: 'Markets',
        viewPermission: 'resourceModule.markets',
        routerLink: 'resource-mgmt/markets',
        iconType: 'letter',
        iconName: 'mr'
      },
      {
        label: 'Regions',
        viewPermission: 'resourceModule.regions',
        routerLink: 'resource-mgmt/regions',
        iconType: 'letter',
        iconName: 'rg'
      },
      {
        label: 'Vendors',
        viewPermission: 'resourceModule.vendors',
        routerLink: 'resource-mgmt/vendors',
        iconType: 'letter',
        iconName: 'vn'
      },
      {
        label: 'Users',
        viewPermission: 'resourceModule.users',
        routerLink: 'resource-mgmt/users',
        iconType: 'letter',
        iconName: 'u'
      }
    ]
  },
  {
    label: 'Sales',
    routerLink: null,
    viewPermission: 'salesModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-users',
    toggle: 'close',
    submenu: [
      {
        label: 'New Order',
        viewPermission: 'salesModule.new',
        routerLink: 'sales/orders/new',
        iconType: 'letter',
        iconName: 'n'
      },
      {
        label: 'Orders',
        viewPermission: 'salesModule.orders',
        routerLink: 'sales/orders',
        iconType: 'letter',
        iconName: 'o'
      },
      // {
      //   label: 'Pricing Comparison',
      //   viewPermission: 'salesModule.pricingComparison',
      //   routerLink: 'sales/pricing-comparison',
      //   iconType: 'letter',
      //   iconName: 'pc'
      // },
      {
        label: 'Pricing Calculator',
        viewPermission: 'salesModule.pricingCalculator',
        routerLink: 'sales/pricing-calculator',
        iconType: 'letter',
        iconName: 'cl'
      }
    ]
  },
  {
    label: 'Sourcing',
    routerLink: null,
    viewPermission: 'sourcingModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-money-check-alt',
    toggle: 'close',
    submenu: [
      {
        label: 'Requests',
        viewPermission: 'sourcingModule.requests',
        routerLink: 'sourcing/requests',
        iconType: 'letter',
        iconName: 'sr'
      },
      {
        label: 'My Requests',
        viewPermission: 'sourcingModule.myRequests',
        routerLink: 'sourcing/requests/my',
        iconType: 'letter',
        iconName: 'my'
      },
      {
        label: 'New',
        viewPermission: 'sourcingModule.new',
        routerLink: 'sourcing/requests/new',
        iconType: 'letter',
        iconName: 'ns'
      },
      {
        label: 'Last Look',
        viewPermission: 'sourcingModule.requests',
        routerLink: 'sourcing/last-look',
        iconType: 'letter',
        iconName: 'll'
      }
    ]
  },
  {
    label: 'Task Mgmt',
    routerLink: null,
    viewPermission: 'taskMgmtModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-tasks-alt',
    toggle: 'close',
    submenu: [
      {
        label: 'Task Dashboard',
        viewPermission: 'taskMgmtModule.tasks',
        routerLink: 'task-mgmt/tasks',
        iconType: 'letter',
        iconName: 'td'
      }
    ]
  },
  {
    label: 'Yield Mgmt',
    routerLink: null,
    viewPermission: 'yieldMgmtModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-chart-area',
    toggle: 'close',
    submenu: [
      {
        label: 'Heat Map',
        viewPermission: 'yieldMgmtModule.heatMap',
        routerLink: 'yield-mgmt/heat-map',
        iconType: 'fal',
        iconName: 'fal fa-fire',
        debugOnly: true
      },
      {
        label: 'Seat Charts',
        viewPermission: 'yieldMgmtModule.seatCharts',
        routerLink: 'yield-mgmt/seat-charts',
        iconType: 'letter',
        iconName: 'sc',
        debugOnly: true
      },
      {
        label: 'Seat Pricing',
        viewPermission: 'yieldMgmtModule.seatPricing',
        routerLink: 'yield-mgmt/seat-pricing',
        iconType: 'letter',
        iconName: 'sp'
      },
      {
        label: 'Custom Shuttles',
        routerLink: 'yield-mgmt/custom-shuttles',
        iconType: 'letter',
        iconName: 'ch',
        debugOnly: true
      },
      {
        label: 'CYO Pricing',
        viewPermission: 'yieldMgmtModule.cyoPricing',
        routerLink: 'yield-mgmt/cyo-pricing',
        iconType: 'letter',
        iconName: 'cp'
      },
      {
        label: 'Charter Pricing',
        viewPermission: 'yieldMgmtModule.charterPricing',
        routerLink: 'yield-mgmt/charter-pricing',
        iconType: 'letter',
        iconName: 'cp'
      },
      {
        label: 'Dashboard',
        routerLink: 'yield-mgmt/rm-dashboard',
        iconType: 'letter',
        iconName: 'rm',
        debugOnly: true
      },
      // {
      //   label: 'Charts',
      //   routerLink: 'yield-mgmt/charts',
      //   iconType: 'letter',
      //   iconName: 'ch',
      //   debugOnly: true
      // }
    ]
  },
  {
    label: 'User Mgmt',
    routerLink: null,
    viewPermission: 'userMgmtModule.overall',
    iconType: 'fal',
    iconName: 'fal fa-user-friends',
    toggle: 'close',
    submenu: [
      {
        label: 'Users',
        viewPermission: 'userMgmtModule.users',
        routerLink: 'user-mgmt/users',
        iconType: 'letter',
        iconName: 'ur'
      }
    ]
  },
  {
    label: 'History',
    routerLink: null,
    iconType: 'fal',
    iconName: 'fal fa-history',
    toggle: 'close',
    submenu: [
      {
        label: 'Version History',
        routerLink: 'version-history',
        iconType: 'letter',
        iconName: 'vh'
      }
    ]
  }
];
