/* eslint-disable max-len */
import { VersionHistory } from '../../../shared/components';

export const versions: Array<VersionHistory> = [
  {
    version: '2.3.0',
    date: new Date('2019-5-28'),
    newFeatures: [
      {
        feature: 'Vendor Management',
        description: 'New Vendor Management Module'
      },
      {
        feature: 'Sourcing',
        description: 'New Sourcing Module'
      },
      {
        feature: 'Heatmap',
        description: 'Added Operator Portal shared charters'
      },
      {
        feature: 'Heatmap',
        description: 'Pricing override filter'
      },
      {
        feature: 'Heatmap',
        description: 'Leg ID filter'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '2.2.0',
    date: new Date('2019-3-29'),
    newFeatures: [
      {
        feature: 'Aircraft Management',
        description: 'New Aircraft Management Module'
      },
      {
        feature: 'Resource Management',
        description: 'Resource Management Module consolidates Route Management and Aircraft Management'
      },
      {
        feature: 'Charter Sales Tail Search',
        description: 'Search by Tail in Sales Quote'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '2.1.0',
    date: new Date('2019-3-15'),
    newFeatures: [
      {
        feature: 'Route Management',
        description: 'New Route Management Module'
      }
    ],
    bugFixes: [
      {
        feature: 'Icon',
        description: 'Side Menu missing icon fix'
      }
    ]
  },
  {
    version: '2.0.5',
    date: new Date('2019-2-22'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Airports with no Area should display City for Area'
      },
      {
        feature: 'Heatmap',
        description: 'Release Date Filter should consider both Release Date and Confirmed Date'
      },
      {
        feature: 'Heatmap',
        description: 'Change Flight Credits Name to Bonus Currency'
      },
      {
        feature: 'Heatmap',
        description: 'Add Client Name to Bookings Information'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'Fix Heatmap Memory Leak Issue'
      },
      {
        feature: 'Heatmap',
        description: 'Correct Shared Charter Flight Revenue'
      },
      {
        feature: 'Heatmap',
        description: 'Heatmap UTC time updates'
      },
      {
        feature: 'Heatmap',
        description: 'Additional Validation in case of Divisor of 0'
      }
    ]
  },
  {
    version: '2.0.4',
    date: new Date('2019-1-17'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Rebate Credits rolled into cash'
      },
      {
        feature: 'Heatmap',
        description: 'Sophisticated Credits rolled into Flight Credits'
      },
      {
        feature: 'Heatmap',
        description: 'Market Selection Filter Enhancements'
      },
      {
        feature: 'Heatmap',
        description: 'Log File Enhancements'
      },
      {
        feature: 'Heatmap',
        description: 'Add LegID to Tile'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'When you land on individual leg page, price matrix doesn’t load'
      }
    ]
  },
  {
    version: '2.0.3',
    date: new Date('2019-1-11'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'New Filter for Light Up the Sky Flights'
      },
      {
        feature: 'Heatmap',
        description: 'Utilize new Route Management Service for Heatmap'
      },
      {
        feature: 'Heatmap',
        description: 'Switched to use data from the new Data Warehouse'
      },
      {
        feature: 'Heatmap',
        description: 'New Market Selection Filter'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'Missing Aircraft Categories in Heatmap'
      },
      {
        feature: 'Heatmap',
        description: 'Correct Crowdfunded Marketable Seat Counts'
      },
      {
        feature: 'Heatmap',
        description: 'Correct Private Charter Flight Revenue'
      }
    ]
  },
  {
    version: '2.0.2',
    date: new Date('2018-12-28'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Color P&L values to indicate positive(green) or negative(red)'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'Correct Shared Charters Seat Counts and Load Factor'
      },
      {
        feature: 'Heatmap',
        description: 'Fix Leg Pricing Issues'
      },
      {
        feature: 'Heatmap',
        description: 'Debug mode doesn’t work'
      },
      {
        feature: 'Heatmap',
        description: 'Update of Console link for Request ID in Bookings History'
      },
      {
        feature: 'Heatmap',
        description: 'Corrected timing of Validation messages for Leg Pricing Updates'
      }
    ]
  },
  {
    version: '2.0.1',
    date: new Date('2018-12-21'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Add P&L gross profit metrics to Heatmap information'
      },
      {
        feature: 'Heatmap',
        description: 'When Clicking into Tile, Display Day & Date in Header'
      },
      {
        feature: 'Heatmap',
        description: 'Add Dollar Sign to prices in Pricing Matrix'
      },
      {
        feature: 'Heatmap',
        description: 'Add Additional Information to Booking History'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'Correct the Leg time being passed to the Pricing Matrix'
      },
      {
        feature: 'Heatmap',
        description: 'Bookings totals not always accurate when there are No Reservations'
      },
      {
        feature: 'Heatmap',
        description: 'Clicking Clear button in Filters Menu causes scrolling to stop working'
      }
    ]
  },
  {
    version: '2.0.0',
    date: new Date('2018-12-14'),
    newFeatures: [
      {
        feature: 'Angular 6 migration',
        description: 'Updated core Angular, RxJs library and other packages. Enhanced performance and security'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.3.2',
    date: new Date('2018-11-20'),
    newFeatures: [
      {
        feature: 'Charter Invoices',
        description: 'Implemented different kinds of charter invoice support'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.3.1',
    date: new Date('2018-11-15'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Separate Daily Totals and Overall Totals for confirmed and non-confirmed flights'
      },
      {
        feature: 'Heatmap',
        description: 'Proper Routing when Clicking on Tile'
      },
      {
        feature: 'Heatmap',
        description: 'Tile Redesign to support Null departure and arrival airport groups'
      },
      {
        feature: 'Heatmap',
        description: 'Add Inactive or Active Colored Indication to Leg Pricing'
      },
      {
        feature: 'Heatmap',
        description: 'Float and carry the latest Date Header Bar and Filter Header Bar'
      },
      {
        feature: 'Heatmap',
        description: 'Split Revenue by Flight Credits and Cash in Consolidated Totals'
      },
      {
        feature: 'Heatmap',
        description: 'Mobile support for Consolidated Overall and Daily Totals'
      },
      {
        feature: 'Heatmap',
        description: 'Mobile support for Header within Details page'
      },
      {
        feature: 'Heatmap',
        description: 'Remove decimal places for consolidated totals values'
      },
      {
        feature: 'Heatmap',
        description: 'Add side menu with actions'
      },
      {
        feature: 'Heatmap',
        description: 'Hide Leg Pricing for Charters and Non-Confirmed Crowdfunded Flights'
      }
    ],
    bugFixes: [
      {
        feature: 'Heatmap',
        description: 'Corrected when adding Filters by Departure Date, Departure Times all change to 12:00 AM'
      },
      {
        feature: 'Heatmap',
        description: 'Corrected CrowdFunded Flight Release Date not being updated when status changes from Not Confirmed to Confirmed'
      },
      {
        feature: 'Heatmap',
        description: 'Corrected intermittent issue where you can no longer click into Heatmap tiles'
      },
      {
        feature: 'Heatmap',
        description: 'Corrected in logs page, breadcrumb back link doesn’t work'
      }
    ]
  },
  {
    version: '1.3.0',
    date: new Date('2018-10-05'),
    newFeatures: [
      {
        feature: 'Heatmap',
        description: 'Added General Pricing change Audit page'
      },
      {
        feature: 'Heatmap',
        description: 'Extended Price change audits to last 3 within a Leg'
      },
      {
        feature: 'Heatmap',
        description: 'Override departure time for leg support'
      },
      {
        feature: 'Heatmap',
        description: 'Consolidate Creator and Finder Revenue within a tile'
      },
      {
        feature: 'Heatmap',
        description: 'Tile Redesign to match Material Design Standarts'
      },
      {
        feature: 'Heatmap',
        description: 'Added Aircraft Category and Aircraft Type to flight information'
      },
      {
        feature: 'Heatmap',
        description: 'Clear Filters Functionality'
      },
      {
        feature: 'Heatmap',
        description: 'Added private Charter Legs'
      },
      {
        feature: 'Heatmap',
        description: 'Filter by Tail#'
      },
      {
        feature: 'Heatmap',
        description: 'Filter by Operator'
      },
      {
        feature: 'Heatmap',
        description: 'Within the Heatmap Tiles, show Source of legs'
      },
      {
        feature: 'Heatmap',
        description: 'Show last opened tile'
      },
      {
        feature: 'Heatmap',
        description: 'Add option to display times by Departing Airport'
      },
      {
        feature: 'Heatmap',
        description: 'Filter Flight Age in Days'
      },
      {
        feature: 'Heatmap',
        description: 'Add indication for Flights recently added to Heatmap'
      },
      {
        feature: 'Heatmap',
        description: 'Show Impression Data for a Flight'
      },
      {
        feature: 'Heatmap',
        description: 'Add Date/Time to Last updated Leg Pricing Information'
      },
      {
        feature: 'Heatmap',
        description: 'In the Daily Header section, show consolidated information for Unsold Seats & Impressions'
      },
      {
        feature: 'Heatmap',
        description: 'Distinguish Quoted Operator Cost Between 0 and Null'
      },
      {
        feature: 'Heatmap',
        description: 'Change display for tiles where Flight has departed and disable leg pricing update for those flights'
      },
      {
        feature: 'Heatmap',
        description: 'Sorting Tiles within each Day'
      },
      {
        feature: 'Heatmap',
        description: 'Modify Heatmap to include uncovered Cost'
      },
      {
        feature: 'CYO Pricing',
        description: 'Redesign CYO edit View for enhanced Usability'
      },
      {
        feature: 'Seat Pricing',
        description: 'Redesign Seat Pricing Rule edit View for enhanced Usability'
      }
    ],
    bugFixes: [
      {
        feature: 'scrolling',
        description: 'corrected scrolling functionality on smaller screens'
      }
    ]
  },
  {
    version: '1.2.13',
    date: new Date('2018-09-26'),
    newFeatures: [
      {
        feature: 'Charter Invoice Orders',
        description: 'Ability to split payments on Charter invoices'
      },
      {
        feature: 'Concierge Orders',
        description: 'Ability to place Concierge Orders'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.12',
    date: new Date('2018-07-23'),
    newFeatures: [
      {
        feature: 'Membership Order Consolidation',
        description: 'Consolidated Memberhsip order view with Group Membership with an enhanced UX'
      },
      {
        feature: 'Report Download',
        description: 'On Demand Report Download Feature'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.11',
    date: new Date('2018-07-23'),
    newFeatures: [
      {
        feature: 'Concierge Order Item',
        description: 'Implemented the ability to sell Concierge Services'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.10',
    date: new Date('2018-08-15'),
    newFeatures: [
      {
        feature: 'CYO Pricing',
        description: 'Implemented the Create Your Own Pricing Module which allows the modification of Custom Shuttle Price Matrix'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.9',
    date: new Date('2018-07-23'),
    newFeatures: [
      {
        feature: 'Seat Pricing',
        description: 'Implemented the Seat Pricing Module which allows the modification of Shuttle Seat Price Matrix'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.8',
    date: new Date('2018-06-26'),
    newFeatures: [
      {
        feature: 'Group Memberships',
        description: 'Prorate the term of membership by extending the membership'
      },
      {
        feature: 'Group Memberships',
        description: 'Converting members to Group Membership with Flex Term Length for $0.00'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.7',
    date: new Date('2018-06-01'),
    newFeatures: [
      {
        feature: 'Group Memberships',
        description: 'Purchase extra seats on existing membership on a separate order. Prorate extra slot price accordingly to group expiration date.'
      },
      {
        feature: 'Group Memberships',
        description: 'Purchase replacement seats on existing membership on a separate order.'
      },
      {
        feature: 'Orders Screen',
        description: 'Search by order id, invoice id, user id. Show all invoices. Show all beneficiaries, align them with invoices.'
      },
      {
        feature: 'GDPR',
        description: 'Made JetStudio GDPR-compliant.'
      }
    ],
    bugFixes: [
    ]
  },
  {
    version: '1.2.6',
    date: new Date('2018-05-24'),
    newFeatures: [
      {
        feature: 'Group Memberships',
        description: 'Implemented ability to dynamically validate extra seats count based on business requirements'
      }
    ],
    bugFixes: [
      {
        feature: 'Group Membership',
        description: 'brought back the functionality to add promotions and prorate in new Group Membership Order'
      },
      {
        feature: 'Group Membership',
        description: 'fixed the functionality to add multipple Group Membership Products into one order'
      }
    ]
  },
  {
    version: '1.2.5',
    date: new Date('2018-05-16'),
    newFeatures: [
      {
        feature: 'Group Memberships',
        description: 'Implemented Group membership with unlimited number of Slots added at the time of purchase and discounts applied based on their quanity'
      }
    ],
    bugFixes: []
  },
  {
    version: '1.2.4',
    date: new Date('2018-04-25'),
    newFeatures: [
      {
        feature: 'Security',
        description: 'User-based security access to products, price components, promotions, transitions and functionality'
      }, {
        feature: 'Invoices',
        description: 'Invoices for Sophisticated membership do not contain credit card payment or hold options'
      }, {
        feature: 'Email',
        description: 'Emails are sent from the person created the order'
      }, {
        feature: 'Id verification',
        description: 'Console\'s ID Verification flag is now respected'
      }, {
        feature: 'Group Memberships',
        description: 'Implemented Family and Enterprise Group memberships'
      }, {
        feature: 'Group Memberships',
        description: 'Implemented Group membership management page'
      }, {
        feature: 'Order Expiration',
        description: 'Orders of New Membership and Group Membership expire in 10 business days vs. 72 hours'
      }, {
        feature: 'FREE Seats',
        description: 'Each beneficiary on Family plan gets a FREE seat up to $1000 until 4/30/18'
      }, {
        feature: 'Login',
        description: 'Fix log-out issue: JetStudio logs people out every 5 minutes. Should be increased to 16 hours.'
      }
    ],
    bugFixes: [
      {
        feature: 'payment',
        description: 'cc_last_4 column - changed type from numeric to character varying, so the leading zero is not lost'
      }
    ]
  },
  {
    version: '1.2.3',
    date: new Date('2018-03-22'),
    newFeatures: [
      {
        feature: 'multipayments',
        description: 'ability to make multiple payments on an invoice'
      }, {
        feature: 'renewals',
        description: 'suppress sending client email and client push on order creation, send those email and push only on order activation'
      }, {
        feature: 'order page',
        description: 'Added menu items “ACH Received” and “Renewal Charged”. Dialogs “Wire Received” and “ACH Received” capture “Payment Date” and “Bank Reference Number”. Dialog “Renewal Charged” captures “Payment Date” and “CC Last 4 Digits”'
      }, {
        feature: 'new order page',
        description: 'added client Business name, removed Free Text'
      }, {
        feature: 'email',
        description: 'email notification is sent to Sales when payment is received from client'
      }, {
        feature: 'invoices',
        description: 'changed all invoices to include corrected legal wording, reduced distance between Cluck to Accept control and Refund Policy to be less than ¼ inch'
      }, {
        feature: 'invoices',
        description: 'added JetStudio “/membershipjs/” and “/membershipjs_amex/” invoices to replace Console’s “/membership/” and “/membership_amex/” invoices, so that we don’t share any invoices with Console anymore and have more control over look and feel of them: (remove “Gov Tax”, “Additional Tax”, put corrected legal wording etc.). Existing invoices that are already sent to clients are not changed and are still supported.'
      }, {
        feature: 'invoices',
        description: 'put Invoice Number on the Wire Transfer instructions'
      }, {
        feature: 'reports',
        description: 'for Karim’s temporary reporting for Accounting, the code now adds extra fields to data_in field on a “forms” record'
      }, {
        feature: 'invoices',
        description: 'separate “Wire only / ACH only” invoice with / without Terms'
      }
    ],
    bugFixes: [
      {
        feature: 'renewals',
        description: 'fix for: renewed membership automatically activates when client accepts Terms and Conditions regardless of if payment was processed by the Renewals team'
      }, {
        feature: 'jetpass',
        description: 'fix for: when jetpass is aligned with new membership created from Console Sophisticated membership, the price manually entered for Console membership is not propagated to the new membership'
      }
    ]
  },
  {
    version: '1.2.2',
    date: new Date('2018-03-07'),
    newFeatures: [
      {
        feature: 'membership sales',
        description: 'added new sales action: Adjustment'
      }, {
        feature: 'renewals',
        description: 'for renewal orders, send emails from Renewals team instead of client\'s personal manager'
      }, {
        feature: 'flight credits',
        description: 'add flight credits to Console when they are sold via JetStudio'
      }, {
        feature: 'push',
        description: 'send Welcome push to clients on their new membership activation'
      }
    ],
    bugFixes: [
      {
        feature: 'membership sales',
        description: 'fix for: if payer is not a beneficiary, then he gets an invoice with no Terms and, acorrdingly, no checkbox, so it\'s impossible to tell from the invoice if the order was paid'
      }
    ]
  }, {
    version: '1.2.1',
    date: new Date('2018-03-01'),
    newFeatures: [
      {
        feature: 'membership sales',
        description: 'memberships sales are available for the following sales actions: New, Upgrade, Extent, Renewal'
      }, {
        feature: 'membership upgrade',
        description: 'the upgrading membership price is prorated based on the unused portion of the upgraded membership'
      }, {
        feature: 'membership activation',
        description: 'added membership Suspend and Resume functions'
      }, {
        feature: 'membership activation',
        description: 'added client ID verification check and Terms and Conditions acceptance check'
      }, {
        feature: 'jetpass',
        description: 'jetpass is sold either as a separate product, added to an existing Sophisticated membership, or as a child product on new or Upgrading Sophisticated membership'
      }, {
        feature: 'initiation fee',
        description: 'initiation fee is a separate product, sold as a child of the main membership product, along with a possible discount'
      }, {
        feature: 'flight credits',
        description: 'flight credits is a separate product, sold as a child of the main membership product'
      }, {
        feature: 'orders',
        description: 'added "Order Billing" that allows saving either client with client ID (existing client) or client with name in a form of a Free Text'
      }, {
        feature: 'orders',
        description: 'added "Order Cancel" functionality for both activated and non-activated orders'
      }, {
        feature: 'cron',
        description: 'payment_recognizer: automatically recognize payments made by clients and activate paid orders'
      }, {
        feature: 'cron',
        description: 'orders_manage_statuses: automatically cancel orders if at least one membership request expired and automatically complete orders if all memberships completed'
      }, {
        feature: 'cron',
        description: 'inapp_purchase_recognizer: automatically recognize purchases made via Jetsmarter App and create corresponding JetStudio orders'
      }, {
        feature: 'cron',
        description: 'orders_manage_statuses: automatically cancel orders if at least one membership request expired and automatically complete orders if all memberships completed'
      }, {
        feature: 'cron',
        description: 'memberships_manage_statuses: automatically complete memberships if their terms have expired'
      }, {
        feature: 'invoices',
        description: 'added $1 invoice for beneficiary'
      }, {
        feature: 'invoices',
        description: 'added invoice with no Terms for payer that is not a beneficiary'
      }, {
        feature: 'invoices',
        description: 'added receipt for payer that is not a beneficiary on $0 orders'
      }, {
        feature: 'invoices',
        description: 'added Terms receipt that\'s sent to payer-beneficiary on Renewals'
      }, {
        feature: 'invoices',
        description: 'added Terms-only that\'s sent to beneficiary on Renewals if payer is not a beneficiary'
      }, {
        feature: 'email and push',
        description: 'automatically generated invoice is sent to the client via email and push'
      }, {
        feature: 'email',
        description: 'added email templates for different situations. New member: Email Invoice with Terms to payer-beneficiary. New member: Email $1 Invoice with Terms to beneficiary. Etc.'
      }, {
        feature: 'push',
        description: 'added push templates for different situations. New member: Push Invoice with Terms to payer-beneficiary. New member: Push $1 Invoice with Terms to beneficiary. Etc.'
      }
    ],
    bugFixes: [
      {
        feature: 'invoices',
        description: 'fix for: if beneficiary is not the payer, then he is displayed with payer\'s name'
      }
    ]
  }, {
    version: '1.1.4',
    date: new Date('2017-11-07'),
    newFeatures: [
      {
        feature: 'membership',
        description: 'add order billing'
      }
    ],
    bugFixes: [
    ]
  }, {
    version: '1.1.3',
    date: new Date('2017-11-07'),
    newFeatures: [
      {
        feature: 'membership',
        description: 'add filter for order list'
      },
      {
        feature: 'membership',
        description: 'add order cancellation'
      },
      {
        feature: 'membership',
        description: 'send email on membership activation'
      }
    ],
    bugFixes: [
    ]
  }, {
    version: '1.1.2',
    date: new Date('2017-10-26'),
    newFeatures: [
      {
        feature: 'membership',
        description: 'membership renewals'
      }
    ],
    bugFixes: [
    ]
  }
];
