import { AbstractControl } from '@angular/forms';

export function jsonValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const jsonPattern = /^\{.*\}$/gs;
  try {
    if (jsonPattern.test(control.value)) {
      JSON.parse(control.value);
    }
  } catch (e) {
    return { jsonValid: false };
  }
  return null;
}
