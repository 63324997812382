import { CyoPricingLifetime } from './cyo-pricing-lifetime';
import { CyoPricingOfferRule } from './cyo-pricing-offer-rules';

export class CyoPricingTerm {
  id: number;
  startDay: number;
  endDay: number;
  crowdsourceEnabled: boolean;
  maxCrowdsourceLifetime: CyoPricingLifetime;
  minCrowdsourceLifetime: CyoPricingLifetime;
  pricingOfferRules: Array<CyoPricingOfferRule>;
}
