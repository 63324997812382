import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';
import * as contracts from '../../../domain/service-contracts/aircraft';
import * as coreHelpers from '../../../../../core/helpers';
import * as coreTypes from '../../../../../core/types';
import * as models from '../../../domain/models/aircraft-category';

@Injectable()
export class AircraftService implements contracts.IAircraftService {
  private get API_PATH(): string {
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      return this.configService.get('CharterPricingServiceEndpoint');
    } else {
      return this.configService.get('CharterPricingServiceEndpointV1');
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getAircraftCategoriesForCalculation(
    request: contracts.GetAircraftCategoryListRequest
  ): Observable<contracts.GetAircraftCategoryForCalculationResponse> {
    const requestUrl = `${this.API_PATH}/pricing-calculator/categories`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetAircraftCategoryForCalculationResponse();

        if (!svcJsonResp) {
          return response;
        }

        response.entities = svcJsonResp.map((i) => {
          const a = new models.AircraftCategory();

          a.setId(_.get(i, 'id', null))
            .setName(_.get(i, 'name', null))
            .setACType(_.get(i, 'actype', null));

          return a;
        });

        return response;
      })
    );
  }

  getAircraftCategories(
    request: contracts.GetAircraftCategoryListRequest
  ): Observable<contracts.GetAircraftCategoryListResponse> {
    const requestUrl = `${this.API_PATH}/membership-comparison/categories/xojet`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetAircraftCategoryListResponse();

        if (!svcJsonResp) {
          return response;
        }

        response.entities = svcJsonResp.map((i) => {
          const a = new models.AircraftCategory();

          a.setId(_.get(i, 'id', null)).setName(_.get(i, 'name', null));

          return a;
        });

        return response;
      })
    );
  }
}
