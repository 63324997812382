import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/price-calculator.actions';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export const pricingCalculatorItemFeatureKey = 'pricingCalculatorItem';

export interface State {
  loaded: boolean;
  loading: boolean;
  log: models.CalculationLog;
  items: Array<models.PriceCalculator>;
  inputParams: models.PricingCalculatorOffer;
}

const initialState: State = {
  loaded: false,
  loading: false,
  log: null,
  items: [],
  inputParams: null,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.save,
    (state): State => ({
      ...state, loading: true, items: []
    })
  ),
  on(
    actions.saveSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      log: payload.log,
      items: payload.items,
    })
  ),
  on(
    actions.saveFail,
    (state): State => ({
      ...state, loading: false
    })
  ),

  on(
    actions.update,
    (state): State => ({
      ...state, loading: true
    })
  ),
  on(
    actions.updateSuccess,
    (state, {payload: {log, items}}): State => {
      const copyItems: Array<models.PriceCalculator> = _.cloneDeep(state.items);
      const newItems = copyItems.map((e) => {
        if (e.actype === items[0].actype) {
          e = { ...items[0], selected: e.selected };
        }
        return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        log,
        items: newItems,
      };
    }
  ),
  on(
    actions.updateFail,
    (state): State => ({
      ...state, loading: false
    })
  ),

  on(
    actions.calculateSuccess,
    (state, {payload: {log,items}}): State => ({
      ...state,
      loaded: true,
      loading: false,
      log,
      items,
    })
  ),
  on(
    actions.calculateFail,
    (state): State => ({
      ...state, loading: false
    })
  ),

  on(
    actions.setInputParams,
    (state, {offer}): State => ({
      ...state, inputParams: offer
    })
  ),

  on(
    actions.selectItem,
    (state, {actype}): State => {
      const items: Array<models.PriceCalculator> = _.cloneDeep(state.items);
      items.forEach((e) => {
        e.selected = e.actype === actype;
      });
      return { ...state, items };
    }
  ),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState
    })
  )
)
