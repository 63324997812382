import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FinalizedLeg } from '../models/finalized-leg';

export interface IFinanceService {
  getFinalizedLegsByFlightRequest(flightRequestId: number): Observable<FinalizedLegsResponse | void>;
}

export const FINANCE_SERVICE_TOKEN = new InjectionToken('Finance.IFinanceService');

export class FinalizedLegsResponse {
  hasFinalizedLegs: boolean;
  finalizedLegs: FinalizedLeg[];
}
