import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as coreTypes from '../../types';
import * as contracts from '../../service-contracts/auth0-error-notification';

@Injectable()
export class Auth0ErrorNotificationService implements contracts.IAuth0ErrorNotificationService {
  private get API_PATH(): string {
    return this.configService.get('ErpServiceEndpoint');
  }
  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  sendErrorNotification(
    request: contracts.SendErrorNotificationRequest
  ): Observable<contracts.SendErrorNotificationResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/api/auth0-error-notification`.replace(
      '/jet-sales/v1.0',
      '/jet-auth'
    );
    return this.http.post(requestUrl, request).pipe(
      map(() => {
        return new contracts.SendErrorNotificationResponse();
      })
    );
  }
}
