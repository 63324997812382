import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import * as models from '../models';

export const SALES_DOCUMENT_SERVICE_TOKEN = new InjectionToken('Sales.ISalesDocumentService');

export interface ISalesDocumentService {
  uploadDocument(
    request: SalesDocumentUploadRequestPayload
  ): Observable<SalesDocumentUploadResponse>;

  uploadAndBook(
    request: SalesDocumentUploadAndBookRequestPayload
  ): Observable<SalesDocumentUploadAndBookResponse>;
}

export class SalesDocumentUploadRequestPayload {
  invoiceId: number;
  documentType: models.CharterInvoiceAuthorizationStateEnum;
  documentName: string;
  documentData: File;
}

export interface SalesDocumentData {
  name: number;
  documentTypeName: models.CharterInvoiceAuthorizationStateEnum;
  url: string;
  uploadTime: Date;
  generateTime: Date;
  grayedOut?: boolean;
  regeneratable: boolean;
  userId: number;
  userName: string;
}

export class SalesDocumentUploadResponse {
  documentId: number;
  documentTypeId: models.CharterInvoiceAuthorizationStateEnum;
  documentTypeName: string;
  name: string;
  invoiceId: number;
  url: string;
  uploadTime: Date;
  generateTime: Date;
  updateTime: Date;
  createTime: Date;
  grayedOut: boolean;
  active: boolean;
  regeneratable: boolean;
  userId: number;
  userName: string;
}

export class SalesDocumentUploadAndBookRequestPayload {
  orderId: number;
  documentType: models.CharterInvoiceAuthorizationStateEnum;
  documentName: string;
  documentData: File;
}

export class SalesDocumentUploadAndBookResponse {
  documents: Array<models.SalesUploadedDocument>;
  orderId: number;
  orderStatusId: models.OrderStatusEnum;
  orderStatusName: string;
}
