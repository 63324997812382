import { createAction, props } from '@ngrx/store';

export const load = createAction('[TaskMgmt.PodsCollection] Load');
export const loadSuccess = createAction(
  '[TaskMgmt.PodsCollection] Load Success',
  props<{ payload: Array<string> }>()
);
export const loadFail = createAction('[TaskMgmt.PodsCollection] Load Fail');

export const setInitial = createAction('[TaskMgmt.PodsCollection] Set Initial');
