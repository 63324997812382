import { CurrencyEnum } from '../../../../shared/models';

export class AircraftInsurance {
  ammount: number = null;
  currency: string = CurrencyEnum.USD;
  expirationDate: Date = null;
  approved = false;

  getAmount(): number {
    return this.ammount;
  }

  getApproved(): boolean {
    return this.approved;
  }

  getExpiration(): Date {
    return this.expirationDate;
  }

  getCurrency(): string {
    return this.currency;
  }
}
