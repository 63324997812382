export class EfficiencyCost {
    hoursUnoccupiedInbound: number;
    hoursOccupied: number;
    hoursUnoccupiedOutbound: number;
    perOccupiedHourCost: number;

    getPerOccupiedHourCost(): number {
        return this.perOccupiedHourCost;
    }

    setPerOccupiedHourCost(perOccupiedHourCost: number): EfficiencyCost {
        this.perOccupiedHourCost = perOccupiedHourCost;

        return this;
    }

    getHoursUnoccupiedInbound(): number {
        return this.hoursUnoccupiedInbound;
    }

    setHoursUnoccupiedInbound(hoursUnoccupiedInbound: number): EfficiencyCost {
        this.hoursUnoccupiedInbound = hoursUnoccupiedInbound;

        return this;
    }

    getHoursOccupied(): number {
        return this.hoursOccupied;
    }

    setHoursOccupied(hoursOccupied: number): EfficiencyCost {
        this.hoursOccupied = hoursOccupied;

        return this;
    }

    getHoursUnoccupiedOutbound(): number {
        return this.hoursUnoccupiedOutbound;
    }

    setHoursUnoccupiedOutbound(hoursUnoccupiedOutbound: number): EfficiencyCost {
        this.hoursUnoccupiedOutbound = hoursUnoccupiedOutbound;

        return this;
    }
}