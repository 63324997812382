export const CUSTOM_SHUTTLES_AVAILABLE_YEARS = [2022, 2023, 2024, 2025, 2026, 2027];
export const SHUTTLE_TYPES = [
  { id: 3, name: 'VIP Custom Shuttle' },
  { id: 2, name: 'Standard Custom Shuttle' },
  { name: 'Scheduled Shuttle', id: 1 },
];
export const MARKUP_PER_TIER: Object = {
  JETSMARTER: 0,
  SMART: -0.05,
  SOPHISTICATED: -0.15,
  'PAY-AS-YOU-GO': 0.15,
  ACCESS: 0.35,
};
