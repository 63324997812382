import * as moment from 'moment-timezone';

export enum DepTimeChangeEnum {
  FIRM,
  FLEX,
}

export enum MemberStatusEnum {
  NON_MEMBER,
  MEMBER
}

export class PricingCalculatorOffer {
  legs: Array<PricingCalculatorOfferLeg>;
  actypes: Array<string>;
  userTimeZoneId: string = moment.tz.guess();
  depTimeChange: DepTimeChangeEnum = null;
  memberState: MemberStatusEnum;
}

export class PricingCalculatorOfferLeg {
  origin: string = null;
  destination: string = null;
  aircraftCategory: number = null;
  depDateTime: string = null;
  sortOrder: number = null;
}
