import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromAircraftInspectionCollection from './aircraft-inspection-collection.reducer';
import * as fromPodsCollection from './pods-collection.reducer';
import * as fromSourceCollection from './source-collection.reducer';
import * as fromDelayCodesCollection from './delay-codes-collection.reducer';
import * as fromTaskCollection from './task-collection.reducer';
import * as fromLeg from './leg.reducer';
import * as fromLegCollection from './leg-collection.reducer';
import * as fromLegSourceCollection from './leg-source-collection.reducer';
import * as fromLegSourceTypeCollection from './leg-source-type-collection.reducer';
import * as fromDenyReasons from './deny-reason-collection.reducer';
import * as fromFlightRequestLegsCollection from './flight-request-legs-collection.reducer';
import * as fromError from './error.reducer';
import * as fromLegView from './leg-view.reducer';
import * as fromTaskAdditionalInfoCollection from './task-additional-info-collection.reducer';

export interface State {
  [fromAircraftInspectionCollection.aircraftInspectionCollectionFeatureKey]: fromAircraftInspectionCollection.State;
  [fromPodsCollection.podsCollectionFeatureKey]: fromPodsCollection.State;
  [fromSourceCollection.sourceCollectionFeatureKey]: fromSourceCollection.State;
  [fromDelayCodesCollection.delayCodesCollectionFeatureKey]: fromDelayCodesCollection.State;
  [fromTaskCollection.taskCollectionFeatureKey]: fromTaskCollection.State;
  [fromLeg.legFeatureKey]: fromLeg.State;
  [fromLegCollection.legCollectionFeatureKey]: fromLegCollection.State;
  [fromDenyReasons.denyReasonsCollectionFeatureKey]: fromDenyReasons.State;
  [fromLegSourceCollection.legSourceCollectionFeatureKey]: fromLegSourceCollection.State;
  [fromLegSourceTypeCollection.legSourceTypeCollectionFeatureKey]: fromLegSourceTypeCollection.State;
  [fromFlightRequestLegsCollection.flightRequestLegsCollectionFeatureKey]: fromFlightRequestLegsCollection.State;
  [fromError.errorFeatureKey]: fromError.State;
  [fromLegView.legViewFeatureKey]: fromLegView.State;
  [fromTaskAdditionalInfoCollection.taskAdditionalInfoCollectionFeatureKey]: fromTaskAdditionalInfoCollection.State;
}

export const featureName = 'taskMgmt';

export const reducers = combineReducers<State, Action>({
  [fromAircraftInspectionCollection.aircraftInspectionCollectionFeatureKey]:
    fromAircraftInspectionCollection.reducer,
  [fromPodsCollection.podsCollectionFeatureKey]: fromPodsCollection.reducer,
  [fromSourceCollection.sourceCollectionFeatureKey]: fromSourceCollection.reducer,
  [fromDelayCodesCollection.delayCodesCollectionFeatureKey]: fromDelayCodesCollection.reducer,
  [fromTaskCollection.taskCollectionFeatureKey]: fromTaskCollection.reducer,
  [fromLeg.legFeatureKey]: fromLeg.reducer,
  [fromLegCollection.legCollectionFeatureKey]: fromLegCollection.reducer,
  [fromLegSourceCollection.legSourceCollectionFeatureKey]: fromLegSourceCollection.reducer,
  [fromLegSourceTypeCollection.legSourceTypeCollectionFeatureKey]:
    fromLegSourceTypeCollection.reducer,
  [fromDenyReasons.denyReasonsCollectionFeatureKey]: fromDenyReasons.reducer,
  [fromFlightRequestLegsCollection.flightRequestLegsCollectionFeatureKey]:
    fromFlightRequestLegsCollection.reducer,
  [fromError.errorFeatureKey]: fromError.reducer,
  [fromLegView.legViewFeatureKey]: fromLegView.reducer,
  [fromTaskAdditionalInfoCollection.taskAdditionalInfoCollectionFeatureKey]:
    fromTaskAdditionalInfoCollection.reducer,
});

export const getTaskMgmtState = createFeatureSelector<State>(featureName);
