export class WyvernCertificate {
  id: number;
  code: string;
  holderName: string;
  issuingCountryCode: string;
  expiration: Date;

  getId(): number {
    return this.id;
  }

  setId(id: number): WyvernCertificate {
    this.id = id;

    return this;
  }

  getCode(): string {
    return this.code;
  }

  setCode(code: string): WyvernCertificate {
    this.code = code;

    return this;
  }

  getHolderName(): string {
    return this.holderName;
  }

  setHolderName(holderName: string): WyvernCertificate {
    this.holderName = holderName;

    return this;
  }

  getIssuingCountryCode(): string {
    return this.issuingCountryCode;
  }

  setIssuingCountryCode(issuingCountryCode: string): WyvernCertificate {
    this.issuingCountryCode = issuingCountryCode;

    return this;
  }

  getExpiration(): Date {
    return this.expiration;
  }

  setExpiration(expiration: Date): WyvernCertificate {
    this.expiration = expiration;

    return this;
  }
}
