import { Injectable } from '@angular/core';
import * as contracts from '../../../domain/service-contracts/invoice';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class InvoiceMockService implements contracts.IInvoiceService {
  updateInvoice(
    request: contracts.UpdateInvoiceRequest
  ): Observable<contracts.UpdateInvoiceResponse> {
    return of(new contracts.UpdateInvoiceResponse());
  }

  getContactMethods(
    request: contracts.GetContactMethodsRequest
  ): Observable<contracts.GetContactMethodsResponse> {
    throw new Error('Not Implemented');
  }

  replaceInvoice(
    request: contracts.ReplaceInvoiceRequest
  ): Observable<contracts.ReplaceInvoiceResponse> {
    return of(new contracts.ReplaceInvoiceResponse()).pipe(delay(400));
  }

  consoleLogin(request: contracts.ConsoleLoginRequest): Observable<contracts.ConsoleLoginResponse> {
    // throw new Error('Not Implemented');
    return of(new contracts.ConsoleLoginResponse());
  }
}
