import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as contracts from '../../../domain/service-contracts/pricing-comparison';
import * as coreHelpers from '../../../../../core/helpers';
import * as coreTypes from '../../../../../core/types';
import * as mappers from '../mapper';
import * as models from '../../../domain/models';

@Injectable()
export class PricingComparisonRestService implements contracts.IPricingComparison {
  private get API_PATH(): string {
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      return this.configService.get('CharterPricingServiceEndpoint');
    } else {
      return this.configService.get('CharterPricingServiceEndpointV1');
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getPrice(request: contracts.GetPriceRequest): Observable<contracts.GetPriceResponse> {
    const requestUrl = `${this.API_PATH}/membership-comparison/offer`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(requestUrl, JSON.stringify(request.offer), { headers }).pipe(
      map((svcJsonResp: any) => {
        const r = new contracts.GetPriceResponse();
        r.entities = svcJsonResp
          .map((item) => mappers.PricingComparisonMapper.parsePrice(item))
          .sort(
            (
              a: models.MembershipPricingComparisonPrice,
              b: models.MembershipPricingComparisonPrice
            ) =>
              +models.MembershipPlanEnum[a.membershipDetails.membershipPlan] -
              +models.MembershipPlanEnum[b.membershipDetails.membershipPlan]
          );

        return r;
      })
    );
  }
}
