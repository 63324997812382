import * as actions from '../actions/change-log-collection';

import * as _ from 'lodash';
import { SortDescriptor } from '@progress/kendo-data-query';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<any>;
  type: string;
  take: number;
  skip: number;
  totalItems: number;
  sort: Array<SortDescriptor>;
  filter: any;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  type: null,
  take: 15,
  skip: 0,
  totalItems: null,
  sort: [{field: 'time', dir: 'desc'}],
  filter: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        skip: action.payload.skip,
        take: action.payload.take,
        type: action.payload.type,
        sort: action.payload.sort,
        filter: action.payload.filter,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        totalItems: _.cloneDeep(action.payload.total),
        items: _.cloneDeep(action.payload.items),
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
        isLoaded: true,
      };
    }

    case actions.RESET_STATE: {
      return {
        ...initialState
      }
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getTotalItems = (state: State) => state.totalItems;
export const getGridState = (state: State) => {
  return {
    skip: state.skip,
    take: state.take,
    sort: state.sort,
    filter: state.filter
  }
};
export const getType = (state: State) => state.type;
