import { ChargeComponent } from './charge-component';

export class Quote {
  amount = 0;
  amountConverted = 0;
  vendorPrice;
  private currency = 'USD';
  private chargeComponents: Array<ChargeComponent> = [];

  getCurrency(): string {
    return this.currency;
  }

  setCurrency(currency: string): Quote {
    this.currency = currency;

    return this;
  }

  getAmountConverted(): number {
    return this.amountConverted;
  }

  setAmountConverted(amountConverted: number): Quote {
    this.amountConverted = amountConverted;

    return this;
  }

  getAmount(): number {
    return this.amount;
  }

  setAmount(amount: number): Quote {
    this.amount = amount;

    return this;
  }

  getVendorPrice(): number {
    return this.vendorPrice;
  }

  setVendorPrice(vendorPrice: number): Quote {
    this.vendorPrice = vendorPrice;

    return this;
  }

  addChargeComponent(chargeComponent: ChargeComponent): Quote {
    this.chargeComponents.push(chargeComponent);

    this.amount += chargeComponent.getAmount();

    return this;
  }

  getChargeComponents(): Array<ChargeComponent> {
    return this.chargeComponents;
  }

  setChargeComponents(chargeComponents: Array<ChargeComponent>): Quote {
    this.chargeComponents = chargeComponents;

    return this;
  }
}
