import { Operator } from './operator';

export class AircraftModel {
  aircraftModelId: number;
  name: string;

  equals(o1: AircraftModel): boolean {
    if (o1 === null || this.aircraftModelId === null || this.aircraftModelId === undefined) {
      return false;
    }

    return this.aircraftModelId === o1.aircraftModelId;
  }
}
