import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface IMembershipService {
  activateMembership(request: ActivateMembershipRequest): Observable<ActivateMembershipResponse>;

  // getSalesActions(request: GetSalesActionsRequest): Observable<GetSalesActionsResponse>;

  getPriceComponentToSalesActions(
    request: GetPriceComponentToSalesActionsRequest
  ): Observable<GetPriceComponentToSalesActionsResponse>;

  getTransitions(request: GetTransitionsRequest): Observable<GetTransitionsResponse>;
}

export const MEMBERSHIP_SERVICE_TOKEN = new InjectionToken('Client.IMembershipService');

export class ActivateMembershipRequest {
  orderId: number;
  orderItemId: number;
  paymentReceivedDate: Date;
}

export class ActivateMembershipResponse {
  // TODO: define ActivateMembershipResponse
  // membership: models.Membership;
  orderStatusId: number;
  orderStatusName: string;
}
//
// export class GetSalesActionsRequest {
// }
//
// export class GetSalesActionsResponse {
//   entities: Array<models.SalesAction>;
// }

export class GetTransitionsRequest {}

export class GetTransitionsResponse {
  entities: Array<models.Transition>;
}

export class GetPriceComponentToSalesActionsRequest {
  active: boolean;
}

export class GetPriceComponentToSalesActionsResponse {
  entities: Array<models.PriceComponentToSalesAction>;
}
