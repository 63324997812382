import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Area Detail] Load';
export const LOAD_SUCCESS = '[RM Area Detail] Load Success';
export const LOAD_FAIL = '[RM Area Detail] Load Fail';
export const ADD_AIRPORT = '[RM Area Detail] Add Airport';
export const REMOVE_AIRPORT = '[RM Area Detail] Remove Airport';
export const UPDATE = '[RM Area Detail] Update';
export const SAVE = '[RM Area Detail] Save';
export const SAVE_SUCCESS = '[RM Area Detail] Save Success';
export const SAVE_FAIL = '[RM Area Detail] Save Fail';

export class LoadPayload {
  constructor(public id: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Area) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddAirportPayload {
  constructor(public id: string, public name: string) {}
}

export class AddAirportAction implements Action {
  readonly type = ADD_AIRPORT;

  constructor(public payload: AddAirportPayload) {}
}

export class RemoveAirportPayload {
  constructor(public id: string) {}
}

export class RemoveAirportAction implements Action {
  readonly type = REMOVE_AIRPORT;

  constructor(public payload: RemoveAirportPayload) {}
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.Area) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class UpdatePayload {
  constructor(public item: models.Area) {}
}

export class UpdateAction implements Action {
  readonly type = UPDATE;

  constructor(public payload: UpdatePayload) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddAirportAction
  | RemoveAirportAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | UpdateAction;
