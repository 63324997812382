import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as sharedSelectors from '../../shared/selectors';
import { Observable } from 'rxjs';

@Injectable()
export class DebugGuard implements CanActivate {
  constructor(private store$: Store<any>) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(select(sharedSelectors.getSystemIsDebug));
  }
}
