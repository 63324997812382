import { createReducer, on } from '@ngrx/store';

import * as _ from 'lodash';

import * as actions from '../actions/order.actions';
import * as models from '../../domain/models';
import * as helpers from '../../domain/helpers';

export const orderFeatureKey = 'order';

export interface State {
  order: models.Order;
  orderGeneratedDocuments: Array<models.SalesGeneratedDocument>;
  isLoaded: boolean;
  isLoading: boolean;
  isLoadedDocument: boolean;
  isLoadingDocument: boolean;
}

const initialState: State = {
  order: null,
  orderGeneratedDocuments: null,
  isLoaded: false,
  isLoading: false,
  isLoadedDocument: false,
  isLoadingDocument: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.cancel,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    actions.cancelSuccess,
    (state, { payload }): State => ({
      ...state,
      isLoading: false,
      order: payload,
    })
  ),
  on(
    actions.cancelFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.reinstate,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    actions.reinstateSuccess,
    (state, { payload }): State => ({
      ...state,
      isLoading: false,
      order: payload,
    })
  ),
  on(
    actions.reinstateFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.load,
    (state): State => ({
      ...state,
      order: null,
      isLoaded: false,
      isLoading: true,
    })
  ),
  on(actions.loadSuccess, (state, { payload }): State => {
    const order = payload;
    if (order && order.invoices.length) {
      const orderGeneratedDocuments = _.cloneDeep(order.invoices[0].documents)
        .filter(
          ({ documentTypeId }: models.SalesInvoiceDocument) =>
            documentTypeId === 1 || documentTypeId === 2
        )
        .map((e) => helpers.parseGeneratedDocument(e));
      return {
        ...state,
        order,
        orderGeneratedDocuments,
        isLoaded: true,
        isLoading: false,
      };
    }

    return {
      ...state,
      order,
      isLoaded: true,
      isLoading: false,
    };
  }),

  on(
    actions.markPaid,
    (state): State => ({
      ...state,
      isLoaded: false,
      isLoading: true,
    })
  ),
  on(actions.markPaidSuccess, (state, { payload }): State => {
    const order = payload;
    const orderGeneratedDocuments = _.cloneDeep(order.invoices[0].documents)
      .filter(
        ({ documentTypeId }: models.SalesInvoiceDocument) =>
          documentTypeId === 1 || documentTypeId === 2
      )
      .map((e) => helpers.parseGeneratedDocument(e));
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      orderGeneratedDocuments,
      order,
    };
  }),
  on(
    actions.markPaidFail,
    (state): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
    })
  ),

  on(
    actions.paymentConfirm,
    (state): State => ({
      ...state,
      isLoaded: false,
      isLoading: true,
    })
  ),
  on(
    actions.paymentConfirmSuccess,
    (state, { payload }): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
      order: payload,
    })
  ),
  on(
    actions.paymentConfirmFail,
    (state): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
    })
  ),

  on(
    actions.submitPaymentOption,
    actions.replaceInvoice,
    (state): State => ({
      ...state,
      isLoaded: false,
      isLoading: true,
    })
  ),

  on(actions.appendDocument, (state, { payload }): State => {
    const order = _.cloneDeep(state.order) as models.Order;

    order.invoices[0].documents = _.values(
      _.merge(_.keyBy(order.invoices[0].documents, 'documentId'), _.keyBy(payload, 'documentId'))
    ).reverse();

    return {
      ...state,
      order,
    };
  }),

  on(
    actions.reGenerateDocument,
    (state): State => ({
      ...state,
      isLoadedDocument: false,
      isLoadingDocument: true,
    })
  ),
  on(
    actions.reGenerateDocumentSuccess,
    actions.getDocumentsByOrderSuccess,
    (state, { payload }): State => {
      const order = _.cloneDeep(state.order) as models.Order;
      order.documentGenerationInProgress = payload.documentGenerationInProgress;
      order.showGeneratedDocumentsPanel = payload.showGeneratedDocumentsPanel;
      return {
        ...state,
        order,
        orderGeneratedDocuments: payload.documents,
        isLoadedDocument: true,
        isLoadingDocument: false,
      };
    }
  ),
  on(
    actions.reGenerateDocumentFail,
    (state): State => ({
      ...state,
      isLoadedDocument: true,
      isLoadingDocument: false,
    })
  ),

  on(
    actions.loadFail,
    actions.submitPaymentOptionSuccess,
    actions.submitPaymentOptionFail,
    actions.replaceInvoiceSuccess,
    actions.replaceInvoiceFail,
    (state): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
    })
  ),

  on(actions.setPaymentOption, (state, { payload }): State => {
    const order = _.cloneDeep(state.order) as models.Order;
    order.paymentOptions = _.cloneDeep(payload.item);
    return {
      ...state,
      order,
    };
  }),

  on(actions.setOrderStatus, (state, { payload }): State => {
    const order: models.Order = _.cloneDeep(state.order);
    order.orderStatus = payload;
    return {
      ...state,
      order,
    };
  }),

  on(
    actions.createSfdcOpportunity,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(actions.createSfdcOpportunitySuccess, (state): State => {
    const newOrder: models.Order = _.cloneDeep(state.order);
    newOrder.showCreateSFDCOpportunityButton = false;
    return {
      ...state,
      isLoading: false,
      order: newOrder,
    };
  }),
  on(
    actions.createSfdcOpportunityFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  )
);
