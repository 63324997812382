import * as constants from '../constants';
import * as models from '../models';

export class TaskLink {
  config: Array<{
    value: string;
    link: string;
  }>;

  constructor() {
    this.config = constants.TASK_LINK_CONFIG;
  }

  isLink(text: string, legSource: string): boolean {
    const isValidPostCheckLink = text.toLowerCase().replace(/\s+/g, '') === constants.ValidateLinks.postCheck ? (legSource === models.FlightTypeEnum.shuttle2) || (legSource === models.FlightTypeEnum.customshuttle) : true;

    return this.config.some(({ value }) => value.toLowerCase().replace(/\s+/g, '') === text.toLowerCase().replace(/\s+/g, '')) &&
      isValidPostCheckLink;
  }
}
