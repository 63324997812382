import { User } from './user';

export class Note {
  createdDate: Date;
  createdUser: User;
  text: string;

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): Note {
    this.createdDate = createdDate;
    return this;
  }

  getText(): string {
    return this.text;
  }

  setText(text): Note {
    this.text = text;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): Note {
    this.createdUser = createdUser;
    return this;
  }
}
