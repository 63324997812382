import { Action } from '@ngrx/store';

import * as models from '../../domain/models';

export const LOAD = '[YM Aircraft Categories Collection] Load';
export const SET_INITIAL = '[YM Aircraft Categories Collection] Set Initial';
export const LOAD_SUCCESS = '[YM Aircraft Categories Collection] Load Success';
export const LOAD_FAIL = '[YM Aircraft Categories Collection] Load Fail';

export class LoadAircraftCategoriesPayload {
  vendorId = '0f0fec1d-0cd3-459e-a2ff-24f38a8e216c';
}

export class LoadAircraftCategoriesAction implements Action {
  readonly type = LOAD;
  constructor(public payload: LoadAircraftCategoriesPayload) {}
}

export class LoadSuccessAircraftCategoriesPayload {
  entities: Array<models.AircraftCategory>;
}

export class LoadSuccessAircraftCategoriesAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessAircraftCategoriesPayload) {}
}

export class LoadFailAircraftCategoriesAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetInitialAction implements Action {
  readonly type = SET_INITIAL;
}

export type Actions =
  | LoadAircraftCategoriesAction
  | LoadSuccessAircraftCategoriesAction
  | SetInitialAction
  | LoadFailAircraftCategoriesAction;
