export class EmailBouncedRecipient {
  action: string;
  diagnosticCode: string;

  getAction(): string {
    return this.action;
  }
  
  setAction(action: string): EmailBouncedRecipient {
    this.action = action;
    return this;
  }

  getDiagnosticCode(): string {
    return this.diagnosticCode;
  }

  setDiagnosticCode(diagnosticCode: string): EmailBouncedRecipient {
    this.diagnosticCode = diagnosticCode;
    return this;
  }
}
