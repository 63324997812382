import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as contracts from '../../../domain/service-contracts/invoice';
import * as models from '../../../domain/models';
import * as coreTypes from '../../../../../core/types';

@Injectable()
export class InvoiceService implements contracts.IInvoiceService {
  private get API_PATH(): string {
    return this.configService.get('InvoiceServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  updateInvoice(
    request: contracts.UpdateInvoiceRequest
  ): Observable<contracts.UpdateInvoiceResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/update`;

    let params = new HttpParams();
    params = params.append('invoice', encodeURIComponent(JSON.stringify(request.invoice)));

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(requestUrl, params.toString(), { headers }).pipe(
      map(() => {
        return new contracts.UpdateInvoiceResponse();
      })
      // .catch((error: any): any => {
      //   const msg = error._body ? error._body : JSON.stringify(error);
      //   window.alert(`Server error: ${msg}`);

      //   return _throw(JSON.stringify(error) || 'Server error');
      // })
    );
  }

  getContactMethods(
    request: contracts.GetContactMethodsRequest
  ): Observable<contracts.GetContactMethodsResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/get-contact-methods`;
    const params = new HttpParams();

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetContactMethodsResponse();
        response.contactMethods = [];

        if (!svcJsonResp || !svcJsonResp.data || !svcJsonResp.data.rows) {
          return response;
        }

        svcJsonResp.data.rows.forEach((el) => {
          const contactMethod = new models.ContactMethod();
          contactMethod.id = el.contactMethodId;
          contactMethod.name = el.contactMethodName;
          contactMethod.description = el.description;
          contactMethod.message = el.content;
          contactMethod.contentTemplateId = el.contentTemplateId;
          response.contactMethods.push(contactMethod);
        });

        return response;
      })
    );
  }

  replaceInvoice(
    request: contracts.ReplaceInvoiceRequest
  ): Observable<contracts.ReplaceInvoiceResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/invoices/replace`;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const req = {
      invoiceReplacement: request,
    };

    return this.http.post(requestUrl, JSON.stringify(req), { headers }).pipe(
      map(() => {
        return new contracts.ReplaceInvoiceResponse();
      })
    );
  }

  consoleLogin(request: contracts.ConsoleLoginRequest): Observable<contracts.ConsoleLoginResponse> {
    const requestUrl = request.url;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(requestUrl, request.payload, { headers }).pipe(
      map(() => {
        return new contracts.ConsoleLoginResponse();
      })
    );
  }
}
