import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-layout',
  styleUrls: ['./simple-layout.component.scss'],
  templateUrl: './simple-layout.component.html'
})
export class SimpleLayoutComponent {

}
