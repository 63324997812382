import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as sharedTypes from '../../../../../shared/types';
import * as coreTypes from '../../../../../core/types';
import * as contracts from '../../../domain/service-contracts/product';
import * as models from '../../../domain/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductService implements contracts.IProductService {
  private get API_PATH(): string {
    return this.configService.get('ProductServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getProductData(
    request: contracts.GetProductDataRequest
  ): Observable<contracts.GetProductDataResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/products/get-product-data`;
    const params = new HttpParams();
    const products: Array<models.Product> = [];

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetProductDataResponse();
        response.entities = [];

        svcJsonResp.data.products
          .filter((p) => p.securityAccess !== models.SecurityAccessEnum.Denied)
          .forEach((el) => {
            const product = new models.Product();
            product.id = Number(el.productId);
            product.isRefundable = Boolean(el.isRefundable);
            product.name = el.productName;
            // product.description = el.description;
            product.amount = sharedTypes.Util.nvlToNumber(el.basePrice);
            product.conciergeDisplaySequence = el.conciergeDisplaySequence;
            // product.productTypeId = Number(el.productTypeId);
            product.parentProductId = Number(el.parentProductId);
            product.priceComponents = [];
            product.productFeatures = [];
            product.securityAccess = el.securityAccess;

            product.productType = new models.ProductType();
            product.productType.id = Number(el.productTypeId); // product.productTypeId;
            product.productType.name = el.productTypeName;
            product.productType.description = el.productTypeDescription;
            product.productType.parentProductTypeId = Number(el.productTypeParentProductTypeId);

            const priceComponents = svcJsonResp.data.priceComponents.filter(
              (pc) =>
                pc.productId === product.id &&
                pc.securityAccess !== models.SecurityAccessEnum.Denied
            );

            priceComponents.forEach((elPC) => {
              const priceComponent = new models.PriceComponent();
              priceComponent.id = elPC.priceComponentId;
              priceComponent.typeId = elPC.priceComponentTypeId;
              priceComponent.typeName = elPC.priceComponentTypeName;
              priceComponent.percentage = sharedTypes.Util.nvlToNumber(elPC.percentage);
              priceComponent.amount = sharedTypes.Util.nvlToNumber(elPC.amount);
              priceComponent.description = elPC.description;
              priceComponent.name = elPC.priceComponentName;
              priceComponent.securityAccess = elPC.securityAccess;
              product.priceComponents.push(priceComponent);
            });

            const productPriceComponentBrackets =
              svcJsonResp.data.productPriceComponentBrackets.find(
                (pcm) => pcm.productId === product.id
              ) as models.ProductPriceComponentsBracket;
            if (productPriceComponentBrackets) {
              productPriceComponentBrackets.brackets.forEach((elPCM) => {
                const brackets = new models.PriceComponentsBracket();
                brackets.base = elPCM.base;
                brackets.priceComponentId = elPCM.priceComponentId;
                brackets.sequence = elPCM.sequence;
                product.priceComponentsBrackets.push(brackets);
              });
            }

            const productFeatures = svcJsonResp.data.productFeatures.filter(
              (pf) => pf.productId === product.id
            );
            productFeatures.forEach((elPC) => {
              const productFeature = new models.ProductFeature();
              productFeature.id = elPC.productFeatureId;
              productFeature.name = elPC.productFeatureName;
              productFeature.typeId = elPC.productFeatureTypeId;
              productFeature.productId = elPC.productId;
              productFeature.value = elPC.value;
              product.productFeatures.push(productFeature);
            });

            products.push(product);
          });

        svcJsonResp.data.productDependencies.forEach((el) => {
          const productDependency = new models.MembershipProductDependency();
          productDependency.id = el.membershipProductDependencyId;
          productDependency.salesAction = Number(el.membershipSalesActionId);

          const childProductId = Number(el.childProductId);
          const childProduct = products.find((i) => i.id === childProductId);

          if (!childProduct) {
            throw new Error(
              `Can't find child product (id: ${childProductId}}) for Membership Product Dependency (id ${productDependency.id})`
            );
          }

          productDependency.childProduct = childProduct;
          productDependency.required = el.required;

          const parentProductId = Number(el.parentProductId);
          const parentProduct = products.find((i) => i.id === parentProductId);

          if (!parentProduct) {
            throw new Error(
              `Can't find parent product (id: ${parentProductId}}) for Membership Product Dependency (id ${productDependency.id})`
            );
          }

          parentProduct.productDependencies.push(productDependency);
        });

        if (svcJsonResp.data.consoleLoginCheck) {
          const consoleLoginPayload = new models.ConsoleLoginCheck();
          consoleLoginPayload.url = svcJsonResp.data.consoleLoginCheck.url;
          consoleLoginPayload.payload = svcJsonResp.data.consoleLoginCheck.payload;
          response.consoleLoginCheck = consoleLoginPayload;
        }

        response.entities = products;

        response.restrictions = svcJsonResp.data.restrictedFunctions
          ? svcJsonResp.data.restrictedFunctions.map((rf) => {
              const restrictedObj = new models.FunctionRestriction();
              restrictedObj.restrictedFunctionType = rf.restrictedFunctionId;
              restrictedObj.securityAccess = rf.securityAccess;

              return restrictedObj;
            })
          : [];

        return response;
      })
    );
  }

  getVendorList(
    request: contracts.GetVendorListRequest
  ): Observable<contracts.GetVendorListResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/products/vendors`;

    return this.http.get(requestUrl).pipe(
      map((svcResp: any) => {
        const items = svcResp.data;

        const response = new contracts.GetVendorListResponse();
        const suffix = '(PD2020)';
        response.entities = items
          .map((v) => {
            const vendor = new models.Vendor();
            vendor.id = v.vendorId;
            vendor.name = v.vendorName;
            vendor.products = v.products.map((vp) => {
              const productRef = new models.VendorProduct();
              productRef.productId = vp.productId;
              productRef.markupPercent = Number(vp.markupPercent);

              return productRef;
            });

            return vendor;
          })
          .sort(
            (a, b) =>
              a.name.endsWith(suffix) - b.name.endsWith(suffix) || a.name.localeCompare(b.name)
          );

        return response;
      })
    );
  }
}
