export class ApplicationConfig {
  configId: number;
  application: string;
  scope: string;
  key: string;
  value: string;
  createTime: Date;

  getConfigId(): number {
    return this.configId;
  }

  setConfigId(configId:number): ApplicationConfig {
    this.configId = configId;

    return this;
  }

  getApplication(): string {
    return this.application;
  }

  setApplication(application: string): ApplicationConfig {
    this.application = application;

    return this;
  }

  getScope(): string {
    return this.scope;
  }

  setScope(scope: string): ApplicationConfig {
    this.scope = scope;

    return this;
  }

  getKey(): string {
    return this.key;
  }

  setKey(key: string): ApplicationConfig {
    this.key = key;

    return this;
  }

  getValue(): string {
    return this.value;
  }

  setValue(value: string): ApplicationConfig {
    this.value = value;

    return this;
  }

  getCreateTime(): Date {
    return this.createTime;
  }

  setCreateTime(createTime: Date): ApplicationConfig {
    this.createTime = createTime;

    return this;
  }
}