import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/deny-reason-collection.actions';
import * as models from '../../domain/models';

export const denyReasonsCollectionFeatureKey = 'denyReasonsCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.DenyReason>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      items: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState,
    })
  )
);
