import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Marketplace.FlightRequest] Load';
export const LOAD_SUCCESS = '[Marketplace.FlightRequest] Load Success';
export const CREATE_SOURCING_REQUEST = '[Marketplace.FlightRequest] Create Sourcing Request';
export const CREATE_SOURCING_REQUEST_SUCCESS = '[Marketplace.FlightRequest] Create Sourcing Request Success';
export const CREATE_SOURCING_REQUEST_FAIL = '[Marketplace.FlightRequest] Create Sourcing Request Fail';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: models.FlightRequest) {}
}

export class CreateSourcingRequestAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST;
}

export class CreateSourcingRequestSuccessAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST_SUCCESS;
}

export class CreateSourcingRequestFailAction implements Action {
  readonly type = CREATE_SOURCING_REQUEST_FAIL;
}

export type Actions = LoadAction
  | LoadSuccessAction
  | CreateSourcingRequestAction
  | CreateSourcingRequestSuccessAction
  | CreateSourcingRequestFailAction;
