import * as moment from 'moment';

export class CustomShuttleCriteria {
  active = false;
  aircraftCategoryName = 'All';
  offerTypeName = 'All';
  membershipTierName = 'All';
  selectedRouteOptionId = 1;
  startDate: Date = moment.utc().toDate();
  year: number = moment().utc().year();
  month: number = moment().utc().month();
}
