export const response = {
  sfdcOpportunityId: '0068A00000BbK0cQAF',
  clientId: 1706554,
  salesActionId: 1,
  productId: 77,
  total: 20000,
  warningMessage: '',
  errorMessage:
    'Contract Commencement Date cannot be in the past. Contract Commencement Date: 2022-04-26',
  warningPopupAlert: false,
  errorPopupAlert: false,

  creditAmount: 123.45,
  creditMethod: 1,
  creditReasons: [1, 2, 3, 4, 5],
  internalComment: 'adsfadf',
};
