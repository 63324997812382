export class UserGroup {
    id: number;
    name: string;
    active: boolean;
    description: string;
    createTime: Date;

    getId(): number {
        return this.id;
    }

    setId(value: number): UserGroup {
        this.id = value;

        return this;
    }

    getName(): string {
        return this.name;
    }

    setName(value: string): UserGroup {
        this.name = value;

        return this;
    }

    getActive(): boolean {
        return this.active;
    }

    setActive(value: boolean): UserGroup {
        this.active = value;

        return this;
    }

    getDescription(): string {
        return this.description;
    }

    setDescription(value: string): UserGroup {
        this.description = value;

        return this;
    }

    getCreateTime(): Date {
        return this.createTime;
    }

    setCreateTime(value: Date): UserGroup {
        this.createTime = value;

        return this;
    }
}