<div class="dialog-container-iframe overlay">
  <div class="top">
    <div class="jumbotron m-b-0">
      <div class="container-fluid container-lg sm-p-r-20">
        <a class="close-icon-light overlay-close text-black fs-16 m-t-10"
           (click)="onClose.emit()">
          <i class="fal fa-times"></i>
        </a>
      </div>
    </div>
  </div>

  <div class="full-height-d"
       (click)="$event.stopPropagation()">
    <div class="container-xs-height full-height container-body bg-white"
         *ngIf="selection === 0">
      <div class="row-xs-height">
        <div class="col col-xs-height col-middle">
          <div class="error-container text-center"
               *ngIf="correctAnswerCheck(answer) === false">
            <span class="inline m-r-5"><i class="fal fa-2x fa-exclamation-triangle"></i></span>
            <h2 class="inline semi-bold">Restricted Access</h2>
            <span class="inline m-l-5"><i class="fal fa-2x  fa-exclamation-triangle"></i></span>
            <p>This section is for testing purposes only.</p>
            <p>To proceed please answer the following challenge.</p>
            <p>What was the name of the first JetSmarter mobile app?</p>
            <input [(ngModel)]="answer">
          </div>

          <div class="error-container text-center"
               *ngIf="correctAnswerCheck(answer) === true">
            <div class="row">
              <div class="col-12">
                <button class="btn btn-lg btn-primary btn-cons"
                        (click)="initSn()">
                  <i class="fal fa-snake fa-2x"></i>
                </button>
              </div>
            </div>
            <div class="row m-t-20">
              <div class="col-12">
                <button class="btn btn-lg btn-primary btn-cons"
                        (click)="selection = 2">
                  <svg id="Capa_1"
                       enable-background="new 0 0 512 512"
                       height="30"
                       viewBox="0 0 512 512"
                       width="30"
                       xmlns="http://www.w3.org/2000/svg">
                    <g>
                      <g fill="#8690af">
                        <path d="m502 163.003v30c0 49.71-40.29 90-90 90h-312c-49.71 0-90-40.29-90-90v-30h35.97c85.42 0 68.35-84.41 165.03-114v9.28c0 24.86 20.15 44.72 45 44.72s45-19.86 45-44.72v-9.28c100.02 30.62 75.74 114 165.03 114z" />
                        <path d="m346 403.003c0 33.14-26.86 60-60 60s-60-26.86-60-60 26.86-30 60-30 60-3.14 60 30z" />
                      </g>
                      <g>
                        <path d="m218.929 230.075c3.905 3.905 10.237 3.905 14.143 0l22.928-22.929 22.929 22.929c3.905 3.905 10.237 3.905 14.143 0 3.905-3.905 3.905-10.237 0-14.143l-22.929-22.929 22.929-22.929c3.905-3.905 3.905-10.237 0-14.143-3.905-3.905-10.237-3.905-14.143 0l-22.929 22.93-22.929-22.929c-3.905-3.905-10.237-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.143l22.929 22.929-22.929 22.929c-3.904 3.904-3.904 10.236.001 14.142z" />
                        <path d="m111.563 334.694c-4.59-3.073-10.8-1.843-13.873 2.746s-1.843 10.8 2.746 13.873c36.123 24.185 75.096 43.958 115.927 58.852 3.598 35.247 33.455 62.839 69.636 62.839 25.014 0 46.993-13.197 59.374-32.986 20.172 2.128 40.53 3.131 60.698 2.986 5.522-.04 9.967-4.549 9.927-10.072-.04-5.522-4.542-10.009-10.072-9.927-17.348.125-34.86-.646-52.21-2.264 1.486-5.667 2.283-11.61 2.283-17.737 0-39.295-31.423-40.242-62.137-40-5.146.04-21.788-.01-24.863 0-22.104.073-44.923 2.291-51.359 26.297-37.298-14.09-72.934-32.418-106.077-54.607zm159.38 48.231c6.538 0 11.218.129 23.076.037 32.964-.262 41.981 1.078 41.981 20.041 0 27.57-22.43 50-50 50s-50-22.43-50-50c0-17.628 7.851-20.078 34.943-20.078z" />
                        <path d="m100 293.003h111c5.523 0 10-4.477 10-10s-4.477-10-10-10h-111c-44.112 0-80-35.888-80-80v-20h25.97c49.17 0 69.005-26.663 88.187-52.448 15.956-21.449 32.401-43.554 67.049-57.507 2.436 27.949 26.081 49.956 54.793 49.956 28.724 0 52.376-22.021 54.796-49.986 35.882 14.272 51.597 36.369 66.846 57.809 18.245 25.652 37.11 52.177 88.388 52.177h25.971v20c0 44.112-35.888 80-80 80h-111c-5.523 0-10 4.477-10 10s4.477 10 10 10h111c55.14 0 100-44.86 100-100v-30c0-5.523-4.477-10-10-10h-35.97c-40.959 0-54.017-18.359-72.09-43.769-17.109-24.056-38.402-53.994-90.013-69.793-6.421-1.967-12.927 2.844-12.927 9.561v9.28c0 19.145-15.701 34.72-35 34.72s-35-15.576-35-34.72v-9.28c0-6.715-6.505-11.53-12.927-9.562-49.853 15.258-71.162 43.903-89.963 69.176-19.169 25.768-33.019 44.386-72.14 44.386h-35.97c-5.523 0-10 4.477-10 10v30c0 55.14 44.86 100 100 100z" />
                        <circle cx="256"
                                cy="283.003"
                                r="10" />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div class="row m-t-20">
              <div class="col-12">
                <button class="btn btn-lg btn-cons"
                        (click)="back()">
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="iframe-container"
         *ngIf="selection === 2">
      <iframe [src]="iFrameContentVal | safeHtml"></iframe>
    </div>
    <div class="iframe-container"
         *ngIf="selection === 1 && ready === true">
      <canvas id="canvas"></canvas>
    </div>


    <div class="container-xs-height full-height container-body bg-white"
         *ngIf="selection === 1 && ready === false">
      <div class="row-xs-height">
        <div class="col col-xs-height col-middle">
          <div class="error-container text-center">
            <h2 class="semi-bold">Get Ready!</h2>
            <p>Use keyboard arrow keys.</p>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>

<kendo-dialog *ngIf="over">
  <confirmation-dialog text="Game Over"
                       [confirmText]="'Quit'"
                       [cancelText]="'Restart'"
                       (confirm)="quit()"
                       (close)="snRestart()">
  </confirmation-dialog>
</kendo-dialog>
