export enum SortLegsEnum {
  legId = 'LEG_ID',
  fosTripId = 'FOS_TRIP_ID',
  tailNumber = 'TAIL_NUMBER',
  pod = 'POD',
  source = 'SOURCE',
  airportCode = 'AIRPORT_CODE',
  departureDateTime = 'DEPARTURE_DATE_TIME',
  nextTaskDue = 'NEXT_TASK_DUE_DATE',
}

export enum SortOrderLegsEnum {
  asc = 'ASC',
  desc = 'DESC',
}

export class SortLegs {
  sortOrder: SortOrderLegsEnum;
  sortBy: SortLegsEnum;
}
