import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const save = createAction(
  '[Sales.PricingCalculator] Save',
  props<{ payload: SavePayload }>()
);

export class SavePayload {
  offer: models.PricingCalculatorOffer;
}

export const saveSuccess = createAction(
  '[Sales.PricingCalculator] Save Success',
  props<{ payload: SaveSuccessPayload }>()
);

export class SaveSuccessPayload {
  log: models.CalculationLog;
  items: Array<models.PriceCalculator>;
}

export const saveFail = createAction('[Sales.PricingCalculator] Save Fail');

export const update = createAction(
  '[Sales.PricingCalculator] Update',
  props<{ payload: UpdatePayload }>()
);

export class UpdatePayload {
  offer: models.PriceCalculatorOfferRequest;
}

export const updateSuccess = createAction(
  '[Sales.PricingCalculator] Update Success',
  props<{ payload: UpdateSuccessPayload }>()
);

export class UpdateSuccessPayload {
  log: models.CalculationLog;
  items: Array<models.PriceCalculator>;
}

export const updateFail = createAction('[Sales.PricingCalculator] Update Fail');

export const calculate = createAction(
  '[Sales.PricingCalculator] Calculate',
  props<{ payload: CalculatePayload }>()
);

export class CalculatePayload {
  logId: string;
}

export const calculateSuccess = createAction(
  '[Sales.PricingCalculator] Calculate Success',
  props<{ payload: SaveSuccessPayload }>()
);

export const calculateFail = createAction('[Sales.PricingCalculator] Calculate Fail');

export const setInputParams = createAction(
  '[Sales.PricingCalculator] Set Input Params',
  props<{ offer: models.PricingCalculatorOffer }>()
);

export const selectItem = createAction(
  '[Sales.PricingCalculator] Select Item',
  props<{ actype: string }>()
);

export const setInitial = createAction('[Sales.PricingCalculator] Set Initial');
