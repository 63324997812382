import { createAction, props } from '@ngrx/store';

export const login = createAction('[Sales.ConsoleLoginCheck] Login');
export const loginSuccess = createAction('[Sales.ConsoleLoginCheck] Login Success');
export const loginFail = createAction('[Sales.ConsoleLoginCheck] Login Fail');

export const setUrl = createAction(
  '[Sales.ConsoleLoginCheck] Set URL',
  props<{ payload: string }>()
);

export const setPayload = createAction(
  '[Sales.ConsoleLoginCheck] Set Payload',
  props<{ payload: any }>()
);
