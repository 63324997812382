import * as _ from 'lodash';

import * as models from '../../domain/models/magic-override';
import {Paginable} from '../../domain/models/magic-override';
import * as actions from '../actions/magic-override';
import {LoadSuccessPayload} from '../actions/magic-override';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  filterCriteria: models.FilterCriteria;
  paginator: models.Paginable;
  legCollection: LoadSuccessPayload;
  initialLoading: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  filterCriteria: new models.FilterCriteria(),
  paginator: new models.Paginable(),
  legCollection: null,
  initialLoading: true
};

export function reducer(state = initialState, action: any): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        legCollection: action.payload,
        isLoaded: true,
        isLoading: false,
        initialLoading: false
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SET_FILTER: {
      const filterCriteria: models.FilterCriteria = _.cloneDeep(action.payload);
      const paginator = new Paginable();
      paginator.offset = 0;
      paginator.limit = state.legCollection?.paginator?.limit;
      paginator.sortBy = state.legCollection?.paginator?.sortBy;
      paginator.sortOrder = state.legCollection?.paginator?.sortOrder;

      return {
        ...state,
        legCollection: {
          ...state.legCollection,
          paginator
        },
        filterCriteria,
      };
    }

    case actions.RESET_FILTER: {
      return {...state, filterCriteria: new models.FilterCriteria()};
    }

    case actions.DEACTIVATE_LEG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.DEACTIVATE_LEG_WARNING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.DEACTIVATE_LEG_WARNING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case actions.DEACTIVATE_LEG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case actions.DEACTIVATE_LEG_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.ACTIVATE_LEG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.ACTIVATE_LEG_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsInitialLoad = (state: State) => state.initialLoading;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.legCollection;
export const getFilters = (state: State) => state.filterCriteria;
export const getPaginator = (state: State) => state.legCollection?.paginator;
