import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const BYPASS_LOAD = '[RM Aircraft Detail] Bypass Load';
export const LOAD = '[RM Aircraft Detail] Load';
export const LOAD_SUCCESS = '[RM Aircraft Detail] Load Success';
export const LOAD_FAIL = '[RM Aircraft Detail] Load Fail';
export const RESET = '[RM Aircraft Detail] Reset';
export const LOAD_OPERATOR = '[RM Aircraft Detail] Load';
export const LOAD_OPERATOR_SUCCESS = '[RM Aircraft Detail] Load Operator Success';
export const LOAD_OPERATOR_FAIL = '[RM Aircraft Detail] Load Operator Fail';
export const SET = '[RM Aircraft Detail] Set';
export const SAVE = '[RM Aircraft Detail] Save';
export const SAVE_SUCCESS = '[RM Aircraft Detail] Save Success';
export const SAVE_FAIL = '[RM Aircraft Detail] Save Fail';
export const SET_EDIT_MODE = '[RM Aircraft Detail] Set Edit Mode';
export const SET_EDIT_ACTION_NEW = '[RM Aircraft Detail] Set Edit Action New';
export const LOAD_WYVERN = '[RM Aircraft Detail] Load Wyvern';
export const LOAD_WYVERN_SUCCESS = '[RM Aircraft Detail] Load Wyvern Success';
export const LOAD_WYVERN_FAIL = '[RM Aircraft Detail] Load Wyvern Fail';
export const SYNC_WITH_GV = '[RM Aircraft Detail] Sync With GV';
export const SYNC_WITH_GV_SUCCESS = '[RM Aircraft Detail] Sync With GV Success';
export const SYNC_WITH_GV_FAIL = '[RM Aircraft Detail] Sync With GV Fail';

export class BypassAction implements Action {
  readonly type = BYPASS_LOAD;
}

export class SetPayload {
  constructor(public item: models.Aircraft) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class LoadPayload {
  constructor(public id: number) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Aircraft) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.Aircraft) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class SetEditModeAction implements Action {
  readonly type = SET_EDIT_MODE;

  constructor(public payload: boolean) {}
}

export class SetEditActionNewAction implements Action {
  readonly type = SET_EDIT_ACTION_NEW;

  constructor(public payload: boolean) {}
}

export class LoadWyvernAction implements Action {
  readonly type = LOAD_WYVERN;

  constructor(public payload: string) {}
}

export class LoadWyvernSuccessAction implements Action {
  readonly type = LOAD_WYVERN_SUCCESS;

  constructor(public payload: models.AircraftWyvern) {}
}

export class LoadWyvernFailAction implements Action {
  readonly type = LOAD_WYVERN_FAIL;

  constructor(public payload: string) {}
}

export class SyncWithGVAction implements Action {
  readonly type = SYNC_WITH_GV;

  constructor(public payload: number) {}
}

export class SyncWithGVSuccessAction implements Action {
  readonly type = SYNC_WITH_GV_SUCCESS;
}

export class SyncWithGVFailAction implements Action {
  readonly type = SYNC_WITH_GV_FAIL;
}

export type Actions =
  | BypassAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | ResetAction
  | SetAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | SetEditModeAction
  | SetEditActionNewAction
  | LoadWyvernAction
  | LoadWyvernSuccessAction
  | LoadWyvernFailAction
  | SyncWithGVAction
  | SyncWithGVSuccessAction
  | SyncWithGVFailAction;
