import { createSelector } from 'reselect';

import * as fromCore from '../../core/reducers';

import { systemFeatureKey } from '../reducers/system.reducer';

export const getSystemState = createSelector(
  fromCore.getCoreState,
  (state) => state[systemFeatureKey]
);
export const getBrowserSupported = createSelector(
  getSystemState,
  (state) => state.browserSupported
);
export const getSystemIsDebug = createSelector(getSystemState, (state) => state.isDebug);
export const getSystemVersion = createSelector(getSystemState, (state) => state.version);
