import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthModule } from "@auth0/auth0-angular";

import * as moduleEffects from './effects';

import * as shared from '../shared/types';
import * as coreContracts from './service-contracts/airport';
import * as coreAuth0ErrorContracts from './service-contracts/auth0-error-notification';
import * as configServiceContracts from './services/config/contracts';

import { SharedModule } from '../shared/shared.module';
import { PagesModule } from '../../assets/pages/components/pages.module';
import { pgTabsModule } from '../../assets/pages/components/tabs/tabs.module';
import * as moduleContainers from './containers';
import * as moduleComponents from './components';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LayoutModule } from '@progress/kendo-angular-layout';
import * as moduleValidators from '../core/validators';
import { environment } from '../../environments/environment';
import { EnvironmentEnum } from '../../environments/environment.interface';
import * as moduleServices from '../modules/+sales/services';
import * as coreServices from '../core/services';
import * as interceptors from './interceptors';

// Application wide components, services
// In case if component should be reused in other applications, better put it into common module
@NgModule({
  declarations: [
    moduleContainers.AccessDeniedPageComponent,
    moduleContainers.AppComponent,
    moduleContainers.DebugComponent,
    moduleContainers.DefaultLayoutComponent,
    moduleContainers.NotFoundPageComponent,
    moduleContainers.LandingComponent,
    moduleContainers.SimpleLayoutComponent,
    moduleContainers.VersionHistoryPageComponent,
    moduleComponents.FooterComponent,
    moduleComponents.ConfirmationDialogComponent,
    moduleComponents.SecondaryComponent,
    moduleComponents.WarningDialogComponent,
  ],

  // ToDo Update after implementing auth0 authorization in production
  imports: [
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: { ...environment.httpInterceptor },
    }),
    CommonModule,
    DialogModule,
    FormsModule,
    PagesModule,
    pgTabsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    EffectsModule.forFeature([moduleEffects.UserEffects, moduleEffects.Auth0ErrorNotificationEffects]),
    TooltipModule,
    LayoutModule,
  ],
  providers: [
    moduleValidators.AirportCodeValidator,
    moduleValidators.AirportCodeValidatorMock,
    {
      provide: shared.GlobalErrorHandler,
      useFactory: globalErrorHandlerFactory,
      deps: [HttpClient, configServiceContracts.SERVICE_TOKEN, Store]
    },
    {
      provide: coreContracts.SERVICE_TOKEN,
      useClass:
        environment.environment !== EnvironmentEnum.LOCAL
          ? moduleServices.AirportRestService
          : moduleServices.AirportMockService,
    },
    {
      provide: coreAuth0ErrorContracts.SERVICE_TOKEN,
      useClass: coreServices.Auth0ErrorNotificationService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.SsoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.HttpUnauthorizedInterceptor,
      multi: true,
    }
  ],
  exports: [
    moduleComponents.ConfirmationDialogComponent,
    moduleComponents.WarningDialogComponent,
  ],
})
export class CoreModule {}


export function globalErrorHandlerFactory(
  http: HttpClient,
  configService: configServiceContracts.IConfigService,
  store: Store<any>): shared.GlobalErrorHandler {
  const loggers: Array<shared.ILogger> = [];
  loggers.push(new shared.ErrorWebServiceLoggerBridge(http, configService));

  return new shared.GlobalErrorHandler(store, loggers);
}
