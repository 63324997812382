import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router
} from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as sharedTypes from '../../../shared/types';
import * as layoutActions from '../../actions/layout.actions';
import * as layoutReducers from '../../reducers/layout.reducer';
import * as sharedSelectors from '../../../shared/selectors';
import * as models from '../../models';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'shared-wireframe-root',
  templateUrl: './wireframe.component.html',
  styleUrls: ['./wireframe.component.scss']
})
export class WireframeComponent implements OnDestroy, OnInit, AfterViewInit {
  browserNowSupported$: Observable<boolean>;
  debugSubscription: Subscription;
  environment = environment.environment;
  outletRouteRegExp = new RegExp(/\/\([^)]*\)/, 'g');
  lastNavigateUrl = '';
  errorIsVisible$: Observable<boolean>;
  // isAppView$: Observable<boolean>;
  isDebug$: Observable<boolean>;
  // isFullHeightView$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  // menuItems$: Observable<Array<sharedTypes.MenuItem>>;
  // outletIsVisible$: Observable<boolean>;
  // user$: Observable<models.User>;
  version$: Observable<string>;
  // activeRoute$: Observable<string>;

  LayoutType = models.LayoutType;
  layoutType = models.LayoutType.Simple;
  layoutType$: Observable<models.LayoutType>;

  private notificationService: sharedTypes.INotificationService;
  private layoutStateSubscription: Subscription;

  constructor(
    public store$: Store<any>,
    private router: Router) {

    this.browserNowSupported$ = this.store$.pipe(
      select(sharedSelectors.getBrowserSupported),
      map(supported => supported === false));
    // this.isAppView$ = this.store$.pipe(select(reducers.getLayoutIsAppView));
    this.isDebug$ = this.store$.pipe(select(sharedSelectors.getSystemIsDebug));
    this.layoutType$ = this.store$.pipe(select(sharedSelectors.getLayoutType));
    // this.user$ = this.store$.pipe(select(reducers.getUserUser));
    this.version$ = this.store$.pipe(select(sharedSelectors.getSystemVersion));
    // this.activeRoute$ = store$.pipe(select(reducers.getRouterPath));

    // this.isFullHeightView$ = combineLatest([
    //   this.store$.pipe(select(reducers.getLayoutState),
    //   this.store$.pipe(select(reducers.getSystemState)])
    //   .map(([layoutState, systemState]) => {
    //     return layoutState.isAppView
    //       || layoutState.isLoading
    //       || layoutState.isFullHeightView
    //       || layoutState.isError
    //       || systemState.browserSupported === false;
    //   });
    //
    // this.isLoading$ = this.store$
    //   .pipe(select(reducers.getLayoutState)
    //   .map((layoutState: layoutReducers.State) => layoutState.isLoading && layoutState.isError === false);
    //
    // this.outletIsVisible$ = combineLatest([
    //   this.store$.pipe(select(reducers.getLayoutState),
    //   this.store$.pipe(select(reducers.getSystemState)])
    //   .map(([layoutState, systemState]) => {
    //     return layoutState.isLoading === false && layoutState.isError === false && systemState.browserSupported;
    //   });
    //
    // this.errorIsVisible$ = this.store$
    //   .pipe(select(reducers.getLayoutState)
    //   .map((state: layoutReducers.State) => state.isError);
    //
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.layoutStateSubscription = this.store$
      .pipe(select(sharedSelectors.getLayoutState))
      .subscribe((layoutState: layoutReducers.State) => {
        this.layoutType = layoutState.layoutType;
      });
  }

  // getMenuItems(): Array<MenuItem> {
  //   return [];
  // }

  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent): void {
  //   if (event.ctrlKey && event.keyCode === 4) { // Ctrl+D
  //     this.store$.pipe(
  //       select(reducers.getSystemIsDebug),
  //       first())
  //       .subscribe(isDebug => {
  //         this.store$.dispatch(new systemActions.SetDebugAction(!isDebug));
  //       });
  //   } else if (event.ctrlKey && event.keyCode === 6) { // Ctrl+F
  //     this.store$.pipe(
  //       select(reducers.getLayoutIsAppView),
  //       first())
  //       .subscribe(appView => {
  //         if (appView === true) {
  //           this.store$.dispatch(new layoutActions.DeactivateAppViewAction());
  //         } else {
  //           this.store$.dispatch(new layoutActions.ActivateAppViewAction());
  //         }
  //       });
  //   }
  // }

  ngOnDestroy(): void {
    if (this.debugSubscription) {
      this.debugSubscription.unsubscribe();
    }

    if (this.layoutStateSubscription) {
      this.layoutStateSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.layoutStateSubscription = this.store$
      .pipe(select(sharedSelectors.getLayoutState))
      .subscribe((layoutState: layoutReducers.State) => {
        this.layoutType = layoutState.layoutType;
      });
  }

  ngAfterViewInit(): void {
    // this.debugSubscription = this.store$
    //   .pipe(
    //     select(reducers.getSystemIsDebug),
    //     skip(1))
    //   .subscribe(isDebug => {
    //     const str = isDebug ? 'enabled' : 'disabled';
    //     this.notificationService.show(`Debug ${str}`, sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Info,
    //       sharedTypes.NotificationPosition.Top);
    //   });
  }

  // setNotificationService(notificationService: sharedTypes.INotificationService): void {
  //   this.notificationService = notificationService;
  // }

  // signOut(): void {
  //   this.store$.dispatch(new userActions.SignOutAction());
  // }

  private isNamedOutletNavigation(event: any): boolean {
    return this.lastNavigateUrl && this.lastNavigateUrl.replace(this.outletRouteRegExp, '') === event?.url.replace(this.outletRouteRegExp, '');
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      if (this.isNamedOutletNavigation(event)) {
        this.store$.dispatch(layoutActions.showNamedOutletSpinner());
      } else {
        this.store$.dispatch(layoutActions.showSpinner());
      }
    }

    if (event instanceof NavigationEnd) {
      if (this.isNamedOutletNavigation(event)) {
        this.store$.dispatch(layoutActions.hideNamedOutletSpinner());
      } else {
        this.store$.dispatch(layoutActions.hideSpinner());
      }
      this.lastNavigateUrl = event.url;
    }

    if (event instanceof NavigationCancel) {
      if (this.isNamedOutletNavigation(event)) {
        this.store$.dispatch(layoutActions.hideNamedOutletSpinner());
      } else {
        this.store$.dispatch(layoutActions.hideSpinner());
      }
    }

    if (event instanceof NavigationError) {
      if (this.isNamedOutletNavigation(event)) {
        this.store$.dispatch(layoutActions.hideNamedOutletSpinner());
      } else {
        this.store$.dispatch(layoutActions.hideSpinner());
      }
    }
  }
}
