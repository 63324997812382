import { Action } from '@ngrx/store';
import * as models from '../../domain/models';


export const LOAD = '[RM Application Config Detail] Load';
export const LOAD_SUCCESS = '[RM Application Config Detail] Load Success';
export const LOAD_FAIL = '[RM Application Config Detail] Load Fail';
export const RESET = '[RM Application Config Detail] Reset';
export const SET = '[RM Application Config Detail] Set';
export const CANCEL = 'Rm Application Config Detail] Cancel';
export const SAVE = '[RM Application Config Detail] Save';
export const SAVE_SUCCESS = '[RM Application Config Detail] Save Success';
export const SAVE_FAIL = '[RM Application Config Detail] Save Fail';
export const DELETE = '[RM Application Config Detail] Delete';
export const DELETE_SUCCESS = '[RM Application Config Detail] Delete Success';
export const DELETE_FAIL = '[RM Application Config Detail] Delete Fail';
export const TOGGLE_EDIT_MODE = '[RM Application Config Detail] Toggle Edit Mode';


export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetPayload {
  constructor(public item: models.ApplicationConfig) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class CancelAction implements Action {
  readonly type = CANCEL;
}

export class LoadPayload {
  constructor(public configId: number) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.ApplicationConfig) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.ApplicationConfig) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class ToggleEditModeAction implements Action {
  readonly type = TOGGLE_EDIT_MODE;
}

export class DeleteAction implements Action {
  readonly type = DELETE;
}
export class DeleteSuccessAction implements Action {
  readonly type = DELETE_SUCCESS;
}

export class DeleteFailAction implements Action {
  readonly type = DELETE_FAIL;
}


export type Actions =
  | ResetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetAction
  | CancelAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | ToggleEditModeAction
  | DeleteAction
  | DeleteSuccessAction
  | DeleteFailAction;
