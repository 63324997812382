import { ChargeComponent } from './charge-component';
import { LegRequest } from './leg-request';
import { Route } from './route';

export class LegQuote {
  private route: Route;
  private departureDate: Date;
  private departureTimeTbd = false;
  private pax: number;
  private eft: number;
  private repo = false;
  private fuelStop = false;
  private suppressTaxSegCalculation = false;
  private amount = 0;
  private currency = 'USD';
  private chargeComponents: Array<ChargeComponent> = [];

  static fromLegRequest(lr1: LegRequest): LegQuote {
    const lq1 = new LegQuote()
      .setDepartureDate(lr1.getDepartureDate())
      .setDepartureTimeTbd(lr1.getDepartureTimeTbd())
      .setRoute(lr1.getRoute())
      .setPax(lr1.getPax())
      .setChargeComponents(lr1.getChargeComponents());

    return lq1;
  }

  constructor() {
    this.route = new Route();
  }

  getCurrency(): string {
    return this.currency;
  }

  setCurrency(currency: string): LegQuote {
    this.currency = currency;

    return this;
  }

  getDepartureDate(): Date {
    return this.departureDate;
  }

  setDepartureDate(date: Date): LegQuote {
    if (this.departureDate) {
      const d1 = new Date(this.departureDate.getTime());
      const d2 = new Date(date.getTime());
      d2.setHours(d1.getHours(), d1.getMinutes(), d1.getSeconds(), d1.getMilliseconds());
      this.departureDate = d2;
    } else {
      this.departureDate = new Date(date);
    }

    return this;
  }

  getDepartureTime(): Date {
    return this.departureDate;
  }

  setDepartureTime(date: Date): LegQuote {
    this.departureDate.setHours(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    this.departureTimeTbd = false;

    return this;
  }

  getDepartureTimeTbd(): boolean {
    return this.departureTimeTbd;
  }

  setDepartureTimeTbd(value: boolean): LegQuote {
    this.departureTimeTbd = value;

    if (this.departureTimeTbd && this.departureDate) {
      this.departureDate.setHours(0, 0, 0, 0);
    }

    return this;
  }

  getRoute(): Route {
    return this.route;
  }

  setRoute(route: Route): LegQuote {
    this.route = route;

    return this;
  }

  getPax(): number {
    return this.pax;
  }

  setPax(pax: number): LegQuote {
    this.pax = pax;

    return this;
  }

  getEft(): number {
    return this.eft;
  }

  setEft(eft: number): LegQuote {
    this.eft = eft;

    return this;
  }

  getRepo(): boolean {
    return this.repo;
  }

  setRepo(repo: boolean): LegQuote {
    this.repo = repo;

    return this;
  }

  getFuelStop(): boolean {
    return this.fuelStop;
  }

  setFuelStop(fuelStop: boolean): LegQuote {
    this.fuelStop = fuelStop;
    return this;
  }
  getSuppressTax(): boolean {
    return this.suppressTaxSegCalculation;
  }

  setSuppressTax(suppressTax: boolean): LegQuote {
    this.suppressTaxSegCalculation = !!suppressTax;
    return this;
  }

  getAmount(): number {
    return this.amount;
  }

  setAmount(amount: number): LegQuote {
    this.amount = amount;

    return this;
  }

  addChargeComponent(chargeComponent: ChargeComponent): LegQuote {
    this.chargeComponents.push(chargeComponent);

    this.amount += chargeComponent.getAmount();

    return this;
  }

  getChargeComponents(): Array<ChargeComponent> {
    return this.chargeComponents;
  }

  setChargeComponents(chargeComponents: Array<ChargeComponent>): LegQuote {
    this.chargeComponents = chargeComponents;

    return this;
  }

  isValidForSubmit(): boolean {
    return (
      this.getDepartureDate() &&
      this.getRoute() &&
      this.getRoute().getOriginAirport() &&
      this.getRoute().getOriginAirport().code !== null &&
      this.getRoute().getDestinationAirport() &&
      this.getRoute().getDestinationAirport().code !== null &&
      this.getEft() !== 0 &&
      this.getEft() > 0
      // && ((!this.getRepo() && this.getPax() > 0) || (this.getRepo() && this.getPax() === 0))
    );
  }
}
