import { PriceComponent } from './price-component';
import { ProductFeature } from './product-feature';
import { ProductType } from './product-type';
import { PriceComponentsBracket } from './price-components-bracket';
import { MembershipProductDependency } from './membership-product-dependency';
import { Vendor } from './vendor';
import { SecurityAccessEnum } from './security-access-enum';

export class Product {
  id: number = null;
  // productTypeId: number = null;
  parentProductId: number = null;
  name: string = null;
  description: string = null;
  amount: number = null;
  conciergeDisplaySequence: number = null;
  priceComponents: Array<PriceComponent> = [];
  priceComponentsBrackets: Array<PriceComponentsBracket> = [];
  productFeatures: Array<ProductFeature> = [];
  productType: ProductType = null;
  productDependencies: Array<MembershipProductDependency> = [];
  isRefundable = false;
  vendorList: Array<Vendor> = [];
  securityAccess: SecurityAccessEnum = SecurityAccessEnum.Denied;
}
