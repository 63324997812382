import * as models from '../../domain/models';

import * as actions from '../actions/sourcing-request-list';
import * as _ from 'lodash';

export interface State {

  items: Array<Array<models.SourcingRequest>>;
}

const initialState: State = {
  items: new Array<Array<models.SourcingRequest>>(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET: {
      return { ...initialState };
    }

    case actions.SEARCH_SUCCESS: {
      const items = _.cloneDeep(state.items);
      items[action.payload.index] = action.payload.data;
      return { items };
    }

    case actions.REMOVE_SEARCH_ITEM: {
      const items = _.cloneDeep(state.items);
      items.splice(action.payload,1);
      return { items }
    }

    default: {
      return state;
    }
  }
}

export const getItems = (state: State) => state.items;
