import { LegListRankType } from './leg-list-rank-type';
import { LegListDisplayType } from './leg-list-display-type';
import { LegListDepartureTimeType } from './leg-list-departure-time-type';
import { LegListTileVersionEnum } from './leg-list-tile-version-enum';

export class LegListSettings {
  tileVersion: LegListTileVersionEnum = LegListTileVersionEnum.V1;
  rankType: LegListRankType = LegListRankType.ProfitAndLoss;
  displayType: LegListDisplayType = LegListDisplayType.ByDate;
  departureTimeType: LegListDepartureTimeType = LegListDepartureTimeType.Local;
}
