import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'expire-countdown',
  templateUrl: './expire-countdown.component.html',
  styleUrls: ['./expire-countdown.component.scss']
})
export class ExpireCountdownComponent implements OnInit {
  @Input() expirationDate: Date;
  @Input() warningRangeInMinutes: number;
  @Output() expire: EventEmitter<void> = new EventEmitter<void>();

  timer$: Observable<number>;
  warningRangeInMs: number;

  ngOnInit(): void {
    this.warningRangeInMs = (this.warningRangeInMinutes || 0) * 60 * 1000;

    this.timer$ = timer(0, 1000).pipe(
        map(() => {
          const ms = this.getMsLeft(this.expirationDate);
          if(!ms) {
            this.expire.emit()
          }
          return ms;
        }),
        takeWhile((ms) => ms >= 0)
    );
  }

  private getMsLeft = (futureDate: Date): number => moment(futureDate).diff(new Date());
}
