import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[YM Country-List-Collection] Load';
export const LOAD_SUCCESS = '[YM Country-List-Collection] Load Success';
export const LOAD_FAIL = '[YM Country-List-Collection] Load Fail';

export class LoadCountryListCollectionAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessCountryListCollectionPayload {
  entities: Array<models.Country>;
}

export class LoadSuccessCountryListCollectionAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessCountryListCollectionPayload) {
  }
}

export class LoadFailCountryListCollectionAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions =
  | LoadCountryListCollectionAction
  | LoadSuccessCountryListCollectionAction
  | LoadFailCountryListCollectionAction;
