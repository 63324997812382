import { Action } from '@ngrx/store';

import * as models from '../../domain/models';

export const LOAD = '[YM Shuttle Offers Data] Load';
export const LOAD_SUCCESS = '[YM Shuttle Offers Data] Load Success';
export const LOAD_FAIL = '[YM Shuttle Offers Data] Load Fail';

export const LOAD_HISTORY = '[YM Shuttle Offers Data] Load History';
export const LOAD_HISTORY_SUCCESS = '[YM Shuttle Offers Data] Load History Success';
export const LOAD_HISTORY_FAIL = '[YM Shuttle Offers Data] Load History Fail';

export const UPDATE = '[YM Shuttle Offers Data] Update';
export const UPDATE_SUCCESS = '[YM Shuttle Offers Data] Update Success';
export const UPDATE_FAIL = '[YM Shuttle Offers Data] Update Fail';

export const RESET = '[YM Shuttle Offers Data] Reset';
export const SET_BULK_ITEMS = '[YM Shuttle Offers Data] Set Bulk Items';

export const UPDATE_BULK = '[YM Shuttle Offers Data] Update Bulk';
export const UPDATE_BULK_SUCCESS = '[YM Shuttle Offers Data] Update Bulk Success';
export const UPDATE_BULK_FAIL = '[YM Shuttle Offers Data] Update Bulk Fail';

export const CREATE_BULK = '[YM Shuttle Offers Data] Create Bulk';
export const CREATE_BULK_SUCCESS = '[YM Shuttle Offers Data] Create Bulk Success';
export const CREATE_BULK_FAIL = '[YM Shuttle Offers Data] Create Bulk Fail';

export class LoadPayload {
  criteria: models.CustomShuttleCriteria;
  fetch: boolean;
  detailsParams?: models.CustomShuttleDetailsParams;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  offers?: Array<models.CustomShuttleOffers>;
  detailsParams?: models.CustomShuttleDetailsParams;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class LoadHistoryPayload {
  id: number;
}

export class LoadHistoryAction implements Action {
  readonly type = LOAD_HISTORY;

  constructor(public payload: LoadHistoryPayload) {}
}

export class LoadHistorySuccessPayload {
  items: Array<models.CustomShuttleOfferHistory>;
}

export class LoadHistorySuccessAction implements Action {
  readonly type = LOAD_HISTORY_SUCCESS;

  constructor(public payload: LoadHistorySuccessPayload) {}
}

export class LoadHistoryFailAction implements Action {
  readonly type = LOAD_HISTORY_FAIL;
}

export class UpdatePayload {
  offers: Array<Partial<models.CustomShuttleOffers>>;
  isBulk = false;
}

export class UpdateAction implements Action {
  readonly type = UPDATE;

  constructor(public payload: UpdatePayload) {}
}

export class UpdateSuccessPayload {
  offers: Array<Partial<models.CustomShuttleOffers>>;
  isBulk = false;
}

export class UpdateSuccessAction implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: UpdatePayload) {}
}

export class UpdateFailAction implements Action {
  readonly type = UPDATE_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetBulkItems implements Action {
  readonly type = SET_BULK_ITEMS;

  constructor(public payload: string) {}
}

export class CreateBulkPayload {
  offers: Array<Partial<models.CustomShuttleOffers>>;
}

export class CreateBulkAction implements Action {
  readonly type = CREATE_BULK;

  constructor(public payload: CreateBulkPayload) {}
}

export class CreateBulkSuccessAction implements Action {
  readonly type = CREATE_BULK_SUCCESS;
}

export class CreateBulkFailAction implements Action {
  readonly type = CREATE_BULK_FAIL;
}

export class UpdateBulkPayload {
  offers: Array<Partial<models.CustomShuttleOffers>>;
}

export class UpdateBulkAction implements Action {
  readonly type = UPDATE_BULK;

  constructor(public payload: UpdateBulkPayload) {}
}

export class UpdateBulkSuccessAction implements Action {
  readonly type = UPDATE_BULK_SUCCESS;
}

export class UpdateBulkFailAction implements Action {
  readonly type = UPDATE_BULK_FAIL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadHistoryAction
  | LoadHistorySuccessAction
  | LoadHistoryFailAction
  | ResetAction
  | UpdateAction
  | UpdateSuccessAction
  | SetBulkItems
  | UpdateFailAction
  | CreateBulkAction
  | CreateBulkSuccessAction
  | CreateBulkFailAction
  | UpdateBulkAction
  | UpdateBulkSuccessAction
  | UpdateBulkFailAction;
