import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Integration Status Details] Load';
export const LOAD_SUCCESS = '[RM Integration Status Details] Load Success';
export const LOAD_FAIL = '[RM Integration Status Details] Load Fail';
export const RESET = '[RM Integration Status Details] Reset';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: string) {}
}

export class LoadSuccessActionPayload {
  constructor(public item: models.IntegrationStatus) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessActionPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | ResetAction;