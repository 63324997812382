import { PriceType } from './price-type';
import { PRICING_TYPES } from '../../constants';
import * as models from '../index';

export class Price {
  seatPrice: number = null;
  startSeat: number = null;
  endSeat: number = null;
  operatorPrice: number = null;
  active: boolean = null;
  updateReasonId: number = null;
  priceType: PriceType = null;
  expirationStartTime: Date = null;
  expirationEndTime: Date = null;
  minRemainingSeats: number = null;
  overridePricingType: PRICING_TYPES = PRICING_TYPES.FIXED;
  logs: Array<models.LegPriceAudit> = [];

  getPriceOverride(price: number): number {
    return this.overridePricingType === PRICING_TYPES.FIXED ? this.seatPrice : this.seatPrice + price;
  }
}
