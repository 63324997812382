import { FinalizedLeg } from '../../../../domain/models/finalized-leg';

export class FinalizedLegMapper {
  static fromJson(json: any): FinalizedLeg | null {
    if (!json) {
      return null;
    }

    const finalizedLeg = new FinalizedLeg();
    finalizedLeg.jsLegId = json.js_leg_id;
    finalizedLeg.flightRequestId = json.flight_request_id;
    finalizedLeg.fosLegStatus = json.fos_leg_status;
    finalizedLeg.arrivalAirportIcao = json.arr_icao;
    finalizedLeg.departureAirportIcao = json.dep_icao;
    finalizedLeg.arrivalDateLocal = json.arr_dt_local;
    finalizedLeg.departureDateLocal = json.dep_dt_local;
    finalizedLeg.blockTimeAct = json.block_time_act;
    finalizedLeg.flightStatusId = json.flight_status;
    finalizedLeg.paxCount = json.pax_count;
    finalizedLeg.tailNumber = json.tail_number;

    return finalizedLeg;
  }
}
