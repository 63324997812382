import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'msToDuration',
})
export class MsToDuration implements PipeTransform {
  transform(value: number, ...args): string {
    if (value == null) {
      return '';
    }

    const duration = moment.duration(value);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');
  
    if(days) {
     return `${days}d ${hours}:${minutes}:${seconds}`;
    }
    
    if (duration.hours()) {
      return `${hours}:${minutes}:${seconds}`;
    }
    
    return `${minutes}:${seconds}`;
  }
}
