import { User } from './user';
import * as moment from 'moment';
import { DepTimeChangeEnum, MemberStatusEnum } from './membership-pricing-comparison-offer';
import { PricingSupportTypeEnum } from './pricing-support-type-enum';

export class PriceCalculator {
  pax: number = null;
  actype: string;
  automation: boolean;
  price: number;
  serviceFee: number;
  nonMemberFee: number;
  segmentFee: number;
  subtotal: number;
  lastDayOfIATAFuel: number;
  reject: string;
  fetTax: TaxFee;
  log: CalculationLog = null;
  segments: Array<PriceCalculatorSegment>;
  selected: boolean = false;
  fuelCost: number;
  roundTripDiscount: number;
  dnq: boolean;
  taxes?: Array<PriceTax>;
  pricingSupportType?: keyof typeof PricingSupportTypeEnum = null;
  bestPrice?: boolean = false;
}

export class PriceCalculatorSegment {
  actype: string = null;
  adjustment: number = null;
  arrive: string = null;
  depart: string = null;
  eft: number = null;
  ferry?: boolean = false;
  ferryInfo: FerryInfo = null;
  firmFlexSurcharge: number = null;
  id: string = null;
  opportunityPax: number = null;
  opportunityPrice: number = null;
  pax: number = null;
  peakDays?: boolean = false;
  price: number = null;
  priceInfo: PriceCalculatorInfo = null;
  suppressTaxFee: boolean = null;
  taxFees: Array<TaxFee> = null;
  time: string = null;
}

export class PricingInfoSurcharge {
  code: string;
  name: string;
  value: number;
}

export class PriceCalculatorInfo {
  rzosurcharge: number;
  base: number;
  surcharges?: Array<PricingInfoSurcharge>;
  regularHourlyRate?: number;
  fuelSurcharge: number;
  noodleToggle: number;
  departAP: number;
  arriveAP: number;
  departState: number;
  arriveState: number;
  challenger300?: number;
  calendarMsrp: string;
  msrp: string;
  msrpValue: number;
  peakDayCharge: number;
  highDemandDayCharge: number;
  departureRZOSurcharge: number;
  arrivalRZOSurcharge: number;
  flightTime: number;
  internationalSurcharge: number;
  eventArrive: number;
  eventDepart: number;
  eventSurcharge: number;
  breakdowns: PriceCalculatorInfoBreakdowns;
  eventFee: number;
  otherLocationFee: number;

  get hasSurcharges(): boolean {
    return this.surcharges.length > 0;
  }

  get hasBreakdowns(): boolean {
    return this.breakdowns !== null;
  }
}

export class PriceCalculatorInfoBreakdowns {
  [key: string]: number;
}

export class FerryInfo {
  destinationEft: number;
  destinationRegularIcao: string;
  destinationRepoIcao: string;
  destinationRepoPrice: number;
  destinationHourlyRate: number;
  destinationBasePrice: number;
  destinationDiscount: number;
  originHourlyRate: number;
  originEft: number;
  originBasePrice: number;
  originRegularIcao: string;
  originRepoIcao: string;
  originRepoPrice: number;
  originDiscount: number;
}

export class PriceTax {
  amount: number;
  name: number;
}

export class TaxFee {
  amount: number;
  unitPercentage: number;
}

export class CalculationLog {
  logId: string;
  user: User;
  createdTime: Date = null;
  input: any;

  toString(): string {
    const hasTimezone = this.input && this.input.userTimeZoneId;
    const timeZone = hasTimezone ? ` (${this.input.userTimeZoneId})` : '';
    const dateTime = hasTimezone
      ? moment(this.createdTime + 'Z').tz(this.input.userTimeZoneId)
      : moment(this.createdTime);
    const createdTime = this.createdTime
      ? ` at ${dateTime.format('YYYY-MM-DD HH:mm')}${timeZone}`
      : '';
    const user = this.user ? `created by ${this.user.firstName} ${this.user.lastName}` : '';
    return `${user} ${createdTime}`;
  }
}

export class PriceCalculatorOfferRequest {
  legs: Array<PriceCalculatorOfferLegRequest>;
  actypes: Array<string>;
  depTimeChange: DepTimeChangeEnum = null;
  memberState: MemberStatusEnum;
  lastDayOfIATAFuel?: number;
  membershipType: string;
}

export class PriceCalculatorOfferLegRequest {
  origin: string = null;
  destination: string = null;
  depDateTime: string = null;
  sortOrder: number = null;
  opportunityPax: number = null;
  opportunityPrice: number = null;
  suppressTaxFee: boolean = false;
  ferry?: boolean;
  peakDays?: boolean;
}
