import {
  GetFinalizedLegIdsFromFlightRequestIdResponse,
  GetLegCostsByFlightRequestIdResponse,
  ILegCostsService,
  LegCostsFinalizedLegIdsResponse,
  LegCostsListResponse,
  LegCostsUpdateResponse,
} from '../../../domain/service-contracts/leg-costs';
import { Inject, Injectable } from '@angular/core';
import { IConfigService, SERVICE_TOKEN } from '../../../../../core/services';
import { LegCostsDto } from './dto/leg-costs-dto';
import { LegCost } from '../../../domain/models/leg-cost';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CostType } from '../../../domain/models/cost-type';
import { FinalizedLegIdDto } from './dto/finalized-leg-id-dto';

@Injectable()
export class LegCostsService implements ILegCostsService {
  private get API_PATH(): string {
    return this.configService.get('OrderServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(SERVICE_TOKEN) private configService: IConfigService
  ) {}

  getLegCostsByFlightRequestId(flightRequestId: number): Observable<LegCostsListResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/leg-costs/${flightRequestId}`;

    return this.http.get(requestUrl).pipe(
      map((serviceResponse: GetLegCostsByFlightRequestIdResponse) => {
        const response = new LegCostsListResponse();
        response.legCosts = [];
        response.message = serviceResponse.message;
        response.code = serviceResponse.code;

        if (!serviceResponse || !serviceResponse.data) {
          return response;
        }

        serviceResponse.data.forEach((legCostsDto: LegCostsDto) => {
          const legCost = new LegCost();

          legCost.id = legCostsDto.leg_costs_id;
          legCost.flightRequestId = legCostsDto.flight_request_id;
          legCost.legId = legCostsDto.leg_id;
          legCost.amount = Number(legCostsDto.amount);

          const costType = new CostType();
          costType.id = legCostsDto.cost_type_id;
          costType.type = legCostsDto.cost_type;
          costType.description = legCostsDto.cost_description;

          legCost.costType = costType;

          response.legCosts.push(legCost);
        });

        return response;
      })
    );
  }

  updateLegCost(legCost: LegCost): Observable<LegCostsUpdateResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/leg-costs/update/`;

    const legCostDto = new LegCostsDto();
    legCostDto.leg_costs_id = legCost.id;
    legCostDto.flight_request_id = legCost.flightRequestId;
    legCostDto.leg_id = legCost.legId;
    legCostDto.cost_type_id = legCost.costType.id;
    legCostDto.amount = Number(legCost.amount);

    return this.http.put<LegCostsUpdateResponse>(requestUrl, legCostDto);
  }

  getLegIdListByFlightRequestId(
    flightRequestId: number
  ): Observable<LegCostsFinalizedLegIdsResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/leg-costs/finalized-leg-ids/${flightRequestId}`;

    return this.http.get(requestUrl).pipe(
      map((serviceResponse: GetFinalizedLegIdsFromFlightRequestIdResponse) => {
        const response = new LegCostsFinalizedLegIdsResponse();
        response.finalizedLegIds = [];
        response.message = serviceResponse.message;
        response.code = serviceResponse.code;

        if (!serviceResponse || !serviceResponse.data) {
          return response;
        }

        serviceResponse.data.forEach((finalizedLegIdDto: FinalizedLegIdDto) => {
          response.finalizedLegIds.push(finalizedLegIdDto.js_leg_id);
        });

        return response;
      })
    );
  }
}
