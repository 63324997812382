import { SalesActionEnum } from './sales-action-enum';

export class SfdcOpportunityFlightPassData {
  sfdcOpportunityId: string;
  clientId: number;
  salesActionId: SalesActionEnum;
  productId: number;
  faceValue: number;
  qtyOfPasses: number;
  route: string;
  total: number;
  contractCommencementDate: string;
  warningMessage: string;
  errorMessage: string;
  warningPopupAlert: boolean;
  errorPopupAlert: boolean;
}

export class SfdcOpportunityFlightPassRoute{
  from: string;
  to: string;
}
