import * as models from '../../modules/+sales/domain/models';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IAirportService {
  getAirportByCode(request: GetAirportByCodeRequest): Observable<GetAirportByCodeResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Core.IAirportService');

export class GetAirportByCodeRequest {
  code: string;
}

export class GetAirportByCodeResponse {
  valid: boolean;
}

export class SearchAirportRequest {
  skip = 0;
  limit = 7;
  searchTerm: string = null;
}
