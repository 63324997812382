import { Action } from '@ngrx/store';

export const GET_OPERATOR_CANCELLATION_TERMS = '[Explorer.Operator] Get Operator Cancellation Terms';
export const GET_OPERATOR_CANCELLATION_TERMS_SUCCESS = '[Explorer.Operator] Get Operator Cancellation Terms Success';
export const GET_OPERATOR_CANCELLATION_TERMS_FAILED = '[Explorer.Operator] Get Operator Cancellation Terms Failed';

export class GetOperatorCancellationTermsPayload {
  operatorUuid: string;
}

export class GetOperatorCancellationTermsAction implements Action {
    readonly type = GET_OPERATOR_CANCELLATION_TERMS;

    constructor(public payload: GetOperatorCancellationTermsPayload) {}
}

export class GetOperatorCancellationTermsSuccessPayload {
  constructor(public cancellationTerms: string) {}
}

export class GetOperatorCancellationTermsSuccessAction implements Action {
  readonly type = GET_OPERATOR_CANCELLATION_TERMS_SUCCESS;

  constructor(public payload: GetOperatorCancellationTermsSuccessPayload) {}
}

export class GetOperatorCancellationTermsFailedAction implements Action {
  readonly type = GET_OPERATOR_CANCELLATION_TERMS_FAILED;
}

export type Actions = 
  | GetOperatorCancellationTermsAction
  | GetOperatorCancellationTermsSuccessAction
  | GetOperatorCancellationTermsFailedAction;
