import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({ providedIn: 'root' })
export class BrowserCheckService {
  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  public checkBrowser(): void {
    const agent = window.navigator.userAgent;
    let browser = null;

    switch (true) {
      case agent.indexOf('Opera') !== -1 || agent.indexOf('OPR') !== -1:
        browser = 'Opera';
        break;
      case agent.indexOf('Edg') != -1:
        browser = 'Edge';
        break;
      case agent.indexOf('Safari') !== -1 &&
        agent.indexOf('Chrome') === -1 &&
        agent.indexOf('Chromium') === -1:
        browser = 'Safari';
        break;
      case agent.indexOf('Firefox') !== -1:
        browser = 'Firefox';
        break;
      case agent.indexOf('MSIE') !== -1 || agent.indexOf('Trident') !== -1:
        browser = 'IE';
        break;
      case agent.indexOf('Chrome') > -1 &&
        !!(<any>window).chrome &&
        agent.indexOf('Chromium') === -1:
        browser = 'Chrome';
        break;
    }

    if (browser !== 'Chrome') {
      const warningText = `Chrome is the preferred web browser for JetStudio. `;
      const browserText = `You are using ${browser}`;
      this.notificationService.show({
        content: browser ? warningText + browserText : warningText,
        cssClass: 'check-browser-notification',
        animation: { type: 'slide', duration: 700 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'warning', icon: true },
        closable: true,
      });
    }
  }
}
