import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromClientRequests from './client-requests.reducer';
import * as fromOrderNew from './order-new.reducer';
import * as fromProductCollection from './product-collection.reducer';
import * as fromOrder from './order.reducer';
import * as fromOrderCollection from './order-collection.reducer';
import * as fromTransitionCollection from './transition-collection.reducer';
import * as fromPriceComponentToSalesActionCollection from './price-component-to-sales-action-collection.reducer';
import * as fromVendorCollection from './vendor-collection.reducer';
import * as fromSecurity from './security.reducer';
import * as fromConsoleLoginCheck from './console-login-check.reducer';
import * as fromAircraftCategoriesCollection from './aircraft-categories-collection.reducer';
import * as fromAdditionalFeaturesCollection from './additional-features.reducer';
import * as fromPricingComparisonPriceCollection from './pricing-comparison-price.reducer';
import * as fromPricingCalculator from './price-calculator.reducer';
import * as fromPeakDays from './peak-days.reducer';
import * as fromPricingMeta from './pricing-meta.reducer';
import * as fromError from './error.reducer';
import * as fromAmendCharges from './amend-charges.reducer';

export interface State {
  [fromAdditionalFeaturesCollection.additionalFeaturesCollectionFeatureKey]: fromAdditionalFeaturesCollection.State;
  [fromAircraftCategoriesCollection.aircraftCategoriesCollectionFeatureKey]: fromAircraftCategoriesCollection.State;
  [fromPricingComparisonPriceCollection.pricingComparisonPriceCollectionFeatureKey]: fromPricingComparisonPriceCollection.State;
  [fromPricingCalculator.pricingCalculatorItemFeatureKey]: fromPricingCalculator.State;
  [fromPeakDays.peakDaysFeatureKey]: fromPeakDays.State;
  [fromClientRequests.clientRequestsFeatureKey]: fromClientRequests.State;
  [fromOrderNew.orderNewFeatureKey]: fromOrderNew.State;
  [fromProductCollection.productCollectionFeatureKey]: fromProductCollection.State;
  [fromOrder.orderFeatureKey]: fromOrder.State;
  [fromOrderCollection.orderCollectionFeatureKey]: fromOrderCollection.State;
  [fromTransitionCollection.transitionCollectionFeatureKey]: fromTransitionCollection.State;
  [fromPriceComponentToSalesActionCollection.priceComponentToSalesActionCollectionFeatureKey]: fromPriceComponentToSalesActionCollection.State;
  [fromConsoleLoginCheck.consoleLoginCheckFeatureKey]: fromConsoleLoginCheck.State;
  [fromVendorCollection.vendorCollectionFeatureKey]: fromVendorCollection.State;
  [fromSecurity.securityFeatureKey]: fromSecurity.State;
  [fromError.errorFeatureKey]: fromError.State;
  [fromAmendCharges.amendChargesFeatureKey]: fromAmendCharges.State;
  [fromPricingMeta.pricingMetaFeatureKey]: fromPricingMeta.State;
}

export const featureName = 'sales';

export const reducers = combineReducers<State, Action>({
  [fromAdditionalFeaturesCollection.additionalFeaturesCollectionFeatureKey]:
    fromAdditionalFeaturesCollection.reducer,
  [fromAircraftCategoriesCollection.aircraftCategoriesCollectionFeatureKey]:
    fromAircraftCategoriesCollection.reducer,
  [fromPricingComparisonPriceCollection.pricingComparisonPriceCollectionFeatureKey]:
    fromPricingComparisonPriceCollection.reducer,
  [fromPricingCalculator.pricingCalculatorItemFeatureKey]: fromPricingCalculator.reducer,
  [fromPeakDays.peakDaysFeatureKey]: fromPeakDays.reducer,
  [fromClientRequests.clientRequestsFeatureKey]: fromClientRequests.reducer,
  [fromOrderNew.orderNewFeatureKey]: fromOrderNew.reducer,
  [fromProductCollection.productCollectionFeatureKey]: fromProductCollection.reducer,
  [fromOrder.orderFeatureKey]: fromOrder.reducer,
  [fromOrderCollection.orderCollectionFeatureKey]: fromOrderCollection.reducer,
  [fromTransitionCollection.transitionCollectionFeatureKey]: fromTransitionCollection.reducer,
  [fromPriceComponentToSalesActionCollection.priceComponentToSalesActionCollectionFeatureKey]:
    fromPriceComponentToSalesActionCollection.reducer,
  [fromConsoleLoginCheck.consoleLoginCheckFeatureKey]: fromConsoleLoginCheck.reducer,
  [fromVendorCollection.vendorCollectionFeatureKey]: fromVendorCollection.reducer,
  [fromSecurity.securityFeatureKey]: fromSecurity.reducer,
  [fromError.errorFeatureKey]: fromError.reducer,
  [fromAmendCharges.amendChargesFeatureKey]: fromAmendCharges.reducer,
  [fromPricingMeta.pricingMetaFeatureKey]: fromPricingMeta.reducer,
});

export const getSalesState = createFeatureSelector<State>(featureName);
