import { AppClient } from './app-client';
import { SalesForceClient } from './sales-force-client';
import { OrganizationEnum } from './organization-enum';

export class UniversalClient {
  email: string = null;
  name: string = null;
  universalPm: string = null;
  activationState: boolean = null;
  gvPersonId: string = null;
  recordType: string = null;

  AppClient: AppClient = null;
  SalesForceClient: SalesForceClient = null;
  organizationId: OrganizationEnum = null;
  readonly = false;
}
