export class AircraftWyvern {
  tailNumber: string;
  serialNumber: string;
  yom: Date;
  yor: Date;
  maxPax: number;
  homeBase: string;
  maxRange: number;

  getTailNumber(): string {
    return this.tailNumber;
  }

  setTailNumber(value: string): AircraftWyvern {
    this.tailNumber = value;

    return this;
  }

  getSerialNumber(): string {
    return this.serialNumber;
  }

  setSerialNumber(value: string): AircraftWyvern {
    this.serialNumber = value;

    return this;
  }

  getYom(): Date {
    return this.yom;
  }

  setYom(yom: Date): AircraftWyvern {
    this.yom = yom;

    return this;
  }

  getYor(): Date {
    return this.yor;
  }

  setYor(yor: Date): AircraftWyvern {
    this.yor = yor;
    
    return this;
  }

  getMaxPax(): number {
    return this.maxPax;
  }

  setMaxPax(maxPax: number): AircraftWyvern {
    this.maxPax = maxPax;
    return this;
  }

  getHomeBase(): string  {
    return this.homeBase;
  }

  setHomeBase(homeBase: string): AircraftWyvern  {
    this.homeBase = homeBase;
    
    return this;
  }

  getMaxRange(): number  {
    return this.maxRange;
  }

  setMaxRange(maxRange: number): AircraftWyvern  {
    this.maxRange = maxRange;
    
    return this;
  }
}
