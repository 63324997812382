import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import * as coreTypes from '../../../../../core/types';
import * as contracts from '../../../domain/service-contracts/client';
import * as models from '../../../domain/models';
import * as mappers from '../mappers';

import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientService implements contracts.IClientService {
  private get API_PATH(): string {
    return this.configService.get('ClientServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService,
    private datePipe: DatePipe
  ) {}

  loadSfdcOpportunity(
    request: contracts.LoadSfdcOpportunityRequest
  ): Observable<contracts.LoadSfdcOpportunityResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/sfdc-opportunity-data/${request.id}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.LoadSfdcOpportunityResponse();
        response.entity =
          mappers.SfdcOpportunityDataMapperService.parseSfdcOpportunityDataInternal(svcJsonResp);
        return response;
      })
    );
  }

  loadSfdcOpportunityCancel(
    request: contracts.LoadSfdcOpportunityRequest
  ): Observable<contracts.LoadSfdcOpportunityCancelResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/sfdc-opportunity-data/cancel/${request.id}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.LoadSfdcOpportunityCancelResponse();
        response.entity =
          mappers.SfdcOpportunityDataMapperService.parseSfdcOpportunityCancelDataInternal(
            svcJsonResp
          );
        return response;
      })
    );
  }

  loadSimplifiedMembership(
    request: number
  ): Observable<contracts.LoadSimplifiedMembershipResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/${request}/simplified-membership`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.LoadSimplifiedMembershipResponse();
        response.simplifiedMembership = svcJsonResp.data.simplifiedMembership;
        return response;
      })
    );
  }

  loadSfdcOpportunityFlightPass(
    request: contracts.loadSfdcOpportunityFlightPassRequest
  ): Observable<contracts.loadSfdcOpportunityFlightPassResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/sfdc-opportunity-data/flight-pass/${request.id}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.loadSfdcOpportunityFlightPassResponse();
        response.entity =
          mappers.ClientMapperService.parseSfdcOpportunityFlightPassDataInternal(svcJsonResp);
        return response;
      })
    );
  }

  loadSfdcOpportunityCreditMemo(
    request: contracts.loadSfdcOpportunityCreditMemoRequest
  ): Observable<contracts.loadSfdcOpportunityCreditMemoResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/sfdc-opportunity-data/credit-memo/${request.id}`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.loadSfdcOpportunityCreditMemoResponse();
        response.entity =
          mappers.ClientMapperService.parseSfdcOpportunityCreditMemoDataInternal(svcJsonResp);
        return response;
      })
    );
  }

  searchClients(
    request: contracts.SearchClientsRequest
  ): Observable<contracts.SearchClientsResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/`;

    let params = new HttpParams();

    if (request.clientId) {
      params = params.append('clientId', request.clientId.toString());
    }

    if (request.name && request.name.length > 0) {
      params = params.append('legalName', request.name);
    }

    if (request.dateOfBirth) {
      params = params.append('dob', this.datePipe.transform(request.dateOfBirth, 'y-MM-dd'));
    }

    if (request.email && request.email.length > 0) {
      params = params.append('email', request.email);
    }

    if (request.phone) {
      params = params.append('phone', request.phone);
    }

    params = params.append('offset', request.offset.toString());
    params = params.append('limit', request.limit.toString());

    if (params.keys().length === 2) {
      return of(new contracts.SearchClientsResponse());
    }

    return this.mapClientSearch(requestUrl, { params });
  }

  searchClientsByName(request: contracts.SearchClientsByNameRequest) {
    const requestUrl = `${this.API_PATH}/v1.0/clients/simple-search-by-name`;

    let params = new HttpParams();

    if (request.clientName) {
      params = params.set('clientName', request.clientName);
    }

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.SearchClientsResponse();

        response.entities = [...svcJsonResp.data];

        return response;
      })
    );
  }

  searchAccountsByName(
    request: contracts.SearchAccountsByNameRequest
  ): Observable<contracts.SearchAccountsByNameResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/salesforce-account-by-name/${request.accountName}`;

    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.SearchAccountsByNameResponse();

        response.entities = [...svcJsonResp.data];

        return response;
      })
    );
  }

  mapClientSearch(request, requestOptions): Observable<contracts.SearchClientsResponse> {
    return this.http.get(request, requestOptions).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.SearchClientsResponse();
        response.entities = svcJsonResp.data.items.map((item) =>
          mappers.ClientMapperService.parseClientInternal(item)
        );

        response.count = svcJsonResp.data.totalItems;
        return response;
      })
    );
  }

  getClient(request: contracts.GetClientRequest): Observable<contracts.GetClientResponse> {
    return forkJoin([
      this.getClientInternal(request.clientId),
      this.getClientOrdersInternal(request.clientId),
    ]).pipe(
      map(([client, orders]) => {
        client.orders = orders;

        const response = new contracts.GetClientResponse();
        response.entity = client;

        return response;
      })
    );
  }

  getClientWithoutOrder(
    request: contracts.GetClientWithoutOrderRequest
  ): Observable<contracts.GetClientWithoutOrderResponse> {
    return this.getClientInternal(request.clientId).pipe(
      map((client: models.Client) => {
        const response = new contracts.GetClientWithoutOrderResponse();
        response.entity = client;

        return response;
      })
    );
  }

  getFlightRequests(
    request: contracts.GetFlightRequestsRequest
  ): Observable<contracts.GetFlightRequestsResponse> {
    const clientRequestUrl = `${this.API_PATH}/v1.0/clients/${request.clientId}/leg-requests`;

    return this.http.get(clientRequestUrl).pipe(
      map((svcResp: any) => {
        const items = svcResp.data;

        const response = new contracts.GetFlightRequestsResponse();

        response.entities = items.map((e) =>
          mappers.CharterRequestMapperService.parseClientRequest(e)
        );

        return response;
      })
    );
  }

  getConciergeAncillaryData(
    request: contracts.GetConciergeAncillaryDataRequest
  ): Observable<contracts.GetConciergeAncillaryDataResponse> {
    const clientRequestUrl = `${this.API_PATH}/v1.0/clients/${request.clientId}/ancillary-data`;

    return this.http.get(clientRequestUrl).pipe(
      map((svcResp: any) => {
        const response = new contracts.GetConciergeAncillaryDataResponse();
        response.entity = mappers.ClientMapperService.parseConciergeFlightAncillaryData(
          svcResp.data
        );
        return response;
      })
    );
  }

  getCharterRequests(
    request: contracts.GetCharterRequestsRequest
  ): Observable<contracts.GetCharterRequestsResponse> {
    const clientRequestUrl = `${this.API_PATH}/v1.0/clients/${request.clientId}/charter-requests`;

    return this.http.get(clientRequestUrl).pipe(
      map((svcResp: any) => {
        const items = svcResp.data;

        const response = new contracts.GetCharterRequestsResponse();

        response.entities = items.map((rq) =>
          mappers.CharterRequestMapperService.mapCharterRequestInternal(rq)
        );

        return response;
      })
    );
  }

  createCharterInvoice(
    request: contracts.CreateCharterInvoiceRequest
  ): Observable<contracts.CreateCharterInvoiceResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/clients/create-charter-invoice`;

    const body = JSON.stringify(request);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(requestUrl, body, { headers }).pipe(
      map(() => {
        return new contracts.CreateCharterInvoiceResponse();
      })
    );
  }

  removeCharterInvoice(
    request: contracts.RemoveCharterInvoiceRequest
  ): Observable<contracts.RemoveCharterInvoiceResponse> {
    const removeCharterInvoiceUrl = `${this.API_PATH}/v1.0/clients/remove-charter-invoice`;
    let params = new HttpParams();
    if (request.formUrlKey) {
      params = params.append('formUrlKey', request.formUrlKey);
    }

    return this.http
      .put(removeCharterInvoiceUrl, '', { params })
      .pipe(map((svcResp: any) => svcResp.data));
  }

  private getClientInternal(clientId: number): Observable<models.Client> {
    const clientRequestUrl = `${this.API_PATH}/v1.0/clients/${clientId}`;

    return this.http
      .get(clientRequestUrl)
      .pipe(map((svcResp: any) => mappers.ClientMapperService.parseClientInternal(svcResp.data)));
  }

  private getClientOrdersInternal(clientId: number): Observable<Array<models.Order>> {
    const clientOrdersRequestUrl = `${this.API_PATH}/v1.0/clients/${clientId}/orders`;

    return this.http
      .get(clientOrdersRequestUrl)
      .pipe(
        map((svcJsonResp: any) =>
          svcJsonResp.data.items.map((e) => mappers.OrderMapperService.parseOrderInternal(e))
        )
      );
  }
}
