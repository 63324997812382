import { Operator } from "./operator";
import { Aircraft } from "./aircraft";
import { EfficiencyCost } from './efficiency-costs';

export class CamberOption {
    perOccupiedHourCost: number;
    hoursBillableTotal: number;
    hoursUnoccupiedTotal: number;
    estimatedCost: number;
    legs: Array<CamberLeg>;

    getEstimatedCost(): number {
        return this.estimatedCost;
    }

    setEstimatedCost(estimatedCost: number): CamberOption {
        this.estimatedCost = estimatedCost;
        return this;
    }

    getLegs(): Array<CamberLeg> {
        return this.legs;
    }

    setLegs(quotes: Array<CamberLeg>): CamberOption {
        this.legs = quotes;

        return this;
    }
}

export class CamberLeg {
    aircraft: Aircraft;
    operator: Operator;
    rationale: Array<string>;
    efficiencyCost: EfficiencyCost;

    getAircraft(): Aircraft {
        return this.aircraft;
    }

    setAircraft(aircraft: Aircraft): CamberLeg {
        this.aircraft = aircraft;

        return this;
    }

    getOperator(): Operator {
        return this.operator;
    }

    setOperator(operator: Operator): CamberLeg {
        this.operator = operator;

        return this;
    }

    getRationale(): Array<string> {
        return this.rationale;
    }

    setRationale(rationale: Array<string>): CamberLeg {
        this.rationale = rationale;

        return this;
    }

    getEfficiencyCost(): EfficiencyCost {
        return this.efficiencyCost;
    }

    setEfficiencyCost(efficiencyCosts: EfficiencyCost): CamberLeg {
        this.efficiencyCost = efficiencyCosts;

        return this;
    }
}