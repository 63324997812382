import { Product } from './product';
import { SalesActionEnum } from './sales-action-enum';

export class MembershipRequest {
  orderId: number = null;
  orderItemId: number = null;
  salesAction: SalesActionEnum = null;
  createTime: Date = null;
  expirationTime: Date = null;
  product: Product = null;
  totalPrice: number = null;
}
