export class FlightsKpi {
  operatorId: string;

  flightsOnTime: number;
  flightsDelayed: number;
  flightsMechanicalBreakdown: number;
  flightsRecoveredInFleet: number;
  flightsRecoveredNewOperator: number;
  flightsTotal: number;
}
