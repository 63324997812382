export class LegListFilterItem {
  applied = false;
  items: Array<any>;
  item: any;

  constructor(item: any, isArray = false) {
    if (isArray) {
      this.items = item;
    } else {
      this.item = item;
    }
  }
}
