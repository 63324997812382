import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Airport Detail] Load';
export const LOAD_SUCCESS = '[RM Airport Detail] Load Success';
export const LOAD_FAIL = '[RM Airport Detail] Load Fail';
export const RESET = '[RM Airport Detail] Reset';

export class LoadPayload {
  constructor(public code: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Airport) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | ResetAction;