import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'jsonParse' })
export class JsonParsePipe implements PipeTransform {
	transform(value: any): string {
		try {
      const obj = JSON.parse(value);
      return JSON.stringify(obj, null, 2);
    } catch (e) {
      return value;
    }
	}
}