import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[Operator Documents] Load';
export const LOAD_SUCCESS = '[Operator Documents] Load Success';
export const LOAD_FAIL = '[Operator Documents] Load Fail';
export const REMOVE = '[Operator Documents] Remove Document';
export const REMOVE_SUCCESS = '[Operator Documents] Remove Document Success';
export const REMOVE_FAIL = '[Operator Documents] Remove Document Fail';

export class LoadPayload {
  constructor(public operatorId: string) {}
}

export class RemovePayload {
  constructor(public operatorId: string, public operatorDocumentId: string) {}
}

export class RemoveAction implements Action {
  readonly type = REMOVE;

  constructor(public payload: RemovePayload) {}
}

export class RemoveSuccessPayload {
  constructor(public result: any) {}
}

export class RemoveSuccessAction implements Action {
  readonly type = REMOVE_SUCCESS;

  constructor(public payload: RemoveSuccessPayload) {}
}

export class RemoveFailAction implements Action {
  readonly type = REMOVE_FAIL;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<models.OperatorDocument>) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | RemoveAction
  | RemoveSuccessAction
  | RemoveFailAction;
