import { Injectable } from '@angular/core';
import * as airportContracts from './contracts';
import * as models from '../../models';
import { Observable, of } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { catchError, map } from 'rxjs/operators';
import { GetAirportQuery } from '../../../shared/graphql-types';

@Injectable()
export class AirportService implements airportContracts.IAirportService {

  constructor(private apollo: Apollo) {}

  getAirportList(request: string): Observable<Array<models.Airport>> {
    const search = request || '';

    const filter = {
      or: [
        {
          code: {contains: search}
        },
        {
          name: {contains: search}
        },
        {
          icao: {contains: search},
        },
        {
          iata: {contains: search},
        },
        {
          address: {city: {contains: search}},
        }
      ],
      active: {eq: true}
    };
    return this.apollo.query<GetAirportQuery>({
      query: gql(`
      query getAirport($filter: AirportFilterInput) {
        listAirports(filter: $filter, pagination: {skip: 0, limit: 20}) {
          airports {
            code
            name
            address {
             city
            }
          }
        }
      }`),
      variables: {
        filter
      }
    })
      .pipe(
        map(serviceResponse => {
          const items = serviceResponse.data.listAirports.airports;
          return items ? items.map(item => {
            const airport = new models.Airport();
            airport.code = item.code;
            airport.name = item.name;
            airport.city = item.address?.city;
            return airport;
          }) : [];
        }),
        catchError((error: Response) => {
          return of([]);
        })
      );
  }
}
