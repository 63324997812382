import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as sharedGuards from './shared/guards';
import * as coreGuards from './core/guards';
import * as coreResolvers from './core/resolver';
import * as coreContainers from './core/containers';
import * as helpers from './core/helpers';

// ToDo Remove after implementing auth0 authorization in production
const appRoutes: Routes = [
  {
    path: '',
    canActivate: [sharedGuards.DebugGuard, coreGuards.AuthorizationGuard],
    children: [
      {
        path: 'clients', loadChildren: () => import('./modules/+client/client.module').then(m => m.ClientModule),
        canActivate: [coreGuards.PermissionGuard],
        data: { viewPermission: 'clientsModule.overall' },
      },
      {
        path: 'explorer', loadChildren: () => import('./modules/+explorer/explorer.module').then(m => m.ExplorerModule),
        canActivate: [coreGuards.PermissionGuard],
        data: { viewPermission: 'explorerModule.search'}
      },
      {
        path: 'charters',
        loadChildren: () => import('./modules/+charter/charter.module').then(m => m.CharterModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'charterSalesModule.overall'
        }
      },
      {
        path: 'marketplace', loadChildren: () => import('./modules/+marketplace/marketplace.module').then(m => m.MarketplaceModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'marketplaceModule.overall'
        }
      },
      {
        path: 'resource-mgmt', loadChildren: () => import('./modules/+resource-mgmt/resource-mgmt.module').then(m => m.ResourceMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'resourceModule.overall'
        }
      },
      {
        path: 'sales', loadChildren: () => import('./modules/+sales/sales.module').then(m => m.SalesModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'salesModule.overall'
        }
      },
      {
        path: 'sourcing', loadChildren: () => import('./modules/+sourcing/sourcing.module').then(m => m.SourcingModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'sourcingModule.overall'
        }
      },
      {
        path: 'task-mgmt', loadChildren: () => import('./modules/+task-mgmt/task-mgmt.module').then(m => m.TaskMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'taskMgmtModule.overall'
        }
      },
      {
        path: 'yield-mgmt', loadChildren: () => import('./modules/+yield-mgmt/yield-management.module').then(m => m.YieldManagementModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'yieldMgmtModule.overall'
        }
      },
      {
        path: 'user-mgmt', loadChildren: () => import('./modules/+user-mgmt/user-mgmt.module').then(m => m.UserMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'userMgmtModule.overall'
        }
      },
      { path: 'version-history', component: coreContainers.VersionHistoryPageComponent },
      {
        path: '',
        component: coreContainers.LandingComponent,
        resolve: [coreResolvers.LandingResolver],
        data: {
          viewPermission: 'clientsModule.overall',
          redirect: 'clients'
        }
      },
      { path: 'login', component: coreContainers.VersionHistoryPageComponent },

      // { path: '', redirectTo: '/clients', pathMatch: 'full' }
    ]
  },
  { path: 'access-denied', component: coreContainers.AccessDeniedPageComponent }
];

const auth0appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      sharedGuards.DebugGuard,
      coreGuards.AuthorizationUserGuard,
      coreGuards.CurrentUserGuard,
    ],
    resolve: [coreResolvers.ConfigServiceInitializerResolver],
    children: [
      {
        path: 'clients', loadChildren: () => import('./modules/+client/client.module').then(m => m.ClientModule),
        canActivate: [coreGuards.PermissionGuard],
        data: { viewPermission: 'clientsModule.overall' },
      },
      {
        path: 'explorer', loadChildren: () => import('./modules/+explorer/explorer.module').then(m => m.ExplorerModule),
        canActivate: [coreGuards.PermissionGuard],
        data: { viewPermission: 'explorerModule.search'}
      },
      {
        path: 'charters',
        loadChildren: () => import('./modules/+charter/charter.module').then(m => m.CharterModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'charterSalesModule.overall'
        }
      },
      {
        path: 'marketplace', loadChildren: () => import('./modules/+marketplace/marketplace.module').then(m => m.MarketplaceModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'marketplaceModule.overall'
        }
      },
      {
        path: 'resource-mgmt', loadChildren: () => import('./modules/+resource-mgmt/resource-mgmt.module').then(m => m.ResourceMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'resourceModule.overall'
        }
      },
      {
        path: 'sales', loadChildren: () => import('./modules/+sales/sales.module').then(m => m.SalesModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'salesModule.overall'
        }
      },
      {
        path: 'sourcing', loadChildren: () => import('./modules/+sourcing/sourcing.module').then(m => m.SourcingModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'sourcingModule.overall'
        }
      },
      {
        path: 'task-mgmt', loadChildren: () => import('./modules/+task-mgmt/task-mgmt.module').then(m => m.TaskMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'taskMgmtModule.overall'
        }
      },
      {
        path: 'yield-mgmt', loadChildren: () => import('./modules/+yield-mgmt/yield-management.module').then(m => m.YieldManagementModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'yieldMgmtModule.overall'
        }
      },
      {
        path: 'user-mgmt', loadChildren: () => import('./modules/+user-mgmt/user-mgmt.module').then(m => m.UserMgmtModule),
        canActivate: [coreGuards.PermissionGuard],
        data: {
          viewPermission: 'userMgmtModule.overall'
        }
      },
      { path: 'version-history', component: coreContainers.VersionHistoryPageComponent },
      {
        path: '',
        component: coreContainers.LandingComponent,
        resolve: [coreResolvers.LandingResolver],
        data: {
          viewPermission: 'clientsModule.overall',
          redirect: 'clients'
        }
      },
      // { path: 'login', component: coreContainers.VersionHistoryPageComponent },

      // { path: '', redirectTo: '/clients', pathMatch: 'full' }
    ],
  },
  { path: 'access-denied', component: coreContainers.AccessDeniedPageComponent },
  {
    path: '**', redirectTo: 'clients'
  }
];

@NgModule({
  imports: [
    // ToDo Update after implementing auth0 authorization in production
    RouterModule.forRoot(helpers.isLegacyAuth() ? appRoutes : auth0appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
