import { MembershipPlanEnum } from "./membership-plan-enum";

export class MembershipStatus {
  clientId: number;
  membershipType: number;
  membershipProgramName: MembershipPlanEnum;
  clientMembershipStatus: boolean = false;
  membershipDisplayName: string;

  getClientId(): number {
    return this.clientId;
  }

  setClientId(clientId: number): MembershipStatus {
    this.clientId = clientId;

    return this;
  }

  getMembershipType(): number {
    return this.membershipType;
  }

  setMembershipType(membershipType: number): MembershipStatus {
    this.membershipType = membershipType;

    return this;
  }

  getMembershipProgramName(): number {
    return this.membershipProgramName;
  }

  setMembershipProgramName(membershipProgramName: number): MembershipStatus {
    this.membershipProgramName = membershipProgramName;

    return this;
  }

  getClientMembershipStatus(): boolean {
    return this.clientMembershipStatus;
  }

  setClientMembershipStatus(clientMembershipStatus: boolean): MembershipStatus {
    this.clientMembershipStatus = clientMembershipStatus;

    return this;
  }

  getMembershipDisplayName(): string {
    return this.membershipDisplayName;
  }

  setMembershipDisplayName(membershipDisplayName: string): MembershipStatus {
    this.membershipDisplayName = membershipDisplayName;

    return this;
  }
}
