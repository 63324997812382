import * as _ from 'lodash';
import * as models from '../../domain/models';

export class AdditionalFeaturesMapper {
  static parseAdditionalFeature(item: any): models.AdditionalFeature {
    const f = new models.AdditionalFeature();
    f.id = _.get(item, 'id', null);
    f.name = _.get(item, 'name', null);
    f.notes = _.get(item, 'notes', null);
    return f;
  }
}
