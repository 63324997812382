import { LegRequest } from './leg-request';
import { AircraftCategory } from './aircraft-category';
import { FlightRequestStatus } from './flight-request-status-enum';
import { User } from './user';
import { OperatorPurchaseOffer } from './operator-purchase-offer';
import { RequestStatusEnum } from './request-status-enum';
import { BidHistoryItem } from './bid-history-item';
import { TravelReason } from './travel-reason';
import { SpecialRequirement } from './special-requirement';
import { FlightRequestSourceEnum } from './flight-request-source.enum';

export class FlightRequest {
  flightRequestId: number;
  aircraftCategories: Array<AircraftCategory>;
  createdDate: Date;
  legs: Array<LegRequest>;
  clientId: number;
  clientName: string;
  specialRequest: string;
  showXODedicatedFleet: boolean;
  sendToXOMarketplace: boolean;
  allowFirmDeparture: boolean;
  offersRequireStaffApproval: boolean;
  sourcingRequestId: string;
  sourcingRequestStatus: RequestStatusEnum;
  status?: FlightRequestStatus;
  createdUser?: User;
  salesAdvisorName: string;
  acceptedPurchaseOffer: OperatorPurchaseOffer;
  opportunityId?: string;
  bidHistory: Array<BidHistoryItem>;
  elacProForma: boolean;
  petOnBoard: boolean;
  petNote: string;
  sfOpportunityId: string;
  travelReason: TravelReason;
  specialRequirements: Array<SpecialRequirement>;
  source: FlightRequestSourceEnum;
  childFlightRequests: Array<FlightRequest>;

  getFlightRequestId(): number {
    return this.flightRequestId;
  }

  setFlightRequestId(flightRequestId: number): FlightRequest {
    this.flightRequestId = flightRequestId;
    return this;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  setAircraftCategories(aircraftCategories: Array<AircraftCategory>): FlightRequest {
    this.aircraftCategories = aircraftCategories;

    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): FlightRequest {
    this.legs = legs;
    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): FlightRequest {
    this.createdDate = createdDate;
    return this;
  }

  getClientId(): number {
    return this.clientId;
  }

  setClientId(clientId: number): FlightRequest {
    this.clientId = clientId;
    return this;
  }

  getClientName(): string {
    return this.clientName;
  }

  setClientName(clientName: string): FlightRequest {
    this.clientName = clientName;
    return this;
  }

  getSpecialRequest(): string {
    return this.specialRequest;
  }

  setSpecialRequest(request: string): FlightRequest {
    this.specialRequest = request;
    return this;
  }

  getAircraftCategoryNames(): string {
    if (this.aircraftCategories && this.aircraftCategories.length) {
      return this.aircraftCategories.map((category) => category.name).join(', ');
    } else {
      return null;
    }
  }

  setShowXODedicatedFleet(value: boolean): FlightRequest {
    this.showXODedicatedFleet = value;

    return this;
  }

  getShowXODedicatedFleet(): boolean {
    return this.showXODedicatedFleet;
  }

  setSendToXOMarketplace(value: boolean): FlightRequest {
    this.sendToXOMarketplace = value;

    return this;
  }

  getSendToXOMarketplace(): boolean {
    return this.sendToXOMarketplace;
  }

  setAllowFirmDeparture(value: boolean): FlightRequest {
    this.allowFirmDeparture = value;

    return this;
  }

  getAllowFirmDeparture(): boolean {
    return this.allowFirmDeparture;
  }

  setOffersRequireStaffApproval(value: boolean): FlightRequest {
    this.offersRequireStaffApproval = value;

    return this;
  }

  getOffersRequireStaffApproval(): boolean {
    return this.offersRequireStaffApproval;
  }

  getSourcingRequestId(): string {
    return this.sourcingRequestId;
  }

  setSourcingRequestId(sourcingRequestId: string): FlightRequest {
    this.sourcingRequestId = sourcingRequestId;
    return this;
  }

  setFlightRequestStatus(value: FlightRequestStatus): FlightRequest {
    this.status = value;
    return this;
  }

  getFlightRequestStatus(): FlightRequestStatus {
    return this.status;
  }

  getCreatedUser(): User {
    return this.createdUser
     ? this.createdUser
     : null;
  }

  setCreatedUser(createdUser: User): FlightRequest {
    this.createdUser = createdUser;

    return this;
  }

  getSalesAdvisorName(): string {
    return this.salesAdvisorName;
  }

  setSalesAdvisorName(name: string): FlightRequest {
    this.salesAdvisorName = name;
    return this;
  }

  getAcceptedPurchaseOffer(): OperatorPurchaseOffer {
    return this.acceptedPurchaseOffer;
  }

  setAcceptedPurchaseOffer(acceptedPurchaseOffer: OperatorPurchaseOffer): FlightRequest {
    this.acceptedPurchaseOffer = acceptedPurchaseOffer;
    return this;
  }

  getOpportunityId(): string {
    return this.opportunityId;
  }

  setOpportunityId(opportunityId: string): FlightRequest {
    this.opportunityId = opportunityId;
    return this;
  }

  getSourcingRequestStatus(): RequestStatusEnum {
    return this.sourcingRequestStatus;
  }

  setSourcingRequestStatus(status: RequestStatusEnum): FlightRequest {
    this.sourcingRequestStatus = status;
    return this;
  }

  getBidHistory(): Array<BidHistoryItem> {
    return this.bidHistory;
  }

  setBidHistory(bidHistory: Array<BidHistoryItem>): FlightRequest {
    this.bidHistory = bidHistory;

    return this;
  }

  getLegsIcaoCodes(): string {
    if (this.legs && this.legs.length) {
      return this.legs.map(leg => {
        try {
          return `${leg.getRoute().getOriginAirport().code} - ${leg.getRoute().getDestinationAirport().code}`;
        } catch (e) {
          return '';
        }
      }).join(', ');
    } else {
      return null;
    }
  }

  getElacProForma(): boolean {
    return this.elacProForma;
  }

  setElacProForma(elacProForma: boolean): FlightRequest {
    this.elacProForma = elacProForma;

    return this;
  }

  setPetOnBoard(petOnBoard: boolean): FlightRequest {
    this.petOnBoard = petOnBoard;

    return this;
  }

  getPetOnBoard(): boolean {
    return this.petOnBoard;
  }

  setPetNote(petNote: string): FlightRequest {
    this.petNote = petNote;

    return this;
  }

  getPetNote(): string {
    return this.petNote;
  }

  setSFOpportunityId(sfOpportunityId: string): FlightRequest {
    this.sfOpportunityId = sfOpportunityId;

    return this;
  }

  getSFOpportunityId(): string {
    return this.sfOpportunityId;
  }

  getSpecialRequirements(): Array<SpecialRequirement> {
    return this.specialRequirements;
  }

  setSpecialRequirements(requirements: Array<SpecialRequirement>): FlightRequest {
    this.specialRequirements = requirements;
    return this;
  }

  setTravelReason(travelReason: TravelReason): FlightRequest {
    this.travelReason = travelReason;

    return this;
  }

  getTravelReason(): TravelReason {
    return this.travelReason;
  }

  getAllSpecialRequirementNames(): string {
    return this.specialRequirements ? this.specialRequirements.reduce(
      (names, item) => {
        return names + (names ? ', ' : '') + item.name;
      }, '') : '';
  }

  setSource(source: FlightRequestSourceEnum): FlightRequest {
    this.source = source;

    return this;
  }

  getSource(): FlightRequestSourceEnum {
    return this.source;
  }

  getChildFlightRequests(): Array<FlightRequest> {
    return this.childFlightRequests
  }

  setChildFlightRequests(childFlightRequests: Array<FlightRequest>): FlightRequest {
    this.childFlightRequests = childFlightRequests;

    return this;
  }
}
