import { createReducer, on } from "@ngrx/store";

import * as models from '../../domain/models';
import * as actions from '../actions/transition-collection.actions';

export const transitionCollectionFeatureKey = 'transitionCollection';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Transition>;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(actions.load, (state): State => ({
    ...state,
    isLoading: true,
  })),

  on(actions.loadSuccess, (state, { payload }): State => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    items: payload,
  })),

  on(actions.loadFail, (state): State => ({
    ...state,
    isLoaded: true,
    isLoading: false,
  }))
);

