import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import * as actions from '../actions/delay-codes-collection.actions';
import * as models from '../../domain/models';

export const delayCodesCollectionFeatureKey = 'delayCodesCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.DelayCode>;
  typeItems: Array<models.DelayCodeType>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  typeItems: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(actions.loadSuccess, (state, { payload }): State => {
    const typeItems = _.uniqBy(
      payload.map((code) => code.type),
      'id'
    );
    return {
      loaded: true,
      loading: false,
      items: payload,
      typeItems,
    };
  }),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  )
);
