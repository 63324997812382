export class RuleData {
  aircraftCategories: Array<string> = null;
  dow: Array<string> = null;
  id: string = null;
  pricesOnInterval: Array<PriceOnInterval>;
  routeGroupNames: Array<string> = null;
  shuttleTypeNames: Array<string> = null;
  totalSeats: number = null;
  version: number = null;
}

export class MembershipPriceRule {
  pricesOnInterval: Array<PriceOnInterval>;
}

export class PriceOnInterval {
  prices: Array<number> = null;
  timeInterval: RuleDataTimeInterval | Array<number> | any = new RuleDataTimeInterval(0);
}

export class RuleDataTimeInterval {
  constructor(start = null, end = null) {
    this.start = start;
    this.end = end;
  }

  start: number = null;
  end: number = null;
}
