import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../../domain/models';

export interface IAircraftService {
  getAircraftCategoriesForCalculation(
    request: GetAircraftCategoryListRequest
  ): Observable<GetAircraftCategoryForCalculationResponse>;
  getAircraftCategories(
    request: GetAircraftCategoryListRequest
  ): Observable<GetAircraftCategoryListResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Sales.IAircraftService');

export class GetAircraftCategoryListRequest {}

export class GetAircraftCategoryListResponse {
  entities: Array<models.AircraftCategory> = [];
}

export class GetAircraftCategoryForCalculationResponse {
  entities: Array<models.AircraftCategory> = [];
}
