export const response = {
  user: {
    phone: '1231231234',
    email: 'flight.inapp.purchase.recognizer@jetsmarter.com',
    id: 2022,
    firstName: 'InApp Flight Purchase',
    lastName: 'Recognizer',
  },
  canChangePaymentType: true,
  totalPrice: 17758,
  invoices: [
    {
      replacedInvoiceId: null,
      userId: 2022,
      legacyFormTplId: 359,
      invoiceTypeName: 'Main Invoice',
      uuid: '618726f3-7032-4df0-ad3c-5b2d3e322857',
      legacyFormEditUrl: null,
      legacyFormUrlKey: '6ae2129f-dba3-40a6-a188-0e2c0c7dcdb9',
      invoiceExternalId: null,
      invoiceReplacements: [],
      invoiceStatusId: 4,
      orderId: 11319,
      invoiceId: 14580,
      createTime: '2019-03-11T14:07:59.034163+00:00',
      parentInvoiceId: null,
      orderItemId: 17833,
      legacyFormUrl: 'https://jetsmarter.com/forms/charterjs/?6ae2129f-dba3-40a6-a188-0e2c0c7dcdb9',
      clientId: 1479550,
      invoiceTypeId: 1,
      amount: 17758,
      client: {
        futureMembershipTotal: 7950,
        clientCreateTime: '2018-04-02T15:36:16.178096+00:00',
        membershipTimeStarted: '2018-04-02T15:48:32.200606+00:00',
        name: 'Oleg Solovey',
        futureMembershipTimeStarted: '2019-04-02T15:48:32.200606+00:00',
        dob: '1990-10-10',
        membershipTotal: null,
        membershipDisplayName: 'JETSMARTER CORP',
        membershipTypeId: 26,
        futureMembershipTypeId: 42,
        phone: 2345674567,
        futureMembershipTimeExpiring: '2020-04-02T15:48:32.200606+00:00',
        email: 'osolovey@jetsmarter.com',
        brokerid: 0,
        membershipTimeExpiring: '2019-04-02T15:48:32.200606+00:00',
        legalName: 'Oleg Solovey',
        personalManager: 1451947,
        futureMembershipDisplayName: 'JETSMARTER',
        id: 1479550,
      },
      orderSourceId: 2,
      replacingInvoiceId: null,
    },
  ],
  items: [
    {
      orderId: 11319,
      parentOrderItem: null,
      totalPrice: 17758,
      promotionAmount: null,
      orderItemTypeId: 11,
      notes: null,
      membershipRequest: {
        orderItemId: 17833,
        salesAction: {
          name: 'New',
          id: 1,
        },
        productName: 'Charter Flight',
        expirationTime: '2019-03-14T14:07:58.854885+00:00',
        expired: false,
        createTime: '2019-03-11T14:07:58.943759+00:00',
      },
      id: 17833,
      product: {
        productId: 114,
        productName: 'Charter Flight',
      },
      childOrderItems: [],
      charterInfo: {
        purchaseOrderNumber: null,
        requestUrl: 'http://console.smart-jets.com/#fl:12507493',
        departureDate: '2019-03-31T13:00:00',
        requestId: 12507493,
      },
      client: {
        futureMembershipTotal: 7950,
        clientCreateTime: '2018-04-02T15:36:16.178096+00:00',
        membershipTimeStarted: '2018-04-02T15:48:32.200606+00:00',
        name: 'Oleg Solovey',
        futureMembershipTimeStarted: '2019-04-02T15:48:32.200606+00:00',
        dob: '1990-10-10',
        membershipTotal: null,
        membershipDisplayName: 'JETSMARTER CORP',
        membershipTypeId: 26,
        futureMembershipTypeId: 42,
        phone: 2345674567,
        futureMembershipTimeExpiring: '2020-04-02T15:48:32.200606+00:00',
        email: 'osolovey@jetsmarter.com',
        brokerid: 0,
        membershipTimeExpiring: '2019-04-02T15:48:32.200606+00:00',
        legalName: 'Oleg Solovey',
        personalManager: 1451947,
        futureMembershipDisplayName: 'JETSMARTER',
        id: 1479550,
      },
      basePrice: 17758,
      conciergeInfo: null,
      promotionItemId: null,
      quantity: 1,
    },
  ],
  notes: null,
  id: 11319,
  orderStatusId: 5,
  sapExportStatus: [
    {
      statusId: 4,
      statusDescription: 'This order\'s SAP creation ended with an error',
      submissionTypeId: 1
    },
    {
      statusId: 4,
      statusDescription: 'This order\'s SAP cancellation ended with an error',
      submissionTypeId: 3
    }
  ],
  partyId: 1479550,
  orderBilling: {
    client: {
      futureMembershipTotal: 7950,
      clientCreateTime: '2018-04-02T15:36:16.178096+00:00',
      membershipTimeStarted: '2018-04-02T15:48:32.200606+00:00',
      name: 'Oleg Solovey',
      futureMembershipTimeStarted: '2019-04-02T15:48:32.200606+00:00',
      dob: '1990-10-10',
      membershipTotal: null,
      membershipDisplayName: 'JETSMARTER CORP',
      membershipTypeId: 26,
      futureMembershipTypeId: 42,
      phone: 2345674567,
      futureMembershipTimeExpiring: '2020-04-02T15:48:32.200606+00:00',
      email: 'osolovey@jetsmarter.com',
      brokerid: 0,
      membershipTimeExpiring: '2019-04-02T15:48:32.200606+00:00',
      legalName: 'Oleg Solovey',
      personalManager: 1451947,
      futureMembershipDisplayName: 'JETSMARTER',
      id: 1479550,
    },
  },
  expirationTime: '2019-03-14T14:07:58.854885+00:00',
  promotionId: null,
  expired: false,
  createTime: '2019-03-11T14:07:58.930044+00:00',
  orderSourceId: 2,
  charterSalesOpportunityId: 'c84dfd8d-4884-475d-be0c-448f2626db56',
};
