import { Action } from '@ngrx/store';

import * as models from '../../domain/models';

export const BYPASS_LOAD = '[RM Aircraft Detail] Bypass Load';
export const LOAD = '[YM Charter Pricing Detail] Load';
export const LOAD_SUCCESS = '[YM Charter Pricing Detail] Load Success';
export const LOAD_FAIL = '[YM Charter Pricing Detail] Load Fail';
export const SAVE = '[YM Charter Pricing Detail] Save';
export const SAVE_SUCCESS = '[YM Charter Pricing Detail] Save Success';
export const SAVE_FAIL = '[YM Charter Pricing Detail] Save Fail';
export const ADD = '[YM Charter Pricing Detail] Add';
export const ADD_SUCCESS = '[YM Charter Pricing Detail] Add Success';
export const ADD_FAIL = '[YM Charter Pricing Detail] Add Fail';
export const SET = '[YM Charter Pricing Detail] Set';

export const VALIDATE = '[YM Charter Pricing Detail] Validate';
export const VALIDATE_RESPONSE = '[YM Charter Pricing Detail] Validate Response';

export class BypassAction implements Action {
  readonly type = BYPASS_LOAD;
}

export class SetPayload {
  constructor(public item: models.CharterPricingRule) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class LoadPayload {
  constructor(public id: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.CharterPricingRule) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.CharterPricingRule) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class AddAction implements Action {
  readonly type = ADD;
}

export class AddSuccessPayload {
  constructor(public item: models.CharterPricingRule) {}
}

export class AddSuccessAction implements Action {
  readonly type = ADD_SUCCESS;

  constructor(public payload: AddSuccessPayload) {}
}

export class AddFailAction implements Action {
  readonly type = ADD_FAIL;
}

export class ValidateAction implements Action {
  readonly type = VALIDATE;
}

export class ValidateResponseAction implements Action {
  readonly type = VALIDATE_RESPONSE;

  constructor(public payload: ValidateResponsePayload) {}
}

export class ValidateResponsePayload {
  constructor(public valid: boolean, public message: string) {}
}

export type Actions =
  | BypassAction
  | SetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | AddAction
  | AddSuccessAction
  | AddFailAction
  | ValidateAction
  | ValidateResponseAction;
