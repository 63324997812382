import { InvoiceTypeEnum } from './invoice-type-enum';
import { ContactMethod } from './contact-method';
import { Client } from './client';
import { InvoiceReplacement } from './invoice-replacement';
import { SalesInvoiceDocument } from './sales-document';

export class Invoice {
  id: number = null;
  invoiceTypeId: InvoiceTypeEnum = null;
  invoiceTypeName: string = null;
  invoiceStatusId: number = null;
  orderId: number = null;
  statusId: number = null;
  clientId: number = null;
  client: Client = null;
  amount: number = null;
  uuid: string = null;
  legacyFormUrlKey: string = null;
  legacyFormTplId: number = null;
  legacyFormUrl: string = null;
  legacyFormEditUrl: string = null;
  externalProfileLink: string = null;
  createTime: Date = null;
  contactMethods: Array<ContactMethod> = [];
  invoiceReplacements: Array<InvoiceReplacement> = [];
  documents: SalesInvoiceDocument[] = [];
}
