import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Application Configs Collection] Load';
export const LOAD_SUCCESS = '[RM Application Configs Collection] Load Success';
export const LOAD_FAIL = '[RM Application Configs Collection] Load Fail';

export class LoadActionPayload {
  constructor(public lookup: viewModels.ConfigsLookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadActionPayload) {}
}

export class LoadSuccessActionPayload {
  items: Array<models.ApplicationConfig>;
  total: number;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessActionPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;