export enum CharterLegacyFormTplEnum {
  CharterMultiPaymentInvoice = 355,
  CharterCoupon = 356,
  CharterCouponCardOnFile = 358,
  LegacyCharterInvoice = 2,
  CharterNewInvoice = 359,
  CharterAdditionalInvoice = 360,
  CharterModifiedInvoice = 361,
  TermsAndConditionsInvoice = 362,
  FlightInvoice = 12,
  OptionFlightInvoice = 22,
  CharterReceipt = 92,
  ConciergeInvoice = 353,
  ConciergeInvoiceCardOnFile = 357,
  ConciergeTermsReceipt = 354,
  ConciergeAdditionalInvoice = 363,
  ConciergeAdditionalTermsReceipt = 364,
}
