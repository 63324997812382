import { ProductOrderItem } from './product-order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class FlightPassOrderItem extends ProductOrderItem {
  createTime: Date = null;
  expirationTime: Date = null;
  faceValue: number = null;
  qtyOfPasses: number = null
  route: string = null;
  salesActionId: number = null
  sfdcOpportunityId: string = null;
  contractCommencementDate: string = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.FlightPass;
  }
}
