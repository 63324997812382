export class Cancellation {
  cancellationTime: Date = null;
  orderStatusId: number = null;
  user: CancellationUser;
}

export class CancellationUser {
  email: string = null;
  firstName: string = null;
  id: number = null;
  lastName: string = null;
}
