import { AircraftCategory } from './aircraft-category';
import { PeakDays } from './peak-days';
import { AdditionalFeature } from "./additional-feature";

export class MembershipType {
  categories: Array<string>;
  id: string;
  displayName: string;
  nreg: boolean;
}

export class PricingMeta {
  calculatorCategories: Array<AircraftCategory>;
  comparisonCategories: Array<AircraftCategory>;
  memberships: Array<MembershipType>;
  peakDays: PeakDays;
  highDemandDays: PeakDays;
  premiumFeatures: Array<AdditionalFeature>;
}
