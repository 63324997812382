import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/opportunity.actions';
import * as lodash from 'lodash';
import * as models from '../../domain/models';

export const opportunityFeatureKey = 'opportunity';

export interface State {
  opportunity: models.Opportunity;
  loaded: boolean;
  loading: boolean;
  duplicateCheck: boolean;
  duplicateContext: models.DuplicateContext;
  membershipLoading?: boolean;
  membershipLoaded?: boolean;
}

const initialState: State = {
  opportunity: null,
  membershipLoading: false,
  membershipLoaded: false,
  loaded: false,
  loading: false,
  duplicateCheck: null,
  duplicateContext: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.addPurchaseQuoteSuccess, (state, { payload }): State => {
    const o1 = lodash.cloneDeep(state.opportunity);
    o1.purchaseQuotes.push(payload.quote);

    return {
      ...state,
      opportunity: o1,
    };
  }),
  on(actions.addSalesQuoteSuccess, (state, { payload }): State => {
    const o1 = lodash.cloneDeep(state.opportunity);
    o1.salesQuotes.push(payload.quote);

    return {
      ...state,
      opportunity: o1,
    };
  }),
  on(actions.addSmartSalesQuoteSuccess, (state, { payload }): State => {
    const o1 = lodash.cloneDeep(state.opportunity);
    o1.getSmartSalesQuotes().push(payload.smartSalesQuote);

    return {
      ...state,
      opportunity: o1,
    };
  }),
  on(
    actions.updatePurchaseQuoteAircraftSuccess,
    actions.updatePurchaseQuoteItinerarySuccess,
    actions.updatePurchaseQuoteAmountSuccess,
    actions.updatePurchaseQuoteSuccess,
    actions.createPurchaseQuoteNoteSuccess,
    (state, { payload }): State => {
      const o1 = lodash.cloneDeep(state.opportunity);
      const index = o1
        .getPurchaseQuotes()
        .findIndex((e) => e.getPurchaseQuoteId() === payload.getPurchaseQuoteId());
      o1.getPurchaseQuotes()[index] = payload;

      return {
        ...state,
        opportunity: o1,
      };
    }
  ),

  on(
    actions.confirmUncorfimSubCharter,
    (state): State => {
      return {
        ...state,
        loading: true,
      };
    }
  ),
  on(
    actions.confirmUncorfimSubCharterSuccess,
    (state, { payload }): State => {
      const o1 = lodash.cloneDeep(state.opportunity);
      const index = o1
        .getPurchaseQuotes()
        .findIndex((e: models.PurchaseQuote) => e.getPurchaseQuoteId() === payload.getPurchaseQuoteId());
      o1.getPurchaseQuotes()[index] = payload;

      return {
        ...state,
        opportunity: o1,
        loading: false,
      };
    }
  ),

  on(
    actions.load,
    (): State => ({
      ...initialState,
      loading: true,
    })
  ),
  on(
    actions.deleteSalesQuote,
    actions.deletePurchaseQuote,
    actions.deleteSalesQuoteRequest,
    actions.updateSmartSalesQuote,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.deleteSalesQuoteFail,
    actions.deletePurchaseQuoteFail,
    actions.deleteSalesQuoteRequestFail,
    actions.updateSmartSalesQuoteFail,
    actions.confirmUncorfimSubCharterFail,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      opportunity: payload,
      loaded: true,
      loading: false,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(
    actions.loadClientMembership,
    (): State => ({
      ...initialState,
      membershipLoaded: false,
      membershipLoading: true,
    })
  ),

  on(actions.loadClientMembershipSuccess, (state, { payload }): State => {
    const opportunity = lodash.cloneDeep(state.opportunity);

    if (!opportunity) {
      return {
        ...state,
        membershipLoading: false,
        membershipLoaded: true,
      };
    }

    opportunity.client.membership = Object.assign(opportunity.client.membership, payload);
    return {
      ...state,
      opportunity: opportunity,
      membershipLoading: false,
      membershipLoaded: true,
    };
  }),
  on(actions.setOpportunityStatusSuccess, (state, { payload }): State => {
    const o1 = lodash.cloneDeep(state.opportunity);
    o1.setStatus(payload.status);

    return {
      ...state,
      opportunity: o1,
    };
  }),
  on(
    actions.updateOpportunitySalesAgentSuccess,
    actions.updateSourcingSpecialistSuccess,
    (state, { payload }): State => ({
      ...state,
      opportunity: payload,
    })
  ),
  on(
    actions.showError,
    (state): State => ({
      ...state,
    })
  ),
  on(
    actions.promptDuplicateCheck,
    (state, { payload: { duplicateContext, duplicateCheck } }): State => ({
      ...state,
      duplicateCheck,
      duplicateContext,
    })
  )
);
