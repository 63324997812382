import { AircraftCategory } from './aircraft-category';
import { Operator } from './operator';
import { Aircraft } from './aircraft';
import { BidHistoryItem, LegRequest, OfferStatusEnum } from '../../domain/models';
import { CurrencyEnum } from '../../../../shared/models';

export class OperatorPurchaseOffer {
  offerPrice: number = null;
  flightPrice: number;
  aircraftCategories: Array<AircraftCategory>;
  operator: Operator;
  aircraft: Aircraft = null;
  legs: Array<LegRequest>;
  bidHistory: Array<BidHistoryItem>;
  currency: CurrencyEnum = null;
  offerPriceInCurrency: number = null;
  status: OfferStatusEnum = null;
  currencyUpdateTime?: Date = null;
  currencyRate?: number = null;

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): OperatorPurchaseOffer {
    this.operator = operator;
    return this;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  setAircraftCategories(categories: Array<AircraftCategory>): OperatorPurchaseOffer {
    this.aircraftCategories = categories;
    return this;
  }

  getAircraftCategoryNames(): string {
    if (this.aircraftCategories && this.aircraftCategories.length) {
      return this.aircraftCategories.map((category) => category.name).join(', ');
    } else {
      return null;
    }
  }

  getOfferPrice(): number {
    return this.offerPrice;
  }

  setOfferPrice(maxPrice: number): OperatorPurchaseOffer {
    this.offerPrice = maxPrice;
    return this;
  }


  getFlightPrice(): number {
    return this.flightPrice;
  }

  setFlightPrice(flightPrice: number): OperatorPurchaseOffer {
    this.flightPrice = flightPrice;
    return this;
  }

  getAircraft(): Aircraft {
    return this.aircraft;
  }

  setAircraft(aircraft: Aircraft): OperatorPurchaseOffer {
    this.aircraft = aircraft;
    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): OperatorPurchaseOffer {
    this.legs = legs;
    return this;
  }

  setBidHistory(bidHistory: Array<BidHistoryItem>): OperatorPurchaseOffer {
    this.bidHistory = bidHistory;
    return this;
  }

  getBidHistory(): Array<BidHistoryItem> {
    return this.bidHistory;
  }

  getOfferPriceInCurrency(): number {
    return this.offerPriceInCurrency;
  }

  setOfferPriceInCurrency(price: number): OperatorPurchaseOffer {
    this.offerPriceInCurrency = price;
    return this;
  }

  getStatus(): OfferStatusEnum {
    return this.status;
  }

  setStatus(status: OfferStatusEnum): OperatorPurchaseOffer {
    this.status = status;

    return this;
  }

  getCurrency(): CurrencyEnum {
    return this.currency;
  }

  setCurrency(currency: CurrencyEnum): OperatorPurchaseOffer {
    this.currency = currency;
    return this;
  }

  setCurrencyUpdateTime(updateDate: Date): OperatorPurchaseOffer {
    this.currencyUpdateTime = updateDate;
    return this;
  }

  getCurrencyUpdateTime(): Date {
    return this.currencyUpdateTime;
  }

  getCurrencyRate(): number {
    return this.currencyRate;
  }

  setCurrencyRate(rate: number): OperatorPurchaseOffer {
    this.currencyRate = rate;
    return this;
  }
}
