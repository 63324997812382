import { PriceComponent } from './price-component';
import { Product } from './product';
import { Promotion } from './promotion';

export class PromotionItem {
  id: number = null;
  discount: PriceComponent;
  product: Product = null;
  promotion: Promotion = null;
  promotionAmount: number = null;
  quantity: number = null;

  createTime: Date = null;
}
