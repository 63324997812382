import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const RESET = '[MySourcing.SourcingRequestList] Reset';
export const SEARCH = '[MySourcing.SourcingRequestList] Search';
export const SEARCH_SUCCESS = '[MySourcing.SourcingRequestList] Load Success';
export const SEARCH_FAIL = '[MySourcing.SourcingRequestList] Load Fail';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SearchPayload {
  criteria: viewModels.SourcingRequestLookupCriteria;
  page: number;
  itemsPerPage: number;

  constructor(
    criteria: viewModels.SourcingRequestLookupCriteria,
    page: number,
    itemsPerPage: number
  ) {
    this.criteria = new viewModels.SourcingRequestLookupCriteria();

    if (criteria) {
      this.criteria = criteria;
    }

    this.page = page;
    this.itemsPerPage = itemsPerPage;
  }
}

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: SearchPayload) {}
}

export class SearchSuccessPayload {
  page: number;
  data: Array<models.SourcingRequest>;
  count: number;
}

export class SearchSuccessAction implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: SearchSuccessPayload) {}
}

export class SearchFailAction implements Action {
  readonly type = SEARCH_FAIL;
}

export type Actions = ResetAction | SearchAction | SearchSuccessAction | SearchFailAction;
