import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';
import { SalesDocumentUploadResponse } from '../../domain/service-contracts/sales-document';

export const cancel = createAction('[Sales.Order] Cancel', props<{ payload: models.Order }>());
export const cancelSuccess = createAction(
  '[Sales.Order] Cancel Success',
  props<{ payload: models.Order }>()
);
export const cancelFail = createAction('[Sales.Order] Cancel Fail');

export const reinstate = createAction(
  '[Sales.Order] Reinstate',
  props<{ payload: models.Order }>()
);
export const reinstateSuccess = createAction(
  '[Sales.Order] Reinstate Success',
  props<{ payload: models.Order }>()
);
export const reinstateFail = createAction('[Sales.Order] Reinstate Fail');

export const load = createAction('[Sales.Order] Load', props<{ payload: number }>());
export const loadSuccess = createAction(
  '[Sales.Order] Load Success',
  props<{ payload: models.Order }>()
);
export const loadFail = createAction('[Sales.Order] Load Fail');

export const markPaid = createAction(
  '[Sales.Order] Mark Paid',
  props<{ payload: MarkPaidPayload }>()
);
export class MarkPaidPayload {
  constructor(public order: models.Order, public paymentTransaction: models.PaymentTransaction) {}
}
export const markPaidFail = createAction(
  '[Sales.Order] Mark Paid Fail',
  props<{ payload: MarkPaidFailPayload }>()
);
export class MarkPaidFailPayload {
  constructor(public order: models.Order, public message: string) {}
}
export const markPaidSuccess = createAction(
  '[Sales.Order] Mark Paid Success',
  props<{ payload: models.Order }>()
);

export const paymentConfirm = createAction(
  '[Sales.Order] Payment Confirm',
  props<{ payload: PaymentConfirmPayload }>()
);
export class PaymentConfirmPayload {
  constructor(public order: models.Order, public paymentTransaction: models.PaymentTransaction) {}
}
export const paymentConfirmSuccess = createAction(
  '[Sales.Order] Payment Confirm Success',
  props<{ payload: models.Order }>()
);
export const paymentConfirmFail = createAction(
  '[Sales.Order] Payment Confirm Fail',
  props<{ payload: PaymentConfirmFailPayload }>()
);
export class PaymentConfirmFailPayload {
  constructor(public order: models.Order, public message: string) {}
}

export const replaceInvoice = createAction(
  '[Sales.Order] Replace Invoice',
  props<{ payload: ReplaceInvoicePayload }>()
);
export class ReplaceInvoicePayload {
  constructor(public invoiceId: number, public newFormTplId: number, public orderId: number) {}
}

export const replaceInvoiceFail = createAction(
  '[Sales.Order] Replace Invoice Fail',
  props<{ payload: any }>()
);
export const replaceInvoiceSuccess = createAction('[Sales.Order] Replace Invoice Success');

export const appendDocument = createAction(
  '[Sales.Order] Upload Contract',
  props<{ payload: SalesDocumentUploadResponse[] }>()
);
export const reGenerateDocument = createAction(
  '[Sales.Order] ReGenerate Contract',
  props<{ payload: ReGenerateDocumentPayload }>()
);
export class ReGenerateDocumentPayload {
  invoiceId: number;
}
export const reGenerateDocumentSuccess = createAction(
  '[Sales.Order] ReGenerate Contract Success',
  props<{ payload: ReGenerateDocumentSuccessPayload }>()
);
export class ReGenerateDocumentSuccessPayload {
  documents: Array<models.SalesGeneratedDocument>;
  documentGenerationInProgress: boolean;
  showGeneratedDocumentsPanel: boolean;
}
export const reGenerateDocumentFail = createAction('[Sales.Order] ReGenerate Contract Fail');

export const setPaymentOption = createAction(
  '[Sales.Order] Set Payment Option',
  props<{ payload: SetPaymentOptionPayload }>()
);
export class SetPaymentOptionPayload {
  constructor(public item: models.OrderPaymentOptions) {}
}
export const submitPaymentOption = createAction('[Sales.Order] Submit Payment Option');
export const submitPaymentOptionSuccess = createAction(
  '[Sales.Order] Submit Payment Option Success'
);
export const submitPaymentOptionFail = createAction('[Sales.Order] Submit Payment Option Fail');

export const getDocumentsByOrder = createAction(
  '[Sales.Order] Get Documents By Order',
  props<{ payload: GetDocumentsByOrderPayload }>()
);
export class GetDocumentsByOrderPayload {
  orderId: number;
}
export const getDocumentsByOrderSuccess = createAction(
  '[Sales.Order] Get Documents By Order Success',
  props<{ payload: GetDocumentsByOrderSuccessPayload }>()
);
export class GetDocumentsByOrderSuccessPayload {
  documents: Array<models.SalesGeneratedDocument>;
  documentGenerationInProgress: boolean;
  showGeneratedDocumentsPanel: boolean;
}
export const getDocumentsByOrderFail = createAction('[Sales.Order] Get Documents By Order Fail');

export const setOrderStatus = createAction(
  '[Sales.Order] Set Order Status',
  props<{ payload: models.OrderStatusEnum }>()
);

export const createSfdcOpportunity = createAction(
  '[Sales.Order] Create SFDC Opportunity',
  props<{ orderId: number }>()
);
export const createSfdcOpportunitySuccess = createAction(
  '[Sales.Order] Create SFDC Opportunity Success'
);
export const createSfdcOpportunityFail = createAction('[Sales.Order] Create SFDC Opportunity Fail');
