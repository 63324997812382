export const betaLabel = ' (DO NOT USE! BETA TEST)';

export class AircraftCategory {
  id: number;
  _name: string;
  _nregName?: string;
  _elacName?: string;
  actype?: string;

  getId(): number {
    return this.id;
  }

  setId(id: number): AircraftCategory {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  getFullName(fromField = 'name') {
    return this[fromField];
  }

  setName(name: string): AircraftCategory {
    this.name = name;

    return this;
  }

  getACType(): string {
    return this.actype;
  }

  setACType(actype: string): AircraftCategory {
    this.actype = actype;

    return this;
  }

  equals(o1: AircraftCategory): boolean {
    if (o1 === null || this.id === null || this.id === undefined) {
      return false;
    }

    return this.id === o1.id;
  }

  toString(): string {
    return `(${this.id}) ${this.name}`;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get nregName(): string {
    return this.beta ? this._nregName.replace(betaLabel, '') : this._nregName;
  }

  set nregName(value: string) {
    this._nregName = value;
  }

  get elacName(): string {
    return this.beta ? this._elacName.replace(betaLabel, '') : this._elacName;
  }

  set elacName(value: string) {
    this._elacName = value;
  }

  get beta(): boolean {
    return this.name.includes(betaLabel);
  }
}
