import { createAction, props } from '@ngrx/store';

export const load = createAction('[TaskMgmt.SourceCollection] Load');
export const loadSuccess = createAction(
  '[TaskMgmt.SourceCollection] Load Success',
  props<{ payload: Array<string> }>()
);
export const loadFail = createAction('[TaskMgmt.SourceCollection] Load Fail');

export const setInitial = createAction('[TaskMgmt.SourceCollection] Set Initial');
