import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Marketplace.OnFleet Aircraft Collection] Load';
export const LOAD_SUCCESS = '[Marketplace.OnFleet Aircraft Collection] Load Success';
export const LOAD_FAIL = '[Marketplace.OnFleet Aircraft Collection] Load Fail';

export class LoadActionPayload {
    operatorId: string;
    categoryIds: Array<number>;
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadActionPayload) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.Aircraft>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
