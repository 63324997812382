import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'currency-rate-content',
  template: `
    <ng-container *ngIf="rateInfo.currency !== 'USD'">
      <em class="hint-text small" [ngClass]="contentClass">{{ amount | currency: (rateInfo.baseCurrency || rateInfo.currency):'symbol-narrow':'.2-2'}}</em>
      <button type="button"
              class="hint-text info-tooltip-btn"
              kendoTooltip
              tooltipClass="tooltip-style"
              filter="button.info-tooltip-btn"
              [tooltipTemplate]="currencyRateTemplate">
        <i class="fal fa-exclamation-circle"></i>
      </button>
      <ng-template #currencyRateTemplate>
        {{1 | currency : rateInfo.currency : 'symbol-narrow' : '.0-0'}} = {{rateInfo.currencyRate | currency : 'USD' : 'symbol-narrow' : '.3-3'}} @ {{rateInfo.currencyUpdateTime | date : 'MM/dd/YYYY'}}
      </ng-template>
    </ng-container>
  `,
  styles: [`
    :host {
      display: block;
    }

    .with-padding {
      padding-left: 12px;
    }

    .info-tooltip-btn {
      padding-right: 0;
      margin-right: 0;
      background: transparent;
      border: 0;
    }
  `]
})
export class CurrencyRateContentComponent {
  @Input()
  rateInfo: any;

  @Input()
  amount: number;

  @Input()
  contentClass: string;
}

