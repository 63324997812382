import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Vendor Collection] Load';
export const LOAD_SUCCESS = '[RM Vendor Collection] Load Success';
export const LOAD_FAIL = '[RM Vendor Collection] Load Fail';
export const ADD_VENDOR = '[RM Vendor Collection] Add Vendor';
export const ADD_VENDOR_SUCCESS = '[RM Vendor Collection] Add Vendor Success';
export const ADD_VENDOR_FAIL = '[RM Vendor Collection] Add Vendor Fail';
export const SET_LOOKUP = '[RM Vendor Collection] Set Lookup';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  constructor(public items: Array<models.Vendor>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddVendorAction implements Action {
  readonly type = ADD_VENDOR;

  constructor(public payload: AddVendorPayload) {}
}

export class AddVendorPayload {
  constructor(public item: models.Vendor) {}
}

export class AddVendorSuccessAction implements Action {
  readonly type = ADD_VENDOR_SUCCESS;

  constructor(public payload: AddVendorSuccessPayload) {}
}

export class AddVendorSuccessPayload {
  constructor(public item: models.Vendor) {}
}

export class AddVendorFailAction implements Action {
  readonly type = ADD_VENDOR_FAIL;
}


export class SetLookupAction implements Action {
  readonly type = SET_LOOKUP;

  constructor(public payload: viewModels.VendorLookup) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddVendorAction
  | AddVendorSuccessAction
  | AddVendorFailAction
  | SetLookupAction;
