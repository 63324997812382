import * as actions from '../actions/document-change-log-collection';
import { ChangeLogItem } from '../../domain/models';
import * as _ from 'lodash';

export interface State {
  isLoadingMap: Map<number, boolean>;
  itemsMap: Map<number, Array<ChangeLogItem>>;
}

const initialState: State = {
  isLoadingMap: new Map(),
  itemsMap: new Map(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      const isLoadingMap = _.cloneDeep(state.isLoadingMap);
      isLoadingMap.set(action.payload.documentId, true);
      return {
        ...state,
        isLoadingMap
      };
    }

    case actions.LOAD_SUCCESS: {
      const itemsMap = _.cloneDeep(state.itemsMap);
      itemsMap.set(action.payload.documentId, action.payload.items);
      const isLoadingMap = _.cloneDeep(state.isLoadingMap);
      isLoadingMap.set(action.payload.documentId, false);
      return {
        ...state,
        isLoadingMap,
        itemsMap,
      };
    }

    case actions.LOAD_FAIL: {
      const isLoadingMap = _.cloneDeep(state.isLoadingMap);
      isLoadingMap.set(action.payload, false);
      return {
        ...state,
        isLoadingMap,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoadingMap = (state: State) => state.isLoadingMap;
export const getItemsMap = (state: State) => state.itemsMap;
