import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import * as actions from '../actions/leg-collection.actions';
import * as models from '../../domain/models';

export const legCollectionFeatureKey = 'legCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.TasksLeg>;
  itemsNoteLoading: boolean;
  legsCriteria: models.LegsLookupCriteria;
  offset: number;
  limit: number;
  number: number;
  totalElements: number;
  sortOrder: models.SortOrderLegsEnum;
  sortBy: models.SortLegsEnum;
  prevState: models.StateEnum;
  relatedLegs: models.RelatedLegs;
  relatedLegsLoading: boolean;
  relatedLegsLoaded: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  itemsNoteLoading: false,
  legsCriteria: new models.LegsLookupCriteria(),
  offset: 0,
  limit: 20,
  number: 0,
  totalElements: 0,
  sortOrder: models.SortOrderLegsEnum.asc,
  sortBy: models.SortLegsEnum.nextTaskDue,
  prevState: null,
  relatedLegs: null,
  relatedLegsLoading: false,
  relatedLegsLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state, { payload: { limit, offset, sortBy, sortOrder, legsCriteria } }): State => ({
      ...state,
      loading: true,
      legsCriteria,
      offset,
      limit,
      sortBy,
      sortOrder,
      number: offset / limit,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload: { items, number, totalElements } }): State => ({
      ...state,
      loaded: true,
      loading: false,
      items,
      number,
      totalElements,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(actions.loadTasks, (state, { payload: { legId } }): State => {
    const index = state.items.findIndex((leg) => leg.legId === legId);
    if (index !== -1) {
      const clonedLegs: Array<models.TasksLeg> = _.cloneDeep(state.items);
      clonedLegs[index].loading = true;
      return {
        ...state,
        items: clonedLegs,
      };
    }
    return {
      ...state,
    };
  }),
  on(actions.loadTasksSuccess, (state, { payload: { legId, items } }): State => {
    const index = state.items.findIndex((leg) => leg.legId === legId);
    if (index !== -1) {
      const clonedLegs: Array<models.TasksLeg> = _.cloneDeep(state.items);
      clonedLegs[index].tasks = items;
      clonedLegs[index].loading = false;
      return {
        ...state,
        items: clonedLegs,
      };
    }
    return {
      ...state,
    };
  }),
  on(actions.loadTasksFail, (state, { payload: { legId } }): State => {
    const index = state.items.findIndex((leg) => leg.legId === legId);
    if (index !== -1) {
      const clonedLegs: Array<models.TasksLeg> = _.cloneDeep(state.items);
      clonedLegs[index].tasks = [];
      clonedLegs[index].loading = false;
      return {
        ...state,
        items: clonedLegs,
      };
    }
    return {
      ...state,
    };
  }),

  on(actions.createNewTaskSuccess, (state, { payload: { legId, task } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    clonedTasks.push(task);
    return {
      ...state,
      items: clone,
    };
  }),

  on(actions.revertTasksState, (state, { payload: { taskIds, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    let prevState: models.StateEnum;
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    taskIds.forEach((taskId) => {
      const index = clonedTasks.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        prevState = clonedTasks[index].state.taskStateId;
        clonedTasks[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      items: clone,
    };
  }),
  on(actions.revertTasksStateSuccess, (state, { payload: { tasks, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    tasks.forEach((task) => {
      const index = clonedTasks.findIndex((e) => e.taskId === task.taskId);
      if (index !== -1) {
        clonedTasks[index] = task;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),
  on(actions.revertTasksStateFail, (state, { payload: { taskIds, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    taskIds.forEach((taskId) => {
      const index = clonedTasks.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clonedTasks[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),

  on(actions.setStateForMultipleTask, (state, { payload: { taskIds, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    let prevState: models.StateEnum;
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    taskIds.forEach((id) => {
      const index = clonedTasks.findIndex((e) => e.taskId === id);
      if (index !== -1) {
        prevState = clonedTasks[index].state.taskStateId;
        clonedTasks[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      items: clone,
    };
  }),
  on(
    actions.setStateForMultipleTaskSuccess,
    (state, { payload: { tasks, legId, newLegStatus } }): State => {
      const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
      const legIndex = clone.findIndex((leg) => leg.legId === legId);
      const clonedTasks = clone[legIndex].tasks;
      tasks.forEach((task) => {
        const taskIndex = clonedTasks.findIndex((e) => e.taskId === task.taskId);
        if (taskIndex !== -1) {
          clonedTasks[taskIndex] = task;
        }
      });
      if (newLegStatus) {
        clone[legIndex].statusDisplayName = newLegStatus;
      }
      return {
        ...state,
        items: clone,
      };
    }
  ),
  on(actions.setStateForMultipleTaskFail, (state, { payload: { taskIds, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    taskIds.forEach((taskId) => {
      const index = clonedTasks.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clonedTasks[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),

  on(
    actions.createNewNote,
    (state): State => ({
      ...state,
      itemsNoteLoading: true,
    })
  ),
  on(actions.createNewNoteSuccess, (state, { payload: { note, taskId, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    const index = clonedTasks.findIndex((e) => e.taskId === taskId);
    if (index !== -1) {
      clonedTasks[index].notes.unshift(note);
      return {
        ...state,
        items: clone,
        itemsNoteLoading: false,
      };
    }
    return {
      ...state,
    };
  }),
  on(
    actions.createNewNoteFail,
    (state): State => ({
      ...state,
      itemsNoteLoading: false,
    })
  ),

  on(
    actions.updateNote,
    (state): State => ({
      ...state,
      itemsNoteLoading: true,
    })
  ),
  on(actions.updateNoteSuccess, (state, { payload: { note, taskId, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    const index = clonedTasks.findIndex((e) => e.taskId === taskId);
    if (index !== -1) {
      const noteIndex = clonedTasks[index].notes.findIndex((e) => note.id === e.id);
      clonedTasks[index].notes[noteIndex] = note;
      return {
        ...state,
        items: clone,
        itemsNoteLoading: false,
      };
    }
    return {
      ...state,
      itemsNoteLoading: false,
    };
  }),
  on(
    actions.updateNoteFail,
    (state): State => ({
      ...state,
      itemsNoteLoading: false,
    })
  ),

  on(
    actions.deleteNote,
    (state): State => ({
      ...state,
      itemsNoteLoading: true,
    })
  ),
  on(actions.deleteNoteSuccess, (state, { payload: { taskId, id, legId } }): State => {
    const clone: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clone.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clone[legIndex].tasks;
    const index = clonedTasks.findIndex((e) => e.taskId === taskId);
    if (index !== -1) {
      const noteIndex = clonedTasks[index].notes.findIndex((e) => id === e.id);
      clonedTasks[index].notes.splice(noteIndex, 1);
      return {
        ...state,
        items: clone,
        itemsNoteLoading: false,
      };
    }
    return {
      ...state,
      itemsNoteLoading: false,
    };
  }),
  on(
    actions.deleteNoteFail,
    (state): State => ({
      ...state,
      itemsNoteLoading: false,
    })
  ),

  on(
    actions.getRelatedLegs,
    (state): State => ({
      ...state,
      relatedLegsLoading: true,
      relatedLegsLoaded: false,
    })
  ),
  on(
    actions.getRelatedLegsSuccess,
    (state, { payload: { relatedLegs } }): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
      relatedLegs,
    })
  ),
  on(
    actions.getRelatedLegsFail,
    (state): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
    })
  ),

  on(
    actions.updateRelatedLegs,
    (state): State => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(actions.updateRelatedLegsSuccess, (state, { payload: { pod, legIds } }): State => {
    const clonedLegs: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const items = clonedLegs.map((leg) => {
      if (legIds.includes(leg.legId)) {
        leg.pod = pod;
      }
      return leg;
    });
    return {
      ...state,
      loading: false,
      loaded: true,
      items,
    };
  }),
  on(
    actions.updateRelatedLegsFail,
    (state): State => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(
    actions.finalizeLeg,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(actions.finalizeLegSuccess, (state, { payload: { legId, taskId } }): State => {
    const clonedLegs: Array<models.TasksLeg> = _.cloneDeep(state.items);
    const legIndex = clonedLegs.findIndex((leg) => leg.legId === legId);
    const clonedTasks = clonedLegs[legIndex].tasks;
    const taskIndex = clonedTasks.findIndex((task) => task.taskId === taskId);
    clonedLegs[legIndex].statusDisplayName = 'Finalized';
    clonedTasks[taskIndex].state = {
      taskStateId: 5,
      displayName: 'Completed',
      code: 'COMPLETED',
    };
    return {
      ...state,
      items: clonedLegs,
      loading: false,
    };
  }),
  on(
    actions.finalizeLegFail,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState,
    })
  )
);
