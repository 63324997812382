import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const selectLegRequest = createAction(
  '[Charter.OpportunityNew] Select Leg Request',
  props<{ payload: number }>()
);

export const updateRequest = createAction(
  '[Charter.OpportunityNew] Update Request',
  props<{ payload: models.SalesQuoteRequest }>()
);

export const reset = createAction('[Charter.OpportunityNew] Reset');

export const setClient = createAction(
  '[Charter.OpportunityNew] Set Client',
  props<{ payload: models.Client }>()
);

export const setSalesAgentEmailAddress = createAction(
  '[Charter.OpportunityNew] Set Sales Agent Email Address',
  props<{ emailAddress: string }>()
);

export const submit = createAction('[Charter.OpportunityNew] Submit');
export const submitSuccess = createAction(
  '[Charter.OpportunityNew] Submit Success',
  props<{ payload: models.Opportunity }>()
);
export const submitFail = createAction('[Charter.OpportunityNew] Submit Fail');

export class PromptDuplicateCheckPayload {
  duplicateCheck: boolean;
  duplicateContext: models.DuplicateContext;
}
export const promptDuplicateCheck = createAction(
  '[Charter.OpportunityNew] Prompt Duplicate Check',
  props<{ payload: PromptDuplicateCheckPayload }>()
);

export const promptDuplicateCheckCancel = createAction(
  '[Charter.OpportunityNew] Prompt Duplicate Check Cancel'
);

export const setZendeskTicket = createAction(
  '[Charter.OpportunityNew] Set Zendesk Ticket',
  props<{ payload: string }>()
);

export const setZendeskUser = createAction(
  '[Charter.OpportunityNew] Set Zendesk User',
  props<{ payload: string }>()
);

export const updateOpportunityNote = createAction(
  '[Charter.OpportunityNew] Update Opportunity Note',
  props<{ payload: string }>()
);

export const updateOpportunitySubCharter = createAction(
  '[Charter.OpportunityNew] Update Opportunity Sub Charter',
  props<{ payload: boolean }>()
);

export class SendNegativeBalanceEmailPayload {
  constructor(public clientId: number, public tripInformation: string) {}
}
export const sendNegativeBalanceEmail = createAction(
  '[Charter.OpportunityNew] Send Negative Balance Email',
  props<{ payload: SendNegativeBalanceEmailPayload }>()
);
export const sendNegativeBalanceEmailSuccess = createAction(
  '[Charter.OpportunityNew] Send Negative Balance Email Success'
);
export const sendNegativeBalanceEmailFail = createAction(
  '[Charter.OpportunityNew] Send Negative Balance Email Fail'
);

export const loadClientMembership = createAction(
  '[Charter.OpportunityNew] Load Client Membership',
  props<{ payload: number }>()
);
export class LoadClientMembershipSuccessPayload {
  productTypeId: number;
}
export const loadClientMembershipSuccess = createAction(
  '[Charter.OpportunityNew] Load Client Membership Success',
  props<{ payload: LoadClientMembershipSuccessPayload }>()
);
export const loadClientMembershipFail = createAction(
  '[Charter.OpportunityNew] Load Client Membership Fail'
);
