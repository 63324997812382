import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as userServiceContracts from '../services/user/contracts';
import * as actions from '../actions/user.actions';
import * as layoutActions from '../../shared/actions/layout.actions';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
  loadUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      switchMap(() => {
        const request = new userServiceContracts.GetUserRequest();

        return this.userService.getUser(request).pipe(
          switchMap((svcResp) => {
            return [
              actions.loadSuccess({ payload: svcResp.entity }),
              layoutActions.setServerEnvironment({ payload: svcResp.environment }),
              layoutActions.setMenuPin({ payload: svcResp.menuPin }),
            ];
          })
        );
      })
    )
  );

  signOut$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.signOut),
      switchMap(() => {
        return this.userService.signOut(new userServiceContracts.SignOutRequest()).pipe(
          map(() => {
            location.reload();

            return actions.empty();
          })
        );
      })
    )
  );

  setMenuPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setMenuPinPreference),
      map((action) => action.payload),
      switchMap((payload) => {
        const req = new userServiceContracts.SetUserMenuPinRequest();
        req.menuPin = payload;
        return this.userService.setUserMenuPinPreference(req).pipe(
          map(() => {
            return actions.empty();
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    @Inject(userServiceContracts.USER_SERVICE_TOKEN)
    private userService: userServiceContracts.IUserService
  ) {}
}
