export const response = {
  message: 'OK',
  code: 200,
  data: [
    {
      vendorId: 1,
      description: null,
      vendorName: 'Empire CLS',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 106,
        },
        {
          markupPercent: '10',
          productId: 172,
        },
      ],
      vendorTypeId: 1,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
    {
      vendorId: 2,
      description: null,
      vendorName: 'MiRide',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 106,
        },
        {
          markupPercent: '10',
          productId: 172,
        },
      ],
      vendorTypeId: 1,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
    {
      vendorId: 3,
      description: null,
      vendorName: 'Drivania',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 106,
        },
        {
          markupPercent: '10',
          productId: 172,
        },
      ],
      vendorTypeId: 1,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
    {
      vendorId: 4,
      description: null,
      vendorName: 'Sky Elite',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 107,
        },
        {
          markupPercent: '10',
          productId: 173,
        },
      ],
      vendorTypeId: 2,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
    {
      vendorId: 5,
      description: null,
      vendorName: 'Silver Linings',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 107,
        },
        {
          markupPercent: '10',
          productId: 173,
        },
      ],
      vendorTypeId: 2,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
    {
      vendorId: 6,
      description: null,
      vendorName: 'Absolute Taste',
      markupPercent: '10',
      products: [
        {
          markupPercent: '10',
          productId: 107,
        },
        {
          markupPercent: '10',
          productId: 173,
        },
      ],
      vendorTypeId: 2,
      active: true,
      createTime: '2018-08-31T20:24:58.367332+00:00',
    },
  ],
};
