import * as actions from '../actions/categories-collection';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  categories: Array<models.AircraftCategory>;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  categories: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const categories = _.cloneDeep(action.payload.entities);

      return {
        categories,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: true,
      };
    }

    case actions.RESET: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getCategories = (state: State) => state.categories;
