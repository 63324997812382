export class Airport {
  areaId: string = null;
  areaName: string = null;
  code: string = null; // airportCode
  icaoCode: string = null;
  iataCode: string = null;
  faaCode: string = null;
  city: string = null;
  state: string = null;
  country: string = null;
  id: string = null;
}
