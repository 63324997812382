import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as actions from '../actions/api-status.actions';
import * as apiStatusContracts from '../services/api-status/contracts';

@Injectable()
export class ApiStatusEffects {
  constructor(
    private actions$: Actions,
    @Inject(apiStatusContracts.API_STATUS_SERVICE_TOKEN)
    private apiStatusService: apiStatusContracts.IApiStatusService
  ) {}

  getApiStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadApiStatus),
      mergeMap(({ payload }) => {
        return this.apiStatusService.getApiStatus(payload).pipe(
          map((svcResp) => {
            const successPayload = new actions.LoadApiStatusSuccessPayload();
            successPayload.apiName = payload;
            successPayload.status = svcResp;
            return actions.loadApiStatusSuccess({ payload: successPayload });
          }),
          catchError(() => of(actions.loadApiStatusFail()))
        );
      })
    )
  );
}
