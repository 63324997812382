import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales.PricingMeta] Load');
export class LoadSuccessPayload {
  meta: models.PricingMeta;
}
export const loadSuccess = createAction(
  '[Sales.PricingMeta] Load Success',
  props<{ payload: LoadSuccessPayload }>()
);
export const loadFail = createAction('[Sales.PricingMeta] Load Fail');

export const setInitial = createAction('[Sales.PricingMeta] Set initial');
