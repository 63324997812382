import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/rating.actions';
import * as models from '../../domain/models';

export const ratingFeatureKey = 'rating';

export interface State {
  loaded: boolean;
  loading: boolean;
  entity: models.Rating;
}

const initialState: State = {
  loaded: false,
  loading: false,
  entity: new models.Rating(),
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      entity: payload,
    })
  )
);
