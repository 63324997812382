import { AircraftModel } from './aircraft-model';
import { AircraftCategory } from './aircraft-category';
import { Operator } from './operator';

export class Aircraft {
  aircraftId: number;
  aircraftModel: AircraftModel;
  tailNumber: string;
  marketableSeatCount: number;
  maxPax: number;
  category: AircraftCategory = null;
  operator: Operator;

  getCategory(): AircraftCategory {
    return this.category;
  }

  setCategory(category: AircraftCategory): Aircraft {
    this.category = category;

    return this;
  }

  getAircraftModel(): AircraftModel {
    return this.aircraftModel;
  }

  setAircraftModel(aircraftModel: AircraftModel): Aircraft {
    this.aircraftModel = aircraftModel;

    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setSetOperator(operator: Operator): Aircraft {
    this.operator = operator;

    return this;
  }
}

