import { WyvernCertificate } from './wyvern-certificate';
import { WyvernRating } from './rating'

export class Wyvern {
  id: number
  name: string;
  phone: string;
  mobile: string;
  email: string;
  certificates: Array<WyvernCertificate>;
  wyvernRating: WyvernRating;

  getId(): number {
    return this.id;
  }

  setId(id: number): Wyvern {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Wyvern {
    this.name = name;

    return this;
  }

  getPhone(): string {
    return this.phone;
  }

  setPhone(phone: string): Wyvern {
    this.phone = phone;

    return this;
  }

  getMobile(): string {
    return this.mobile;
  }

  setMobile(mobile: string): Wyvern {
    this.mobile = mobile;

    return this;
  }

  getEmail(): string {
    return this.email;
  }

  setEmail(email: string): Wyvern {
    this.email = email;

    return this;
  }

  getCertificates(): Array<WyvernCertificate> {
    return this.certificates;
  }

  setCertificates(certificates: Array<WyvernCertificate>): Wyvern {
    this.certificates = certificates;

    return this;
  }

  getWyvernRating(): WyvernRating {
    return this.wyvernRating;
  }

  setWyvernRating(wyvernRating: WyvernRating): Wyvern {
    this.wyvernRating = wyvernRating;

    return this;
  }
}
