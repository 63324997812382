export class FinalizedLeg {
  private _jsLegId: string;

  get jsLegId(): string {
    return this._jsLegId;
  }

  set jsLegId(value: string) {
    this._jsLegId = value;
  }

  private _flightRequestId?: number;

  get flightRequestId(): number {
    return this._flightRequestId;
  }

  set flightRequestId(value: number) {
    this._flightRequestId = value;
  }

  private _fosLegStatus?: string;

  get fosLegStatus(): string {
    return this._fosLegStatus;
  }

  set fosLegStatus(value: string) {
    this._fosLegStatus = value;
  }

  private _departureAirportIcao?: string;

  get departureAirportIcao(): string {
    return this._departureAirportIcao;
  }

  set departureAirportIcao(value: string) {
    this._departureAirportIcao = value;
  }

  private _arrivalAirportIcao?: string;

  get arrivalAirportIcao(): string {
    return this._arrivalAirportIcao;
  }

  set arrivalAirportIcao(value: string) {
    this._arrivalAirportIcao = value;
  }

  private _departureDateLocal?: string;

  get departureDateLocal(): string {
    return this._departureDateLocal;
  }

  set departureDateLocal(value: string) {
    this._departureDateLocal = value;
  }

  private _arrivalDateLocal?: string;

  get arrivalDateLocal(): string {
    return this._arrivalDateLocal;
  }

  set arrivalDateLocal(value: string) {
    this._arrivalDateLocal = value;
  }

  private _blockTimeAct?: number;

  get blockTimeAct(): number {
    return this._blockTimeAct;
  }

  set blockTimeAct(value: number) {
    this._blockTimeAct = value;
  }

  private _paxCount?: number;

  get paxCount(): number {
    return this._paxCount;
  }

  set paxCount(value: number) {
    this._paxCount = value;
  }

  private _tailNumber?: string;

  get tailNumber(): string {
    return this._tailNumber;
  }

  set tailNumber(value: string) {
    this._tailNumber = value;
  }

  private _flightStatusId?: number;

  get flightStatusId(): number {
    return this._flightStatusId;
  }

  set flightStatusId(value: number) {
    this._flightStatusId = value;
  }
}
