import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as selectors from '../selectors';
import * as actions from '../actions/user.actions';

@Injectable({ providedIn: 'root' })
export class CurrentUserGuard implements CanActivate {
  constructor(private store$: Store<any>) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(select(selectors.getUserUser)).pipe(
      map((user) => {
        if (!user) {
          this.store$.dispatch(actions.load());
          return false;
        }
        return true;
      }),
      filter((user) => !!user)
    );
  }
}
