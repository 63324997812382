export const response = {
  sfdcOpportunityId: '0068A00000BbK0cQAF',
  clientId: 1706554,
  salesActionId: 1,
  productId: 77,
  faceValue: 2500,
  qtyOfPasses: 25,
  route: {
    from: 'KFLL',
    to: 'KTEB',
  },
  total: 20000,
  contractCommencementDate: '2022-04-26',
  warningMessage: '',
  errorMessage:
    'Contract Commencement Date cannot be in the past. Contract Commencement Date: 2022-04-26',
  warningPopupAlert: false,
  errorPopupAlert: false,
};
