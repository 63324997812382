import * as actions from '../actions/sent-emails';
import { Email } from '../../domain/models';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<Email>;
  emailBody: string;
  emailBodyLoaded: boolean;
  emailBodyLoading: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  emailBody: null,
  emailBodyLoaded: false,
  emailBodyLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_SENT_EMAILS: {
      return {...initialState, loading: true};
    }

    case actions.LOAD_SENT_EMAILS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        items: action.payload.emails,
      };
    }

    case actions.LOAD_SENT_EMAILS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    }

    case actions.LOAD_EMAIL_BODY: {
      return {
        ...state,
        emailBodyLoaded: false, 
        emailBodyLoading: true
      };
    }

    case actions.LOAD_EMAIL_BODY_SUCCESS: {
      return {
        ...state,
        emailBodyLoaded: true,
        emailBodyLoading: false,
        emailBody: action.payload.body,
      };
    }

    case actions.LOAD_EMAIL_BODY_FAILURE: {
      return {
        ...state,
        emailBodyLoaded: false,
        emailBodyLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getItems = (state: State) => state.items;
export const getEmailBody = (state: State) => state.emailBody;
export const getEmailBodyLoaded = (state: State) => state.emailBodyLoaded;
export const getEmailBodyLoading = (state: State) => state.emailBodyLoading;
