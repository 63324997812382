export enum OrderItemTypeEnum {
  Product = 1,
  Membership = 2,
  Adjustment = 3,
  LegacyAdjustment = 4,
  GroupMembership = 5,
  GroupMembershipSlot = 6,
  CustomTerm = 7,
  FlightCredit = 8,
  Concierge = 9,
  // FlightCreditIn = 10,
  Charter = 11,
  NewCharter = 12,
  ModifyCharter = 13,
  PromotionalCredit = 14,
  LoyaltyCredit = 15,
  FlightPass = 16,
  SFDCDepositDue = 17,
  AnnualMembershipFee = 18,
  CashCreditTransfer = 20,
  BonusCreditTransfer = 21,
}
