import { Action } from '@ngrx/store';

import * as models from '../../domain/models';

export const LOAD = '[RM Aircraft Categories Collection] Load';
export const LOAD_SUCCESS = '[RM Aircraft Categories Collection] Load Success';
export const LOAD_FAIL = '[RM Aircraft Categories Collection] Load Fail';
export const RESET = '[RM Aircraft Categories Collection] Reset';

export class LoadAircraftCategoriesPayload {
  operatorId?: string;
  isMarket?: boolean;
}

export class LoadAircraftCategoriesAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadAircraftCategoriesPayload) {}
}

export class LoadSuccessAircraftCategoriesPayload {
  entities: Array<models.AircraftCategory>;
}

export class LoadSuccessAircraftCategoriesAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessAircraftCategoriesPayload) {}
}

export class LoadFailAircraftCategoriesAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadAircraftCategoriesAction
  | LoadSuccessAircraftCategoriesAction
  | LoadFailAircraftCategoriesAction
  | ResetAction;
