import { IAirportCodeValidator } from './contracts';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class AirportCodeValidatorMock implements IAirportCodeValidator {
  validate(code: string): Observable<boolean> {
    return (code.indexOf('KFLL') === -1 ? of(false) : of(true)).pipe(delay(3000));
  }
}
