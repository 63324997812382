import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const BYPASS_LOAD = '[RM Vendor Detail] Bypass Load';
export const LOAD = '[RM Vendor Detail] Load';
export const LOAD_SUCCESS = '[RM Vendor Detail] Load Success';
export const LOAD_FAIL = '[RM Vendor Detail] Load Fail';
export const RESET = '[RM Vendor Detail] Reset';
export const SET = '[RM Vendor Detail] Set';
export const CANCEL = 'Rm Vendor Detail] Cancel';
export const SAVE = '[RM Vendor Detail] Save';
export const SAVE_SUCCESS = '[RM Vendor Detail] Save Success';
export const SAVE_FAIL = '[RM Vendor Detail] Save Fail';
export const TOGGLE_EDIT_MODE = '[RM Vendor Detail] Toggle Edit Mode';
export const VALIDATE = '[RM Vendor Detail] Validate';
export const VALIDATE_SUCCESS = '[RM Vendor Detail] Validate Success';
export const VALIDATE_FAIL = '[RM Vendor Detail] Validate Fail';
export const SET_VALIDATION_MESSAGE = '[RM Vendor Detail] Set Validation Message';

export class BypassAction implements Action {
  readonly type = BYPASS_LOAD;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetPayload {
  constructor(public item: models.Vendor) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class CancelAction implements Action {
  readonly type = CANCEL;
}

export class LoadPayload {
  constructor(public id: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Vendor) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.Vendor) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class ToggleEditModeAction implements Action {
  readonly type = TOGGLE_EDIT_MODE;
}

export class ValidatePayload {
  skip: boolean;
}

export class ValidateAction implements Action {
  readonly type = VALIDATE;

  constructor(public payload: ValidatePayload) {
  }
}

export class ValidateSuccessAction implements Action {
  readonly type = VALIDATE_SUCCESS;

  constructor(public payload: models.ValidationMessageData) {
  }
}

export class ValidateFailAction implements Action {
  readonly type = VALIDATE_FAIL;
}

export class SetValidationMessageAction implements Action {
  readonly type = SET_VALIDATION_MESSAGE;

  constructor(public payload: models.ValidationMessageData) {
  }
}

export type Actions =
  | BypassAction
  | ResetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetAction
  | CancelAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | ToggleEditModeAction
  | ValidateAction
  | ValidateSuccessAction
  | ValidateFailAction
  | SetValidationMessageAction;
