import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/error.actions';

export const errorOpportunityFeatureKey = 'errorOpportunity';

export interface State {
  error: string;
}

const initialState: State = {
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.showOpportunityError,
    (state, { error }): State => ({
      ...state,
      error,
    })
  )
);
