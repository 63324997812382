import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales.AircraftCategoriesCollection] Load');
export const loadSuccess = createAction(
  '[Sales.AircraftCategoriesCollection] Load Success',
  props<{ payload: Array<models.AircraftCategory> }>()
);
export const loadFail = createAction('[Sales.AircraftCategoriesCollection] Load Fail');

export const loadForCalc = createAction('[Sales.AircraftCategoriesCollection] Load For Calc');
export const loadForCalcSuccess = createAction(
  '[Sales.AircraftCategoriesCollection] Load For Calc Success',
  props<{ payload: Array<models.AircraftCategory> }>()
);
export const loadForCalcFail = createAction(
  '[Sales.AircraftCategoriesCollection] Load For Calc Fail'
);

export const setInitial = createAction('[Sales.AircraftCategoriesCollection] Set Initial');
