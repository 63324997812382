export const response = {
  client: {
    message: 'OK',
    code: 200,
    data: {
      phoneCallConsent: true,
      balance: '0',
      regSource: 'JS',
      name: 'JS Multi',
      dba: 'JS',
      referredByClient: null,
      isSelectableAsBeneficiary: true,
      isSelectedAsBeneficiary: false,
      accountRoleId: 1,
      accountClients: [
        {
          id: 996,
          name: 'Account Client 1',
          accountRoleId: 0,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 997,
          name: 'Account Client 2',
          accountRoleId: 1,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 998,
          name: 'Account Client 3',
          accountRoleId: 2,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 999,
          name: 'Account Client 4',
          accountRoleId: 3,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: false,
        },
      ],
      verificationStatus: 'Not submitted',
      account: null,
      clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
      email: '3@example.com',
      phone: '049',
      clientServices: [
        {
          startDate: '2018-09-17T04:00:00+00:00',
          totalPrice: '17500',
          endDate: '2019-09-17T04:00:00+00:00',
          promotionItemId: null,
          clientServiceCreateTime: '2018-09-18T02:31:40.408661+00:00',
          productTypeId: 3,
          isLegacy: false,
          orderItem: {
            promotionAmount: null,
            promotionItemId: null,
            totalPrice: '17500',
            orderItemId: 10524,
            basePrice: '15000',
          },
          clientServiceTypeId: 1,
          parentProductTypeId: 1,
          membershipTypeId: 2,
          orderId: 6258,
          productId: 3,
          prorateSuppressed: true,
          product: {
            description: null,
            previousProductId: null,
            basePrice: '15000',
            productTypeDescription: null,
            nextProductId: null,
            productTypeName: 'Smart',
            productTypeId: 3,
            isRefundable: true,
            productTypeParentProductTypeId: 1,
            active: true,
            parentProductId: null,
            productName: 'Smart $15,000',
            createTime: '2017-08-16T16:59:14.056627+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(
              2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(
              9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), \'[]\')`,
            productId: 3,
          },
          membershipActivateTime: '2018-09-18T02:31:40.408661+00:00',
          membershipSalesActionId: 1,
          clientServiceStatusId: 2,
          productName: 'Smart $15,000',
          beneficiaryPersonId: 3,
          discount: null,
          isReactivatable: false,
          isTerminatable: true,
          legacyMembershipPeriodId: 986010,
          orderItemId: 10524,
          promotionAmount: null,
          clientServiceId: 7544,
          membershipSalesActionName: 'New',
          clientId: 3,
          basePrice: '15000',
          membershipCreateTime: '2018-09-18T02:31:40.408661+00:00',
        },
        {
          startDate: '2018-09-18T04:00:00+00:00',
          totalPrice: '12950',
          endDate: '2019-09-18T04:00:00+00:00',
          promotionItemId: null,
          slotsCount: 4,
          clientServiceCreateTime: '2018-09-18T16:05:11.340102+00:00',
          productTypeId: 38,
          isLegacy: false,
          orderItem: {
            promotionAmount: null,
            promotionItemId: null,
            totalPrice: '12950',
            orderItemId: 10555,
            basePrice: '9950',
          },
          clientServiceTypeId: 3,
          parentProductTypeId: 34,
          membershipTypeId: null,
          orderId: 6274,
          productId: 64,
          prorateSuppressed: true,
          product: {
            description: null,
            previousProductId: null,
            basePrice: '9950',
            productTypeDescription: null,
            nextProductId: null,
            productTypeName: 'Family Group Membership',
            productTypeId: 38,
            isRefundable: true,
            productTypeParentProductTypeId: 34,
            active: true,
            parentProductId: null,
            productName: 'Family Group 12 Months',
            createTime: '2018-04-10T18:04:22.594543+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(
              2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(
                9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), \'[]\')`,
            productId: 64,
          },
          membershipActivateTime: '2018-09-18T16:08:20.770994+00:00',
          membershipSalesActionId: null,
          clientServiceStatusId: 7,
          productName: 'Family Group 12 Months',
          beneficiaryPersonId: 3,
          discount: null,
          isReactivatable: true,
          isTerminatable: false,
          legacyMembershipPeriodId: null,
          orderItemId: 10555,
          promotionAmount: null,
          clientServiceId: 7561,
          membershipSalesActionName: null,
          clientId: 3,
          basePrice: '9950',
          membershipCreateTime: null,
        },
        {
          startDate: '2018-09-18T04:00:00+00:00',
          totalPrice: '9950',
          endDate: '2019-09-18T04:00:00+00:00',
          promotionItemId: null,
          slotsCount: 4,
          clientServiceCreateTime: '2018-09-18T16:11:55.810219+00:00',
          productTypeId: 38,
          isLegacy: false,
          orderItem: {
            promotionAmount: null,
            promotionItemId: null,
            totalPrice: '9950',
            orderItemId: 10557,
            basePrice: '9950',
          },
          clientServiceTypeId: 3,
          parentProductTypeId: 34,
          membershipTypeId: null,
          orderId: 6275,
          productId: 64,
          prorateSuppressed: true,
          product: {
            description: null,
            previousProductId: null,
            basePrice: '9950',
            productTypeDescription: null,
            nextProductId: null,
            productTypeName: 'Family Group Membership',
            productTypeId: 38,
            isRefundable: true,
            productTypeParentProductTypeId: 34,
            active: true,
            parentProductId: null,
            productName: 'Family Group 12 Months',
            createTime: '2018-04-10T18:04:22.594543+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(
              2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(
              9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), \'[]\')`,
            productId: 64,
          },
          membershipActivateTime: '2018-09-18T16:11:55.810219+00:00',
          membershipSalesActionId: null,
          clientServiceStatusId: 2,
          productName: 'Family Group 12 Months',
          beneficiaryPersonId: 3,
          discount: null,
          isReactivatable: false,
          isTerminatable: false,
          legacyMembershipPeriodId: null,
          orderItemId: 10557,
          promotionAmount: null,
          clientServiceId: 7562,
          membershipSalesActionName: null,
          clientId: 3,
          basePrice: '9950',
          membershipCreateTime: null,
        },
        {
          startDate: '2019-09-17T04:00:00+00:00',
          totalPrice: '9950',
          endDate: '2020-12-17T04:00:00+00:00',
          promotionItemId: null,
          slotsCount: 4,
          clientServiceCreateTime: '2018-09-18T02:46:35.111087+00:00',
          productTypeId: 38,
          isLegacy: false,
          orderItem: {
            promotionAmount: null,
            promotionItemId: null,
            totalPrice: '9950',
            orderItemId: 10526,
            basePrice: '9950',
          },
          clientServiceTypeId: 3,
          parentProductTypeId: 34,
          membershipTypeId: null,
          orderId: 6259,
          productId: 103,
          prorateSuppressed: true,
          product: {
            description: null,
            previousProductId: null,
            basePrice: '9950',
            productTypeDescription: null,
            nextProductId: null,
            productTypeName: 'Family Group Membership',
            productTypeId: 38,
            isRefundable: true,
            productTypeParentProductTypeId: 34,
            active: true,
            parentProductId: null,
            productName: 'Family Group 15 Months',
            createTime: '2018-08-10T18:46:27.616083+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(2017, 8, 16, 16, 21, 2, 65428, tzinfo=psycopg2.tz.FixedOffsetTimezone(
              offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59, 999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(
                offset=0, name=None)), \'[]\')`,
            productId: 103,
          },
          membershipActivateTime: '2018-09-18T02:46:35.111087+00:00',
          membershipSalesActionId: null,
          clientServiceStatusId: 1,
          productName: 'Family Group 15 Months',
          beneficiaryPersonId: 3,
          discount: null,
          isReactivatable: false,
          isTerminatable: false,
          legacyMembershipPeriodId: null,
          orderItemId: 10526,
          promotionAmount: null,
          clientServiceId: 7545,
          membershipSalesActionName: null,
          clientId: 3,
          basePrice: '9950',
          membershipCreateTime: null,
        },
        {
          startDate: '2019-09-17T04:00:00+00:00',
          totalPrice: null,
          endDate: '2020-12-17T04:00:00+00:00',
          promotionItemId: null,
          clientServiceCreateTime: '2018-09-18T13:39:08.666093+00:00',
          productTypeId: 35,
          isLegacy: false,
          orderItem: {
            promotionAmount: null,
            promotionItemId: null,
            totalPrice: null,
            orderItemId: null,
            basePrice: null,
          },
          clientServiceTypeId: 1,
          parentProductTypeId: 1,
          membershipTypeId: 37,
          orderId: null,
          productId: 63,
          prorateSuppressed: true,
          product: {
            description: null,
            previousProductId: null,
            basePrice: '1950',
            productTypeDescription: null,
            nextProductId: null,
            productTypeName: 'Family Slot Membership',
            productTypeId: 35,
            isRefundable: false,
            productTypeParentProductTypeId: 1,
            active: true,
            parentProductId: null,
            productName: 'Family Slot',
            createTime: '2018-04-10T18:02:25.654136+00:00',
            lifetimeInterval: `DateTimeTZRange(datetime.datetime(2018, 4, 8, 0, 0, tzinfo=psycopg2.tz.
              FixedOffsetTimezone(offset=0, name=None)), datetime.datetime(9999, 12, 31, 23, 59, 59,
                999999, tzinfo=psycopg2.tz.FixedOffsetTimezone(offset=0, name=None)), \'[]\')`,
            productId: 63,
          },
          membershipActivateTime: '2018-09-18T13:39:08.666093+00:00',
          membershipSalesActionId: 2,
          clientServiceStatusId: 6,
          productName: 'Family Slot',
          beneficiaryPersonId: null,
          discount: null,
          isReactivatable: false,
          isTerminatable: false,
          legacyMembershipPeriodId: 986328,
          orderItemId: null,
          promotionAmount: null,
          clientServiceId: 7546,
          membershipSalesActionName: 'Renew',
          clientId: 3,
          basePrice: null,
          membershipCreateTime: '2018-09-18T13:39:08.666093+00:00',
        },
      ],
      brokerid: 0,
      dob: '1991-01-01',
      legalName: 'JS Multi',
      membershipRequests: [],
      isFree: false,
      gdpr: null,
      id: 3,
      emailSupportConsent: true,
      flightCreditsAmount: '2985',
    },
  },
  orders: {
    message: 'OK',
    code: 200,
    data: {
      totalItems: 4,
      items: [
        {
          canChangePaymentType: false,
          totalPrice: '9950',
          user: {
            phone: '954-555-5555',
            email: 'szhukov@jetsmarter.com',
            id: 233,
            firstName: 'Serge',
            lastName: 'Zhukov',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 233,
              legacyFormTplId: 348,
              invoiceTypeName: 'Main Invoice',
              uuid: '76f14ed6-1052-4aab-bd66-546e3fde9369',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '5e5d3f8d-2b4c-4caa-b4fc-bb447f31d32f',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 6275,
              invoiceId: 8719,
              createTime: '2018-09-18T16:11:36.533896+00:00',
              parentInvoiceId: null,
              orderItemId: 10557,
              legacyFormUrl:
                'http://console.vista.studio/forms/membership_nocc_terms/index.html?5e5d3f8d-2b4c-4caa-b4fc-bb447f31d32f',
              clientId: 3,
              invoiceTypeId: 1,
              amount: '9950',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 6275,
              parentOrderItem: null,
              totalPrice: '9950',
              promotionAmount: null,
              orderItemTypeId: 1,
              notes: null,
              membershipRequest: {
                orderItemId: 10557,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Family Group 12 Months',
                expirationTime: '2018-10-02T16:11:36.425127+00:00',
                expired: true,
                createTime: '2018-09-18T16:11:36.474494+00:00',
              },
              id: 10557,
              product: {
                productTypeId: 38,
                productId: 64,
                productName: 'Family Group 12 Months',
              },
              childOrderItems: [
                {
                  product: {
                    productId: 65,
                    productName: 'Family Group Initiation Fee',
                  },
                  totalPrice: '0',
                  orderItemId: 10558,
                  orderItemTypeId: 1,
                  beneficiaryPersonId: 3,
                  discount: {
                    typeId: 1,
                    name: 'July Initiation Fee Discount',
                    typeName: 'Discount',
                    amount: null,
                    percentage: '100',
                    id: 138,
                  },
                  client: {
                    futureMembershipTotal: null,
                    clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                    membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                    regSource: 'JS',
                    name: 'JS Multi',
                    futureMembershipTimeStarted: null,
                    dob: '1991-01-01',
                    membershipTotal: '17500',
                    membershipDisplayName: 'SMART',
                    membershipTypeId: 12,
                    futureMembershipTypeId: null,
                    phone: '049',
                    futureMembershipTimeExpiring: null,
                    email: '3@example.com',
                    brokerid: 0,
                    membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                    dba: 'JS',
                    legalName: 'JS Multi',
                    personalManager: 1336112,
                    futureMembershipDisplayName: null,
                    id: 3,
                  },
                  basePrice: '3000',
                  productFeatureId: null,
                  quantity: 1,
                },
              ],
              membership: {
                startDate: '2018-09-18T04:00:00+00:00',
                activateTime: '2018-09-18T16:11:55.810219+00:00',
                salesAction: {
                  name: null,
                  id: null,
                },
                typeName: null,
                endDate: '2019-09-18T04:00:00+00:00',
                id: 7562,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              basePrice: '9950',
              conciergeInfo: null,
              quantity: 1,
              promotionItemId: null,
              charterInfo: null,
            },
          ],
          notes: null,
          id: 6275,
          orderStatusId: 5,
          partyId: 3,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
              membershipTimeStarted: '2018-09-17T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Multi',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '17500',
              membershipDisplayName: 'SMART',
              membershipTypeId: 12,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: '3@example.com',
              brokerid: 0,
              membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Multi',
              personalManager: 1336112,
              futureMembershipDisplayName: null,
              id: 3,
            },
          },
          expirationTime: null,
          promotionId: null,
          expired: false,
          createTime: '2018-09-18T16:11:36.461526+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '12950',
          user: {
            phone: '954-555-5555',
            email: 'szhukov@jetsmarter.com',
            id: 233,
            firstName: 'Serge',
            lastName: 'Zhukov',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 233,
              legacyFormTplId: 348,
              invoiceTypeName: 'Main Invoice',
              uuid: '7ed8d10a-33e6-4386-ad9b-5ee9af07f6fa',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '9009c8f1-f028-48f5-8acc-533e9653c957',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 6274,
              invoiceId: 8718,
              createTime: '2018-09-18T16:04:36.817247+00:00',
              parentInvoiceId: null,
              orderItemId: 10555,
              legacyFormUrl:
                'http://console.vista.studio/forms/membership_nocc_terms/index.html?9009c8f1-f028-48f5-8acc-533e9653c957',
              clientId: 3,
              invoiceTypeId: 1,
              amount: '12950',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 6274,
              parentOrderItem: null,
              totalPrice: '12950',
              promotionAmount: null,
              orderItemTypeId: 1,
              notes: null,
              membershipRequest: {
                orderItemId: 10555,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Family Group 12 Months',
                expirationTime: '2018-10-02T16:04:36.714031+00:00',
                expired: true,
                createTime: '2018-09-18T16:04:36.759975+00:00',
              },
              id: 10555,
              product: {
                productTypeId: 38,
                productId: 64,
                productName: 'Family Group 12 Months',
              },
              childOrderItems: [
                {
                  product: {
                    productId: 65,
                    productName: 'Family Group Initiation Fee',
                  },
                  totalPrice: '3000',
                  orderItemId: 10556,
                  orderItemTypeId: 1,
                  beneficiaryPersonId: 3,
                  client: {
                    futureMembershipTotal: null,
                    clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                    membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                    regSource: 'JS',
                    name: 'JS Multi',
                    futureMembershipTimeStarted: null,
                    dob: '1991-01-01',
                    membershipTotal: '17500',
                    membershipDisplayName: 'SMART',
                    membershipTypeId: 12,
                    futureMembershipTypeId: null,
                    phone: '049',
                    futureMembershipTimeExpiring: null,
                    email: '3@example.com',
                    brokerid: 0,
                    membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                    dba: 'JS',
                    legalName: 'JS Multi',
                    personalManager: 1336112,
                    futureMembershipDisplayName: null,
                    id: 3,
                  },
                  basePrice: '3000',
                  productFeatureId: null,
                  quantity: 1,
                },
              ],
              membership: {
                startDate: '2018-09-18T04:00:00+00:00',
                activateTime: '2018-09-18T16:05:11.340102+00:00',
                salesAction: {
                  name: null,
                  id: null,
                },
                typeName: null,
                endDate: '2019-09-18T04:00:00+00:00',
                id: 7561,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              basePrice: '9950',
              conciergeInfo: null,
              quantity: 1,
              promotionItemId: null,
              charterInfo: null,
            },
          ],
          notes: null,
          id: 6274,
          orderStatusId: 7,
          partyId: 3,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
              membershipTimeStarted: '2018-09-17T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Multi',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '17500',
              membershipDisplayName: 'SMART',
              membershipTypeId: 12,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: '3@example.com',
              brokerid: 0,
              membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Multi',
              personalManager: 1336112,
              futureMembershipDisplayName: null,
              id: 3,
            },
          },
          expirationTime: null,
          promotionId: null,
          expired: false,
          createTime: '2018-09-18T16:04:36.746270+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '9950',
          user: {
            phone: '954-555-5555',
            email: 'szhukov@jetsmarter.com',
            id: 233,
            firstName: 'Serge',
            lastName: 'Zhukov',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 233,
              legacyFormTplId: 333,
              invoiceTypeName: 'Main Invoice',
              uuid: '8549b4eb-e93b-4a5d-b57d-c92c1548e09e',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '2a4c2efb-6a95-4b5e-9f2d-1e88750c8f93',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 6259,
              invoiceId: 8700,
              createTime: '2018-09-18T02:46:10.921083+00:00',
              parentInvoiceId: null,
              orderItemId: 10526,
              legacyFormUrl:
                'http://console.vista.studio/forms/membership_terms_receipt/index.html?2a4c2efb-6a95-4b5e-9f2d-1e88750c8f93',
              clientId: 3,
              invoiceTypeId: 1,
              amount: '9950',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 6259,
              parentOrderItem: null,
              totalPrice: '9950',
              promotionAmount: null,
              orderItemTypeId: 1,
              notes: null,
              membershipRequest: {
                orderItemId: 10526,
                salesAction: {
                  name: 'Renew',
                  id: 2,
                },
                productName: 'Family Group 15 Months',
                expirationTime: '2018-10-02T02:46:10.817215+00:00',
                expired: true,
                createTime: '2018-09-18T02:46:10.861667+00:00',
              },
              id: 10526,
              product: {
                productTypeId: 38,
                productId: 103,
                productName: 'Family Group 15 Months',
              },
              childOrderItems: [
                {
                  product: {
                    productId: 113,
                    productName: '2,985 Flight Credits',
                  },
                  totalPrice: '0',
                  orderItemId: 10527,
                  orderItemTypeId: 1,
                  beneficiaryPersonId: 3,
                  client: {
                    futureMembershipTotal: null,
                    clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                    membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                    regSource: 'JS',
                    name: 'JS Multi',
                    futureMembershipTimeStarted: null,
                    dob: '1991-01-01',
                    membershipTotal: '17500',
                    membershipDisplayName: 'SMART',
                    membershipTypeId: 12,
                    futureMembershipTypeId: null,
                    phone: '049',
                    futureMembershipTimeExpiring: null,
                    email: '3@example.com',
                    brokerid: 0,
                    membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                    dba: 'JS',
                    legalName: 'JS Multi',
                    personalManager: 1336112,
                    futureMembershipDisplayName: null,
                    id: 3,
                  },
                  basePrice: '0',
                  productFeatureId: null,
                  quantity: 1,
                },
              ],
              membership: {
                startDate: '2019-09-17T04:00:00+00:00',
                activateTime: '2018-09-18T02:46:35.111087+00:00',
                salesAction: {
                  name: null,
                  id: null,
                },
                typeName: null,
                endDate: '2020-12-17T04:00:00+00:00',
                id: 7545,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              basePrice: '9950',
              conciergeInfo: null,
              quantity: 1,
              promotionItemId: null,
              charterInfo: null,
            },
          ],
          notes: null,
          id: 6259,
          orderStatusId: 5,
          partyId: 3,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
              membershipTimeStarted: '2018-09-17T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Multi',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '17500',
              membershipDisplayName: 'SMART',
              membershipTypeId: 12,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: '3@example.com',
              brokerid: 0,
              membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Multi',
              personalManager: 1336112,
              futureMembershipDisplayName: null,
              id: 3,
            },
          },
          expirationTime: null,
          promotionId: null,
          expired: false,
          createTime: '2018-09-18T02:46:10.848230+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '17500',
          user: {
            phone: '954-555-5555',
            email: 'szhukov@jetsmarter.com',
            id: 233,
            firstName: 'Serge',
            lastName: 'Zhukov',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 233,
              legacyFormTplId: 348,
              invoiceTypeName: 'Main Invoice',
              uuid: '2da5d5a9-bb35-4c84-90b5-8e99eac21595',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'c0f69116-7a65-4e0a-b373-33c66ca39186',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 6258,
              invoiceId: 8699,
              createTime: '2018-09-18T02:31:23.776919+00:00',
              parentInvoiceId: null,
              orderItemId: 10524,
              legacyFormUrl:
                'http://console.vista.studio/forms/membership_nocc_terms/index.html?c0f69116-7a65-4e0a-b373-33c66ca39186',
              clientId: 3,
              invoiceTypeId: 1,
              amount: '17500',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 6258,
              parentOrderItem: null,
              totalPrice: '17500',
              promotionAmount: null,
              orderItemTypeId: 2,
              notes: null,
              membershipRequest: {
                orderItemId: 10524,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Smart $15,000',
                expirationTime: '2018-09-25T02:31:23.675283+00:00',
                expired: true,
                createTime: '2018-09-18T02:31:23.718027+00:00',
              },
              id: 10524,
              product: {
                productTypeId: 3,
                productId: 3,
                productName: 'Smart $15,000',
              },
              childOrderItems: [
                {
                  product: {
                    productId: 50,
                    productName: 'Initiation Fee (Smart)',
                  },
                  totalPrice: '2500',
                  orderItemId: 10525,
                  orderItemTypeId: 1,
                  beneficiaryPersonId: 3,
                  client: {
                    futureMembershipTotal: null,
                    clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                    membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                    regSource: 'JS',
                    name: 'JS Multi',
                    futureMembershipTimeStarted: null,
                    dob: '1991-01-01',
                    membershipTotal: '17500',
                    membershipDisplayName: 'SMART',
                    membershipTypeId: 12,
                    futureMembershipTypeId: null,
                    phone: '049',
                    futureMembershipTimeExpiring: null,
                    email: '3@example.com',
                    brokerid: 0,
                    membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                    dba: 'JS',
                    legalName: 'JS Multi',
                    personalManager: 1336112,
                    futureMembershipDisplayName: null,
                    id: 3,
                  },
                  basePrice: '2500',
                  productFeatureId: null,
                  quantity: 1,
                },
              ],
              membership: {
                startDate: '2018-09-17T04:00:00+00:00',
                activateTime: '2018-09-18T02:31:40.408661+00:00',
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                typeName: 'Smart',
                endDate: '2019-09-17T04:00:00+00:00',
                id: 7544,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
                membershipTimeStarted: '2018-09-17T04:00:00+00:00',
                regSource: 'JS',
                name: 'JS Multi',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: '17500',
                membershipDisplayName: 'SMART',
                membershipTypeId: 12,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: '3@example.com',
                brokerid: 0,
                membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
                dba: 'JS',
                legalName: 'JS Multi',
                personalManager: 1336112,
                futureMembershipDisplayName: null,
                id: 3,
              },
              basePrice: '15000',
              conciergeInfo: null,
              quantity: 1,
              promotionItemId: null,
              charterInfo: null,
            },
          ],
          notes: null,
          id: 6258,
          orderStatusId: 5,
          partyId: 3,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2018-08-08T02:24:13.876922+00:00',
              membershipTimeStarted: '2018-09-17T04:00:00+00:00',
              regSource: 'JS',
              name: 'JS Multi',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: '17500',
              membershipDisplayName: 'SMART',
              membershipTypeId: 12,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: '3@example.com',
              brokerid: 0,
              membershipTimeExpiring: '2019-09-17T04:00:00+00:00',
              dba: 'JS',
              legalName: 'JS Multi',
              personalManager: 1336112,
              futureMembershipDisplayName: null,
              id: 3,
            },
          },
          expirationTime: '2018-09-25T02:31:23.675283+00:00',
          promotionId: null,
          expired: true,
          createTime: '2018-09-18T02:31:23.703809+00:00',
          orderSourceId: 1,
        },
      ],
    },
  },
};
