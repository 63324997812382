export enum PRICING_TYPES {
  FIXED,
  TOGGLE,
}

export enum OVERRIDE_TYPES {
  SINGLE,
  RANGE
}

