import * as moment from 'moment';
import * as _ from 'lodash';
import * as models from '../models';

export const sortTasks = (
  sortBy: models.SortByEnum,
  sortOrder: models.SortOrderEnum,
  tasks: Array<models.Task>
): Array<models.Task> => {
  return [
    ..._.cloneDeep(tasks).sort((a: models.Task, b: models.Task) => {
      const { sortA, sortB } = getSortData(sortBy, a, b);
      let result;
      if (sortBy === models.SortByEnum.departureDateTime || sortBy === models.SortByEnum.dueDate) {
        result = sortDate(sortA, sortB);
      } else {
        result = sortNotDate(sortA, sortB);
      }
      return result * (sortOrder === models.SortOrderEnum.asc ? 1 : -1);
    }),
  ];
};

const sortNotDate = (sortA: string | number | Date, sortB: string | number | Date): number => {
  if (sortA === null) {
    return 0;
  }
  return sortA === sortB ? 0 : sortA > sortB ? 1 : -1;
};

const sortDate = (sortA: string | number | Date, sortB: string | number | Date): number =>
  moment(sortA).isSame(sortB) ? 0 : moment(sortB).isBefore(sortA) ? 1 : -1;

const getSortData = (
  sortBy: models.SortByEnum,
  a: models.Task,
  b: models.Task
): {
  sortA: string | number | Date;
  sortB: string | number | Date;
} => {
  let sortA, sortB;
  switch (sortBy) {
    case models.SortByEnum.assignedUser:
      sortA = a.assignedUser.lastName;
      sortB = b.assignedUser.lastName;
      break;
    case models.SortByEnum.fosTripId:
      sortA = a.leg.fosTripId;
      sortB = b.leg.fosTripId;
      break;
    case models.SortByEnum.source:
      sortA = a.leg.source;
      sortB = b.leg.source;
      break;
    case models.SortByEnum.airportCode:
      sortA = a.leg.departureAirportCode;
      sortB = b.leg.departureAirportCode;
      break;
    case models.SortByEnum.departureDateTime:
      sortA = a.leg.departureDateTime;
      sortB = b.leg.departureDateTime;
      break;
    case models.SortByEnum.title:
      sortA = a.title;
      sortB = b.title;
      break;
    case models.SortByEnum.dueDate:
      sortA = a.dueDate;
      sortB = b.dueDate;
      break;
    case models.SortByEnum.state:
      sortA = a.state.displayName;
      sortB = b.state.displayName;
      break;
    default:
      sortA = null;
      sortB = null;
  }
  return { sortA, sortB };
};
