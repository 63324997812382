import { ActualTimeSourceEnum } from './actual-time-source-enum';
import { TaskPropertyEnum } from './task-property-enum';

export class TaskAdditionalInfo {
  actualTimeReadOnly: boolean;
  taskId: number;
  actualTimeUpdated: Date;
  informationSourceName: string;
  valueDate: Date;
  informationSourceId: ActualTimeSourceEnum;
  taskPropertyId: number;
  taskPropertyName: TaskPropertyEnum;
  valueString: string;
}
