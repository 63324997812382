export enum Dba {
  JS = 'JS',
  XO = 'XO',
  RWA = 'RWA',
  XOI = 'XOI',
  XOA = 'XOA',
}

export const DbaName = {
  JS : 'JetSmarter',
  XO : 'XOJet',
  RWA : 'Red Wing Aviation',
  XOI : 'XOI',
  XOA : 'XOJetAviation',
}

export const getBroker = (dba: Dba): boolean =>
  dba === Dba.XOA || dba === Dba.RWA || dba === Dba.XOI;
