import { LegCost } from '../models/leg-cost';

export const sortLegCostsByLegIdThenId = (legCostA: LegCost, legCostB: LegCost): number => {
  if (legCostA.legId > legCostB.legId) {
    return 1;
  } else if (legCostA.legId < legCostB.legId) {
    return -1;
  } else {
    if (legCostA.id > legCostB.id) {
      return 1;
    }
    if (legCostA.id < legCostB.id) {
      return -1;
    }
  }
  return 0;
};
