import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface IClientService {
  getClient(request: GetClientRequest): Observable<GetClientResponse>;

  getClientWithoutOrder(
    request: GetClientWithoutOrderRequest
  ): Observable<GetClientWithoutOrderResponse>;

  searchClients(request: SearchClientsRequest): Observable<SearchClientsResponse>;

  searchClientsByName(request: SearchClientsByNameRequest): Observable<SearchClientsResponse>;

  searchAccountsByName(
    request: SearchAccountsByNameRequest
  ): Observable<SearchAccountsByNameResponse>;

  getFlightRequests(request: GetFlightRequestsRequest): Observable<GetFlightRequestsResponse>;

  getConciergeAncillaryData(
    request: GetConciergeAncillaryDataRequest
  ): Observable<GetConciergeAncillaryDataResponse>;

  getCharterRequests(request: GetCharterRequestsRequest): Observable<GetCharterRequestsResponse>;

  createCharterInvoice(
    reuest: CreateCharterInvoiceRequest
  ): Observable<CreateCharterInvoiceResponse>;

  removeCharterInvoice(
    request: RemoveCharterInvoiceRequest
  ): Observable<RemoveCharterInvoiceResponse>;

  loadSfdcOpportunity(request: LoadSfdcOpportunityRequest): Observable<LoadSfdcOpportunityResponse>;

  loadSfdcOpportunityCancel(request: LoadSfdcOpportunityRequest): Observable<LoadSfdcOpportunityCancelResponse>;

  loadSfdcOpportunityFlightPass(request: loadSfdcOpportunityFlightPassRequest): Observable<loadSfdcOpportunityFlightPassResponse>;

  loadSfdcOpportunityCreditMemo(request: loadSfdcOpportunityCreditMemoRequest): Observable<loadSfdcOpportunityCreditMemoResponse>;

  loadSimplifiedMembership(request: number): Observable<LoadSimplifiedMembershipResponse>;

}

export const CLIENT_SERVICE_TOKEN = new InjectionToken('Order.IClientService');

export class LoadSfdcOpportunityRequest {
  id: string;
}

export class LoadSfdcOpportunityResponse {
  entity: models.SfdcOpportunityData;
}

export class LoadSfdcOpportunityCancelResponse {
  entity: models.SfdcOpportunityCancelData;
}

export class loadSfdcOpportunityFlightPassRequest {
  id: string;
}

export class loadSfdcOpportunityFlightPassResponse {
  entity: models.SfdcOpportunityFlightPassData;
}

export class loadSfdcOpportunityCreditMemoRequest {
  id: string;
}

export class loadSfdcOpportunityCreditMemoResponse {
  entity: models.SfdcOpportunityCreditMemoData;
}

export class GetClientWithoutOrderRequest {
  clientId: number;
}

export class GetClientWithoutOrderResponse {
  entity: models.Client;
}

export class GetClientRequest {
  clientId: number;
}

export class GetClientResponse {
  entity: models.Client;
}

export class SearchClientsRequest {
  clientId?: number;
  name: string;
  dateOfBirth: Date;
  email: string;
  limit: number;
  phone: string;
  offset: number;
}

export class SearchClientsByNameRequest {
  clientName: string;
}

export class SearchClientsResponse {
  entities: Array<models.Client> = [];
  count: number;
}

export class SearchAccountsByNameRequest {
  accountName: string;
}

export class SearchAccountsByNameResponse {
  entities: Array<any> = [];
}

export class GetFlightRequestsRequest {
  clientId: number;
}

export class GetFlightRequestsResponse {
  entities: Array<models.ClientRequest>;
}

export class GetConciergeAncillaryDataRequest {
  clientId: number;
}

export class GetConciergeAncillaryDataResponse {
  entity: models.ConciergeFlightAncillaryData;
}

export class GetCharterRequestsRequest {
  clientId: number;
}

export class GetCharterRequestsResponse {
  entities: Array<models.CharterClientRequest>;
}

export class CreateCharterInvoiceRequest {
  requestId: number;
  charterInvoiceType: models.CharterInvoiceTypeEnum;
  proformaAdditionalInvoiceOption?: models.ProformaAdditionalInvoiceOptionsEnum;
}

export class CreateCharterInvoiceResponse {}

export class RemoveCharterInvoiceRequest {
  formUrlKey: string;
}

export class RemoveCharterInvoiceResponse {}

export class LoadSimplifiedMembershipResponse {
  simplifiedMembership: models.SimplifiedMembershipEnum;
}
