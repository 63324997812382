/* eslint-disable complexity */
import * as actions from '../actions/seat-pricing-collection';
import * as models from '../../domain/models';
import * as viewModel from '../../view/view-models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  failedToLoad: boolean;
  metaIsLoaded: boolean;
  items: Array<models.RuleData>;
  isRuleLoading: boolean;
  isRuleLoaded: boolean;
  ruleFailedToLoad: boolean;
  rule: models.RuleData;
  metaData: models.MetaData;
  lookupCriteria: viewModel.RouteLookup;
  matrixData: models.MatrixData;
  matrixDataIsLoading: boolean;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  failedToLoad: false,
  metaIsLoaded: false,
  items: new Array<models.RuleData>(),
  isRuleLoading: false,
  isRuleLoaded: false,
  ruleFailedToLoad: false,
  rule: null,
  metaData: null,
  lookupCriteria: new viewModel.RouteLookup(),
  matrixData: null,
  matrixDataIsLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        failedToLoad: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      const items = _.cloneDeep(action.payload.entities);

      return {
        ...state,
        items,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        failedToLoad: true,
      };
    }

    case actions.LOAD_RULE: {
      return {
        ...state,
        isRuleLoaded: false,
        isRuleLoading: true,
      };
    }

    case actions.LOAD_RULE_SUCCESS: {
      const { rule } = action.payload;
      return {
        ...state,
        rule,
        isRuleLoaded: true,
        isRuleLoading: false,
      };
    }

    case actions.LOAD_RULE_FAIL: {
      return {
        ...state,
        rule: null,
        isRuleLoaded: true,
        isRuleLoading: false,
      };
    }

    case actions.LOAD_META_DATA: {
      return {
        ...state,
        metaIsLoaded: false,
        failedToLoad: false
      };
    }

    case actions.LOAD_META_DATA_SUCCESS: {
      const metaData = _.cloneDeep(action.payload.entity);

      return {
        ...state,
        metaData,
        metaIsLoaded: true,
      };
    }

    case actions.LOAD_MATRIX: {
      return {
        ...state,
        matrixData: null,
        matrixDataIsLoading: true,
      };
    }

    case actions.LOAD_MATRIX_SUCCESS: {
      const matrixData = _.cloneDeep(action.payload.entity);

      return {
        ...state,
        matrixData,
        matrixDataIsLoading: false,
      };
    }

    case actions.LOAD_MATRIX_FAIL: {
      return {
        ...state,
        matrixDataIsLoading: false,
      };
    }

    case actions.SET_MATRIX: {
      const { matrix } = action.payload;
      return {
        ...state,
        matrixData: matrix
      };
    }

    case actions.SET: {
      const rule = _.cloneDeep(action.payload.entity);

      const items = _.cloneDeep(state.items.map((i: models.RuleData) => (i.id === rule.id) ? rule : i));

      return {
        ...state,
        matrixData: null,
        items,
      };
    }

    case actions.ADD: {
      const items = _.cloneDeep(
        state.items.map((i) => {
          const i2 = _.cloneDeep(i);
          i2.id = i2.id + 1;

          return i2;
        })
      );

      items.unshift(new viewModel.SeatPricingRuleWrapper({}, 0));

      return {
        ...state,
        lookupCriteria: new viewModel.RouteLookup(),
        items,
      };
    }

    case actions.REMOVE: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.REMOVE_SUCCESS: {
      const items = _.cloneDeep(state.items.filter((i: models.RuleData) => i.id !== action.payload.id));

      return {
        ...state,
        items,
        isLoading: false
      };
    }

    case actions.REMOVE_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }

    case actions.SAVE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }
    case actions.SAVE_SUCCESS: {
      const rule = _.cloneDeep(action.payload.entity);

      const isLoading = state.metaData === null;

      return {
        ...state,
        rule,
        isLoaded: true,
        isLoading,
      };
    }

    case actions.SAVE_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.CREATE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true
      };
    }
    case actions.CREATE_SUCCESS: {
      const rule = _.cloneDeep(action.payload.entity);
      const isLoading = state.metaData === null;

      return {
        ...state,
        rule,
        isLoaded: true,
        isLoading
      };
    }

    case actions.CREATE_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false
      };
    }

    case actions.SET_LOOKUP_CRITERIA: {
      const lookupCriteria = _.cloneDeep(action.payload.entity);

      return {
        ...state,
        lookupCriteria,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getFailedToLoad = (state: State) => state.failedToLoad;
export const getItems = (state: State) => state.items;
export const getMetaData = (state: State) => state.metaData;
export const getMetaDataIsLoaded = (state: State) => state.metaIsLoaded;
export const getLookupCriteria = (state: State) => state.lookupCriteria;
export const getMatrixData = (state: State) => state.matrixData;
export const getMatrixDataIsLoading = (state: State) => state.matrixDataIsLoading;
export const getIsRuleLoaded = (state: State) => state.isRuleLoaded;
export const getIsRuleLoading = (state: State) => state.isRuleLoading;
export const getRuleFailedToLoad = (state: State) => state.ruleFailedToLoad;
export const getRule = (state: State) => state.rule;
