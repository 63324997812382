import * as actions from '../actions/operator-documents';

import * as _ from 'lodash';
import { OperatorDocument } from '../../domain/models';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<OperatorDocument>;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        items: _.cloneDeep(action.payload.items),
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
        isLoaded: true,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
