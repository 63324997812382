import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Group Collection] Load';
export const LOAD_SUCCESS = '[RM Group Collection] Load Success';
export const LOAD_FAIL = '[RM Group Collection] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  items: Array<models.UserGroup>;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
