export const responseSfdcOpportunityCancelWithOrder = {
  sfdcOpportunityId: '0068A00000C13WaQAJ',
  orderId: 30341, // UI goes to this order page and starts cancellation
  clientId: 1706556,
  warningMessage: '',
  warningPopupAlert: false,
  errorMessage: '',
  errorPopupAlert: false,
};

export const responseSfdcOpportunityCancelWithoutOrder = {
  sfdcOpportunityId: '0068A00000C13WaQAJ',
  orderId: null, // UI goes to the client profile page
  clientId: 1706573,
  warningMessage:
    'Membership Order was not found by SFDC Opportunity ID 0068A00000C13WaQAJ. Please cancel the appropriate membership on this page.',
  warningPopupAlert: true,
  errorMessage: '',
  errorPopupAlert: false,
};
