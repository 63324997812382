import { Leg } from './leg';

export class OpportunityRmjsDto {
  clientId: number;
  notes: string;
  purchaseAmount: number;
  salesAmount: number;
  tailNumber: string;
  legs: Array<Leg>;
  invoiceNotes: string;
  flexDeparture: boolean;
  xoRwaFlightRequestId?: number;
}
