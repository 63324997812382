import { SeatPricingRuleWrapper } from '../../../view/view-models';
import * as contracts from '../../../domain/service-contracts/seat-pricing-mgmt';
import * as models from '../../../domain/models';
import * as feeModels from '../../../domain/models/configurations';
import * as moment from 'moment';

import { SeatPricingRuleDto } from './dtos/seat-pricing-rule-dto';
import { RouteGroupRuleDto } from './dtos/route-group-rule-dto';
import { MatrixRuleDto } from './dtos/matrix-rule-dto';
import { MatrixLegDto } from './dtos/matrix-leg-dto';

import * as _ from 'lodash';

export class SeatPricingMgmtMapperService {
  static convertRulesToDto(rules: Array<SeatPricingRuleWrapper>): Array<RouteGroupRuleDto> {
    let serializedArr = new Array<RouteGroupRuleDto>();

    serializedArr = rules.map((rule) => {
      const rs = new RouteGroupRuleDto();

      rs.routeGroupNames = rule.routeGroupNames;
      rs.aircraftCategories = rule.aircraftCategories;
      rs.shuttleTypeNames = rule.shuttleTypeNames;
      rs.dow = rule.dow.length ? rule.dow : [];
      rs.totalSeats = rule.totalSeats;

      if (rule.seat) {
        rs.seat = this.convertSeatPricingRuleToDto(rule.seat);
      }

      return rs;
    });

    return serializedArr;
  }

  static convertSeatPricingRuleToDto(fr: models.SeatPricingRule): SeatPricingRuleDto {
    const seatPricingRuleSerialized = new SeatPricingRuleDto();

    if (
      fr.timeIntervals.length > 1 ||
      (fr.timeIntervals.length === 1 &&
        (fr.timeIntervals[0].start !== 0 || fr.timeIntervals[0].end !== 180))
    ) {
      seatPricingRuleSerialized.timeIntervals = fr.timeIntervals.map((ti) => {
        if (ti.start === null || ti.end === null) {
          throw new Error('Time Interval value can not be Null');
        }
        const arr = [];
        arr.push(ti.start);
        arr.push(ti.end);

        return arr;
      });
    }

    return seatPricingRuleSerialized;
  }

  static convertRuleToMatrixRequestDto(
    rule: SeatPricingRuleWrapper,
    isFirstSeat: boolean
  ): MatrixRuleDto {
    const matrixDto = new MatrixRuleDto();

    matrixDto.totalSeats = rule.totalSeats;

    let targetRule: models.SeatPricingRule = null;
    targetRule = rule.seat;

    if (
      targetRule.timeIntervals.length > 1 ||
      (targetRule.timeIntervals.length === 1 &&
        (targetRule.timeIntervals[0].start !== 0 || targetRule.timeIntervals[0].end !== 180))
    ) {
      matrixDto.timeIntervals = targetRule.timeIntervals.map((ti) => {
        if (ti.start === null || ti.end === null) {
          throw new Error('Time Interval value can not be Null');
        }
        const arr = [];
        arr.push(ti.start);
        arr.push(ti.end);

        return arr;
      });
    }

    return matrixDto;
  }

  static convertLegToMatrixRequestDto(info: contracts.GetLegMatrixValueDataRequest): MatrixLegDto {
    const matrixDto = new MatrixLegDto();

    matrixDto.routeGroupFrom = info.routeGroupFrom;
    matrixDto.routeGroupTo = info.routeGroupTo;
    matrixDto.legId = info.legId;
    matrixDto.aircraftCategory = info.aircraftCategory.getName();
    matrixDto.shuttleTypeName = this.mapShuttleTypeDto(info.shuttleTypeName);
    matrixDto.departureDate = moment(info.departureDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    return matrixDto;
  }

  static mapShuttleTypeDto(type: models.LegSource): string {
    switch (type) {
      case models.LegSource.CustomShuttle:
        return 'customshuttle';
      case models.LegSource.Shuttle:
        return 'jetshuttle';
      case models.LegSource.CrowdFunded:
        return 'crowdfunded';
      case models.LegSource.PrivateCharter:
        return 'privatecharter';
      default:
        throw new Error('Requestor Type not recognized');
    }
  }

  static parseRuleData(item: any): models.RuleData {
    const r = new models.RuleData();
    r.aircraftCategories = _.get(item, 'aircraftCategories', null);
    r.dow = _.get(item, 'dow', null);
    r.id = _.get(item, 'id', null);
    r.pricesOnInterval = _.get(item, 'pricesOnInterval', null)
      ? _.get(item, 'pricesOnInterval', []).map((el) => this.parsePricesOnInterval(el))
      : null;
    r.routeGroupNames = _.get(item, 'routeGroupNames', null);
    r.shuttleTypeNames = _.get(item, 'shuttleTypeNames', null);
    r.totalSeats = _.get(item, 'totalSeats', null);
    r.version = _.get(item, 'version', null);
    return r;
  }

  static parseAirportFeeArrayData(items: Array<any> = []): Array<feeModels.AirportFee> {
    return items.map(i => SeatPricingMgmtMapperService.parseAirportFeeData(i));
  }

  static parseDepTimeFeeArrayData(items: Array<any> = []): Array<feeModels.DepartureTimeFee> {
    return items.map(i => SeatPricingMgmtMapperService.parseDepTimeFeeData(i));
  }

  static parseDepTimeFeeData(item: any = {}): feeModels.DepartureTimeFee {
    const p = new feeModels.DepartureTimeFee();
    p.id = _.get(item, 'id', null);
    p.groupId = _.get(item, 'groupId', null);
    p.fee = _.get(item, 'fee', null);
    p.marketFrom = _.get(item, 'marketFrom', null);
    p.marketTo = _.get(item, 'marketTo', null);
    p.timeOfDay = _.get(item, 'timeOfDay', null);
    return p;
  }

  static parseAirportFeeData(item: any = {}): feeModels.AirportFee {
    const p = new feeModels.AirportFee();
    p.fee = _.get(item, 'fee', null);
    p.type = _.get(item, 'type', null);
    p.airportCode = _.get(item, 'airportCode', null);
    return p;
  }

  static parsePricesOnInterval(item: any): models.PriceOnInterval {
    const p = new models.PriceOnInterval();
    p.prices = _.get(item, 'prices', null);
    if (_.get(item, 'timeInterval', null)) {
      p.timeInterval.start = _.get(item, 'timeInterval[0]', null);
      p.timeInterval.end = _.get(item, 'timeInterval[1]', null);
    } else {
      p.timeInterval = null;
    }
    return p;
  }

  static parseFeeDetails(item: any): models.FeeDetails {
    const d = new models.FeeDetails();
    d.arrivalFee = _.get(item, 'arrivalFee', null);
    d.departureFee = _.get(item, 'departureFee', null);
    d.timeFee = _.get(item, 'timeFee', null);
    d.totalFee = _.get(item, 'totalFee', null);
    return d;
  }
}
