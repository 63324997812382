import { Amenity } from "./amenity";

export class AmenityNode {
    hasAdditionalInfo: boolean;
    additionalInfo: string;
    hierarchyPath: string;
    pathNameList: Array<string>;
    pathName: string;
    visible: boolean;
    name: string;
    id: number;
    depth: number;
    index: number;
    children: Array<AmenityNode>;
  
    constructor(amenity: Amenity) {
        this.hasAdditionalInfo = amenity.hasAdditionalInfo;
        this.additionalInfo = amenity.additionalInfo;
        this.hierarchyPath = amenity.hierarchyPath;
        this.pathNameList = amenity.hierarchyPath ? amenity.hierarchyPath.split('.') : [];
        this.pathName = this.pathNameList.pop();
        this.visible = amenity.visible;
        this.name = amenity.name;
        this.id = amenity.id;
        this.depth = this.pathNameList.length;
        this.children = [];
    }
  }