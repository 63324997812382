import { ReportPaths } from "./report-paths";

export enum AircraftReportPathsEnum {
	REPORT_CONFIG = 'report-config',
	EXPIRATION_CONFIG = 'expiration-config',
	INSURANCE_EXPIRATION = 'fos-config',
	AIRCRAFT_CONFIG = 'aircraft-config',
	VISTA_GLOBAL_CONFIG = 'vista-global-config',
}

export enum AircraftStartReportPathsEnum {
	REPORT_EXPIRATION = 'report-expiration',
	REPORT_FOS = 'report-fos',
	REPORT_AIRCRAFT = 'report-aircraft',
	VISTA_GLOBAL_REPORT = 'vista-global-report'
}

export const aircraftReportsPathsByName = {
	[AircraftReportPathsEnum.REPORT_CONFIG] : 'config',
	[AircraftReportPathsEnum.EXPIRATION_CONFIG] : 'expiration/config',
	[AircraftReportPathsEnum.INSURANCE_EXPIRATION] : 'insurance/config',
	[AircraftReportPathsEnum.AIRCRAFT_CONFIG] : 'aircraft/config ',
	[AircraftReportPathsEnum.VISTA_GLOBAL_CONFIG] : 'vg-aircraft/config ',
	[AircraftStartReportPathsEnum.REPORT_EXPIRATION] : 'report/expiration',
	[AircraftStartReportPathsEnum.REPORT_FOS] : 'report/insurance',
	[AircraftStartReportPathsEnum.REPORT_AIRCRAFT] : 'report/aircraft',
	[AircraftStartReportPathsEnum.VISTA_GLOBAL_REPORT] : 'report/vg-aircraft',
}

export const aircraftReportPaths: ReportPaths = {
	configLabelsByPath: new Map([
		[AircraftReportPathsEnum.AIRCRAFT_CONFIG, 'Aircraft report configuration'],
		[AircraftReportPathsEnum.VISTA_GLOBAL_CONFIG, 'Vista global approved aircraft report configuration'],
		[AircraftReportPathsEnum.EXPIRATION_CONFIG, 'Operator approval expiration report configuration'],
		[AircraftReportPathsEnum.INSURANCE_EXPIRATION, 'Insurance expiration report configuration'],
		[AircraftReportPathsEnum.REPORT_CONFIG, 'All report configuration'],
	]),
	startLabelsByPath: new Map([
		[AircraftStartReportPathsEnum.REPORT_AIRCRAFT, 'Start aircraft report process'],
		[AircraftStartReportPathsEnum.VISTA_GLOBAL_REPORT, 'Start Vista global approved aircraft report'],
		[AircraftStartReportPathsEnum.REPORT_EXPIRATION, 'Start operator approval expiration report process'],
		[AircraftStartReportPathsEnum.REPORT_FOS, 'Start insurance expiration report process'],
	])
}