export class SourcingRequestLookupCriteria {
  search: string = null;
  owner: string = null;
  from: string = null;
  to: string = null;
  departureDate = null;
  pods: Array<string> = [];
  client: string = null;
  priceType: string = null;
  status: string = null;
  sendToXOMarketplace: string = null;
  type: string = null;
  flightRequestId: string = null;
}
