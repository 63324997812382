export class Client {
  id: number = null;
  account: string = null;
  name: string = null;
  legalName: string = null;
  dob: Date = null;
  phone: string = null;
  email: string = null;

  setId(id: number): Client {
    this.id = id;

    return this;
  }

  getId(): number {
    return this.id;
  }

  getAccount(): string {
    return this.account;
  }

  setAccount(account: string): Client {
    this.account = account;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Client {
    this.name = name;

    return this;
  }

  getLegalName(): string {
    return this.legalName;
  }

  setLegalName(legalName): Client {
    this.legalName = legalName;
    
    return this;
  }

  getDob(): Date {
    return this.dob;
  }

  setDob(dob: Date): Client {
    this.dob = dob;

    return this;
  }

  getPhone(): string {
    return this.phone;
  }

  setPhone(phone: string): Client {
    this.phone = phone;

    return this;
  }

  getEmail(): string {
    return this.email;
  }

  setEmail(email: string): Client {
    this.email = email;

    return this;
  }
}
