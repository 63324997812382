<div class="full-height">
  <!-- BEGIN APP -->

  <!-- BEGIN SIDEBAR -->
  <app-sidebar [menuLinks]="menuItems$ | async"
               [activeRoute]="activeRoute$"
               (toggleMenu)="toggleMenuPin()"></app-sidebar>
  <!-- END SIDEBAR -->

  <!-- START PAGE-CONTAINER -->
  <page-container>
    <!-- START PAGE HEADER WRAPPER -->
    <header class="header"
            [applicationName]="'JetStudio'"
            [appSwitcherIsVisible]="true"
            *ngIf="(isAppView$ | async) === false"
            (signOut)="signOut()"
            (toggleMobileSidebar)="toggleMobileSidebar()"
            [userName]="userName$ | async">
    </header>
    <!-- END PAGE HEADER WRAPPER -->

    <!-- START PAGE CONTENT WRAPPER -->
    <div class="page-content-wrapper"
         [ngClass]="{'app-view':isAppView$ | async, 'full-height': (isFullHeightView$ | async)}">

      <!-- START PAGE CONTENT -->
      <div class="content"
           [ngClass]="{'full-height':(isFullHeightView$ | async)}">
        <spinner class="full-height"
                 *ngIf="isLoading$ | async"></spinner>


        <div class="full-height full-width"
             [hidden]="(outletIsVisible$ | async) === false">
          <router-outlet></router-outlet>
        </div>

        <shared-internal-error class="full-height"
                               *ngIf="errorIsVisible$ | async"
                               [errorText]="errorText$ | async"></shared-internal-error>

        <shared-browser-not-supported class="full-height"
                                      *ngIf="browserNowSupported$ | async"></shared-browser-not-supported>
      </div>
      <!-- END PAGE CONTENT -->

      <footer *ngIf="(isAppView$ | async) == false"></footer>
    </div>
    <!-- END PAGE CONTENT WRAPPER -->

  </page-container>
  <!-- END PAGE CONTAINER -->
  <!--START QUICKVIEW -->
  <system *ngIf="isDebug$ | async"
          [environment]="environment"
          [version]="version$ | async">
  </system>
  <!-- END QUICKVIEW-->
  <!-- END APP -->
</div>

<!--<div class="full-height" *ngIf="layoutType === LayoutType.Blank">-->
<!--<router-outlet></router-outlet>-->
<!--</div>-->
