import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class LoadPayload {
  constructor(public legId: number) {}
}
export const load = createAction(
  '[TaskMgmt.FlightRequestLegsCollection] Load',
  props<{ payload: LoadPayload }>()
);
export const loadSuccess = createAction(
  '[TaskMgmt.FlightRequestLegsCollection] Load Success',
  props<{ payload: Array<models.FlightLeg> }>()
);
export const loadFail = createAction('[TaskMgmt.FlightRequestLegsCollection] Load Fail');
export const reset = createAction('[TaskMgmt.FlightRequestLegsCollection] Reset');
