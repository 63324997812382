export const Currencies = [
  'AED',
  'AMD',
  'AOA',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BND',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CSD',
  'CVE',
  'CYP',
  'CZK',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'GBP',
  'GEL',
  'GHC',
  'GHS',
  'GNF',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'MAD',
  'MGA',
  'MKD',
  'MNT',
  'MOP',
  'MRO',
  'MRU',
  'MTL',
  'MUR',
  'MVR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NOK',
  'NPR',
  'NZD',
  'NZD5',
  'OMR',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'QAR',
  'ROL',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SIT',
  'SKK',
  'SLL',
  'THB',
  'TND',
  'TRL',
  'TRY',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UZS',
  'VEB',
  'VEF',
  'VND',
  'VUV',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'ZAR',
  'ZMW',
];
