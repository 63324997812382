import { CharterClientRequest } from './charter-client-request';
import { CateringCredit } from './catering-credit';
export class ClientRequest {
  requestId: number;
  departureDate: Date;
  requestUrl: string = null;
  flightRequest: CharterClientRequest = null;
  cateringCredit: CateringCredit;
  flightRequestUrl: string = null;
  flightRequestId: number = null;
  legUrl: string;
  legId: number;
}
