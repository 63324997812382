import { OperatorApprovalStatusEnum } from "../../domain/models";
import { Lookup } from "./lookup";

export class VendorLookup extends Lookup {
  active: boolean = null;
  prospectusApproved: boolean = null;
  automatedSourcingForGuaranteed: boolean = null;
  automatedSourcingForNonGuaranteed: boolean = null;
  aircraftsRegistrationCountryCode: string = null;
  operatorApprovalStatus: OperatorApprovalStatusEnum = null;
  regionIds: Array<number> = [];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.active = data.hasOwnProperty('active') ? data.active : null;
      this.prospectusApproved = data.hasOwnProperty('prospectusApproved') ?  data.prospectusApproved : null;
      this.automatedSourcingForGuaranteed = data.hasOwnProperty('automatedSourcingForGuaranteed') ? data.automatedSourcingForGuaranteed : null;
      this.automatedSourcingForNonGuaranteed = data.hasOwnProperty('automatedSourcingForNonGuaranteed') ? data.automatedSourcingForNonGuaranteed : null;
      this.aircraftsRegistrationCountryCode = data.hasOwnProperty('aircraftsRegistrationCountryCode') ? data.aircraftsRegistrationCountryCode : null;
      this.operatorApprovalStatus = data.hasOwnProperty('operatorApprovalStatus') ? data.operatorApprovalStatus : null;
      this.regionIds = data.hasOwnProperty('regionIds') ? data.regionIds : [];
    }
  }
}
