import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/restricted-functions-collection.actions';
import * as models from '../../domain/models';

export const restrictedFunctionsCollectionFeatureKey = 'restrictedFunctionsCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  restrictedFunctions: Array<models.RestrictedFunction>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  restrictedFunctions: [],
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      restrictedFunctions: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  )
);
