import { AircraftAmenity } from './aircraft-amenity';
import { AircraftModel } from './aircraft-model';
import { AircraftTail } from './aircraft-tail';
import { LegRequest } from './leg-request';
import { Operator } from './operator';
import { User } from './user';
import { Quote } from './quote';

import * as uuid from 'uuid';
import { LegQuote } from './leg-quote';
import { AircraftCategory } from './aircraft-category';
import { Note } from './note';
import { PurchaseRequisition } from './purchase-requisition';
import { QuoteAttribute } from './quote-attribute';
import { AircraftImage } from './aircraft-image';
import { FileData } from './file-data';
import { PdfFile } from './pdf-file';

export class PurchaseQuote extends Quote {
  purchaseQuoteId: string = uuid.v4();
  salesQuoteRequestId: string;
  operator: Operator = null;
  deleted: boolean;
  aircraftCategory: AircraftCategory = null;
  aircraftModel: AircraftModel = null;
  aircraftId: number;
  aircraftAmenities: Array<AircraftAmenity> = new Array<AircraftAmenity>();
  legs: Array<LegQuote> = new Array<LegQuote>();
  lastModifiedDate: Date;
  lastModifiedUser: User;
  aircraftImages: Array<AircraftImage>;
  createdDate: Date;
  createdUser: User;
  purchaseRequisition: PurchaseRequisition = null;
  aircraftTailNumber: string;
  aircraftTail: AircraftTail;
  aircraftYearOfManufacture: number = null;
  aircraftYearOfRefurbishment: number = null;
  requiresOwnerApproval: boolean;
  opportunityId: string;
  notes: Array<Note>;
  extraFeeIncluded: boolean;
  vendorQuoteId: string;
  vendorQuoteDate: Date;
  quoteAttributes: QuoteAttribute[];
  uploadedFiles: Array<PdfFile> = new Array<PdfFile>();
  files: Array<FileData> = [];
  subcharterConfirmed: boolean = false;

  getExtraFeeIncluded(): boolean {
    return this.extraFeeIncluded;
  }

  setExtraFeeIncluded(extraFeeIncluded: boolean): PurchaseQuote {
    this.extraFeeIncluded = extraFeeIncluded;
    return this;
  }

  getUploadedFiles(): Array<PdfFile> {
    return this.uploadedFiles;
  }

  setUploadedFiles(uploadedFiles: Array<PdfFile>): PurchaseQuote {
    this.uploadedFiles = uploadedFiles;
    return this;
  }

  getQuoteAttributes(): QuoteAttribute[] {
    return this.quoteAttributes;
  }

  setQuoteAttributes(quoteAttributes: QuoteAttribute[]): PurchaseQuote {
    this.quoteAttributes = quoteAttributes;
    return this;
  }

  getVendorQuoteId(): string {
    return this.vendorQuoteId;
  }

  setVendorQuoteId(vendorQuoteId: string): PurchaseQuote {
    this.vendorQuoteId = vendorQuoteId;
    return this;
  }

  getVendorQuoteDate(): Date {
    return this.vendorQuoteDate;
  }

  setVendorQuoteDate(vendorQuoteDate: Date): PurchaseQuote {
    this.vendorQuoteDate = vendorQuoteDate;
    return this;
  }

  getPurchaseRequisition(): PurchaseRequisition {
    return this.purchaseRequisition;
  }

  setPurchaseRequisition(purchaseRequisition: PurchaseRequisition): PurchaseQuote {
    this.purchaseRequisition = purchaseRequisition;
    return this;
  }

  getAircraftId(): number {
    return this.aircraftId;
  }

  setAircraftId(aircraftId: number): PurchaseQuote {
    this.aircraftId = aircraftId;
    return this;
  }

  getNotes(): Array<Note> {
    return this.notes;
  }

  setNotes(notes: Array<Note>): PurchaseQuote {
    this.notes = notes;
    return this;
  }

  getDeleted(): boolean {
    return this.deleted;
  }

  setDeleted(deleted): PurchaseQuote {
    this.deleted = deleted;
    return this;
  }

  getOpportunityId(): string {
    return this.opportunityId;
  }

  setOpportunityId(opportunityId): PurchaseQuote {
    this.opportunityId = opportunityId;
    return this;
  }

  getRequiresOwnerApproval(): boolean {
    return this.requiresOwnerApproval;
  }

  setRequiresOwnerApproval(requiresOwnerApproval): PurchaseQuote {
    this.requiresOwnerApproval = requiresOwnerApproval;
    return this;
  }

  getAircraftTailNumber(): string {
    return this.aircraftTailNumber;
  }

  setAircraftTailNumber(aircraftTailNumber: string) {
    this.aircraftTailNumber = aircraftTailNumber;

    return this;
  }

  getAircraftYearOfManufacture(): number {
    return this.aircraftYearOfManufacture;
  }

  setAircraftYearOfManufacture(aircraftYearOfManufacture: number) {
    this.aircraftYearOfManufacture = aircraftYearOfManufacture;
    return this;
  }

  getAircraftYearOfRefurbishment(): number {
    return this.aircraftYearOfRefurbishment;
  }

  setAircraftYearOfRefurbishment(aircraftYearOfRefurbishment: number) {
    this.aircraftYearOfRefurbishment = aircraftYearOfRefurbishment;

    return this;
  }

  getPurchaseQuoteId(): string {
    return this.purchaseQuoteId;
  }

  setPurchaseQuoteId(purchaseQuoteId: string): PurchaseQuote {
    this.purchaseQuoteId = purchaseQuoteId;

    return this;
  }

  getSalesQuoteRequestId(): string {
    return this.salesQuoteRequestId;
  }

  setSalesQuoteRequestId(salesQuoteRequestId: string): PurchaseQuote {
    this.salesQuoteRequestId = salesQuoteRequestId;

    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setOperator(operator: Operator): PurchaseQuote {
    this.operator = operator;

    return this;
  }

  getAircraftModel(): AircraftModel {
    return this.aircraftModel;
  }

  setAircraftModel(aircraftModel: AircraftModel): PurchaseQuote {
    this.aircraftModel = aircraftModel;

    return this;
  }

  getAircraftCategory(): AircraftCategory {
    return this.aircraftCategory;
  }

  setAircraftCategory(aircraftCategory: AircraftCategory): PurchaseQuote {
    this.aircraftCategory = aircraftCategory;

    return this;
  }

  addAircraftAmenity(aircraftAmenity: AircraftAmenity): PurchaseQuote {
    this.aircraftAmenities.push(aircraftAmenity);

    return this;
  }

  getAircraftAmenities(): Array<AircraftAmenity> {
    return this.aircraftAmenities;
  }

  setAircraftAmenities(aircraftAmenities: Array<AircraftAmenity>): PurchaseQuote {
    this.aircraftAmenities = aircraftAmenities;

    return this;
  }

  getAircraftImages(): Array<AircraftImage> {
    return this.aircraftImages;
  }

  setAircraftImages(aircraftImages: Array<AircraftImage>): PurchaseQuote {
    this.aircraftImages = aircraftImages;

    return this;
  }

  addLeg(leg: LegQuote): PurchaseQuote {
    this.legs.push(leg);

    return this;
  }

  getLegs(): Array<LegQuote> {
    return this.legs;
  }

  removeLeg(index: number): PurchaseQuote {
    this.legs.splice(index, 1);

    return this;
  }

  setLegs(legs: Array<LegQuote>): PurchaseQuote {
    this.legs = legs;

    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): PurchaseQuote {
    this.lastModifiedDate = lastModifiedDate;

    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): PurchaseQuote {
    this.lastModifiedUser = lastModifiedUser;

    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): PurchaseQuote {
    this.createdDate = createdDate;

    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): PurchaseQuote {
    this.createdUser = createdUser;

    return this;
  }

  getSubcharterConfirmed(): boolean {
    return this.subcharterConfirmed;
  }

  setSubcharterConfirmed(subcharterConfirmed: boolean): PurchaseQuote {
    this.subcharterConfirmed = subcharterConfirmed;

    return this;
  }
}
