export enum ClientServiceAction {
  Extend,
  New,
  Renew,
  Convert,
  Suspend,
  Resume,
  NewOrder,
  NewConcierge,
  NewCharter,
  NewCreditMemo
}
