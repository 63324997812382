import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Sales.ProductCollection] Load');
export class LoadSuccessPayload {
  constructor(
    public products: Array<models.Product>,
    public restrictions: Array<models.FunctionRestriction>,
    public consoleLoginCheck: models.ConsoleLoginCheck
  ) {}
}
export const loadSuccess = createAction(
  '[Sales.ProductCollection] Load Success',
  props<{ payload: LoadSuccessPayload }>()
);
export const loadFail = createAction('[Sales.ProductCollection] Load Fail');
