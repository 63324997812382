import { SortDescriptor } from '@progress/kendo-data-query';
import { Lookup } from './lookup';
import { Operator } from '../../domain/models';

export class AircraftLookup extends Lookup {
  insApproveOnly: boolean = null;
  completed: boolean = null;
  approvedBy: string = null;
  aircraftCategory: number = null;
  homeBaseCountry: string = null;
  homeBaseServiceAreas: Array<string> = [];
  onFleet: boolean = null;
  homeBase: string = null;
  active = true;
  d085OpsSpecsApproval: boolean = null;
  marketplacePhotosApproval: boolean = null;
  aircraftAge: number = null;
  refurbishmentAge: number = null;
  sort: Array<SortDescriptor> = [{field: 'tailNumber', dir: 'asc'}];
  operator: Operator = null;

  constructor(data?: any) {
    super(data);
    if (data) {
      this.insApproveOnly = data.hasOwnProperty('insApproveOnly') ? data.insApproveOnly : null;
      this.completed = data.hasOwnProperty('completed') ? data.completed : null;
      this.approvedBy = data.hasOwnProperty('approvedBy') ? data.approvedBy : null;
      this.aircraftCategory = data.hasOwnProperty('aircraftCategory') ? data.aircraftCategory : null;
      this.homeBaseCountry = data.hasOwnProperty('homeBaseCountry') ? data.homeBaseCountry : null;
      this.homeBaseServiceAreas = data.hasOwnProperty('homeBaseServiceAreas') ? data.homeBaseServiceAreas : [];
      this.onFleet = data.hasOwnProperty('onFleet') ? data.onFleet : null;
      this.homeBase = data.hasOwnProperty('homeBase') ? data.homeBase : null;
      this.active = data.hasOwnProperty('active') ? data.active : true;
      this.d085OpsSpecsApproval = data.hasOwnProperty('d085OpsSpecsApproval') ? data.d085OpsSpecsApproval : null;
      this.marketplacePhotosApproval = data.hasOwnProperty('marketplacePhotosApproval') ? data.marketplacePhotosApproval : null;
      this.aircraftAge = data.hasOwnProperty('aircraftAge') ? data.aircraftAge : null;
      this.refurbishmentAge = data.hasOwnProperty('refurbishmentAge') ? data.refurbishmentAge : null;
      this.operator = data.hasOwnProperty('operator') ? data.operator : null;
    }
  }
}
