import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[YM Cyo-Pricing] Load';
export const LOAD_SUCCESS = '[YM Cyo-Pricing] Load Success';
export const LOAD_FAIL = '[YM Cyo-Pricing] Load Fail';
export const LOAD_MARKET = '[YM Cyo-Pricing] Load Market';
export const LOAD_MARKET_SUCCESS = '[YM Cyo-Pricing] Load Market Success';
export const LOAD_MARKET_FAIL = '[YM Cyo-Pricing] Load Market Fail';
export const SET = '[YM Cyo-Pricing] Set';
export const ADD = '[YM Cyo-Pricing] Add';
export const REMOVE = '[YM Cyo-Pricing] Remove';
export const SET_LOOKUP_CRITERIA = '[YM Cyo-Pricing] Set Lookup Criteria';
export const SAVE = '[YM Cyo-Pricing] Save';
export const SAVE_SUCCESS = '[YM Cyo-Pricing] Save Success';
export const SAVE_FAIL = '[YM Cyo-Pricing] Save Fail';

export class LoadCyoPricingAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessCyoPricingPayload {
  entities: Array<viewModels.CyoPricingRuleWrapper>;
  version: number;
}

export class LoadSuccessCyoPricingAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessCyoPricingPayload) {}
}

export class LoadFailRulePayload {
  entity: any;
}

export class LoadFailRuleAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload: LoadFailRulePayload) {}
}

export class LoadCyoPricingMarketAction implements Action {
  readonly type = LOAD_MARKET;

  constructor(public payload: LoadCyoPricingMarketPayload) {}
}

export class LoadCyoPricingMarketPayload {
  constructor(public id: string) {}
}

export class LoadCyoPricingMarketSuccessAction implements Action {
  readonly type = LOAD_MARKET_SUCCESS;

  constructor(public payload: LoadCyoPricingMarketSuccessPayload) {}
}

export class LoadCyoPricingMarketSuccessPayload {
  entity: models.Market;
}

export class LoadCyoPricingMarketFailAction implements Action {
  readonly type = LOAD_MARKET_FAIL;
}

export class SetCyoRulePayload {
  entity: viewModels.CyoPricingRuleWrapper;
}

export class SetCyoRuleAction implements Action {
  readonly type = SET;

  constructor(public payload: SetCyoRulePayload) {}
}

export class SaveCyoRuleAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessCyoRulePayload {
  entities: Array<viewModels.CyoPricingRuleWrapper>;
  version: number;
}

export class SaveSuccessCyoRuleAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessCyoRulePayload) {}
}

export class SaveFailCyoRulePayload {
  entity: any;
}

export class SaveFailCyoRuleAction implements Action {
  readonly type = SAVE_FAIL;

  constructor(public payload: SaveFailCyoRulePayload) {}
}

export class SetCyoLookupCriteriaPayload {
  entity: viewModels.RouteLookup;
}

export class SetCyoLookupCriteriaAction implements Action {
  readonly type = SET_LOOKUP_CRITERIA;

  constructor(public payload: SetCyoLookupCriteriaPayload) {}
}

export class RemoveCyoRulePayload {
  entity: viewModels.CyoPricingRuleWrapper;
}

export class RemoveCyoRuleAction implements Action {
  readonly type = REMOVE;

  constructor(public payload: SetCyoRulePayload) {}
}

export class AddRuleAction implements Action {
  readonly type = ADD;
}

export type Actions =
  | LoadCyoPricingAction
  | LoadSuccessCyoPricingAction
  | LoadFailRuleAction
  | LoadCyoPricingMarketAction
  | LoadCyoPricingMarketSuccessAction
  | LoadCyoPricingMarketFailAction
  | SetCyoRuleAction
  | SaveCyoRuleAction
  | SaveSuccessCyoRuleAction
  | SaveFailCyoRuleAction
  | SetCyoLookupCriteriaAction
  | RemoveCyoRuleAction
  | AddRuleAction;
