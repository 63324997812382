import { Client } from './client';
import { ClientServiceStatusEnum } from './client-service-status';
import { ClientServiceTypeEnum } from './client-service-type';
// import { OrderItem } from './order-item';
import { Product } from './product';

export class ClientService {
  clientServiceId: number;
  clientServiceStatus: ClientServiceStatusEnum;
  client: Client;
  startDate: Date;
  orderId: number;
  endDate: Date;
  product: Product;
  createTime: Date;
  termsAndConditionsAcceptedDate: Date;
  // activateTime: Date;
  suspendTime: Date;
  cancelTime: Date;

  // orderItem: OrderItem;
  isLegacy: boolean;
  isReactivatable: boolean;
  isTerminatable: boolean;
  sfdcOpportunityLink: string;
  sfdcOpportunityId?: string;

  get clientServiceType(): ClientServiceTypeEnum {
    throw new Error('Not Implemented');
  }
}

// CREATE TABLE client_service.client_service (
//   client_service_id SERIAL,
//   client_service_type_id INTEGER NOT NULL,
//   client_service_status_id INTEGER NOT NULL,
//   client_id INTEGER NOT NULL,
//   start_date TIMESTAMP WITH TIME ZONE NOT NULL,
//   end_date TIMESTAMP WITH TIME ZONE NOT NULL,
//   product_id INTEGER NOT NULL,
//   create_time TIMESTAMP WITH TIME ZONE DEFAULT now() NOT NULL,
//   created_by_user_id INTEGER NOT NULL,
//   activate_time TIMESTAMP WITH TIME ZONE,
//   activated_by_user_id INTEGER,
//   suspend_time TIMESTAMP WITH TIME ZONE,
//   suspended_by_user_id INTEGER,
//   cancel_time TIMESTAMP WITH TIME ZONE,
//   canceled_by_user_id INTEGER,
//   CONSTRAINT client_service_pkey PRIMARY KEY(client_service_id)
// )
// WITH
