import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/aircraft-model-collection.actions';
import * as models from '../../domain/models';

export const aircraftModelCollectionFeatureKey = 'aircraftModelCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.AircraftModel>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { entities }): State => ({
      ...state,
      loaded: true,
      loading: false,
      items: entities,
    })
  )
);
