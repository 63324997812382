import { Action } from '@ngrx/store';

import * as models from '../../domain/models/magic-override';
import { Paginable } from '../../domain/models/magic-override';

export const LOAD = '[YieldMgmt.MagicOverride] Load';
export const LOAD_SUCCESS = '[YieldMgmt.MagicOverride] Load Success';
export const LOAD_FAIL = '[YieldMgmt.MagicOverride] Load Fail';
export const SET_FILTER = '[YieldMgmt.MagicOverride] Set Filter';
export const RESET_FILTER = '[YieldMgmt.MagicOverride] Reset Filter';
export const ACTIVATE_LEG = '[YieldMgmt.MagicOverride] Activate Leg';
export const ACTIVATE_LEG_SUCCESS = '[YieldMgmt.MagicOverride] Activate Leg Success';
export const ACTIVATE_LEG_FAIL = '[YieldMgmt.MagicOverride] Activate Leg Fail';
export const DEACTIVATE_LEG = '[YieldMgmt.MagicOverride] Deactivate Leg';
export const DEACTIVATE_LEG_WARNING = '[YieldMgmt.MagicOverride] Deactivate Leg Warning';
export const DEACTIVATE_LEG_WARNING_SUCCESS = '[YieldMgmt.MagicOverride] Deactivate Leg Warning Success';
export const DEACTIVATE_LEG_SUCCESS = '[YieldMgmt.MagicOverride] Deactivate Leg Success';
export const DEACTIVATE_LEG_FAIL = '[YieldMgmt.MagicOverride] Deactivate Leg Fail';

export class LoadActionPayload {
  paginator: Paginable = new Paginable();
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload?: LoadActionPayload) {
  }
}

export class LoadSuccessPayload {
  legs: Array<models.Leg>;
  paginator: Paginable;
  totalElements: number;
  totalPages: number;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetFilterActionPayload {
  filterCriteria: models.FilterCriteria = new models.FilterCriteria();
}

export class SetFilterAction implements Action {
  readonly type = SET_FILTER;

  constructor(public payload: SetFilterActionPayload) {
  }
}

export class ResetFilterAction implements Action {
  readonly type = RESET_FILTER;
}

export class ActivateLegAction implements Action {
  readonly type = ACTIVATE_LEG;

  constructor(public payload: ActivateLegPayload) {
  }
}

export class ActivateLegSuccessAction implements Action {
  readonly type = ACTIVATE_LEG_SUCCESS;

  constructor(payload: any) {
  }
}

export class ActivateLegFailAction implements Action {
  readonly type = ACTIVATE_LEG_FAIL;

  constructor(payload: any) {
  }
}

export class DeactivateLegActionWarningAction implements Action {
  readonly type = DEACTIVATE_LEG_WARNING;

  constructor(public payload: DeactivatePayload) {
  }
}

export class DeactivateLegActionWarningSuccessAction implements Action {
  readonly type = DEACTIVATE_LEG_WARNING_SUCCESS;

  constructor(public payload: any) {
  }
}

export class DeactivatePayload {
  legIds: Array<number>;
  forceUpdate: boolean;
}

export class DeactivateLegAction implements Action {
  readonly type = DEACTIVATE_LEG;

  constructor(public payload: DeactivatePayload) {
  }
}

export class DeactivateLegSuccessAction implements Action {
  readonly type = DEACTIVATE_LEG_SUCCESS;

  constructor(payload: any) {
  }
}

export class DeactivateLegFailAction implements Action {
  readonly type = DEACTIVATE_LEG_FAIL;

  constructor(payload: any) {
  }
}

export class ActivateLegPayload {
  legIds: Array<number>;
  price: number;
  startSeat: number;
  endSeat: number;
  seatsToOverride: number;
  overridePricingType: string;
  expirationStartTime: Date;
  expirationEndTime: Date;
  active: boolean;
  updateReasonId: number;
  document: {}; // hardcoded object, should be here for now
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetFilterAction
  | ResetFilterAction
  | ActivateLegAction
  | ActivateLegSuccessAction
  | ActivateLegFailAction
  | DeactivateLegAction
  | DeactivateLegSuccessAction
  | DeactivateLegActionWarningAction
  | DeactivateLegFailAction;
