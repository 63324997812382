import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/peak-days.actions';
import * as models from '../../domain/models';

export const peakDaysFeatureKey = 'peakDays';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: models.PeakDays;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: new models.PeakDays(),
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (_, { payload }): State => ({
      loaded: true,
      loading: false,
      items: payload,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(actions.setInitial, (): State => initialState)
);
