export class OpportunityStatus {
  private opportunityStatusId: number;
  private name: string;

  getOpportunityStatusId(): number {
    return this.opportunityStatusId;
  }

  setOpportunityStatusId(opportunityStatusId: number): OpportunityStatus {
    this.opportunityStatusId = opportunityStatusId;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): OpportunityStatus {
    this.name = name;

    return this;
  }
}
