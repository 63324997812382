import * as _ from 'lodash';
import * as moment from 'moment/moment';

import * as models from '../../../../domain/models';
import * as domainServices from '../../../../domain/domain-services';
import * as serviceContracts from '../../../../domain/service-contracts/order';
import * as contracts from '../contracts';
import * as mappers from './index';

export class OrderMapper {
  static parseOrder(item: any) {
    const order = new models.Order();
    order.id = Number(item.id);
    if (item.hasOwnProperty('canEdit')) {
      order.canEdit = item.canEdit;
    }
    order.chargeableCancellation = _.get(item, 'chargeableCancellation', null);
    order.charterSalesCancellation.cancellationFee =
      _.get(item, 'charterSalesCancellation.cancellationFee', null);
    order.charterSalesCancellation.cancellationNotes =
      _.get(item, 'charterSalesCancellation.cancellationNotes', null);
    order.autoAccept = _.get(item, 'autoAccept', null);
    order.canCancelOrder = _.get(item, 'canCancelOrder', null);
    order.invoiceUIPaymentStatusId = _.get(item, 'invoiceUiPaymentStatus', null);
    order.documentGenerationInProgress = _.get(item, 'documentGenerationInProgress', null);
    order.showGeneratedDocumentsPanel = _.get(item, 'showGeneratedDocumentsPanel', null);
    order.totalPrice = Number(item.totalPrice);
    order.balance = item.balance ? Number(item.balance) : null;
    order.orderStatus = Number(item.orderStatusId);
    order.orderSourceId = Number(item.orderSourceId);
    order.replacedWithAdditional = _.get(item, 'replacedWithAdditional', false);
    order.sapExportStatus = item.sapExportStatus
      ? item.sapExportStatus.map((status) =>
          models.SapExportStatus.getStatus(
            status.statusId,
            status.statusDescription,
            status.submissionTypeId
          )
        )
      : null;
    order.createTime = new Date(item.createTime);
    order.paymentConfirmed = _.get(item, 'paymentConfirmed', null);
    order.canChangePaymentType = item.canChangePaymentType === true;
    order.charterSalesOpportunityId = _.get(item, 'charterSalesOpportunityId', null);
    order.showCreateSFDCOpportunityButton = !!_.get(item, 'showCreateSFDCOpportunityButton', null);
    order.orderBilling = this.parseOrderBilling(item.orderBilling);
    order.user = this.parseUser(item.user);
    order.cancellations = _.get(item, 'cancellations', [])?.length
      ? item.cancellations.map((e) => this.parseCancellation(e))
      : [];
    order.invoices = _.get(item, 'invoices', []).map((e) => this.parseInvoice(e));
    order.items = _.get(item, 'items', []).map((e) => this.parseOrderItem(e));
    return order;
  }

  static parseOrderBilling(item: any): models.OrderBilling {
    const orderBilling = new models.OrderBilling();
    orderBilling.client = this.parseOrderBillingClient(item.client);
    orderBilling.businessName = _.get(item, 'businessName', null);
    return orderBilling;
  }

  static parseOrderBillingClient(item: any): models.Client {
    const client = new models.Client();
    client.id = Number(item.id);
    client.legalName = _.get(item, 'legalName', null);
    client.name = _.get(item, 'name', null);
    client.dob = new Date(_.get(item, 'dob', null));
    return client;
  }

  static parseUser(item: any): models.User {
    const user = new models.User();
    user.id = _.get(item, 'id', null);
    user.email = _.get(item, 'email', null);
    user.firstName = _.get(item, 'firstName', null);
    user.lastName = _.get(item, 'lastName', null);
    user.phone = _.get(item, 'phone', null);
    return user;
  }

  static parseCancellation(item: any): models.Cancellation {
    const cancellation = new models.Cancellation();
    cancellation.cancellationTime = new Date(_.get(item, 'cancellationTime', null));
    cancellation.orderStatusId = _.get(item, 'orderStatusId', null);
    cancellation.user = this.parseCancellationUser(item.user);
    return cancellation;
  }

  static parseCancellationUser(item: any): models.CancellationUser {
    const cancellationUser = new models.CancellationUser();
    cancellationUser.email = _.get(item, 'email', null);
    cancellationUser.id = _.get(item, 'id', null);
    cancellationUser.firstName = _.get(item, 'firstName', null);
    cancellationUser.lastName = _.get(item, 'lastName', null);
    return cancellationUser;
  }

  static parseInvoice(item: any): models.Invoice {
    const invoice = new models.Invoice();
    invoice.id = _.get(item, 'invoiceId', null);
    invoice.amount = _.get(item, 'amount', null);
    invoice.legacyFormUrlKey =
      _.get(item, 'legacyFormUrlKey', null) || _.get(item, 'formUrlKey', null);
    invoice.legacyFormUrl = _.get(item, 'legacyFormUrl', null) || _.get(item, 'formUrl', null);
    invoice.legacyFormEditUrl =
      _.get(item, 'legacyFormEditUrl', null) || _.get(item, 'formEditUrl', null);
    invoice.invoiceTypeId = _.get(item, 'invoiceTypeId', null);
    invoice.invoiceStatusId = _.get(item, 'invoiceStatusId', null);
    invoice.invoiceTypeName = _.get(item, 'invoiceTypeName', null);
    invoice.createTime = new Date(item.createTime);
    invoice.documents = _.get(item, 'documents', null);
    invoice.invoiceReplacements = _.get(item, 'invoiceReplacements', []).map((ir) =>
      this.parseInvoiceReplacement(ir)
    );
    if (_.get(item, 'client', null)) {
      invoice.client = this.parseClient(item.client);
    }
    return invoice;
  }

  static parseInvoiceReplacement(item: any): models.InvoiceReplacement {
    const ir = new models.InvoiceReplacement();
    ir.formTplId = _.get(item, 'formTplId', null);
    ir.name = _.get(item, 'name', null);
    return ir;
  }

  static parseClient(item: any): models.Client {
    const client = new models.Client();
    client.id = _.get(item, 'id', null);
    client.name = _.get(item, 'name', null);
    client.legalName = _.get(item, 'legalName', null);
    client.dob = _.get(item, 'dob', null);
    client.dba = _.get(item, 'dba', null);
    client.email = _.get(item, 'email', null);
    client.phone = _.get(item, 'phone', null);
    return client;
  }

  static parseOrderItem(item: any): models.OrderItem {
    const orderItem = new domainServices.OrderService().parseOrderItemInternal(item);
    orderItem.childOrderItems = _.get(item, 'childOrderItems', []).map((e) =>
      new domainServices.OrderService().parseOrderItemInternal(e)
    );
    return orderItem;
  }

  static convertOrderToSerializable(
    request: serviceContracts.CreateOrderRequest
  ): contracts.OrderSerializable {
    const orderSerializable = new contracts.OrderSerializable();
    orderSerializable.orderSourceId = models.OrderSourceEnum.JetStudio;
    orderSerializable.autoAccept = request.order.autoAccept;

    if (request.order.promotion !== null) {
      orderSerializable.promotionId = request.order.promotion.id;
    }

    orderSerializable.orderBilling.clientId = request.order.orderBilling.client.id;
    orderSerializable.orderBilling.businessName = request.order.orderBilling.businessName;
    orderSerializable.paymentOptions = new mappers.PaymentOptionsMapper().toDto(
      request.order.paymentOptions
    );

    orderSerializable.totalPrice = request.order.totalPrice;
    if (request.order.lastOrder) {
      orderSerializable.lastOrder = {
        items: request.order.lastOrder.items,
        totalPrice: request.order.lastOrder.totalPrice,
      } as models.Order;

      orderSerializable.lastOrder.items.forEach((e) => {
        const el = e as any;
        el.cateringCreditAmount = el.cateringCreditOrderItem;
        delete el.cateringCreditOrderItem;
      });

      orderSerializable.balance = request.order.totalPrice - request.order.lastOrder.totalPrice;
      orderSerializable.conciergeInvoiceType = models.ConciergeInvoiceTypeEnum.Additional;
    }

    if (
      request.order.items[0].orderItemType === models.OrderItemTypeEnum.Concierge &&
      !request.order.lastOrder
    ) {
      orderSerializable.conciergeInvoiceType = models.ConciergeInvoiceTypeEnum.New;
    }

    orderSerializable.createTime = request.order.createTime;
    orderSerializable.invoices = request.order.invoices;

    orderSerializable.items = request.order.items.map((orderItem) => {
      const orderItemSerializable = this.convertOrderItemToSerializable(orderItem);
      orderItemSerializable.childOrderItems = [];

      if (orderItem.childOrderItems) {
        orderItemSerializable.childOrderItems = orderItem.childOrderItems.map((childOrderItem) =>
          this.convertOrderItemToSerializable(childOrderItem)
        );
      }

      return orderItemSerializable;
    });

    return orderSerializable;
  }

  static convertOrderItemToSerializable(
    orderItem: models.OrderItem
  ): contracts.OrderItemSerializable {
    let orderItemSerializable: contracts.OrderItemSerializable = null;

    switch (orderItem.orderItemType) {
      case models.OrderItemTypeEnum.Adjustment: {
        orderItemSerializable = new contracts.AdjustmentOrderItemSerializable();
        const oi1 = orderItem as models.OrderAdjustmentOrderItem;

        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedOrderId =
          oi1.adjustedOrderItem.orderId;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedOrderItemId =
          oi1.adjustedOrderItem.id;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).adjustedAmount =
          oi1.adjustedAmount;
        (orderItemSerializable as contracts.AdjustmentOrderItemSerializable).isFullRefund =
          oi1.isFullRefund();

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.LegacyAdjustment: {
        orderItemSerializable = new contracts.LegacyAdjustmentOrderItemSerializable();
        const oi2 = orderItem as models.LegacyAdjustmentOrderItem;
        (
          orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable
        ).membershipPeriodId = oi2.membershipPeriodId;
        (orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable).adjustedAmount =
          oi2.adjustedAmount;
        (orderItemSerializable as contracts.LegacyAdjustmentOrderItemSerializable).isFullRefund =
          oi2.isFullRefund();

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.FlightPass: {
        orderItemSerializable = new contracts.FlightPassOrderItemSerializable();
        const oi3 = orderItem as models.FlightPassOrderItem;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).sfdcOpportunityId =
          oi3.sfdcOpportunityId;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).faceValue =
          oi3.faceValue;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).route = oi3.route;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).qtyOfPasses =
          oi3.qtyOfPasses;
        (
          orderItemSerializable as contracts.FlightPassOrderItemSerializable
        ).contractCommencementDate = oi3.contractCommencementDate;

        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).productId =
          oi3.product.id;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).productTypeId =
          oi3.product.productType.id;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).productName =
          oi3.product.name;
        (orderItemSerializable as contracts.FlightPassOrderItemSerializable).productAmount =
          oi3.product.amount;
        (
          orderItemSerializable as contracts.FlightPassOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi3.product.productType.parentProductTypeId;
        orderItemSerializable.basePrice = oi3.product.amount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi3.quantity;
        orderItemSerializable.promotionItemId =
          oi3.promotionItem !== null ? oi3.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi3.promotionAmount;

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.Product: {
        orderItemSerializable = new contracts.ProductOrderItemSerializable();
        const oi3 = orderItem as models.ProductOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi3.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi3.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi3.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi3.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi3.product.productType.parentProductTypeId;
        orderItemSerializable.basePrice = oi3.product.amount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi3.quantity;
        orderItemSerializable.promotionItemId =
          oi3.promotionItem !== null ? oi3.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi3.promotionAmount;

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.Membership: {
        orderItemSerializable = new contracts.MembershipOrderItemSerializable();
        const oi4 = orderItem as models.MembershipOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi4.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi4.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi4.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi4.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi4.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.MembershipOrderItemSerializable).salesAction =
          oi4.salesAction;
        orderItemSerializable.basePrice = oi4.product.amount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi4.quantity;
        orderItemSerializable.promotionItemId =
          oi4.promotionItem !== null ? oi4.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi4.promotionAmount;
        if (oi4.membershipClientService) {
          if (!(oi4.membershipClientService instanceof models.LegacyMembershipClientService)) {
            (
              orderItemSerializable as contracts.MembershipOrderItemSerializable
            ).managedClientServiceId = oi4.membershipClientService.clientServiceId;
          } else {
            (
              orderItemSerializable as contracts.MembershipOrderItemSerializable
            ).managedLsegacyMembershipPeriodId = oi4.membershipClientService.clientServiceId;
          }
        }
        (orderItemSerializable as contracts.MembershipOrderItemSerializable).referredByClientId =
          _.get(oi4, 'referredByClient.id', null);

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.GroupMembership:
      case models.OrderItemTypeEnum.GroupMembershipSlot:
        orderItemSerializable = new contracts.GroupMembershipOrderItemSerializable();
        const oi5 = orderItem as models.GroupMembershipOrderItem;

        (orderItemSerializable as contracts.ProductOrderItemSerializable).productId =
          oi5.product.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productTypeId =
          oi5.product.productType.id;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productName =
          oi5.product.name;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).productAmount =
          oi5.product.amount;
        (
          orderItemSerializable as contracts.ProductOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi5.product.productType.parentProductTypeId;
        orderItemSerializable.basePrice = oi5.product.amount;
        orderItemSerializable.promotionItemId =
          oi5.promotionItem !== null ? oi5.promotionItem.id : null;
        (orderItemSerializable as contracts.ProductOrderItemSerializable).promotionAmount =
          oi5.promotionAmount;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).slotsCount =
          oi5.slotsCount;
        (
          orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
        ).sfdcOpportunityId = oi5.sfdcOpportunityId;
        (
          orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
        ).contractCommencementDate = oi5.contractCommencementDate;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).quantity =
          oi5.quantity;
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).salesAction =
          oi5.salesAction;
        if (oi5.membershipClientService) {
          if (!(oi5.membershipClientService instanceof models.LegacyMembershipClientService)) {
            (
              orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
            ).managedClientServiceId = oi5.membershipClientService.clientServiceId;
          } else {
            (
              orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
            ).managedLsegacyMembershipPeriodId = oi5.membershipClientService.clientServiceId;
          }
        }
        (orderItemSerializable as contracts.GroupMembershipOrderItemSerializable).beneficiaries =
          oi5.beneficiaries.filter((i) => i !== null).map((i) => i.id);
        (
          orderItemSerializable as contracts.GroupMembershipOrderItemSerializable
        ).referredByClientId = _.get(oi5, 'referredByClient.id', null);

        orderItemSerializable.orderItemType = models.OrderItemTypeEnum.Product;
        break;

      case models.OrderItemTypeEnum.CustomTerm:
        orderItemSerializable = new contracts.CustomTermOrderItemSerializable();
        const oi6 = orderItem as models.CustomTermOrderItem;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).termMonths =
          oi6.termMonths;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).termDays =
          oi6.termDays;
        (orderItemSerializable as contracts.CustomTermOrderItemSerializable).totalPrice =
          oi6.totalPrice;
        orderItemSerializable.orderItemType = models.OrderItemTypeEnum.CustomTerm;
        break;

      case models.OrderItemTypeEnum.FlightCredit: {
        orderItemSerializable = new contracts.FlightCreditOrderItemSerializable();
        const oi7 = orderItem as models.FlightCreditAdjustmentOrderItem;
        (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).flightCreditsAmount =
          oi7.flightCreditAmount;
        (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).modifiesTotal =
          oi7.modifiesTotal;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        if (oi7.product) {
          (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).productId =
            oi7.product.id;
          (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).productTypeId =
            oi7.product.productType.id;
          (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).productName =
            oi7.product.name;
          (orderItemSerializable as contracts.FlightCreditOrderItemSerializable).productAmount =
            oi7.product.amount;
          (
            orderItemSerializable as contracts.FlightCreditOrderItemSerializable
          ).productProductTypeParentProductTypeId = oi7.product.productType.parentProductTypeId;
        }
        break;
      }

      case models.OrderItemTypeEnum.SFDCDepositDue: {
        orderItemSerializable = new contracts.SfdcDepositOrderItemSerializable();
        const oi7 = orderItem as models.SfdcDepositAdjustmentOrderItem;
        (orderItemSerializable as contracts.SfdcDepositOrderItemSerializable).sfdcDepositAmount =
          oi7.sfdcDepositAmount;
        (orderItemSerializable as contracts.SfdcDepositOrderItemSerializable).adjustment =
          oi7.sfdcDepositAdjustment;
        (orderItemSerializable as contracts.SfdcDepositOrderItemSerializable).replacesTotal =
          oi7.replacesTotal;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.AnnualMembershipFee: {
        orderItemSerializable = new contracts.SfdcAnnualMembershipFeeOrderItemSerializable();
        const oi7 = orderItem as models.SfdcAnnualMembershipFeeOrderItem;
        (
          orderItemSerializable as contracts.SfdcAnnualMembershipFeeOrderItemSerializable
        ).sfdcAnnualMembershipFee = oi7.sfdcAnnualMembershipFee;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.CashCreditTransfer: {
        orderItemSerializable = new contracts.SfdcCashCreditTransferOrderItemSerializable();
        const oi7 = orderItem as models.SfdcCashCreditTransferOrderItem;
        (
          orderItemSerializable as contracts.SfdcCashCreditTransferOrderItemSerializable
        ).sfdcCashCreditTransfer = oi7.sfdcCashCreditTransfer;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.BonusCreditTransfer: {
        orderItemSerializable = new contracts.SfdcBonusCreditTransferOrderItemSerializable();
        const oi7 = orderItem as models.SfdcBonusCreditTransferOrderItem;
        (
          orderItemSerializable as contracts.SfdcBonusCreditTransferOrderItemSerializable
        ).sfdcBonusCreditTransfer = oi7.sfdcBonusCreditTransfer;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.PromotionalCredit: {
        orderItemSerializable = new contracts.PromotionalCreditOrderItemSerializable();
        const oi7 = orderItem as models.PromotionCreditAdjustmentOrderItem;
        (
          orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable
        ).promotionalCreditsAmount = oi7.promotionalCreditAmount;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        (orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable).modifiesTotal =
          oi7.modifiesTotal;
        if (oi7.product) {
          (orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable).productId =
            oi7.product.id;
          (
            orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable
          ).productTypeId = oi7.product.productType.id;
          (orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable).productName =
            oi7.product.name;
          (
            orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable
          ).productAmount = oi7.product.amount;
          (
            orderItemSerializable as contracts.PromotionalCreditOrderItemSerializable
          ).productProductTypeParentProductTypeId = oi7.product.productType.parentProductTypeId;
        }
        break;
      }

      case models.OrderItemTypeEnum.LoyaltyCredit: {
        orderItemSerializable = new contracts.LoyaltyCreditOrderItemSerializable();
        const oi7 = orderItem as models.LoyaltyCreditAdjustmentOrderItem;
        (
          orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable
        ).loyaltyCreditsAmount = oi7.loyaltyCreditAmount;
        (orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable).modifiesTotal =
          oi7.modifiesTotal;
        orderItemSerializable.orderItemType = orderItem.orderItemType;
        if (oi7.product) {
          (orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable).productId =
            oi7.product.id;
          (orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable).productTypeId =
            oi7.product.productType.id;
          (orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable).productName =
            oi7.product.name;
          (orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable).productAmount =
            oi7.product.amount;
          (
            orderItemSerializable as contracts.LoyaltyCreditOrderItemSerializable
          ).productProductTypeParentProductTypeId = oi7.product.productType.parentProductTypeId;
        }
        break;
      }

      case models.OrderItemTypeEnum.Concierge: {
        orderItemSerializable = new contracts.ConciergeOrderItemSerializable();
        const oi8 = orderItem as models.ConciergeOrderItem;

        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).salesAction =
          models.SalesActionEnum.New;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productId =
          oi8.product.id;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productTypeId =
          oi8.product.productType.id;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).productName =
          oi8.product.name;
        (
          orderItemSerializable as contracts.ConciergeOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi8.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).quantity = oi8.quantity;
        orderItemSerializable.basePrice = oi8.product.amount;

        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).requestId =
          oi8.requestId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).sfdcCompensationId =
          oi8.sfdcCompensationId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).sfdcOpportunityId =
          oi8.sfdcOpportunityId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).flightRequestId =
          oi8.flightRequestId;
        if (oi8.requestId === 0) {
          (orderItemSerializable as contracts.ConciergeOrderItemSerializable).serviceDate = moment(
            oi8.serviceDate
          ).format('Y-MM-DD');
        }
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).departureDate =
          oi8.departureDate;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).vendorId = oi8.vendorId;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).vendorRefNumber =
          oi8.vendorRefNumber;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).quoteAmount =
          oi8.quoteAmount;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).markupPercent =
          oi8.markupPercent;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).clientComments =
          oi8.clientComments?.trim() ? oi8.clientComments.trim() : null;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).comments =
          oi8.comments?.trim() ? oi8.comments.trim() : null;
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).discount = oi8.discount;
        if (!oi8.isCreditMemo) {
          (orderItemSerializable as contracts.ConciergeOrderItemSerializable).cateringCredit =
            oi8.cateringCredit;
        }
        (orderItemSerializable as contracts.ConciergeOrderItemSerializable).serviceDiscountAmount =
          oi8.serviceDiscountAmount;

        if (oi8.creditMethod) {
          (orderItemSerializable as contracts.ConciergeOrderItemSerializable).creditMethod =
            oi8.creditMethod;
        }

        if (oi8.creditReason) {
          (orderItemSerializable as contracts.ConciergeOrderItemSerializable).creditReasons =
            oi8.creditReason.map(({ creditReasonId }) => creditReasonId);
        }

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      case models.OrderItemTypeEnum.Charter: {
        orderItemSerializable = new contracts.CharterOrderItemSerializable();
        const oi9 = orderItem as models.CharterOrderItem;

        (orderItemSerializable as contracts.CharterOrderItemSerializable).salesAction =
          models.SalesActionEnum.New;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productId =
          oi9.product.id;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productTypeId =
          oi9.product.productType.id;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).productName =
          oi9.product.name;
        (
          orderItemSerializable as contracts.CharterOrderItemSerializable
        ).productProductTypeParentProductTypeId = oi9.product.productType.parentProductTypeId;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).quantity = oi9.quantity;
        orderItemSerializable.basePrice = oi9.product.amount;

        (orderItemSerializable as contracts.CharterOrderItemSerializable).requestId = oi9.requestId;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).purchaseOrderNumber =
          oi9.purchaseOrderNumber;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).formUrlKey =
          oi9.formUrlKey;
        (orderItemSerializable as contracts.CharterOrderItemSerializable).charterInvoiceType =
          oi9.charterInvoiceType;

        orderItemSerializable.orderItemType = orderItem.orderItemType;
        break;
      }

      default: {
        throw new Error(`Wrong orderItem.orderItemType: ${orderItem.orderItemType}`);
      }
    }

    orderItemSerializable.id = orderItem.id;
    orderItemSerializable.orderId = orderItem.orderId;
    orderItemSerializable.clientId = orderItem.client ? orderItem.client.id : null;
    orderItemSerializable.discount = orderItem.discount;
    orderItemSerializable.totalPrice = orderItem.totalPrice;
    orderItemSerializable.notes = orderItem.notes;

    if (orderItem.parentOrderItem) {
      orderItemSerializable.parentOrderItemId = orderItem.parentOrderItem.id;
    }

    return orderItemSerializable;
  }
}
