import { Injectable } from '@angular/core';
import * as contracts from '../../../domain/service-contracts/promotion';

import { Observable } from 'rxjs';

@Injectable()
export class PromotionMockService implements contracts.IPromotionService {
  getPromotion(request: contracts.GetPromotionRequest): Observable<contracts.GetPromotionResponse> {
    throw new Error('Not implemented');
  }
}
