import { AircraftModel } from './aircraft-model';
import { AircraftCategory } from './aircraft-category';
import { Operator } from './operator';
import { Amenity } from './amenity';
import { AircraftImage } from './aircraft-image';

export class Aircraft {
  aircraftId: number;
  aircraftModel: AircraftModel;
  tailNumber: string;
  marketableSeatCount: number;
  maxPax: number;
  category: AircraftCategory = null;
  operator: Operator;
  yom: Date = null;
  yor: Date = null;
  amenities: Array<Amenity> = [];
  images: Array<AircraftImage> = [];
  requiresOwnerApproval: boolean = false;


  getCategory(): AircraftCategory {
    return this.category;
  }

  setCategory(category: AircraftCategory): Aircraft {
    this.category = category;

    return this;
  }

  getAircraftModel(): AircraftModel {
    return this.aircraftModel;
  }

  setAircraftModel(aircraftModel: AircraftModel): Aircraft {
    this.aircraftModel = aircraftModel;

    return this;
  }

  getOperator(): Operator {
    return this.operator;
  }

  setSetOperator(operator: Operator): Aircraft {
    this.operator = operator;

    return this;
  }

  getYom(): Date {
    return this.yom;
  }

  setYom(yom: Date): Aircraft {
    this.yom = yom;

    return this;
  }

  getYor(): Date {
    return this.yor;
  }

  setYor(yor: Date): Aircraft {
    this.yor = yor;

    return this;
  }

  getAmenities(): Array<Amenity> {
    return this.amenities;
  }

  setAmenities(amenities: Array<Amenity>): Aircraft {
    this.amenities = amenities;

    return this;
  }

  getImages(): Array<AircraftImage> {
    return this.images;
  }

  setImages(images: Array<AircraftImage>): Aircraft {
    this.images = images;

    return this;
  }

  getRequiresOwnerApproval(): boolean {
    return this.requiresOwnerApproval;
  }

  setRequiresOwnerApproval(requiresOwnerApproval: boolean): Aircraft {
    this.requiresOwnerApproval = requiresOwnerApproval;

    return this;
  }
}

