import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction('[TaskMgmt.LegSourceCollection] Load');
export const loadSuccess = createAction(
  '[TaskMgmt.LegSourceCollection] Load Success',
  props<{ payload: Array<models.LegSource> }>()
);
export const loadFail = createAction('[TaskMgmt.LegSourceCollection] Load Fail');

export const setInitial = createAction('[TaskMgmt.LegSourceCollection] Set Initial');

export class UpdateLegSourcePayload {
  dueDateDeltaTime: string;
  dueDateInitialTimeField: string;
  dueDateSetTime: string;
  taskCategoryId: number;
  taskTypeId?: number;
  title: string;
  active: boolean;
  dueDateUponCreation: boolean;
}
export const updateLegSource = createAction(
  '[TaskMgmt.LegSourceCollection] Update Leg Source',
  props<{ payload: UpdateLegSourcePayload }>()
);
export const updateLegSourceSuccess = createAction(
  '[TaskMgmt.LegSourceCollection] Update Leg Source Success',
  props<{ payload: models.LegSource }>()
);
export const updateLegSourceFail = createAction(
  '[TaskMgmt.LegSourceCollection] Update Leg Source Fail'
);

export const createLegSource = createAction(
  '[TaskMgmt.LegSourceCollection] Create Leg Source',
  props<{ payload: UpdateLegSourcePayload }>()
);
export const createLegSourceSuccess = createAction(
  '[TaskMgmt.LegSourceCollection] Create Leg Source Success',
  props<{ payload: models.LegSource }>()
);
export const createLegSourceFail = createAction(
  '[TaskMgmt.LegSourceCollection] Create Leg Source Fail'
);
