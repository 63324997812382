export class Permissions {
  clientsModule: {
    overall: boolean;
    lookup: boolean;
    universalSearch: boolean;
  };
  charterSalesModule: {
    overall: boolean;
    opportunities: boolean;
    myOpportunities: boolean;
    newOpportunities: boolean;
  };
  explorerModule: {
    search: boolean;
  };
  marketplaceModule: {
    overall: boolean;
    oneWayQuotes: boolean;
  };
  resourceModule: {
    overall: boolean;
    aircraft: boolean;
    airports: boolean;
    applicationConfigs: boolean;
    areas: boolean;
    markets: boolean;
    regions: boolean;
    vendors: boolean;
    users: boolean;
  };
  salesModule: {
    overall: boolean;
    new: boolean;
    orders: boolean;
    pricingCalculator: boolean;
    pricingComparison: boolean;
  };
  sourcingModule: {
    overall: boolean;
    requests: boolean;
    myRequests: boolean;
    new: boolean;
  };
  taskMgmtModule: {
    overall: boolean;
    tasks: boolean;
  };
  yieldMgmtModule: {
    overall: boolean;
    heatMap: boolean;
    configurations: boolean;
    seatCharts: boolean;
    seatPricing: boolean;
    cyoPricing: boolean;
    charterPricing: boolean;
  };
  userMgmtModule: {
    overall: boolean;
    users: boolean;
  };

  constructor() {
    this.clientsModule = {
      overall: false,
      lookup: false,
      universalSearch: false,
    };
    this.charterSalesModule = {
      overall: false,
      opportunities: false,
      myOpportunities: false,
      newOpportunities: false,
    };
    this.explorerModule = {
      search: false,
    };
    this.marketplaceModule = {
      overall: false,
      oneWayQuotes: false,
    };
    this.resourceModule = {
      overall: false,
      aircraft: false,
      airports: false,
      applicationConfigs: false,
      areas: false,
      markets: false,
      regions: false,
      vendors: false,
      users: false,
    };
    this.salesModule = {
      overall: false,
      new: false,
      orders: false,
      pricingComparison: false,
      pricingCalculator: false,
    };
    this.sourcingModule = {
      overall: false,
      requests: false,
      myRequests: false,
      new: false,
    };
    this.taskMgmtModule = {
      overall: false,
      tasks: false,
    };
    this.yieldMgmtModule = {
      overall: false,
      heatMap: false,
      configurations: false,
      seatCharts: false,
      seatPricing: false,
      cyoPricing: false,
      charterPricing: false,
    };
    this.userMgmtModule = {
      overall: false,
      users: false,
    };
  }
}
