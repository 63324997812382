import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export class LoadCharterRequestsPayload {
  constructor(public clientId: number) {}
}
export const loadCharterRequests = createAction(
  '[Sales.ClientRequests] Load Charter Requests',
  props<{ payload: LoadCharterRequestsPayload }>()
);
export const loadCharterRequestsSuccess = createAction(
  '[Sales.ClientRequests] Load Charter Requests Success',
  props<{ payload: Array<models.CharterClientRequest> }>()
);
export const loadCharterRequestsFail = createAction(
  '[Sales.ClientRequests] Load Charter Requests Fail'
);

export class LoadFlightRequestsPayload {
  constructor(public clientId: number, public isRefreshed?: boolean) {}
}
export const loadFlightRequests = createAction(
  '[Sales.ClientRequests] Load Flight Requests',
  props<{ payload: LoadFlightRequestsPayload }>()
);
export const loadFlightRequestsSuccess = createAction(
  '[Sales.ClientRequests] Load Flight Requests Success',
  props<{ payload: Array<models.ClientRequest> }>()
);
export const loadFlightRequestsFail = createAction(
  '[Sales.ClientRequests] Load Flight Requests Fail'
);

export class LoadConciergeAncillaryDataPayload {
  constructor(public clientId: number) {}
}
export const loadConciergeAncillaryData = createAction(
  '[Sales.ClientRequests] Load Concierge Ancillary Data',
  props<{ payload: LoadConciergeAncillaryDataPayload }>()
);
export const loadConciergeAncillaryDataSuccess = createAction(
  '[Sales.ClientRequests] Load Concierge Ancillary Data Success',
  props<{ payload: models.ConciergeFlightAncillaryData }>()
);
export const loadConciergeAncillaryDataFail = createAction(
  '[Sales.ClientRequests] Load Concierge Ancillary Data Fail'
);

// CreateCharterRequestsAction
export class CreateCharterInvoicePayload {
  constructor(
    public requestId: number,
    public charterInvoiceType: models.CharterInvoiceTypeEnum,
    public proformaAdditionalInvoiceOption: models.ProformaAdditionalInvoiceOptionsEnum
  ) {}
}
export const createCharterInvoice = createAction(
  '[Sales.ClientRequests] Create Charter Invoice',
  props<{ payload: CreateCharterInvoicePayload }>()
);
export const createCharterInvoiceSuccess = createAction(
  '[Sales.ClientRequests] Create Charter Invoice Success'
);
export const createCharterInvoiceFail = createAction(
  '[Sales.ClientRequests] Create Charter Invoice Fail'
);

export class RemoveCharterInvoicePayload {
  formUrlKey: string;
}
export const removeCharterInvoice = createAction(
  '[Sales.ClientRequests] Remove Charter Invoice',
  props<{ payload: RemoveCharterInvoicePayload }>()
);
export const removeCharterInvoiceSuccess = createAction(
  '[Sales.ClientRequests] Remove Charter Invoice Success'
);
export const removeCharterInvoiceFail = createAction(
  '[Sales.ClientRequests] Remove Charter Invoice Fail'
);

export const loadSimplifiedMembership = createAction(
  '[Sales.ClientRequests] Load Simplified Membership',
  props<{ payload: number }>()
);
export const loadSimplifiedMembershipSuccess = createAction(
  '[Sales.ClientRequests] Load Simplified Membership Success',
  props<{ payload: models.SimplifiedMembershipEnum }>()
);
export const loadSimplifiedMembershipFail = createAction(
  '[Sales.ClientRequests] Load Simplified Membership Fail'
);

export const setInitial = createAction('[Sales.ClientRequests] Set Initial');

export const setLegRequests = createAction(
  '[Sales.ClientRequests] Set Leg Requests',
  props<{ requestId: number }>()
);
