import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromAircraftAmenityCollection from './aircraft-amenity-collection.reducer';
import * as fromAircraftCategoryCollection from './aircraft-category-collection.reducer';
import * as fromAircraftModelCollection from './aircraft-model-collection.reducer';
import * as fromMyOpportunityList from './my-opportunity-list.reducer';
import * as fromOperatorCollection from './operator-collection.reducer';
import * as fromOpportunity from './opportunity.reducer';
import * as fromOpportunityList from './opportunity-list.reducer';
import * as fromOpportunityNew from './opportunity-new.reducer';
import * as fromOpportunityStatusCollection from './opportunity-status-collection.reducer';
import * as fromPricingCalculatorOpportunity from './opportunity-new-pc.reducer';
import * as fromQuoteAttributesCollection from './quote-attributes-collection.reducer';
import * as fromRating from './rating.reducer';
import * as fromSalesAgentCollection from './sales-agent-collection.reducer';
import * as fromServiceClass from './service-class-collection.reducer';
import * as fromSourcingSpecialistCollection from './sourcing-specialist-collection.reducer';
import * as fromError from './error.reducer';

export interface State {
  [fromAircraftAmenityCollection.aircraftAmenityCollectionFeatureKey]: fromAircraftAmenityCollection.State;
  [fromAircraftCategoryCollection.aircraftCategoryCollectionFeatureKey]: fromAircraftCategoryCollection.State;
  [fromAircraftModelCollection.aircraftModelCollectionFeatureKey]: fromAircraftModelCollection.State;
  [fromMyOpportunityList.myOpportunityListFeatureKey]: fromMyOpportunityList.State;
  [fromError.errorOpportunityFeatureKey]: fromError.State;
  [fromOperatorCollection.operatorCollectionFeatureKey]: fromOperatorCollection.State;
  [fromOpportunity.opportunityFeatureKey]: fromOpportunity.State;
  [fromOpportunityList.opportunityListFeatureKey]: fromOpportunityList.State;
  [fromOpportunityNew.opportunityNewFeatureKey]: fromOpportunityNew.State;
  [fromOpportunityStatusCollection.opportunityStatusCollectionFeatureKey]: fromOpportunityStatusCollection.State;
  [fromPricingCalculatorOpportunity.opportunityNewPcFeatureKey]: fromPricingCalculatorOpportunity.State;
  [fromQuoteAttributesCollection.quoteAttributesCollectionFeatureKey]: fromQuoteAttributesCollection.State;
  [fromRating.ratingFeatureKey]: fromRating.State;
  [fromSalesAgentCollection.salesAgentCollectionFeatureKey]: fromSalesAgentCollection.State;
  [fromServiceClass.serviceClassCollectionFeatureKey]: fromServiceClass.State;
  [fromSourcingSpecialistCollection.sourcingSpecialistCollectionFeatureKey]: fromSourcingSpecialistCollection.State;
}

export const featureName = 'charterSales';

export const reducers = combineReducers<State, Action>({
  [fromAircraftAmenityCollection.aircraftAmenityCollectionFeatureKey]:
    fromAircraftAmenityCollection.reducer,
  [fromAircraftCategoryCollection.aircraftCategoryCollectionFeatureKey]:
    fromAircraftCategoryCollection.reducer,
  [fromAircraftModelCollection.aircraftModelCollectionFeatureKey]:
    fromAircraftModelCollection.reducer,
  [fromMyOpportunityList.myOpportunityListFeatureKey]: fromMyOpportunityList.reducer,
  [fromError.errorOpportunityFeatureKey]: fromError.reducer,
  [fromOperatorCollection.operatorCollectionFeatureKey]: fromOperatorCollection.reducer,
  [fromOpportunity.opportunityFeatureKey]: fromOpportunity.reducer,
  [fromOpportunityList.opportunityListFeatureKey]: fromOpportunityList.reducer,
  [fromOpportunityNew.opportunityNewFeatureKey]: fromOpportunityNew.reducer,
  [fromOpportunityStatusCollection.opportunityStatusCollectionFeatureKey]:
    fromOpportunityStatusCollection.reducer,
  [fromPricingCalculatorOpportunity.opportunityNewPcFeatureKey]:
    fromPricingCalculatorOpportunity.reducer,
  [fromQuoteAttributesCollection.quoteAttributesCollectionFeatureKey]:
    fromQuoteAttributesCollection.reducer,
  [fromRating.ratingFeatureKey]: fromRating.reducer,
  [fromSalesAgentCollection.salesAgentCollectionFeatureKey]: fromSalesAgentCollection.reducer,
  [fromServiceClass.serviceClassCollectionFeatureKey]: fromServiceClass.reducer,
  [fromSourcingSpecialistCollection.sourcingSpecialistCollectionFeatureKey]:
    fromSourcingSpecialistCollection.reducer,
});

export const getCharterSalesState = createFeatureSelector<State>(featureName);
