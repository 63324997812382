import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ChangeLogItem } from '../../domain/models';

export const LOAD = '[RM User Change Log Collection] Load';
export const LOAD_SUCCESS = '[RM User Change Log Collection] Load Success';
export const LOAD_FAIL = '[RM User Change Log Collection] Load Fail';

export class LoadPayload {
  skip: number;
  take: number;
  sort: Array<SortDescriptor>;
  userId: number;

  constructor() {
    this.skip = 0;
    this.take = 100;
    this.sort = [{field: 'changeTime', dir: 'desc'}];
  }
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {
  }
}

export class LoadSuccessPayload {
  constructor(public items: Array<ChangeLogItem>, public userId: number) {
  }
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload: number) {
  }
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
