import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/console-login-check.actions';
import * as _ from 'lodash';

export const consoleLoginCheckFeatureKey = 'consoleLoginCheck';

export interface State {
  url: string;
  requestPayload: any;
  isLoggedIn: boolean;
  isLoading: boolean;
}

const initialState: State = {
  url: null,
  requestPayload: null,
  isLoading: false,
  isLoggedIn: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.login,
    (state): State => ({
      ...state,
      isLoading: true,
      isLoggedIn: false,
    })
  ),
  on(
    actions.loginSuccess,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoggedIn: true,
    })
  ),
  on(
    actions.loginFail,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoggedIn: false,
    })
  ),

  on(
    actions.setPayload,
    (state, { payload }): State => ({
      ...state,
      requestPayload: _.cloneDeep(payload),
    })
  ),

  on(
    actions.setUrl,
    (state, { payload }): State => ({
      ...state,
      url: _.cloneDeep(payload),
    })
  )
);
