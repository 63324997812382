import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class SfdcBonusCreditTransferOrderItem extends OrderItem {
  sfdcBonusCreditTransfer: number = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.BonusCreditTransfer;
  }
}
