import * as moment from 'moment';
import { Paginable } from './paginable';

export type ProfitabilityMarginSymbolType = 'EQ' | 'LE' | 'GE' | null;
export type PriceOverrideType = 'ACTIVE_APPLICABLE' | 'ACTIVE_NOT_APPLICABLE' | 'INACTIVE';

export class FilterCriteria {
  legIds: Array<number> = [];
  startDate: Date = moment().toDate();
  startTime: Date = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
  endDate: Date = moment().add(3, 'months').toDate();
  endTime: Date = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate();
  dow: Array<number> = [1, 2, 3, 4, 5, 6, 7];
  markets: Array<{ from: string, to: string }> = [
    { from: 'NEW YORK', to: 'SOUTH FLORIDA' },
    { from: 'SOUTH FLORIDA', to: 'NEW YORK' }
  ];
  startSeat: number = 0;
  endSeat: number = 20;
  profitabilityMargin: number = -100;
  profitabilityMarginOperator: ProfitabilityMarginSymbolType = 'GE';
  priceOverride: Array<PriceOverrideType> = ['ACTIVE_APPLICABLE', 'ACTIVE_NOT_APPLICABLE', 'INACTIVE'];

  get startDateFormatted(): string {
    return moment(this.startDate, moment.ISO_8601).format('YYYY-MM-DDTHH:mm');
  }
}
