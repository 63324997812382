import { CyoPricingTerm } from './cyo-pricing-term';

export class CyoPricingRule {
  id: string = null;
  marketId: string = null;
  routeGroupName: string = null;
  aircraftCategoryEft: number = null;
  aircraftCategory: string = null;
  offerType = 'Regular';
  costPerSeat: number = null;
  creatorSeatsMultiplier: number = null;
  maxCostFlightOneWay: number = null;

  maxDailyEndTime: Date = null;
  minDailyStartTime: Date = null;

  shuttleTypeName = 'VIP Custom Shuttle';
  totalSeats: number = null;
  airportFrom: Array<string> = [];
  airportTo: Array<string> = [];

  pricingTerms: Array<CyoPricingTerm> = [];
}
