import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/pricing-meta.actions';
import * as models from '../../domain/models';

export const pricingMetaFeatureKey = 'pricingMeta';

export interface State {
  loaded: boolean;
  loading: boolean;
  item: models.PricingMeta;
}

const initialState: State = {
  loaded: false,
  loading: false,
  item: null,
};

export const reducer = createReducer(
  initialState,

  on(actions.load, (state): State => ({ ...state, loading: true })),
  on(
    actions.loadSuccess,
    (_, { payload }): State => ({
      loaded: true,
      loading: false,
      item: payload.meta,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(actions.setInitial, (): State => initialState)
);
