import * as codeModels from '../../../../core/models';

export class Dba {
  id: codeModels.Dba;
  name: string;

  getId(): codeModels.Dba {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  setId(id: codeModels.Dba): Dba {
    this.id = id;
    return this;
  }

  setName(name: string): Dba {
    this.name = name;
    return this;
  }
}
