import * as models from '../../domain/models';
import * as actions from '../actions/vendor-collection';

import * as _ from 'lodash';

export interface State {
  items: Array<models.Vendor>;
}

const initialState: State = {
  items: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        items: _.cloneDeep(action.payload.items),
      };
    }

    default: {
      return state;
    }
  }
}

export const getItems = (state: State) => state.items;
