import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

import * as contracts from './contracts';
import * as mapper from './mapper/user-mapper.service';
import * as helpers from '../../helpers';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { IConfigService } from '../config/contracts';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserService implements contracts.IUserService {
  constructor(private http: HttpClient,
              private cookieService: CookieService,
              private configService: IConfigService) {
  }

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {
    const OPERATOR_API_PATH = this.configService.get('OperatorApiServiceEndpoint');
    const JET_SALES_PATH = this.configService.get('JetSalesServiceEndpoint');
    const menuPin = this.cookieService.get('JetStudio.menuPinPreference');

    return forkJoin(this.http.get(`${OPERATOR_API_PATH}/operators/user/current`),
      this.http.get(`${JET_SALES_PATH}/v1.0/security/get-security-data`)
        .pipe(catchError(() => {
          return of(null);
        }))).pipe(
      map(([currentUser, securityData]: Array<any>) => {
        const response = new contracts.GetUserResponse();
        if (currentUser) {
          if (securityData) {
            currentUser.restrictedFunctions = _.get(securityData, 'data.restrictedFunctions', null);
          }
          response.entity = mapper.UserMapperService.mapUserDtoToInternal(currentUser);
        }
        sessionStorage.setItem('id', String(response.entity.id));
        response.environment = _.get(environment, 'serverEnvironment', 'prod');
        response.menuPin = menuPin !== undefined && menuPin !== null ? Number(menuPin) : 0;

        return response;
      })
    );
  }

  setUserMenuPinPreference(request: contracts.SetUserMenuPinRequest): Observable<contracts.SetUserMenuPinResponse> {
    this.cookieService.put('JetStudio.menuPinPreference', request.menuPin + '');

    return of(new contracts.SetUserMenuPinResponse());
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {

    // ToDo Remove after implementing auth0 authorization in production
    if(helpers.isLegacyAuth() && !request.isLegacyAuthInterceptorError) {
      localStorage.removeItem('isLegacy');
    }

    const host = location.host.split('.');
    if (host.length > 2) {
      let i = 2;
      while (i < host.length) {
        const subdomain = '.' + location.host.split('.').splice(-i, i).join('.');
        i++;
        this.cookieService.removeAll({domain: subdomain});
      }
    }
    this.cookieService.removeAll();
    return of(new contracts.SignOutResponse());
  }
}
