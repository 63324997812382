import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class PromotionCreditAdjustmentOrderItem extends OrderItem {
  promotionalCreditAmount = 0;
  modifiesTotal = false;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.PromotionalCredit;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.promotionalCreditAmount;
  }
}
