export class Leg {
  actualArrival: Date;
  actualDeparture: Date;
  airTravel: number;
  aircraft: AircraftInfo;
  floorPlanUrl: string;
  arrivalFbo: Fbo;
  id: number;
  maxPax: number;
  modelName: string;
  operatorName: string;
  tailNumber: string;
  aircraftAndCrewInPositionTime: any;
  aircraftInPositionTime: any;
  aircraftInspectionId: any;
  aircraftOffRampTime: any;
  aircraftServicesCompletedTime: any;
  allPassengersLoadedTime: any;
  arrivalDelayCode: any;
  arrivalDelayCodeDescription: string;
  arrivalTime: Date;
  arrivalTimeBasedOnEFT: Date;
  arrivalTimeLocal: Date;
  bookingStatusId: number;
  cateringLoadedTime: Date;
  cateringVerifiedTime: Date;
  completedTasks: number;
  creatorClientId: string;
  creatorLegalName: string;
  crewInPositionTime: Date;
  departureDelayCode: number;
  departureDelayCodeDescription: string;
  departureTime: Date;
  departureTimeLocal: Date;
  doorsClosedTime: Date;
  estimatedFlightTime: number;
  externalId: number;
  hasPaxWithAnimalAllergies: boolean;
  importDate: Date;
  isVip: boolean;
  k9ScreeningConductedTime: Date;
  lastUpdateDate: Date;
  legId: number;
  legTypeName: string;
  operator: Operator;
  overriddenArrivalTime: Date;
  overriddenArrivalTimeLocal: Date;
  overriddenDepartureTime: Date;
  overriddenDepartureTimeLocal: Date;
  departureFbo: Fbo;
  passengers: Array<Passenger>;
  paxCount: number;
  pilotCheckedIDsTime: Date;
  pilotsInCommandVerifiedTime: Date;
  route: Route;
  shuttleNotes: string;
  totalWeight: number;
  totalTasks: number;
  uuid: string;
}

export class Route {
  distance: number;
  groupId: number;
  groupUiColorHex: string;
  departureAirport: AirportInfo;
  arrivalAirport: AirportInfo;
  groupName: string;
}

export class AirportInfo {
  id: string;
  code: string;
  address: Address;
  timezone: string;
}

export class AircraftInfo {
  floorPlanUrl: string;
  id: number;
  maxPax: number;
  modelName: string;
  operatorName: string;
  tailNumber: string;
}

export class AircraftItemInspectionType {
  answerRequired: boolean;
  description: string;
  id: number;
  pictureRequired: boolean;
}

export class PassengerFlightStatus {
  id: number;
  name: string;
}

export class Fbo {
  address: Address;
  id: number;
  name: string;
  phoneNumber: string;
}

export class Address {
  city: string;
  country: string;
  stateProvince: string;
  postalCode: string;
  line1: string;
}

export class Operator {
  legalName: string;
}

export class Passenger {
  avatarUrl: string;
  boardingStatusChangedTime: Date;
  clientId: number;
  comments: any;
  dateOfBirth: Date;
  facePhoto: null;
  flightStatusId: number;
  heliIn: false;
  heliOut: false;
  id: number;
  isSophisticatedMembership: boolean;
  leader: boolean;
  legalName: string;
  membershipName: string;
  payerLegalName: string;
  phoneNumber: string;
  placeOfBirth: string;
  requestId: number;
  seatNumber: number;
  staff: boolean;
  vip: boolean;
  weight: number;
  paxFlightStatus: PassengerFlightStatus;
}

export class LegRequest {
  legId: number;
  actualArrival: Date;
  actualDeparture: Date;
  departureDelayCodeId: number;
  arrivalDelayCodeId: number;
  dirty?: boolean;
  taskId?: number;
}
