import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Market Collection] Load';
export const LOAD_SUCCESS = '[RM Market Collection] Load Success';
export const LOAD_FAIL = '[RM Market Collection] Load Fail';
export const ADD_MARKET = '[RM Market Collection] Add Market';
export const ADD_MARKET_SUCCESS = '[RM Market Collection] Add Market Success';
export const ADD_MARKET_FAIL = '[RM Market Collection] Add Market Fail';
// export const SET_MARKET = '[RM Market Collection] Set Market';
// export const SAVE = '[RM Market Collection] Save';
// export const SAVE_SUCCESS = '[RM Market Collection] Save Success';
// export const SAVE_FAIL = '[RM Market Collection] Save Fail';

export class LoadPayload {
  constructor(public lookup: viewModels.Lookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<viewModels.ListItem>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddMarketAction implements Action {
  readonly type = ADD_MARKET;

  constructor(public payload: AddMarketPayload) {}
}

export class AddMarketPayload {
  constructor(public item: viewModels.AddMarket) {}
}

export class AddMarketSuccessAction implements Action {
  readonly type = ADD_MARKET_SUCCESS;

  constructor(public payload: AddMarketSuccessPayload) {}
}

export class AddMarketSuccessPayload {
  constructor(public item: models.Market) {}
}

export class AddMarketFailAction implements Action {
  readonly type = ADD_MARKET_FAIL;
}

// export class SetMarketAction implements Action {
//   readonly type = SET_MARKET;

//   constructor(public payload: SetMarketPayload) {
//   }
// }

// export class SetMarketPayload {
//   constructor(public item: models.Market) {
//   }
// }

// export class SaveAction implements Action {
//   readonly type = SAVE;
// }

// export class SaveSuccessPayload {
//   constructor(public item: models.Market) {
//   }
// }

// export class SaveSuccessAction implements Action {
//   readonly type = SAVE_SUCCESS;

//   constructor(public payload: SaveSuccessPayload) {
//   }
// }

// export class SaveFailAction implements Action {
//   readonly type = SAVE_FAIL;
// }

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddMarketAction
  | AddMarketSuccessAction
  | AddMarketFailAction;
// | SetMarketAction
// | SaveAction
// | SaveSuccessAction
// | SaveFailAction;
