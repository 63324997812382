import { CyoPricingRule } from '../../domain/models';
import { AircraftCategory } from '../../domain/models';
import * as _ from 'lodash';

export class CyoPricingRuleWrapper extends CyoPricingRule {
  edited = false;
  availableAircraftCategories: Array<AircraftCategory> = [];
  allAirportFrom: Map<string, boolean> = new Map<string, boolean>();
  allAirportTo: Map<string, boolean> = new Map<string, boolean>();

  getSelectedCategoryId(): number {
    if (this.availableAircraftCategories.length === 0 || !this.aircraftCategory) {
      return null;
    }
    const right = this.availableAircraftCategories.find(
      (ac) => ac.getName() === this.aircraftCategory
    );

    return right !== undefined ? right.getId() : null;
  }
}
