import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateWithPostfix' })
export class DateWithPostfixPipe implements PipeTransform {
	transform(
		value: Date | string | number, 
		postfix: string = '',
		format?: string
	): string {
		const date = new DatePipe('en-US').transform(value, format)
		return date ? `${date} ${postfix}` : '';
	}
}