import { CostType } from '../models/cost-type';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CostTypeDto } from '../../services/cost-types/cost-types/dto/cost-type-dto';

export interface ICostTypesService {
  getAll(): Observable<CostTypeListResponse>;
}

export const COST_TYPE_SERVICE_TOKEN = new InjectionToken('CostTypes.ICostTypesService');

export class CostTypeListResponse {
  code: number;
  message: string;
  costTypes: Array<CostType>;
}

export class GetAllCostTypesResponse {
  code: number;
  message: string;
  data: Array<CostTypeDto>;
}
