import * as actions from '../actions/invoice';
import * as models from '../../models';

import { createReducer, on } from '@ngrx/store';

export interface State {
  loaded: boolean;
  loading: boolean;
  invoice: models.Invoice;
  purchasePriceLoaded: boolean;
  purchasePriceLoading: boolean;
  purchasePriceAmount: number;
}

const initialState: State = {
  loaded: false,
  loading: false,
  invoice: null,
  purchasePriceLoaded: false,
  purchasePriceLoading: false,
  purchasePriceAmount: null,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),

  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      loaded: true,
      loading: false,
      invoice: payload.invoice,
    })
  ),

  on(
    actions.loadFailed,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),
  on(
    actions.loadPurchasePrice,
    (state): State => ({
      ...state,
      purchasePriceLoading: true,
    })
  ),

  on(
    actions.loadPurchasePriceSuccess,
    (state, { payload }): State => ({
      ...state,
      purchasePriceLoaded: true,
      purchasePriceLoading: false,
      purchasePriceAmount: payload.amount,
    })
  ),

  on(
    actions.loadPurchasePriceFailed,
    (state): State => ({
      ...state,
      purchasePriceLoaded: true,
      purchasePriceLoading: false,
    })
  ),
  on(actions.clear, (state): State => initialState)
);

//
// export function reducer(state = initialState, action: actions.Actions): State {
//   switch (action.type) {
//     case actions.LOAD: {
//       return { ...state, loading: true };
//     }
//
//     case actions.LOAD_SUCCESS: {
//       const { payload } = action;
//       return {
//         loaded: true,
//         loading: false,
//         invoice: payload,
//       };
//     }
//
//     case actions.LOAD_FAIL: {
//       return {
//         ...state,
//         loaded: true,
//         loading: false,
//       };
//     }
//
//     default: {
//       return state;
//     }
//   }
// }

// export const getLoaded = (state: State) => state.loaded;
// export const getLoading = (state: State) => state.loading;
// export const getInvoice = (state: State) => state.invoice;
