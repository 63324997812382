import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs';

import * as helpers from '../helpers';

@Injectable()
export class SsoInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ToDo Update after implementing auth0 authorization in production
    let modified: HttpRequest<any>;
    modified = httpRequest;
    if (!helpers.isLegacyAuth()) {
      modified = httpRequest.clone({ setHeaders: { sso: `true`, application: 'JS' } });
    }

    return next.handle(modified);
  }
}
