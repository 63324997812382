import { Route } from './route';

export class LegRequest {
  route: Route = new Route();
  departureDate: Date;
  newDepartureTime: Date;
  departureTimeUtc: Date;
  departureTimeTbd = false;
  pax: number;
  originIcaoCode?: string;
  destinationIcaoCode?: string;
  destinationFboId: number;
  originFboId: number;
  eft?: number;
  toIsTechStop: boolean;
  newOriginIcaoCode?: string;
  newDestinationIcaoCode?: string;

  getOriginIcaoCode(): string {
    return this.originIcaoCode;
  }

  setOriginIcaoCode(originIcaoCode: string): LegRequest {
    this.originIcaoCode = originIcaoCode;
    return this;
  }

  getDestinationIcaoCode(): string {
    return this.destinationIcaoCode;
  }

  setDestinationIcaoCode(destinationIcaoCode: string): LegRequest {
    this.destinationIcaoCode = destinationIcaoCode;
    return this;
  }

  getDepartureDate(): Date {
    return this.departureDate;
  }

  setDepartureDate(date: Date): LegRequest {
    if (this.departureDate) {
      const d1 = new Date(this.departureDate.getTime());
      const d2 = new Date(date.getTime());
      d2.setHours(d1.getHours(), d1.getMinutes(), d1.getSeconds(), d1.getMilliseconds());
      this.departureDate = d2;
    } else {
      this.departureDate = new Date(date);
    }

    return this;
  }

  getDepartureTime(): Date {
    return this.departureDate;
  }

  setDepartureTime(date: Date): LegRequest {
    this.departureDate.setHours(
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );

    this.departureTimeTbd = false;

    return this;
  }

  getDepartureTimeTbd(): boolean {
    return this.departureTimeTbd;
  }

  setDepartureTimeTbd(value: boolean): LegRequest {
    this.departureTimeTbd = value;

    if (this.departureTimeTbd && this.departureDate) {
      this.departureDate.setHours(0, 0, 0, 0);
    }

    return this;
  }

  getRoute(): Route {
    return this.route;
  }

  setRoute(route: Route): LegRequest {
    this.route = route;

    return this;
  }

  getPax(): number {
    return this.pax;
  }

  setPax(pax: number): LegRequest {
    this.pax = pax;

    return this;
  }

  isValidForSubmit(): boolean {
    return (
      this.getDepartureDate() &&
      this.getRoute() &&
      this.getRoute().getOriginAirport() &&
      this.getRoute().getOriginAirport().code !== null &&
      this.getRoute().getOriginAirport().code.length === 4 &&
      this.getRoute().getDestinationAirport() &&
      this.getRoute().getDestinationAirport().code !== null &&
      this.getRoute().getDestinationAirport().code.length === 4 &&
      this.getPax() > 0
    );
  }

  setOriginFboId(id: number): LegRequest {
    this.originFboId = id;
    return this;
  }

  getOriginFboId(): number {
    return this.originFboId;
  }

  setDestinationFboId(id: number): LegRequest {
    this.destinationFboId = id;
    return this;
  }

  getDestinationFboId(): number {
    return this.destinationFboId;
  }

  getDepartureTimeUtc(): Date {
    return this.departureTimeUtc;
  }

  setDepartureTimeUtc(date: Date): LegRequest {
    this.departureTimeUtc = date;
    return this;
  }

  getNewDepartureTime(): Date {
    return this.newDepartureTime;
  }

  setNewDepartureTime(value: Date): LegRequest {
    this.newDepartureTime = value;
    return this;
  }

  getEft(): number {
    return this.eft;
  }

  setEft(value: number): LegRequest {
    this.eft = value;
    return this;
  }

  getTechStop(): boolean {
    return this.toIsTechStop;
  }

  setTechStop(value: boolean): LegRequest {
    this.toIsTechStop = value;

    return this;
  }

  getNewOriginIcaoCode(): string {
    return this.newOriginIcaoCode;
  }

  setNewOriginIcaoCode(originIcaoCode: string): LegRequest {
    this.newOriginIcaoCode = originIcaoCode;
    return this;
  }

  getNewDestinationIcaoCode(): string {
    return this.newDestinationIcaoCode;
  }

  setNewDestinationIcaoCode(destinationIcaoCode: string): LegRequest {
    this.newDestinationIcaoCode = destinationIcaoCode;
    return this;
  }
}
