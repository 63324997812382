import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as legListViewModels from '../../view/view-models/leg-list';

export const LOAD = '[YM Leg List] Load';
export const LOAD_SUCCESS = '[YM Leg List] Load Success';
export const LOAD_FAIL = '[YM Leg List] Load Fail';
export const LOAD_BY_ID = '[YM Leg List] Load By Id';
export const LOAD_BY_ID_SUCCESS = '[YM Leg List] Load By Id  Success';
export const UPDATE_FILTERS = '[YM Leg List] Update Filters';
export const RESET_FILTERS = '[YM Leg List] Reset Filters';
export const CLEAR_FILTERS = '[YM Leg List] Clear Filters';
export const UPDATE_SORTING = '[YM Leg List] Update Sorting';
export const UPDATE_SETTINGS = '[YM Leg List] Update Settings';
export const SELECT_ITEM = '[YM Leg List] Select item';
export const UPDATE_AIRPORT_GROUPS = '[YM Leg List] Update airport groups';
export const UPDATE_SUCCESS_AIRPORT_GROUPS = '[YM Leg List] Update success airport groups';

export class UpdateAirportGroupsAction implements Action {
  readonly type = UPDATE_AIRPORT_GROUPS;
}

export class UpdateSuccessAirportGroupsPayload {
  constructor(public legs: Array<models.Leg>) {}
}

export class UpdateSuccessAirportGroupsAction implements Action {
  readonly type = UPDATE_SUCCESS_AIRPORT_GROUPS;
  constructor(public payload: UpdateSuccessAirportGroupsPayload) {}
}

export class LoadByIdPayload {
  id: number;
}

export class LoadByIdAction implements Action {
  readonly type = LOAD_BY_ID;
  constructor(public payload: LoadByIdPayload) {}
}

export class LoadByIdSuccessPayload {
  leg: models.Leg;
}

export class LoadByIdSuccessAction implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;
  constructor(public payload: LoadByIdSuccessPayload) {}
}

export class LoadActionPayload {
  offset: number;
  limit: number;
  notRefresh: boolean;
}

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload?: LoadActionPayload) {}
}

export class LoadSuccessPayload {
  constructor(public legs: Array<models.Leg>, public lastUpdatedDate: Date) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class UpdateFilters implements Action {
  readonly type = UPDATE_FILTERS;

  constructor(public payload: legListViewModels.LegListFilters) {}
}

export class ResetFilters implements Action {
  readonly type = RESET_FILTERS;
}

export class ClearFilters implements Action {
  readonly type = CLEAR_FILTERS;
}

export class UpdateSorting implements Action {
  readonly type = UPDATE_SORTING;

  constructor(public payload: legListViewModels.LegListSorting) {}
}

export class UpdateSettings implements Action {
  readonly type = UPDATE_SETTINGS;

  constructor(public payload: legListViewModels.LegListSettings) {}
}

export class SelectItem implements Action {
  readonly type = SELECT_ITEM;

  constructor(public payload: number) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | UpdateFilters
  | ResetFilters
  | ClearFilters
  | UpdateSorting
  | SelectItem
  | UpdateAirportGroupsAction
  | UpdateSuccessAirportGroupsAction
  | UpdateSettings
  | LoadByIdAction
  | LoadByIdSuccessAction;
