import { Client } from './client';
import { OrderItemTypeEnum } from './order-item-type';
import { ProductOrderItem } from './product-order-item';
import { SalesActionEnum } from './sales-action-enum';

export class GroupMembershipSlotOrderItem extends ProductOrderItem {
  beneficiaries: Array<Client> = [];

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.GroupMembershipSlot;
  }
}
