import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const RESET = '[Sourcing.SourcingRequestList] Reset';
export const SEARCH = '[Sourcing.SourcingRequestList] Search';
export const SEARCH_SUCCESS = '[Sourcing.SourcingRequestList] Load Success';
export const SEARCH_FAIL = '[Sourcing.SourcingRequestList] Load Fail';
export const SET_PODS = '[Sourcing.SourcingRequestList] Set Pods';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SearchPayload {
  criteria: viewModels.SourcingRequestLookupCriteria;
  page: number;
  itemsPerPage: number;

  constructor(
    criteria: viewModels.SourcingRequestLookupCriteria,
    page: number,
    itemsPerPage: number
  ) {
    this.criteria = new viewModels.SourcingRequestLookupCriteria();

    if (criteria) {
      this.criteria = criteria;
    }

    this.page = page;
    this.itemsPerPage = itemsPerPage;
  }
}

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: SearchPayload) {}
}

export class SearchSuccessPayload {
  page: number;
  data: Array<models.SourcingRequest>;
  count: number;
}

export class SearchSuccessAction implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: SearchSuccessPayload) {}
}

export class SearchFailAction implements Action {
  readonly type = SEARCH_FAIL;
}

export class SetAction implements Action {
  readonly type = SET_PODS;

  constructor(public payload: Array<string>) {}
}

export type Actions = ResetAction 
  | SearchAction 
  | SearchSuccessAction 
  | SearchFailAction
  | SetAction;
