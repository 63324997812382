import { AircraftRating } from "./aircraft-rating";

export class Operator {
  id: string = null;
  name: string = null;
  legalName: string = null;
  argusRating: AircraftRating = new AircraftRating();
  wyvernRating: AircraftRating = new AircraftRating();
  isBaoRating: AircraftRating = new AircraftRating();
  levelFlightRating: AircraftRating = new AircraftRating();


  getOperatorId(): string {
    return this.id;
  }

  setOperatorId(id: string): Operator {
    this.id = id;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Operator {
    this.name = name;

    return this;
  }

  getLegalName(): string {
    return this.legalName;
  }

  setLegalName(legalName: string): Operator {
    this.legalName = legalName;

    return this;
  }

  getArgusRating(): AircraftRating {
    return this.argusRating;
  }

  setArgusRating(argusRating: AircraftRating): Operator {
    this.argusRating = argusRating;

    return this;
  }

  getWyvernRating(): AircraftRating {
    return this.wyvernRating;
  }

  setWyvernRating(wyvernRating: AircraftRating): Operator {
    this.wyvernRating = wyvernRating;

    return this;
  }

  getIsBaoRating(): AircraftRating {
    return this.isBaoRating;
  }

  setIsBaoRating(isBaoRating: AircraftRating): Operator {
    this.isBaoRating = isBaoRating;

    return this;
  }

  getLevelFlightRating(): AircraftRating {
    return this.levelFlightRating;
  }

  setLevelFlightRating(levelFlightRating: AircraftRating): Operator {
    this.levelFlightRating = levelFlightRating;

    return this;
  }

  getLegalNameAndName(): string {
    return this.legalName 
      && this.name 
      && this.legalName == this.name 
        ? this.legalName
        : `${this.legalName} (${this.name})`
  }

  equals(o1: Operator): boolean {
    if (!o1) {
      return false;
    }

    return this.id === o1.id;
  }
}
