import { LegRequest } from './leg-request';
import { Client } from './client';


export class SourcingRequest {
  sourcingRequestId: string;
  client: Client;
  legs: Array<LegRequest>;

  getSourcingRequestId(): string {
    return this.sourcingRequestId;
  }

  setSourcingRequestId(sourcingRequestId: string): SourcingRequest {
    this.sourcingRequestId = sourcingRequestId;
    return this;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): SourcingRequest {
    this.legs = legs;
    return this;
  }

  getLegsIcaoCodes(): string {
    if(this.legs && this.legs.length) {
      return this.legs.map(leg => `${leg.getOriginIcaoCode()} - ${leg.getDestinationIcaoCode()}`).join(', ');
    } else {
      return null;
    }
  }

  getClient(): Client {
    return this.client;
  }

  setClient(client: Client): SourcingRequest {
    this.client = client;

    return this;
  }
}
