import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Wyvern] Load';
export const LOAD_SUCCESS = '[RM Wyvern] Load Success';
export const LOAD_FAIL = '[RM Wyvern] Load Fail';
export const RESET = '[RM Wyvern] Reset';

export class LoadPayload {
  constructor(public id: number) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Wyvern) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | ResetAction;
