<div class="row">
    <div class="col">
        <ng-container *ngIf="timer$ | async as msLeft; else expiredBadge">
            <span class="form-text timer" [ngClass]="{'expiring': (msLeft <= warningRangeInMs)}">
                {{ msLeft | msToDuration }}
            </span>
        </ng-container>
        <ng-template #expiredBadge>
            <span class="badge badge-danger">
                Expired
            </span>
        </ng-template>
    </div>
</div>