import * as models from '../../../domain/models';

export const response = {
  message: 'OK',
  code: 200,
  data: [
    {
      priceComponentId: 11,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 3,
    },
    {
      priceComponentId: 13,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 4,
    },
    {
      priceComponentId: 16,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 7,
    },
    {
      priceComponentId: 18,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 7,
    },
    {
      priceComponentId: 19,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 8,
    },
    {
      priceComponentId: 20,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 8,
    },
    {
      priceComponentId: 21,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 1,
    },
    {
      priceComponentId: 22,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 2,
    },
    {
      priceComponentId: 23,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-18T20:08:55.858038+00:00',
      productId: 3,
    },
    {
      priceComponentId: 25,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:22:29.139392+00:00',
      productId: 30,
    },
    {
      priceComponentId: 26,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:22:43.342338+00:00',
      productId: 31,
    },
    {
      priceComponentId: 27,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:22:49.420531+00:00',
      productId: 32,
    },
    {
      priceComponentId: 28,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:22:53.843930+00:00',
      productId: 33,
    },
    {
      priceComponentId: 30,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:02.089548+00:00',
      productId: 35,
    },
    {
      priceComponentId: 31,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:11.367472+00:00',
      productId: 36,
    },
    {
      priceComponentId: 32,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:18.670223+00:00',
      productId: 37,
    },
    {
      priceComponentId: 33,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:21.831577+00:00',
      productId: 38,
    },
    {
      priceComponentId: 34,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:26.328906+00:00',
      productId: 39,
    },
    {
      priceComponentId: 35,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:29.612628+00:00',
      productId: 40,
    },
    {
      priceComponentId: 36,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:32.942405+00:00',
      productId: 41,
    },
    {
      priceComponentId: 37,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:37.108401+00:00',
      productId: 42,
    },
    {
      priceComponentId: 38,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T15:23:40.462122+00:00',
      productId: 43,
    },
    {
      priceComponentId: 40,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:26.541150+00:00',
      productId: 3,
    },
    {
      priceComponentId: 41,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:34.383033+00:00',
      productId: 30,
    },
    {
      priceComponentId: 42,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:37.943515+00:00',
      productId: 31,
    },
    {
      priceComponentId: 43,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:41.927107+00:00',
      productId: 32,
    },
    {
      priceComponentId: 44,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:53.369191+00:00',
      productId: 33,
    },
    {
      priceComponentId: 46,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:51:59.505898+00:00',
      productId: 35,
    },
    {
      priceComponentId: 47,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:02.218264+00:00',
      productId: 36,
    },
    {
      priceComponentId: 48,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:13.185863+00:00',
      productId: 37,
    },
    {
      priceComponentId: 49,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:18.210872+00:00',
      productId: 38,
    },
    {
      priceComponentId: 50,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:25.132721+00:00',
      productId: 39,
    },
    {
      priceComponentId: 51,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:28.765322+00:00',
      productId: 40,
    },
    {
      priceComponentId: 52,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:32.014348+00:00',
      productId: 41,
    },
    {
      priceComponentId: 53,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:34.973632+00:00',
      productId: 42,
    },
    {
      priceComponentId: 54,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-19T20:52:39.182141+00:00',
      productId: 43,
    },
    {
      priceComponentId: 55,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-20T04:35:30.812618+00:00',
      productId: 1,
    },
    {
      priceComponentId: 56,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-20T04:35:34.155792+00:00',
      productId: 2,
    },
    {
      priceComponentId: 58,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-26T18:41:42.149827+00:00',
      productId: 44,
    },
    {
      priceComponentId: 59,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-26T18:41:51.603584+00:00',
      productId: 44,
    },
    {
      priceComponentId: 60,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-26T19:13:54.168351+00:00',
      productId: 45,
    },
    {
      priceComponentId: 61,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2017-10-26T19:14:08.638998+00:00',
      productId: 45,
    },
    {
      priceComponentId: 63,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-02-16T19:37:29.956288+00:00',
      productId: 3,
    },
    {
      priceComponentId: 64,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-02-16T20:02:58.734521+00:00',
      productId: 2,
    },
    {
      priceComponentId: 68,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-03-14T17:43:54.061662+00:00',
      productId: 53,
    },
    {
      priceComponentId: 68,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-03-14T17:44:04.506660+00:00',
      productId: 53,
    },
    {
      priceComponentId: 69,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-03-14T21:18:31.387356+00:00',
      productId: 58,
    },
    {
      priceComponentId: 70,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-03-14T21:18:35.763869+00:00',
      productId: 58,
    },
    {
      priceComponentId: 84,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-02T04:55:08.593649+00:00',
      productId: 62,
    },
    {
      priceComponentId: 84,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-05T15:35:10.432252+00:00',
      productId: 62,
    },
    {
      priceComponentId: 84,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-04T19:08:07.653162+00:00',
      productId: 62,
    },
    {
      priceComponentId: 86,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-04T19:05:03.858162+00:00',
      productId: 59,
    },
    {
      priceComponentId: 86,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-04T19:08:18.445405+00:00',
      productId: 59,
    },
    {
      priceComponentId: 104,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T16:44:42.550325+00:00',
      productId: 71,
    },
    {
      priceComponentId: 104,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T17:09:54.039888+00:00',
      productId: 71,
    },
    {
      priceComponentId: 104,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T16:45:03.426383+00:00',
      productId: 71,
    },
    {
      priceComponentId: 105,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T16:44:48.859479+00:00',
      productId: 72,
    },
    {
      priceComponentId: 105,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T17:09:59.927782+00:00',
      productId: 72,
    },
    {
      priceComponentId: 105,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-25T16:45:16.360755+00:00',
      productId: 72,
    },
    {
      priceComponentId: 106,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-04-26T20:28:13.396254+00:00',
      productId: 73,
    },
    {
      priceComponentId: 111,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-03T17:11:07.551274+00:00',
      productId: 55,
    },
    {
      priceComponentId: 114,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-08T17:18:54.052767+00:00',
      productId: 3,
    },
    {
      priceComponentId: 115,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-10T16:06:07.031334+00:00',
      productId: 2,
    },
    {
      priceComponentId: 116,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-10T16:18:10.488884+00:00',
      productId: 13,
    },
    {
      priceComponentId: 117,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-10T16:20:11.605743+00:00',
      productId: 77,
    },
    {
      priceComponentId: 118,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-10T20:54:14.864062+00:00',
      productId: 3,
    },
    {
      priceComponentId: 119,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-11T14:35:03.818981+00:00',
      productId: 74,
    },
    {
      priceComponentId: 120,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-11T14:35:14.085419+00:00',
      productId: 75,
    },
    {
      priceComponentId: 124,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-05-17T19:53:15.144340+00:00',
      productId: 79,
    },
    {
      priceComponentId: 135,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-06-26T14:18:31.442751+00:00',
      productId: 87,
    },
    {
      priceComponentId: 143,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-07-18T21:04:37.191022+00:00',
      productId: 95,
    },
    {
      priceComponentId: 143,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-07-18T21:04:42.935493+00:00',
      productId: 95,
    },
    {
      priceComponentId: 169,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-08-17T20:47:49.938514+00:00',
      productId: 102,
    },
    {
      priceComponentId: 175,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2019-04-08T22:36:22.587493+00:00',
      productId: 64,
    },
    {
      priceComponentId: 175,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2019-04-08T22:36:02.690006+00:00',
      productId: 64,
    },
    {
      priceComponentId: 175,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-08-27T16:10:39.667760+00:00',
      productId: 64,
    },
    {
      priceComponentId: 175,
      salesActionId: 4,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2019-04-08T22:36:22.619821+00:00',
      productId: 64,
    },
    {
      priceComponentId: 180,
      salesActionId: 2,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-08-29T15:45:51.673595+00:00',
      productId: 105,
    },
    {
      priceComponentId: 180,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-08-29T16:17:10.641507+00:00',
      productId: 105,
    },
    {
      priceComponentId: 186,
      salesActionId: 3,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2018-09-04T20:35:48.196262+00:00',
      productId: 98,
    },
    {
      priceComponentId: 229,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2019-07-24T16:32:41.452301+00:00',
      productId: 153,
    },
    {
      priceComponentId: 230,
      salesActionId: 1,
      securityAccess: 1,
      productTypeId: null,
      partyId: null,
      active: true,
      productFeatureId: null,
      createTime: '2019-07-24T16:32:41.503186+00:00',
      productId: 153,
    },
  ],
};
