export class ArgusRating {
  argusRatingId: string;
  name: string;
  expirationDate: Date;

  getArgusRatingId(): string {
    return this.argusRatingId;
  }

  setArgusRatingId(argusRatingId: string): ArgusRating {
    this.argusRatingId = argusRatingId;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): ArgusRating {
    this.name = name;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(value: Date): ArgusRating {
    this.expirationDate = value;
    return this;
  }

  equals(o1: ArgusRating): boolean {
    if (o1 === null || this.argusRatingId === null || this.argusRatingId === undefined) {
      return false;
    }

    return this.argusRatingId === o1.argusRatingId;
  }
}

export class WyvernRating {
  wyvernRatingId: string;
  name: string;
  expirationDate: Date;

  getWyvernRatingId(): string {
    return this.wyvernRatingId;
  }

  setWyvernRatingId(wyvernRatingId: string): WyvernRating {
    this.wyvernRatingId = wyvernRatingId;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): WyvernRating {
    this.name = name;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(value: Date): WyvernRating {
    this.expirationDate = value;
    return this;
  }

  equals(o1: WyvernRating): boolean {
    if (o1 === null || this.wyvernRatingId === null || this.wyvernRatingId === undefined) {
      return false;
    }

    return this.wyvernRatingId === o1.wyvernRatingId;
  }
}

export class IsBaoRating {
  isBaoRatingId: string;
  name: string;
  expirationDate: Date;

  getIsBaoRatingId(): string {
    return this.isBaoRatingId;
  }

  setIsBaoRatingId(isBaoRatingId: string): IsBaoRating {
    this.isBaoRatingId = isBaoRatingId;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): IsBaoRating {
    this.name = name;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(value: Date): IsBaoRating {
    this.expirationDate = value;
    return this;
  }

  equals(o1: IsBaoRating): boolean {
    if (o1 === null || this.isBaoRatingId === null || this.isBaoRatingId === undefined) {
      return false;
    }

    return this.isBaoRatingId === o1.isBaoRatingId;
  }
}

export class LevelFlightRating {
  levelFlightRatingId: string;
  name: string;
  expirationDate: Date;

  getLevelFlightRatingId(): string {
    return this.levelFlightRatingId;
  }

  setLevelFlightRatingId(levelFlightRatingId: string): LevelFlightRating {
    this.levelFlightRatingId = levelFlightRatingId;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): LevelFlightRating {
    this.name = name;
    return this;
  }

  getExpirationDate(): Date {
    return this.expirationDate;
  }

  setExpirationDate(value: Date): LevelFlightRating {
    this.expirationDate = value;
    return this;
  }
  
  equals(o1: LevelFlightRating): boolean {
    if (o1 === null || this.levelFlightRatingId === null || this.levelFlightRatingId === undefined) {
      return false;
    }

    return this.levelFlightRatingId === o1.levelFlightRatingId;
  }
}

export class Rating {
  argusRatings: ArgusRating[];
  wyvernRatings: WyvernRating[];
  isBaoRatings: IsBaoRating[];
  levelFlightRatings: LevelFlightRating[];

  getArgusRatings(): ArgusRating[] {
    return this.argusRatings;
  }

  setArgusRatings(argusRatings: ArgusRating[]): Rating {
    this.argusRatings = argusRatings;
    return this;
  }

  getWyvernRatings(): WyvernRating[] {
    return this.wyvernRatings;
  }

  setWyvernRatings(wyvernRatings: WyvernRating[]): Rating {
    this.wyvernRatings = wyvernRatings;
    return this;
  }

  getIsBaoRatings(): IsBaoRating[] {
    return this.isBaoRatings;
  }

  setIsBaoRatings(isBaoRatings: IsBaoRating[]): Rating {
    this.isBaoRatings = isBaoRatings;
    return this;
  }

  getLevelFlightRatings(): LevelFlightRating[] {
    return this.levelFlightRatings;
  }

  setLevelFlightRatings(levelFlightRatings: LevelFlightRating[]): Rating {
    this.levelFlightRatings = levelFlightRatings;
    return this;
  }
}
