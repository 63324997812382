<pg-header>
  <a
    href="javascript:void(0)"
    class="btn-link toggle-sidebar d-lg-none pg pg-menu"
    (click)="toggleMobileSidebar.emit()"
  ></a>

  <div class="row">
    <img
      src="assets/img/VStudio_Red.svg"
      alt="logo"
      data-src="assets/img/VStudio_Red.svg"
      ui-jq="unveil"
      data-src-retina="assets/img/VStudio_Red.svg"
      width="50"
      height="50"
      (click)="testRequest()"
    />

    <h4 class="font-weight-200 d-lg-block d-none p-l-10" [innerHTML]="getApplicationHeader()"></h4>
  </div>

  <div class="d-flex align-items-center">
    <div
      [hidden]="!appSwitcherIsVisible"
      class="pull-left m-t-5 p-r-10 fs-14 font-heading d-lg-block d-none"
    >
      <div class="dropdown pull-right" dropdown>
        <button
          class="profile-dropdown-toggle m-r-10"
          type="button"
          dropdownToggle
          id="profileDropdown"
        >
          <i class="far fa-lg fa-ellipsis-v"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right profile-dropdown"
          *dropdownMenu
          style="width: 280px; height: 280px; right: -12px"
          role="menu"
        >
          <div class="container m-t-15">
            <div class="row">
              <div class="col col-xs-4">
                <button
                  class="btn btn-default no-padding"
                  (click)="appSwitcher.emit(0)"
                  style="width: 70px; height: 70px"
                >
                  <div><i class="fal fa-plane" style="font-size: 32px"></i></div>
                  <div><span class="fs-12">JetStudio</span></div>
                </button>
              </div>
              <div class="col col-xs-4">
                <button
                  class="btn btn-default no-padding"
                  (click)="appSwitcher.emit(1)"
                  style="width: 70px; height: 70px"
                >
                  <div><i class="fal fa-exclamation-triangle" style="font-size: 32px"></i></div>
                  <div><span class="fs-12">Legacy</span></div>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col col-xs-4"></div>
              <div class="col col-xs-4"></div>
              <div class="col col-xs-4"></div>
            </div>
            <div class="row">
              <div class="col col-xs-4"></div>
              <div class="col col-xs-4"></div>
              <div class="col col-xs-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pull-right d-lg-block d-none">
      <div class="collapse navbar-collapse">
        <ul *ngIf="auth.user$ | async as user" class="navbar-nav d-md-block">
          <li>
            <a
              #anchor
              (click)="onToggle()"
              class="nav-link dropdown-toggle"
              id="profile-dropdown"
              data-toggle="dropdown"
            >
              <!-- Profile image should be set to the profile picture from the id token -->
              <img
                [src]="user.picture"
                alt="Profile picture"
                class="nav-user-profile rounded-circle"
                style="width: 25px"
              />
            </a>
            <kendo-popup
              [anchor]="authAnchor"
              class="login-popup"
              [popupClass]="'login-dropdown-wrapper'"
              *ngIf="showPopup"
            >
              <div class="content px-2">
                <div class="dropdown-header pt-1 p-b-10 p-t-10 p-b-10 fs-6 text-center">
                  <span>{{ user.name }}</span>
                  <br/>
                  <span *ngIf="user.email">({{ user.email }})</span>
                </div>
                <button
                  (click)="logout()"
                  class="btn btn-link dropdown-item p-b-10 fs-6"
                  id="logoutBtn"
                >
                  <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
                </button>
              </div>
            </kendo-popup>
          </li>
        </ul>
      </div>
    </div>
  </div>
</pg-header>
