export const response = {
  client: {
    message: 'OK',
    code: 200,
    data: {
      phoneCallConsent: null,
      balance: '0',
      regSource: 'JS',
      name: 'XO Test',
      dba: 'XO',
      referredByClient: null,
      isSelectableAsBeneficiary: false,
      isSelectedAsBeneficiary: false,
      accountRoleId: 1,
      accountClients: [
        {
          id: 996,
          name: 'Account Client 1',
          accountRoleId: 0,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 997,
          name: 'Account Client 2',
          accountRoleId: 1,
          isSelectableAsBeneficiary: true,
          isSelectedAsBeneficiary: false,
        },
        {
          id: 998,
          name: 'Account Client 3',
          accountRoleId: 2,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: true,
        },
        {
          id: 999,
          name: 'Account Client 4',
          accountRoleId: 3,
          isSelectableAsBeneficiary: false,
          isSelectedAsBeneficiary: false,
        },
      ],
      verificationStatus: 'Not submitted',
      account: null,
      clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
      email: 'XO Test@e.com',
      phone: '049',
      clientServices: [],
      brokerid: 0,
      dob: '1991-01-01',
      legalName: 'XO Test',
      membershipRequests: [
        {
          orderId: 7890,
          orderItemId: 13069,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 147,
          managedClientServiceId: null,
          clientId: 1,
          productName: 'XO Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-08-08T17:01:30.173317+00:00',
          orderItemTotalPrice: '15488.4',
          requestedProductId: null,
          createTime: '2019-07-24T17:01:31.509074+00:00',
          productId: 147,
        },
        {
          orderId: 7893,
          orderItemId: 13072,
          currentBasePrice: '0',
          salesActionId: 1,
          membershipSalesActionId: 1,
          currentProductId: 114,
          managedClientServiceId: null,
          clientId: 1,
          productName: 'Charter Flight',
          membershipSalesActionName: 'New',
          expirationTime: '2019-08-09T15:54:39.034546+00:00',
          orderItemTotalPrice: '15488.4',
          requestedProductId: null,
          createTime: '2019-07-25T15:54:40.017739+00:00',
          productId: 114,
        },
      ],
      isFree: true,
      gdpr: null,
      id: 1,
      emailSupportConsent: null,
      flightCreditsAmount: '0',
    },
  },
  orders: {
    message: 'OK',
    code: 200,
    data: {
      totalItems: 9,
      items: [
        {
          canChangePaymentType: true,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 362,
              invoiceTypeName: 'Main Invoice',
              uuid: '7468e172-b1ee-4562-9c16-5d9415681503',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '759a70b2-bf59-4fc7-89ba-11a23676c622',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 2,
              orderId: 7893,
              invoiceId: 10711,
              createTime: '2019-07-25T15:54:45.973592+00:00',
              parentInvoiceId: 10708,
              orderItemId: 13072,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs_add_terms/?759a70b2-bf59-4fc7-89ba-11a23676c622',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7893,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13072,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-08-09T15:54:39.034546+00:00',
                expired: false,
                createTime: '2019-07-25T15:54:40.017739+00:00',
              },
              id: 13072,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7893,
          orderStatusId: 6,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-09T15:54:39.034546+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-25T15:54:39.555470+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15988.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 360,
              invoiceTypeName: 'Main Invoice',
              uuid: '28501508-61bd-4bb4-a351-ff7c7271ff48',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '039e64ec-bc4d-4957-9646-dad3f0b86d8f',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7892,
              invoiceId: 10710,
              createTime: '2019-07-24T20:15:13.143110+00:00',
              parentInvoiceId: 10708,
              orderItemId: 13071,
              legacyFormUrl:
                'http://console.vista.studio/forms/xcharterjs_add/?039e64ec-bc4d-4957-9646-dad3f0b86d8f',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15988.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7892,
              parentOrderItem: null,
              totalPrice: '15988.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13071,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'XO Charter Flight',
                expirationTime: '2019-08-08T20:15:04.380825+00:00',
                expired: false,
                createTime: '2019-07-24T20:15:07.197903+00:00',
              },
              id: 13071,
              product: {
                productTypeId: 61,
                productId: 147,
                productName: 'XO Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15988.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7892,
          orderStatusId: 3,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-08T20:15:04.380825+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-24T20:15:06.754347+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 362,
              invoiceTypeName: 'Main Invoice',
              uuid: '7ee3887e-8a2a-4c08-83dc-ca305b9dcff6',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '473fc6c2-2b8a-4d93-bd3e-7f567955d100',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7891,
              invoiceId: 10709,
              createTime: '2019-07-24T20:07:57.961263+00:00',
              parentInvoiceId: 10708,
              orderItemId: 13070,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs_add_terms/?473fc6c2-2b8a-4d93-bd3e-7f567955d100',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7891,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13070,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-08-08T20:07:50.388584+00:00',
                expired: false,
                createTime: '2019-07-24T20:07:51.995546+00:00',
              },
              id: 13070,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7891,
          orderStatusId: 3,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-08T20:07:50.388584+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-24T20:07:51.544008+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: 'ff9ab121-f387-430d-a76c-2a0984a9308a',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'd9a5ad42-a1e0-464d-999e-bda50ebcc0fa',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7890,
              invoiceId: 10708,
              createTime: '2019-07-24T17:01:38.222031+00:00',
              parentInvoiceId: null,
              orderItemId: 13069,
              legacyFormUrl:
                'http://console.vista.studio/forms/xcharterjs/?d9a5ad42-a1e0-464d-999e-bda50ebcc0fa',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7890,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13069,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'XO Charter Flight',
                expirationTime: '2019-08-08T17:01:30.173317+00:00',
                expired: false,
                createTime: '2019-07-24T17:01:31.509074+00:00',
              },
              id: 13069,
              product: {
                productTypeId: 61,
                productId: 147,
                productName: 'XO Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7890,
          orderStatusId: 5,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-08T17:01:30.173317+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-24T17:01:31.083358+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: '6552eddf-3f3e-469a-9fac-3a5f279ad5ec',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '36597af8-e44b-41fa-8552-b15dac58252f',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7889,
              invoiceId: 10707,
              createTime: '2019-07-24T16:59:17.589434+00:00',
              parentInvoiceId: null,
              orderItemId: 13068,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs/?36597af8-e44b-41fa-8552-b15dac58252f',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7889,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13068,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-08-08T16:59:09.623099+00:00',
                expired: false,
                createTime: '2019-07-24T16:59:10.985270+00:00',
              },
              id: 13068,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7889,
          orderStatusId: 3,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-08T16:59:09.623099+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-24T16:59:10.552779+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '115.5',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 353,
              invoiceTypeName: 'Main Invoice',
              uuid: 'a14d92da-412f-4cc1-bbfb-590c4b490b30',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'e5a59524-0e8b-4219-91c4-e93dea7181f0',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7884,
              invoiceId: 10702,
              createTime: '2019-07-23T20:13:23.428151+00:00',
              parentInvoiceId: null,
              orderItemId: 13062,
              legacyFormUrl:
                'http://console.vista.studio/forms/conciergejs/?e5a59524-0e8b-4219-91c4-e93dea7181f0',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '115.5',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7884,
              parentOrderItem: null,
              totalPrice: '115.5',
              promotionAmount: null,
              orderItemTypeId: 9,
              notes: null,
              membershipRequest: {
                orderItemId: 13062,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Catering',
                expirationTime: '2019-07-26T20:13:17.399731+00:00',
                expired: false,
                createTime: '2019-07-23T20:13:19.559362+00:00',
              },
              id: 13062,
              product: {
                productTypeId: 62,
                productId: 107,
                productName: 'Catering',
              },
              childOrderItems: [],
              charterInfo: null,
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '110.0',
              conciergeInfo: {
                vendorId: 5,
                requestUrl: 'http://console.vista.studio/#l:12713052',
                comments: 'qweqwe',
                vendorName: 'Silver Linings',
                markupPercent: '10',
                quoteAmount: '100',
                departureDate: '2019-08-16T16:00:00+00:00',
                serviceDate: null,
                vendorRefNumber: '123',
                requestId: 12713052,
                clientComments: 'qwe',
              },
              quantity: 1,
              promotionItemId: null,
              surcharge: {
                typeId: 2,
                name: 'Administrative Fee',
                promoCode: null,
                typeName: 'Surcharge',
                amount: null,
                percentage: '5',
                id: 190,
              },
            },
          ],
          notes: null,
          id: 7884,
          orderStatusId: 7,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-07-26T20:13:17.399731+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-23T20:13:19.117495+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '113.85',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 353,
              invoiceTypeName: 'Main Invoice',
              uuid: '33037c48-074b-4ca8-8e12-5ad1d22ed991',
              legacyFormEditUrl: null,
              legacyFormUrlKey: 'c7ed216d-35a0-4640-85c1-586f6e98288f',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 4,
              orderId: 7883,
              invoiceId: 10701,
              createTime: '2019-07-23T20:07:19.510432+00:00',
              parentInvoiceId: null,
              orderItemId: 13061,
              legacyFormUrl:
                'http://console.vista.studio/forms/xconciergejs/?c7ed216d-35a0-4640-85c1-586f6e98288f',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '113.85',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7883,
              parentOrderItem: null,
              totalPrice: '113.85',
              promotionAmount: null,
              orderItemTypeId: 9,
              notes: null,
              membershipRequest: {
                orderItemId: 13061,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'XO Ground Transportation',
                expirationTime: '2019-07-26T20:07:12.456211+00:00',
                expired: false,
                createTime: '2019-07-23T20:07:14.882983+00:00',
              },
              id: 13061,
              product: {
                productTypeId: 63,
                productId: 172,
                productName: 'XO Ground Transportation',
              },
              childOrderItems: [],
              charterInfo: null,
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '110.0',
              conciergeInfo: {
                vendorId: 1,
                requestUrl: 'http://console.vista.studio/#l:12713052',
                comments: 'sdf',
                vendorName: 'Empire CLS',
                markupPercent: '10',
                quoteAmount: '100',
                departureDate: '2019-08-16T16:00:00+00:00',
                serviceDate: null,
                vendorRefNumber: 'qwe',
                requestId: 12713052,
                clientComments: 'asf',
              },
              quantity: 1,
              promotionItemId: null,
              surcharge: {
                typeId: 2,
                name: 'Administrative Fee',
                promoCode: null,
                typeName: 'Surcharge',
                amount: null,
                percentage: '3.5',
                id: 227,
              },
            },
          ],
          notes: null,
          id: 7883,
          orderStatusId: 7,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-07-26T20:07:12.456211+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-23T20:07:14.410855+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: '869d228b-57df-4b7d-8d06-fc959382134f',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '6cff5346-09d3-4de9-afa0-ce802a886d62',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7882,
              invoiceId: 10700,
              createTime: '2019-07-23T19:49:20.870140+00:00',
              parentInvoiceId: null,
              orderItemId: 13060,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs/?6cff5346-09d3-4de9-afa0-ce802a886d62',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7882,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13060,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-08-07T19:49:13.505101+00:00',
                expired: false,
                createTime: '2019-07-23T19:49:14.781175+00:00',
              },
              id: 13060,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7882,
          orderStatusId: 3,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-07T19:49:13.505101+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-23T19:49:14.335488+00:00',
          orderSourceId: 1,
        },
        {
          canChangePaymentType: false,
          totalPrice: '15488.4',
          user: {
            phone: null,
            email: 'osolovey@jetsmarter.com',
            id: 2,
            firstName: 'Oleg',
            lastName: 'Solovey',
          },
          invoices: [
            {
              replacedInvoiceId: null,
              userId: 2,
              legacyFormTplId: 359,
              invoiceTypeName: 'Main Invoice',
              uuid: 'f407ab8d-b1e5-4840-88be-0a40f1b1bf21',
              legacyFormEditUrl: null,
              legacyFormUrlKey: '091a025a-81ea-46fd-ba54-ebffb4496379',
              invoiceExternalId: null,
              invoiceReplacements: [],
              invoiceStatusId: 5,
              orderId: 7881,
              invoiceId: 10699,
              createTime: '2019-07-23T19:11:46.282290+00:00',
              parentInvoiceId: null,
              orderItemId: 13059,
              legacyFormUrl:
                'http://console.vista.studio/forms/charterjs/?091a025a-81ea-46fd-ba54-ebffb4496379',
              clientId: 1,
              invoiceTypeId: 1,
              amount: '15488.4',
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              orderSourceId: 1,
              replacingInvoiceId: null,
            },
          ],
          items: [
            {
              orderId: 7881,
              parentOrderItem: null,
              totalPrice: '15488.4',
              promotionAmount: null,
              orderItemTypeId: 11,
              notes: null,
              membershipRequest: {
                orderItemId: 13059,
                salesAction: {
                  name: 'New',
                  id: 1,
                },
                productName: 'Charter Flight',
                expirationTime: '2019-08-07T19:11:39.164593+00:00',
                expired: false,
                createTime: '2019-07-23T19:11:40.473419+00:00',
              },
              id: 13059,
              product: {
                productTypeId: 60,
                productId: 114,
                productName: 'Charter Flight',
              },
              childOrderItems: [],
              charterInfo: {
                purchaseOrderNumber: null,
                requestUrl: 'http://console.vista.studio/#fl:12713051',
                departureDate: '2019-08-16T12:00:00',
                requestId: 12713051,
              },
              client: {
                futureMembershipTotal: null,
                clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
                membershipTimeStarted: null,
                regSource: 'JS',
                name: 'XO Test',
                futureMembershipTimeStarted: null,
                dob: '1991-01-01',
                membershipTotal: null,
                membershipDisplayName: null,
                membershipTypeId: null,
                futureMembershipTypeId: null,
                phone: '049',
                futureMembershipTimeExpiring: null,
                email: 'XO Test@e.com',
                brokerid: 0,
                membershipTimeExpiring: null,
                dba: 'XO',
                legalName: 'XO Test',
                personalManager: 1000001,
                futureMembershipDisplayName: null,
                id: 1,
              },
              basePrice: '15488.4',
              conciergeInfo: null,
              promotionItemId: null,
              quantity: 1,
            },
          ],
          notes: null,
          id: 7881,
          orderStatusId: 3,
          partyId: 1,
          orderBilling: {
            client: {
              futureMembershipTotal: null,
              clientCreateTime: '2019-06-20T21:18:02.085649+00:00',
              membershipTimeStarted: null,
              regSource: 'JS',
              name: 'XO Test',
              futureMembershipTimeStarted: null,
              dob: '1991-01-01',
              membershipTotal: null,
              membershipDisplayName: null,
              membershipTypeId: null,
              futureMembershipTypeId: null,
              phone: '049',
              futureMembershipTimeExpiring: null,
              email: 'XO Test@e.com',
              brokerid: 0,
              membershipTimeExpiring: null,
              dba: 'XO',
              legalName: 'XO Test',
              personalManager: 1000001,
              futureMembershipDisplayName: null,
              id: 1,
            },
          },
          expirationTime: '2019-08-07T19:11:39.164593+00:00',
          promotionId: null,
          expired: false,
          createTime: '2019-07-23T19:11:39.914504+00:00',
          orderSourceId: 1,
        },
      ],
    },
  },
};
