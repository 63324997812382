export class FlightRequest {
  private _id: number
  private _flightRequestId: number;
  private _legId: string;
  private _costTypeId: number;
  private _amount: number;

  constructor() {
  }


  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get flightRequestId(): number {
    return this._flightRequestId;
  }

  set flightRequestId(value: number) {
    this._flightRequestId = value;
  }

  get legId(): string {
    return this._legId;
  }

  set legId(value: string) {
    this._legId = value;
  }

  get costTypeId(): number {
    return this._costTypeId;
  }

  set costTypeId(value: number) {
    this._costTypeId = value;
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
  }
}
