import { Task } from './task';

export class TasksLeg {
  accountName: string;
  arrivalAirportCode: string;
  arrivalDateTime: Date;
  broken: boolean;
  canceled: boolean;
  departureAirportCode: string;
  departureDateTime: Date;
  fosTripId: number;
  invoiceLink: string;
  legId: number;
  loading: boolean;
  localArrivalDateTime: Date;
  localDepartureDateTime: Date;
  nextTaskDueDate: Array<NextTaskDueItem>;
  pod: string;
  sfdcAccountId: string;
  source: string;
  statusDisplayName: string;
  tailNumber: string;
  tasks: Array<Task>;
  uuid: string;
}

export class NextTaskDueItem {
  dueDate:  string;
  taskId: number;
  title:  string;
  isDuplicate?: boolean;
}

export class LegState {
  legStatusId: number;
  displayName: string;
}

export class LegsLookupCriteria {
  search = '';
  legStatusId = '';
  pod = '';
  source = '';
  taskCategoryId = '';
  taskStateId = '1,2,3,6';
  departureDateFrom = null;
  departureDateTo = null;
  dueDateFrom = null;
  dueDateTo = null;

  clear? = (): LegsLookupCriteria => {
    this.search = '';
    this.legStatusId = '';
    this.pod = '';
    this.source = '';
    this.taskCategoryId = '';
    this.taskStateId = '';
    this.departureDateFrom = null;
    this.departureDateTo = null;
    this.dueDateFrom = null;
    this.dueDateTo = null;
    return this;
  };
}

export class LegsTaskLookupCriteria {
  taskCategoryId = '';
  departureDateFrom = null;
  departureDateTo = null;

  clear? = (): LegsTaskLookupCriteria => {
    this.taskCategoryId = '';
    this.departureDateFrom = null;
    this.departureDateTo = null;
    return this;
  };
}
