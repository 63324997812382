import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class SfdcAnnualMembershipFeeOrderItem extends OrderItem {
  sfdcAnnualMembershipFee: number = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.AnnualMembershipFee;
  }
}
