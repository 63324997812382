import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const save = createAction(
  '[Sales.PricingComparisonPrice] Save',
  props<{ payload: SavePayload }>()
);

export class SavePayload {
  offer: models.MembershipPricingComparisonOffer;
}

export const saveSuccess = createAction(
  '[Sales.PricingComparisonPrice] Save Success',
  props<{ payload: SaveSuccessPayload }>()
);

export class SaveSuccessPayload {
  items: Array<models.MembershipPricingComparisonPrice>;
}

export const saveFail = createAction('[Sales.PricingComparisonPrice] Save Fail');
export const setInitial = createAction('[Sales.PricingComparisonPrice] Set Initial');
