import { createAction, props } from '@ngrx/store';

import { LegCost } from '../../domain/models/leg-cost';
import { CostType } from '../../domain/models/cost-type';

export const loadLegCostsTable = createAction(
  '[Sales.AmendCharges] Load Leg Costs table',
  props<{ requestId: number }>()
);

export const setLegCostsTableData = createAction(
  '[Sales.AmendCharges] Set Leg Costs table data',
  props<{ legCosts: LegCost[] }>()
);

export const displayErrorMessage = createAction(
  '[Sales.AmendCharges] Display error message',
  props<{ message: string }>()
);

export const displaySuccessMessage = createAction(
  '[Sales.AmendCharges] Display success message',
  props<{ message: string }>()
);

export const setCostTypesList = createAction(
  '[Sales.AmendCharges] Set Cost Types list',
  props<{ costTypes: CostType[] }>()
);

export const insertLegCost = createAction(
  '[Sales.AmendCharges] Insert Leg Cost',
  props<{ legCost: LegCost }>()
);

export const updateLegCost = createAction(
  '[Sales.AmendCharges] Update Leg Cost',
  props<{ legCost: LegCost }>()
);

export const setHasFinalizedLegs = createAction(
  '[Sales.AmendCharges] Set hasFinalizedLegs',
  props<{ hasFinalizedLegs: boolean }>()
);

export const setIsFinalizedLegsCheckRunning = createAction(
  '[Sales.AmendCharges] Set isFinalizedLegsCheckRunning',
  props<{ isFinalizedLegsCheckRunning: boolean }>()
);

export const setLegCostIdToBeRemoved = createAction(
  '[Sales.AmendCharges] Set Leg Cost id to be removed',
  props<{ legCostId: number }>()
);

export const enableSaveButton = createAction(
  '[Sales.AmendCharges] Toggle empty fields value on dialog',
  props<{ emptyFields: boolean }>()
);

export const setRequestId = createAction(
  '[Sales.AmendCharges] Set request id',
  props<{ requestId: number }>()
);

export const loadFinalizedLegIds = createAction(
  '[Sales.AmendCharges] Load Leg ID List',
  props<{ requestId: number }>()
);

export const setFinalizedLegIds = createAction(
  '[Sales.AmendCharges] Set Finalized Leg ID List',
  props<{ finalizedLegIds: String[] }>()
);

export const toggleLoadingFinalizedLegIds = createAction(
  '[Sales.AmendCharges] Toggle Finalized Leg ID List loading'
);

export const toggleLoadingLegCostsTable = createAction(
  '[Sales.AmendCharges] Toggle loading Leg Costs table'
);

export const toggleDialog = createAction('[Sales.AmendCharges] Toggle dialog');

export const toggleConfirmRemoveDialog = createAction(
  '[Sales.AmendCharges] Toggle confirm remove dialog'
);

export const toggleCostTypesListLoading = createAction(
  '[Sales.AmendCharges] Toggle Cost Types list loading'
);

export const loadCostTypesList = createAction('[Sales.AmendCharges] Load Cost Types list');

export const updateTotalLegCostsAmount = createAction(
  '[Sales.AmendCharges] Update total Leg Costs amount'
);

export const resetModifiedLegCosts = createAction('[Sales.AmendCharges] Reset modified Leg Costs');

export const removeLegCost = createAction('[Sales.AmendCharges] Remove Leg Cost');

export const saveModifiedLegCosts = createAction('[Sales.AmendCharges] Save modified Leg Costs');

export const toggleSaveButtonLoading = createAction(
  '[Sales.AmendCharges] Toggle save button loading'
);

export const clearMessages = createAction('[Sales.AmendCharges] Clear messages');

export const checkIfRequestIdHasFinalizedLegs = createAction(
  '[Sales.AmendCharges] Check if flightRequestId has finalized legs'
);

export const loadAmendCharges = createAction('[Sales.AmendCharges] Load Amend Charges');
