import { Operator } from "./operator";
import { AircraftCategory } from "./aircraft-category";

export class MarketplaceOption {
    operator: Operator;
    category: AircraftCategory;
    creationType: string;

    getCategory(): AircraftCategory {
        return this.category;
    }

    setCategory(category: AircraftCategory): MarketplaceOption {
        this.category = category;

        return this;
    }

    getOperator(): Operator {
        return this.operator;
    }

    setOperator(operator: Operator): MarketplaceOption {
        this.operator = operator;

        return this;
    }

    getCreationType(): string {
        return this.creationType;
    }

    setCreationType(creationType: string): MarketplaceOption {
        this.creationType = creationType;

        return this;
    }
}