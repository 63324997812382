export enum SortOrderEnum {
  asc = 'ASC',
  desc = 'DESC',
}

export enum SortByEnum {
  legId = 'LEG_ID',
  assignedUser = 'ASSIGNED_USER',
  category = 'CATEGORY',
  fosTripId = 'FOS_TRIP_ID',
  tailNumber = 'TAIL_NUMBER',
  pod = 'POD',
  source = 'SOURCE',
  airportCode = 'AIRPORT_CODE',
  departureDateTime = 'DEPARTURE_DATE_TIME',
  title = 'TITLE',
  dueDate = 'DUE_DATE',
  state = 'STATE',
}

export class Sort {
  sortOrder: SortOrderEnum;
  sortBy: SortByEnum;
}
