import { Action } from '@ngrx/store';

import * as models from '../../domain/models/configurations';

export const LOAD = '[YM DepartureTimeFeeConfig] Load';
export const LOAD_SUCCESS = '[YM DepartureTimeFeeConfig] Load Success';
export const LOAD_FAIL = '[YM DepartureTimeFeeConfig] Load Fail';

export const ADD = '[YM DepartureTimeFeeConfig] Add Record';
export const ADD_SUCCESS = '[YM DepartureTimeFeeConfig] Add Record Success';
export const ADD_FAIL = '[YM DepartureTimeFeeConfig] Add Record Fail';

export const UPDATE = '[YM DepartureTimeFeeConfig] Update Record';
export const UPDATE_SUCCESS = '[YM DepartureTimeFeeConfig] Update Record Success';
export const UPDATE_FAIL = '[YM DepartureTimeFeeConfig] Update Record Fail';

export const REMOVE = '[YM DepartureTimeFeeConfig] Remove Record';
export const REMOVE_SUCCESS = '[YM DepartureTimeFeeConfig] Remove Record Success';
export const REMOVE_FAIL = '[YM DepartureTimeFeeConfig] Remove Record Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  fees: Array<models.DepartureTimeFee>;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddFeeAction implements Action {
  readonly type = ADD;

  constructor(public fee: models.DepartureTimeFee) {
  }
}

export class AddFeeSuccessAction implements Action {
  readonly type = ADD_SUCCESS;

  constructor(fee: models.DepartureTimeFee) {
  }
}

export class AddFeeFailAction implements Action {
  readonly type = ADD_FAIL;
}

export class UpdateFeeAction implements Action {
  readonly type = UPDATE;

  constructor(public fee: models.DepartureTimeFee) {
  }
}

export class UpdateFeeSuccessAction implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(fee: models.DepartureTimeFee) {
  }
}

export class UpdateFeeFailAction implements Action {
  readonly type = UPDATE_FAIL;
}

export class RemoveFeeAction implements Action {
  readonly type = REMOVE;

  constructor(public fee: models.DepartureTimeFee) {
  }
}

export class RemoveFeeSuccessAction implements Action {
  readonly type = REMOVE_SUCCESS;
}

export class RemoveFeeFailAction implements Action {
  readonly type = REMOVE_FAIL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddFeeAction
  | AddFeeSuccessAction
  | AddFeeFailAction
  | UpdateFeeAction
  | UpdateFeeSuccessAction
  | UpdateFeeFailAction
  | RemoveFeeAction
  | RemoveFeeSuccessAction
  | RemoveFeeFailAction;
