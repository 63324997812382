import * as _ from 'lodash';
import * as moment from 'moment';
import * as models from '../../../../domain/models';

export class GeneratedDocumentMapper {
  static parseGeneratedDocument(item: any): models.SalesGeneratedDocument {
    const d = new models.SalesGeneratedDocument();
    d.active = _.get(item, 'active', null);
    d.createTime = _.get(item, 'createTime', null)
      ? moment(_.get(item, 'createTime', null)).toDate()
      : null;
    d.documentId = _.get(item, 'documentId', null);
    d.documentTypeId = _.get(item, 'documentTypeId', null);
    d.generateTime = _.get(item, 'generateTime', null)
      ? moment(_.get(item, 'generateTime', null)).toDate()
      : null;
    d.grayedOut = _.get(item, 'grayedOut', null);
    d.name = _.get(item, 'name', null);
    d.regeneratable = _.get(item, 'regeneratable', null);
    d.updateTime = _.get(item, 'updateTime', null)
      ? moment(_.get(item, 'updateTime', null)).toDate()
      : null;
    d.uploadTime = _.get(item, 'uploadTime', null)
      ? moment(_.get(item, 'uploadTime', null)).toDate()
      : null;
    d.url = _.get(item, 'url', null);
    d.userId = _.get(item, 'userId', null);
    return d;
  }
}
