import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Marketplace.MembershipStatus] Load';
export const LOAD_SUCCESS = '[Marketplace.MembershipStatus] Load Success';
export const LOAD_FAIL = '[Marketplace.MembershipStatus] Load Fail';
export const BACKUP = '[Marketplace.MembershipStatus] Backup Data';
export const RESTORE = '[Marketplace.MembershipStatus] Restore';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: number) {
  }
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: models.MembershipStatus) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class BackupAction implements Action {
  readonly type = BACKUP;
}

export class RestoreAction implements Action {
  readonly type = RESTORE;
}

export type Actions = LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | BackupAction
  | RestoreAction;
