import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';
import { Product } from './product';
import { PromotionItem } from './promotion-item';
import { OrderItemProrateTypeEnum } from './order-item-prorate-type-enum';
import { SalesActionEnum } from './sales-action-enum';

export class ProductOrderItem extends OrderItem {
  requestId: number = null;
  departureDate: Date = null;

  salesAction: SalesActionEnum = null;
  product: Product = null;
  promotionAmount: number = null;
  promotionItem: PromotionItem = null;
  alignWithMembership = false;
  quantity = 1;
  prorateType: OrderItemProrateTypeEnum = null;
  loyaltyType?: LoyaltyType;

  get isPromotional(): boolean {
    return this.promotionItem !== null;
  }

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.Product;
  }
}

export type LoyaltyType = 'flights' | 'membership';
