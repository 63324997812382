import { EmailBouncedRecipient } from "./email-bounced-recipient";
import { EmailEventTypeEnum } from "./email-event-type-enum";

export class EmailNotificationHistory {
  comment: string;
  eventType: EmailEventTypeEnum;
  timestamp: Date;
  recipients: Array<string>;
  userAgent: string;
  ipAddress: string;
  bouncedRecipients: Array<EmailBouncedRecipient> = [];

  getComment(): string {
    return this.comment;
  }

  setComment(comment: string): EmailNotificationHistory {
    this.comment = comment;
    return this;
  }

  getEventType(): EmailEventTypeEnum {
    return this.eventType;
  }

  setEventType(eventType: EmailEventTypeEnum): EmailNotificationHistory {
    this.eventType = eventType;
    return this;
  }
 
  getTimestamp(): Date {
    return this.timestamp;
  }

  setTimestamp(timestamp: Date): EmailNotificationHistory {
    this.timestamp = timestamp;
    return this;
  }

  getRecipients(): Array<string> {
    return this.recipients;
  }

  setRecipients(recipients: Array<string>): EmailNotificationHistory {
    this.recipients = recipients;
    return this;
  }

  getUserAgent(): string {
    return this.userAgent;
  }

  setUserAgent(userAgent: string): EmailNotificationHistory {
    this.userAgent = userAgent;
    return this;
  }

  getIpAddress(): string {
    return this.ipAddress;
  }
  
  setIpAddress(ipAddress: string): EmailNotificationHistory {
    this.ipAddress = ipAddress;
    return this;
  }

  getBouncedRecipients(): Array<EmailBouncedRecipient> {
    return this.bouncedRecipients;
  }

  setBouncedRecipients(bouncedRecipients: Array<EmailBouncedRecipient>): EmailNotificationHistory {
    this.bouncedRecipients = bouncedRecipients;
    return this;
  }
}
