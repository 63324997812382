<ng-container [ngSwitch]="layoutType$ | async">
  <div *ngSwitchCase="LayoutType.Default" [ngTemplateOutlet]="default"></div>
  <div *ngSwitchCase="LayoutType.Simple" [ngTemplateOutlet]="simple"></div>
</ng-container>

<ng-template #default>
  <default-layout></default-layout>
</ng-template>

<ng-template #simple>
  <app-simple-layout></app-simple-layout>
</ng-template>

<ng-template kendoDialogContainer></ng-template>



<!--<app-simple-layout></app-simple-layout>-->
