import { Client } from './client';
import { ClientServiceStatusEnum } from './client-service-status';
import { ClientServiceTypeEnum } from './client-service-type';
import { Product } from './product';

export class ClientService {
  clientServiceId: number;
  clientServiceStatus: ClientServiceStatusEnum;
  client: Client;
  startDate: Date;
  endDate: Date;
  product: Product;
  createTime: Date;
  // activateTime: Date;
  suspendTime: Date;
  cancelTime: Date;
  prorateSuppressed = false;

  // orderItem: OrderItem;

  get clientServiceType(): ClientServiceTypeEnum {
    throw new Error('Not implemented');
  }
}
