import { AccountRoleEnum } from './account-role-enum';
import { ClientService } from './client-service';
import { ClientServiceStatusEnum } from './client-service-status';
import { ClientServiceTypeEnum } from './client-service-type';
import { MembershipClientService } from './membership-client-service';
import { MembershipRequest } from './membership-request';
import { Order } from './order';
import { Search } from './search';

export class Client {
  id: number = null;
  name: string = null;
  legalName: string = null;
  account: string = null;
  dob: Date = null;
  dateCreated: Date = null;
  address: string = null;
  state: string = null;
  phone: string = null;
  email: string = null;
  status: string = null;
  dba: string = null;
  balance: string = null;
  flightCreditAmount: number = null;
  membershipRequest: MembershipRequest = null;

  isSelectableAsBeneficiary = false;
  isSelectedAsBeneficiary = false;

  accountRoleId: AccountRoleEnum = null;
  accountClients: Array<Client> = [];

  clientServices: Array<ClientService> = [];
  orders: Array<Order> = [];
  searchHistory: Array<Search> = [];

  get currentMembership(): MembershipClientService {
    if (this.memberships.length > 0) {
      const currentDate = new Date();

      const membershipClientService = this.memberships
        .filter(
          (i: ClientService) =>
            i.clientServiceType === ClientServiceTypeEnum.Membership ||
            i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
        )
        .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
        .find((i) => i.startDate <= currentDate && i.endDate > currentDate);

      return membershipClientService ? membershipClientService : null;
    }

    return null;
  }

  // get jetPasses(): Array<JetPassClientService> {
  //   return this.clientServices
  //     .filter(i => i.clientServiceType !== ClientServiceTypeEnum.JetPass)
  //     .map(i => i as JetPassClientService);
  // }

  get memberships(): Array<ClientService> {
    return this.clientServices
      .filter(
        (i) =>
          i.clientServiceType === ClientServiceTypeEnum.Membership ||
          i.clientServiceType === ClientServiceTypeEnum.LegacyMembership
      )
      .map((i) => i as ClientService);
  }
}
