import { PricingTypeEnum } from './pricing-type-enum';

export class Leg {
  legId: number;
  market: string;
  departureAirportCode: string;
  arrivalAirportCode: string;
  source: string;
  departureDate: Date;
  departureTime: number;
  dow: number;
  marketableSeats: number;
  bookedSeats: number;
  availableSeats: number;
  disabled: boolean;
  loadFactor: number;
  revenue: number;
  cost: number;
  netRevenue: number;
  activePrice: number;
  overrideType: PricingTypeEnum;
  overrideStartDate: Date;
  overrideEndDate: Date;
}
