import { Category } from './category';
import { Operator } from './operator';
import { Model } from './model';
import { AircraftInsurance } from './aircraft-insurance';
import { Amenity } from './amenity';
import { AircraftImage } from './aircraft-image';
import { ServiceClassEnum } from '../../../../shared/models/service-class-enum';
import { Equipment } from './equipment';
import { AircraftCost } from './aircraft-cost';

export class Aircraft {
  tailNumber: string = null;
  category: Category = new Category();

  model: Model = new Model();

  // argusRating inside Operator
  operator: Operator = new Operator();
  offFleetOperators: Array<Operator> = [];

  homeBase: string = null;
  homeBaseServiceArea: string = null;
  id: number = null;
  gvAircraftId: number = null;
  insurance: AircraftInsurance = new AircraftInsurance();
  maxPax: number = null;
  shuttleMaxPax: number = null;
  deleted = false;
  requiresOwnerApproval = false;
  insuranceRestrictionApprover = false;
  floatingFleet = false;
  yom: Date = null;
  yor: Date = null;
  amenities: Array<Amenity> = [];
  images: Array<AircraftImage> = [];
  serviceClass: ServiceClassEnum = null;

  notes: string = null;

  completed = true;
  approvedByAll: boolean = false;
  operatorApprovalExpirationDate: Date = null;
  operatorApprovalExpirationDateType: string = null;
  onFleet: boolean = null;
  generic: boolean = null;
  equipments: Array<Equipment> = [];
  untypedWyvernImages: number = null;
  usedWyvernImagesCount: number = null;
  wyvernImages: Array<AircraftImage> = [];
  operatorImages: Array<AircraftImage> = [];
  serialNumber: string = null;
  maximumRange: number = null;
  d085OpsSpecsApproval: boolean = null;
  operatorAgreement: boolean = null;
  faaMakeModelSeries: string = null;
  aircraftCost: AircraftCost = new AircraftCost();
  marketplacePhotosApproval: boolean = false;
}
