import * as models from '../../../../domain/models';
import * as dto from '../contracts';

export class PaymentTransactionCreditCardDtoMapper {
  toDto(pt: models.PaymentTransactionCreditCard): dto.PaymentTransactionCreditCardDto {
    return new dto.PaymentTransactionCreditCardDto(
      pt.last4Digits,
      pt.paymentDate,
      pt.paymentTransactionType
    );
  }
}
