import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import * as _ from 'lodash';

import * as contracts from '../../../domain/service-contracts/aircraft';
import * as models from '../../../domain/models/aircraft-category';
import * as aircraftCategories from './get-aircraft-categories.response';
import * as aircraftCalcCategories from './get-aircraft-categories-calc.response';

@Injectable()
export class AircraftMockService implements contracts.IAircraftService {
  getAircraftCategoriesForCalculation(
    request: contracts.GetAircraftCategoryListRequest
  ): Observable<contracts.GetAircraftCategoryForCalculationResponse> {
    const response = new contracts.GetAircraftCategoryForCalculationResponse();
    response.entities = aircraftCalcCategories.response.content.map((i) => {
      const a = new models.AircraftCategory();

      a.setId(_.get(i, 'id', null))
        .setName(_.get(i, 'name', null))
        .setACType(_.get(i, 'actype', null));

      return a;
    });
    return of(response).pipe(delay(1000));
  }

  getAircraftCategories(
    request: contracts.GetAircraftCategoryListRequest
  ): Observable<contracts.GetAircraftCategoryListResponse> {
    const response = new contracts.GetAircraftCategoryListResponse();
    response.entities = aircraftCategories.response.content.map((i) => {
      const a = new models.AircraftCategory();

      a.setId(_.get(i, 'id', null)).setName(_.get(i, 'name', null));

      return a;
    });
    return of(response).pipe(delay(1000));
  }
}
