export const response = {
  "log":{
    "logId":"9a69bc01-0c13-4347-9c3a-865b01fb5e4a",
    "user":{
      "id":2,
      "firstName":"Oleg",
      "lastName":"Solovey"
    },
    "createdTime":"2022-10-05T15:09:14",
    "input":null
  },
  "data":[
    {
      "pax":13,
      "actype":"HEAVY",
      "price":47949.78,
      "reject":null,
      "segments":[
        {
          "depart":"KTEB",
          "arrive":"MYNN",
          "time":"2022-09-23 12:00:00",
          "actype":"HEAVY",
          "pax":13,
          "opportunityPax":null,
          "eft":2.82,
          "price":47693.68,
          "opportunityPrice":null,
          "debug":null,
          "priceInfo":{
            "base":42129.0,
            "surcharges":[
              {
                "code":"fuel",
                "name":"Fuel Surcharge",
                "value":4064.68
              },
              {
                "code":"rzo",
                "name":"RZO Surcharge",
                "value":5000
              },
              {
                "code":"high",
                "name":"High DemandDay Charge",
                "value":2500
              },
              {
                "code":"peak",
                "name":"PeakDay Charge",
                "value":2500
              },
              {
                "code":"esa",
                "name":"International Surcharge",
                "value":2500
              }
            ]
          },
          "taxFees":[
            {
              "priceComponent":{
                "priceComponentId":"9e5b5505-4c9c-4f5b-bc54-e0955ee642b3",
                "name":"Segment Fee (International)",
                "unitBasePriceType":"STATIC",
                "unitQuantityType":"VARIABLE",
                "unitBasePrice":19.70,
                "unitPercentage":null
              },
              "unitBasePrice":19.70,
              "unitQuantity":13,
              "unitPercentage":null,
              "amount":256.10,
              "currency":"USD"
            }
          ],
          "suppressTaxFee":null,
          "legType":null
        }
      ],
      "subtotal":47693.68,
      "serviceFee":0,
      "nonMemberFee":null,
      "segmentFee":256.10,
      "fetTax":0,
      "fetTaxPercent":7.5,
      "roundTripDiscount":null,
      "lastDayOfIATAFuel":303.24,
      "pricingSupportType":"FIXED",
      "bestPrice":true,
      "dnq":false,
      "guaranteedAvailability":false
    },
    {
      "pax":13,
      "actype":"HEAVY",
      "price":62280.10,
      "reject":null,
      "segments":[
        {
          "depart":"KTEB",
          "arrive":"MYNN",
          "time":"2022-09-23 12:00:00",
          "actype":"G-IV SP/G450",
          "pax":13,
          "opportunityPax":null,
          "eft":2.67,
          "price":62024.00,
          "opportunityPrice":null,
          "debug":null,
          "priceInfo":{
            "base":40666.67,
            "surcharges":[
            ]
          },
          "taxFees":[
            {
              "priceComponent":{
                "priceComponentId":"9e5b5505-4c9c-4f5b-bc54-e0955ee642b3",
                "name":"Segment Fee (International)",
                "unitBasePriceType":"STATIC",
                "unitQuantityType":"VARIABLE",
                "unitBasePrice":19.70,
                "unitPercentage":null
              },
              "unitBasePrice":19.70,
              "unitQuantity":13,
              "unitPercentage":null,
              "amount":256.10,
              "currency":"USD"
            }
          ],
          "suppressTaxFee":null,
          "legType":"regular"
        }
      ],
      "subtotal":62024.0,
      "serviceFee":0,
      "nonMemberFee":null,
      "segmentFee":256.10,
      "fetTax":0,
      "fetTaxPercent":7.5,
      "roundTripDiscount":null,
      "lastDayOfIATAFuel":null,
      "pricingSupportType":"DYNAMIC",
      "bestPrice":false,
      "dnq":false,
      "guaranteedAvailability":false
    }
  ]
};
