import { TripTypeEnum } from "../../domain/models";

export class SearchResultsLookup {
    originAirportCode: string;
    destinationAirportCode: string;
    tripType: TripTypeEnum = TripTypeEnum.ONE_WAY;
    departureDate: Date;
    departureTime?: Date;
    returnDate?: Date;
    returnTime?: Date;
    pax: number;
    returnPax: number;
}

    