export enum EnvironmentEnum {
  DEVELOPMENT = 0,
  LOCAL,
  PRODUCTION,
  TEST,
  EPIC
}

export interface IAuth {
  domain: string;
  clientId: string;
  // authorizationParams: {
    audience: string;
  //   redirect_uri?: string;
  // };
  errorPath: string;
  connection?: string;
}

export interface IEnvironment {
  auth: IAuth;
  httpInterceptor: {
    allowedList: Array<string>;
  };
  environment: EnvironmentEnum;
  bearerToken?: string;
  aircraftServiceEndpoint: string;
  clientServiceEndpoint: string;
  clientOperatorServiceEndpoint: string;
  consoleAppEndpoint: string;
  charterPricingServiceEndpoint: string;
  charterPricingServiceEndpointV1: string;
  flightAnalyticsServiceEndpoint: string;
  invoiceAppEndpoint: string;
  invoiceServiceEndpoint: string;
  jetPulseServiceEndpoint: string;
  jetSalesServiceEndpoint: string;
  legServiceEndpoint: string;
  marketplaceServiceEndpoint: string;
  membershipServiceEndpoint: string;
  operatorServiceEndpoint: string;
  orderServiceEndpoint: string;
  offerServiceEndpoint: string;
  productServiceEndpoint: string;
  routeGroupServiceEndpoint: string;
  routeManagementServiceEndpoint: string;
  seatPricingServiceEndpoint: string;
  cyoPricingServiceEndpoint: string;
  charterSalesServiceEndpoint: string;
  operatorApiServiceEndpoint: string;
  operatorAnalyticsApiServiceEndpoint: string;
  sourcingApiServiceEndpoint: string;
  bookingApiServiceEndpoint: string;
  charterPricingAnalyticsEndpoint: string;
  charterPricingAnalyticsEndpointV1: string;
  taskApiServiceEndpoint: string;
  customShuttleServiceEndpoint: string;
  seatChartsUrl?: string;
  version: string;
  taskManagerDebounceTime?: number;
  flightPreviewOptionsEndpoint?: string;
  operatorPlatformApiServiceEndpoint?: string;
  clientAppEndpoint?: string;
  clientSearchEndpoint?: string;
  masterApiEndpoint: string;
  emailServiceEndpoint?: string;
  clientMembershipStatusEndpoint: string;
  erpServiceEndpoint: string;
  serverEnvironment: string;
  allowedDomains: Array<string>;
  identityProvider: string;
  rmDashboardExternalUrl: string;
}
