export const response = {
  "memberships": [{
    "id": "XO_MEMBER",
    "displayName": "XO MEMBERSHIP",
    "nreg": true,
    "categories": ["LITE", "SMID", "Challenger 300/350", "G-IV SP/G450", "MID", "SUPERMID", "CL604", "HEAVY", "CL850", "GLEX"]
  }, {
    "id": "XO_NON_MEMBER",
    "displayName": "XO NON-MEMBER / XO SHARED",
    "nreg": true,
    "categories": ["LITE", "SMID", "Challenger 300/350", "G-IV SP/G450", "MID", "SUPERMID", "CL604", "HEAVY", "CL850", "GLEX"]
  }, { "id": "SELECT", "displayName": "SELECT", "nreg": false, "categories": ["LITE", "SMID"] }, {
    "id": "SIGNATURE",
    "displayName": "SIGNATURE",
    "nreg": false,
    "categories": ["LITE", "SMID"]
  }, { "id": "ELAC", "displayName": "ELAC", "nreg": false, "categories": ["LITE", "MID", "SMID", "CL30", "HEAVY"] }],
  "peakDays": {
    "SELECT": ["2023-01-01", "2023-01-02", "2023-01-03", "2023-01-16", "2023-02-16", "2023-02-17", "2023-02-20", "2023-02-21", "2023-03-12", "2023-03-18", "2023-03-19", "2023-03-25", "2023-03-26", "2023-04-06", "2023-04-09", "2023-04-10", "2023-11-17", "2023-11-18", "2023-11-19", "2023-11-21", "2023-11-22", "2023-11-23", "2023-11-26", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-22", "2023-12-23", "2023-12-25", "2023-12-26", "2022-03-13", "2022-03-19", "2022-03-20", "2022-03-26", "2022-03-27", "2022-04-07", "2022-04-10", "2022-04-18", "2022-11-19", "2022-11-20", "2022-01-01", "2022-01-02", "2022-01-03", "2022-01-17", "2022-02-17", "2022-02-18", "2022-02-21", "2022-02-22", "2022-04-17", "2022-11-22", "2022-11-23", "2022-11-26", "2022-11-27", "2022-12-16", "2022-12-17", "2022-12-18", "2022-12-25", "2022-12-26", "2022-12-27", "2022-12-28"],
    "ELAC": ["2020-02-13", "2020-02-14", "2020-02-17", "2020-02-18", "2020-04-12", "2020-11-24", "2020-11-25", "2020-11-28", "2020-11-29", "2020-12-18", "2020-12-19", "2020-12-20", "2020-12-25", "2020-12-26", "2020-12-27", "2020-12-28", "2021-01-01", "2021-01-02", "2021-01-03", "2021-01-18", "2021-02-11", "2021-02-12", "2021-02-15", "2021-02-16", "2021-04-04", "2021-11-23", "2021-11-24", "2021-11-27", "2021-11-28", "2021-12-17", "2021-12-18", "2021-12-19", "2021-12-25", "2021-12-26", "2021-12-27", "2021-12-28", "2021-09-01", "2021-09-04", "2021-09-05", "2023-01-01", "2023-01-02", "2023-01-03", "2023-01-16", "2023-02-16", "2023-02-17", "2023-02-20", "2023-02-21", "2023-04-09", "2023-11-21", "2023-11-22", "2023-11-23", "2023-11-26", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-22", "2023-12-23", "2023-12-25", "2023-12-26", "2022-01-01", "2022-01-02", "2022-01-03", "2022-01-17", "2022-02-17", "2022-02-18", "2022-02-21", "2022-02-22", "2022-04-17", "2022-11-22", "2022-11-23", "2022-11-26", "2022-11-27", "2022-12-16", "2022-12-17", "2022-12-18", "2022-12-25", "2022-12-26", "2022-12-27", "2022-12-28"],
    "XO_MEMBER": ["2023-01-01", "2023-01-02", "2023-01-03", "2023-01-16", "2023-02-16", "2023-02-17", "2023-02-20", "2023-02-21", "2023-03-12", "2023-03-18", "2023-03-19", "2023-03-25", "2023-03-26", "2023-04-06", "2023-04-09", "2023-04-10", "2023-11-17", "2023-11-18", "2023-11-19", "2023-11-21", "2023-11-22", "2023-11-23", "2023-11-26", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-22", "2023-12-23", "2023-12-25", "2023-12-26", "2022-03-13", "2022-03-19", "2022-03-20", "2022-03-26", "2022-03-27", "2022-04-07", "2022-04-10", "2022-04-18", "2022-11-19", "2022-11-20", "2022-01-01", "2022-01-02", "2022-01-03", "2022-01-17", "2022-02-17", "2022-02-18", "2022-02-21", "2022-02-22", "2022-04-17", "2022-11-22", "2022-11-23", "2022-11-26", "2022-11-27", "2022-12-16", "2022-12-17", "2022-12-18", "2022-12-25", "2022-12-26", "2022-12-27", "2022-12-28"],
    "SIGNATURE": ["2023-01-01", "2023-01-02", "2023-01-03", "2023-01-16", "2023-02-16", "2023-02-17", "2023-02-20", "2023-02-21", "2023-03-12", "2023-03-18", "2023-03-19", "2023-03-25", "2023-03-26", "2023-04-06", "2023-04-09", "2023-04-10", "2023-11-17", "2023-11-18", "2023-11-19", "2023-11-21", "2023-11-22", "2023-11-23", "2023-11-26", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-22", "2023-12-23", "2023-12-25", "2023-12-26", "2022-01-01", "2022-01-02", "2022-01-03", "2022-01-17", "2022-02-17", "2022-02-18", "2022-02-21", "2022-02-22", "2022-04-17", "2022-11-22", "2022-11-23", "2022-11-26", "2022-11-27", "2022-12-16", "2022-12-17", "2022-12-18", "2022-12-25", "2022-12-26", "2022-12-27", "2022-12-28"],
    "XO_NON_MEMBER": ["2023-01-01", "2023-01-02", "2023-01-03", "2023-01-16", "2023-02-16", "2023-02-17", "2023-02-20", "2023-02-21", "2023-03-12", "2023-03-18", "2023-03-19", "2023-03-25", "2023-03-26", "2023-04-06", "2023-04-09", "2023-04-10", "2023-11-17", "2023-11-18", "2023-11-19", "2023-11-21", "2023-11-22", "2023-11-23", "2023-11-26", "2023-12-15", "2023-12-16", "2023-12-17", "2023-12-22", "2023-12-23", "2023-12-25", "2023-12-26", "2022-03-13", "2022-03-19", "2022-03-20", "2022-03-26", "2022-03-27", "2022-04-07", "2022-04-10", "2022-04-18", "2022-11-19", "2022-11-20", "2022-01-01", "2022-01-02", "2022-01-03", "2022-01-17", "2022-02-17", "2022-02-18", "2022-02-21", "2022-02-22", "2022-04-17", "2022-11-22", "2022-11-23", "2022-11-26", "2022-11-27", "2022-12-16", "2022-12-17", "2022-12-18", "2022-12-25", "2022-12-26", "2022-12-27", "2022-12-28"]
  },
  "calculatorCategories": [{
    "id": null,
    "name": "Light Jets",
    "nregName": "N-Reg Light Jets",
    "elacName": "Light Jets",
    "actype": "LITE"
  }, {
    "id": null,
    "name": "Citation X",
    "nregName": "N-Reg Citation X",
    "elacName": "Citation X",
    "actype": "SMID"
  }, {
    "id": null,
    "name": "Challenger 300/350",
    "nregName": "N-Reg Challenger 300/350",
    "elacName": "Challenger 300/350",
    "actype": "Challenger 300/350"
  }, {
    "id": null,
    "name": "Gulfstream IV-SP/450",
    "nregName": "N-Reg Gulfstream IV-SP/450",
    "elacName": "Gulfstream IV-SP/450",
    "actype": "G-IV SP/G450"
  }, {
    "id": null,
    "name": "Citation XLS",
    "nregName": "Citation XLS",
    "elacName": "Mid Size Jets",
    "actype": "MID"
  }, {
    "id": null,
    "name": "Challenger 350",
    "nregName": "Challenger 350",
    "elacName": "Challenger 350",
    "actype": "SUPERMID"
  }, {
    "id": null,
    "name": "Challenger 300",
    "nregName": "Challenger 300",
    "elacName": "Challenger 300",
    "actype": "CL30"
  }, {
    "id": null,
    "name": "Challenger 604",
    "nregName": "Challenger 604",
    "elacName": "Challenger 604",
    "actype": "CL604"
  }, {
    "id": null,
    "name": "Challenger 605",
    "nregName": "Challenger 605",
    "elacName": "Heavy Jets",
    "actype": "HEAVY"
  }, {
    "id": null,
    "name": "Challenger 850",
    "nregName": "Challenger 850",
    "elacName": "Challenger 850",
    "actype": "CL850"
  }, { "id": null, "name": "Global 6000", "nregName": "Global 6000", "elacName": "Global 6000", "actype": "GLEX" }],
  "premiumFeatures": [{ "id": 2, "name": "Guaranteed Aircraft Recovery", "notes": null }, {
    "id": 3,
    "name": "Guaranteed Availability Peak Days",
    "notes": "24 Hours Notice"
  }, { "id": 4, "name": "Fixed Hourly Rate", "notes": "Only available with Elite Access" }, {
    "id": 5,
    "name": "Catering credit",
    "notes": "$250 / Leg"
  }, { "id": 6, "name": "Get free seats on empty legs", "notes": null }],
  "comparisonCategories": [{
    "id": 4,
    "name": "Light Jets",
    "nregName": null,
    "elacName": null,
    "actype": null
  }, { "id": 5, "name": "Mid Size Jets", "nregName": null, "elacName": null, "actype": null }, {
    "id": 7,
    "name": "Heavy Jets",
    "nregName": null,
    "elacName": null,
    "actype": null
  }, { "id": 6, "name": "Super Mid Size Jets", "nregName": null, "elacName": null, "actype": null }]
};
