import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';
import * as actions from '../actions/market-collection';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<viewModels.ListItem>;
  pristine: boolean;
  lookup: viewModels.Lookup;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  pristine: false,
  lookup: new viewModels.Lookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        lookup: _.cloneDeep(action.payload.lookup),
      };
    }

    case actions.ADD_MARKET_FAIL:
    case actions.ADD_MARKET_SUCCESS: {
      // const items = _.cloneDeep(state.items).push(_.cloneDeep(action.payload.item));
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        pristine: true,
      };
    }
    // case actions.SAVE_SUCCESS: {
    //   const items = _.cloneDeep(state.items).map(i => i.id === action.payload.item.id ? _.cloneDeep(action.payload.item) : i);
    //   return {
    //     ...state,
    //     isLoaded: true,
    //     isLoading: false,
    //     items,
    //     pristine: true
    //   };
    // }

    case actions.LOAD_SUCCESS: {
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = _.cloneDeep(action.payload.total);
      return {
        lookup,
        isLoaded: true,
        isLoading: false,
        items: _.cloneDeep(action.payload.items),
        pristine: true,
      };
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        lookup,
        isLoaded: true,
      };
    }

    case actions.ADD_MARKET: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        pristine: false,
      };
    }

    // case actions.SET_MARKET: {
    //   const markets = _.cloneDeep(state.items).map(i => i.id === action.payload.item.id ? _.cloneDeep(action.payload.item) : i);
    //   return {
    //     ...state,
    //     items: markets,
    //     pristine: false
    //   };
    // }

    // case actions.SAVE_FAIL: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     isLoaded: false,
    //     pristine: false
    //   };
    // }

    // case actions.SAVE: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isLoaded: false
    //   };
    // }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getPristine = (state: State) => state.pristine;
export const getLookup = (state: State) => state.lookup;
