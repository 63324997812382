import * as viewModels from '../../view/view-models';
import * as models from '../../domain/models';
import * as actions from '../actions/airport-collection';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<viewModels.ListItem>;
  lookup: viewModels.Lookup;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  lookup: new viewModels.Lookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload.lookup),
        isLoading: true,
      };
    }

    // case actions.SAVE_SUCCESS:
    case actions.LOAD_SUCCESS: {
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = _.cloneDeep(action.payload.total);

      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        lookup,
        items: _.cloneDeep(action.payload.items),
      };
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        isLoaded: true,
        lookup,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getLookup = (state: State) => state.lookup;
