import { createReducer, on } from '@ngrx/store';

import * as models from '../../domain/models';
import * as actions from '../actions/client-list.actions';
import * as viewModels from '../../view/view-models';

import * as _ from 'lodash';

export const clientListFeatureKey = 'clientList';

export interface State {
  criteria: viewModels.ClientLookupCriteria;
  currentPage: number;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Client>;
  itemsPerPage: number;
  itemsTotalCount: number;
}

const initialState: State = {
  criteria: new viewModels.ClientLookupCriteria(),
  currentPage: 1,
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 15,
  itemsTotalCount: 0,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.reset,
    (): State => ({
      ...initialState,
    })
  ),

  on(
    actions.searchClients,
    (state, { payload }): State => ({
      ...state,
      criteria: _.cloneDeep(payload.criteria),
      isLoading: true,
    })
  ),
  on(
    actions.searchClientsSuccess,
    (state, { payload: { page, data, count } }): State => ({
      ...state,
      currentPage: page,
      isLoaded: false,
      isLoading: false,
      items: data,
      itemsTotalCount: count,
    })
  ),
  on(
    actions.searchClientsFail,
    (state): State => ({
      ...state,
      isLoaded: false,
      isLoading: false,
    })
  )
);
