export const response = {
  message: 'OK',
  code: 200,
  data: [
    {
      active: true,
      createTime: '2021-03-10T09:44:35.598753+00:00',
      documentId: 369,
      documentTypeId: 1,
      generateTime: null,
      grayedOut: true,
      name: 'Invoice_17493.pdf',
      regeneratable: true,
      updateTime: null,
      uploadTime: '2021-03-10T13:18:12.070291+00:00',
      url:
        'https://s3.amazonaws.com/jetsmarter-document-repo-dev/25d3645a-78e0-4a61-9d49-36aa68912712.pdf',
      userId: 2,
    },
  ],
};
