import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const loadUserGroups = createAction(
  '[UserMgmt.EntitiesToUpdate] Load User Groups',
  props<{ payload: models.User }>()
);
export const loadUserGroupsSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Load User Groups Success',
  props<{ payload: Array<models.UserGroup> }>()
);
export const loadUserGroupsFail = createAction('[UserMgmt.EntitiesToUpdate] Load User Groups Fail');

export const updateUserGroups = createAction(
  '[UserMgmt.EntitiesToUpdate] Update User Groups',
  props<{ payload: models.User }>()
);
export const updateUserGroupsSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Update User Groups Success'
);
export const updateUserGroupsFail = createAction(
  '[UserMgmt.EntitiesToUpdate] Update User Groups Fail'
);

export const loadGroupUsers = createAction(
  '[UserMgmt.EntitiesToUpdate] Load Group Users',
  props<{ payload: number }>()
);
export const loadGroupUsersSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Load Group Users Success',
  props<{ payload: Array<models.GroupUser> }>()
);
export const loadGroupUsersFail = createAction('[UserMgmt.EntitiesToUpdate] Load Group Users Fail');

export const updateGroupUsers = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Group Users',
  props<{ payload: models.GroupToUpdate }>()
);
export const updateGroupUsersSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Group Users Success'
);
export const updateGroupUsersFail = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Group Users Fail'
);

export const removeUserFromGroup = createAction(
  '[UserMgmt.EntitiesToUpdate] Remove User From Group',
  props<{ payload: number }>()
);

export const addUsersToGroup = createAction(
  '[UserMgmt.EntitiesToUpdate] Add Users To Group',
  props<{ payload: Array<models.GroupUser> }>()
);

export const resetGroupUsers = createAction('[UserMgmt.EntitiesToUpdate] Reset Group Users');

export const resetUserToUpdateGroups = createAction(
  '[UserMgmt.EntitiesToUpdate] Reset User To Update Groups'
);

// *********** Restricted Functions Actions ***********

export const loadUserRestricts = createAction(
  '[UserMgmt.EntitiesToUpdate] Load User Restricts',
  props<{ payload: models.User }>()
);
export const loadUserRestrictsSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Load User Restricts Success',
  props<{ payload: Array<models.UserRestrictedFunction> }>()
);
export const loadUserRestrictsFail = createAction(
  '[UserMgmt.EntitiesToUpdate] Load User Restricts Fail'
);

export const loadGroupRestricts = createAction(
  '[UserMgmt.EntitiesToUpdate] Load Group Restricts',
  props<{ payload: number }>()
);
export const loadGroupRestrictsSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Load Group Restricts Success',
  props<{ payload: Array<models.UserRestrictedFunction> }>()
);
export const loadGroupRestrictsFail = createAction(
  '[UserMgmt.EntitiesToUpdate] Load Group Restricts Fail'
);

export const updateRestricts = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Restricts',
  props<{ payload: models.User | models.GroupToUpdate }>()
);
export const updateRestrictsSuccess = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Restricts Success'
);
export const updateRestrictsFail = createAction(
  '[UserMgmt.EntitiesToUpdate] Update Restricts Fail'
);

export const resetGroupRestricts = createAction(
  '[UserMgmt.EntitiesToUpdate] Reset Group Restricts'
);

export const resetUserToUpdateRestrictions = createAction(
  '[UserMgmt.EntitiesToUpdate] Reset User To Update Restrictions'
);
