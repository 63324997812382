import * as models from '../models';
import * as _ from 'lodash';
import * as moment from 'moment';

export const filterOffers = (
  offers: Array<models.CustomShuttleOffers>,
  criteria: models.CustomShuttleCriteria
): Array<models.CustomShuttleOffers> =>
  offers.filter((item) => {
    const show = [];
    if (!criteria.active) {
      show.push(item.active);
    }
    if (criteria.aircraftCategoryName !== 'All') {
      show.push(item.aircraftCategoryName === criteria.aircraftCategoryName);
    }
    if (criteria.offerTypeName !== 'All') {
      show.push(item.offerTypeName === criteria.offerTypeName);
    }
    if (criteria.membershipTierName !== 'All') {
      show.push(item.membershipTierName === criteria.membershipTierName);
    }
    return show.every(Boolean);
  });

const nest = (seq, keys: Array<string>) => {
  if (!keys.length) {
    return seq;
  }

  const first = keys[0];
  const rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), (value) => {
    return nest(value, rest);
  });
};

const parseDateToPaddedStr = (date: Date) => {
  const dateObj = {
    years: date.getUTCFullYear(),
    months: _.padStart(date.getUTCMonth() + 1 + '', 2, '0'),
    date: _.padStart(date.getUTCDate() + '', 2, '0'),
    full: '',
  };
  dateObj.full = dateObj.years + '-' + dateObj.months + '-' + dateObj.date;
  return dateObj;
};

export const groupData = (data: Array<models.CustomShuttleOffers>) => {
  let sortedData = _.values(_.sortBy(data, 'departureStartTime'));
  sortedData = _.map(sortedData, (sdRow: any) => {
    const date = new Date(sdRow.departureStartTime); // departureStartTime has timezone in it
    sdRow._dayTimestamp = parseDateToPaddedStr(date).full;
    return sdRow;
  });
  return nest(sortedData, ['aircraftCategoryId', 'membershipTypeId', '_dayTimestamp']);
};

export const orderData = (
  format: string,
  filter: string,
  data: Array<models.CustomShuttleOffers>,
  criteria: models.CustomShuttleCriteria
) => {
  const state = new models.SchedulerOptions();
  const selectedDate = moment(criteria.startDate).format(filter);

  return _.chain(data)
    .filter((row) => moment.utc(row[state.columnKey]).format(filter) === selectedDate)
    .groupBy(state.resourcesName[0])
    .map((row, key) => {
      return {
        title: key,
        [state.resourceIdKeyName[0]]: row[0][state.resourceIdKeyName[0]],
        data: _.chain(row)
          .groupBy(state.resourcesName[1])
          .map((r, k) => {
            return {
              title: k,
              [state.resourceIdKeyName[1]]: r[0][state.resourceIdKeyName[1]],
              data: _.chain(r)
                .groupBy((x) => {
                  return moment.utc(x[state.columnKey]).format(format);
                })
                .mapValues((x: any) => {
                  return x.sort((a, b) => {
                    if (+a[state.sortEventsByKey] < +b[state.sortEventsByKey]) {
                      return -1;
                    }
                    if (+a[state.sortEventsByKey] > +b[state.sortEventsByKey]) {
                      return 1;
                    }
                    return 0;
                  });
                })
                .value(),
            };
          })
          .sortBy('title')
          .value(),
      };
    })
    .sortBy('title')
    .value();
};

export const getSelectedItem = (
  d: Array<models.CustomShuttleGroupedOffers>,
  params: models.CustomShuttleDetailsParams
) => {
  const state = new models.SchedulerOptions();
  return _.chain(d)
    .filter((row) => row[state.resourceIdKeyName[0]] === params[state.resourceIdKeyName[0]])
    .map(({ data }) => data)
    .flatten()
    .filter((row) => row[state.resourceIdKeyName[1]] === params[state.resourceIdKeyName[1]])
    .map(({ data }) => data)
    .flatten()
    .get(0)
    .values()
    .filter(
      ([item]) => moment(item.departureStartTime).format('YYYY-MM-DD') === params.departureStartDate
    )
    .get(0)
    .value();
};

export const getBulkItems = (
  d: Array<models.CustomShuttleOffers>,
  datePad: string
): Array<models.CustomShuttleOffers> =>
  d.filter(({ departureStartTime }) => moment(departureStartTime).format('MMDDYYYY') === datePad);
