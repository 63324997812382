import { Leg } from "./leg";

export class Search {
    searchId: number;
    clientId: number;
    device: string;
    version: string;
    searchTime: Date;
    resultCount: Number;
    searchType: string;
    legs: Array<Leg>;
    pax: number;
}