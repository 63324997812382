import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromUsersCollection from './users-collection.reducer';
import * as fromUserManagementDataCollection from './user-management-data-collection.reducer';
import * as fromGroupsCollection from './groups-collection.reducer';
import * as fromRestrictedFunctionsCollection from './restricted-functions-collection.reducer';
import * as fromEntitiesToUpdate from './entities-to-update.reducer';

export interface State {
  [fromUsersCollection.usersCollectionFeatureKey]: fromUsersCollection.State;
  [fromUserManagementDataCollection.userManagementDataCollectionFeatureKey]: fromUserManagementDataCollection.State;
  [fromGroupsCollection.groupsCollectionFeatureKey]: fromGroupsCollection.State;
  [fromRestrictedFunctionsCollection.restrictedFunctionsCollectionFeatureKey]: fromRestrictedFunctionsCollection.State;
  [fromEntitiesToUpdate.entitiesToUpdateFeatureKey]: fromEntitiesToUpdate.State;
}

export const featureName = 'userMgmt';

export const reducers = combineReducers<State, Action>({
  [fromUsersCollection.usersCollectionFeatureKey]: fromUsersCollection.reducer,
  [fromUserManagementDataCollection.userManagementDataCollectionFeatureKey]:
    fromUserManagementDataCollection.reducer,
  [fromGroupsCollection.groupsCollectionFeatureKey]: fromGroupsCollection.reducer,
  [fromRestrictedFunctionsCollection.restrictedFunctionsCollectionFeatureKey]:
    fromRestrictedFunctionsCollection.reducer,
  [fromEntitiesToUpdate.entitiesToUpdateFeatureKey]: fromEntitiesToUpdate.reducer,
});

export const getUserMgmtState = createFeatureSelector<State>(featureName);
