import * as models from '../../../../domain/models';

// TODO: define OrderItem DTO
export class OrderItemSerializable {
  id: number = null;
  orderId: number = null;
  childOrderItems: Array<OrderItemSerializable> = new Array<OrderItemSerializable>();
  parentOrderItemId: number = null;
  clientId: number = null;
  discount: models.PriceComponent = null;
  // membership: models.Membership = null;
  promotion: models.Promotion = null;
  // promotionItem: models.PromotionItem = null;
  promotionItemId: number = null;
  basePrice: number = null;
  quantity: number = null;
  salesAction: models.SalesActionEnum = null;
  totalPrice: number = null;
  notes: string = null;
  prorate?: number = null;
  // membershipRequest: models.MembershipRequest = null;
  orderItemType: models.OrderItemTypeEnum;
}
