import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../../domain/models';

export interface IPriceCalculator {
  getPrice(request: GetPriceCalculatorRequest): Observable<GetPriceCalculatorResponse>;
  updatePrice(request: UpdatePriceCalculatorRequest): Observable<GetPriceCalculatorResponse>;
  getCalculation(request: GetCalculationRequest): Observable<GetPriceCalculatorResponse>;
  getPeakDays(): Observable<GetPeakDaysResponse>;
  getPricingMeta(): Observable<GetPricingMetaResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Sales.IPriceCalculator');

export class GetPriceCalculatorRequest {
  offer: models.PricingCalculatorOffer;
}

export class UpdatePriceCalculatorRequest {
  offer: models.PriceCalculatorOfferRequest;
}

export class GetCalculationRequest {
  logId: string;
}

export class GetPriceCalculatorResponse {
  log: models.CalculationLog = null;
  items: Array<models.PriceCalculator> = null;
}

export class GetPeakDaysResponse {
  items: models.PeakDays;
}

export class GetPricingMetaResponse {
  item: models.PricingMeta;
}
