import { RequestSourceEnum } from "./request-source.enum";

export class SearchResultOption {
  operatorName: string;
  operatorUuid: string;
  price: number;
  aircraftModel: string;
  categoryName: string;
  tailNumber: string;
  vendorPrice: number;
  fuelStops: number;
  source: RequestSourceEnum;
}
