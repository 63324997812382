import { OrderItemKind } from './order-item-kind';
import * as models from '../../domain/models';

export class OrderItem {
  itemKind: OrderItemKind = null;
  index: number = null;
  product: models.Product = null;
  name: string = null;
  amount: number | null = null;
  totalAmount: number | null = null;
  percentage: number | null = null;
  selectable: boolean = null;
  client: models.Client = null;
  removable: boolean = true;
  salesAction: string = null;
  quantity: number = null;
  needsApproval: boolean = null;
  value: string = null;
  requestId: number = null;
  requestUrl: string = null;
  isInternalComment?: boolean = null;
  hasErrors: boolean = false;
}
