import { Inject, Injectable } from '@angular/core';
import * as airportServiceContracts from '../../service-contracts/airport';
import { IAirportCodeValidator } from './contracts';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AirportCodeValidator implements IAirportCodeValidator {
  constructor(
    @Inject(airportServiceContracts.SERVICE_TOKEN)
    public airportService: airportServiceContracts.IAirportService
  ) {}

  validate(code: string): Observable<boolean> {
    const request = new airportServiceContracts.GetAirportByCodeRequest();
    request.code = code;

    return this.airportService.getAirportByCode(request).pipe(
      map((response: airportServiceContracts.GetAirportByCodeResponse) => !!response.valid),
      catchError(() => of(false))
    );
  }
}
