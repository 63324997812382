import { createAction, props } from '@ngrx/store';

export const getAirportList = createAction(
  '[Shared.Airport] Get Airport List',
  props<{ payload: string }>()
);
export const getAirportListSuccess = createAction(
  '[Shared.Airport] Get Airport List Success',
  props<{ payload: any }>()
);
export const getAirportListFail = createAction('[Shared.Airport] Get Airport List Fail');
