import * as models from '../../../domain/models';

import * as _ from 'lodash';

export class CharterRequestMapperService {
  static parseClientRequest(item: any): models.ClientRequest {
    const ro = new models.ClientRequest();
    ro.requestId = _.get(item, 'requestId', null);
    ro.legId = _.get(item, 'legId', null);
    ro.legUrl = _.get(item, 'legUrl', null);
    ro.flightRequestId = _.get(item, 'flightRequestId', null);
    ro.flightRequestUrl = _.get(item, 'flightRequestUrl', null);
    ro.departureDate = item.departureDate !== null ? new Date(item.departureDate) : null;
    ro.requestUrl = item.requestUrl ? item.requestUrl : null;
    ro.flightRequest = item.flightRequest
      ? this.mapCharterRequestInternal(item.flightRequest)
      : null;
    ro.cateringCredit = item.cateringCredit
      ? this.mapCateringCreditInternal(item.cateringCredit)
      : null;
    return ro;
  }

  static mapCharterRequestInternal(obj: any): models.CharterClientRequest {
    const rq = _.cloneDeep(obj);
    const ro = new models.CharterClientRequest();
    ro.requestId = rq.requestId;
    ro.departureDate = rq.departureDate !== null ? new Date(rq.departureDate) : null;
    ro.requestUrl = rq.requestUrl;
    ro.charterAction = rq.charterAction;
    ro.orderItemTotal = rq.hasOwnProperty('orderItemTotal') ? rq['orderItemTotal'] : null;
    ro.isValid = rq.hasOwnProperty('isValid') ? rq['isValid'] : null;

    ro.invoices =
      rq.hasOwnProperty('invoices') && rq.invoices.length
        ? rq.invoices.map((i) => {
            const inv = new models.CharterInvoice();

            inv.id = i.hasOwnProperty('id') ? i['id'] : null;
            inv.totalPrice = i.hasOwnProperty('totalPrice') ? i['totalPrice'] : null;
            inv.formUrl = i.hasOwnProperty('formUrl') ? i['formUrl'] : null;
            inv.paymentStatus = i.hasOwnProperty('invoicePaymentStatus')
              ? i['invoicePaymentStatus']
              : null;
            inv.formUrlKey = i.hasOwnProperty('formUrlKey') ? i['formUrlKey'] : null;
            inv.formTplId = i.hasOwnProperty('formTplId') ? i['formTplId'] : null;
            inv.formEditUrl = i.hasOwnProperty('formEditUrl') ? i['formEditUrl'] : null;
            inv.orderUrl = i.hasOwnProperty('orderUrl') ? i['orderUrl'] : null;
            inv.clientSource = i.hasOwnProperty('clientSource')
              ? i['clientSource'] !== null ? String(i['clientSource']) : i['clientSource']
              : models.ClientSourceTypeEnum.JetSmarter;

            return inv;
          })
        : [];

    ro.invoices = _.orderBy(ro.invoices, ['id'], ['asc']);

    return ro;
  }

  static mapCateringCreditInternal(obj: any): models.CateringCredit {
    const rq = _.cloneDeep(obj);
    const ro = new models.CateringCredit();
    ro.amount = _.get(obj, 'amount', null);
    ro.cateringCreditId = _.get(obj, 'cateringCreditId', null);
    ro.clientId = _.get(obj, 'clientId', null);
    ro.flightRequestId = _.get(obj, 'flightRequestId', null);
    ro.legId = _.get(obj, 'legId', null);
    ro.legRequestId = _.get(obj, 'legRequestId', null);
    return ro;
  }
}
