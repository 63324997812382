import { createSelector } from 'reselect';

import * as fromRouter from '@ngrx/router-store';

import * as fromCore from '../reducers';

export const getRouterState = createSelector(fromCore.getCoreState, (state) => state['router']);
export const getRouterPath = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState) => state.state && state.state.url
);
