import { CharterInvoiceAuthorizationStateEnum } from './charter-invoice-authorization-state-enum';

export class SalesGeneratedDocument {
  active: boolean;
  createTime: Date;
  documentId: number = null;
  documentTypeId: CharterInvoiceAuthorizationStateEnum = null;
  generateTime: Date;
  grayedOut: boolean = null;
  name: string;
  regeneratable: boolean = null;
  updateTime: Date;
  uploadTime: Date;
  url: string;
  userId: number = null;
}
