import { User } from './user';

export class PurchaseRequisition {
  createdDate: Date;
  createdUser: User;

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): PurchaseRequisition {
    this.createdDate = createdDate;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): PurchaseRequisition {
    this.createdUser = createdUser;
    return this;
  }
}
