import { SalesQuote } from './sales-quote';
import { User } from './user';
import { Attribute } from './attribute';

export class SmartSalesQuote {
  private smartSalesQuoteId: string;
  private salesQuotes: Array<SalesQuote> = [];
  private lastModifiedDate: Date;
  private lastModifiedUser: User;
  private createdDate: Date;
  private createdUser: User;
  private shareableLink: string;
  private attributes: Array<Attribute>;

  getSmartSalesQuoteId(): string {
    return this.smartSalesQuoteId;
  }

  getShareableLink(): string {
    return this.shareableLink;
  }

  setShareableLink(shareableLink: string): SmartSalesQuote {
    this.shareableLink = shareableLink;
    return this;
  }

  setSmartSalesQuoteId(smartSalesQuoteId: string): SmartSalesQuote {
    this.smartSalesQuoteId = smartSalesQuoteId;

    return this;
  }

  getSalesQuotes(): Array<SalesQuote> {
    return this.salesQuotes;
  }

  setSalesQuotes(salesQuotes: Array<SalesQuote>): SmartSalesQuote {
    this.salesQuotes = salesQuotes;

    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(lastModifiedDate: Date): SmartSalesQuote {
    this.lastModifiedDate = lastModifiedDate;

    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  setLastModifiedUser(lastModifiedUser: User): SmartSalesQuote {
    this.lastModifiedUser = lastModifiedUser;

    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  setCreatedDate(createdDate: Date): SmartSalesQuote {
    this.createdDate = createdDate;

    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(createdUser: User): SmartSalesQuote {
    this.createdUser = createdUser;

    return this;
  }

  getAttributes(): Attribute[] {
    return this.attributes;
  }

  setAttributes(attributes: Attribute[]): SmartSalesQuote {
    this.attributes = attributes;
    return this;
  }
}
