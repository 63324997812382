import * as actions from '../actions/flight-preview-collection';
import * as models from '../../domain/models';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.FlightOptionPreview>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.FLIGHT_PREVIEW_LOAD: {
      return {...state, loading: true};
    }

    case actions.FLIGHT_PREVIEW_LOAD_SUCCESS: {
      return {
        loaded: true,
        loading: false,
        items: action.payload
      };
    }

    case actions.FLIGHT_PREVIEW_LOAD_FAIL: {
      return {
        items: [],
        loaded: false,
        loading: false
      };
    }

    case actions.FLIGHT_PREVIEW_RESET: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export const getFlightPreviewLoaded = (state: State) => state.loaded;
export const getFlightPreviewLoading = (state: State) => state.loading;
export const getFlightPreviewItems = (state: State) => state.items;
