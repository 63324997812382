import * as actions from '../actions/leg-booking';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.LegBookingHistory>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_HISTORY: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case actions.LOAD_HISTORY_SUCCESS: {
      return {
        items: _.cloneDeep(action.payload.bookings),
        loaded: true,
        loading: false,
      };
    }

    case actions.LOAD_HISTORY_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const loaded = (state: State) => state.loaded;
export const items = (state: State) => state.items;
