/* eslint-disable max-lines */

import * as models from '../../../domain/models';

export const response = {
  message: 'OK',
  code: 200,
  data: [
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 1,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 3,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 4,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      id: 6,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 7,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 8,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 10,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 5,
        name: 'Simple 3 Months (FREE)',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 14,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 5,
        name: 'Simple 3 Months (FREE)',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 15,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 5,
        name: 'Simple 3 Months (FREE)',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 17,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 7,
        name: 'Affinity',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 7,
        name: 'Affinity',
      },
      id: 19,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 8,
        name: 'Legacy',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 8,
        name: 'Legacy',
      },
      id: 20,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4166.67',
        description: null,
        id: 13,
        name: 'Sophisticated 1 Month',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 21,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4166.67',
        description: null,
        id: 13,
        name: 'Sophisticated 1 Month',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 23,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 5,
        name: 'Simple 3 Months (FREE)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 5,
        name: 'Simple 3 Months (FREE)',
      },
      id: 28,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4166.67',
        description: null,
        id: 13,
        name: 'Sophisticated 1 Month',
      },
      nextProduct: {
        amount: '4166.67',
        description: null,
        id: 13,
        name: 'Sophisticated 1 Month',
      },
      id: 29,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 16,
        name: 'LegacyTrial (TrialMembershipR)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 16,
        name: 'LegacyTrial (TrialMembershipR)',
      },
      id: 30,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 17,
        name: 'GILT (GILT_Membership)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 17,
        name: 'GILT (GILT_Membership)',
      },
      id: 31,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 18,
        name: 'ARTBASEL (ARTBASEL_Membership)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 18,
        name: 'ARTBASEL (ARTBASEL_Membership)',
      },
      id: 32,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 19,
        name: 'UBS (UBS)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 19,
        name: 'UBS (UBS)',
      },
      id: 33,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3990',
        description: null,
        id: 20,
        name: 'ACCESS-OLD (Social)',
      },
      nextProduct: {
        amount: '3990',
        description: null,
        id: 20,
        name: 'ACCESS-OLD (Social)',
      },
      id: 34,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3990',
        description: null,
        id: 20,
        name: 'ACCESS-OLD (Social)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      id: 35,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 21,
        name: 'INVITATION ONLY (Gift)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 21,
        name: 'INVITATION ONLY (Gift)',
      },
      id: 36,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 22,
        name: 'SMART (MembershipManual)',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 22,
        name: 'SMART (MembershipManual)',
      },
      id: 37,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4999',
        description: null,
        id: 23,
        name: 'SMART (Manual)',
      },
      nextProduct: {
        amount: '4999',
        description: null,
        id: 23,
        name: 'SMART (Manual)',
      },
      id: 38,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '23340',
        description: null,
        id: 24,
        name: 'CORPORATE (Corporate)',
      },
      nextProduct: {
        amount: '23340',
        description: null,
        id: 24,
        name: 'CORPORATE (Corporate)',
      },
      id: 39,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 25,
        name: 'AffinityC (AFFINITY_C)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 25,
        name: 'AffinityC (AFFINITY_C)',
      },
      id: 40,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 25,
        name: 'AffinityC (AFFINITY_C)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 7,
        name: 'Affinity',
      },
      id: 41,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 26,
        name: 'AFFINITY2 (AFFINITY2)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 26,
        name: 'AFFINITY2 (AFFINITY2)',
      },
      id: 42,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 26,
        name: 'AFFINITY2 (AFFINITY2)',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 7,
        name: 'Affinity',
      },
      id: 43,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '1',
        description: null,
        id: 27,
        name: 'SMART (Legacy)',
      },
      nextProduct: {
        amount: '1',
        description: null,
        id: 27,
        name: 'SMART (Legacy)',
      },
      id: 44,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 45,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 46,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 47,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 48,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 50,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 51,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 52,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 53,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 54,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 55,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 56,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 57,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 58,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 59,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 60,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 61,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 62,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 64,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 65,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 66,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 67,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 68,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 69,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 70,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 71,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 73,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 74,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      id: 75,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      id: 76,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 77,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 78,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 79,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 80,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 81,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 82,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 83,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 84,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 85,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 86,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 87,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 88,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 89,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 90,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 91,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 92,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 93,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 94,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 95,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 96,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 97,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 98,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 99,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 100,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 101,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 102,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 103,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 104,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 105,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 106,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 107,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 108,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 109,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 110,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 111,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 112,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 113,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 114,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 115,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 116,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 117,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 118,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 119,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 120,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 121,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 122,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 123,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 124,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 125,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 126,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 127,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 128,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 129,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 130,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 131,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 132,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 133,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 134,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 135,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 142,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 143,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '2500',
        description: null,
        id: 50,
        name: 'Initiation Fee (Smart)',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 145,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '2500',
        description: null,
        id: 50,
        name: 'Initiation Fee (Smart)',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 146,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 148,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 149,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 150,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 48,
        name: '10,000 Flight Credits',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 152,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 48,
        name: '10,000 Flight Credits',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 153,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 155,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 156,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 157,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 158,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 159,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 160,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 161,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 162,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 163,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 164,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 165,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 166,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 167,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 170,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 171,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 172,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 173,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 174,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 175,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 176,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 177,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 178,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 179,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 180,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 181,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 182,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 184,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 201,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 202,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 203,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 204,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 205,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 206,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 207,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 208,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 209,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 210,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 211,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 212,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 213,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 214,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 215,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 217,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 218,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      id: 219,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      id: 220,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 225,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      id: 226,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      id: 227,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      id: 228,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 229,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 230,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      id: 231,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      id: 232,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      id: 233,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      id: 234,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      id: 235,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      id: 236,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      id: 237,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      id: 238,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 239,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 240,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 241,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 243,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 244,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 245,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 246,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 247,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 248,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      id: 249,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 250,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 251,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 256,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 257,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 258,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 259,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 260,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 261,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 263,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      id: 265,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 266,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 267,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 268,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 269,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 270,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 271,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 272,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 273,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 274,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 275,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 276,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 277,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 279,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 280,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 281,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 282,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 283,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 284,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 285,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 286,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 287,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 289,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '4166.67',
        description: null,
        id: 13,
        name: 'Sophisticated 1 Month',
      },
      id: 290,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      id: 292,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 315,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      id: 317,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 318,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 320,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      id: 321,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      id: 322,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      id: 323,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 324,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 326,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 332,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 333,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 334,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 335,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 336,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 338,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 339,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 340,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 341,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 342,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 344,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 345,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 346,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 348,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 349,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 350,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 351,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 352,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 353,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 354,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 355,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 356,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 357,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 358,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 359,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 360,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 361,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 362,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 363,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 364,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 366,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      id: 368,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      id: 369,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      id: 370,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      id: 371,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 372,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 80,
        name: 'Family Group Extra Seats',
      },
      id: 373,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '495',
        description: null,
        id: 81,
        name: 'Family Group Replacement Seats',
      },
      id: 374,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 82,
        name: 'Enterprise Extra Seats',
      },
      id: 375,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 376,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      id: 380,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 383,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '2487.50',
        description: null,
        id: 84,
        name: 'Family Group 3 Months',
      },
      id: 407,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '2487.50',
        description: null,
        id: 84,
        name: 'Family Group 3 Months',
      },
      nextProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      id: 408,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 410,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 411,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 413,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 414,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 415,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 416,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 417,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 418,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 419,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 420,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 421,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 422,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 423,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 424,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 425,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 426,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 427,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 428,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 429,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 430,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 431,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 432,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 436,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 437,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 438,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 439,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 440,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 441,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 442,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 443,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 444,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 445,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 446,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 447,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 448,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 449,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 450,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 451,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 452,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 453,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 454,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 455,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 456,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 457,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 458,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 461,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 462,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 463,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 464,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 465,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 92,
        name: 'Family Group 12+ Months',
      },
      id: 496,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 497,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: null,
        id: 93,
        name: 'Membership 12+ Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 95,
        name: 'Family Group 15 Months 6 Users',
      },
      id: 498,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: null,
        id: 93,
        name: 'Membership 12+ Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 92,
        name: 'Family Group 12+ Months',
      },
      id: 499,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: null,
        id: 93,
        name: 'Membership 12+ Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 500,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 501,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 518,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 519,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 520,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 521,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 522,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 523,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 524,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 525,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 526,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 527,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 528,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 529,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 530,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 531,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 532,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 533,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 534,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 535,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 536,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 537,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 538,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 539,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 540,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 541,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 542,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 543,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 544,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 545,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 546,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 547,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 548,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 549,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 550,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 551,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 552,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 553,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 554,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 557,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 558,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 559,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 560,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 562,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 563,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 564,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 565,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 566,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 567,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 568,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 569,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 570,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 571,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 572,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 573,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 574,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 575,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 576,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 577,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 578,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 579,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 580,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 581,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 582,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 583,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 586,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 587,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 588,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 589,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 591,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 592,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 622,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 623,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 624,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 625,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 626,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 627,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 628,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 629,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 630,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 631,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 632,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 633,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 634,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 635,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 636,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 637,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 638,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 639,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 640,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 641,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 644,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 645,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 646,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 647,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 649,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 650,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 651,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 652,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 653,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 654,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 655,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 656,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 657,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 658,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 659,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 660,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 661,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 662,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 663,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 664,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 665,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 666,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 667,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 668,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 669,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 670,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 673,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 674,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 675,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 676,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 678,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 679,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 680,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 681,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 682,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 683,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 684,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 685,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 686,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 687,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 688,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 689,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 690,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 691,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 692,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 693,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 694,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 695,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 696,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 697,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 698,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 699,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 702,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 703,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 704,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 705,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8333.33',
        description: null,
        id: 77,
        name: 'Sophisticated 2 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 707,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '301.37',
        description: null,
        id: 79,
        name: 'Smart $11,000 (10 days free)',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 708,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 738,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 739,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 740,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 741,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 742,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 743,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 744,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 745,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 746,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 747,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 748,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 749,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 750,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 751,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 752,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 753,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 754,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 755,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 756,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 757,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 760,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 761,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 762,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 763,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 764,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 765,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 766,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 767,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 768,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 769,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 770,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 771,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 772,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 773,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 774,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 775,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 776,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 777,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 778,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 779,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 780,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 781,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 782,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 785,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 786,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 787,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 789,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 790,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 791,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 792,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 793,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 794,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 795,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 796,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 797,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 798,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 799,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 800,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 801,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 802,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 803,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 804,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 805,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 806,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 807,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 808,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 811,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 812,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      id: 813,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 815,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 816,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 817,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 818,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 819,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 820,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 821,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 822,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 823,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 824,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 825,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 826,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 827,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 828,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 829,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 830,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 831,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 832,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 833,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 834,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 837,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 838,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 839,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 840,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 849,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 850,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      id: 851,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 853,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      id: 854,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 855,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 860,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 90,
        name: 'Membership Flex',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 862,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 95,
        name: 'Family Group 15 Months 6 Users',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 863,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 95,
        name: 'Family Group 15 Months 6 Users',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 866,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '1950',
        description: null,
        id: 63,
        name: 'Family Slot',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 867,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 868,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 869,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 870,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 871,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 872,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 873,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 874,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 875,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 876,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 877,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 878,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 879,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 880,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 881,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 882,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 883,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 884,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 885,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 886,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 887,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 888,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 891,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 892,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 893,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 894,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 895,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 896,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 897,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 898,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 899,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 900,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 901,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 902,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 903,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 904,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 905,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 906,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 907,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 908,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 909,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 910,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 911,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 912,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 913,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 916,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 917,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 918,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 919,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 920,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 921,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 922,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 923,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 924,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 925,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 926,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 927,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 928,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 929,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 930,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 931,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 932,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 933,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 934,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 935,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 936,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 937,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 938,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 941,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 942,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 943,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 944,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 945,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 946,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 947,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 948,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 949,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 950,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 951,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 952,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 953,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 954,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 955,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 956,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 957,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 958,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 959,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 960,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 961,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 962,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 963,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 964,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 965,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 966,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 969,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 970,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '4950',
        description: null,
        id: 102,
        name: 'Membership 15 Months',
      },
      id: 971,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 972,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 973,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 974,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 975,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 976,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 977,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 978,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 979,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 980,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 981,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 982,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 983,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 984,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 985,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 986,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 987,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 988,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 989,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 990,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 991,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 994,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 995,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 103,
        name: 'Family Group 15 Months',
      },
      id: 996,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1002,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1003,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1004,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1005,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1006,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1007,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1008,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1009,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1010,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1011,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1012,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1013,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1014,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1015,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1016,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1017,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1018,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1019,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1020,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1021,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1022,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1025,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1026,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1027,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 1028,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1029,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1030,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1031,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1032,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1033,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1034,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1035,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1036,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1037,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1038,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1039,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1040,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1041,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1042,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1043,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1044,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1045,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1046,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1047,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1048,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1049,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1050,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1053,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1054,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1055,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1060,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1065,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1081,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1082,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1083,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1084,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1085,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1086,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1087,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1088,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1089,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1090,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1091,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1092,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1093,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1094,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1095,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1096,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1097,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1098,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1099,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1100,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1103,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1104,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 101,
        name: 'Starter',
      },
      id: 1105,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      id: 1106,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 1107,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1108,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1109,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1110,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1111,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1112,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1113,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1114,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1115,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1116,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1117,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1118,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1119,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1120,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1121,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1122,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1123,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1124,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1125,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1126,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1127,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1130,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1131,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1132,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1133,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1134,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1135,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1136,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1137,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1138,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1139,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1140,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1141,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1142,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1143,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1144,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1145,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1146,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1147,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1148,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1149,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1150,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1151,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1152,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1153,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1154,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1155,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1158,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1159,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1160,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1161,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1162,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1163,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1164,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1165,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '15000',
        description: null,
        id: 3,
        name: 'Smart $15,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1166,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1167,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1168,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '5999',
        description: null,
        id: 30,
        name: 'Smart $5,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1169,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '6999',
        description: null,
        id: 31,
        name: 'Smart $6,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1170,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7999',
        description: null,
        id: 32,
        name: 'Smart $7,999',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1171,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '8499',
        description: null,
        id: 33,
        name: 'Smart $8,499',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1172,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9000',
        description: null,
        id: 35,
        name: 'Smart $9,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1173,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9675',
        description: null,
        id: 36,
        name: 'Smart $9,675',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1174,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '10000',
        description: null,
        id: 37,
        name: 'Smart $10,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1175,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11000',
        description: null,
        id: 38,
        name: 'Smart $11,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1176,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '11500',
        description: null,
        id: 39,
        name: 'Smart $11,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1177,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '12000',
        description: null,
        id: 40,
        name: 'Smart $12,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1178,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13000',
        description: null,
        id: 41,
        name: 'Smart $13,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1179,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '13500',
        description: null,
        id: 42,
        name: 'Smart $13,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1180,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '14000',
        description: null,
        id: 43,
        name: 'Smart $14,000',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1181,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1182,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1183,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '3750',
        description: null,
        id: 55,
        name: 'Smart 3 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1186,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1187,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '7500',
        description: null,
        id: 58,
        name: 'Smart $7,500',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1188,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1189,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1190,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 110,
        name: 'Signature Group Flex',
      },
      id: 1191,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 104,
        name: 'Signature 15 Months',
      },
      id: 1194,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1195,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1196,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 89,
        name: 'Family Group Flex',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1197,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1198,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1199,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '9950',
        description: null,
        id: 64,
        name: 'Family Group 12 Months',
      },
      nextProduct: {
        amount: '4950',
        description: 'Membership 12 Months $4,950',
        id: 59,
        name: 'Individual Membership',
      },
      id: 1200,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '97500',
        description: null,
        id: 1,
        name: 'Sophisticated 36 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1201,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '50000',
        description: null,
        id: 2,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1202,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '40000',
        description: null,
        id: 44,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1203,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '45000',
        description: null,
        id: 45,
        name: 'Sophisticated 12 Months',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1204,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Sophisticated memberships from Console',
        id: 56,
        name: 'Sophisticated Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1205,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: null,
        id: 14,
        name: 'Access 12 Months',
      },
      nextProduct: {
        amount: '1995',
        description: null,
        id: 105,
        name: 'Founding Member Promo',
      },
      id: 1206,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      id: 1239,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      nextProduct: {
        amount: '950',
        description: null,
        id: 99,
        name: 'Signature Group Extra Seats',
      },
      id: 1240,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '25000',
        description: null,
        id: 98,
        name: 'Signature Group',
      },
      nextProduct: {
        amount: '0',
        description: null,
        id: 109,
        name: 'Signature Group Flex Extra Slots',
      },
      id: 1241,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '50000',
        description: null,
        id: 151,
        name: 'Select Monthly (Program Deposit)',
      },
      id: 1242,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '5000',
        description: null,
        id: 153,
        name: 'Select Annual',
      },
      id: 1243,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '100000',
        description: null,
        id: 161,
        name: 'Signature* Monthly (Program Deposit)',
      },
      id: 1244,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '15000',
        description: null,
        id: 163,
        name: 'Signature* Annual',
      },
      id: 1245,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '100000',
        description: null,
        id: 167,
        name: 'Elite Monthly (Program Deposit)',
      },
      id: 1246,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: null,
      nextProduct: {
        amount: '25000',
        description: null,
        id: 169,
        name: 'Elite Annual',
      },
      id: 1247,
      salesAction: {
        description: null,
        id: 1,
        name: 'New',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 167,
        name: 'Elite Monthly (Program Deposit)',
      },
      id: 1248,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '19950',
        description: 'Enterprise Group 12 Months $19,950',
        id: 67,
        name: 'Enterprise Group 12 Months',
      },
      nextProduct: {
        amount: '5000',
        description: null,
        id: 153,
        name: 'Select Annual',
      },
      id: 1249,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 151,
        name: 'Select Monthly (Program Deposit)',
      },
      id: 1250,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '5000',
        description: null,
        id: 153,
        name: 'Select Annual',
      },
      id: 1251,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 161,
        name: 'Signature* Monthly (Program Deposit)',
      },
      id: 1252,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 163,
        name: 'Signature* Annual',
      },
      id: 1253,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 167,
        name: 'Elite Monthly (Program Deposit)',
      },
      id: 1254,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 169,
        name: 'Elite Annual',
      },
      id: 1255,
      salesAction: {
        description: null,
        id: 4,
        name: 'Extend',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 151,
        name: 'Select Monthly (Program Deposit)',
      },
      id: 1256,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '5000',
        description: null,
        id: 153,
        name: 'Select Annual',
      },
      id: 1257,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 161,
        name: 'Signature* Monthly (Program Deposit)',
      },
      id: 1258,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 163,
        name: 'Signature* Annual',
      },
      id: 1259,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 167,
        name: 'Elite Monthly (Program Deposit)',
      },
      id: 1260,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 169,
        name: 'Elite Annual',
      },
      id: 1261,
      salesAction: {
        description: null,
        id: 2,
        name: 'Renew',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '50000',
        description: null,
        id: 151,
        name: 'Select Monthly (Program Deposit)',
      },
      id: 1262,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '5000',
        description: null,
        id: 153,
        name: 'Select Annual',
      },
      id: 1263,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 161,
        name: 'Signature* Monthly (Program Deposit)',
      },
      id: 1264,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '15000',
        description: null,
        id: 163,
        name: 'Signature* Annual',
      },
      id: 1265,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '100000',
        description: null,
        id: 167,
        name: 'Elite Monthly (Program Deposit)',
      },
      id: 1266,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
    {
      currentProduct: {
        amount: '0',
        description: 'To renew Smart memberships from Console',
        id: 29,
        name: 'Smart Renewals From Console',
      },
      nextProduct: {
        amount: '25000',
        description: null,
        id: 169,
        name: 'Elite Annual',
      },
      id: 1267,
      salesAction: {
        description: null,
        id: 3,
        name: 'Convert',
      },
    },
  ],
};
