import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/client.actions';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const clientFeatureKey = 'client';

export interface State {
  client: models.Client;
  isLoaded: boolean;
  isLoading: boolean;
  isOrdersLoaded: boolean;
  isOrdersLoading: boolean;
  terminationData: models.TerminationData;
  terminationDataLoading: boolean;
  terminationDataLoaded: boolean;
  ordersPerPage: number;
  ordersCurrentPage: number;
  ordersTotalItems: number;
  isSearchHistoryLoaded: boolean;
  isSearchHistoryLoading: boolean;
  searchHistoryPerPage: number;
  searchHistoryCurrentPage: number;
  searchHistoryTotalItems: number;
}

const initialState: State = {
  client: new models.Client(),
  isLoaded: false,
  isLoading: false,
  isOrdersLoaded: false,
  isOrdersLoading: false,
  terminationData: null,
  terminationDataLoading: false,
  terminationDataLoaded: false,
  ordersPerPage: 10,
  ordersCurrentPage: 1,
  ordersTotalItems: 0,
  isSearchHistoryLoaded: false,
  isSearchHistoryLoading: false,
  searchHistoryPerPage: 10,
  searchHistoryCurrentPage: 1,
  searchHistoryTotalItems: 0,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (): State => ({
      ...initialState,
      isLoading: true,
    })
  ),
  on(actions.loadSuccess, (state, { payload }): State => {
    const client = _.cloneDeep(payload);
    const orders = _.cloneDeep(state.client.orders);
    if (orders.length) {
      client.orders = orders;
    }

    const searchHistory = _.cloneDeep(state.client.searchHistory);
    if (searchHistory.length) {
      client.searchHistory = searchHistory;
    }

    return {
      ...state,
      client,
      isLoaded: true,
      isLoading: false,
    };
  }),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      client: null,
      isLoaded: true,
      isLoading: false,
    })
  ),

  on(
    actions.loadOrders,
    (state, { payload: { itemsPerPage } }): State => ({
      ...state,
      ordersPerPage: itemsPerPage,
      isOrdersLoading: true,
      isOrdersLoaded: false,
    })
  ),
  on(actions.loadOrdersSuccess, (state, { payload: { orders, ordersPage, totalItems } }): State => {
    const client = _.cloneDeep(state.client);
    if (state.client) {
      client.orders = orders;
    }

    return {
      ...state,
      client,
      ordersCurrentPage: ordersPage,
      ordersTotalItems: totalItems,
      ordersPerPage: state.ordersPerPage,
      isOrdersLoading: false,
      isOrdersLoaded: true,
    };
  }),
  on(
    actions.loadOrdersFail,
    (state): State => ({
      ...state,
      isOrdersLoading: false,
      isOrdersLoaded: false,
    })
  ),

  on(
    actions.resumeMembership,
    (state): State => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    })
  ),
  on(actions.resumeMembershipSuccess, (state): State => {
    const client = _.cloneDeep(state.client);
    client.currentMembership.clientServiceStatus = models.ClientServiceStatusEnum.Active;

    return {
      ...state,
      client,
      isLoading: false,
      isLoaded: true,
    };
  }),
  on(
    actions.resumeMembershipFail,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoaded: true,
    })
  ),

  on(
    actions.suspendMembership,
    (state): State => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    })
  ),
  on(actions.suspendMembershipSuccess, (state): State => {
    const client = _.cloneDeep(state.client);
    client.currentMembership.clientServiceStatus = models.ClientServiceStatusEnum.Suspended;

    return {
      ...state,
      client,
      isLoading: false,
      isLoaded: true,
    };
  }),
  on(
    actions.suspendMembershipFail,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoaded: true,
    })
  ),

  on(
    actions.reactivateClientService,
    (state): State => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    })
  ),
  on(actions.reactivateClientServiceSuccess, (state, { payload }): State => {
    const client = _.cloneDeep(payload.entity);

    return {
      ...state,
      client,
      isLoading: false,
      isLoaded: true,
    };
  }),
  on(
    actions.reactivateClientServiceFail,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoaded: false,
    })
  ),

  on(
    actions.terminateClientService,
    (state): State => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    })
  ),
  on(actions.terminateClientServiceSuccess, (state, { payload }): State => {
    const client = _.cloneDeep(payload.entity);

    return {
      ...state,
      client,
      isLoading: false,
      isLoaded: true,
    };
  }),
  on(
    actions.terminateClientServiceFail,
    (state): State => ({
      ...state,
      isLoading: false,
      isLoaded: false,
    })
  ),

  on(
    actions.loadTerminationData,
    (state): State => ({
      ...state,
      terminationDataLoading: true,
      terminationDataLoaded: false,
    })
  ),
  on(actions.loadTerminationDataSuccess, (state, { payload }): State => {
    const terminationData = _.cloneDeep(payload.entity);
    return {
      ...state,
      terminationData,
      terminationDataLoading: false,
      terminationDataLoaded: true,
    };
  }),
  on(
    actions.loadTerminationDataFail,
    (state): State => ({
      ...state,
      terminationDataLoading: false,
      terminationDataLoaded: true,
    })
  ),

  on(
    actions.terminateSfdcMembership,
    (state): State => ({
      ...state,
      terminationDataLoading: true,
      terminationDataLoaded: false,
    })
  ),
  on(
    actions.terminateSfdcMembershipSuccess,
    (state): State => ({
      ...state,
      terminationDataLoading: false,
      terminationDataLoaded: true,
    })
  ),
  on(
    actions.terminateSfdcMembershipFail,
    (state): State => ({
      ...state,
      terminationDataLoading: false,
      terminationDataLoaded: true,
    })
  ),
  on(
    actions.loadSearchHistory,
    (state, { payload: { itemsPerPage } }): State => ({
      ...state,
      searchHistoryPerPage: itemsPerPage,
      isSearchHistoryLoading: true,
      isSearchHistoryLoaded: false,
    })
  ),
  on(
    actions.loadSearchHistorySuccess, 
    (state, { payload: { searchHistory, searchPage, totalItems } }): State => {
      const client: models.Client = _.cloneDeep(state.client);
      if (state.client) {
        client.searchHistory = searchHistory;
      }

      return {
        ...state,
        client,
        searchHistoryCurrentPage: searchPage,
        searchHistoryTotalItems: totalItems,
        searchHistoryPerPage: state.searchHistoryPerPage,
        isSearchHistoryLoading: false,
        isSearchHistoryLoaded: true,
      };
  }),
  on(
    actions.loadSearchHistoryFail,
    (state): State => ({
      ...state,
      isSearchHistoryLoading: false,
      isSearchHistoryLoaded: false,
    })
  ),
);
