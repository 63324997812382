import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as sharedLayoutActions from '../../shared/actions/layout.actions';
import * as sharedSelectors from '../selectors';
import { isAllowedDomain } from '../../app.module';
import { User } from '../models';

@Injectable()
export class ConfigServiceInitializerResolver implements Resolve<Observable<User>> {
  constructor(private store$: Store<any>) {}
  resolve(): Observable<User> {
    if (!isAllowedDomain()) {
      this.store$.dispatch(
        sharedLayoutActions.showError({
          payload:
            'Sorry. Seems wrong App Configuration has been deployed. Please contact development team.',
        })
      );
    }
    return this.store$.pipe(
      select(sharedSelectors.getUserUser),
      filter((user) => user !== null),
      first()
    );
  }
}
