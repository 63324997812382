import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IAuth0ErrorNotificationService {
  sendErrorNotification(request: SendErrorNotificationRequest): Observable<SendErrorNotificationResponse>;
}

export const SERVICE_TOKEN = new InjectionToken('Core.IAuth0ErrorNotificationService');

export class SendErrorNotificationRequest {
  failedEndpoint: string;
}

export class SendErrorNotificationResponse {
}
