export class DelayCode {
  code: number;
  description: string;
  id: number;
  type: DelayCodeType;
}

export class DelayCodeType {
  active: boolean;
  description: string;
  id: number;
}
