import * as actions from '../actions/rating';
import * as models from '../../domain/models';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  entity: models.Rating;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  entity: new models.Rating(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        isLoaded: true,
        isLoading: false,
        entity: action.payload,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getEntity = (state: State) => state.entity;
