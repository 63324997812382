// import { PriceComponent } from './price-component';
// import { ProductFeature } from './product-feature';
import { ProductType } from './product-type';
// import { MembershipProductDependency } from './membership-product-dependency';

export class Product {
  id: number = null;
  // productTypeId: number = null;
  // parentProductId: number = null;
  name: string = null;
  // description: string = null;
  // amount: number = null;
  // priceComponents: Array<PriceComponent> = [];
  // productFeatures: Array<ProductFeature> = [];
  productType: ProductType = null;
  // productDependencies: Array<MembershipProductDependency> = [];
  // isRefundable = false;
}
