import * as models from '../../../../domain/models';
import * as dto from '../contracts';
import { PaymentTransactionWiredTransferDtoMapper } from './payment-transaction-wired-transfer-dto-mapper';
import { PaymentTransactionCreditCardDtoMapper } from './payment-transaction-credit-card-dto-mapper';
import { PaymentTransactionAchDtoMapper } from './payment-transaction-ach-dto-mapper';
import { PaymentTransactionCheckDtoMapper } from './payment-transaction-check-dto-mapper';

export class PaymentTransactionMapper {
  toDto(pt: models.PaymentTransaction): dto.PaymentTransactionDto {
    switch (pt.paymentTransactionType) {
      case models.PaymentTransactionTypeEnum.ACH:
        return new PaymentTransactionAchDtoMapper().toDto(pt as models.PaymentTransactionAch);

      case models.PaymentTransactionTypeEnum.Check:
        return new PaymentTransactionCheckDtoMapper().toDto(pt as models.PaymentTransactionCheck);

      case models.PaymentTransactionTypeEnum.WireTransfer:
        return new PaymentTransactionWiredTransferDtoMapper().toDto(
          pt as models.PaymentTransactionWiredTransfer
        );

      case models.PaymentTransactionTypeEnum.CreditCard:
        return new PaymentTransactionCreditCardDtoMapper().toDto(
          pt as models.PaymentTransactionCreditCard
        );

      default:
        throw new Error('Not Implemented');
    }
  }
}
