import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/leg-view.actions';

export const legViewFeatureKey = 'legView';

export interface State {
  legView: boolean;
}

const initialState: State = {
  legView: true,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.changeLegView,
    (state): State => ({
      ...state,
      legView: !state.legView,
    })
  )
);
