export const response = {
  "log": {
    "logId": "8c58427a-0a45-4abc-b8c5-07281b690320",
    "user": {
      "id": 2,
      "firstName": "Oleg",
      "lastName": "Solovey"
    },
    "createdTime": "2022-10-10T16:07:56",
    "input": null
  },
  "data": [
    {
      "pax": 7,
      "actype": "LITE",
      "price": 25664.88,
      "reject": "",
      "segments": [
        {
          "depart": "KTEB",
          "arrive": "KFLL",
          "time": "2022-10-27 12:00:00",
          "actype": "LITE",
          "pax": 7,
          "opportunityPax": null,
          "eft": 2.6,
          "price": 23750,
          "opportunityPrice": null,
          "debug": {
            "eftType": "calc",
            "logic": "state to state",
            "rate": null
          },
          "priceInfo": {
            "base": 16000,
            "noodleToggle": 0,
            "departAP": -1000,
            "arriveAP": -250,
            "surcharges": [],
            "calendarMsrp": "MEGAP:9000",
            "msrp": "MEGAP",
            "msrpValue": 9000
          },
          "taxFees": [
            {
              "priceComponent": {
                "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
                "name": "Tax",
                "unitBasePriceType": "VARIABLE",
                "unitQuantityType": null,
                "unitBasePrice": null,
                "unitPercentage": 7.5
              },
              "unitBasePrice": 23750,
              "unitQuantity": null,
              "unitPercentage": 7.5,
              "amount": 1781.25,
              "currency": "USD"
            },
            {
              "priceComponent": {
                "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
                "name": "Segment Fee",
                "unitBasePriceType": "STATIC",
                "unitQuantityType": "VARIABLE",
                "unitBasePrice": 4.5,
                "unitPercentage": null
              },
              "unitBasePrice": 4.5,
              "unitQuantity": 7,
              "unitPercentage": null,
              "amount": 31.50,
              "currency": "USD"
            }
          ],
          "suppressTaxFee": null,
          "legType": null
        }
      ],
      "taxes": null,
      "subtotal": 23845,
      "serviceFee": 95,
      "nonMemberFee": null,
      "segmentFee": 31.50,
      "fetTax": 1788.38,
      "fetTaxPercent": 7.5,
      "roundTripDiscount": null,
      "lastDayOfIATAFuel": null,
      "pricingSupportType": "DYNAMIC",
      "bestPrice": false,
      "dnq": true,
      "guaranteedAvailability": true
    },
    {
      "pax": 8,
      "actype": "SMID",
      "price": 42348.01,
      "reject": "",
      "segments": [
        {
          "depart": "KTEB",
          "arrive": "KFLL",
          "time": "2022-10-27 12:00:00",
          "actype": "SMID",
          "pax": 8,
          "opportunityPax": null,
          "eft": 2.46,
          "price": 39065,
          "opportunityPrice": null,
          "debug": {
            "eftType": "table",
            "logic": "one way eft",
            "rate": 9799
          },
          "priceInfo": {
            "base": 26065,
            "noodleToggle": 0,
            "departAP": -500,
            "arriveAP": -500,
            "surcharges": [],
            "calendarMsrp": "MEGAP:14000",
            "msrp": "MEGAP",
            "msrpValue": 14000
          },
          "taxFees": [
            {
              "priceComponent": {
                "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
                "name": "Tax",
                "unitBasePriceType": "VARIABLE",
                "unitQuantityType": null,
                "unitBasePrice": null,
                "unitPercentage": 7.5
              },
              "unitBasePrice": 39065,
              "unitQuantity": null,
              "unitPercentage": 7.5,
              "amount": 2929.88,
              "currency": "USD"
            },
            {
              "priceComponent": {
                "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
                "name": "Segment Fee",
                "unitBasePriceType": "STATIC",
                "unitQuantityType": "VARIABLE",
                "unitBasePrice": 4.5,
                "unitPercentage": null
              },
              "unitBasePrice": 4.5,
              "unitQuantity": 8,
              "unitPercentage": null,
              "amount": 36.00,
              "currency": "USD"
            }
          ],
          "suppressTaxFee": null,
          "legType": null
        }
      ],
      "taxes": null,
      "subtotal": 39360,
      "serviceFee": 295,
      "nonMemberFee": null,
      "segmentFee": 36.00,
      "fetTax": 2952.01,
      "fetTaxPercent": 7.5,
      "roundTripDiscount": null,
      "lastDayOfIATAFuel": null,
      "pricingSupportType": "DYNAMIC",
      "bestPrice": false,
      "dnq": true,
      "guaranteedAvailability": true
    },
    {
      "pax": 8,
      "actype": "Challenger 300/350",
      "price": 54150.44,
      "reject": null,
      "segments": [
        {
          "depart": "KTEB",
          "arrive": "KFLL",
          "time": "2022-10-27 12:00:00",
          "actype": "Challenger 300/350",
          "pax": 8,
          "opportunityPax": null,
          "eft": 2.83,
          "price": 50172.5,
          "opportunityPrice": null,
          "debug": null,
          "priceInfo": {
            "base": 27625.0,
            "breakdowns": {
              "4%_XO_Margin": 1937.0,
              "payment_admin": 110.0,
              "vip_lounge": 200.0,
              "wifi": 708.33,
              "dining": 200.0,
              "co2": 48.17,
              "destination_repo_price": 1441.0,
              "customer_service": 100.0,
              "msrp_adjustment_(MEGAP)": 14000.0,
              "fuel_surcharge": 2833.0,
              "landing": 770.0,
              "de_icing": 200.0
            },
            "surcharges": []
          },
          "taxFees": [
            {
              "priceComponent": {
                "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
                "name": "Tax",
                "unitBasePriceType": "VARIABLE",
                "unitQuantityType": null,
                "unitBasePrice": null,
                "unitPercentage": 7.5
              },
              "unitBasePrice": 50172.5,
              "unitQuantity": null,
              "unitPercentage": 7.5,
              "amount": 3762.94,
              "currency": "USD"
            },
            {
              "priceComponent": {
                "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
                "name": "Segment Fee",
                "unitBasePriceType": "STATIC",
                "unitQuantityType": "VARIABLE",
                "unitBasePrice": 4.5,
                "unitPercentage": null
              },
              "unitBasePrice": 4.5,
              "unitQuantity": 8,
              "unitPercentage": null,
              "amount": 36.00,
              "currency": "USD"
            }
          ],
          "suppressTaxFee": null,
          "legType": "regular"
        }
      ],
      "taxes": [
        {
          "amount": 179.0,
          "name": "On Demand ETS charge",
          "perPax": false
        }
      ],
      "subtotal": 50351.5,
      "serviceFee": 0,
      "nonMemberFee": null,
      "segmentFee": 36.00,
      "fetTax": 3762.94,
      "fetTaxPercent": 7.5,
      "roundTripDiscount": null,
      "lastDayOfIATAFuel": null,
      "pricingSupportType": "DYNAMIC",
      "bestPrice": false,
      "dnq": true,
      "guaranteedAvailability": true
    },
    {
      "pax": 13,
      "actype": "G-IV SP/G450",
      "price": 83226.79,
      "reject": null,
      "segments": [
        {
          "depart": "KTEB",
          "arrive": "KFLL",
          "time": "2022-10-27 12:00:00",
          "actype": "G-IV SP/G450",
          "pax": 13,
          "opportunityPax": null,
          "eft": 2.67,
          "price": 77199.34,
          "opportunityPrice": null,
          "debug": null,
          "priceInfo": {
            "base": 40666.67,
            "breakdowns": {
              "4%_XO_Margin": 2976.0,
              "payment_admin": 110.0,
              "vip_lounge": 200.0,
              "wifi": 666.67,
              "dining": 200.0,
              "co2": 45.33,
              "destination_repo_price": 1601.67,
              "customer_service": 100.0,
              "msrp_adjustment_(MEGAP)": 20000.0,
              "fuel_surcharge": 5333.0,
              "other_location_fee": 4200.0,
              "landing": 900.0,
              "de_icing": 200.0
            },
            "surcharges": []
          },
          "taxFees": [
            {
              "priceComponent": {
                "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
                "name": "Tax",
                "unitBasePriceType": "VARIABLE",
                "unitQuantityType": null,
                "unitBasePrice": null,
                "unitPercentage": 7.5
              },
              "unitBasePrice": 77199.34,
              "unitQuantity": null,
              "unitPercentage": 7.5,
              "amount": 5789.95,
              "currency": "USD"
            },
            {
              "priceComponent": {
                "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
                "name": "Segment Fee",
                "unitBasePriceType": "STATIC",
                "unitQuantityType": "VARIABLE",
                "unitBasePrice": 4.5,
                "unitPercentage": null
              },
              "unitBasePrice": 4.5,
              "unitQuantity": 13,
              "unitPercentage": null,
              "amount": 58.50,
              "currency": "USD"
            }
          ],
          "suppressTaxFee": null,
          "legType": "regular"
        }
      ],
      "taxes": [
        {
          "amount": 179.0,
          "name": "On Demand ETS charge",
          "perPax": false
        }
      ],
      "subtotal": 77378.34,
      "serviceFee": 0,
      "nonMemberFee": null,
      "segmentFee": 58.50,
      "fetTax": 5789.95,
      "fetTaxPercent": 7.5,
      "roundTripDiscount": null,
      "lastDayOfIATAFuel": null,
      "pricingSupportType": "DYNAMIC",
      "bestPrice": false,
      "dnq": true,
      "guaranteedAvailability": true
    }
  ]
};
