// HAS TO BE UPDATED
export enum CategoryEnum {
  PISTON = 1,
  TURBOPROP = 2,
  VLJ = 3,
  LIGHT = 4,
  MID = 5,
  SUPERMID = 6,
  HEAVY = 7,
  ULTRA = 8,
  AIRLINER = 9,
  VIP = 10,
}
