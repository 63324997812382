import { createReducer, on } from "@ngrx/store";

import * as models from '../../domain/models';
import * as actions from '../actions/vendor-collection.actions';

export const vendorCollectionFeatureKey = 'vendorCollection';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Vendor>;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: new Array<models.Vendor>(),
};

export const reducer = createReducer(
  initialState,

  on(actions.load, (state): State => (  {
    ...state,
    isLoaded: false,
    isLoading: true,
  })),

  on(
    actions.loadSuccess,
    (_, { payload }): State => ({
      isLoaded: true,
      isLoading: false,
      items: payload ? payload : new Array<models.Vendor>(),
    })
  )
);
