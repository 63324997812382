import { Operator } from './operator';

export class ChangeLogItem {
  modificationDate: Date;
  email: string;
  type: string;
  action: string;
  changes: Array<any>;
  displayedType: string;
  currentOperator: Operator;
}
