import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/group-membership.actions';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const groupMembershipFeatureKey = 'groupMembership';

export interface State {
  client: models.Client;
  groupMembership: models.GroupMembership;
  loaded: boolean;
  loading: boolean;
  submitting: boolean;
  untouchedGroupMembership: models.GroupMembership;
}

const initialState: State = {
  client: null,
  groupMembership: null,
  loaded: false,
  loading: false,
  submitting: false,
  untouchedGroupMembership: null,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (): State => ({
      ...initialState,
      loading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      client: payload.client,
      groupMembership: payload,
      loaded: true,
      loading: false,
      untouchedGroupMembership: payload,
      submitting: false,
    })
  ),

  on(
    actions.submitGroupMembership,
    (state): State => ({
      ...state,
      submitting: true,
    })
  ),
  on(
    actions.submitGroupMembershipSuccess,
    (state, { payload }): State => ({
      ...state,
      submitting: false,
      groupMembership: payload,
      untouchedGroupMembership: payload,
    })
  ),
  on(
    actions.submitGroupMembershipFail,
    (state): State => ({
      ...state,
      submitting: false,
    })
  ),

  on(
    actions.undoChanges,
    (state): State => ({
      ...state,
      groupMembership: state.untouchedGroupMembership,
    })
  ),

  on(
    actions.updateGroupMembership,
    (state, { payload }): State => ({
      ...state,
      groupMembership: payload,
    })
  )
);
