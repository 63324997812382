export enum OfferBidStatusEnum {
  NO_OFFER = 'No Offer',
  OPERATOR_OFFER = 'Operator Offer',
  STAFF_OFFER = 'Staff Offer',
  STAFF_CANCELLED = 'Staff Cancelled',
  OPERATOR_CANCELLED = 'Operator Cancelled',
  OPERATOR_REVOKED = 'Operator Revoked',
  STAFF_REVOKED = 'Staff Revoked',
  CLIENT_CANCELLED = 'Client Cancelled',
  OPERATOR_EDITED_OFFER = 'Operator Edited Offer',
  STAFF_EDITED_OFFER = 'Staff Edited Offer',
  DEFERRED = 'Deferred',
  TAKEN_ON_FLEET = 'Taken On Fleet',
}
