import { InjectionToken } from '@angular/core';
import * as models from '../../domain/models';
// TODO: This is wrong
import * as viewModels from '../../view/view-models';
import { Observable } from 'rxjs';

export interface IOrderService {
  cancelOrder(request: CancelOrderRequest): Observable<CancelOrderResponse>;

  reinstateOrder(request: ReinstateOrderRequest): Observable<ReinstateOrderResponse>;

  createOrder(request: CreateOrderRequest): Observable<CreateOrderResponse>;

  getOrder(request: GetOrderRequest): Observable<GetOrderResponse>;

  markPaid(request: MarkPaidRequest): Observable<MarkPaidResponse>;

  arPaymentConfirm(request: MarkPaidRequest): Observable<MarkPaidResponse>;

  orderList(request: OrderListRequest): Observable<OrderListResponse>;

  downloadReport(): Observable<DownloadReportResponse>;

  changeOrderPaymentMethod(
    request: ChangeOrderPaymentMethodRequest
  ): Observable<ChangeOrderPaymentMethodResponse>;

  reGenerateDocument(request: ReGenerateRequest): Observable<ReGenerateResponse>;

  getDocumentsByOrder(request: GetDocumentsByOrderRequest): Observable<GetDocumentsByOrderResponse>;

  isProformaInvoice(flightRequestId: number): Observable<ProformaInvoiceResponse>;

  isAmendChargesRestricted(userId: number): Observable<AmendChargesPermissionResponse>;

  // orderStatusList(request: OrderStatusListRequest): Observable<OrderStatusListResponse>;

  createSfdcOpportunity(
    request: CreateSfdcOpportunityRequest
  ): Observable<CreateSfdcOpportunityResponse>;
}

export const ORDER_SERVICE_TOKEN = new InjectionToken('Order.IOrderService');

export class CreateOrderRequest {
  order: models.Order;
}

export class CreateOrderResponse {
  orderId: number;
  invoice: models.Invoice;
}

export class CancelOrderRequest {
  orderId: number;
  chargeableCancellation?: boolean;
  cancellationFee?: number;
  cancellationNotes?: string;
}

export class CancelOrderResponse {
  orderId: number;
  orderStatusId: number;
  orderStatusName: string;
  message: string;
}

export class ReinstateOrderRequest {
  orderId: number;
}

export class ReinstateOrderResponse {
  orderId: number;
  orderStatusId: number;
  orderStatusName: string;
  message: string;
}

export class GetOrderRequest {
  orderId: number;
}

export class GetOrderResponse {
  entity: models.Order;
}

export class MarkPaidRequest {
  order: models.Order;
  paymentTransaction: models.PaymentTransaction;
}

export class MarkPaidResponse {
  entity: models.Order;
}

export class OrderListRequest {
  offset: number;
  limit: number;
  orderId: number;
  invoiceId: number;
  sapExportStatusIds: Array<number>;
  accountId: string;
  requestId: number;
  clientId: number;
  clientName: string;
  clientNameId?: string;
  orderStatusIds: Array<number>;
  invoiceTotal: number;
  filterByCurrentUser: boolean;
  filterByUnconfirmedPayments: boolean;
  paymentType: string;
  startDate: string;
  endDate: string;
}

export class OrderListResponse {
  orders: Array<viewModels.OrderVM> = [];
  count: number;
  accountingReportAcl: Array<number>;
}

export class DownloadReportResponse {}

export class ChangeOrderPaymentMethodRequest {
  id: number;
  paymentOptions: models.OrderPaymentOptions;
}

export class ChangeOrderPaymentMethodResponse {}

export class ReGenerateRequest {
  invoiceId: number;
}

export class ReGenerateResponse {
  documentGenerationInProgress: boolean;
  showGeneratedDocumentsPanel: boolean;
  entities: Array<models.SalesGeneratedDocument>;
}

export class GetDocumentsByOrderRequest {
  orderId: number;
}

export class GetDocumentsByOrderResponse {
  documentGenerationInProgress: boolean;
  showGeneratedDocumentsPanel: boolean;
  entities: Array<models.SalesGeneratedDocument>;
}

export class ProformaInvoiceResponse {
  code: number;
  message: string;
  data: IsProformaInvoiceResponse;
}

class OrderData {
  isProformaInvoice: boolean;
  charterSalesOpportunityId: string;
}

export class IsProformaInvoiceResponse {
  order_id: number;
  order_data: OrderData;
  has_finalized_legs: boolean;
  finalized_legs: models.FinalizedLeg[];
}

export class AmendChargesPermissionResponse {
  code: number;
  message: string;
  data: ExclusiveAccess;
}

export class ExclusiveAccess {
  exclusive_access_id: number;
  restricted_function_id: number;
  user_id_granted: number;
  active: boolean;
  user_id_denied: number;
}

export class CreateSfdcOpportunityRequest {
  orderId: number;
}

export class CreateSfdcOpportunityResponse {
  sfdcOpportunityId: string;
}
