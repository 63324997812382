import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'snakeToTitleCase' })
export class SnakeCaseToTitlePipe implements PipeTransform {
  transform(value: string): string {
    const t = value.split('_').join(' ')
    let words: RegExp = /\b(?!(HIGH))\w+/g;
    let newVal = t.replace(words, (match) => {
      return match.replace(/^\w/, (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
    })
    return newVal.charAt(0).toUpperCase() + newVal.substr(1);
  }
}
