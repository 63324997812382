import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[YM Route-Group-Collection] Load';
export const LOAD_SUCCESS = '[YM Route-Group-Collection] Load Success';
export const LOAD_FAIL = '[YM Route-Group-Collection] Load Fail';

export class LoadRouteGroupCollectionAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessRouteGroupCollectionPayload {
  entities: Array<models.RouteGroup>;
}

export class LoadSuccessRouteGroupCollectionAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessRouteGroupCollectionPayload) {}
}

export class LoadFailRouteGroupCollectionPayload {
  entity: any;
}

export class LoadFailRouteGroupCollectionAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload: LoadFailRouteGroupCollectionPayload) {}
}

export type Actions =
  | LoadRouteGroupCollectionAction
  | LoadSuccessRouteGroupCollectionAction
  | LoadFailRouteGroupCollectionAction;
