import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import moment from 'moment';
import * as _ from 'lodash';

import * as contracts from '../../../domain/service-contracts/price-calculator';
import * as mappers from '../mapper';
import * as models from "../../../domain/models";
import { response } from './get-price-response';
import { response as priceElac } from './get-price-elac-response';
import { response as calculationResponse } from './get-calculation-response';
import { response as peakDaysResponse } from './get-peak-days-response';
import { response as pricingMetaResponse } from './get-pricing-meta-reponse';

@Injectable()
export class PriceCalculatorMockService implements contracts.IPriceCalculator {
  getPrice(
    request: contracts.GetPriceCalculatorRequest
  ): Observable<contracts.GetPriceCalculatorResponse> {
    const { data, log } = request.offer.membershipType === 'ELAC' ? priceElac : response;
    const r = new contracts.GetPriceCalculatorResponse();
    r.log = mappers.PriceCalculatorMapper.parseLog(log);
    r.items = data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));
    return of(r).pipe(delay(1000));
  }

  updatePrice(
    request: contracts.UpdatePriceCalculatorRequest
  ): Observable<contracts.GetPriceCalculatorResponse> {
    const { data, log } = response;
    const r = new contracts.GetPriceCalculatorResponse();
    r.log = mappers.PriceCalculatorMapper.parseLog(log);
    r.items = data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));
    return of(r).pipe(delay(1000));
  }

  getCalculation(request: contracts.GetCalculationRequest): Observable<contracts.GetPriceCalculatorResponse> {
    const { data, log } = calculationResponse;
    const r = new contracts.GetPriceCalculatorResponse();
    r.log = mappers.PriceCalculatorMapper.parseLog(log);
    r.items = data.map((item) => mappers.PriceCalculatorMapper.parsePrice(item));
    return of(r).pipe(delay(1000));
  }

  getPeakDays(): Observable<contracts.GetPeakDaysResponse> {
    const { peakDays } = peakDaysResponse;
    const r = new contracts.GetPeakDaysResponse();
    r.items = new models.PeakDays();
    for(const peakDayArr in peakDays){
      r.items[peakDayArr.toLowerCase()] =  peakDays[peakDayArr].map((item) => moment(item).toDate());
      }
    r.items.none = _.cloneDeep(r.items.select);

    return of(r).pipe(delay(1000));
  }

  getPricingMeta(): Observable<contracts.GetPricingMetaResponse> {
    return of({ item: mappers.PriceCalculatorMapper.parsePricingMeta(pricingMetaResponse) });
  }
}
