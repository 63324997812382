import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as actions from '../actions/user.actions';
import * as selectors from '../selectors';
import * as models from '../models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private store$: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(select(selectors.getUserUserViewPermissions)).pipe(
      map((viewPermissions: models.Permissions) => {
        if (viewPermissions === null) {
          this.store$.dispatch(actions.accessDenied({ payload: true }));
          return false;
        }

        if (_.get(route, 'data.viewPermission', false)) {
          const perm = route.data.viewPermission;

          if (!_.get(viewPermissions, perm, false)) {
            // this.globalErrorHandler.handleError('You do not have permission');
            this.store$.dispatch(actions.accessDenied({ payload: true }));
            return false;
          }
        }

        this.store$.dispatch(actions.accessDenied({ payload: false }));
        return true;
      })
    );
  }
}
