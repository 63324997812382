export class AircraftTail {
  id: number;
  aircraftTailNumber: string;
  offFleetOperatorIds?: string[];
  maxPax: number;
  operatorName: string;
  categoryName: string;
  modelName: string;
  modelId: number;

  getId(): number {
    return this.id;
  }

  getTail(): string {
    return this.aircraftTailNumber;
  }

  getOffFleetOperatorIds(): string[] {
    return this.offFleetOperatorIds;
  }

  getMaxPax(): number {
    return this.maxPax;
  }
}
