import { ClientService } from './client-service';
import { OrderItemTypeEnum } from './order-item-type';
import { ProductOrderItem } from './product-order-item';
import { Client } from './client';

export class MembershipOrderItem extends ProductOrderItem {
  // salesAction: SalesActionEnum = null;
  createTime: Date = null;
  expirationTime: Date = null;
  expired: boolean = null;

  membershipClientService: ClientService | null = null;
  referredByClient: Client = null;
  isNotAffectPrice?: boolean;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.Membership;
  }
}
