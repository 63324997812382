import { AircraftInsurance } from './aircraft-insurance';
import { Operator } from './operator';

export class BulkEditData {
  insurance: AircraftInsurance;
  offFleetOperators: Array<Operator>;
  notes: string;
  d085OpsSpecsApproval: boolean;
  deleted: boolean;
}
