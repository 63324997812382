import { OperatorApprovalStatusEnum } from "./operator-approval-status.enum";
import { Rating } from "./rating";

export class Operator {
  operatorId: string = null;
  name: string = null;
  legalName: string = null;
  argusRating: Rating = null;
  wyvernRating: Rating = null;
  isBaoRating: Rating = null;
  levelFlightRating: Rating = null;
  cancellationTerms: string  = null;
  blanketCrewApproval: boolean = false;
  operatorApprovalStatus: OperatorApprovalStatusEnum;

  get legalNameAndName(): string {
    return this.legalName
      && this.name
      && this.legalName == this.name
        ? this.legalName
        : `${this.legalName} (${this.name})`;
  }


  getOperatorId(): string {
    return this.operatorId;
  }

  setOperatorId(operatorId: string): Operator {
    this.operatorId = operatorId;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Operator {
    this.name = name;

    return this;
  }

  getLegalName(): string {
    return this.legalName;
  }

  setLegalName(legalName: string): Operator {
    this.legalName = legalName;

    return this;
  }

  getLegalNameAndName(): string {
    return this.legalNameAndName;
  }

  setArgusRating(argusRating: Rating) {
    this.argusRating =  argusRating;
  }

  getArgusRating(): Rating {
    return this.argusRating;
  }

  setWyvernRating(wyvernRating: Rating) {
    this.wyvernRating =  wyvernRating;
  }

  getWyvernRating(): Rating {
    return this.wyvernRating;
  }

  setIsBaoRating(isBaoRating: Rating) {
    this.isBaoRating =  isBaoRating;
  }

  getIsBaoRating(): Rating {
    return this.isBaoRating;
  }

  setLevelFlightRating(levelFlightRating: Rating) {
    this.levelFlightRating =  levelFlightRating;
  }

  getLevelFlightRating(): Rating {
    return this.levelFlightRating;
  }

  getCanCellationTerms(): string {
    return this.cancellationTerms;
  }

  setCancellationTerms(cancellationTerms: string): Operator {
    this.cancellationTerms = cancellationTerms;

    return this;
  }

  getBanketCrewApproval(): boolean {
    return this.blanketCrewApproval;
  }

  setBanketCrewApproval(blanketCrewApproval: boolean): Operator {
    this.blanketCrewApproval = blanketCrewApproval;
    
    return this;
  }

  getOperatorApprovalStatus(): OperatorApprovalStatusEnum {
    return this.operatorApprovalStatus;
  }

  setOperatorApprovalStatus(operatorApprovalStatus: OperatorApprovalStatusEnum): Operator {
    this.operatorApprovalStatus = operatorApprovalStatus;

    return this;
  }

  equals(o1: Operator): boolean {
    if (!o1) {
      return false;
    }

    return this.operatorId === o1.operatorId;
  }
}
