import { AbstractControl } from '@angular/forms';

export function IntPhoneNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
  // eslint-disable-next-line max-len
  const phoneNumberRegExp = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/g;

  if (control.value && !control.value.match(phoneNumberRegExp)) {
    return { intPhoneNumber: true };
  } else {
    return null;
  }
}
