// 1 - Product, 2 - Discount, 3 - Flight Credit,
// 4 - Promotion, 5 - Prorate, 6 - child order item, 7 - child order item discount

export enum OrderItemKind {
  Product = 1,
  Discount = 2,
  FlightCredit = 3,
  Promotion = 4,
  Prorate = 5,
  ChildOrderItem = 6,
  ChildOrderItemDiscount = 7,
  CustomTerm = 8,
  Comment = 9,
  LineItem = 10,
  PromotionalCredit = 11,
  LoyaltyCredit = 12,
  FlightPass = 13,
  SfdcDepositDue = 14,
}
