export enum OrderStatusEnum {
  Created = 1,
  Paid = 2,
  Canceled = 3,
  AwaitingApproval = 4,
  AwaitingFulfillment = 5,
  AwaitingPayment = 6,
  Completed = 7,
}

export class OrderStatus {
  id: OrderStatusEnum;
  name: string;

  getId(): OrderStatusEnum {
    return this.id;
  }

  setId(id: OrderStatusEnum): OrderStatus {
    this.id = id;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): OrderStatus {
    this.name = name;
    return this;
  }
}
