export const response = {
  orderId: 4561,
  externalInvoiceId: 'e4c8d3f0-3ee9-413b-9186-2c2de0797d65',
  invoiceUuid: 'daa767e6-524f-4d69-9c48-ec051c7f9f72',
  orderItems: [
    {
      orderItemId: 10933,
    },
    {
      orderItemId: 10934,
    },
  ],
  invoiceId: 6007,
};
