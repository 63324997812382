import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/aircraft-category-collection.actions';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export const aircraftCategoryCollectionFeatureKey = 'aircraftCategoryCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.AircraftCategory>;
  calcItems: Array<models.AircraftCategory>;
  calcItemsLoading: boolean;
  calcItemsLoaded: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  calcItems: [],
  calcItemsLoading: false,
  calcItemsLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(actions.loadSuccess, (state, { entities }): State => {
    const items = _.cloneDeep(entities);
    return {
      ...state,
      loaded: true,
      loading: false,
      items,
    };
  }),

  on(actions.loadForCalc, (state): State => ({ ...state, calcItemsLoading: true })),
  on(actions.loadForCalcSuccess, (state, { entities }): State => {
    const calcItems = _.cloneDeep(entities);
    return {
      ...state,
      calcItemsLoading: false,
      calcItemsLoaded: true,
      calcItems,
    };
  }),

  on(actions.setInitial, (): State => initialState)
);
