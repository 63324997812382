export enum FlightTypeEnum {
  shuttle2 = 'Custom Shuttle',
  customshuttle = 'Custom Shuttle',
  charterseats = 'Shared seats',
  charter = 'Private charter',
  'shuttle-jsx' = 'JetSuiteX',
  'shuttle-advancedair' = 'AdvancedAir',
  tmc = 'TMC',
  xojet = 'XOJET',
  vistajet = 'Vista Jet',
  op_shared_charter = 'OP Shared Charter',
  op_whole_charter = 'OP Whole Charter',
  marketplace = 'Marketplace',
}
