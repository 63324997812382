import { ClientService } from './client-service';
import { GroupMembershipItem } from './group-membership-item';
import { ClientServiceTypeEnum } from './client-service-type';

export class GroupMembership extends ClientService {
  items: Array<GroupMembershipItem> = [];
  slotsCount: number;
  remainingReplacementsCount: number;

  get clientServiceType(): ClientServiceTypeEnum {
    return ClientServiceTypeEnum.GroupMembership;
  }
}
