import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/client-requests.actions';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const clientRequestsFeatureKey = 'clientRequests';

export interface State {
  charterRequests: Array<models.CharterClientRequest>;
  flightRequests: Array<models.ClientRequest>;
  conciergeFlightRequests: Array<models.ConciergeFlightRequest>;
  creditReasons: Array<models.CreditReason>;
  legRequests: Array<models.ConciergeLegRequest>;
  legRequestsResponse: Array<models.ConciergeLegRequest>;
  isFlightRequestsLoaded: boolean;
  isCharterRequestsLoaded: boolean;
  isAncillaryDataLoaded: boolean;
  isLoading: boolean;
  simplifiedMembership: models.SimplifiedMembershipEnum;
}

const initialState: State = {
  charterRequests: new Array<models.CharterClientRequest>(),
  flightRequests: new Array<models.ClientRequest>(),
  conciergeFlightRequests: [],
  legRequests: [],
  creditReasons: [],
  legRequestsResponse: [],
  isFlightRequestsLoaded: false,
  isCharterRequestsLoaded: false,
  isLoading: false,
  isAncillaryDataLoaded: false,
  simplifiedMembership: models.SimplifiedMembershipEnum.Undefined,
};

export const reducer = createReducer(
  initialState,

  on(actions.loadCharterRequests, (state): State => {
    const { isAncillaryDataLoaded, conciergeFlightRequests, legRequestsResponse, creditReasons } =
      state;
    return {
      ...initialState,
      isLoading: true,
      isCharterRequestsLoaded: false,
      isAncillaryDataLoaded,
      conciergeFlightRequests,
      legRequestsResponse,
      creditReasons,
    };
  }),
  on(actions.loadCharterRequestsSuccess, (state, { payload }): State => {
    const charterRequests = _.cloneDeep(payload);

    return {
      ...state,
      charterRequests,
      isCharterRequestsLoaded: true,
      isLoading: false,
    };
  }),
  on(
    actions.loadCharterRequestsFail,
    (state): State => ({
      ...initialState,
      isLoading: true,
      isFlightRequestsLoaded: false,
    })
  ),

  on(
    actions.loadSimplifiedMembershipSuccess,
    (state, { payload }): State => ({
      ...state,
      simplifiedMembership: payload,
    })
  ),

  on(actions.loadFlightRequestsSuccess, (state, { payload }): State => {
    const flightRequests = _.cloneDeep(payload);

    return {
      ...state,
      flightRequests,
      isFlightRequestsLoaded: true,
      isLoading: false,
    };
  }),
  on(
    actions.createCharterInvoiceFail,
    actions.loadCharterRequestsFail,
    actions.loadFlightRequestsFail,
    (): State => ({
      ...initialState,
    })
  ),

  on(
    actions.loadConciergeAncillaryData,
    (state): State => ({
      ...state,
      flightRequests: [],
      isLoading: true,
      isAncillaryDataLoaded: false,
    })
  ),
  on(actions.loadConciergeAncillaryDataSuccess, (state, { payload }): State => {
    const data: models.ConciergeFlightAncillaryData = _.cloneDeep(payload);
    return {
      ...state,
      conciergeFlightRequests: data.flightRequests,
      legRequestsResponse: data.legRequests,
      creditReasons: data.creditReasons,
      isLoading: false,
      isAncillaryDataLoaded: true,
    };
  }),
  on(
    actions.loadConciergeAncillaryDataFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    actions.createCharterInvoice,
    (state): State => ({
      ...state,
      isLoading: true,
      isCharterRequestsLoaded: false,
    })
  ),

  on(actions.setLegRequests, (state, { requestId }): State => {
    const legRequestsResponse: Array<models.ConciergeLegRequest> = _.cloneDeep(
      state.legRequestsResponse
    );
    let legRequests: Array<models.ConciergeLegRequest> = [];
    const conciergeFlightRequest = state.conciergeFlightRequests.find(
      (e) => e.requestId === requestId
    );
    if (conciergeFlightRequest.legRequestIds && conciergeFlightRequest.legRequestIds.length) {
      conciergeFlightRequest.legRequestIds.forEach((legRequestId) => {
        const legRequest = legRequestsResponse.find((leg) => leg.requestId === legRequestId);
        legRequests.push(legRequest);
      });
      return {
        ...state,
        legRequests,
      };
    }
    legRequests = legRequestsResponse.filter((leg) => leg.flightRequestId === requestId);
    return {
      ...state,
      legRequests,
    };
  }),

  on(
    actions.setInitial,
    (): State => ({
      ...initialState,
    })
  )
);
