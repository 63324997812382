import * as actions from '../actions/custom-shuttle-offers';
import * as models from '../../domain/models';
import * as helpers from '../../domain/helpers';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  historyLoading: boolean;
  historyLoaded: boolean;
  criteria: models.CustomShuttleCriteria;
  items: Array<models.CustomShuttleOffers>;
  filteredItems: Array<models.CustomShuttleOffers>;
  groupedItems: Array<models.CustomShuttleGroupedOffers>;
  selectedItem: Array<models.CustomShuttleOffers>;
  bulkItems: Array<models.CustomShuttleOffers>;
  historyItems: Array<models.CustomShuttleOfferHistory>;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  historyLoading: false,
  historyLoaded: false,
  criteria: new models.CustomShuttleCriteria(),
  items: [],
  filteredItems: [],
  groupedItems: [],
  selectedItem: null,
  bulkItems: null,
  historyItems: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET: {
      return {
        ...initialState,
        criteria: state.criteria,
      };
    }

    case actions.LOAD: {
      if (action.payload.fetch) {
        return {
          ...state,
          isLoaded: false,
          isLoading: true,
          criteria: action.payload.criteria,
        };
      }
      return {
        ...state,
        selectedItem: null,
        criteria: action.payload.criteria,
      };
    }

    case actions.LOAD_SUCCESS: {
      const { offers, detailsParams } = action.payload;
      const items = _.cloneDeep(offers || state.items);
      const filteredItems = helpers.filterOffers(items, state.criteria);
      const groupedItems = helpers.orderData('D', 'MM YYYY', filteredItems, state.criteria);
      if (detailsParams) {
        const selectedItem = helpers.getSelectedItem(groupedItems, detailsParams);
        return {
          ...state,
          selectedItem,
          items,
          filteredItems,
          groupedItems,
          isLoaded: true,
          isLoading: false,
        };
      }

      return {
        ...state,
        items,
        filteredItems,
        groupedItems,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.UPDATE_SUCCESS: {
      const {
        payload: { offers, isBulk },
      } = action;
      if (isBulk) {
        return {
          ...state,
          bulkItems: state.bulkItems.map((o) => {
            const i = offers.findIndex(({ id }) => o.id === id);
            if (i !== -1) {
              return { ...o, ...offers[i] };
            }
            return o;
          }),
        };
      }
      return {
        ...state,
        selectedItem: state.selectedItem.map((o) => {
          const i = offers.findIndex(({ id }) => o.id === id);
          if (i !== -1) {
            return { ...o, ...offers[i] };
          }
          return o;
        }),
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SET_BULK_ITEMS: {
      const { payload } = action;
      const bulkItems = helpers.getBulkItems(
        state.filteredItems,
        `${payload}${state.criteria.year}`
      );
      return {
        ...state,
        bulkItems,
      };
    }

    case actions.LOAD_HISTORY: {
      return {
        ...state,
        historyLoaded: false,
        historyLoading: true,
      };
    }

    case actions.LOAD_HISTORY_SUCCESS: {
      const { payload } = action;
      const historyItems = payload.items;
      return {
        ...state,
        historyLoaded: true,
        historyLoading: false,
        historyItems,
      };
    }

    case actions.LOAD_HISTORY_FAIL: {
      return {
        ...state,
        historyLoaded: true,
        historyLoading: false,
        historyItems: null,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getFilteredItems = (state: State) => state.items;
export const getCriteria = (state: State) => state.criteria;
export const getGroupedItems = (state: State) => state.groupedItems;
export const getSelectedItem = (state: State) => state.selectedItem;
export const getBulkItems = (state: State) => state.bulkItems;
export const getHistoryLoaded = (state: State) => state.historyLoaded;
export const getHistoryLoading = (state: State) => state.historyLoading;
export const getHistoryItems = (state: State) => state.historyItems;
