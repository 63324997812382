import { Action } from '@ngrx/store';
import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export class LoadPayload {
  criteria: models.TaskLookupCriteria;
  offset: number;
  limit: number;
  sortOrder: models.SortOrderEnum = models.SortOrderEnum.asc;
  sortBy: models.SortByEnum = models.SortByEnum.legId;
  isNotUpdateCriteria = false;
  notes: Array<models.Note>;
  sort = false;
  isLeg: boolean;
}
export const load = createAction(
  '[TaskMgmt.TaskCollection] Load',
  props<{ payload: LoadPayload }>()
);
export class LoadSuccessPayload {
  items: Array<models.Task>;
  number: number;
  totalElements: number;
}
export const loadSuccess = createAction(
  '[TaskMgmt.TaskCollection] Load Success',
  props<{ payload: LoadSuccessPayload }>()
);
export const loadFail = createAction('[TaskMgmt.TaskCollection] Load Fail');

export const setPods = createAction(
  '[TaskMgmt.TaskCollection] Set Pods',
  props<{ payload: Array<string> }>()
);

export class SetStatePayload {
  taskStateId: number;
  taskId: number;
}
export const setState = createAction(
  '[TaskMgmt.TaskCollection] Set State',
  props<{ payload: SetStatePayload }>()
);
export class SetStateSuccessPayload {
  task: models.Task;
}
export const setStateSuccess = createAction(
  '[TaskMgmt.TaskCollection] Set State Success',
  props<{ payload: SetStateSuccessPayload }>()
);
export class SetStateFailedPayload {
  taskId: number;
  taskStateId: models.StateEnum;
}
export const setStateFail = createAction(
  '[TaskMgmt.TaskCollection] Set State Fail',
  props<{ payload: SetStateFailedPayload }>()
);

export class UpdateLegsSuccessPayload {
  entities: Array<models.FlightLeg>;
}
export const updateLegsSuccess = createAction(
  '[TaskMgmt.TaskCollection] Update Legs Success',
  props<{ payload: UpdateLegsSuccessPayload }>()
);
export const updateLegsFail = createAction('[TaskMgmt.TaskCollection] Update Legs Fail');

export class LoadTaskLogsPayload {
  taskId: number;
}
export const loadTaskLogs = createAction(
  '[TaskMgmt.TaskCollection] Load Task Logs',
  props<{ payload: LoadTaskLogsPayload }>()
);
export class LoadTaskLogsSuccessPayload {
  logs: Array<models.TaskLog>;
}
export const loadTaskLogsSuccess = createAction(
  '[TaskMgmt.TaskCollection] Load Task Logs Success',
  props<{ payload: LoadTaskLogsSuccessPayload }>()
);
export const loadTaskLogsFail = createAction('[TaskMgmt.TaskCollection] Load Task Logs Fail');

export const resetTaskLogs = createAction('[TaskMgmt.TaskCollection] Reset Task Logs');

export class CreateNewTaskPayload {
  legId: number;
  dueDate: Date;
  description: string;
  taskCategoryId: number;
  title: string;
}
export const createNewTask = createAction(
  '[TaskMgmt.TaskCollection] Create New Task',
  props<{ payload: CreateNewTaskPayload }>()
);
export class CreateNewTaskSuccessPayload {
  task: models.Task;
}
export const createNewTaskSuccess = createAction(
  '[TaskMgmt.TaskCollection] Create New Task Success',
  props<{ payload: CreateNewTaskSuccessPayload }>()
);
export const createNewTaskFail = createAction(
  '[TaskMgmt.TaskCollection] Create New Task Fail',
  props<{ payload?: any }>()
);

export const setInitial = createAction('[TaskMgmt.TaskCollection] Set Initial');

export class CreateNewNotePayload {
  taskId: number;
  text: string;
}
export const createNewNote = createAction(
  '[TaskMgmt.TaskCollection] Create New Note',
  props<{ payload: CreateNewNotePayload }>()
);
export class CreateNewNoteSuccessPayload {
  note: models.Note;
  taskId: number;
}
export const createNewNoteSuccess = createAction(
  '[TaskMgmt.TaskCollection] Create New Note Success',
  props<{ payload: CreateNewNoteSuccessPayload }>()
);
export const createNewNoteFail = createAction(
  '[TaskMgmt.TaskCollection] Create New Note Fail',
  props<{ payload?: any }>()
);

export class UpdateNotePayload {
  id: string;
  text: string;
  taskId: number;
}
export const updateNote = createAction(
  '[TaskMgmt.TaskCollection] Update Note',
  props<{ payload: UpdateNotePayload }>()
);
export class UpdateNoteSuccessPayload {
  note: models.Note;
  taskId: number;
}
export const updateNoteSuccess = createAction(
  '[TaskMgmt.TaskCollection] Update Note Success',
  props<{ payload: UpdateNoteSuccessPayload }>()
);
export const updateNoteFail = createAction(
  '[TaskMgmt.TaskCollection] Update Note Fail',
  props<{ payload?: any }>()
);

export class DeleteNotePayload {
  id: string;
  taskId: number;
}
export const deleteNote = createAction(
  '[TaskMgmt.TaskCollection] Delete Note',
  props<{ payload: DeleteNotePayload }>()
);
export class DeleteNoteSuccessPayload {
  id: string;
  taskId: number;
}
export const deleteNoteSuccess = createAction(
  '[TaskMgmt.TaskCollection] Delete Note Success',
  props<{ payload: DeleteNoteSuccessPayload }>()
);
export const deleteNoteFail = createAction(
  '[TaskMgmt.TaskCollection] Delete Note Fail',
  props<{ payload?: any }>()
);

export class SetStateForMultipleTaskPayload {
  taskStateId: number;
  taskIds: Array<number>;
}
export const setStateForMultipleTask = createAction(
  '[TaskMgmt.TaskCollection] Set State For Multiple Task',
  props<{ payload: SetStateForMultipleTaskPayload }>()
);
export class SetStateForMultipleTaskSuccessPayload {
  tasks: Array<models.Task>;
}
export const setStateForMultipleTaskSuccess = createAction(
  '[TaskMgmt.TaskCollection] Set State For Multiple Task Success',
  props<{ payload: SetStateForMultipleTaskSuccessPayload }>()
);
export class SetStateForMultipleTaskFailedPayload {
  taskIds: Array<number>;
  taskStateId: models.StateEnum;
}
export const setStateForMultipleTaskFail = createAction(
  '[TaskMgmt.TaskCollection] Set State For Multiple Task Fail',
  props<{ payload: SetStateForMultipleTaskFailedPayload }>()
);

export class RevertStatePayload {
  taskIds: Array<number>;
}
export const revertState = createAction(
  '[TaskMgmt.TaskCollection] Revert State',
  props<{ payload: RevertStatePayload }>()
);
export class RevertStateSuccessPayload {
  tasks: Array<models.Task>;
}
export const revertStateSuccess = createAction(
  '[TaskMgmt.TaskCollection] Revert State Success',
  props<{ payload: RevertStateSuccessPayload }>()
);
export class RevertStateFailedPayload {
  taskIds: Array<number>;
}
export const revertStateFail = createAction(
  '[TaskMgmt.TaskCollection] Revert State Fail',
  props<{ payload: RevertStateFailedPayload }>()
);

export class GetRelatedLegsPayload {
  legId: number;
}
export const getRelatedLegs = createAction(
  '[TaskMgmt.TaskCollection] Get Related Legs',
  props<{ payload: GetRelatedLegsPayload }>()
);
export class GetRelatedLegsSuccessPayload {
  relatedLegs: models.RelatedLegs;
}
export const getRelatedLegsSuccess = createAction(
  '[TaskMgmt.TaskCollection] Get Related Legs Success',
  props<{ payload: GetRelatedLegsSuccessPayload }>()
);
export const getRelatedLegsFail = createAction('[TaskMgmt.TaskCollection] Get Related Legs Fail');

export class UpdateRelatedLegsPayload {
  flightRequestId: number;
  legIds: Array<number>;
  pod: string;
}
export const updateRelatedLegs = createAction(
  '[TaskMgmt.TaskCollection] Update Related Legs',
  props<{ payload: UpdateRelatedLegsPayload }>()
);
export class UpdateRelatedLegsSuccessPayload {
  pod: string;
}
export const updateRelatedLegsSuccess = createAction(
  '[TaskMgmt.TaskCollection] Update Related Legs Success',
  props<{ payload: UpdateRelatedLegsSuccessPayload }>()
);
export const updateRelatedLegsFail = createAction(
  '[TaskMgmt.TaskCollection] Update Related Legs Fail'
);

export class FinalizeLegPayload {
  legId: number;
}
export const finalizeLeg = createAction(
  '[TaskMgmt.TaskCollection] Finalize Leg',
  props<{ payload: FinalizeLegPayload }>()
);
export const finalizeLegSuccess = createAction('[TaskMgmt.TaskCollection] Finalize Leg Success');
export const finalizeLegFail = createAction('[TaskMgmt.TaskCollection] Finalize Leg Fail');

export class UpdateLegsPayload {
  legs: Array<models.LegRequest>;
}
export const completeTasks = createAction(
  '[TaskMgmt.TaskCollection] Complete Tasks',
  props<{
    updateLegsPayload: UpdateLegsPayload;
    setStateForMultipleTaskPayload: SetStateForMultipleTaskPayload;
  }>()
);
export const completeTasksSuccess = createAction(
  '[TaskMgmt.TaskCollection] Complete Tasks Success'
);
export class CompleteTasksFailPayload {
  taskIds: Array<number>;
  taskStateId: models.StateEnum;
}
export const completeTasksFail = createAction(
  '[TaskMgmt.TaskCollection] Complete Tasks Fail',
  props<{ payload: CompleteTasksFailPayload }>()
);
