import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Charter.SourcingSpecialistCollection] Load');
export const loadSuccess = createAction(
  '[Charter.SourcingSpecialistCollection] Load Success',
  props<{ payload: Array<models.User> }>()
);
export const loadFail = createAction('[Charter.SourcingSpecialistCollection] Load Fail');
