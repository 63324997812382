import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import * as models from '../../domain/models';
import * as actions from '../actions/product-collection.actions';

export const productCollectionFeatureKey = 'productCollection';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Product>;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (_state, { payload }): State => ({
      isLoaded: true,
      isLoading: false,
      items: _.cloneDeep(payload.products),
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
    })
  )
);
