import { SeatPricingRule } from './seat-pricing-rule';
import { SeatPricingMgmtSortMapper } from '../../../services/seat-pricing-mgmt/mappers/seat-pricing-mgmt-sort-mapper';

export class RouteGroupRule {
  id: number;
  routeGroupNames: Array<string>;
  aircraftCategories: Array<string>;
  shuttleTypeNames: Array<string>;
  dow: Array<string>;
  totalSeats: number;
  seat: SeatPricingRule;

  constructor(obj: any, id: number) {
    if (id !== null) {
      this.id = id;
      this.routeGroupNames = obj.hasOwnProperty('routeGroupNames') ? SeatPricingMgmtSortMapper.sortRoutes(obj.routeGroupNames) : [];
      this.aircraftCategories = obj.hasOwnProperty('aircraftCategories') ? obj.aircraftCategories : [];
      this.shuttleTypeNames = obj.hasOwnProperty('shuttleTypeNames') ? obj.shuttleTypeNames : [];
      this.dow = obj.hasOwnProperty('dow') ? obj.dow : [];
      this.totalSeats = obj.hasOwnProperty('totalSeats') ? obj.totalSeats : null;

      this.seat = new SeatPricingRule(obj.hasOwnProperty('firstSeat') ? obj.firstSeat : {});
    }
  }
}
