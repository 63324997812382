import { Injectable } from '@angular/core';
import {
  CostTypeListResponse,
  ICostTypesService,
} from '../../../domain/service-contracts/cost-types';
import { Observable, of } from 'rxjs';
import { CostType } from '../../../domain/models/cost-type';

@Injectable()
export class CostTypeMockService implements ICostTypesService {
  private mockCostTypes: CostType[] = [
    {
      id: 1,
      type: 'PDFP ',
      description: 'Peak Day Premium 1/18',
    },
    {
      id: 2,
      type: 'HAND ',
      description: 'Service Fee',
    },
    {
      id: 3,
      type: 'PDFP',
      description: 'Peak Day Premium 2/11, 2/12',
    },
    {
      id: 4,
      type: '1HRD',
      description: 'Waive +/- 1 Hr Dept Flex',
    },
    {
      id: 5,
      type: 'STD',
      description: 'Slide 03/06',
    },
    {
      id: 6,
      type: 'STD',
      description: 'NON PREF FBO',
    },
    {
      id: 7,
      type: '3HRD',
      description: 'Waive +/- 3 Hr Dept Flex',
    },
    {
      id: 8,
      type: 'RONC',
      description: 'Crew overnight',
    },
    {
      id: 9,
      type: 'INTF',
      description: "Est. Int'l'/Customs Fees Bahamas & Canada",
    },
    {
      id: 10,
      type: 'NFBO',
      description: 'Non-Preferred FBO Fee',
    },
    {
      id: 11,
      type: 'DISC',
      description: 'YC Charter Discount',
    },
    {
      id: 12,
      type: 'CANF',
      description: 'Cancellation Fee',
    },
    {
      id: 13,
      type: 'SGTX',
      description: 'Segment Tax 1/2',
    },
    {
      id: 14,
      type: 'ISEG',
      description: "Int'l' Segment Fee",
    },
    {
      id: 15,
      type: '2HRS',
      description: '2 Hr Sliding Dept Window',
    },
    {
      id: 16,
      type: 'PDFP',
      description: 'Peak Day Premium 2/11',
    },
    {
      id: 17,
      type: 'AFTR',
      description: 'After Hours/Callout',
    },
    {
      id: 18,
      type: 'MEGA',
      description: 'MSRP Mega',
    },
    {
      id: 19,
      type: 'INTF',
      description: "Estimated Int'l' Fees",
    },
    {
      id: 20,
      type: 'ASFE',
      description: 'Airport Service Fees',
    },
    {
      id: 21,
      type: 'PDFP',
      description: 'Peak Day Premium 2/15',
    },
    {
      id: 22,
      type: 'HIGH',
      description: 'MSRP High',
    },
    {
      id: 23,
      type: 'DISC',
      description: 'Service Issue Discount',
    },
    {
      id: 24,
      type: 'DISC',
      description: 'Charter Discount',
    },
    {
      id: 25,
      type: 'LOWF',
      description: 'MSRP Low Floor',
    },
    {
      id: 26,
      type: 'INTF',
      description: "Est. Int'l'/Customs Fees Mexico",
    },
    {
      id: 27,
      type: 'INTF',
      description: "Est. Int'l'/Customs Fees",
    },
    {
      id: 28,
      type: 'GCL3',
      description: 'Guaranteed CL30 2/9, 2/11, 2/12',
    },
    {
      id: 29,
      type: 'PA01',
      description: 'Preferred Access Reward',
    },
    {
      id: 30,
      type: 'LOW',
      description: '-4998,75',
    },
    {
      id: 31,
      type: 'SGTX',
      description: 'Hawaii Segment Tax',
    },
    {
      id: 32,
      type: 'RTD',
      description: 'ELAC Round Trip Discount',
    },
    {
      id: 33,
      type: 'STD',
      description: 'MSRP Standard',
    },
    {
      id: 34,
      type: 'PDFP',
      description: 'Peak Day Premium 1/2',
    },
    {
      id: 35,
      type: 'MISC',
      description: 'Miscellaneous Fee',
    },
    {
      id: 36,
      type: 'RISE',
      description: 'Non Member Handling',
    },
    {
      id: 37,
      type: '1HRD',
      description: 'Waive +/- 3 Hr Dept Flex',
    },
    {
      id: 38,
      type: 'MGSA',
      description: 'Goldman Sachs Partnership Discount',
    },
    {
      id: 39,
      type: 'LOW',
      description: 'MSRP Low',
    },
    {
      id: 40,
      type: 'SA01',
      description: 'Select Access Reward',
    },
    {
      id: 41,
      type: 'TPGC',
      description: 'TPG Fees',
    },
    {
      id: 42,
      type: 'REDE',
      description: 'Red Eye Fee',
    },
    {
      id: 43,
      type: 'MARK',
      description: 'Mark-Up',
    },
    {
      id: 44,
      type: 'MSRP',
      description: 'Pricing Adjustment',
    },
    {
      id: 45,
      type: 'SCPA',
      description: 'SA Dsicount for Competitive Pricing',
    },
    {
      id: 46,
      type: 'SMEM',
      description: 'SA Discount for Membership Retention',
    },
    {
      id: 47,
      type: 'SINF',
      description: 'SA Dsicount for an Influencer',
    },
    {
      id: 48,
      type: 'MEG+',
      description: 'MSRP Mega+',
    },
    {
      id: 49,
      type: 'BRCO',
      description: 'Service Fee',
    },
    {
      id: 50,
      type: 'SNCL',
      description: 'SA Dsicount for a New Client',
    },
    {
      id: 51,
      type: 'FAFE',
      description: 'Cabin Hostess',
    },
    {
      id: 52,
      type: 'PLUS',
      description: 'Price Adjustment',
    },
    {
      id: 53,
      type: 'LHD',
      description: 'ELAC Long Haul Discount',
    },
    {
      id: 54,
      type: 'RONC',
      description: 'Crew Overnight',
    },
    {
      id: 55,
      type: 'SGTX',
      description: 'Segment Tax',
    },
    {
      id: 56,
      type: 'SSVC',
      description: 'SA Discount for Service Issues',
    },
    {
      id: 57,
      type: 'BASE',
      description: 'MSRP Basement',
    },
    {
      id: 58,
      type: 'GCL3',
      description: 'Guaranteed CL30',
    },
    {
      id: 59,
      type: 'CCFE',
      description: 'Convenience Fee',
    },
    {
      id: 60,
      type: 'NA',
      description: 'MSRP Standard',
    },
    {
      id: 61,
      type: 'LYCU',
      description: 'Loyalty Credits Redeemed',
    },
    {
      id: 62,
      type: 'SSVC',
      description: 'SA Dsicount for Service Issues',
    },
    {
      id: 63,
      type: 'PDFP',
      description: 'Peak Day Premium',
    },
    {
      id: 64,
      type: 'FCHRG',
      description: 'Flight Charge',
    },
    {
      id: 65,
      type: 'FET',
      description: 'FET',
    },
  ];

  getAll(): Observable<CostTypeListResponse> {
    const response = new CostTypeListResponse();
    response.costTypes = this.mockCostTypes;
    response.message = 'success';
    response.code = 200;

    return of(response);
  }
}
