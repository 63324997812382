export const response = {
  data: [
    {
      id: 1,
      membershipDetails: {
        membershipPlan: 'BUY AS YOU FLY',
        showLoyaltyCredits: false,
        membershipFee: null,
        membershipFeePeriod: null,
        minimumDeposit: null,
        flightBookingPayments: 3.5,
        flightBookingPaymentsType: 'credit card',
        benefitAccess: null,
        referralProgram: false,
      },
      recommended: false,
      routes: [
        {
          routeId: 'KFLL_KBCE_6_null_FLEX',
          origin: 'KFLL',
          destination: 'KBCE',
          aircraftCategory: 6,
          blockTime: 5.15,
          pax: 8,
          cost: {
            low: 43054.06,
            high: 68605.11,
            lowHourlyAvg: 8360.01,
            highHourlyAvg: 13321.38,
            discountValueLow: 0,
            discountValueHigh: 0,
            serviceFee: 295,
            nonMemberFee: null,
            segmentFee: 36,
            fetTax: 4582.13,
            fuelCost: null,
            fetTaxPercent: 7.5,
            priceWithoutServiceFee: false,
            lowWithDiscount: 43054.06,
          },
        },
      ],
      totalCost: {
        low: 43054.06,
        high: 68605.11,
        lowHourlyAvg: 8360.01,
        highHourlyAvg: 13321.38,
        discountValueLow: 0,
        discountValueHigh: 0,
        serviceFee: null,
        nonMemberFee: null,
        segmentFee: null,
        fetTax: null,
        fuelCost: null,
        fetTaxPercent: null,
        priceWithoutServiceFee: false,
        lowWithDiscount: 43054.06,
      },
      features: [],
    },
    {
      id: 2,
      membershipDetails: {
        membershipPlan: 'RISE',
        showLoyaltyCredits: false,
        membershipFee: 595,
        membershipFeePeriod: 'year',
        minimumDeposit: null,
        flightBookingPayments: 3.5,
        flightBookingPaymentsType: 'credit card',
        benefitAccess: 'Limited access',
        referralProgram: false,
      },
      recommended: false,
      routes: [
        {
          routeId: 'KFLL_KBCE_6_null_FLEX',
          origin: 'KFLL',
          destination: 'KBCE',
          aircraftCategory: 6,
          blockTime: 5.15,
          pax: 8,
          cost: {
            low: 42645.24,
            high: 68196.28,
            lowHourlyAvg: 8280.63,
            highHourlyAvg: 13242,
            discountValueLow: 0,
            discountValueHigh: 0,
            serviceFee: 295,
            nonMemberFee: null,
            segmentFee: 36,
            fetTax: 4582.13,
            fuelCost: null,
            fetTaxPercent: 7.5,
            priceWithoutServiceFee: false,
            lowWithDiscount: 42645.24,
          },
        },
      ],
      totalCost: {
        low: 42645.24,
        high: 68196.28,
        lowHourlyAvg: 8280.63,
        highHourlyAvg: 13242,
        discountValueLow: 0,
        discountValueHigh: 0,
        serviceFee: null,
        nonMemberFee: null,
        segmentFee: null,
        fetTax: null,
        fuelCost: null,
        fetTaxPercent: null,
        priceWithoutServiceFee: false,
        lowWithDiscount: 42645.24,
      },
      features: [],
    },
    {
      id: 3,
      membershipDetails: {
        membershipPlan: 'SELECT',
        showLoyaltyCredits: true,
        membershipFee: 250,
        membershipFeePeriod: 'month',
        minimumDeposit: 50000,
        flightBookingPayments: null,
        flightBookingPaymentsType: 'deposit-funded',
        benefitAccess: 'Partial access',
        referralProgram: true,
      },
      recommended: false,
      routes: [
        {
          routeId: 'KFLL_KBCE_6_null_FLEX',
          origin: 'KFLL',
          destination: 'KBCE',
          aircraftCategory: 6,
          blockTime: 5.15,
          pax: 8,
          cost: {
            low: 41203.13,
            high: 65890.13,
            lowHourlyAvg: 8000.61,
            highHourlyAvg: 12794.2,
            discountValueLow: 766,
            discountValueHigh: 1225,
            serviceFee: 295,
            nonMemberFee: null,
            segmentFee: 36,
            fetTax: 4582.13,
            fuelCost: null,
            fetTaxPercent: 7.5,
            priceWithoutServiceFee: false,
            lowWithDiscount: 40437.13,
          },
        },
      ],
      totalCost: {
        low: 41203.13,
        high: 65890.13,
        lowHourlyAvg: 8000.61,
        highHourlyAvg: 12794.2,
        discountValueLow: 766,
        discountValueHigh: 1225,
        serviceFee: null,
        nonMemberFee: null,
        segmentFee: null,
        fetTax: null,
        fuelCost: null,
        fetTaxPercent: null,
        priceWithoutServiceFee: false,
        lowWithDiscount: 40437.13,
      },
      features: [2, 3],
    },
    {
      id: 4,
      membershipDetails: {
        membershipPlan: 'SIGNATURE',
        showLoyaltyCredits: true,
        membershipFee: 500,
        membershipFeePeriod: 'month',
        minimumDeposit: 100000,
        flightBookingPayments: null,
        flightBookingPaymentsType: 'deposit-funded',
        benefitAccess: 'Full access',
        referralProgram: true,
      },
      recommended: false,
      routes: [
        {
          routeId: 'KFLL_KBCE_6_null_FLEX',
          origin: 'KFLL',
          destination: 'KBCE',
          aircraftCategory: 6,
          blockTime: 5.15,
          pax: 8,
          cost: {
            low: 41203.13,
            high: 65890.13,
            lowHourlyAvg: 8000.61,
            highHourlyAvg: 12794.2,
            discountValueLow: 1532,
            discountValueHigh: 2451,
            serviceFee: 295,
            nonMemberFee: null,
            segmentFee: 36,
            fetTax: 4582.13,
            fuelCost: null,
            fetTaxPercent: 7.5,
            priceWithoutServiceFee: false,
            lowWithDiscount: 39671.13,
          },
        },
      ],
      totalCost: {
        low: 41203.13,
        high: 65890.13,
        lowHourlyAvg: 8000.61,
        highHourlyAvg: 12794.2,
        discountValueLow: 1532,
        discountValueHigh: 2451,
        serviceFee: null,
        nonMemberFee: null,
        segmentFee: null,
        fetTax: null,
        fuelCost: null,
        fetTaxPercent: null,
        priceWithoutServiceFee: false,
        lowWithDiscount: 39671.13,
      },
      features: [5, 6, 2, 3],
    },
    {
      id: 5,
      membershipDetails: {
        membershipPlan: 'ELITE',
        showLoyaltyCredits: true,
        membershipFee: 1000,
        membershipFeePeriod: 'month',
        minimumDeposit: 100000,
        flightBookingPayments: null,
        flightBookingPaymentsType: 'deposit-funded',
        benefitAccess: 'Full access',
        referralProgram: true,
      },
      recommended: true,
      routes: [
        {
          routeId: 'KFLL_KBCE_6_null_FLEX',
          origin: 'KFLL',
          destination: 'KBCE',
          aircraftCategory: 6,
          blockTime: 5.15,
          pax: 8,
          cost: {
            low: 32017.25,
            high: 37553.5,
            lowHourlyAvg: 6216.94,
            highHourlyAvg: 7291.94,
            discountValueLow: 0,
            discountValueHigh: 0,
            serviceFee: 0,
            nonMemberFee: null,
            segmentFee: 36,
            fetTax: 2617.5,
            fuelCost: 0,
            fetTaxPercent: 7.5,
            priceWithoutServiceFee: false,
            lowWithDiscount: 32017.25,
          },
        },
      ],
      totalCost: {
        low: 32017.25,
        high: 37553.5,
        lowHourlyAvg: 6216.94,
        highHourlyAvg: 7291.94,
        discountValueLow: 0,
        discountValueHigh: 0,
        serviceFee: null,
        nonMemberFee: null,
        segmentFee: null,
        fetTax: null,
        fuelCost: null,
        fetTaxPercent: null,
        priceWithoutServiceFee: false,
        lowWithDiscount: 32017.25,
      },
      features: [5, 6, 2, 3, 4],
    },
  ],
};
