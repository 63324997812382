import * as models from '../../../../domain/models';
import * as dto from '../contracts';

export class PaymentTransactionAchDtoMapper {
  toDto(pt: models.PaymentTransactionAch): dto.PaymentTransactionAchDto {
    return new dto.PaymentTransactionAchDto(
      pt.referenceNumber,
      pt.paymentDate,
      pt.paymentTransactionType
    );
  }
}
