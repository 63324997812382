import * as models from '../../domain/models';
import { CurrencyEnum } from '../../../../shared/models';

export class PurchaseOfferFormViewModel {
  aircraftCategories: Array<models.AircraftCategory> = [];
  operators: Array<models.Operator> = [];
  offerPrice: number = null;
  priceType: models.PriceTypeEnum = null;
  additionalInstructions: string = null;
  currency: CurrencyEnum = CurrencyEnum.USD;

  isValidOperators() {
    return !!this.operators.length;
  }

  isValidOfferPrice() {
    return !!this.offerPrice || this.priceType === models.PriceTypeEnum.DYNAMIC;
  }

  isValid() {
    return this.isValidOperators() && this.isValidOfferPrice();
  }
}
