import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';
import * as actions from '../actions/search-results';
import * as _ from 'lodash';

export interface State {
  vistaStudioItems: Array<models.VistaStudioSearchResult>;
  vistaStudioItemsLoading: boolean;
  vistaStudioItemsLoaded: boolean;
  marketplaceItems: Array<models.MarketplaceSearchResult>;
  marketplaceItemsLoading: boolean;
  marketplaceItemsLoaded: boolean;
  lookup: viewModels.SearchResultsLookup;
}

const initialState: State = {
  vistaStudioItems: [],
  vistaStudioItemsLoading: false,
  vistaStudioItemsLoaded: false,
  marketplaceItems: [],
  marketplaceItemsLoading: false,
  marketplaceItemsLoaded: false,
  lookup: new viewModels.SearchResultsLookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.GET_VISTA_STUDIO_SEARCH_RESULTS: {
      return { 
        ...state,
        vistaStudioItemsLoaded: false,
        vistaStudioItemsLoading: true,
        
      };
    }

    case actions.GET_VISTA_STUDIO_SEARCH_RESULTS_SUCCESS: {
      const items = _.cloneDeep(action.payload.items);
      return { 
        ...state,
        vistaStudioItems: items,
        vistaStudioItemsLoaded: true,
        vistaStudioItemsLoading: false,
        
      };
    }

    case actions.GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED: {
      const lookup = _.cloneDeep(state.lookup);
      return { 
        ...initialState,
        lookup,
        vistaStudioItemsLoaded: true,
        vistaStudioItemsLoading: true,
        
      };
    }

    case actions.GET_MARKETPLACE_SEARCH_RESULTS: {
      return { 
        ...state,
        marketplaceItemsLoaded: false,
        marketplaceItemsLoading: true,
        
      };
    }

    case actions.GET_MARKETPLACE_SEARCH_RESULTS_SUCCESS: {
      const items = _.cloneDeep(action.payload.items);
      return { 
        ...state,
        marketplaceItems: items,
        marketplaceItemsLoaded: true,
        marketplaceItemsLoading: false,
        
      };
    }

    case actions.GET_VISTA_STUDIO_SEARCH_RESULTS_FAILED: {
      const lookup = _.cloneDeep(state.lookup);
      return { 
        ...initialState,
        lookup,
        marketplaceItemsLoaded: true,
        marketplaceItemsLoading: true,
        
      };
    }

    case actions.SET_LOOKUP: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload),
      }
    }

    case actions.RESET_LOOKUP: {
      return {
        ...initialState,
      }
    }

    default: {
      return state;
    }
  }
}

export const getVistaStudioItems = (state: State) => state.vistaStudioItems;
export const getLookup = (state: State) => state.lookup;
export const getVistaStudioItemsLoading = (state: State) => state.vistaStudioItemsLoading;
export const getVistaStudioItemsLoaded = (state: State) => state.vistaStudioItemsLoaded;

export const getMarketplaceItems = (state: State) => state.marketplaceItems;
export const getMarketplaceItemsLoading = (state: State) => state.marketplaceItemsLoading;
export const getMarketplaceItemsLoaded = (state: State) => state.marketplaceItemsLoaded;
