import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { RxStomp } from '@stomp/rx-stomp';
import { StompHeaders, publishParams, Frame } from '@stomp/stompjs';

export interface IStompService extends RxStomp {
    connect(romPath: string): void;
    disconnect(): void;
    subscribe(event: string): Observable<any>;
    publish(queueName: string | publishParams, message?: string, headers?: StompHeaders): void;
}

export const STOMP_SERVICE_TOKEN = new InjectionToken('Shared.StompService');
