import { Organization } from './organization';
import { Role } from './role';
import { Permissions } from './permissions';
import { RestrictedFunction } from './restricted-function';

export class User {
  id: number;
  userTypeId: number;
  firstName: string;
  lastName: string;
  organization: Organization;
  roles: Array<Role>;
  emailAddress: string;
  viewPermissions: Permissions;
  logoutUrl: string;
  restrictedFunctions: Array<RestrictedFunction>;

  constructor() {
    this.roles = [];
    this.viewPermissions = new Permissions();
    this.restrictedFunctions = [];
  }
}
