import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as contracts from '../../../service-contracts/airport';
import * as coreHelpers from '../../../helpers';
import * as coreTypes from '../../../types';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AirportRestService implements contracts.IAirportService {
  private get API_ROUTE_MGMT_PATH(): string {
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      return this.configService.get('RouteManagementServiceEndpoint');
    } else {
      return this.configService.get('CharterPricingAnalyticsEndpointV1');
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getAirportByCode(
    request: contracts.GetAirportByCodeRequest
  ): Observable<contracts.GetAirportByCodeResponse> {
    const clientRequestUrl = `${this.API_ROUTE_MGMT_PATH}/airports/validate?code=${request.code}`;
    // https://pricing.dev.jetsmarter.io/api/v1/charter-pricing-analytics-backend-api/airports/validate?code=HHHH
    return this.http.get(clientRequestUrl).pipe(
      map((isValid: any) => {
        const response = new contracts.GetAirportByCodeResponse();
        response.valid = isValid;
        return response;
      })
    );
  }
}
