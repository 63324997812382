import { Injectable } from '@angular/core';

import * as sharedTypes from '../../../../../shared/types';
import * as contracts from '../../../domain/service-contracts/membership';
import * as models from '../../../domain/models';

import { response as getTransitionsResponse } from './get-transitions-response';
import { response as getPriceComponentsToSalesActionsResponse } from './get-price-components-to-sales-actions-response';

import { Observable, of } from 'rxjs';

@Injectable()
export class MembershipMockService implements contracts.IMembershipService {
  getPriceComponentToSalesActions(
    request: contracts.GetPriceComponentToSalesActionsRequest
  ): Observable<contracts.GetPriceComponentToSalesActionsResponse> {
    const response = new contracts.GetPriceComponentToSalesActionsResponse();
    const entities = getPriceComponentsToSalesActionsResponse.data.map((item) => {
      const pcToSa = new models.PriceComponentToSalesAction();

      pcToSa.priceComponentId = Number(item.priceComponentId);
      pcToSa.salesAction = Number(item.salesActionId);

      return pcToSa;
    });

    response.entities = entities;

    return of(response);
  }

  getTransitions(
    request: contracts.GetTransitionsRequest
  ): Observable<contracts.GetTransitionsResponse> {
    const svcJsonResp = getTransitionsResponse;

    const response = new contracts.GetTransitionsResponse();
    response.entities = [];

    const entities = svcJsonResp.data.map((item) => {
      const transition = new models.Transition();

      transition.membershipTransitionId = Number(item.id);
      transition.salesAction = Number(item.salesAction.id);

      if (item.currentProduct) {
        transition.currentProduct = new models.Product();
        transition.currentProduct.id = item.currentProduct.id;
        transition.currentProduct.name = item.currentProduct.name;
        transition.currentProduct.description = item.currentProduct.description;
        transition.currentProduct.amount = sharedTypes.Util.nvlToNumber(item.currentProduct.amount);
      }

      if (item.nextProduct) {
        if (item.nextProduct.id === null) {
          transition.nextProduct = {} as models.Product;
        } else {
          transition.nextProduct = new models.Product();
          transition.nextProduct.id = item.nextProduct.id;
          transition.nextProduct.name = item.nextProduct.name;
          transition.nextProduct.description = item.nextProduct.description;
          transition.nextProduct.amount = sharedTypes.Util.nvlToNumber(item.nextProduct.amount);
        }
      }

      return transition;
    });

    response.entities = entities;

    return of(response);
  }

  activateMembership(
    request: contracts.ActivateMembershipRequest
  ): Observable<contracts.ActivateMembershipResponse> {
    throw new Error('Not implemented');
  }
}
