import { AircraftImageTypeEnum } from './aircraft-image-type-enum';

export class AircraftImage {
  aircraftImageType: AircraftImageTypeEnum;
  absolutePath: string;

  setAircraftImageType(aircraftImageType: AircraftImageTypeEnum): AircraftImage {
    this.aircraftImageType = aircraftImageType;

    return this;
  }

  getAbsolutePath(): string {
    return this.absolutePath;
  }

  setAbsolutePath(absolutePath: string): AircraftImage {
    this.absolutePath = absolutePath;

    return this;
  }
}
