export enum CreationTypeEnum {
  AUTOMATED = 'AUTOMATED',
  AUTOMATED_FOR_PREVIEW_ORG = 'AUTOMATED_FOR_PREVIEW_ORG',
  ELAC_PRO_FORMA = 'ELAC_PRO_FORMA',
  Manual = 'MANUAL',
  SEARCH_OPTIONS = 'SEARCH_OPTIONS',
  XO_DEDICATED_FLEET = 'XO_DEDICATED_FLEET',
  AUTOMATED_CAMBER = "AUTOMATED_CAMBER",
  MANUAL_CAMBER = "MANUAL_CAMBER",
  EXPLORER = "EXPLORER",
  EXPLORER_GUARANTEED_PRICE = "EXPLORER_GUARANTEED_PRICE",
}