import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const addClient = createAction(
  '[Client.GroupMembership] Add Client',
  props<{ payload: models.Client }>()
);

export const removeClient = createAction(
  '[Client.GroupMembership] Remove Client',
  props<{ payload: models.Client }>()
);

export const removeClientService = createAction(
  '[Client.GroupMembership] Remove Client Service',
  props<{ payload: models.Client }>()
);

export const load = createAction('[Client.GroupMembership] Load', props<{ payload: number }>());
export const loadSuccess = createAction(
  '[Client.GroupMembership] Load Success',
  props<{ payload: models.GroupMembership }>()
);

export const undoChanges = createAction('[Client.GroupMembership] Undo Changes');

export const updateGroupMembership = createAction(
  '[Client.GroupMembership] Update Group Membership',
  props<{ payload: models.GroupMembership }>()
);

export const submitGroupMembership = createAction(
  '[Client.GroupMembership] Submit Group Membership',
  props<{ payload: models.GroupMembership }>()
);
export const submitGroupMembershipSuccess = createAction(
  '[Client.GroupMembership] Submit Group Membership Success',
  props<{ payload: models.GroupMembership }>()
);
export class SubmitGroupMembershipFailPayload {
  constructor(public groupMembership: models.GroupMembership, public errorMessage: string) {}
}
export const submitGroupMembershipFail = createAction(
  '[Client.GroupMembership] Submit Group Membership Fail',
  props<{ payload: SubmitGroupMembershipFailPayload }>()
);
