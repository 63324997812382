import * as models from '../../domain/models';

import * as actions from '../actions/sourcing-request-list';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  criteria: viewModels.SourcingRequestLookupCriteria;
  currentPage: number;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.SourcingRequest>;
  itemsPerPage: number;
  itemsTotalCount: number;
}

const initialState: State = {
  criteria: new viewModels.SourcingRequestLookupCriteria(),
  currentPage: 1,
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 25,
  itemsTotalCount: 0,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET: {
      return { ...initialState };
    }

    case actions.SEARCH: {
      return {
        ...state,
        criteria: _.cloneDeep(action.payload.criteria),
        isLoading: true,
      };
    }

    case actions.SEARCH_SUCCESS: {
      return {
        ...state,
        currentPage: action.payload.page,
        isLoaded: true,
        isLoading: false,
        items: action.payload.data,
        itemsTotalCount: action.payload.count,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCriteria = (state: State) => state.criteria;
export const getCurrentPage = (state: State) => state.currentPage;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
