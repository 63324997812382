import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export const LOAD = '[RM Change Log Collection] Load';
export const LOAD_SUCCESS = '[RM Change Log Collection] Load Success';
export const LOAD_FAIL = '[RM Change Log Collection] Load Fail';
export const RESET_STATE = '[RM Change Log Collection] Reset State';

export class LoadPayload {
  skip: number;
  take: number;
  sort: Array<SortDescriptor>;
  type?: string;
  filter?: any;

  constructor() {
    this.skip = 0;
    this.take = 15;
    this.sort = [{field: 'changeTime', dir: 'desc'}];
  }
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {
  }
}

export class LoadSuccessPayload {
  constructor(public items: Array<any>, public total: number) {
  }
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class ResetStateAction implements Action {
  readonly type = RESET_STATE;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | ResetStateAction;
