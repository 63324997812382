export const response = {
  peakDays: {
    SELECT: [
      '2022-03-13',
      '2022-03-19',
      '2022-03-20',
      '2022-03-26',
      '2022-03-27',
      '2022-04-07',
      '2022-04-10',
      '2022-04-18',
      '2022-11-19',
      '2022-11-20',
      '2022-01-01',
      '2022-01-02',
      '2022-01-03',
      '2022-01-17',
      '2022-02-17',
      '2022-02-18',
      '2022-02-21',
      '2022-02-22',
      '2022-04-17',
      '2022-11-22',
      '2022-11-23',
      '2022-11-26',
      '2022-11-27',
      '2022-12-16',
      '2022-12-17',
      '2022-12-18',
      '2022-12-25',
      '2022-12-26',
      '2022-12-27',
      '2022-12-28',
    ],
    ELAC: [
      '2020-02-13',
      '2020-02-14',
      '2020-02-17',
      '2020-02-18',
      '2020-04-12',
      '2020-11-24',
      '2020-11-25',
      '2020-11-28',
      '2020-11-29',
      '2020-12-18',
      '2020-12-19',
      '2020-12-20',
      '2020-12-25',
      '2020-12-26',
      '2020-12-27',
      '2020-12-28',
      '2021-01-01',
      '2021-01-02',
      '2021-01-03',
      '2021-01-18',
      '2021-02-11',
      '2021-02-12',
      '2021-02-15',
      '2021-02-16',
      '2021-04-04',
      '2021-11-23',
      '2021-11-24',
      '2021-11-27',
      '2021-11-28',
      '2021-12-17',
      '2021-12-18',
      '2021-12-19',
      '2021-12-25',
      '2021-12-26',
      '2021-12-27',
      '2021-12-28',
      '2021-09-01',
      '2021-09-04',
      '2021-09-05',
      '2022-01-01',
      '2022-01-02',
      '2022-01-03',
      '2022-01-17',
      '2022-02-17',
      '2022-02-18',
      '2022-02-21',
      '2022-02-22',
      '2022-04-17',
      '2022-11-22',
      '2022-11-23',
      '2022-11-26',
      '2022-11-27',
      '2022-12-16',
      '2022-12-17',
      '2022-12-18',
      '2022-12-25',
      '2022-12-26',
      '2022-12-27',
      '2022-12-28',
    ],
    SIGNATURE: [
      '2022-01-01',
      '2022-01-02',
      '2022-01-03',
      '2022-01-17',
      '2022-02-17',
      '2022-02-18',
      '2022-02-21',
      '2022-02-22',
      '2022-04-17',
      '2022-11-22',
      '2022-11-23',
      '2022-11-26',
      '2022-11-27',
      '2022-12-16',
      '2022-12-17',
      '2022-12-18',
      '2022-12-25',
      '2022-12-26',
      '2022-12-27',
      '2022-12-28',
    ],
  },
};
