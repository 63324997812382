import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/task-collection.actions';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const taskCollectionFeatureKey = 'taskCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.Task>;
  prevState: models.StateEnum;
  criteria: models.TaskLookupCriteria;
  offset: number;
  limit: number;
  sortOrder: models.SortOrderEnum;
  sortBy: models.SortByEnum;
  number: number;
  totalElements: number;
  totalPages: number;
  taskLogs: Array<models.TaskLog>;
  taskLogsLoading: boolean;
  taskLogsLoaded: boolean;
  relatedLegs: models.RelatedLegs;
  relatedLegsLoading: boolean;
  relatedLegsLoaded: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  prevState: null,
  offset: 0,
  limit: 20,
  criteria: new models.TaskLookupCriteria(),
  sortOrder: models.SortOrderEnum.asc,
  sortBy: models.SortByEnum.dueDate,
  number: 0,
  totalElements: 0,
  totalPages: 0,
  taskLogs: [],
  taskLogsLoading: false,
  taskLogsLoaded: false,
  relatedLegs: null,
  relatedLegsLoading: false,
  relatedLegsLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.load,
    (
      state,
      { payload: { criteria, limit, offset, sortBy, sortOrder, isNotUpdateCriteria } }
    ): State => {
      if (isNotUpdateCriteria) {
        return { ...state, loading: true };
      }
      return {
        ...state,
        loading: true,
        criteria,
        offset,
        limit,
        sortBy,
        sortOrder,
        number: offset / limit,
      };
    }
  ),
  on(
    actions.loadSuccess,
    (state, { payload: { items, number, totalElements } }): State => ({
      ...state,
      loaded: true,
      loading: false,
      number,
      totalElements,
      items,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  ),

  on(actions.setPods, (state, { payload }): State => {
    const pod = payload.join(',');
    return {
      ...state,
      criteria: {
        ...new models.TaskLookupCriteria(),
        pod,
      },
    };
  }),

  on(actions.setState, (state, { payload: { taskId } }): State => {
    const index = state.items.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      const prevState: models.StateEnum = clone[index].state.taskStateId;
      clone[index].state.taskStateId = models.StateEnum.LOADING;
      return {
        ...state,
        prevState,
        items: clone,
      };
    }
    return {
      ...state,
    };
  }),
  on(actions.setStateSuccess, (state, { payload: { task } }): State => {
    const index = state.items.findIndex((e) => e.taskId === task.taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      clone[index] = task;
      return {
        ...state,
        items: clone,
        prevState: null,
      };
    }
    return {
      ...state,
      prevState: null,
    };
  }),
  on(actions.setStateFail, (state, { payload: { taskId } }): State => {
    const index = state.items.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      clone[index].state.taskStateId = state.prevState;
    }
    return {
      ...state,
      items: clone,
      prevState: null,
    };
  }),

  on(actions.revertState, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    let prevState: models.StateEnum;
    taskIds.forEach((taskId) => {
      const index = state.items.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        prevState = clone[index].state.taskStateId;
        clone[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      items: clone,
    };
  }),
  on(actions.revertStateSuccess, (state, { payload: { tasks } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    tasks.forEach((task) => {
      const index = state.items.findIndex((e) => e.taskId === task.taskId);
      if (index !== -1) {
        clone[index] = task;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),
  on(actions.revertStateFail, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    taskIds.forEach((taskId) => {
      const index = state.items.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clone[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),

  on(actions.setStateForMultipleTask, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    let prevState: models.StateEnum;
    taskIds.forEach((id) => {
      const index = state.items.findIndex((e) => e.taskId === id);
      if (index !== -1) {
        prevState = clone[index].state.taskStateId;
        clone[index].state.taskStateId = models.StateEnum.LOADING;
      }
    });
    return {
      ...state,
      prevState,
      items: clone,
    };
  }),
  on(actions.setStateForMultipleTaskSuccess, (state, { payload: { tasks } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    tasks.forEach((task) => {
      const index = state.items.findIndex((e) => e.taskId === task.taskId);
      if (index !== -1) {
        clone[index] = task;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),
  on(actions.setStateForMultipleTaskFail, (state, { payload: { taskIds } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    taskIds.forEach((taskId) => {
      const index = state.items.findIndex((e) => e.taskId === taskId);
      if (index !== -1) {
        clone[index].state.taskStateId = state.prevState;
      }
    });
    return {
      ...state,
      items: clone,
    };
  }),

  on(
    actions.loadTaskLogs,
    (state): State => ({
      ...state,
      taskLogsLoading: true,
      taskLogsLoaded: false,
    })
  ),
  on(
    actions.loadTaskLogsSuccess,
    (state, { payload: { logs } }): State => ({
      ...state,
      taskLogsLoading: false,
      taskLogsLoaded: true,
      taskLogs: logs,
    })
  ),

  on(actions.createNewNoteSuccess, (state, { payload: { taskId, note } }): State => {
    const index = state.items.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      clone[index].notes.unshift(note);
      return {
        ...state,
        items: clone,
      };
    }
    return {
      ...state,
    };
  }),

  on(actions.createNewTaskSuccess, (state, { payload: { task } }): State => {
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    clone.push(task);
    return {
      ...state,
      items: clone,
    };
  }),

  on(actions.updateNoteSuccess, (state, { payload: { taskId, note } }): State => {
    const index = state.items.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      const noteIndex = clone[index].notes.findIndex((e) => note.id === e.id);
      clone[index].notes[noteIndex] = note;
      return {
        ...state,
        items: clone,
      };
    }
    return {
      ...state,
    };
  }),

  on(actions.deleteNoteSuccess, (state, { payload: { taskId, id } }): State => {
    const index = state.items.findIndex((e) => e.taskId === taskId);
    const clone: Array<models.Task> = _.cloneDeep(state.items);
    if (index !== -1) {
      const noteIndex = clone[index].notes.findIndex((e) => id === e.id);
      clone[index].notes.splice(noteIndex, 1);
      return {
        ...state,
        items: clone,
      };
    }
    return {
      ...state,
    };
  }),

  on(
    actions.resetTaskLogs,
    (state): State => ({
      ...state,
      taskLogsLoaded: false,
      taskLogsLoading: false,
      taskLogs: [],
    })
  ),

  on(
    actions.setInitial,
    (state): State => ({
      ...state,
      loaded: false,
      loading: false,
      prevState: null,
      items: [],
      taskLogs: [],
      taskLogsLoading: false,
      taskLogsLoaded: false,
    })
  ),

  on(
    actions.getRelatedLegs,
    (state): State => ({
      ...state,
      relatedLegsLoading: true,
      relatedLegsLoaded: false,
    })
  ),
  on(
    actions.getRelatedLegsSuccess,
    (state, { payload: { relatedLegs } }): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
      relatedLegs,
    })
  ),
  on(
    actions.getRelatedLegsFail,
    (state): State => ({
      ...state,
      relatedLegsLoading: false,
      relatedLegsLoaded: true,
    })
  ),

  on(
    actions.updateRelatedLegs,
    (state): State => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(actions.updateRelatedLegsSuccess, (state, { payload: { pod } }): State => {
    const items = state.items.map((task) => {
      return { ...task, leg: { ...task.leg, pod } };
    });
    return {
      ...state,
      loading: false,
      loaded: true,
      items,
    };
  }),
  on(
    actions.updateRelatedLegsFail,
    (state): State => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(
    actions.finalizeLeg,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    actions.finalizeLegFail,
    (state): State => ({
      ...state,
      loaded: true,
      loading: false,
    })
  )
);
