import * as _ from 'lodash';

import * as models from '../../domain/models';

export const percentage = {
  'BUY AS YOU FLY': '0',
  RISE: '0',
  SELECT: '1-2',
  SIGNATURE: '1-4',
  ELITE: '1',
};

export class PricingComparisonMapper {
  static parsePrice(item: any): models.MembershipPricingComparisonPrice {
    const p = new models.MembershipPricingComparisonPrice();
    p.id = _.get(item, 'id', null);
    p.membershipDetails = this.parseMembershipDetails(_.get(item, 'membershipDetails', null));
    p.recommended = _.get(item, 'recommended', false);
    p.offFleetCredit = _.get(item, 'offFleetCredit', null);
    p.onFleetCredit = _.get(item, 'onFleetCredit', null);
    p.routes = _.get(item, 'routes', []).map((i) => this.parseRoute(i));
    p.totalCost = this.parseTotalCost(_.get(item, 'totalCost', null));
    p.lastDayOfIATAFuel = _.get(item, 'lastDayOfIATAFuel', null);
    p.features = _.get(item, 'features', []);
    // p.discountPercentage = _.get(item, 'discountPercentage', null);
    p.discountPercentage = percentage;
    return p;
  }

  static parseMembershipDetails(item: any): models.MembershipPricingComparisonPriceMembershipDetails {
    const d = new models.MembershipPricingComparisonPriceMembershipDetails();
    d.membershipPlan = _.get(item, 'membershipPlan', null);
    d.showLoyaltyCredits = _.get(item, 'showLoyaltyCredits', false);
    d.membershipFee = _.get(item, 'membershipFee', null);
    d.membershipFeePeriod = _.get(item, 'membershipFeePeriod', null);
    d.minimumDeposit = _.get(item, 'minimumDeposit', null);
    d.flightBookingPayments = _.get(item, 'flightBookingPayments', null);
    d.flightBookingPaymentsType = _.get(item, 'flightBookingPaymentsType', null);
    d.benefitAccess = _.get(item, 'benefitAccess', null);
    d.referralProgram = _.get(item, 'referralProgram', null);
    return d;
  }

  static parseRoute(item: any): models.MembershipPricingComparisonPriceRoute {
    const r = new models.MembershipPricingComparisonPriceRoute();
    r.aircraftCategory = _.get(item, 'aircraftCategory', null);
    r.cost = this.parseCost(_.get(item, 'cost', null));
    r.destination = _.get(item, 'destination', null);
    r.id = _.get(item, 'routeId', null);
    r.blockTime = _.get(item, 'blockTime', null);
    r.origin = _.get(item, 'origin', null);
    r.pax = _.get(item, 'pax', null);
    return r;
  }

  static parseCost(item: any): models.MembershipPricingComparisonPriceCost {
    const c = new models.MembershipPricingComparisonPriceCost();
    c.high = _.get(item, 'high', null);
    c.highHourlyAvg = _.get(item, 'highHourlyAvg', null);
    c.low = _.get(item, 'low', null);
    c.lowHourlyAvg = _.get(item, 'lowHourlyAvg', null);
    c.discountValueLow = _.get(item, 'discountValueLow', null);
    c.discountValueHigh = _.get(item, 'discountValueHigh', null);
    c.lowWithDiscount = _.get(item, 'lowWithDiscount', null);
    c.fetTax = _.get(item, 'fetTax', null);
    c.fetTaxPercent = _.get(item, 'fetTaxPercent', null);
    c.fuelCost = _.get(item, 'fuelCost', null);
    c.nonMemberFee = _.get(item, 'nonMemberFee', null);
    c.priceWithoutServiceFee = _.get(item, 'priceWithoutServiceFee', null);
    c.segmentFee = _.get(item, 'segmentFee', null);
    c.serviceFee = _.get(item, 'serviceFee', null);
    return c;
  }

  static parseTotalCost(item: any): models.MembershipPricingComparisonPriceTotalCost {
    const c = new models.MembershipPricingComparisonPriceTotalCost();
    c.high = _.get(item, 'high', null);
    c.highHourlyAvg = _.get(item, 'highHourlyAvg', null);
    c.low = _.get(item, 'low', null);
    c.lowHourlyAvg = _.get(item, 'lowHourlyAvg', null);
    c.discountValueLow = _.get(item, 'discountValueLow', null);
    c.discountValueHigh = _.get(item, 'discountValueHigh', null);
    c.lowWithDiscount = _.get(item, 'lowWithDiscount', null);
    c.fetTax = _.get(item, 'fetTax', null);
    c.fetTaxPercent = _.get(item, 'fetTaxPercent', null);
    c.fuelCost = _.get(item, 'fuelCost', null);
    c.nonMemberFee = _.get(item, 'nonMemberFee', null);
    c.priceWithoutServiceFee = _.get(item, 'priceWithoutServiceFee', null);
    c.segmentFee = _.get(item, 'segmentFee', null);
    c.serviceFee = _.get(item, 'serviceFee', null);
    return c;
  }
}
