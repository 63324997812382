import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const RESET = '[FlightRequest.SourcingRequestList] Reset';
export const SEARCH = '[FlightRequest.SourcingRequestList] Search';
export const SEARCH_SUCCESS = '[FlightRequest.SourcingRequestList] Load Success';
export const SEARCH_FAIL = '[FlightRequest.SourcingRequestList] Load Fail';
export const REMOVE_SEARCH_ITEM = '[FlightRequest.SourcingRequestList] Remove Search Item';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SearchActionPayload {
  constructor(
    public index: number,
    public leg: models.LegRequest,
    public clientId: number,
  ) {}
}

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: SearchActionPayload) {}
}

export class SearchSuccessActionPayload {
  constructor(
    public data: Array<models.SourcingRequest>,
    public index: number
  ) {}
}

export class SearchSuccessAction implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: SearchSuccessActionPayload) {}
}

export class SearchFailAction implements Action {
  readonly type = SEARCH_FAIL;
}

export class RemoveSearchItemAction implements Action {
  readonly type = REMOVE_SEARCH_ITEM;

  constructor(public payload: number) {}
}

export type Actions = ResetAction
  | SearchAction
  | SearchSuccessAction
  | SearchFailAction
  | RemoveSearchItemAction;
