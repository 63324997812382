import { createSelector } from 'reselect';

import * as fromCore from '../../core/reducers';

import { layoutFeatureKey } from '../reducers/layout.reducer';

export const getLayoutState = createSelector(
  fromCore.getCoreState,
  (state) => state[layoutFeatureKey]
);
export const getLayoutIsAppView = createSelector(getLayoutState, (state) => state.isAppView);
export const getLayoutIsFullHeightView = createSelector(
  getLayoutState,
  (state) => state.isFullHeightView
);
export const getLayoutIsLoading = createSelector(getLayoutState, (state) => state.isLoading);
export const getLayoutIsNamedOutletLoading = createSelector(
  getLayoutState,
  (state) => state.isNamedOutletLoading
);
export const getLayoutType = createSelector(getLayoutState, (state) => state.layoutType);
export const getLayoutServerEnvironment = createSelector(
  getLayoutState,
  (state) => state.serverEnvironment
);
export const getLayoutMenuPin = createSelector(getLayoutState, (state) => state.menuPin);
