<div class="modal-header clearfix text-left">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
          (click)="onClose()"
          kendoTooltip
          tooltipClass="tooltip-style"
          title="Close">
    <i class="fal fa-times fs-14"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row m-t-20">
    <div class="col col-sm-12">
      {{ text }}
      <ng-content></ng-content>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col col-sm-12">
      <button class="btn btn-primary btn-cons pull-right m-r-0"
              (click)="onConfirm()">{{confirmText}}</button>
      <button class="btn btn-default btn-cons pull-right"
              (click)="onClose()">{{cancelText}}</button>
    </div>
  </div>
</div>
