import { OrderItemSerializable } from './order-item-serializable';
import * as models from '../../../../domain/models';

export class FlightPassOrderItemSerializable extends OrderItemSerializable {
  productId: number = null;
  productProductTypeParentProductTypeId: number = null;
  productTypeId: number = null;
  productName: string = null;
  productAmount: number = null;
  promotionAmount: number = null;
  promotionItemId: number = null;
  clientName: string = null;
  sfdcOpportunityId: string = null;
  route: string = null;
  faceValue: number = null;
  qtyOfPasses: number = null
  salesActionId: number = null
  contractCommencementDate: string = null;
  salesAction: models.SalesActionEnum = null;

}
