export const response = {
  canChangePaymentType: true,
  totalPrice: 15488.4,
  user: {
    phone: null,
    email: 'osolovey@jetsmarter.com',
    id: 2,
    firstName: 'Oleg',
    lastName: 'Solovey',
  },
  invoices: [
    {
      replacedInvoiceId: 10403,
      userId: 2,
      legacyFormTplId: 355,
      invoiceTypeName: 'Main Invoice',
      uuid: 'c54c7a4e-49c2-4548-b8d8-50d20fd782a9',
      legacyFormEditUrl: null,
      legacyFormUrlKey: '351e5aa5-6c3d-4d62-a4be-a025a6ee18c1',
      invoiceExternalId: null,
      invoiceReplacements: [],
      invoiceStatusId: 2,
      orderId: 7651,
      invoiceId: 10404,
      createTime: '2019-03-17T21:35:35.829548+00:00',
      parentInvoiceId: null,
      orderItemId: 12760,
      legacyFormUrl:
        'http://console.vista.studio/forms/charterjs_mp/?351e5aa5-6c3d-4d62-a4be-a025a6ee18c1',
      clientId: 1618854,
      invoiceTypeId: 1,
      amount: 15488.4,
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-10-18T18:17:41.229239+00:00',
        membershipTimeStarted: null,
        name: 'serge051u36 ali baba maharash',
        futureMembershipTimeStarted: null,
        dob: '1991-01-01',
        membershipTotal: null,
        membershipDisplayName: null,
        membershipTypeId: null,
        futureMembershipTypeId: null,
        phone: 51,
        futureMembershipTimeExpiring: null,
        email: 'serge051u36@e.com',
        brokerid: 0,
        membershipTimeExpiring: null,
        legalName: 'serge051u36 ali baba maharash',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1618854,
      },
      orderSourceId: 1,
      replacingInvoiceId: null,
    },
    {
      replacedInvoiceId: 10403,
      userId: 2,
      legacyFormTplId: 356,
      invoiceTypeName: 'Payment Coupon',
      uuid: 'cfcae1cd-17bc-4ebf-9fdc-a073713c30c5',
      legacyFormEditUrl: null,
      legacyFormUrlKey: 'c9a2019a-3998-4dda-91fa-b318153b3f54',
      invoiceExternalId: null,
      invoiceReplacements: [],
      invoiceStatusId: 2,
      orderId: 7651,
      invoiceId: 10405,
      createTime: '2019-03-17T21:36:27.361660+00:00',
      parentInvoiceId: 10404,
      orderItemId: 12760,
      legacyFormUrl:
        'http://console.vista.studio/forms/charter_coupon/?c9a2019a-3998-4dda-91fa-b318153b3f54',
      clientId: 1618854,
      invoiceTypeId: 2,
      amount: 10000,
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-10-18T18:17:41.229239+00:00',
        membershipTimeStarted: null,
        name: 'serge051u36 ali baba maharash',
        futureMembershipTimeStarted: null,
        dob: '1991-01-01',
        membershipTotal: null,
        membershipDisplayName: null,
        membershipTypeId: null,
        futureMembershipTypeId: null,
        phone: 51,
        futureMembershipTimeExpiring: null,
        email: 'serge051u36@e.com',
        brokerid: 0,
        membershipTimeExpiring: null,
        legalName: 'serge051u36 ali baba maharash',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1618854,
      },
      orderSourceId: 1,
      replacingInvoiceId: null,
    },
    {
      replacedInvoiceId: 10403,
      userId: 2,
      legacyFormTplId: 356,
      invoiceTypeName: 'Payment Coupon',
      uuid: 'b7a57689-9561-40c8-b630-06df07cc71e5',
      legacyFormEditUrl: null,
      legacyFormUrlKey: 'ba53ee80-51a0-4a54-bee8-318b81757b4b',
      invoiceExternalId: null,
      invoiceReplacements: [],
      invoiceStatusId: 2,
      orderId: 7651,
      invoiceId: 10406,
      createTime: '2019-03-17T21:37:03.462580+00:00',
      parentInvoiceId: 10404,
      orderItemId: 12760,
      legacyFormUrl:
        'http://console.vista.studio/forms/charter_coupon/?ba53ee80-51a0-4a54-bee8-318b81757b4b',
      clientId: 1618854,
      invoiceTypeId: 2,
      amount: 5488.4,
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-10-18T18:17:41.229239+00:00',
        membershipTimeStarted: null,
        name: 'serge051u36 ali baba maharash',
        futureMembershipTimeStarted: null,
        dob: '1991-01-01',
        membershipTotal: null,
        membershipDisplayName: null,
        membershipTypeId: null,
        futureMembershipTypeId: null,
        phone: 51,
        futureMembershipTimeExpiring: null,
        email: 'serge051u36@e.com',
        brokerid: 0,
        membershipTimeExpiring: null,
        legalName: 'serge051u36 ali baba maharash',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1618854,
      },
      orderSourceId: 1,
      replacingInvoiceId: null,
    },
  ],
  items: [
    {
      orderId: 7651,
      parentOrderItem: null,
      totalPrice: 15488.4,
      promotionAmount: null,
      orderItemTypeId: 11,
      notes: null,
      membershipRequest: {
        orderItemId: 12760,
        salesAction: {
          name: 'New',
          id: 1,
        },
        productName: 'Charter Flight',
        expirationTime: '2019-04-01T20:50:39.663777+00:00',
        expired: false,
        createTime: '2019-03-17T20:50:41.354510+00:00',
      },
      id: 12760,
      product: {
        productId: 114,
        productName: 'Charter Flight',
      },
      childOrderItems: [],
      charterInfo: {
        purchaseOrderNumber: null,
        requestUrl: 'http://console.vista.studio/#fl:12118676',
        departureDate: '2019-02-16T12:00:00',
        requestId: 12118676,
      },
      client: {
        futureMembershipTotal: null,
        clientCreateTime: '2018-10-18T18:17:41.229239+00:00',
        membershipTimeStarted: null,
        name: 'serge051u36 ali baba maharash',
        futureMembershipTimeStarted: null,
        dob: '1991-01-01',
        membershipTotal: null,
        membershipDisplayName: null,
        membershipTypeId: null,
        futureMembershipTypeId: null,
        phone: 51,
        futureMembershipTimeExpiring: null,
        email: 'serge051u36@e.com',
        brokerid: 0,
        membershipTimeExpiring: null,
        legalName: 'serge051u36 ali baba maharash',
        personalManager: 1000001,
        futureMembershipDisplayName: null,
        id: 1618854,
      },
      basePrice: 15488.4,
      conciergeInfo: null,
      promotionItemId: null,
      quantity: 1,
    },
  ],
  notes: null,
  id: 7651,
  orderStatusId: 6,
  sapExportStatus: [
    {
      statusId: 5,
      statusDescription: '',
      submissionTypeId: 1
    }
  ],
  partyId: 1618854,
  orderBilling: {
    client: {
      futureMembershipTotal: null,
      clientCreateTime: '2018-10-18T18:17:41.229239+00:00',
      membershipTimeStarted: null,
      name: 'serge051u36 ali baba maharash',
      futureMembershipTimeStarted: null,
      dob: '1991-01-01',
      membershipTotal: null,
      membershipDisplayName: null,
      membershipTypeId: null,
      futureMembershipTypeId: null,
      phone: 51,
      futureMembershipTimeExpiring: null,
      email: 'serge051u36@e.com',
      brokerid: 0,
      membershipTimeExpiring: null,
      legalName: 'serge051u36 ali baba maharash',
      personalManager: 1000001,
      futureMembershipDisplayName: null,
      id: 1618854,
    },
  },
  expirationTime: '2019-04-01T20:50:39.663777+00:00',
  promotionId: null,
  expired: false,
  createTime: '2019-03-17T20:50:40.757050+00:00',
  orderSourceId: 1,
  charterSalesOpportunityId: 'c84dfd8d-4884-475d-be0c-448f2626db56',
};
