import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction(
  '[UserMgmt.UsersCollection] Load',
  props<{ payload: models.User }>()
);
export const loadSuccess = createAction(
  '[UserMgmt.UsersCollection] Load Success',
  props<{ payload: Array<models.User> }>()
);
export const loadFail = createAction('[UserMgmt.UsersCollection] Load Fail');

export const reset = createAction('[UserMgmt.UsersCollection] Reset');

export class SavePayload {
  users: Array<models.User>;
}
export const save = createAction(
  '[UserMgmt.UsersCollection] Save',
  props<{ payload: SavePayload }>()
);
export const saveSuccess = createAction('[UserMgmt.UsersCollection] Save Success');
export const saveFail = createAction('[UserMgmt.UsersCollection] Save Fail');

export const saveActiveStatus = createAction(
  '[UserMgmt.UsersCollection] Save Active Status',
  props<{ payload: models.ActiveStatus }>()
);
export const saveActiveStatusSuccess = createAction(
  '[UserMgmt.UsersCollection] Save Active Status Success'
);
export const saveActiveStatusFail = createAction(
  '[UserMgmt.UsersCollection] Save Active Status Fail'
);
