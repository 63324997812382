import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/opportunity-new.actions';
import * as lodash from 'lodash';
import * as models from '../../domain/models';

export const opportunityNewFeatureKey = 'opportunityNew';

const getInitialOpportunity = (): models.Opportunity => {
  const o1 = new models.Opportunity();
  o1.salesQuoteRequests = [new models.SalesQuoteRequest()];
  return o1;
};

export interface State {
  opportunity: models.Opportunity;
  duplicateCheck: boolean;
  duplicateContext: models.DuplicateContext;
  selectedLegRequestIndex: number;
  isLoading: boolean;
}

const initialState: State = {
  opportunity: getInitialOpportunity(),
  duplicateCheck: null,
  duplicateContext: null,
  selectedLegRequestIndex: null,
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.reset,
    actions.submitSuccess,
    (): State => ({
      ...initialState,
    })
  ),
  on(actions.setClient, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity).setClient(payload);
    o2.getSalesQuoteRequests()[0].setClient(payload);
    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(actions.setSalesAgentEmailAddress, (state, { emailAddress }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);

    o2.salesAgent = new models.User();
    o2.salesAgent.emailAddress = emailAddress;

    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(actions.setZendeskTicket, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);
    o2.zendeskIntegration.zendeskTicketId = payload;

    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(
    actions.promptDuplicateCheck,
    (state, { payload: { duplicateCheck, duplicateContext } }): State => ({
      ...state,
      duplicateCheck,
      duplicateContext,
    })
  ),
  on(
    actions.promptDuplicateCheckCancel,
    (state): State => ({
      ...state,
      duplicateCheck: null,
      duplicateContext: null,
      isLoading: false,
    })
  ),
  on(actions.setZendeskUser, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);
    o2.zendeskIntegration.zendeskUserId = payload;

    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(
    actions.selectLegRequest,
    (state, { payload }): State => ({
      ...state,
      selectedLegRequestIndex: payload,
    })
  ),
  on(actions.updateOpportunityNote, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);
    o2.notes = payload;
    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(actions.updateOpportunitySubCharter, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);
    o2.subCharter = payload;
    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(actions.updateRequest, (state, { payload }): State => {
    const o2 = lodash.cloneDeep(state.opportunity);
    o2.salesQuoteRequests = [payload];

    return {
      ...state,
      opportunity: o2,
    };
  }),
  on(
    actions.submit,
    actions.loadClientMembership,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    actions.submitFail,
    actions.loadClientMembershipFail,
    actions.loadClientMembershipSuccess,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  )
);
