import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfigService, SERVICE_TOKEN } from '../../../../../core/services';
import { FinalizedLegsResponse, IFinanceService } from '../../../domain/service-contracts/finance';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinalizedLegsResponseMapper } from './mappers/finalized-legs-response-mapper';

@Injectable({
  providedIn: 'root',
})
export class FinanceService implements IFinanceService {
  private get API_PATH(): string {
    return `${this.configService.get('OrderServiceEndpoint')}/v1.0/finance`;
  }

  constructor(
    private http: HttpClient,
    @Inject(SERVICE_TOKEN) private configService: IConfigService
  ) {}

  getFinalizedLegsByFlightRequest(
    flightRequestId: number
  ): Observable<FinalizedLegsResponse | void> {
    const endpoint = `${this.API_PATH}/flight-request/${flightRequestId}/finalized-legs`;

    return this.http.get(endpoint).pipe(
      map((response: any) => {
        if (response) {
          return FinalizedLegsResponseMapper.fromJson(response.data);
        }
      })
    );
  }
}
