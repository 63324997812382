import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/order-collection.actions';
// TODO: This is wrong, should use domain model
import * as viewModels from '../../view/view-models';

import * as _ from 'lodash';

export const orderCollectionFeatureKey = 'orderCollection';

export interface State {
  currentPage: number;
  isLoaded: boolean;
  isLoading: boolean;
  isDownloading: boolean;
  canDownload: boolean;
  // TODO: Model here, not View Model
  items: Array<viewModels.OrderVM>;
  itemsPerPage: number;
  itemsTotalCount: number;
  errorMessage: string;
}

const initialState: State = {
  currentPage: 1,
  isLoaded: false,
  isLoading: false,
  isDownloading: false,
  canDownload: false,
  items: [],
  itemsPerPage: 15,
  itemsTotalCount: 0,
  errorMessage: null,
};

export const reducer = createReducer(
  initialState,

  on(actions.activateMembershipSuccess, (state, { payload }): State => {
    const items = _.cloneDeep(state.items);

    const order = items.find((item) => item.id === payload.orderId);
    // const orderItem = order.cellItems.find((item) => item.orderItemId === payload.orderItemId);
    // const orderItem = order.items.find(item => item.id === action.payload.orderItemId);
    // TODO: implement ACTIVATE_MEMBERSHIP_SUCCESS reducer
    // orderItem.membership = action.payload.membership;
    order.orderStatusId = payload.orderStatusId;
    order.orderStatusName = payload.orderStatusName;

    return {
      ...state,
      items,
      errorMessage: null,
    };
  }),
  on(actions.activateMembershipFail, (state, { payload }): State => {
    const items = _.cloneDeep(state.items);

    items.forEach((order: viewModels.OrderVM) => {
      order.cellItems.forEach((cellItem: viewModels.OrderCellItemVM) => {
        cellItem.activateButtonDisabled = false;
        cellItem.activateButtonCaption = 'Activate';
      });
    });

    return {
      ...state,
      items,
      errorMessage: payload.errorMessage,
    };
  }),

  on(actions.cancelOrderSuccess, (state, { payload }): State => {
    const items = _.cloneDeep(state.items);

    const order = items.find((item) => item.id === payload.orderId);
    order.orderStatusId = payload.orderStatusId;
    order.orderStatusName = payload.orderStatusName;

    return {
      ...state,
      items,
      errorMessage: null,
    };
  }),
  on(actions.cancelOrderFail, (state, { payload }): State => {
    const items = _.cloneDeep(state.items);

    items.forEach((order: viewModels.OrderVM) => {
      order.cancelButtonDisabled = false;
      order.cancelButtonCaption = 'Cancel';
    });

    return {
      ...state,
      items,
      errorMessage: payload.errorMessage,
    };
  }),

  on(
    actions.load,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      currentPage: payload.page,
      isLoaded: true,
      isLoading: false,
      isDownloading: false,
      canDownload: payload.canDownload,
      items: payload.data,
      itemsPerPage: state.itemsPerPage,
      itemsTotalCount: payload.count,
      errorMessage: null,
    })
  ),
  on(
    actions.loadFail,
    (state): State => ({
      ...state,
      isLoaded: true,
      isLoading: false,
      isDownloading: false,
    })
  ),

  on(
    actions.download,
    (state): State => ({
      ...state,
      isDownloading: true,
    })
  ),
  on(
    actions.downloadSuccess,
    actions.downloadFail,
    (state): State => ({
      ...state,
      isDownloading: false,
    })
  ),

  on(
    actions.resetItems,
    (state): State => ({
      ...state,
      items: [],
    })
  )
);
