import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/opportunity-new-pc.actions';
import * as coreModels from '../../../../core/models';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export const opportunityNewPcFeatureKey = 'opportunityNewPc';

export interface State {
  pricingCalculatorOpportunity: coreModels.PricingCalculatorOpportunity;
  sameAndSimilar: Array<models.SameAndSimilarData>;
  isSameAndSimilarLoading: boolean;
}

const initialState: State = {
  pricingCalculatorOpportunity: null,
  sameAndSimilar: null,
  isSameAndSimilarLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    actions.setPricingCalculatorOpportunity,
    (state, { payload: { pricingCalculatorOpportunity } }): State => ({
      ...state,
      pricingCalculatorOpportunity,
    })
  ),

  on(
    actions.getSameAndSimilar,
    (state): State => ({
      ...state,
      isSameAndSimilarLoading: true,
    })
  ),
  on(actions.getSameAndSimilarSuccess, (state, { payload }): State => {
    const { items } = payload;
    const sameAndSimilar: Array<models.SameAndSimilarData> = _.cloneDeep(items);
    return { ...state, isSameAndSimilarLoading: false, sameAndSimilar };
  }),
  on(
    actions.getSameAndSimilarFail,
    (state): State => ({
      ...state,
      isSameAndSimilarLoading: false,
    })
  ),
  on(
    actions.setInitialState,
    (): State => ({
      ...initialState,
    })
  )
);
