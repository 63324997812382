export class AppClient {
  activationLink: string = null;
  activationLinkExpirationTime: Date = null;
  activationLinkExpired: boolean = null;
  appRegDate: Date = null;
  appName: string = null;
  dba: string = null;
  appCurrentTier: string = null;
  appEmail: string = null;
  proxyClient: boolean = null;
  clientId: number = null;
  activationState: boolean = null;
  personalManager: string = null;
  appMemberSince: Date = null;
  regSource: string = null;
  appPastTiers: string = null;
  appPhone: string = null;

  getActivationLink(): string {
    return this.activationLink;
  }

  setActivationLink(activationLink): AppClient {
    this.activationLink = activationLink;
    return this;
  }

  getActivationLinkExpirationTime(): Date {
    return this.activationLinkExpirationTime;
  }

  setActivationLinkExpirationTime(activationLinkExpirationTime): AppClient {
    this.activationLinkExpirationTime = activationLinkExpirationTime;
    return this;
  }

  getActivationLinkExpired(): boolean {
    return this.activationLinkExpired;
  }

  setActivationLinkExpired(activationLinkExpired): AppClient {
    this.activationLinkExpired = activationLinkExpired;
    return this;
  }

  getAppRegDate(): Date {
    return this.appRegDate;
  }

  setAppRegDate(appRegDate): AppClient {
    this.appRegDate = appRegDate;
    return this;
  }

  getAppName(): string {
    return this.appName;
  }

  setAppName(appName): AppClient {
    this.appName = appName;
    return this;
  }

  getDba(): string {
    return this.dba;
  }

  setDba(dba): AppClient {
    this.dba = dba;
    return this;
  }

  getAppCurrentTier(): string {
    return this.appCurrentTier;
  }

  setAppCurrentTier(appCurrentTier): AppClient {
    this.appCurrentTier = appCurrentTier;
    return this;
  }

  getAppEmail(): string {
    return this.appEmail;
  }

  setAppEmail(appEmail): AppClient {
    this.appEmail = appEmail;
    return this;
  }

  getClientId(): number {
    return this.clientId;
  }

  setClientId(clientId): AppClient {
    this.clientId = clientId;
    return this;
  }

  getActivationState(): boolean {
    return this.activationState;
  }

  setActivationState(activationState): AppClient {
    this.activationState = activationState;
    return this;
  }

  getPersonalManager(): string {
    return this.personalManager;
  }

  setPersonalManager(personalManager): AppClient {
    this.personalManager = personalManager;
    return this;
  }

  getAppMemberSince(): Date {
    return this.appMemberSince;
  }

  setAppMemberSince(appMemberSince): AppClient {
    this.appMemberSince = appMemberSince;
    return this;
  }

  getRegSource(): string {
    return this.regSource;
  }

  setRegSource(regSource): AppClient {
    this.regSource = regSource;
    return this;
  }

  getAppPastTiers(): string {
    return this.appPastTiers;
  }

  setAppPastTiers(appPastTiers): AppClient {
    this.appPastTiers = appPastTiers;
    return this;
  }

  getAppPhone(): string {
    return this.appPhone;
  }

  setAppPhone(appPhone): AppClient {
    this.appPhone = appPhone;
    return this;
  }
}
