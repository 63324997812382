import { ArgusRating, IsBaoRating, WyvernRating } from './rating';

export class Operator {
  id: string = null;
  name: string = null;
  argusRating: ArgusRating = null;
  wyvernRating: WyvernRating = null;
  isBaoRating: IsBaoRating = null;
  note: string = null;
  cancellationTerms: string = null;
  email: string = null;
  phone: string = null;

  equals(o1: Operator): boolean {
    if (!o1) {
      return false;
    }

    return this.id === o1.id;
  }
}
