<div class="full-height">
  <!-- BEGIN APP -->

  <!-- BEGIN SIDEBAR -->
<!--  ToDo Update after implementing auth0 authorization in production-->
  <app-sidebar [menuLinks]="menuItems$ | async"
               *ngIf="!isLoginPage && (auth.isAuthenticated$ | async)"
               [activeRoute]="activeRoute$"
               [menuPinThreeWay]="menuPinThreeWay$ | async"
               [userName]="userName$ | async"
               [environmentName]="environmentName$ | async"
               (signOut)="signOut()"
               (toggleMenuDrawer)="toggleMenuDrawer()"
               (toggleMenu)="toggleMenuPin()"></app-sidebar>
  <!-- END SIDEBAR -->

  <!-- START PAGE-CONTAINER -->
  <page-container [ngClass]="{'overflow-block' : secondaryToggle }">
    <!-- START PAGE HEADER WRAPPER -->
<!--    ToDo Update after implementing auth0 authorization in production-->
    <ng-container *ngIf="!helpers.isLegacyAuth(); else legacyHeader">
      <auth0-header class="header"
                    [applicationName]="'Requests'"
                    [environmentName]="environmentName$ | async"
                    [appSwitcherIsVisible]="true"
                    *ngIf="(isAppView$ | async) === false && !isLoginPage && (auth.isAuthenticated$ | async)"
                    (signOut)="signOut()"
                    (appSwitcher)="appSwitcher($event)"
                    (toggleMobileSidebar)="toggleMobileSidebar()"
                    [userName]="userName$ | async"
                    [logoutUrl]="logoutUrl$ | async">
      </auth0-header>
    </ng-container>
<!--    ToDo Remove after implementing auth0 authorization in production   -->
    <ng-template #legacyHeader>
      <legacy-header class="header"
                     [applicationName]="'Requests'"
                     [environmentName]="environmentName$ | async"
                     [appSwitcherIsVisible]="true"
                     *ngIf="(isAppView$ | async) === false"
                     (signOut)="signOut()"
                     (appSwitcher)="appSwitcher($event)"
                     (toggleMobileSidebar)="toggleMobileSidebar()"
                     [userName]="userName$ | async"
                     [logoutUrl]="logoutUrl$ | async">
      </legacy-header>
    </ng-template>
    <!-- END PAGE HEADER WRAPPER -->

    <!-- START PAGE CONTENT WRAPPER -->
    <div class="page-content-wrapper"
         [ngClass]="{'app-view':isAppView$ | async, 'full-height': (isFullHeightView$ | async)}">

      <!-- START PAGE CONTENT -->
      <div class="content"
           [ngClass]="{'full-height':(isFullHeightView$ | async)}">
        <spinner class="full-height"
                 *ngIf="isLoading$ | async"></spinner>


        <div class="full-height full-width"
             [hidden]="(outletIsVisible$ | async) === false">
          <router-outlet></router-outlet>
        </div>

        <shared-internal-error class="full-height"
                               [errorText]="errorText$ | async"
                               *ngIf="errorIsVisible$ | async"></shared-internal-error>

        <shared-browser-not-supported class="full-height"
                                      *ngIf="browserNowSupported$ | async"></shared-browser-not-supported>

        <access-denied-page *ngIf="(accessDenied$ | async) && !(isDebug$ | async)"></access-denied-page>

        <secondary *ngIf="secondaryToggle"
                   (onClose)="secondaryToggle = false"></secondary>
      </div>
      <!-- END PAGE CONTENT -->
<!--      ToDo Update after implementing auth0 authorization in production-->
      <footer *ngIf="(isAppView$ | async) == false && !isLoginPage"></footer>
    </div>
    <!-- END PAGE CONTENT WRAPPER -->

  </page-container>
  <!-- END PAGE CONTAINER -->
  <!--START QUICKVIEW -->
  <debug *ngIf="isDebug$ | async"
         [environment]="environment"
         [version]="version$ | async"
         [status]="status$ | async"
         [isStatusLoading]="isStatusLoading$ | async "
         [gitEnvVariables]="gitEnvVariables$ | async"
         (statusExpand)="onApiStatusExpand($event)">
  </debug>
  <!-- END QUICKVIEW-->
  <!-- END APP -->
</div>
