// Oleh Kolinko's Code
export class SeatPricingMgmtSortMapper {
  static sortRoutes(routes: Array<string>): Array<string> {
    // STEP 1 create hashMap
    const routesHashMap = {};
    routes.forEach(routeString => {
      const routeArr = routeString.split(' - ');

      if (!routesHashMap[routeArr[0]]) {
        // create empty object on first FROM ocassion
        routesHashMap[routeArr[0]] = {};
      }

      // Add route to hashMap
      routesHashMap[routeArr[0]][routeArr[1]] = true;
    });

    // STEP 2 back to array
    const result = [];
    Object.keys(routesHashMap).sort().forEach(from => {
      Object.keys(routesHashMap[from]).forEach(to => {

        const route = `${from} - ${to}`;
        const reversedRoute = `${to} - ${from}`;

        // skip routes that we already used
        if (routesHashMap[from][to]) {
          result.push(route);

          // if reversed route exists
          if (routesHashMap[to] && routesHashMap[to][from]) {
            result.push(reversedRoute);
            // mark this route that it has been used
            routesHashMap[to][from] = false;
          }
        }
      });
    });

    return result;
  }
}
