import { AircraftAmenity } from './aircraft-amenity';
import { AircraftModel } from './aircraft-model';
import { AircraftTail } from './aircraft-tail';
import { AircraftCategory } from './aircraft-category';
import { Operator } from './operator';
import { AircraftImage } from './aircraft-image';

export class Aircraft {
  tail: AircraftTail = null;
  model: AircraftModel = null;
  category: AircraftCategory = null;
  operator: Operator = null;
  yom: Date = null;
  yor: Date = null;
  maxPax: number = null;
  amenities: Array<AircraftAmenity> = [];
  images: Array<AircraftImage> = [];
  requiresOwnerApproval = false;
  offFleetOperatorIds?: Array<string>;
}
