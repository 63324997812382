import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const reset = createAction('[Client.UniversalClientList] Reset');

export class SearchClientsPayload {
  searchTerm: string;
}
export const searchClients = createAction(
  '[Client.UniversalClientList] Search Clients',
  props<{ payload: SearchClientsPayload }>()
);
export class SearchClientsSuccessPayload {
  data: Array<models.UniversalClient>;
}
export const searchClientsSuccess = createAction(
  '[Client.UniversalClientList] Search Clients Success',
  props<{ payload: SearchClientsSuccessPayload }>()
);
export const searchClientsFail = createAction('[Client.UniversalClientList] Search Clients Fail');

export const setClient = createAction(
  '[Client.UniversalClientList] Set Client',
  props<{ payload: models.UniversalClient }>()
);

export const generateClientActivationLink = createAction(
  '[Client.UniversalClientList] Generate Client Activation Link',
  props<{ payload: string }>()
);
export const generateClientActivationLinkSuccess = createAction(
  '[Client.UniversalClientList] Generate Client Activation Link Success',
  props<{ payload: any }>()
);

export const importClient = createAction(
  '[Client.UniversalClientList] Import Client',
  props<{ payload: models.UniversalClient }>()
);
export const importClientSuccess = createAction(
  '[Client.UniversalClientList] Import Client Success',
  props<{ payload: models.UniversalClient }>()
);
export const importClientFail = createAction('[Client.UniversalClientList] Import Client Fail');

export class LinkToSFDCContactPayload {
  constructor(public clientId: number, public sfdcContactId?: string) {}
}
export const linkToSFDCContact = createAction(
  '[Client.UniversalClientList] Link To SFDC Contact',
  props<{ payload: LinkToSFDCContactPayload }>()
);
export const linkToSFDCContactSuccess = createAction(
  '[Client.UniversalClientList] Link To SFDC Contact Success',
  props<{ payload: models.UniversalClient }>()
);

export const confirmLinkingToSFDCContact = createAction(
  '[Client.UniversalClientList] Confirm Linking To SFDC Contact',
  props<{ payload: LinkToSFDCContactPayload }>()
);
export const confirmLinkingToSFDCContactSuccess = createAction(
  '[Client.UniversalClientList] Confirm Linking To SFDC Contact Success',
  props<{ payload: models.UniversalClient }>()
);

export const cancelLinkingToSFDCContact = createAction(
  '[Client.UniversalClientList] Cancel Linking To SFDC Contact',
  props<{ payload: LinkToSFDCContactPayload }>()
);
export const cancelLinkingToSFDCContactSuccess = createAction(
  '[Client.UniversalClientList] Cancel Linking To SFDC Contact Success',
  props<{ payload: models.UniversalClient }>()
);

export const showError = createAction(
  '[Client.UniversalClientList] Show Error',
  props<{ payload: any }>()
);
