import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as coreTypes from '../../../../../core/types';
import * as contracts from '../../../domain/service-contracts/security';
import * as models from '../../../domain/models';

@Injectable()
export class SecurityService implements contracts.ISecurityService {
  private get API_PATH(): string {
    return this.configService.get('MembershipServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  reset(request: contracts.ResetRequest): Observable<contracts.ResetResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/security/security-code-reset`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(requestUrl, JSON.stringify(request), { headers }).pipe(
      map((svcResp) => {
        const response = new contracts.ResetResponse();

        return response;
      })
    );
  }

  authorize(request: contracts.AuthorizeRequest): Observable<contracts.AuthorizeResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/security/authenticate-override`;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(requestUrl, JSON.stringify(request), { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.AuthorizeResponse();
        response.entities = svcJsonResp.denied.map((d) => {
          const newEntity = new models.AuthorizationRequest();
          newEntity.entityTypeId = d.entityTypeId;
          newEntity.entityId = d.entityId;

          return newEntity;
        });

        return response;
      })
    );
  }
}
