import { createAction } from '@ngrx/store';
import * as utils from '../../../../core/utils';
import * as models from '../../models';

enum Actions {
  LOAD = '[Features.Invoice] Load',
  LOAD_SUCCESS = '[Features.Invoice] Load Success',
  LOAD_FAIL = '[Features.Invoice] Load Fail',
  UPDATE = '[Features.Invoice] Update',
  UPDATE_SUCCESS = '[Features.Invoice] Update Success',
  UPDATE_FAIL = '[Features.Invoice] Update Fail',
  LOAD_PURCHASE_PRICE = '[Features.Invoice] Load Purchase Price',
  LOAD_PURCHASE_PRICE_SUCCESS = '[Features.Invoice] Load Purchase Price Success',
  LOAD_PURCHASE_PRICE_FAIL = '[Features.Invoice] Load Purchase Price Fail',
  CLEAR = '[Features.Invoice] Clear',
}

export class LoadPayload {
  id: string;
}

export class LoadSuccessPayload {
  invoice: models.Invoice;
}

export class UpdatePayload {
  invoice: models.Invoice;
  key: string;
}

export class LoadPurchasePricePayload {
  id: number;
}

export class LoadPurchasePriceSuccessPayload {
  amount: number;
}

export const load = createAction(Actions.LOAD, utils.payload<LoadPayload>());
export const loadSuccess = createAction(Actions.LOAD_SUCCESS, utils.payload<LoadSuccessPayload>());
export const loadFailed = createAction(Actions.LOAD_FAIL);
export const update = createAction(Actions.UPDATE, utils.payload<UpdatePayload>());
export const updateSuccess = createAction(Actions.UPDATE_SUCCESS);
export const updateFailed = createAction(Actions.UPDATE_FAIL);
export const clear = createAction(Actions.CLEAR);
export const loadPurchasePrice = createAction(Actions.LOAD_PURCHASE_PRICE, utils.payload<LoadPurchasePricePayload>());
export const loadPurchasePriceSuccess = createAction(Actions.LOAD_PURCHASE_PRICE_SUCCESS, utils.payload<LoadPurchasePriceSuccessPayload>());
export const loadPurchasePriceFailed = createAction(Actions.LOAD_PURCHASE_PRICE_FAIL);
