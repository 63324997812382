import { createAction, props } from '@ngrx/store';
// TODO: this is must be fixed, should use domain models
import * as viewModels from '../../view/view-models';

export class ActivateMembershipPayload {
  orderId: number;
  orderItemId: number;
}
export const activateMembership = createAction(
  '[Sales.OrderCollection] Activate',
  props<{ payload: ActivateMembershipPayload }>()
);

// TODO: Finish ActivateMembershipSuccessPayload
export class ActivateMembershipSuccessPayload {
  orderId: number;
  orderItemId: number;
  orderStatusId: number;
  orderStatusName: string;
}
export const activateMembershipSuccess = createAction(
  '[Sales.OrderCollection] Activate Success',
  props<{ payload: ActivateMembershipSuccessPayload }>()
);

export class ActivateMembershipFailPayload {
  errorMessage: string;
}
export const activateMembershipFail = createAction(
  '[Sales.OrderCollection] Activate Fail',
  props<{ payload: ActivateMembershipFailPayload }>()
);

export class CancelOrderPayload {
  orderId: number;
  chargeableCancellation?: boolean = null;
  charterSalesCancellation?: {
    cancellationFee?: number;
    cancellationNotes?: string;
  };
}
export const cancelOrder = createAction(
  '[Sales.OrderCollection] Cancel Order',
  props<{ payload: CancelOrderPayload }>()
);
export class CancelOrderSuccessPayload {
  orderId: number;
  orderStatusId: number;
  orderStatusName: string;
  message: string;
}
export const cancelOrderSuccess = createAction(
  '[Sales.OrderCollection] Cancel Order Success',
  props<{ payload: CancelOrderSuccessPayload }>()
);
export class CancelOrderFailPayload {
  errorMessage: string;
}
export const cancelOrderFail = createAction(
  '[Sales.OrderCollection] Cancel Order Fail',
  props<{ payload: CancelOrderFailPayload }>()
);

export class LoadActionPayload {
  page: number;
  itemsPerPage: number;
  requestId: number;
  accountId: string;
  clientId: number;
  clientName: string;
  clientNameId?: string;
  orderStatusIds: Array<number>;
  invoiceTotal: number;
  orderId: number;
  invoiceId: number;
  sapExportStatusIds: Array<number>;
  filterByCurrentUser: boolean;
  filterByUnconfirmedPayments: boolean;
  paymentType: string;
  startDate: string;
  endDate: string;
}
export const load = createAction(
  '[Sales.OrderCollection] Load',
  props<{ payload: LoadActionPayload }>()
);
export class LoadSuccessPayload {
  page: number;
  data: Array<viewModels.OrderVM>;
  count: number;
  canDownload = false;
}
export const loadSuccess = createAction(
  '[Sales.OrderCollection] Load Success',
  props<{ payload: LoadSuccessPayload }>()
);
export const loadFail = createAction('[Sales.OrderCollection] Load Fail');

export const download = createAction('[Sales.OrderCollection] Download');
export const downloadSuccess = createAction('[Sales.OrderCollection] Download Success');
export class DownloadFailPayload {
  errorMessage: string;
}
export const downloadFail = createAction(
  '[Sales.OrderCollection] Download Fail',
  props<{ payload: DownloadFailPayload }>()
);

export const resetItems = createAction('[Sales.OrderCollection] Reset Items');
