export class User {
  id: number = null;
  firstName: string = null;
  lastName: string = null;
  lastLoginDate: Date = null;

  toString(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.lastLoginDate = data.lastLoginDate;
    }
  }
}
