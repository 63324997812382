import * as uuid from 'uuid';

import { AircraftCategory } from './aircraft-category';
import { Client } from './client';
import { LegRequest } from './leg-request';
import { SalesQuoteRequestType } from './sales-quote-request-type';
import { User } from './user';

export class SalesQuoteRequest {
  duplicateCheck = true;
  salesQuoteRequestId: string = uuid.v4();
  client: Client;
  aircraftCategory: AircraftCategory;
  aircraftSource: string = null;
  legs: Array<LegRequest> = [];
  createdDate: Date;
  createdUser: User;
  guaranteedAmount: number;
  guaranteedPriceQuote: number;
  deleted: boolean;
  lastModifiedDate: Date;
  lastModifiedUser: User;
  type: SalesQuoteRequestType;
  marketplace: boolean = false;

  setAircraftSource(aircraftSource: string): SalesQuoteRequest {
    this.aircraftSource = aircraftSource;
    return this;
  }

  getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  setLastModifiedDate(date: Date): SalesQuoteRequest {
    this.lastModifiedDate = date;
    return this;
  }

  getLastModifiedUser(): User {
    return this.lastModifiedUser;
  }

  getGuaranteedPriceQuote(): number {
    return this.guaranteedPriceQuote;
  }

  setLastModifiedUser(user: User): SalesQuoteRequest {
    this.lastModifiedUser = user;
    return this;
  }

  getCreatedDate(): Date {
    return this.createdDate;
  }

  serCreatedDate(date: Date): SalesQuoteRequest {
    this.createdDate = date;
    return this;
  }

  getCreatedUser(): User {
    return this.createdUser;
  }

  setCreatedUser(user: User): SalesQuoteRequest {
    this.createdUser = user;
    return this;
  }

  getGuaranteedAmount(): number {
    return this.guaranteedAmount;
  }

  setGuaranteedPriceQuote(guaranteedPriceQuote: number): SalesQuoteRequest {
    this.guaranteedPriceQuote = guaranteedPriceQuote;
    return this;
  }

  setGuaranteedAmount(guaranteedAmount: number): SalesQuoteRequest {
    this.guaranteedAmount = guaranteedAmount;
    return this;
  }

  getDeleted(): boolean {
    return this.deleted;
  }

  setDeleted(deleted): SalesQuoteRequest {
    this.deleted = deleted;
    return this;
  }

  addLegRequest(legRequest: LegRequest): void {
    this.legs.push(legRequest);
  }

  removeLegRequestAt(index: number): void {
    this.legs.splice(index, 1);
  }

  updateLegRequest(index: number, legRequest: LegRequest): void {
    this.legs[index] = legRequest;
  }

  getLegs(): Array<LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<LegRequest>): SalesQuoteRequest {
    this.legs = legs;

    return this;
  }

  getAircraftCategory(): AircraftCategory {
    return this.aircraftCategory;
  }

  setAircraftCategory(aircraftCategory: AircraftCategory): SalesQuoteRequest {
    this.aircraftCategory = aircraftCategory;

    return this;
  }

  getClient(): Client {
    return this.client;
  }

  setClient(client: Client): SalesQuoteRequest {
    this.client = client;

    return this;
  }

  getSalesQuoteRequestId(): string {
    return this.salesQuoteRequestId;
  }

  setSalesQuoteRequestId(salesQuoteRequestId: string): SalesQuoteRequest {
    this.salesQuoteRequestId = salesQuoteRequestId;

    return this;
  }

  getLegRequests(): Array<LegRequest> {
    return this.legs;
  }

  getType(): SalesQuoteRequestType {
    return this.type;
  }

  setType(type: SalesQuoteRequestType): SalesQuoteRequest {
    this.type = type;
    return this;
  }

  setMarketplace(marketplace: boolean): SalesQuoteRequest {
    this.marketplace = marketplace;
    return this;
  }
}
