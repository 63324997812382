import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import * as sharedTypes from '../../../../../shared/types';
import * as coreTypes from '../../../../../core/types';
import * as contracts from '../../../domain/service-contracts/membership';
import * as models from '../../../domain/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MembershipService implements contracts.IMembershipService {
  private get API_PATH(): string {
    return this.configService.get('MembershipServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getTransitions(
    request: contracts.GetTransitionsRequest
  ): Observable<contracts.GetTransitionsResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/memberships/transitions`;

    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetTransitionsResponse();
        response.entities = [];

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        const entities = svcJsonResp.data.map((item) => {
          const transition = new models.Transition();

          transition.membershipTransitionId = Number(item.id);
          transition.salesAction = Number(item.salesAction.id);

          if (item.currentProduct) {
            transition.currentProduct = new models.Product();
            transition.currentProduct.id = item.currentProduct.id;
            transition.currentProduct.name = item.currentProduct.name;
            transition.currentProduct.description = item.currentProduct.description;
            transition.currentProduct.amount = sharedTypes.Util.nvlToNumber(
              item.currentProduct.amount
            );
          }

          if (item.nextProduct) {
            if (item.nextProduct.id === null) {
              transition.nextProduct = {} as models.Product;
            } else {
              transition.nextProduct = new models.Product();
              transition.nextProduct.id = item.nextProduct.id;
              transition.nextProduct.name = item.nextProduct.name;
              transition.nextProduct.description = item.nextProduct.description;
              transition.nextProduct.amount = sharedTypes.Util.nvlToNumber(item.nextProduct.amount);
            }
          }

          return transition;
        });

        response.entities = entities;

        return response;
      })
      // .catch((error: any): Observable<any> => {
      //   const msg = error._body ? error._body : JSON.stringify(error);
      //   window.alert(`Server error: ${msg}`);

      //   return _throw(JSON.stringify(error) || 'Server error');
      // })
    );
  }

  getPriceComponentToSalesActions(
    request: contracts.GetPriceComponentToSalesActionsRequest
  ): Observable<contracts.GetPriceComponentToSalesActionsResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/memberships/price-components-to-sales-actions`;
    let params = new HttpParams();
    params = params.append('active', request.active.toString());

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetPriceComponentToSalesActionsResponse();
        response.entities = [];

        if (!svcJsonResp || !svcJsonResp.data) {
          return response;
        }

        const entities = svcJsonResp.data.map((item) => {
          const pcToSa = new models.PriceComponentToSalesAction();

          pcToSa.priceComponentId = Number(item.priceComponentId);
          pcToSa.salesAction = Number(item.salesActionId);

          return pcToSa;
        });

        response.entities = entities;

        return response;
      })
      // .catch((error: any): Observable<any> => {
      //   const msg = error._body ? error._body : JSON.stringify(error);
      //   window.alert(`Server error: ${msg}`);

      //   return _throw(JSON.stringify(error) || 'Server error');
      // })
    );
  }

  activateMembership(
    request: contracts.ActivateMembershipRequest
  ): Observable<contracts.ActivateMembershipResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/memberships/activate`;

    let params = new HttpParams();
    params = params.append('orderItemId', request.orderItemId.toString());

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.put(requestUrl, params.toString(), { headers }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.ActivateMembershipResponse();

        // response.membership = new models.Membership();
        // response.membership.id = svcJsonResp.data.id;
        // response.membership.typeId = Number(svcJsonResp.data.typeId);
        // response.membership.salesAction = new models.SalesAction();
        // response.membership.salesAction.id = Number(svcJsonResp.data.salesActionId);
        // response.membership.salesAction.name = svcJsonResp.data.salesActionName;
        // response.membership.clientId = Number(svcJsonResp.data.clientId);
        // response.membership.statusId = Number(svcJsonResp.data.statusId);
        // response.membership.startDate = new Date(svcJsonResp.data.startDate);
        // response.membership.endDate = new Date(svcJsonResp.data.endDate);
        // response.membership.activateTime = new Date(svcJsonResp.data.activateTime);
        // response.membership.orderItemId = Number(svcJsonResp.data.orderItemId);
        // response.membership.legacyMembershipPeriodId = Number(svcJsonResp.data.legacyMembershipPeriodId);
        // response.membership.createdByUserId = Number(svcJsonResp.data.createdByUserId);

        response.orderStatusId = Number(svcJsonResp.data.orderStatusId);
        response.orderStatusName = svcJsonResp.data.orderStatusName;

        return response;
      })
      // .catch((error: any): Observable<any> => {
      //   const msg = error._body ? error._body : JSON.stringify(error);
      //   window.alert(`Server error: ${msg}`);

      //   return _throw(JSON.stringify(error) || 'Server error');
      // })
      // .catch((error: any): any => {
      //   const msg = error._body ? error._body : JSON.stringify(error);
      //   window.alert('Server error: ' + msg);
      //   return _throw(JSON.stringify(error) || 'Server error');
      // })
    );
  }
}
