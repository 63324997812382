export class QuoteAttribute {
  quoteAttributeUuid: string;
  name: string;

  getQuoteAttributeUuid(): string {
    return this.quoteAttributeUuid;
  }

  setQuoteAttributeUuid(quoteAttributeUuid: string): QuoteAttribute {
    this.quoteAttributeUuid = quoteAttributeUuid;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): QuoteAttribute {
    this.name = name;
    return this;
  }
}
