export class SapExportStatus {

  private _id: number;
  private _submissionTypeId: number;
  private _displayName: string;
  private _color: string;
  private _description?: string;

  private constructor(
    id: number,
    displayName: string,
    color: string,
    description?: string,
    submissionTypeId?: number,
  ) {
    this._id = id;
    this._displayName = displayName;
    this._color = color;
    this._description = description;
    this._submissionTypeId = submissionTypeId;
  }

  public static getStatus(id: number, sapExportStatusDescription?: string, submissionTypeId?: number): SapExportStatus {
    if (!id) {
      return null;
    }

    switch (id) {
      case 1:
        return SapExportStatus.NOT_IN_QUEUE(sapExportStatusDescription, submissionTypeId);
      case 2:
        return SapExportStatus.POSTED(sapExportStatusDescription, submissionTypeId);
      case 3:
        return SapExportStatus.SUPPRESSED(sapExportStatusDescription, submissionTypeId);
      case 4:
        return SapExportStatus.ERROR(sapExportStatusDescription, submissionTypeId);
      case 5:
        return SapExportStatus.PENDING(sapExportStatusDescription, submissionTypeId);
      case 6:
        return SapExportStatus.NOT_APPLICABLE(sapExportStatusDescription, submissionTypeId);
      default:
        return null;
    }

  }

  public static NOT_IN_QUEUE(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(1, 'Not in Queue', '#C0C0C0', description, submissionTypeId);
  }

  public static POSTED(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(2, 'Posted', '#99CC00', description, submissionTypeId);
  }

  public static SUPPRESSED(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(3, 'Suppressed', '#3366ff', description, submissionTypeId);
  }

  public static ERROR(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(4, 'Error', '#FF0000', description, submissionTypeId);
  }

  public static PENDING(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(5, 'Pending', '#FF9900', description, submissionTypeId);
  }

  public static NOT_APPLICABLE(description?: string, submissionTypeId?: number) {
    return new SapExportStatus(6, 'Not Applicable', '#808080', description, submissionTypeId);
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get submissionTypeId(): number {
    return this._submissionTypeId;
  }

  set submissionTypeId(value: number) {
    this._submissionTypeId = value;
  }

  get displayName(): string {
    return this._displayName;
  }

  set displayName(value: string) {
    this._displayName = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

}
