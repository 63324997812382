import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as actions from '../actions/layout.actions';
import * as sharedTypes from '../types';

@Injectable()
export class LayoutEffects {
  showError$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.showErrorPopup),
        map((action) => {
          this.notificationService.show(
            action.payload ||
              `An error occurred and your request couldn't be completed. Please try again.`,
            sharedTypes.NotificationStyle.Bar,
            sharedTypes.NotificationType.Danger,
            sharedTypes.NotificationPosition.Top,
            30000
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN)
    private notificationService: sharedTypes.INotificationService
  ) {}
}
