import { createReducer, on } from '@ngrx/store';

import * as models from '../../domain/models';
import * as actions from '../actions/price-component-to-sales-action-collection.actions';

export const priceComponentToSalesActionCollectionFeatureKey = 'priceComponentToSalesActionCollection';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.PriceComponentToSalesAction>;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(actions.load, (state): State => ({ ...state, isLoading: true })),

  on(
    actions.loadSuccess,
    (_, { payload }): State => ({
      isLoaded: true,
      isLoading: false,
      items: payload,
    })
  )
);
