export class Task {
  taskId: number;
  leg: FlightLeg;
  assignedUser: AssignedUser;
  taskTypeId: number;
  invoiceLink: string;
  state: State;
  category: Category;
  dueDate: Date;
  title: string;
  description: string;
  notes: Array<Note>;
  finalizeLegInfo: FinalizeLegInfo;
  actualTime?: Date;
  rwaClient: boolean;
}

export class FlightLeg {
  accountName: string;
  arrivalAirportCode: string;
  arrivalDateTime: Date;
  broken: boolean;
  canceled: boolean;
  departureAirportCode: string;
  departureDateTime: Date;
  fosTripId: number;
  legId: number;
  localArrivalDateTime: Date;
  localDepartureDateTime: Date;
  pod: string;
  sfdcAccountId: string;
  source: string;
  tailNumber: string;
  uuid: string;
}

export class AssignedUser {
  userId: number;
  firstName: string;
  lastName: string;
}

export class Category {
  taskCategoryId: number;
  sortOrder: number;
  displayName: string;
}

export class State {
  taskStateId: number;
  displayName: string;
  code: string;
}

export class LegTasksLookupFilters {
  search = '';
  taskStateId = '';
  taskCategoryId = '';
  pod = '';
  source = '';
  legStatusId= '';
  departureDateFrom = null;
  departureDateTo = null;
  dueDateFrom = null;
  dueDateTo = null;
}

export class TaskLookupCriteria {
  search = '';
  taskStateId = '1,2,3,6';
  taskCategoryId = '';
  pod = '';
  source = '';
  dueDateFrom = null;
  dueDateTo = null;
  departureDateFrom = null;
  departureDateTo = null;

  clear? = (): TaskLookupCriteria => {
    this.search = '';
    this.taskStateId = '';
    this.taskCategoryId = '';
    this.pod = '';
    this.source = '';
    this.departureDateFrom = null;
    this.departureDateTo = null;
    this.dueDateFrom = null;
    this.dueDateTo = null;
    return this;
  };
}

export class TaskLog {
  createTime: Date;
  description: string;
  id: number;
  user: AssignedUser;
}

export class Note {
  id: string;
  text: string;
  creationDate: Date;
  user: AssignedUser;
}

export class FinalizeLegInfo {
  completed: boolean;
  flightRequestIsNotCancelled: boolean;
  latestInvoiceIsActive: boolean;
  latestInvoicePostedToSAP: boolean;
  legIsNotCancelled: boolean;
  tailNumberIsInConsole: boolean;
  // xojet: boolean;
  // xojetOnFleet: boolean;
  tailNumberOperatorMatchAM: boolean;
}
