import { Area } from './area';
import { Category } from './category';

export class Market {
  id: string = null;
  name: string = null;
  regionId: string = null;
  regionName: string = null;
  departureArea: Area = null;
  arrivalArea: Area = null;
  aircraftCategories: Array<Category> = [];
}
