import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as sharedTypes from '../../../../../shared/types';
import * as coreTypes from '../../../../../core/types';
import * as contracts from '../../../domain/service-contracts/promotion';
import * as models from '../../../domain/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionService implements contracts.IPromotionService {
  private get API_PATH(): string {
    return this.configService.get('OfferServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getPromotion(request: contracts.GetPromotionRequest): Observable<contracts.GetPromotionResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/promotions/`;

    let params = new HttpParams();
    params = params.append('code', request.promotionCode);

    return this.http.get(requestUrl, { params }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetPromotionResponse();

        if (
          !svcJsonResp ||
          !svcJsonResp.data ||
          !svcJsonResp.data.offers ||
          !svcJsonResp.data.offers.length
        ) {
          return response;
        }

        if (svcJsonResp.data.offers && svcJsonResp.data.offers.length > 1) {
          throw new Error(
            `Multiple promotions returned with promo code '${request.promotionCode}'`
          );
        }

        const el = svcJsonResp.data.offers[0];

        // svcJsonResp.data.offers.forEach(el => {
        const promotion = new models.Promotion();
        promotion.id = el.promotionId;
        promotion.code = el.promoCode;
        promotion.percentage = sharedTypes.Util.nvlToNumber(el.percentage);
        promotion.amount = sharedTypes.Util.nvlToNumber(el.amount);
        promotion.description = el.description;
        promotion.createTime = new Date(el.createTime);
        promotion.items = [];

        const priceComponents = svcJsonResp.data.offerItems.filter(
          (pi) => pi.promotionId === promotion.id
        );
        priceComponents.forEach((elPI) => {
          const promotionItem = new models.PromotionItem();
          promotionItem.id = elPI.promotionItemId;
          // promotionItem.promotionId = elPI.promotionId;

          // const productType = new models.ProductType();
          // productType.id = Number(elPI.productTypeId);
          //
          // promotionItem.productType = productType;

          if (elPI.product) {
            const product = new models.Product();
            product.id = Number(elPI.product.productId);

            const productType = new models.ProductType();
            productType.id = Number(elPI.product.productTypeId);
            productType.parentProductTypeId = Number(elPI.product.parentProductTypeId);
            product.productType = productType;

            promotionItem.product = product;
          }

          if (elPI.priceComponent) {
            const priceComponentElement = elPI.priceComponent;

            const discount = new models.PriceComponent();
            discount.typeId = Number(priceComponentElement.priceComponentTypeId);
            discount.id = Number(priceComponentElement.priceComponentId);

            if (priceComponentElement.percentage) {
              discount.percentage = Number(priceComponentElement.percentage);
            }

            if (priceComponentElement.amount) {
              discount.amount = Number(priceComponentElement.amount);
            }
            discount.name = priceComponentElement.priceComponentName;

            promotionItem.discount = discount;
          }

          promotionItem.promotionAmount = sharedTypes.Util.nvlToNumber(elPI.promotionAmount);
          promotionItem.quantity = elPI.quantity;
          promotion.items.push(promotionItem);
        });

        response.entity = promotion;
        // });

        return response;
      })
    );
  }
}
