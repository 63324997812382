import { PriceTypeEnum } from './price-type-enum';
import { Surcharge } from './surcharge';

export class FixedPriceAlternateOption {
  price: number = 0;
  pricingSupportType: PriceTypeEnum;
  bestPrice: boolean;
  base: number = 0;
  surcharges: Array<Surcharge>;

  getPrice(): number {
    return this.price;
  }

  setPrice(price: number): FixedPriceAlternateOption {
    this.price = price;

    return this;
  }

  getPricingSupportType(): PriceTypeEnum {
    return this.pricingSupportType;
  }

  setPricingSupportType(pricingSupportType: PriceTypeEnum): FixedPriceAlternateOption {
    this.pricingSupportType = pricingSupportType;
    
    return this;
  }

  getBestPrice(): boolean {
    return this.bestPrice;
  }

  setBestPrice(bestPrice: boolean): FixedPriceAlternateOption {
    this.bestPrice = bestPrice;
    
    return this;
  }

  getBase(): number {
    return this.base;
  }

  setBase(base: number): FixedPriceAlternateOption {
    this.base = base;

    return this;
  }

  getSurcharges(): Array<Surcharge> {
    return this.surcharges;
  }

  setSurcharges(surcharges: Array<Surcharge>): FixedPriceAlternateOption {
    this.surcharges = surcharges;

    return this;
  } 
}