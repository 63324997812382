export const response = {
  content: [
    {
      id: 4,
      name: 'Light Jets',
    },
    {
      id: 5,
      name: 'Mid Size Jets',
    },
    {
      id: 6,
      name: 'Super Mid Size Jets',
    },
    {
      id: 7,
      name: 'Heavy Jets',
    },
  ],
};
