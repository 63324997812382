import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction('[Charter.AircraftAmenity] Load');

export const loadSuccess = createAction(
  '[Charter.AircraftAmenity] Load Success',
  props<{ payload: Array<models.AircraftAmenity> }>()
);

export const loadFail = createAction('[Charter.AircraftAmenity] Load Fail');
