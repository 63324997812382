import { ClientRequest } from './client-request';
import { CharterInvoice } from './charter-invoice';
import { CharterActionEnum } from './charter-action-enum';
import { ProformaAdditionalInvoiceOptionsEnum } from './proforma-additional-invoice-options-enum';

export class CharterClientRequest extends ClientRequest {
  invoices: Array<CharterInvoice> = [];
  orderItemTotal?: number = null;
  charterAction: CharterActionEnum = null;
  isValid: boolean = null;
  proformaAdditionalInvoiceOption: ProformaAdditionalInvoiceOptionsEnum;
}
