import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Ramen Regions] Load';
export const LOAD_SUCCESS = '[RM Ramen Regions] Load Success';
export const LOAD_FAIL = '[RM Ramen Regions] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor() {}
}

export class LoadSuccessPayload {
  entities: Array<models.RmnMainRegion>;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
