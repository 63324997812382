export enum RestrictedFunctionTypeEnum {
  RenewalPaid = 1,
  WireReceived = 2,
  AccountingReportDownload = 3,
  NewOrderMembership = 4,
  NewOrderService = 5,
  NewOrderConcierge = 6,
  NewOrderCharter = 7,
  AmendChargesButton = 8,
  ReinstateOrder = 9,
  ARPaymentConfirmedOrder = 10,
  RMPricingCalculator = 11,
  ProformaAdditionalInvoiceFinalizeButton = 12,
  NewOpportunityForNonEliteClients = 13,
  CharterCreditMemo = 14,
  CreditMemoCancellation = 15,
  CancelOrder = 16,
}
