import { createSelector } from 'reselect';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromAircraftCategoriesCollection from './aircraft-categories-collection';
import * as fromAirportCollection from './airport-collection';
import * as fromCharterPricingCollection from './charter-pricing-collection';
import * as fromCharterPricingDetail from './charter-pricing-detail';
import * as fromCustomShuttleOffers from './custom-shuttle-offers';
import * as fromCyoPricing from './cyo-pricing';
import * as fromLegBooking from './leg-booking';
import * as fromLegCollection from './leg-collection';
import * as fromLegPricing from './leg-pricing';
import * as fromLegPricingAuditCollection from './leg-pricing-audit-collection';
import * as fromMagicOverride from './magic-override';
import * as fromRamenFilters from './ramen-filters';
import * as fromRouteGroupCollection from './route-group-collection';
import * as fromSeatPricingCollection from './seat-pricing-collection';
import * as fromShuttleRouteGroups from './shuttle-route-groups';
import * as fromVendor from './vendor-collection';
import * as fromDepTimeFee from './departure-time-fee-config';

export interface State {
  seatPricingCollection: fromSeatPricingCollection.State;
  cyoPricing: fromCyoPricing.State;
  routeGroupCollection: fromRouteGroupCollection.State;
  airportCollection: fromAirportCollection.State;
  legCollection: fromLegCollection.State;
  legBooking: fromLegBooking.State;
  legPricing: fromLegPricing.State;
  legPricingAuditCollection: fromLegPricingAuditCollection.State;
  charterPricingCollection: fromCharterPricingCollection.State;
  charterPricingDetail: fromCharterPricingDetail.State;
  aircraftCategoriesCollection: fromAircraftCategoriesCollection.State;
  ramenFilters: fromRamenFilters.State;
  vendorCollection: fromVendor.State;
  shuttleRouteGroups: fromShuttleRouteGroups.State;
  customShuttleOffers: fromCustomShuttleOffers.State;
  magicOverride: fromMagicOverride.State;
  departureTimeFee: fromDepTimeFee.State;
}

const reducers: ActionReducerMap<State> = {
  seatPricingCollection: fromSeatPricingCollection.reducer,
  cyoPricing: fromCyoPricing.reducer,
  routeGroupCollection: fromRouteGroupCollection.reducer,
  airportCollection: fromAirportCollection.reducer,
  legCollection: fromLegCollection.reducer,
  legBooking: fromLegBooking.reducer,
  legPricing: fromLegPricing.reducer,
  legPricingAuditCollection: fromLegPricingAuditCollection.reducer,
  charterPricingCollection: fromCharterPricingCollection.reducer,
  charterPricingDetail: fromCharterPricingDetail.reducer,
  aircraftCategoriesCollection: fromAircraftCategoriesCollection.reducer,
  ramenFilters: fromRamenFilters.reducer,
  vendorCollection: fromVendor.reducer,
  shuttleRouteGroups: fromShuttleRouteGroups.reducer,
  customShuttleOffers: fromCustomShuttleOffers.reducer,
  magicOverride: fromMagicOverride.reducer,
  departureTimeFee: fromDepTimeFee.reducer
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): State {
  return combinedReducer(state, action);
}

export const getRamenFiltersState = (state: any) => state.yieldMgmt.ramenFilters;

export const getRamenFilters = createSelector(getRamenFiltersState, fromRamenFilters.getFilters);

export const getVendorCollectionState = (state: any) => state.yieldMgmt.vendorCollection;
export const getVendorCollectionItems = createSelector(
  getVendorCollectionState,
  fromVendor.getItems
);

export const getAircraftCategoriesCollectionState = (state: any) =>
  state.yieldMgmt.aircraftCategoriesCollection;
export const getAircraftCategoriesCollectionIsLoaded = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getIsLoaded
);
export const getAircraftCategoriesCollectionIsLoading = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getIsLoading
);
export const getAircraftCategoriesCollection = createSelector(
  getAircraftCategoriesCollectionState,
  fromAircraftCategoriesCollection.getCategories
);

export const getRouteGroupCollectionState = (state: any) => state.yieldMgmt.routeGroupCollection;
export const getRouteGroupCollectionIsLoading = createSelector(
  getRouteGroupCollectionState,
  fromRouteGroupCollection.getIsLoading
);
export const getRouteGroupCollection = createSelector(
  getRouteGroupCollectionState,
  fromRouteGroupCollection.getRouteGroups
);

export const getCharterPricingCollectionState = (state: any) =>
  state.yieldMgmt.charterPricingCollection;
export const getCharterPricingCollectionIsLoaded = createSelector(
  getCharterPricingCollectionState,
  fromCharterPricingCollection.getIsLoaded
);
export const getCharterPricingCollectionIsLoading = createSelector(
  getCharterPricingCollectionState,
  fromCharterPricingCollection.getIsLoading
);
export const getCharterPricingCollectionRules = createSelector(
  getCharterPricingCollectionState,
  fromCharterPricingCollection.getRules
);
export const getCharterPricingCollectionLookup = createSelector(
  getCharterPricingCollectionState,
  fromCharterPricingCollection.getLookup
);
export const getCharterPricingCollectionTotal = createSelector(
  getCharterPricingCollectionState,
  fromCharterPricingCollection.getTotalCount
);

export const getCharterPricingDetailState = (state: any) => state.yieldMgmt.charterPricingDetail;
export const getCharterPricingDetailIsLoaded = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getIsLoaded
);
export const getCharterPricingDetailIsLoading = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getIsLoading
);
export const getCharterPricingDetailItem = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getItem
);
export const getCharterPricingDetailPristine = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getPristine
);
export const getCharterPricingDetailIsValid = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getIsValid
);
export const getCharterPricingDetailValidationLoading = createSelector(
  getCharterPricingDetailState,
  fromCharterPricingDetail.getValidationLoading
);
export const getAirportCollectionState = (state: any) => state.yieldMgmt.airportCollection;
export const getAirportCollectionIsLoaded = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getIsLoaded
);
export const getAirportCollection = createSelector(
  getAirportCollectionState,
  fromAirportCollection.getAirports
);

export const getSeatPricingCollectionState = (state: any) => state.yieldMgmt.seatPricingCollection;
export const getSeatPricingCollectionIsLoaded = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getIsLoaded
);
export const getSeatPricingCollectionIsLoading = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getIsLoading
);
export const getSeatPricingCollectionFailedToLoad = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getFailedToLoad
);
export const getSeatPricingCollectionItems = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getItems
);
export const getSeatPricingCollectionMetaData = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getMetaData
);
export const getSeatPricingCollectionMetaDataIsLoaded = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getMetaDataIsLoaded
);
export const getSeatPricingCollectionLookupCriteria = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getLookupCriteria
);
export const getSeatPricingCollectionMatrixData = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getMatrixData
);
export const getSeatPricingCollectionMatrixDataIsLoading = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getMatrixDataIsLoading
);
export const getSeatPricingCollectionIsRuleLoaded = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getIsRuleLoaded
);

export const getSeatPricingCollectionRuleFailedToLoad = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getRuleFailedToLoad
);
export const getSeatPricingCollectionRule = createSelector(
  getSeatPricingCollectionState,
  fromSeatPricingCollection.getRule
);

export const getCyoPricingState = (state: any) => state.yieldMgmt.cyoPricing;
export const getCyoPricingIsLoaded = createSelector(getCyoPricingState, fromCyoPricing.getIsLoaded);
export const getCyoPricingIsLoading = createSelector(
  getCyoPricingState,
  fromCyoPricing.getIsLoading
);
export const getCyoPricingRules = createSelector(getCyoPricingState, fromCyoPricing.getRules);
export const getCyoPricingVersion = createSelector(getCyoPricingState, fromCyoPricing.getVersion);
export const getCyoPricingLookupCriteria = createSelector(
  getCyoPricingState,
  fromCyoPricing.getLookupCriteria
);
export const getCyoPricingIsMarketLoaded = createSelector(
  getCyoPricingState,
  fromCyoPricing.getIsMarketLoaded
);
export const getCyoPricingIsMarketLoading = createSelector(
  getCyoPricingState,
  fromCyoPricing.getIsMarketLoading
);
export const getCyoPricingMarket = createSelector(getCyoPricingState, fromCyoPricing.getMarket);

export const getLegCollectionState = (state: any) => state.yieldMgmt.legCollection;
export const getLegCollectionOffset = createSelector(
  getLegCollectionState,
  fromLegCollection.getOffset
);
export const getLegCollectionLimit = createSelector(
  getLegCollectionState,
  fromLegCollection.getLimit
);
export const getLegCollectionIsLoaded = createSelector(
  getLegCollectionState,
  fromLegCollection.getIsLoaded
);
export const getLegCollectionIsLoading = createSelector(
  getLegCollectionState,
  fromLegCollection.getIsLoading
);
export const getLegCollectionIsLegLoaded = createSelector(
  getLegCollectionState,
  fromLegCollection.getIsLegLoaded
);
export const getLegCollectionIsLegLoading = createSelector(
  getLegCollectionState,
  fromLegCollection.getIsLegLoading
);
export const getLegCollectionItems = createSelector(
  getLegCollectionState,
  fromLegCollection.getItems
);
export const getLegSelectedItemId = createSelector(
  getLegCollectionState,
  fromLegCollection.getSelectedItemId
);
export const getLegCollectionFilters = createSelector(
  getLegCollectionState,
  fromLegCollection.getFilters
);
export const getLegCollectionSorting = createSelector(
  getLegCollectionState,
  fromLegCollection.getSorting
);
export const getLegCollectionSettings = createSelector(
  getLegCollectionState,
  fromLegCollection.getSettings
);
export const getLegCollectionLastUpdatedDate = createSelector(
  getLegCollectionState,
  fromLegCollection.getLastUpdated
);
export const getLegCollectionAirportGroupsUpdated = createSelector(
  getLegCollectionState,
  fromLegCollection.getAirportGroupsUpdated
);

export const getLegBookingState = (state: any) => state.yieldMgmt.legBooking;
export const getLegBookingItems = createSelector(getLegBookingState, fromLegBooking.items);
export const getLegBookingLoaded = createSelector(getLegBookingState, fromLegBooking.loaded);

export const getLegPricingState = (state: any) => state.yieldMgmt.legPricing;
export const getLegPricingLegPrice = createSelector(getLegPricingState, fromLegPricing.getLegPrice);
export const getLegPricingLegPriceAuditLog = createSelector(
  getLegPricingState,
  fromLegPricing.getLegPriceAuditLog
);
export const getLegPricingLoading = createSelector(getLegPricingState, fromLegPricing.loading);
export const getLegPricingLegMatrix = createSelector(
  getLegPricingState,
  fromLegPricing.getLegMatrix
);
export const getLegPricingMatrixLoaded = createSelector(
  getLegPricingState,
  fromLegPricing.getMatrixLoaded
);
export const getLegPricingMatrixLoading = createSelector(
  getLegPricingState,
  fromLegPricing.getMatrixLoading
);
export const getLegPricingUpdating = createSelector(getLegPricingState, fromLegPricing.updating);
export const getLastUpdatedByUser = createSelector(
  getLegPricingState,
  fromLegPricing.getLastUpdatedByUser
);
export const getLastUpdatedDate = createSelector(
  getLegPricingState,
  fromLegPricing.getLastUpdatedDate
);
export const getLegPricingUpdateReasons = createSelector(
  getLegPricingState,
  fromLegPricing.getUpdateReasons
);
export const getLegPricingUpdateReasonsLoading = createSelector(
  getLegPricingState,
  fromLegPricing.getUpdateReasonsLoading
);
export const getLegPricingUpdateReasonsLoaded = createSelector(
  getLegPricingState,
  fromLegPricing.getUpdateReasonsLoaded
);

export const getLegPricingAuditCollectionState = (state: any) =>
  state.yieldMgmt.legPricingAuditCollection;
export const getLegPricingAuditCollectionLoaded = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getIsLoaded
);
export const getLegPricingAuditCollectionLoading = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getIsLoading
);
export const getLegPricingAuditCollectionItems = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getItems
);
export const getLegPricingAuditCollectionItemsPerPage = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getItemsPerPage
);
export const getLegPricingAuditCollectionTotalCount = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getItemsTotalCount
);
export const getLegPricingAuditCollectionCurrentPage = createSelector(
  getLegPricingAuditCollectionState,
  fromLegPricingAuditCollection.getCurrentPage
);

export const getShuttleRouteGroupsState = (state: any) => state.yieldMgmt.shuttleRouteGroups;
export const getCustomShuttleRouteGroups = createSelector(
  getShuttleRouteGroupsState,
  fromShuttleRouteGroups.getCustomShuttleRouteGroups
);
export const getCustomShuttleRouteGroupsLoaded = createSelector(
  getShuttleRouteGroupsState,
  fromShuttleRouteGroups.getIsLoaded
);
export const getCustomShuttleRouteGroupsLoading = createSelector(
  getShuttleRouteGroupsState,
  fromShuttleRouteGroups.getIsLoading
);

export const getCustomShuttleOffersState = (state: any) => state.yieldMgmt.customShuttleOffers;
export const getCustomShuttleOffers = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getFilteredItems
);
export const getCustomShuttleOffersLoaded = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getIsLoaded
);
export const getCustomShuttleOffersLoading = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getIsLoading
);
export const getCustomShuttleCriteria = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getCriteria
);
export const getCustomShuttleGroupedItems = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getGroupedItems
);
export const getCustomShuttleSelectedItem = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getSelectedItem
);
export const getCustomShuttleBulkItems = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getBulkItems
);
export const getCustomShuttleHistoryItems = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getHistoryItems
);
export const getCustomShuttleHistoryLoading = createSelector(
  getCustomShuttleOffersState,
  fromCustomShuttleOffers.getHistoryLoading
);
export const getConfigurationsDataState = (state: any) => state.yieldMgmt.configurationsData;
// MagicOverride
export const getMagicOverrideState = (state: any) => state.yieldMgmt.magicOverride;

// Airport Fees
export const getAirportFeeState = (state: any) => state.yieldMgmt.airportFee;

// Airport Fees
export const getDepTimeFeeState = (state: any) => state.yieldMgmt.departureTimeFee;

export const getMagicOverrideLegCollectionIsInitialLoad = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getIsInitialLoad
);

export const getMagicOverrideLegCollectionIsLoaded = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getIsLoaded
);
export const getMagicOverrideLegCollectionIsLoading = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getIsLoading
);

export const getMagicOverrideFilterCriteria = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getFilters
);

export const getMagicOverridePaginator = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getPaginator
);

export const getMagicOverrideLegs = createSelector(
  getMagicOverrideState,
  fromMagicOverride.getItems
);


export const getDepTimeFees = createSelector(
  getDepTimeFeeState,
  fromDepTimeFee.getDepTimeFees
);
