export const response = {
  "log": {
    "logId": "4bd3092b-bc89-495a-a9e7-d409d173e2a5",
    "user": { "id": 2, "firstName": "Oleg", "lastName": "Solovey" },
    "createdTime": "2021-09-24T11:53:13",
    "input": {
      "userTimeZoneId": "Europe/Minsk",
      "actypes": ["LITE", "SMID", "CL30"],
      "depTimeChange": "FLEX",
      "memberState": "MEMBER",
      "legs": [{
        "origin": "KTEB",
        "destination": "KFLL",
        "depDateTime": "2021-09-26T12:00:00",
        "sortOrder": 0
      }, {
        "origin": "KFLL",
        "destination": "KLAX",
        "depDateTime": "2021-09-28T12:00:00",
        "sortOrder": 1
      }, { "origin": "KLAX", "destination": "KFLL", "depDateTime": "2021-09-29T12:00:00", "sortOrder": 2 }]
    }
  },
  "data": [{
    "pax": null,
    "actype": "LITE",
    "price": null,
    "reject": "unsupported route FL-CA",
    "segments": null,
    "subtotal": null,
    "serviceFee": null,
    "nonMemberFee": null,
    "segmentFee": null,
    "fetTax": null,
    "fetTaxPercent": 7.5,
    "guaranteedAvailability": false
  }, {
    "pax": 8,
    "actype": "SMID",
    "price": 101002.71,
    "reject": null,
    "segments": [{
      "depart": "KTEB",
      "arrive": "KFLL",
      "time": "2021-09-26T12:00:00-04:00",
      "actype": "SMID",
      "pax": 8,
      "eft": 2.46,
      "price": 29065,
      "debug": { "eftType": "table", "logic": "multi leg eft", "rate": 9799 },
      "priceInfo": {
        "base": 26065,
        "noodleToggle": 0,
        "departAP": -500,
        "arriveAP": -500,
        "departState": null,
        "arriveState": null,
        "challenger300": null,
        "calendarMsrp": "HIGH:4000",
        "msrp": "HIGH",
        "msrpValue": 4000
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 29065, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2179.88, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 8, "unitPercentage": null, "amount": 34.40, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }, {
      "depart": "KFLL",
      "arrive": "KLAX",
      "time": "2021-09-28T12:00:00-04:00",
      "actype": "SMID",
      "pax": 8,
      "eft": 4.9,
      "price": 32250,
      "debug": { "eftType": "table", "logic": "multi leg state to state", "rate": 33000 },
      "priceInfo": {
        "base": 33000,
        "noodleToggle": -750,
        "departAP": -500,
        "arriveAP": 500,
        "departState": null,
        "arriveState": null,
        "challenger300": null,
        "calendarMsrp": null,
        "msrp": "STANDARD",
        "msrpValue": 0
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 32250, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2418.75, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 8, "unitPercentage": null, "amount": 34.40, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }, {
      "depart": "KLAX",
      "arrive": "KFLL",
      "time": "2021-09-29T12:00:00-07:00",
      "actype": "SMID",
      "pax": 8,
      "eft": 4.9,
      "price": 32250,
      "debug": { "eftType": "table", "logic": "multi leg state to state", "rate": 33000 },
      "priceInfo": {
        "base": 33000,
        "noodleToggle": -750,
        "departAP": 500,
        "arriveAP": -500,
        "departState": null,
        "arriveState": null,
        "challenger300": null,
        "calendarMsrp": null,
        "msrp": "STANDARD",
        "msrpValue": 0
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 32250, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2418.75, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 8, "unitPercentage": null, "amount": 34.40, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }],
    "subtotal": 93860,
    "serviceFee": 295,
    "nonMemberFee": null,
    "segmentFee": 103.20,
    "fetTax": 7039.51,
    "fetTaxPercent": 7.5,
    "guaranteedAvailability": true
  }, {
    "pax": 9,
    "actype": "CL30",
    "price": 117140.61,
    "reject": null,
    "segments": [{
      "depart": "KTEB",
      "arrive": "KFLL",
      "time": "2021-09-26T12:00:00-04:00",
      "actype": "CL30",
      "pax": 9,
      "eft": 2.46,
      "price": 34065,
      "debug": { "eftType": "table", "logic": "multi leg eft", "rate": 9799 },
      "priceInfo": {
        "base": 26065,
        "noodleToggle": 0,
        "departAP": -500,
        "arriveAP": -500,
        "departState": null,
        "arriveState": null,
        "challenger300": 5000,
        "calendarMsrp": "HIGH:4000",
        "msrp": "HIGH",
        "msrpValue": 4000
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 34065, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2554.88, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 9, "unitPercentage": null, "amount": 38.70, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }, {
      "depart": "KFLL",
      "arrive": "KLAX",
      "time": "2021-09-28T12:00:00-04:00",
      "actype": "CL30",
      "pax": 9,
      "eft": 4.9,
      "price": 37250,
      "debug": { "eftType": "table", "logic": "multi leg state to state", "rate": 33000 },
      "priceInfo": {
        "base": 33000,
        "noodleToggle": -750,
        "departAP": -500,
        "arriveAP": 500,
        "departState": null,
        "arriveState": null,
        "challenger300": 5000,
        "calendarMsrp": null,
        "msrp": "STANDARD",
        "msrpValue": 0
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 37250, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2793.75, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 9, "unitPercentage": null, "amount": 38.70, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }, {
      "depart": "KLAX",
      "arrive": "KFLL",
      "time": "2021-09-29T12:00:00-07:00",
      "actype": "CL30",
      "pax": 9,
      "eft": 4.9,
      "price": 37250,
      "debug": { "eftType": "table", "logic": "multi leg state to state", "rate": 33000 },
      "priceInfo": {
        "base": 33000,
        "noodleToggle": -750,
        "departAP": 500,
        "arriveAP": -500,
        "departState": null,
        "arriveState": null,
        "challenger300": 5000,
        "calendarMsrp": null,
        "msrp": "STANDARD",
        "msrpValue": 0
      },
      "taxFees": [{
        "priceComponent": {
          "priceComponentId": "ec3d446e-1b6e-4227-ad7e-55f8c016523d",
          "name": "Tax",
          "unitBasePriceType": "VARIABLE",
          "unitQuantityType": null,
          "unitBasePrice": null,
          "unitPercentage": 7.5
        }, "unitBasePrice": 37250, "unitQuantity": null, "unitPercentage": 7.5, "amount": 2793.75, "currency": "USD"
      }, {
        "priceComponent": {
          "priceComponentId": "4044ae1a-4e27-4219-9e49-d7509a24eb05",
          "name": "Segment Fee",
          "unitBasePriceType": "STATIC",
          "unitQuantityType": "VARIABLE",
          "unitBasePrice": 4.3,
          "unitPercentage": null
        }, "unitBasePrice": 4.3, "unitQuantity": 9, "unitPercentage": null, "amount": 38.70, "currency": "USD"
      }],
      "firmFlexSurcharge": null
    }],
    "subtotal": 108860,
    "serviceFee": 295,
    "nonMemberFee": null,
    "segmentFee": 116.10,
    "fetTax": 8164.51,
    "fetTaxPercent": 7.5,
    "guaranteedAvailability": true
  }]
};
