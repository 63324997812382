import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

import * as fromClient from './client.reducer';
import * as fromClientList from './client-list.reducer';
import * as fromUniversalClientList from './universal-client-list.reducer';
import * as fromGroupMembership from './group-membership.reducer';
import * as fromDbaList from './dba-list.reducer';
import * as fromError from './error.reducer';

export interface State {
  [fromClient.clientFeatureKey]: fromClient.State;
  [fromClientList.clientListFeatureKey]: fromClientList.State;
  [fromUniversalClientList.universalClientListFeatureKey]: fromUniversalClientList.State;
  [fromGroupMembership.groupMembershipFeatureKey]: fromGroupMembership.State;
  [fromDbaList.dbaListFeatureKey]: fromDbaList.State;
  [fromError.errorFeatureKey]: fromError.State;
}

export const featureName = 'client';

export const reducers = combineReducers<State, Action>({
  [fromClient.clientFeatureKey]: fromClient.reducer,
  [fromClientList.clientListFeatureKey]: fromClientList.reducer,
  [fromUniversalClientList.universalClientListFeatureKey]: fromUniversalClientList.reducer,
  [fromGroupMembership.groupMembershipFeatureKey]: fromGroupMembership.reducer,
  [fromDbaList.dbaListFeatureKey]: fromDbaList.reducer,
  [fromError.errorFeatureKey]: fromError.reducer,
});

export const getClientState = createFeatureSelector<State>(featureName);
