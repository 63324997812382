import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import * as sharedComponents from '../../../shared/components';
import { BrowserCheckService } from '../../services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends sharedComponents.WireframeComponent implements OnInit {
  constructor(store$: Store<any>, router: Router, private browserCheck: BrowserCheckService) {
    super(store$, router);
  }
  ngOnInit() {
    this.browserCheck.checkBrowser();
  }
}
