import { ProductOrderItemSerializable } from './product-order-item-serializable';
import * as models from '../../../../domain/models';

export class ConciergeOrderItemSerializable extends ProductOrderItemSerializable {
  vendorId: number = null;
  vendorRefNumber: string = null;
  quoteAmount: number = null;
  markupPercent: number = null;
  clientComments: string = null;
  serviceDate: string = null;
  comments: string = null;
  surcharge: models.PriceComponent = null;
  serviceDiscountAmount: number = null;
  cateringCredit: models.CateringCredit = null;
  flightRequestId: number;
  creditMethod: number;
  creditReasons: Array<number>;
  sfdcCompensationId?: string;
  sfdcOpportunityId?: string;
}
