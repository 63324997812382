import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/user.actions';
import * as models from '../models';

export const userFeatureKey = 'user';

export interface State {
  user: models.User;
  isAccessDenied: boolean;
}

const initialState: State = {
  user: null,
  isAccessDenied: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.loadSuccess,
    (state, { payload }): State => ({
      ...state,
      user: payload,
    })
  ),

  on(
    actions.accessDenied,
    (state, { payload }): State => ({
      ...state,
      isAccessDenied: payload,
    })
  )
);
