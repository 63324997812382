import * as viewModels from './';
// TODO: this is wrong
import * as models from '../../domain/models';

export class OrderCellItemVM {
  orderItemId: number = null;
  order: viewModels.OrderVM = null;
  name: string = null;
  person: string = null;
  personLegal: string = null;
  personId: number = null;
  amount: any = null;
  cellIsProduct: boolean = null;
  cellIsTotal: boolean = null;
  productCellData: any = null;
  personCellData: any = null;
  amountCellData: any = null;
  membership: models.MembershipClientService = null;
  // membershipRequest: models.MembershipRequest = null;
  membershipRequest: models.MembershipOrderItem = null;
  salesAction: models.SalesActionEnum = null;
  activateButtonDisabled = false;
  activateButtonCaption = 'Activate';
  parentOrderItemId: number = null;
  productTypeId: number = null;
}
