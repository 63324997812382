import { Dba } from './dba';
import { ClientMembership } from './client-membership';
import { MembershipTier } from './membership-tier';

export class Client {
  id: number = null;
  account: string = null;
  name: string = null;
  legalName: string = null;
  dob: Date = null;
  dateCreated: Date = null;
  address: string = null;
  state: string = null;
  phone: string = null;
  email: string = null;
  balance: number = null;
  balancePaymentOverride: boolean = false;
  isEliteMembership:boolean = false;
  dba: Dba = null;
  membershipTier: MembershipTier = new MembershipTier();
  membership?: ClientMembership = new ClientMembership();
  // status: string = null;
  // flightCreditAmount: number = null;

  // membershipRequest: MembershipRequest = null;

  // clientServices: Array<ClientService> = [];
  // orders: Array<Order> = [];

  // get currentMembership(): MembershipClientService {
  //   if (this.memberships.length > 0) {
  //     const currentDate = new Date();
  //
  //     const membershipClientService = this.memberships
  //       .filter((i: ClientService) =>
  //         i.clientServiceType === ClientServiceTypeEnum.Membership
  //         || i.clientServiceType === ClientServiceTypeEnum.LegacyMembership)
  //       .filter((i: ClientService) => i.clientServiceStatus !== ClientServiceStatusEnum.Completed)
  //       .find(i => i.startDate <= currentDate && i.endDate > currentDate);
  //
  //     return membershipClientService ? membershipClientService : null;
  //   }
  //
  //   return null;
  // }
  //
  // get jetPasses(): Array<JetPassClientService> {
  //   return this.clientServices
  //     .filter(i => i.clientServiceType !== ClientServiceTypeEnum.JetPass)
  //     .map(i => i as JetPassClientService);
  // }

  // get memberships(): Array<MembershipClientService> {
  //   return this.clientServices
  //     .filter(i => i.clientServiceType === ClientServiceTypeEnum.Membership
  //       || i.clientServiceType === ClientServiceTypeEnum.LegacyMembership)
  //     .map(i => i as MembershipClientService);
  // }
}
