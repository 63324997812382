export class RtpRequestStage {
    name: string;
    status: string;
    expiredAt: Date;

    getName(): string {
        return this.name;
    }

    setName(name: string): RtpRequestStage {
        this.name = name;

        return this;
    }

    getStatus(): string {
        return this.status;
    }

    setStatus(status: string): RtpRequestStage {
        this.status = status;

        return this;
    }

    getExpiredAt(): Date {
        return this.expiredAt;
    }

    setExpiredAt(expiredAt: Date): RtpRequestStage {
        this.expiredAt = expiredAt;

        return this;
    }
}