import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD = '[RM Region Detail] Load';
export const LOAD_SUCCESS = '[RM Region Detail] Load Success';
export const LOAD_FAIL = '[RM Region Detail] Load Fail';
export const UPDATE = '[RM Region Detail] Update';
export const SAVE = '[RM Region Detail] Save';
export const SAVE_SUCCESS = '[RM Region Detail] Save Success';
export const SAVE_FAIL = '[RM Region Detail] Save Fail';

export class LoadPayload {
  constructor(public id: string) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.Region) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.Region) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class UpdatePayload {
  constructor(public item: models.Region) {}
}

export class UpdateAction implements Action {
  readonly type = UPDATE;

  constructor(public payload: UpdatePayload) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | UpdateAction;
