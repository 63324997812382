import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Charter.ServiceClassCollection] Load');
export const loadSuccess = createAction(
  '[Charter.ServiceClassCollection] Load Success',
  props<{ payload: Array<models.ServiceClass> }>()
);
export const loadFail = createAction('[Charter.ServiceClassCollection] Load Fail');
