import { Action } from '@ngrx/store';

export const SUBMIT = '[Marketplace.NewFlightRequest] Submit';
export const SUBMIT_SUCCESS = '[Marketplace.NewFlightRequest] Submit Success';
export const SUBMIT_FAIL = '[Marketplace.NewFlightRequest] Submit Fail';

import * as models from '../../domain/models';

export class SubmitActionPayload {
  aircraftCategories: Array<models.AircraftCategory>;
  legs: Array<models.LegRequest>;
  client: models.Client;
  specialRequest: string;
  showXODedicatedFleet: boolean;
  sendToXOMarketplace: boolean;
  allowFirmDeparture: boolean;
  sendOfferAutomatically: boolean;
  elacProForma: boolean;
  petOnBoard: boolean;
  petNote: string;
  sfOpportunityId: string;
  travelReason: string;
  specialRequirements: Array<string>;
}

export class SubmitAction implements Action {
  readonly type = SUBMIT;

  constructor(public payload: SubmitActionPayload) {}
}

export class SubmitSuccessAction implements Action {
  readonly type = SUBMIT_SUCCESS;

  constructor(public payload: string) {}
}

export class SubmitFailAction implements Action {
  readonly type = SUBMIT_FAIL;

  constructor() {
  }
}


export type Actions = SubmitAction | SubmitSuccessAction | SubmitFailAction;
