export class CreditMemoLeg {
  creditAmount: number;
  legId: number;
  legRequestId: number;
}

export class SfdcOpportunityCreditMemoData {
  sfdcOpportunityId: string;
  clientId: number;
  legs: Array<CreditMemoLeg>;
  sfdcCompensationId: string;
  internalComment: string;
  creditAmount: number
  creditMethod: number;
  creditReasons: Array<number>;
  warningMessage: string;
  errorMessage: string;
  warningPopupAlert: boolean;
  errorPopupAlert: boolean;
}


