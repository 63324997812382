import * as actions from '../actions/sent-emails';
import { Email } from '../../domain/models';
import { SortDescriptor } from '@progress/kendo-data-query';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<Email>;
  take: number;
  skip: number;
  sort: Array<SortDescriptor>;
  totalItems: number;
  emailBody: string;
  emailBodyLoaded: boolean;
  emailBodyLoading: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  take: 10,
  skip: 0,
  sort: [{field: 'sent', dir: 'desc'}],
  totalItems: null,
  emailBody: null,
  emailBodyLoaded: false,
  emailBodyLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_SENT_EMAILS: {
      return {
        ...state, 
        loading: true,
        take: action.payload.take,
        skip: action.payload.skip,
        sort: action.payload.sort,
      };
    }

    case actions.LOAD_SENT_EMAILS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        items: action.payload.emails,
        totalItems: action.payload.totalItems,
      };
    }

    case actions.LOAD_SENT_EMAILS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    }

    case actions.LOAD_EMAIL_BODY: {
      return {
        ...state,
        emailBodyLoaded: false, 
        emailBodyLoading: true
      };
    }

    case actions.LOAD_EMAIL_BODY_SUCCESS: {
      return {
        ...state,
        emailBodyLoaded: true,
        emailBodyLoading: false,
        emailBody: action.payload.body,
      };
    }

    case actions.LOAD_EMAIL_BODY_FAILURE: {
      return {
        ...state,
        emailBodyLoaded: false,
        emailBodyLoading: false,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getItems = (state: State) => state.items;
export const getTotalItems = (state: State) => state.totalItems;
export const getGridState = (state: State) => {
  return {
    skip: state.skip,
    take: state.take,
    sort: state.sort,
  }
};
export const getEmailBody = (state: State) => state.emailBody;
export const getEmailBodyLoaded = (state: State) => state.emailBodyLoaded;
export const getEmailBodyLoading = (state: State) => state.emailBodyLoading;