import {
  CostTypeListResponse,
  GetAllCostTypesResponse,
  ICostTypesService,
} from '../../../domain/service-contracts/cost-types';
import { Inject, Injectable } from '@angular/core';
import { IConfigService, SERVICE_TOKEN } from '../../../../../core/types';
import { CostTypeDto } from './dto/cost-type-dto';
import { CostType } from '../../../domain/models/cost-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CostTypesService implements ICostTypesService {
  private get API_PATH(): string {
    return this.configService.get('OrderServiceEndpoint');
  }

  constructor(
    private http: HttpClient,
    @Inject(SERVICE_TOKEN) private configService: IConfigService
  ) {}

  getAll(): Observable<CostTypeListResponse> {
    const requestUrl = `${this.API_PATH}/v1.0/cost-types/`;

    return this.http.get(requestUrl).pipe(
      map((serviceResponse: GetAllCostTypesResponse) => {
        const response = new CostTypeListResponse();
        response.costTypes = [];
        response.message = serviceResponse.message;
        response.code = serviceResponse.code;

        if (!serviceResponse || !serviceResponse.data) {
          return response;
        }

        serviceResponse.data.forEach((costTypeDto: CostTypeDto) => {
          const costType = new CostType();
          costType.id = costTypeDto.cost_type_id;
          costType.type = costTypeDto.cost_type;
          costType.description = costTypeDto.cost_description;

          response.costTypes.push(costType);
        });

        return response;
      })
    );
  }
}
