import * as models from '../../domain/models';
import * as legListViewModels from '../../view/view-models/leg-list';
import * as actions from '../actions/leg-collection';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  selectedItemId: number;
  offset: number;
  limit: number;
  items: Array<models.Leg>;
  filters: legListViewModels.LegListFilters;
  sorting: legListViewModels.LegListSorting;
  settings: legListViewModels.LegListSettings;
  lastUpdatedDate: Date;
  airportGroupsUpdated: boolean;
  isLegLoading: boolean;
  isLegLoaded: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  selectedItemId: null,
  items: [],
  filters: new legListViewModels.LegListFilters(),
  sorting: new legListViewModels.LegListSorting(),
  offset: 0,
  limit: 2000,
  settings: new legListViewModels.LegListSettings(),
  lastUpdatedDate: null,
  airportGroupsUpdated: false,
  isLegLoading: false,
  isLegLoaded: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      if (action.payload) {
        return {
          ...state,
          isLoading: true,
        };
      }
      return {
        ...state,
        offset: 0,
        limit: 2000,
        items: [],
        isLoading: true,
      };
    }

    case actions.SELECT_ITEM: {
      return {
        ...state,
        selectedItemId: action.payload,
      };
    }

    case actions.LOAD_BY_ID: {
      return {
        ...state,
        isLegLoading: true,
        isLegLoaded: false,
      };
    }

    case actions.LOAD_BY_ID_SUCCESS: {
      return {
        ...state,
        items: [...state.items, action.payload.leg],
        isLegLoaded: true,
        isLegLoading: false,
      };
    }

    case actions.LOAD_SUCCESS: {
      const merged = [...action.payload.legs, ...state.items];
      const items = merged.filter(
        ({ legId }, pos, arr) => arr.map((mapObj) => mapObj.legId).indexOf(legId) === pos
      );
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        airportGroupsUpdated: false,
        items,
        lastUpdatedDate: action.payload.lastUpdatedDate,
      };
    }

    case actions.UPDATE_SUCCESS_AIRPORT_GROUPS: {
      return {
        ...state,
        airportGroupsUpdated: true,
        items: action.payload.legs,
      };
    }

    case actions.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case actions.RESET_FILTERS:
      return {
        ...state,
        filters: new legListViewModels.LegListFilters(),
      };

    case actions.UPDATE_SORTING:
      return {
        ...state,
        sorting: action.payload,
      };

    case actions.UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getIsLegLoaded = (state: State) => state.isLegLoaded;
export const getIsLegLoading = (state: State) => state.isLegLoading;
export const getSelectedItemId = (state: State) => state.selectedItemId;
export const getItems = (state: State) => state.items;
export const getFilters = (state: State) => state.filters;
export const getSorting = (state: State) => state.sorting;
export const getSettings = (state: State) => state.settings;
export const getLastUpdated = (state: State) => state.lastUpdatedDate;
export const getAirportGroupsUpdated = (state: State) => state.airportGroupsUpdated;
export const getOffset = (state: State) => state.offset;
export const getLimit = (state: State) => state.limit;
