export class CancellationTerms {
  defaultTerms: boolean;
  terms: string;

  getDefaultTerms(): boolean {
    return this.defaultTerms;
  }

  setDefaultTerms(value: boolean): CancellationTerms {
    this.defaultTerms = value;

    return this;
  }

  getTerms(): string {
    return this.terms;
  }

  setTerms(terms: string): CancellationTerms {
    this.terms = terms;

    return this;
  }
}
