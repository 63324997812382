import * as models from '../models';

export const AIRCRAFT_CATEGORY = [
  'supermid',
  'mid',
  'light',
  'heavy',
  'cl850',
  'ulr',
  'cl300',
  'challenger_300',
  'challenger300',
];

export const CHALLENGERS = ['cl300', 'challenger_300', 'challenger300'];
export const XO_AIRCRAFT_CATEGORIES = [6, 4];
export const VJ_AIRCRAFT_CATEGORIES = [6, 8];
