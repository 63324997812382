import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction('[TaskMgmt.LegSourceTypeCollection] Load');
export const loadSuccess = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Load Success',
  props<{ payload: Array<models.LegSourceType> }>()
);
export const loadFail = createAction('[TaskMgmt.LegSourceTypeCollection] Load Fail');

export const setInitial = createAction('[TaskMgmt.LegSourceTypeCollection] Set Initial');

export class UpdateLegSourceTypePayload {
  legSourceTypeId?: number;
  active: boolean;
  legSource: string;
  taskTypeId: number;
}
export const updateLegSourceType = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Update Leg Source Type',
  props<{ payload: UpdateLegSourceTypePayload }>()
);
export const updateLegSourceTypeSuccess = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Update Leg Source Type Success',
  props<{ payload: models.LegSourceType }>()
);
export const updateLegSourceTypeFail = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Update Leg Source Type Fail'
);

export const createLegSourceType = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Create Leg Source Type',
  props<{ payload: UpdateLegSourceTypePayload }>()
);
export const createLegSourceTypeSuccess = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Create Leg Source Type Success',
  props<{ payload: models.LegSourceType }>()
);
export const createLegSourceTypeFail = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Create Leg Source Type Fail'
);

export class FilterWorkflowItemsPayload {
  filterCriteria: string;
}
export const filterWorkflowItems = createAction(
  '[TaskMgmt.LegSourceTypeCollection] Filter Workflow Items',
  props<{ payload: FilterWorkflowItemsPayload }>()
);
