export class OrderFilterCriteria {
  accountId: string = null;
  clientId: number = null;
  clientName: string = null;
  clientNameId: string = null;
  orderStatusIds: Array<number> = [];
  orderId: number = null;
  invoiceId: number = null;
  sapExportStatusIds: Array<number> = [];
  filterByCurrentUser = false;
  filterByUnconfirmedPayments = false;
  requestId: number = null;
  invoiceTotal: number = null;
  paymentType: string = null;
  startDate: Date = null;
  endDate: Date = null;
}
