import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const SET_FILTER = '[YM Ramen] Set Filter';
export const RESET_FILTER = '[YM Ramen] Reset Filter';

export class SetFilterActionPayload {
  filterCriteria: models.RmnFilterCriteria = new models.RmnFilterCriteria();
}

export class SetFilterAction implements Action {
  readonly type = SET_FILTER;

  constructor(public payload: SetFilterActionPayload) {}
}

export class ResetFilterAction implements Action {
  readonly type = RESET_FILTER;
}

export type Actions = SetFilterAction | ResetFilterAction;
