import { ProductOrderItem } from './product-order-item';
import { OrderItemTypeEnum } from './order-item-type';
import { CharterInvoiceTypeEnum } from './charter-invoice-type-enum';
import { CharterActionEnum } from './charter-action-enum';

export class CharterOrderItem extends ProductOrderItem {
  purchaseOrderNumber: string = null;
  formUrlKey: string = null;
  charterInvoiceType: CharterInvoiceTypeEnum = null;
  charterAction: CharterActionEnum = null;
  isValid: boolean = null;
  requestUrl: string = null;

  forceJsInvoice = false;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.Charter;
  }
}
