import { CurrencyEnum } from '../../../../shared/models';

export class AircraftCost {
  capacity: number;
  liveHourlyRate: number;
  repoHourlyRate: number;
  peakHourlyRate: number;
  domesticLandingFee: number;
  internationalLandingFee: number;
  internationalHandingFee: number;
  domesticRon: number;
  internationalRon: number;
  cabinAttendantFee: number;
  shortLegFee: number;
  minimumDailyHours: number;
  currency: CurrencyEnum;
  notes: string;

  getCapacity(): number {
    return this.capacity;
  }

  setCapacity(capacity: number): AircraftCost {
    this.capacity = capacity;

    return this;
  }

  getLiveHourlyRate(): number {
    return this.liveHourlyRate;
  }

  setLiveHourlyRate(liveHourlyRate: number): AircraftCost {
    this.liveHourlyRate = liveHourlyRate;

    return this;
  }

  getRepoHourlyRate(): number{
    return this.repoHourlyRate;
  }

  setRepoHourlyRate(repoHourlyRate: number): AircraftCost {
    this.repoHourlyRate = repoHourlyRate;

    return this;
  }

  getPeakHourlyRate(): number{
    return this.peakHourlyRate;
  }

  setPeakHourlyRate(peakHourlyRate: number): AircraftCost {
    this.peakHourlyRate = peakHourlyRate;

    return this;
  }

  getDomesticLandingFee(): number{
    return this.domesticLandingFee;
  }

  setDomesticLandingFee(domesticLandingFee: number): AircraftCost {
    this.domesticLandingFee = domesticLandingFee;

    return this;
  }

  getInternationalLandingFee(): number{
    return this.internationalLandingFee;
  }
  setInternationalLandingFee(internationalLandingFee: number): AircraftCost {
    this.internationalLandingFee= internationalLandingFee;

    return this;
  }

  getInternationalHandingFee(): number{
    return this.internationalHandingFee;
  }

  setInternationalHandingFee(internationalHandingFee: number): AircraftCost {
    this.internationalHandingFee = internationalHandingFee;

    return this;
  }

  getDomesticRon(): number{
    return this.domesticRon;
  }

  setDomesticRon(domesticRon: number): AircraftCost {
    this.domesticRon = domesticRon;

    return this;
  }
  
  getInternationalRon(): number{
    return this.internationalRon;
  }

  setInternationalRon(internationalRon: number): AircraftCost {
    this.internationalRon = internationalRon;

    return this;
  }

  getCabinAttendantFee(): number{
    return this.cabinAttendantFee;
  }

  setCabinAttendantFee(cabinAttendantFee: number): AircraftCost {
    this.cabinAttendantFee = cabinAttendantFee;

    return this;
  }

  getShortLegFee(): number{
    return this.shortLegFee;
  }

  setShortLegFee(shortLegFee: number): AircraftCost {
    this.shortLegFee = shortLegFee;

    return this;
  }

  getMinimumDailyHours(): number{
    return this.minimumDailyHours;
  }

  setMinimumDailyHours(minimumDailyHours: number): AircraftCost {
    this.minimumDailyHours = minimumDailyHours;

    return this;
  }
  
  getCurrency(): CurrencyEnum {
    return this.currency;
  }

  setCurrency(currency: CurrencyEnum): AircraftCost {
    this.currency = currency;
    
    return this;
  }
  
  getNotes(): string{
    return this.notes;
  }

  setNotes(notes: string): AircraftCost {
    this.notes = notes;

    return this;
  }
}
