import { createAction, props } from '@ngrx/store';
import * as models from '../../domain/models';

export const load = createAction('[Charter.AircraftCategory] Load');
export const loadSuccess = createAction(
  '[Charter.AircraftCategory] Load Success',
  props<{ entities: Array<models.AircraftCategory> }>()
);
export const loadFail = createAction('[Charter.AircraftCategory] Load Fail');

export const loadForCalc = createAction('[Charter.Calculation.AircraftCategories] Load');
export const loadForCalcSuccess = createAction(
  '[Charter.Calculation.AircraftCategories] Load Success',
  props<{ entities: Array<models.AircraftCategory> }>()
);
export const loadForCalcFail = createAction(
  '[Charter.Calculation.AircraftCategories] Load Fail'
);

export const setInitial = createAction('[Charter.AircraftCategories] Set Initial');
