import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import * as contracts from './contracts';
import * as models from '../../models';

import { response as svcJsonResp } from './get-user.response';
import * as mapper from './mapper/user-mapper.service';

import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class UserMockService implements contracts.IUserService {
  constructor(private cookieService: CookieService) {
  }

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {
    const response = new contracts.GetUserResponse();
    response.entity = mapper.UserMapperService.mapUserDtoToInternal(svcJsonResp);
    sessionStorage.setItem('id', String(response.entity.id));
    response.environment = _.get(svcJsonResp, 'environment', 'prod');

    const menuPin = this.cookieService.get('JetStudio.menuPinPreference');
    response.menuPin = menuPin !== undefined && menuPin !== null ? Number(menuPin) : 0;

    return of(response);
  }

  setUserMenuPinPreference(request: contracts.SetUserMenuPinRequest): Observable<contracts.SetUserMenuPinResponse> {
    this.cookieService.put('JetStudio.menuPinPreference', request.menuPin + '');
    const menuPin = this.cookieService.get('JetStudio.menuPinPreference');

    return of(new contracts.SetUserMenuPinResponse());
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {
    const response = new contracts.SignOutResponse();

    return of(response);
  }
}
