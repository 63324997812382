import { PaymentTransaction } from './payment-transaction';
import { PaymentTransactionTypeEnum } from './payment-transaction-type-enum';

export class PaymentTransactionCreditCard extends PaymentTransaction {
  last4Digits: string = null;

  get paymentTransactionType(): PaymentTransactionTypeEnum {
    return PaymentTransactionTypeEnum.CreditCard;
  }
}
