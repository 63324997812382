import * as models from '../../../domain/models';
import * as _ from 'lodash';

export class SfdcOpportunityDataMapperService {
  static parseSfdcOpportunityDataInternal(item: any): models.SfdcOpportunityData {
    const od = new models.SfdcOpportunityData();
    od.clientId = _.get(item, 'clientId', null);
    od.warningMessage = _.get(item, 'warningMessage', null);
    od.errorMessage = _.get(item, 'errorMessage', null);
    od.salesActionId = _.get(item, 'salesActionId', null);
    od.loyaltyCreditAmount = _.get(item, 'loyaltyCreditAmount', null);
    od.flightCreditAmount = _.get(item, 'flightCreditAmount', null);
    od.managedClientServiceId = _.get(item, 'managedClientServiceId', null);
    od.promotionalCreditAmount = _.get(item, 'promotionalCreditAmount', null);
    od.sfdcOpportunityId = _.get(item, 'sfdcOpportunityId', null);
    od.sfdcDepositAmount =
      typeof item.sfdcDepositAmount === 'number' ? item.sfdcDepositAmount : null;
    od.sfdcDepositAdjustment =
      typeof item.sfdcDepositAdjustment === 'number' ? item.sfdcDepositAdjustment : null;
    od.contractCommencementDate = _.get(item, 'contractCommencementDate', null);
    od.productId = _.get(item, 'productId', null);
    od.loyaltyCreditAmountGrantedForMembership = _.get(
      item,
      'loyaltyCreditAmountGrantedForMembership',
      null
    );
    od.loyaltyCreditAmountGrantedForFlights = _.get(
      item,
      'loyaltyCreditAmountGrantedForFlights',
      null
    );
    od.existingFcAmountBeingConvertedToLc = _.get(item, 'existingFcAmountBeingConvertedToLc', null);
    od.warningPopupAlert = _.get(item, 'warningPopupAlert', null);
    od.errorPopupAlert = _.get(item, 'errorPopupAlert', null);
    od.sfdcAnnualMembershipFee = _.get(item, 'sfdcAnnualMembershipFee', null);
    od.sfdcCashCreditTransfer = _.get(item, 'sfdcCashCreditTransfer', null)
      ? Number(_.get(item, 'sfdcCashCreditTransfer', null))
      : null;
    od.sfdcBonusCreditTransfer = _.get(item, 'sfdcBonusCreditTransfer', null)
      ? Number(_.get(item, 'sfdcBonusCreditTransfer', null))
      : null;
    return od;
  }

  static parseSfdcOpportunityCancelDataInternal(item: any): models.SfdcOpportunityCancelData {
    const od = new models.SfdcOpportunityCancelData();
    od.clientId = _.get(item, 'clientId', null);
    od.warningMessage = _.get(item, 'warningMessage', null);
    od.errorMessage = _.get(item, 'errorMessage', null);
    od.sfdcOpportunityId = _.get(item, 'sfdcOpportunityId', null);
    od.warningPopupAlert = _.get(item, 'warningPopupAlert', false);
    od.errorPopupAlert = _.get(item, 'errorPopupAlert', false);
    od.orderId = _.get(item, 'orderId', null);
    return od;
  }
}
