import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[YM Airport-Collection] Load';
export const LOAD_SUCCESS = '[YM Airport-Collection] Load Success';
export const LOAD_FAIL = '[YM Airport-Collection] Load Fail';

export class LoadAirportCollectionAction implements Action {
  readonly type = LOAD;

  constructor(public payload?: LoadAirportCollectionPayload) {
  }
}

export class LoadAirportCollectionPayload {
  airportCodes: Array<string>;
}

export class LoadSuccessAirportCollectionPayload {
  entities: Array<models.Airport>;
}

export class LoadSuccessAirportCollectionAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessAirportCollectionPayload) {}
}

export class LoadFailAirportCollectionPayload {
  entity: any;
}

export class LoadFailAirportCollectionAction implements Action {
  readonly type = LOAD_FAIL;

  // constructor(public payload: LoadFailAirportCollectionPayload) {
  // }
}

export type Actions =
  | LoadAirportCollectionAction
  | LoadSuccessAirportCollectionAction
  | LoadFailAirportCollectionAction;
