import { AircraftCategory } from '../aircraft-category';

export class CharterPricingRule {
  id: number = null;
  fromAirportCodes: Array<string> = [];
  toAirportCodes: Array<string> = [];
  startTimeUtc: Date = new Date();
  endTimeUtc: Date = new Date(
    new Date(new Date().setDate(new Date().getDate() + 1)).setHours(23, 59, 59, 999)
  );
  active = true;
  aircraftCategory: AircraftCategory = null;
  overridePrice: number = null;
  defaultPrice: number = null;
}
