import { createSelector } from 'reselect';

import * as fromCore from '../../core/reducers';

import { airportFeatureKey } from '../reducers/airport.reducer';

export const getAirportState = createSelector(
  fromCore.getCoreState,
  (state) => state[airportFeatureKey]
);
export const getAirportOptions = createSelector(getAirportState, (state) => state.airportOptions);
export const getAirportOptionsLoading = createSelector(
  getAirportState,
  (state) => state.airportOptionsLoading
);
