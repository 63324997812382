import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/api-status.actions';
import * as models from '../models';

export const apiStatusFeatureKey = 'apiStatus';

export interface State {
  apiStatus: models.ApiStatus;
  apiStatusLoading: boolean;
  apiStatusLoaded: boolean;
}

const initialState: State = {
  apiStatus: null,
  apiStatusLoading: false,
  apiStatusLoaded: false,
};

export const reducer = createReducer(
  initialState,

  on(
    actions.loadApiStatus,
    (state): State => ({
      ...state,
      apiStatusLoading: true,
      apiStatusLoaded: false,
    })
  ),
  on(
    actions.loadApiStatusSuccess,
    (state, { payload }): State => ({
      ...state,
      apiStatus: payload.status,
      apiStatusLoading: false,
      apiStatusLoaded: true,
    })
  ),
  on(
    actions.loadApiStatusFail,
    (state): State => ({
      ...state,
      apiStatusLoading: false,
      apiStatusLoaded: false,
    })
  )
);
