import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/pricing-comparison-price.actions';
import * as models from '../../domain/models';

export const pricingComparisonPriceCollectionFeatureKey = 'pricingComparisonPriceCollection';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<models.MembershipPricingComparisonPrice>;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(actions.save, (state): State => ({ ...state, loading: true, items: [] })),

  on(
    actions.saveSuccess,
    (_, { payload }): State => ({
      loaded: true,
      loading: false,
      items: payload.items,
    })
  ),

  on(
    actions.saveFail,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),

  on(actions.setInitial, (): State => ({ ...initialState }))
);
