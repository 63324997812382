import { SalesActionEnum } from './sales-action-enum';

export class SfdcOpportunityData {
  warningMessage: string;
  errorMessage: string;
  salesActionId: SalesActionEnum;
  sfdcOpportunityId: string;
  contractCommencementDate: string;
  clientId: number;
  promotionalCreditAmount: number;
  managedClientServiceId: number;
  loyaltyCreditAmountGrantedForMembership: number;
  loyaltyCreditAmountGrantedForFlights: number;
  existingFcAmountBeingConvertedToLc: number;
  flightCreditAmount: number;
  loyaltyCreditAmount: number;
  productId: number;
  warningPopupAlert: boolean;
  sfdcDepositAmount: number;
  sfdcDepositAdjustment: number;
  errorPopupAlert: boolean;
  sfdcAnnualMembershipFee: number;
  sfdcCashCreditTransfer: number;
  sfdcBonusCreditTransfer: number;
}
