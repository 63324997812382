export class CheckItem {
  caption: string;
  data: Array<CheckItemData>;
}

export class CheckItemData {
  code: string;
  caption: string;
  timeKey: string;
}

export const CHECKS: Array<CheckItem> = [
  {
    caption: 'Check <b>In</b>',
    data: [
      {
        code: 'aircraftandcrewinposition',
        caption: 'Aircraft & Crew in position',
        timeKey: 'aircraftAndCrewInPositionTime',
      },
      {
        code: 'cateringverified',
        caption: 'Catering verified',
        timeKey: 'cateringVerifiedTime',
      },
      {
        code: 'pilotsincommandverified',
        caption: 'PIC/SIC verified',
        timeKey: 'pilotsInCommandVerifiedTime',
      },
    ],
  },
  {
    caption: 'Pre <b>Boarding</b>',
    data: [
      {
        code: 'aircraftservicescompleted',
        caption: 'Aircraft services completed',
        timeKey: 'aircraftServicesCompletedTime',
      },
      {
        code: 'cateringloaded',
        caption: 'Catering on board',
        timeKey: 'cateringLoadedTime',
      },
      {
        code: 'k9screeningconducted',
        caption: 'K9 screening conducted',
        timeKey: 'k9ScreeningConductedTime',
      },
      {
        code: 'pilotcheckedids',
        caption: "Pilot checked ID's",
        timeKey: 'pilotCheckedIDsTime',
      },
    ],
  },
  {
    caption: '<b>Boarding</b>',
    data: [
      {
        code: 'allpassengersloaded',
        caption: 'All passengers on board',
        timeKey: 'allPassengersLoadedTime',
      },
      {
        code: 'doorsclosed',
        caption: 'Doors closed',
        timeKey: 'doorsClosedTime',
      },
      {
        code: 'aircraftofframp',
        caption: 'Aircraft off ramp',
        timeKey: 'aircraftOffRampTime',
      },
    ],
  },
];
