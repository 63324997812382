import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Sourcing.Operator] Load';
export const LOAD_SUCCESS = '[Sourcing.Operator] Load Success';
export const LOAD_FAIL = '[Sourcing.Operator] Load Fail';

export class LoadActionPayload {
  takenOperatorIds: Array<string>;
  search: string;
}

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload: LoadActionPayload) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<models.Operator>) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction;
