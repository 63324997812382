import { InjectionToken } from '@angular/core';

import * as models from '../../domain/models';
import { Observable } from 'rxjs';

export interface IInvoiceService {
  updateInvoice(request: UpdateInvoiceRequest): Observable<UpdateInvoiceResponse>;

  getContactMethods(request: GetContactMethodsRequest): Observable<GetContactMethodsResponse>;

  replaceInvoice(request: ReplaceInvoiceRequest): Observable<ReplaceInvoiceResponse>;

  consoleLogin(request: ConsoleLoginRequest): Observable<ConsoleLoginResponse>;
}

export const INVOICE_SERVICE_TOKEN = new InjectionToken('Order.IInvoiceService');

export class ConsoleLoginRequest {
  payload: string;
  url: string;
}

export class ConsoleLoginResponse {}

export class UpdateInvoiceRequest {
  invoice: models.Invoice;
}

export class UpdateInvoiceResponse {}

export class GetContactMethodsRequest {}

export class GetContactMethodsResponse {
  contactMethods: Array<models.ContactMethod>;
}

export class ReplaceInvoiceRequest {
  invoiceId: number;
  newFormTplId: number;
}

export class ReplaceInvoiceResponse {}
