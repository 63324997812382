import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import * as models from '../../domain/models';
import * as actions from '../actions/security.actions';

export const securityFeatureKey = 'security';

export interface State {
  isLoading: boolean;
  functionRestrictions: Array<models.FunctionRestriction>;
}

const initialState: State = {
  isLoading: false,
  functionRestrictions: new Array<models.FunctionRestriction>(),
};

export const reducer = createReducer(
  initialState,

  on(
    actions.reset,
    actions.authorizeFunctionality,
    actions.authorizeOrder,
    (state): State => ({
      ...state,
      isLoading: true,
    })
  ),

  on(
    actions.resetSuccess,
    actions.resetFail,
    actions.authorizeFunctionalitySuccess,
    actions.authorizeFunctionalityFail,
    actions.authorizeOrderSuccess,
    actions.authorizeOrderFail,
    (state): State => ({
      ...state,
      isLoading: false,
    })
  ),

  on(actions.setRestrictions, (state, { payload }): State => {
    const functionRestrictions = _.cloneDeep(payload.entities);
    return {
      ...state,
      functionRestrictions,
    };
  })
);
