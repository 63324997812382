export const response = {
  userId: 2,
  firstName: 'Oleg',
  lastName: 'Solovey',
  email: 'test.xojet@xojet.com',
  emailAddress: 'test.xojet@xojet.com',
  organizationId: 1,
  userTypeId: 17,
  logoutUrl: '/sp/ssout',
  environment: 'dev',
  restrictedFunctions: [
    {
      description: null,
      restrictedFunctionId: 2,
      restrictedFunctionName: 'Wire Received',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 4,
      restrictedFunctionName: 'NewOrderMembership',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 5,
      restrictedFunctionName: 'NewOrderService',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 6,
      restrictedFunctionName: 'NewOrderConcierge',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 7,
      restrictedFunctionName: 'NewOrderCharter',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 8,
      restrictedFunctionName: 'AmendChargesButton',
      securityAccess: 1,
    },
    {
      description: 'Access to Actions -> AR Payment Confirmed menu on the Order screen',
      restrictedFunctionId: 10,
      restrictedFunctionName: 'AR Payment Confirmed Order',
      securityAccess: 1,
    },
    {
      description: "'Revenue Management' mode of Pricing Calculator ",
      restrictedFunctionId: 11,
      restrictedFunctionName: 'RM Pricing Calculator',
      securityAccess: 1,
    },
    {
      description: 'Access to Actions -> Charter Credit Memo menu on the Order screen',
      restrictedFunctionId: 14,
      restrictedFunctionName: 'Charter Credit Memo',
      securityAccess: 1,
    },
  ],
  viewPermissions: {
    clientsModule: {
      overall: true,
      lookup: true,
      universalSearch: true,
    },
    explorerModule: {
      search: true,
    },
    charterSalesModule: {
      overall: true,
      opportunities: true,
      myOpportunities: true,
      newOpportunities: true,
    },
    marketplaceModule: {
      overall: true,
      oneWayQuotes: true,
    },
    resourceModule: {
      overall: true,
      aircraft: true,
      applicationConfigs: true,
      airports: true,
      areas: true,
      markets: true,
      regions: true,
      vendors: true,
      users: true,
    },
    salesModule: {
      overall: true,
      new: true,
      orders: true,
      pricingCalculator: true,
      pricingComparison: true,
    },
    sourcingModule: {
      overall: true,
      requests: true,
      myRequests: true,
      new: true,
    },
    taskMgmtModule: {
      overall: true,
      tasks: true,
    },
    yieldMgmtModule: {
      overall: true,
      heatMap: true,
      configurations: true,
      seatCharts: true,
      seatPricing: true,
      cyoPricing: true,
      charterPricing: true,
    },
    userMgmtModule: {
      overall: true,
      users: true,
    },
  },
};
