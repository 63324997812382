import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '../actions/airport.actions';
import * as airportContracts from '../services/airport/contracts';

@Injectable()
export class AirportEffects {
  constructor(
    private actions$: Actions,
    @Inject(airportContracts.SERVICE_TOKEN)
    private airportService: airportContracts.IAirportService
  ) {}

  getAirportList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAirportList),
      switchMap((action) => {
        return this.airportService.getAirportList(action.payload).pipe(
          map((svcResp) => {
            return actions.getAirportListSuccess({ payload: svcResp });
          }),
          catchError(() => of(actions.getAirportListFail()))
        );
      })
    )
  );
}
