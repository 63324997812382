import { Range } from '../range';

export class MatrixInterval {
  timeInterval: Range;
  prices: Array<number>;

  constructor(obj: any) {
    if (obj) {
      if (
        !obj.hasOwnProperty('timeInterval') ||
        !(obj.timeInterval instanceof Array) ||
        obj.timeInterval.length !== 2
      ) {
        throw new Error('Time Interval parameter for the matrix is not accurate');
      }
      if (!obj.hasOwnProperty('prices') || !(obj.prices instanceof Array)) {
        throw new Error(`Couldn't find Seat Prices Array for matrix on time interval: ${obj.timeInterval}`);
      }

      this.timeInterval = new Range();
      this.timeInterval.start = obj.timeInterval[0];
      this.timeInterval.end = obj.timeInterval[1];

      this.prices = obj.prices;
    }
  }
}
