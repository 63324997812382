import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/system.actions';

import { environment } from '../../../environments/environment';

export const systemFeatureKey = 'system';

export interface State {
  browserSupported: boolean;
  isDebug: boolean;
  version: string;
}

const initialState: State = {
  browserSupported: false,
  isDebug: false,
  version: environment.version
};

export const reducer = createReducer(
  initialState,

  on(
    actions.setBrowserSupport,
    (state, {payload}): State => ({
      ...state, browserSupported: payload
    })
  ),

  on(
    actions.setDebug,
    (state, {payload}): State => ({
      ...state, isDebug: payload
    })
  )
)
