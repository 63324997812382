import { Client } from './client';
import { OrderItemTypeEnum } from './order-item-type';
import { ClientService } from './client-service';
import { MembershipOrderItem } from './membership-order-item';

export class GroupMembershipOrderItem extends MembershipOrderItem {
  slotsCount: number = null;
  extraSlotAllowence: number = null;
  beneficiaries: Array<Client> = [];
  sfdcOpportunityId: string = null;
  contractCommencementDate: string = null;

  // Override
  membershipClientService: ClientService | null = null;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.GroupMembership;
  }
}
