import { PromotionItem } from './promotion-item';

export class Promotion {
  id: number = null;
  code: string = null;
  amount: number = null;
  percentage: number = null;
  description: string = null;
  createTime: Date = null;
  items: Array<PromotionItem> = [];
}
