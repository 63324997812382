import * as viewModels from '../../view/view-models';
import * as models from '../../domain/models';
import * as actions from '../actions/area-detail';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  item: models.Area;
  pristine: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  pristine: true,
  item: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.SAVE:
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.ADD_AIRPORT: {
      const item = _.cloneDeep(state.item);

      if (item.airports.find((a) => a.id === action.payload.id) === undefined) {
        const airport = new models.Airport();
        airport.id = _.cloneDeep(action.payload.id);
        airport.code = _.cloneDeep(action.payload.name);
        item.airports.push(airport);
      }

      return {
        ...state,
        pristine: false,
        item,
      };
    }

    case actions.REMOVE_AIRPORT: {
      const item = _.cloneDeep(state.item);
      item.airports = item.airports.filter((i) => i.id !== action.payload.id);

      return {
        ...state,
        pristine: false,
        item,
      };
    }

    case actions.SAVE_SUCCESS:
    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        pristine: true,
        item: _.cloneDeep(action.payload.item),
      };
    }

    case actions.SAVE_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        pristine: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
        isLoaded: true,
      };
    }

    case actions.UPDATE: {
      return {
        ...state,
        pristine: false,
        item: _.cloneDeep(action.payload.item),
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItem = (state: State) => state.item;
export const getPristine = (state: State) => state.pristine;
