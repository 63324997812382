import { CateringCredit } from './catering-credit';
import { CharterInvoicePaymentStatusEnum } from './charter-invoice-payment-status-enum';
import { CharterLegacyFormTplEnum } from './charter-legacy-form-tpl-enum';
import { ConciergeOrderItem } from './concierge-order-item';

export class ConciergeFlightAncillaryData {
  creditReasons: Array<CreditReason>;
  flightRequests: Array<ConciergeFlightRequest>;
  legRequests: Array<ConciergeLegRequest>;
}

export class ConciergeFlightRequest {
  invoices: Array<ConciergeFlightRequestInvoice>;
  lastOrder: ConciergeLastOrder;
  requestId: number;
  departureDate: Date;
  conciergeAction: number;
  legRequestIds?: Array<number>;
}

export class CreditReason {
  creditReasonId: number;
  creditReasonName: string;
  tiedToFlight: boolean;
}

export class ConciergeFlightRequestInvoice {
  clientSource: string;
  createTime: Date = null;
  totalPrice: number;
  formUrl: string;
  paymentStatus: CharterInvoicePaymentStatusEnum;
  formTplId: CharterLegacyFormTplEnum;
}

export class ConciergeLegRequest {
  requestId: number;
  departureDate: Date;
  requestUrl: string = null;
  flightRequest: FlightRequestInLeg = null;
  cateringCredit: CateringCredit;
  flightRequestUrl: string = null;
  flightRequestId: number = null;
  legUrl: string;
  legId: number;
}

export class FlightRequestInLeg {
  invoices: Array<ConciergeFlightRequestInvoice>;
}

export class ConciergeLastOrder {
  items: Array<ConciergeOrderItem> = [];
  orderUrl?: string = null;
  totalPrice: number = 0;
}
