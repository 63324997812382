import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ClipboardService } from '../../services';

@Directive({
  selector: '[clipboard]',
  providers: [ClipboardService]
})
export class ClipboardDirective {
  @Input('clipboard') public clipboard = '';
  @Output() copyEvent = new EventEmitter<string>();
  @Output() errorEvent = new EventEmitter<any>();
  @HostListener('click') async onClickHandler() {
    try {
      const value = await this.clipboardService.copy(this.clipboard);
      this.copyEvent.emit( value );
    } catch (e) {
      this.errorEvent.emit(e);
    }
  }

  constructor(private clipboardService: ClipboardService) {}
}
