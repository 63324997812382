import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as contracts from '../../../domain/service-contracts/additional-features';
import * as coreHelpers from '../../../../../core/helpers';
import * as coreTypes from '../../../../../core/types';
import * as mappers from '../mapper';

@Injectable()
export class AdditionalFeaturesRest implements contracts.IAdditionalFeaturesService {
  private get API_PATH(): string {
    // ToDo Update after implementing auth0 authorization in production
    if (coreHelpers.isLegacyAuth()) {
      return this.configService.get('CharterPricingServiceEndpoint');
    } else {
      return this.configService.get('CharterPricingServiceEndpointV1');
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getAdditionalFeatures(
    request: contracts.GetAdditionalFeaturesRequest
  ): Observable<contracts.GetAdditionalFeaturesResponse> {
    const requestUrl = `${this.API_PATH}/membership-comparison/premium-features`;
    return this.http.get(requestUrl).pipe(
      map((svcJsonResp: any) => {
        const resp = new contracts.GetAdditionalFeaturesResponse();
        resp.entities = svcJsonResp.map((item) =>
          mappers.AdditionalFeaturesMapper.parseAdditionalFeature(item)
        );
        return resp;
      })
    );
  }
}
