import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/my-opportunity-list.actions';
import * as queries from '../../domain/queries';
import * as models from '../../domain/models';

import * as _ from 'lodash';

export const myOpportunityListFeatureKey = 'myOpportunityList';

export interface State {
  query: queries.OpportunityCriteria;
  currentPage: number;
  loaded: boolean;
  loading: boolean;
  items: Array<models.Opportunity>;
  itemsPerPage: number;
  itemsTotalCount: number;
}

const initialState: State = {
  query: new queries.OpportunityCriteria(),
  currentPage: 1,
  loaded: false,
  loading: false,
  items: [],
  itemsPerPage: 15,
  itemsTotalCount: 0,
};

export const reducer = createReducer(
  initialState,
  on(actions.reset, (): State => ({ ...initialState })),
  on(
    actions.search,
    (state, { criteria }): State => ({
      ...state,
      query: _.cloneDeep(criteria),
      loading: true,
    })
  ),
  on(
    actions.searchSuccess,
    (state, { count, data, page }): State => ({
      ...state,
      currentPage: page,
      loaded: true,
      loading: false,
      items: data,
      itemsTotalCount: count,
    })
  )
);
