import { createAction, props } from '@ngrx/store';

export const loadApiStatus = createAction(
  '[Shared.ApiStatus] Load Api Status',
  props<{ payload: string }>()
);
export class LoadApiStatusSuccessPayload {
  apiName: string;
  status: any;
}
export const loadApiStatusSuccess = createAction(
  '[Shared.ApiStatus] Load Api Status Success',
  props<{ payload: LoadApiStatusSuccessPayload }>()
);
export const loadApiStatusFail = createAction('[Shared.ApiStatus] Load Api Status Fail');
