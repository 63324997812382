import * as models from '../../domain/models/configurations';
import * as actions from '../actions/departure-time-fee-config';
import cloneDeep from 'lodash/cloneDeep';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  fees: Array<models.DepartureTimeFee>;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  fees: []
};

export function reducer(state: State = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD:
    case actions.UPDATE:
    case actions.ADD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const fees = cloneDeep(action.payload.fees);

      return {
        fees,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.UPDATE_SUCCESS:
    case actions.ADD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.ADD_FAIL:
    case actions.UPDATE_FAIL:
    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };
    }

    case actions.REMOVE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true
      };
    }

    case actions.REMOVE_SUCCESS:
    case actions.REMOVE_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getDepTimeFees = (state: State) => state.fees;
