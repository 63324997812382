export class PricingCalculatorOpportunity {
  actype: string = null;
  flexDeparture: boolean = null;
  price: number = null;
  priceLegsSum: number = null;
  subtotal: number = null;
  segmentFee: number = null;
  tax: number = null;
  legs: Array<PricingCalculatorOfferLeg> = null;
}

export class PricingCalculatorOfferLeg {
  eft: number = null;
  origin: string = null;
  destination: string = null;
  depDateTime: string = null;
  price: number = null;
  pax: number = null;
  suppressTaxFee: boolean = false;
  ferry: boolean = false;
}
