import { CharterLegacyFormTplEnum } from './charter-legacy-form-tpl-enum';
import { CharterInvoicePaymentStatusEnum } from './charter-invoice-payment-status-enum';
import { ClientSourceTypeEnum } from './client-source-type';

export class CharterInvoice {
  id: number;
  totalPrice: number;
  formUrl: string;
  paymentStatus: CharterInvoicePaymentStatusEnum;
  formUrlKey: string;
  formTplId: CharterLegacyFormTplEnum;
  formEditUrl: string;
  orderUrl?: string = null;
  clientSource: ClientSourceTypeEnum = null;
  createTime: Date = null;
}
