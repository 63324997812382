import { LegacyMembershipClientService } from './legacy-membership-client-service';
import { OrderItem } from './order-item';
import { OrderItemTypeEnum } from './order-item-type';

export class LegacyAdjustmentOrderItem extends OrderItem {
  clientService: LegacyMembershipClientService;

  membershipPeriodId: number = null;
  adjustedAmount = 0;

  get orderItemType(): OrderItemTypeEnum {
    return OrderItemTypeEnum.LegacyAdjustment;
  }

  isFullRefund(): boolean {
    return this.refundableAmount === this.adjustedAmount;
  }
}
