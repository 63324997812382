import { createAction, props } from '@ngrx/store';

import * as models from '../../domain/models';

export const load = createAction('[Charter.Rating] Load');
export const loadSuccess = createAction(
  '[Charter.Rating] Load Success',
  props<{ payload: models.Rating }>()
);
export const loadFail = createAction('[Charter.Rating] Load Fail');
