import { User } from './user';
import { OperatorDocumentActivity } from './operator-document-activity';
import { OperatorDocumentType } from './operator-document-type';

export class OperatorDocument {
  id: number;
  operatorDocumentId: string = null;
  operatorId: string = null;
  fileName: string = null;
  url: string = null;
  status: string = null;
  docTypeId: number = null;
  user: User = null;
  activities: Array<OperatorDocumentActivity> = [];
}
