import { EmailNotificationHistory } from "./email-notification-history";

export class Email {
  id: string;
  from: string;
  to: Array<string> = [];
  cc: Array<string> = [];
  bcc: Array<string> = [];
  repliesTo: Array<string> = [];
  sent: Date;
  subject: string;
  body: string;
  emailAttachments: Array<string> = [];
  emailNotificationHistories: Array<EmailNotificationHistory> = [];
  skipSending: boolean = false;
}
