export class Surcharge {
	code: string;
  name: string;
  value: number;

  getCode(): string {
    return this.code;
  }

  setCode(code: string): Surcharge {
    this.code = code;

    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(name: string): Surcharge {
    this.name = name;

    return this;
  }

  getValue(): number {
    return this.value;
  }

  setValue(value: number): Surcharge {
    this.value = value;

    return this;
  }
}